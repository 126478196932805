import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Wrapper,
  Header,
  HeadingSummaryBlock,
  FaqBlock,
  DivWrapper,
  ContentContainerBottom,
} from 'src/components/base/core';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { commonSpacing, theme } from 'src/shared/theme/theme';
import { useGetBlockHeight } from 'src/hooks/useGetBlockHeight';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';
import { routeType } from 'src/helpers/containers/helperRoutePreference';
import { PAY3342 } from 'src/constants/constants';

interface TextContentInterface {
  header: string;
  title1: string;
  content1: string;
  title2: string;
  content2: string;
  title3: string;
  content3: string;
}

export const EarlyPayoutsContainer = () => {
  const { t } = useTranslation();
  const account = useSelector((state: RootState) => state.account);
  const navigate = useNavigate();
  const [elementRef, height] = useGetBlockHeight();
  const [updatePreferenceOnServer] = handlePreferences();

  useEffect(() => {
    localStorage.removeItem('approved-account');
  }, []);
  const handleDoneButtonClick = () => {
    localStorage.removeItem('early-payout');
    updatePreferenceOnServer(routeType.TERM_SELECTION);
    navigate('/payment-selection', {
      state: { account_id: account?.response?.data?.id },
    });
    return;
  };

  const handleBackButtonClick = () => {
    localStorage.removeItem('early-payout');
    localStorage.setItem('approved-account', 'true');
    updatePreferenceOnServer(routeType.ACCOUNT_APPROVED);
    navigate('/account-approved');
    return;
  };

  const getTextContent = (): TextContentInterface => {
    if (PAY3342) {
      return {
        header: t('headingContent.commonSteps.earlyPayouts.headingNew'),
        title1: t('faqBlocks.ownPayoutTermsNew.heading'),
        content1: t('faqBlocks.ownPayoutTermsNew.content'),
        title2: t('faqBlocks.fairFeeNew.heading'),
        content2: t('faqBlocks.fairFeeNew.content'),
        title3: t('faqBlocks.flexibleSetupNew.heading'),
        content3: t('faqBlocks.flexibleSetupNew.content'),
      };
    } else {
      return {
        header: t('headingContent.commonSteps.earlyPayouts.heading'),
        title1: t('faqBlocks.ownPayoutTerms.heading'),
        content1: t('faqBlocks.ownPayoutTerms.content'),
        title2: t('faqBlocks.fairFree.heading'),
        content2: t('faqBlocks.fairFree.content'),
        title3: t('faqBlocks.flexibleSetup.heading'),
        content3: t('faqBlocks.flexibleSetup.content'),
      };
    }
  };

  const textContent = getTextContent();

  return (
    <Wrapper>
      <DivWrapper className="no_fullscreen">
        <Box component={'div'} className={'container_content'}>
          <Box
            component={'div'}
            className={'container_content_top'}
            ref={elementRef}
            sx={{
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                minHeight: height,
                paddingBottom: height ? 0 : commonSpacing.desktopPaddingB,
              },
            }}
          >
            <Header
              isBackButtonVisible={true}
              onBackButtonClick={handleBackButtonClick}
              paddingX={0}
              isLanguageSwitcher
            />
            <Box sx={{ marginBottom: 6 }}>
              <HeadingSummaryBlock heading={textContent.header} headingAlignment={'left'} />
            </Box>
            <Box>
              <FaqBlock
                heading={textContent.title1}
                content={textContent.content1}
                iconType="DateCalenderIcon"
              />
              <FaqBlock
                heading={textContent.title2}
                content={textContent.content2}
                iconType="FairFreeIcon"
              />
              <FaqBlock
                heading={textContent.title3}
                content={textContent.content3}
                iconType="SetupIcon"
              />
            </Box>
          </Box>
        </Box>
        <ContentContainerBottom className={'no_fullscreen'}>
          <Button
            variant="containedLarge"
            color="primary"
            type="submit"
            fullWidth
            onClick={handleDoneButtonClick}
          >
            {t('buttonTexts.continueButton')}
          </Button>
        </ContentContainerBottom>
      </DivWrapper>
    </Wrapper>
  );
};
