import { getEnvFromLocation } from '../../shared/utilities/getEnvironments';
import { AnalyticsBrowser } from '@segment/analytics-next';

const getEnvironment = getEnvFromLocation();
let analytics: AnalyticsBrowser;
export const trackEvent = (name: string, data?: any) => {
  // if(process.env.NODE_ENV === 'production') return

  const eventName = `${name}`;
  // @ts-ignore
  analytics?.track(eventName, data);
};

export const setUserForTracking = (id: string | null, name?: string) => {
  // @ts-ignore
  analytics?.identify(id);
};

export const trackPage = () => {
  // @ts-ignore
  analytics?.page();
};

export const trackClickEvent = (ev: any) => {
  if (['input', 'button', 'svg'].includes(ev.target?.tagName.toLowerCase()) && ev.target?.id)
    trackEvent(`${ev.target?.id} Click`);
};

export const init = (key: string) => {
  analytics = AnalyticsBrowser.load({ writeKey: key });
};
