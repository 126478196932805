import { Box } from '@mui/system';
import { BottomRow, InvoiceItemRow } from './BatchDetailBottom.style';
import { TagWrapper } from '../BatchDetailTop/BatchDetailTop.style';
import { Icon } from 'src/components/base/core/Media/Icon/Icon';
import { Typography } from '@mui/material';
import { handlePayModeDisplay } from 'src/shared/utilities/dataConvertions';
import { getEarningFormattedDate } from 'src/shared/utilities/dob';
import { useCurrencyFormat } from 'src/hooks/useCurrencyFormat';
import moneyFormat from 'src/shared/utilities/moneyFormat';
import { useState } from 'react';
import { SearchResultEmpty } from 'src/components/base/core/Grids/InvoiceGridElements/PaymentGrid/SearchResultEmpty/SearchResultEmpty';

export interface InvoicesListProps {
  item: {
    amount: number;
    approved_at?: string;
    coupa_invoice_number?: string;
    invoice_lines?: any[];
    invoiced_at?: string;
    paid_at?: string;
  };
  paymode?: string;
  t: any;
  isInvoiceLinesAvailable?: boolean;
}

export const BatcheInvoiceItem = ({
  item,
  paymode,
  t,
  isInvoiceLinesAvailable,
}: InvoicesListProps) => {
  const [invoicesListOpen, setInvoicesListOpen] = useState(false);

  const onClickItem = () => {
    setInvoicesListOpen(!invoicesListOpen);
  };

  const invoicelineItems = (invoiceLinesArray: any[] = []) => {
    return invoiceLinesArray.map((item: any, i: any) => {
      const qty = item?.quantity;
      return (
        <BottomRow className="content" key={i}>
          <Box className="content_left">
            <Typography variant="h2Bold" component={'p'} color={'gray.700'} marginBottom={1}>
              {item?.description}
            </Typography>
            <Typography variant="h1" component={'p'} color={'gray.500'}>
              {qty && `${qty} x `}
              {moneyFormat(item?.price)}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography variant="h2Bold" component={'p'} color={'gray.500'}>
              {moneyFormat(item?.total)}
            </Typography>
          </Box>
        </BottomRow>
      );
    });
  };

  return (
    <>
      <InvoiceItemRow onClick={isInvoiceLinesAvailable ? () => onClickItem() : () => {}}>
        <Box
          className={`invoice_content ${
            isInvoiceLinesAvailable ? 'accordion_available' : 'accordion_unavailable'
          }`}
        >
          <Box className="invoice_content_left">
            <span className="title_value">{item.coupa_invoice_number}</span>
            <span className="date_value">{getEarningFormattedDate(item.invoiced_at)}</span>
          </Box>
          <Box className="invoice_content_right">
            <span className="amount_value">{useCurrencyFormat(item.amount)}</span>
            <TagWrapper className="un_editable invoice_line_item">
              {handlePayModeDisplay(paymode)}
            </TagWrapper>
          </Box>
        </Box>
        {isInvoiceLinesAvailable && (
          <Box
            className={`invoice_content_icon ${invoicesListOpen ? 'active' : 'inactive'}`}
            onClick={onClickItem}
          >
            <Icon iconType="RightAlignedArrow" />
          </Box>
        )}
      </InvoiceItemRow>
      {invoicesListOpen && (
        <Box display={'flex'} flexDirection={'column'}>
          <BottomRow className="header">
            <Box className="header_left">
              <Typography variant="h1" fontSize={'10px'} color={'gray.500'}>
                {t('invoiceSummary.work.itemText')}
              </Typography>
            </Box>
            <Box className="header_right">
              <Typography variant="h1" fontSize={'10px'} color={'gray.500'}>
                {t('invoiceSummary.work.amountText')}
              </Typography>
            </Box>
          </BottomRow>
          {isInvoiceLinesAvailable ? (
            invoicelineItems(item?.invoice_lines)
          ) : (
            <SearchResultEmpty content={'No items found'} />
          )}
        </Box>
      )}
    </>
  );
};
