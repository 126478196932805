import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import {
  Header,
  HeadingSummaryBlock,
  FormInput,
  FormInputPhoneNumber,
  FormInputSSN,
  AddressFields,
  UpdateFormWrapper,
  UpdateCardWrapper,
  UpdateFormTop,
  AccountVerificationStatusses,
  Messages,
  FormInPutChangePassword,
  FixedParentWrapperContainer,
  FixedParentWrapper,
} from 'src/components/base/core';
import { ERROR_MESSAGES } from 'src/constants/Validation';
import { FormEditSubmitionprops } from 'src/models/component/base.model';
import { initAccount } from 'src/models/init/account.init';
import dtoToFormValues from 'src/shared/utilities/dto';
import { AccountParams } from 'src/shared/utilities/interface';
import { FormProps } from '../../IdentityVerification/FormAddressSubmition/FormAddressSubmition';
import { identityVerificationSchemaObj, revieInfoSchemaObj } from 'src/helpers/validationSchemas';
import { handleEditIndividual } from 'src/helpers/containers/helperEditIndividual';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { handleIdModalVisibility } from 'src/helpers/containers/helperIdDocumentModal';
import { FormInputDate } from 'src/components/base/core/FormElements/FormInputDate/FormInputDate';
import { theme } from 'src/shared/theme/theme';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { handleVerifyPhone } from 'src/helpers/helperPhoneVerificaition';
import { PhoneVerifyCodeContainer } from '../../../VerifyCode/VerifyCode';

export const FormEditSubmition = ({
  onCancelButton,
  onFormSubmit,
  onBackButtonClick,
  title,
  type,
  setResult,
  result,
  navigate,
  isSettings = false,
  isShadowCard = false,
  isVerified = false,
}: FormEditSubmitionprops) => {
  const [
    accountData,
    errorState,
    setErrorState,
    appLogin,
    isNullDOB,
    onChangeHandlerPhone,
    isLoading,
    onChangeHandlerDateInput,
    isNot18,
    isValidDOB,
  ]: any = handleEditIndividual();
  const { t } = useTranslation();

  const { webauthnEnabled, allowEditEmail, disabledPhoneVerificationStep } = useFlags();
  const [formData, setFormData] = useState<AccountParams>(initAccount);
  const [verifyCodeContainerVisible, setVerifyCodeContainerVisible] = useState(false);
  const [handleVerificationCode, verifiedPhoneNumber, errorStatePhone, isPhoneVerificationLoading] =
    handleVerifyPhone();

  const [openIdModal, onClickIdModalOpen, onClickIdModalClose] = handleIdModalVisibility();
  const [btnSubmitted, setBtnSubmitted] = useState(false);
  const [enableSSNUpdate, setEnableSSNUpdate]: any = useState(false);
  const [errorManualDate, setErrorManualDate] = useState(false);
  const [ssnVerificationFailed, setSSNVerificationFailed] = useState(false);
  const [addressError, setAddressError] = useState('');

  const ssnLast4AlreadyProvided = accountData?.individual?.ssn_last4_provided;
  const allowFullSSN = ssnLast4AlreadyProvided;

  const revieInfoSchema = yup
    .object({
      ...revieInfoSchemaObj,
      ...identityVerificationSchemaObj,
      phone_number: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .min(14, ERROR_MESSAGES.PHONE_NUMBER_INVALID),
      dob: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .test(
          'len',
          errorManualDate ? ERROR_MESSAGES.DATE_INVALID_LESS_THAN_18 : ERROR_MESSAGES.DATE_INVALID,
          (value) => {
            if (value && value?.length === 10 && isNot18(value) && isValidDOB(value)) return true;
            else return false;
          }
        ),
      idNumber: yup
        .string()
        .test('required', ERROR_MESSAGES.REQUIRED, (value) => {
          if (!enableSSNUpdate || value) return true;
          return false;
        })
        .test(
          'min',
          allowFullSSN ? ERROR_MESSAGES.SSN_INVALID_FULL : ERROR_MESSAGES.SSN_INVALID_LAST4,
          (value) => {
            if (
              (!allowFullSSN && value?.length === 4) ||
              (allowFullSSN && value?.length === 9) ||
              !enableSSNUpdate
            )
              return true;
            return false;
          }
        ),
    })
    .required();

  //hook-forms
  const {
    register,
    handleSubmit,
    setValue,
    control,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<AccountParams>({
    mode: 'onSubmit',
    resolver: yupResolver(revieInfoSchema),
    defaultValues: initAccount,
  });

  let defaultValues: FormProps = { line1: '', line2: '', city: '', state: '', postal_code: '' };
  const [addressStates, setAddressStates] = useState(defaultValues);
  const maxDate: any = moment().subtract(18, 'years').format();

  const updateBindingsChange = (event: any, feild: any) => {
    setValue(feild, event?.target?.value);
  };

  useEffect(() => {
    if (result) {
      dtoToFormValues(setValue, result);
      if (result && !isNullDOB(result.individual.dob)) {
        const dob = result?.individual?.dob;
        const formattedDOB: any = moment(new Date(dob.year, dob.month - 1, dob.day));
        setValue('dob', moment(formattedDOB).format('MM/DD/yyyy'));
      }

      if (
        result?.individual?.ssn_last4_provided &&
        result?.currently_due_requirements?.length > 0 &&
        result?.currently_due_requirements?.indexOf('individual.id_number') > -1
      ) {
        setSSNVerificationFailed(true);
      } else {
        setSSNVerificationFailed(false);
      }
    }
  }, [result]);

  const skipTheOTPVerification = (data: any) => {
    appLogin(data, onFormSubmit, setResult);
    setFormData(initAccount);
    setVerifyCodeContainerVisible(false);
  };

  const moveToOTPVerification = (data: any, updatedData: any) => {
    setFormData(updatedData);
    // @ts-ignore
    handleVerificationCode(data, getValues, setVerifyCodeContainerVisible);
  };

  // check tos_shown_and_accepted true or false __
  const getToshShownAccepted =
    accountData && accountData?.tos_shown_and_accepted && isSettings ? true : false;

  const appSubmit = handleSubmit(async (data) => {
    const getIDNumber: any = data.idNumber;
    let updatedData = {
      ...data,
      allowFullSSN: allowFullSSN,
      idNumber: getIDNumber,
      front: data.front,
      back: data.back,
    };

    // if tos_shown_and_accepted false __
    if (!getToshShownAccepted) {
      updatedData = {
        ...data,
        allowFullSSN: allowFullSSN,
        idNumber: getIDNumber,
        front: data.front,
        back: data.back,
        tos_shown_and_accepted: true,
      };
    }
    // if webauthnEnabled featureflag enable or not __
    if (webauthnEnabled && !disabledPhoneVerificationStep) {
      if (accountData && accountData.individual.phone) {
        if (accountData.individual.phone.includes(data?.phone_number?.replace(/[^0-9]/g, ''))) {
          skipTheOTPVerification(updatedData);
        } else {
          moveToOTPVerification(data, updatedData);
        }
      } else {
        moveToOTPVerification(data, updatedData);
      }
    } else {
      skipTheOTPVerification(updatedData);
    }
  });

  const onChangeHandlerDate = (event: any) => {
    onChangeHandlerDateInput(event, setValue, setErrorManualDate);
  };

  const onChangeHandler = (event: any) => {
    onChangeHandlerPhone(event, setValue);
  };

  const handleNoSSNClick = () => {
    navigate('/sign-up');
  };

  const getIndivisualData = result && result?.individual;
  const getVerificationData = result && result?.individual?.verification;
  const confirmVerificationDocumentSubmitted =
    getVerificationData?.document?.front !== null && getVerificationData?.status === 'verified';
  const confirmUnVerificationDocumentSubmitted =
    getVerificationData?.document?.front !== null && getVerificationData?.status !== 'verified';
  const confirmationNoUploadDone =
    !confirmVerificationDocumentSubmitted && !confirmUnVerificationDocumentSubmitted;

  const setVerifiedOnlyForSetting = getVerificationData?.status === 'verified' && isSettings;
  const disabledTheFirstNameField =
    setVerifiedOnlyForSetting &&
    getIndivisualData?.first_name !== null &&
    getIndivisualData?.first_name !== '';
  const disabledTheLastNameField =
    setVerifiedOnlyForSetting &&
    getIndivisualData?.last_name !== null &&
    getIndivisualData?.last_name !== '';
  const emailId = result && result?.individual?.email;

  if (verifyCodeContainerVisible) {
    return (
      <FixedParentWrapper>
        <FixedParentWrapperContainer>
          <PhoneVerifyCodeContainer
            phone={verifiedPhoneNumber}
            setVerifyCodeContainerVisible={setVerifyCodeContainerVisible}
            onSubmitFunction={() =>
              appLogin(formData, onFormSubmit, setResult, setVerifyCodeContainerVisible)
            }
            phoneWithMask={formData.phone_number}
          />
        </FixedParentWrapperContainer>
      </FixedParentWrapper>
    );
  }

  return (
    <>
      {(isLoading || isPhoneVerificationLoading) && <ManualLoader />}
      {
        <UpdateFormWrapper
          onSubmit={appSubmit}
          className={`no_bottom_margin ${isShadowCard ? 'is_shadow_card' : 'disable_shadow_card'}`}
        >
          {!isSettings && (
            <>
              <Box
                sx={{
                  [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                    paddingX: 4,
                  },
                }}
              >
                <Header
                  isBackButtonVisible={true}
                  onBackButtonClick={onBackButtonClick}
                  isProgressBarVisible={true}
                  totalProgressSteps={5}
                  currentProgressStep={5}
                  paddingX={0}
                  backButtonId={'EditFormBackButton'}
                  isLanguageSwitcher
                />
              </Box>
              <Box
                sx={{
                  marginBottom: 6,
                  paddingTop: 4,
                  [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                    paddingX: 4,
                  },
                }}
              >
                <HeadingSummaryBlock
                  heading={t('headingContent.individual.review.heading')}
                  headingAlignment="left"
                  content={t('headingContent.individual.review.heading')}
                  contentAlignment="left"
                />
              </Box>
            </>
          )}
          <Box
            sx={{
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                paddingX: 4,
              },
            }}
          >
            {!isSettings && (
              <UpdateFormTop className={type ? 'enabled_status' : 'disabled_status'}>
                <Typography
                  textTransform={'uppercase'}
                  variant="caption2"
                  color={'gray.500'}
                  marginBottom={0}
                >
                  {title}
                </Typography>
                {type && type !== undefined && <AccountVerificationStatusses type={type} />}
              </UpdateFormTop>
            )}
            <UpdateCardWrapper className={`update_wrapper_top`}>
              <Box
                className={isSettings ? 'is_shadow_card' : 'disabled_shadow_card'}
                paddingTop={isSettings ? 4 : 0}
                paddingBottom={isSettings ? 1 : 0}
                paddingX={isSettings ? 4 : 0}
                marginBottom={isSettings ? 4 : 0}
              >
                {isSettings && (
                  <Box marginBottom={4}>
                    <UpdateFormTop className={type ? 'enabled_status' : 'disabled_status'}>
                      <Typography
                        textTransform={'uppercase'}
                        variant="caption2"
                        color={'gray.500'}
                        marginBottom={0}
                      >
                        {title}
                      </Typography>
                      {type && type !== undefined && <AccountVerificationStatusses type={type} />}
                    </UpdateFormTop>
                  </Box>
                )}

                <Controller
                  control={control}
                  name="first_name"
                  render={({ field: { value } }) => (
                    <FormInput
                      label={t('formInput.firstName')}
                      id={'first_name'}
                      placeholder={t('placeHolder.firstName')}
                      {...register('first_name')}
                      error={errors?.first_name?.message}
                      onChange={(event: any) => {
                        event.target.value = event.target.value.replace('  ', ' ');
                        updateBindingsChange(event, 'first_name');
                      }}
                      value={value}
                      inputFocus={() => trigger('first_name')}
                      readonly={disabledTheFirstNameField}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="last_name"
                  render={({ field: { value } }) => (
                    <FormInput
                      id={'lastName'}
                      label={t('formInput.lastName')}
                      placeholder={t('formInput.lastName')}
                      {...register('last_name')}
                      error={errors?.last_name?.message}
                      onChange={(event: any) => {
                        event.target.value = event.target.value.replace('  ', ' ');
                        updateBindingsChange(event, 'last_name');
                      }}
                      value={value}
                      inputFocus={() => trigger('last_name')}
                      readonly={disabledTheLastNameField}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { value } }) => (
                    <FormInput
                      label={t('formInput.email')}
                      id={'email'}
                      placeholder={t('placeHolder.emailAddress')}
                      {...register('email')}
                      inputMode="email"
                      error={errors?.email?.message}
                      onChange={(event: any) => {
                        updateBindingsChange(event, 'email');
                      }}
                      value={value}
                      inputFocus={() => trigger('email')}
                      readonly={
                        !allowEditEmail &&
                        setVerifiedOnlyForSetting &&
                        value !== '' &&
                        value !== null
                      }
                    />
                  )}
                />
                {webauthnEnabled && <FormInPutChangePassword email={emailId} />}
                <Controller
                  control={control}
                  name="phone_number"
                  render={({ field: { value } }) => (
                    <FormInputPhoneNumber
                      label={t('formInput.phone')}
                      id={'phone'}
                      placeholder={'(123) 543-3454'}
                      name={'phone_number'}
                      onChange={onChangeHandler}
                      inputError={errors.phone_number?.message || errorStatePhone}
                      inputMode="numeric"
                      value={value}
                      inputFocus={() => trigger('phone_number')}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="dob"
                  render={({ field: { value } }) =>
                    setVerifiedOnlyForSetting && value !== '' && value !== null ? (
                      <FormInputDate
                        label={t('formInput.dob')}
                        id={'dob'}
                        {...register('dob')}
                        onChange={(event: any) => {
                          onChangeHandlerDate(event);
                        }}
                        inputMode="numeric"
                        inputFocus={(e: any) => {
                          trigger('dob');
                        }}
                        value={value}
                        readonly={true}
                      />
                    ) : (
                      <FormInputDate
                        label={t('formInput.dob')}
                        id={'dob'}
                        {...register('dob')}
                        inputError={errors.dob?.message}
                        onChange={(event: any) => {
                          onChangeHandlerDate(event);
                        }}
                        inputMode="numeric"
                        inputFocus={(e: any) => {
                          trigger('dob');
                        }}
                        value={value || ''}
                      />
                    )
                  }
                />
                <AddressFields
                  setAddressStates={setAddressStates}
                  addressStates={addressStates}
                  register={register}
                  errors={errors}
                  control={control}
                  getValue={getValues}
                  setValue={setValue}
                  trigger={trigger}
                  isVerifiedOnlyForSetting={setVerifiedOnlyForSetting}
                />

                {(!isSettings || !isVerified) && (
                  <>
                    <FormInputSSN
                      label={t('formInput.ssn')}
                      id={'securityNumber'}
                      only4={!allowFullSSN}
                      {...register('idNumber')}
                      error={errors?.idNumber?.message}
                      inputError={errors?.idNumber?.message}
                      onChange={(event: any) => {
                        updateBindingsChange(event, 'idNumber');
                      }}
                      replace={enableSSNUpdate}
                      setEnableSSNUpdate={setEnableSSNUpdate}
                      inputFocus={() => trigger('idNumber')}
                      ssnNotProvided={!ssnLast4AlreadyProvided}
                      ssnVerificationFailed={ssnVerificationFailed}
                    />
                  </>
                )}

                {isSettings && (
                  <>
                    {isVerified && (
                      <Box
                        component={'div'}
                        paddingY={0}
                        marginBottom={3}
                        display={'flex'}
                        alignItems={'center'}
                      >
                        <Typography
                          variant="h1"
                          color={'gray.700'}
                          className="ssn_provided_text"
                          sx={{ marginRight: 2 }}
                        >
                          {t('reviewCardtexts.idProvidedLast4Text')}
                        </Typography>
                        <AccountVerificationStatusses type="verified" />
                      </Box>
                    )}

                    {(confirmUnVerificationDocumentSubmitted ||
                      confirmVerificationDocumentSubmitted) && (
                      <Box
                        component={'div'}
                        paddingY={0}
                        marginBottom={3}
                        display={'flex'}
                        alignItems={'center'}
                      >
                        <Typography
                          variant="h1"
                          color={'gray.700'}
                          className="ssn_provided_text"
                          sx={{ marginRight: 2 }}
                        >
                          {t('linkTexts.business.providedIDDoc')}
                        </Typography>
                        <AccountVerificationStatusses type="verified" />
                      </Box>
                    )}
                  </>
                )}

                {errorState && (
                  <Messages
                    topMargin={0}
                    bottomMargin={4}
                    messageHeading={
                      errorState.includes('phone')
                        ? t('errorMessages.phoneUniqueError.heading')
                        : errorState.includes('email')
                        ? t('errorMessages.emailUniqueError.heading')
                        : errorState.includes('P.O. Box')
                        ? t('errorMessages.invalidAddressError.heading')
                        : errorState
                    }
                    messageContent={
                      errorState.includes('phone')
                        ? t('errorMessages.phoneUniqueError.content')
                        : errorState.includes('email')
                        ? t('errorMessages.emailUniqueError.content')
                        : errorState.includes('P.O. Box')
                        ? errorState
                        : 'Please provide valid details to use PayUp.'
                    }
                    closeEvent={() => setErrorState('')}
                  />
                )}
              </Box>

              <Box className="update_wrapper_bottom">
                {!isSettings && (
                  <Button
                    variant="text"
                    color="primary"
                    type="button"
                    sx={{ overflow: 'hidden', fontWeight: 600 }}
                    onClick={onCancelButton}
                  >
                    {t('buttonTexts.cancelButton')}
                  </Button>
                )}
                <Button
                  variant="containedLarge"
                  color="primary"
                  type="submit"
                  sx={{ overflow: 'hidden', width: isSettings ? '100%' : 'auto' }}
                  onClick={() => {
                    setBtnSubmitted(true);
                  }}
                >
                  {!isSettings ? t('buttonTexts.updateButton') : t('buttonTexts.submitButton')}
                </Button>
              </Box>
            </UpdateCardWrapper>
          </Box>
        </UpdateFormWrapper>
      }
    </>
  );
};
