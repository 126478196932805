import { Container } from '@mui/material';
import { WrapperProps } from 'src/models/component/base.model';
import { WrpperAuthorized } from '../../CommonNestedStyles/FormWrapper.style';
import { WrapperContainer } from './Wrapper.style';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { hideTabsOnNativeApp } from 'src/shared/config/config';

export const Wrapper = ({
  type = 'sm',
  children,
  isFullScreen = false,
  isAuthorizedWrapper,
  iAuthorizedHeaderDesktopFixed = true,
  isTabNavigationAvailable = false,
  isSettingDesktop,
  idSelector = 'wrapperContainer',
}: WrapperProps) => {
  const isOnNativeApp = useSelector((state: RootState) => state.appState);

  // handle Authorized screens wrapper classes __
  const handleAuthorizedWrapperClass = iAuthorizedHeaderDesktopFixed
    ? 'header_desktop_fixed'
    : isSettingDesktop
    ? 'setting_desktop_wrapper'
    : null;
  // handle wrapper height, if tab navigation available __
  const handleTabNavContainWrapperClass = isTabNavigationAvailable ? 'tab_available' : null;

  // handle wrapper height if loading in the native app __
  const handleClassIfLoadingInNativeApp =
    isOnNativeApp && hideTabsOnNativeApp ? 'is-native-wrapper' : null;

  /**
   * Return primary Ui interaction
   */
  return (
    <>
      {!isAuthorizedWrapper && (
        <WrapperContainer id={idSelector}>
          <Container
            maxWidth={type}
            sx={{
              height: 'auto',
              position: 'relative',
            }}
          >
            {children}
          </Container>
        </WrapperContainer>
      )}

      {isAuthorizedWrapper && (
        <WrpperAuthorized
          className={classNames([
            handleAuthorizedWrapperClass,
            handleTabNavContainWrapperClass,
            handleClassIfLoadingInNativeApp,
          ])}
          id={idSelector}
        >
          {children}
        </WrpperAuthorized>
      )}
    </>
  );
};
