import { styled } from '@mui/material/styles';

export const StepOneContentContainer = styled('div')(
  ({ theme }) => `
   display: flex;
   flex-direction: column;\

   @media (max-width: ${theme.breakpoints.values.sm}px) {
    min-height: 300px;
   }

   @media (min-width: ${theme.breakpoints.values.sm}px) {
    min-height: 300px;
   }
`
);

export const FinanceTermsContent = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-bottom: 12px;

  * {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray[700]};
  }
`
);

export const FinanceTermsAccordianWrapper = styled('div')(
  ({ theme }) => `
 display: flex;
 flex-direction: column;
 width: 100%;
 padding-bottom: 200px;
`
);

export const TermsLink = styled('a')(
  ({ theme }) => `
    font-weight: 600;
    font-size: 16px;
    color: ${theme.palette.primary.main};
    cursor: pointer;
    margin-bottom: ${theme.spacing(4)};
    display: inline-flex;
    align-items: center;
  `
);
