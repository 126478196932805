import { AccountParams, FormAddressProps } from '../../shared/utilities/interface';
import { IRelationShip } from '../api/account';

const maxDate = new Date();
maxDate.setFullYear(maxDate.getFullYear() - 13);

export const Document = {
  front: undefined,
  back: undefined,
};

export const Verification = {
  document: Document,
};

export const initAccount: AccountParams = {
  business_type: 'individual',
  city: '',
  dob: '',
  email: '',
  first_name: '',
  idNumber: undefined,
  inputDescription: '',
  inputMCC: '',
  last_name: '',
  line1: '',
  line2: '',
  phone_number: '',
  postal_code: '',
  state: '',
  isRep: false,
  ownership: 'owner',
  inputOwnerTitle: '',
  inputComLine1: '',
  inputComLine2: '',
  inputComCity: '',
  inputComState: '',
  inputComPostal: '',
  inputCompanyName: '',
  inputTaxId: '',
  inputCompanyPhone: '',
  inputBusinessName: '',
  employerId: '',
  inputJobTitle: '',
  checkboxSameAddress: '',
  checkboxSharePercentage: '',
  front: undefined,
  back: undefined,
  documentType: undefined,
  phone_number_2: '',
  primary_contact_name: '',
  display_name: '',
};

export const ErrorMsg = {
  message: '',
};

export const Address = {
  city: '',
  line1: '',
  line2: '',
  postal_code: '',
  state: '',
};

export const DOB = {
  day: '',
  month: '',
  year: '',
};

export const Metadata = {
  documentType: undefined,
};

export const Result = {
  data: null,
  errorMessage: ErrorMsg,
};

export const BusinessProfile = {
  mcc: '',
  name: '',
  product_description: '',
};

export const ErrorReq = {
  code: '',
  reason: '',
  requirement: '',
};

export const Individual = {
  dob: DOB,
  address: Address,
  first_name: '',
  last_name: '',
  ssn_last4_provided: false,
  email: '',
  phone: '',
  Verification: Verification,
  metadata: Metadata,
};

export const Company = {
  name: '',
  Verification: Verification,
  metadata: Metadata,
};

export const BankInit = {
  account_holder_name: '',
  routing_number: '',
  account_number: '',
  account_number_conf: '',
  id: '',
  account: '',
};

export const ResultObject = {
  business_profile: BusinessProfile,
  errors: [ErrorReq],
  individual: Individual,
  business_type: '',
  id: '',
  company: Company,
};

export const InitInfo = {
  response: {
    data: {
      company: {
        phone: null,
        name: null,
      },
      individual: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        dob: null,
        address: {
          state: null,
          city: null,
          line1: null,
          line2: null,
          postal_code: null,
        },
        relationShip: {
          title: null,
        },
      },
    },
  },
};

export const DetailsForPhoneVerification = {
  type: 'individual',
  phoneVerified: false,
  phoneWorkVerified: false,
  additionalPhoneVerified: false,
  phone: '',
  phoneWork: '',
  additionalPhone: '',
};

export const addressDefaultValues: FormAddressProps = {
  line1: '',
  line2: '',
  city: '',
  state: '',
  postal_code: '',
};

export const initialRelationShipObject: IRelationShip = {
  director: false, // This person is a member of the governing board of the company: relationship[director]
  executive: false, // This person is an executive or senior manager with significant management responsibility: relationship[executive]
  owner: false, // This person owns 25% or more of the company: relationship[owner]
  percent_ownership: null,
  representative: false, // This person is authorized as the primary representative of the account: relationship[representative]
};
