import moment from 'moment';
import timezone from 'moment-timezone';
import { DOB } from './interface';

export function DOBtoDate(dob: any) {
  try {
    const d = parseInt(dob?.day) < 10 ? `0${dob.day}` : dob.day;
    const m = parseInt(dob.month) < 10 ? `0${dob.month}` : dob.month;
    return `${m} / ${d} / ${dob.year}`;
  } catch (e) {
    return null;
  }
}

export function DOBView(dob: any) {
  try {
    const d = parseInt(dob?.day) < 10 ? `0${dob.day}` : dob.day;
    const m = parseInt(dob.month) < 10 ? `0${dob.month}` : dob.month;
    return `${dob.year}/${m}/${d}`;
  } catch (e) {
    return null;
  }
}
export const getFormattedDate = (date: any) => {
  const d = new Date(DOBView(date) as string);
  const currentDOB = moment(d).format('MMMM DD, YYYY'); // June 1, 2019
  return currentDOB;
};

export const getEarningFormattedDate = (date: any) => {
  const d = new Date(date as string);
  return moment(d).format('MM/DD/YYYY'); // 03/24/2019
};

export const dateFormatWithTimeZoneForEarning = (date: any) => {
  return moment(date).format('MMM DD, YYYY ');
};

export const dateFormatMMMSpaceDD = (date: any) => {
  return moment(date).format('MMM DD');
};

export const dateFormatMMMSpaceDDYYYY = (date: any) => {
  return moment(date).format('MMM DD, YYYY');
};

export const timeFormatWithoutTimeZoneForEarning = (date: any) => {
  return moment(date).format('hh:mm A');
};

export const dateFormatWithYear = (date: any) => {
  return timezone(date).format('MMM D, YYYY');
};

export const dateFormatWithMonth = (date: any) => {
  return timezone(date).format('MMM D');
};

export const getArrivalTimeforInstantPayment = (date: any) => {
  return moment(date).add(1, 'hour').format('hh:mm A');
};

export const getCalculatedPayArrivalDate = (dateValue: string): string | null => {
  if (dateValue) {
    const date = moment(dateValue).add(1, 'day').format();
    return date;
  }

  return null;
};

export const isNullDOB = (dob: DOB) => {
  return !dob.day || !dob.month || !dob.year;
};

export const onChangeHandlerDateInput = (val: any, setValue: any, setErrorManualDateType: any) => {
  if (val) {
    const dateMoment = moment(val);
    const toDate = moment(val).toDate();
    const dateWithFormat = moment(toDate).format('MM/DD/yyyy');
    if (dateWithFormat === 'Invalid date') {
      setErrorManualDateType(true);
    }
    if (
      !dateMoment?.isValid() ||
      !toDate?.getFullYear() ||
      toDate?.getFullYear() < 1900 ||
      (toDate?.getFullYear() > new Date().getFullYear() && val.length !== 10)
    ) {
      setErrorManualDateType(false);
    } else {
      const formattedDate = moment(toDate).format('MM/DD/yyyy');
      const years = moment().diff(formattedDate, 'years', true);
      if (years < 18) {
        val.length === 10 ? setErrorManualDateType(true) : setErrorManualDateType(false);
      } else {
        val.length < 9 ? setErrorManualDateType(false) : setErrorManualDateType(false);
      }
    }
  }
  setValue('dob', val); // Set value
};

export const isNot18 = (val: any) => {
  const toDate = moment(val).toDate();
  const dateWithFormat = moment(toDate).format('MM/DD/yyyy');
  const years = moment().diff(dateWithFormat, 'years', true);
  if (years < 18) {
    return val.length === 10 ? false : true;
  }
  return true;
};

export const isValidDOB = (value: any) => {
  const dateMoment = moment(value);
  return dateMoment?.isValid();
};
