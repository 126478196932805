import { useTranslation } from 'react-i18next';
import { InformationListProps } from 'src/models/component/base.model';
import { InformationListBlockOuterWrapper } from './InformationList.style';
import { InformationListItem } from './InformationListItem/InformationListItem';
import { ShadowCard } from '../../CommonNestedStyles/FormWrapper.style';
import { Box, Typography } from '@mui/material';

export const InformationList = ({
  title,
  onClick,
  data,
  navType = 'settings',
  cardMarginTop = 6,
  cardPaddingRight = 4,
  cardPaddingLeft = 4,
  cardPaddingBottom = 4,
  cardPaddingTop = 4,
}: InformationListProps) => {
  const { t } = useTranslation();

  return (
    <InformationListBlockOuterWrapper className={navType}>
      <ShadowCard
        display={'flex'}
        flexDirection={'column'}
        paddingTop={cardPaddingTop}
        paddingBottom={cardPaddingBottom}
        paddingLeft={cardPaddingLeft}
        paddingRight={cardPaddingRight}
        marginTop={cardMarginTop}
      >
        {title && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              cursor: 'pointer',
              width: '100%',
            }}
          >
            <Typography
              component="p"
              variant="h2ExtraBold"
              textTransform={'uppercase'}
              sx={{
                marginBottom: 2,
                display: 'inline-flex',
                alignItems: 'center',
                color: 'gray.500',
              }}
            >
              {title}
            </Typography>
          </Box>
        )}
        {data &&
          data.map((option: any, index: any) => {
            return (
              !option.isHidden && (
                <InformationListItem
                  key={index}
                  title={option.title}
                  onClick={onClick}
                  data={''}
                  icon={option.icon}
                  buttonEnable={option.buttonDetails.buttonEnable}
                  color={option.buttonDetails.color}
                  buttonText={option.buttonDetails.buttonText}
                  id={option.id}
                  subTitle={option.subTitle ?? null}
                />
              )
            );
          })}
      </ShadowCard>
    </InformationListBlockOuterWrapper>
  );
};
