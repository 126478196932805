import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Box, Button, Typography } from '@mui/material';
import {
  Header,
  HeadingSummaryBlock,
  FormWrapper,
  ContentContainerBottom,
  FormInput,
  TermsText,
  FormInputPhoneNumber,
  Messages,
  FormEINInput,
  ManualLoader,
} from 'src/components/base/core';
import { PhoneVerifyCodeContainer } from '../../../VerifyCode/VerifyCode';

import { theme, commonSpacing } from 'src/shared/theme/theme';
import { AccountParams } from 'src/shared/utilities/interface';
import { getCookie } from 'src/shared/utilities/cookiesHelper';
import dtoToFormValues from 'src/shared/utilities/dto';

import { handleVerifyPhone } from 'src/helpers/helperPhoneVerificaition';
import { setCoupaInfoBusinessDetails, setCoupaInfoPhone } from 'src/helpers/setCoupaValues';
import { handleBusinessInfo } from 'src/helpers/containers/helperBusinessInfo';

import { initAccount } from 'src/models/init/account.init';
import { ERROR_MESSAGES } from 'src/constants/Validation';

import { useGetBlockHeight } from 'src/hooks/useGetBlockHeight';

export const FormBusinessInformationSubmition = ({
  onBackButtonClick,
  onFormSubmit,
  accountData,
  setAccountData,
  isChangeBusinessType,
  isBackFromDashboard,
}: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { webauthnEnabled, disabledPhoneVerificationStep } = useFlags();

  /**
   * set states using useState react hooks
   */
  const [formData, setFormData] = useState<AccountParams>(initAccount);
  const [verifyCodeContainerVisible, setVerifyCodeContainerVisible] = useState(false);
  const [enableEIN, setEnableEIN]: any = useState(true);
  const [enableBack, setEnableBack]: any = useState(true);
  const [btnSubmitted, setBtnSubmitted] = useState(false);

  /**
   * call the cutome hooks and helper function
   */
  const [handleVerificationCode, verifiedPhoneNumber, errorState, isPhoneVerificationLoading] =
    handleVerifyPhone();
  const [elementRef, height] = useGetBlockHeight();
  const [formSubmit, isLoading, onChangeHandlerPhone, onChangeHandlerTax, serverSideErrors]: any =
    handleBusinessInfo(accountData, setAccountData);

  /**
   * render data in the initial load
   */
  useEffect(() => {
    // if account is initialized set the input values using setValue method
    if (accountData) {
      dtoToFormValues(setValue, accountData);

      if (accountData?.individual?.verification?.status === 'verified') {
        setEnableBack(false);
      }
      if (accountData?.company?.tax_id_provided) {
        setEnableEIN(false);
      }
    }

    // prefill info with coupa
    if (!accountData && getCookie('coupaObj')) {
      if (webauthnEnabled) {
        // email authentication flow __
        setCoupaInfoBusinessDetails(setValue);
      } else {
        // phone number autherntication flow __
        setCoupaInfoPhone(setValue);
      }
    }
  }, [accountData]);

  /**
   * modify init for custom form validation
   */
  const schemaObj = {
    inputTaxId: yup
      .string()
      .test('required', ERROR_MESSAGES.REQUIRED, (value) => {
        if (!enableEIN || value) return true;
        return false;
      })
      .test('min', ERROR_MESSAGES.EIN_INVALID, (value) => {
        if ((value && value?.length === 9) || !enableEIN) return true;
        return false;
      }),
    inputCompanyName: yup.string().required(ERROR_MESSAGES.REQUIRED),
    inputCompanyPhone: yup
      .string()
      .required(ERROR_MESSAGES.REQUIRED)
      .min(14, ERROR_MESSAGES.PHONE_NUMBER_INVALID),
  };

  // update with custom validation
  const schema = yup.object(schemaObj).required();

  /**
   * initialize react hook form
   */
  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<AccountParams>({
    resolver: yupResolver(schema),
    defaultValues: initAccount,
    mode: 'onSubmit',
  });

  /**
   * function for skip the phone number otp verification and submit the data
   */
  const skipTheOTPVerification = (data: any) => {
    formSubmit(data, onFormSubmit, isChangeBusinessType);
    setFormData(initAccount);
    setVerifyCodeContainerVisible(false);
  };

  /**
   * function to handele the phone number otp verification and submit the data
   */
  const moveToOTPVerification = (data: any) => {
    setFormData(data);
    // @ts-ignore
    handleVerificationCode(data, getValues, setVerifyCodeContainerVisible, 'business_info');
  };

  /**
   * onSubmit form function
   */
  const onAppFormSubmit = handleSubmit(async (data: any) => {
    if (webauthnEnabled && !disabledPhoneVerificationStep) {
      if (accountData && accountData.company.phone) {
        if (accountData.company.phone.includes(data?.inputCompanyPhone?.replace(/[^0-9]/g, ''))) {
          skipTheOTPVerification(data);
        } else {
          moveToOTPVerification(data);
        }
      } else {
        moveToOTPVerification(data);
      }
    } else {
      skipTheOTPVerification(data);
    }
  });

  // set the updatated formfiedls value useing setValue method
  const updateBindingsChange = (event: any, feild: any) => {
    setValue(feild, event.target.value);
  };

  // this function used to handle the phone number field onchange
  const onChangeHandler = (event: any) => {
    onChangeHandlerPhone(event, getValues, setValue, btnSubmitted);
  };

  // this function used to handle the EIN tax id field onchange
  const onChangeHandlerTaxValue = (event: any) => {
    onChangeHandlerTax(event, getValues, setValue, btnSubmitted);
  };

  /**
   * Primary Ui interaction if verifyCodeContainerVisible is true
   */
  if (verifyCodeContainerVisible) {
    return (
      <PhoneVerifyCodeContainer
        phone={verifiedPhoneNumber}
        setVerifyCodeContainerVisible={setVerifyCodeContainerVisible}
        onSubmitFunction={() =>
          formSubmit(formData, onFormSubmit, setAccountData, isChangeBusinessType)
        }
        phoneWithMask={formData.inputCompanyPhone}
      />
    );
  }

  /**
   * Defaul Ui interaction
   */
  return (
    <>
      {(isLoading || isPhoneVerificationLoading) && <ManualLoader />}
      <FormWrapper onSubmit={onAppFormSubmit} className="no_fullscreen">
        <Box component={'div'} className={'container_content bottom_terms_padding terms_note'}>
          <Box
            component={'div'}
            className={'container_content_top terms_note'}
            ref={elementRef}
            sx={{
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                minHeight: height,
                paddingBottom: height ? 4 : commonSpacing.desktopPaddingB,
              },
            }}
          >
            <Header
              isBackButtonVisible={enableBack}
              onBackButtonClick={onBackButtonClick}
              isProgressBarVisible={true}
              totalProgressSteps={5}
              currentProgressStep={1}
              paddingX={0}
              backButtonId={'BusinessInfoBack'}
            />
            <Box sx={{ marginBottom: 6 }}>
              <HeadingSummaryBlock
                heading={t('headingContent.business.businessAddress.heading')}
                headingAlignment="left"
                content={t('headingContent.business.businessAddress.content')}
                contentAlignment="left"
              />
            </Box>
            <Controller
              control={control}
              name="inputCompanyName"
              render={({ field: { value } }) => (
                <FormInput
                  label={t('formInput.comapanyName')}
                  id={'company_name'}
                  placeholder={t('placeHolder.comapnyName')}
                  {...register('inputCompanyName')}
                  error={errors?.inputCompanyName?.message}
                  onChange={(event: any) => {
                    updateBindingsChange(event, 'inputCompanyName');
                  }}
                  inputFocus={(e: any) => {
                    trigger('inputCompanyName');
                  }}
                  value={value}
                />
              )}
            />
            {!isBackFromDashboard && (
              <Controller
                control={control}
                name="inputTaxId"
                render={({ field: { value } }) => (
                  <FormEINInput
                    label={t('formInput.ein')}
                    id={'ein'}
                    placeholder={'00-00000000'}
                    {...register('inputTaxId')}
                    error={errors?.inputTaxId?.message}
                    inputError={errors?.inputTaxId?.message}
                    onChange={onChangeHandlerTaxValue}
                    value={value}
                    replace={enableEIN}
                    setEnableEIN={setEnableEIN}
                    inputMode="numeric"
                    inputFocus={(e: any) => {
                      trigger('inputTaxId');
                    }}
                  />
                )}
              />
            )}
            <Controller
              control={control}
              name="inputCompanyPhone"
              render={({ field: { value } }) => (
                <FormInputPhoneNumber
                  label={t('formInput.phone')}
                  id={'inputCompanyPhone'}
                  placeholder={'(123) 543-3454'}
                  {...register('inputCompanyPhone')}
                  inputError={errors.inputCompanyPhone?.message || errorState}
                  onChange={onChangeHandler}
                  inputMode="numeric"
                  value={value}
                  inputFocus={(e: any) => {
                    trigger('inputCompanyPhone');
                  }}
                />
              )}
            />

            {enableBack && (
              <Box component={'div'} paddingY={2}>
                <Typography
                  variant="h2"
                  color={'primary'}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => navigate('/sign-up')}
                >
                  {t('linkTexts.business.ssnRedirectLink')}
                </Typography>
              </Box>
            )}
            {serverSideErrors && (
              <Messages
                topMargin={0}
                bottomMargin={0}
                messageHeading={
                  serverSideErrors && serverSideErrors.includes('phone')
                    ? t('errorMessages.phoneUniqueError.heading')
                    : serverSideErrors.includes('email')
                    ? t('errorMessages.emailUniqueError.heading')
                    : serverSideErrors
                }
                messageContent={
                  serverSideErrors.includes('phone')
                    ? t('errorMessages.phoneUniqueError.content')
                    : serverSideErrors.includes('email')
                    ? t('errorMessages.emailUniqueError.content')
                    : 'Please provide the valide details to use PayUp.'
                }
              />
            )}
          </Box>
          <Box component={'div'} className="container_content_bottom terms_note">
            <TermsText
              mainContent={t('noticeBlocks.termsBlock.heading')}
              subContent={t('noticeBlocks.termsBlock.content')}
            />
          </Box>
        </Box>
        <ContentContainerBottom className={'no_note no_fullscreen'}>
          <Button
            variant="containedLarge"
            color="primary"
            type="submit"
            fullWidth
            sx={{ overflow: 'hidden' }}
            onClick={() => {
              setBtnSubmitted(true);
            }}
          >
            {t('buttonTexts.continueButton')}
          </Button>
        </ContentContainerBottom>
      </FormWrapper>
    </>
  );
};
