import React from 'react';
import { Accordion } from 'src/components/base/core/OtherUtilities/Accordions/Accordion/Accordion';
import { FilterTag } from '../FilterTag/FilterTag';
import { FilterContentWrapper } from '../FilterModal/FilterModal.style';
import { GridContext } from 'src/contexts/gridContext';
import { useTranslation } from 'react-i18next';
import { invoiceBatchTabsType } from 'src/shared/utilities/interface';

export interface DataSortingProps {
  handleOnChange?: any;
  selectedOption?: any;
  tabType?: invoiceBatchTabsType;
  isTransactionGrid?: boolean;
}

export const DataSorting = ({
  handleOnChange,
  selectedOption,
  tabType,
  isTransactionGrid,
}: DataSortingProps) => {
  const handleTerms = (_tabType?: string) => {
    switch (_tabType) {
      case 'submitted':
        return isTransactionGrid ? 'CREATED' : 'INVOICED';
      case 'paid':
        return 'PAID';
      case 'transactions':
        return 'PAID';
      case 'approved':
        return 'APPROVED';
      default:
        break;
    }
  };
  const gridContext = React.useContext(GridContext);
  const isFilterActive = gridContext?.filterStatus?.isSortBFilterActive;
  const { t } = useTranslation();

  return (
    <Accordion
      title={t('earning.filter.sortFilter.title')}
      isFilterActive={isFilterActive}
      isNoBorder={true}
    >
      <FilterContentWrapper>
        <FilterTag
          inputName={'sort'}
          title={t('earning.filter.sortFilter.date')}
          idValue={'dateDESC'}
          iconType="SortingIcon"
          inputValue={`${handleTerms(tabType)}_AT_DESC`}
          handleOnChange={handleOnChange}
          className={'sorting_tag'}
          selectedOption={selectedOption}
        />
        <FilterTag
          inputName={'sort'}
          title={t('earning.filter.sortFilter.date')}
          inputValue={`${handleTerms(tabType)}_AT_ASC`}
          idValue={'dateASC'}
          iconType="SortingIconDSC"
          handleOnChange={handleOnChange}
          className={'sorting_tag'}
          selectedOption={selectedOption}
        />
        {isTransactionGrid ? null : (
          <FilterTag
            inputName={'sort'}
            title={'Net'}
            inputValue={'PAYMENT_TERM_BY_PAYOUT_TERM_ID__DUE_DAYS_ASC'}
            idValue={'netASC'}
            iconType="SortingIcon"
            handleOnChange={handleOnChange}
            className={'sorting_tag'}
            selectedOption={selectedOption}
          />
        )}
        {isTransactionGrid ? null : (
          <FilterTag
            inputName={'sort'}
            title={'Net'}
            inputValue={'PAYMENT_TERM_BY_PAYOUT_TERM_ID__DUE_DAYS_DESC'}
            idValue={'netDESC'}
            iconType="SortingIconDSC"
            handleOnChange={handleOnChange}
            className={'sorting_tag'}
            selectedOption={selectedOption}
          />
        )}
        <FilterTag
          inputName={'sort'}
          title={t('earning.filter.sortFilter.amount')}
          idValue={'amountASC'}
          iconType="SortingIcon"
          inputValue={isTransactionGrid ? 'AMOUNT_ASC' : 'PAYOUT_AMOUNT_ASC'}
          className={'sorting_tag'}
          handleOnChange={handleOnChange}
          selectedOption={selectedOption}
        />
        <FilterTag
          inputName={'sort'}
          title={t('earning.filter.sortFilter.amount')}
          idValue={'amountDESC'}
          iconType="SortingIconDSC"
          inputValue={isTransactionGrid ? 'AMOUNT_DESC' : 'PAYOUT_AMOUNT_DESC'}
          className={'sorting_tag'}
          handleOnChange={handleOnChange}
          selectedOption={selectedOption}
        />
      </FilterContentWrapper>
    </Accordion>
  );
};
