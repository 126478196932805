import { styled } from '@mui/material/styles';

export const Container = styled('div')(
  ({ theme }) => `
  flex-direction: column;
  margin-left: ${theme.spacing(1)};
`
);

export const ConnectorLine = styled('div')<{ isActive: boolean }>(
  ({ theme, isActive }) => `
  width: 1px;
  height: 24px;
  margin-left: ${theme.spacing(3)};
  background: ${isActive ? theme.palette.blue[100] : theme.palette.gray[300]};
  opacity: ${isActive ? 1 : 0.6};
`
);

export const SecondRow = styled('div')`
  flex-direction: row;
  flex: 1;
  display: flex;
`;

export const DetailsSection = styled('div')(
  ({ theme }) => `
  flex-direction: column;
  display: flex;
  margin-left: ${theme.spacing(4)};
`
);

export const BottomSection = styled('div')`
  flex-direction: row;
  flex: 1;
  display: flex;
  align-items: center;
`;
