import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Box, Button } from '@mui/material';
import { HeadingSummaryBlock } from '../..';

import { ListImage, SplashScreenListViewWrapper } from './SplashScreenListView.style';

import { splashScreenItems } from 'src/shared/content/content';
import { Images } from 'src/shared/utilities/images';

export const SplashScreenListView = ({ onEnableFinaceAccount }: any) => {
  const { t, i18n } = useTranslation();
  const [items] = splashScreenItems({ t: t });

  return (
    <SplashScreenListViewWrapper>
      <Box
        display="flex"
        width={'100%'}
        justifyContent={'center'}
        flexDirection={'column'}
        marginBottom={8}
      >
        <Box
          component="div"
          sx={{
            color: 'gray.800',
            marginBottom: 8,
            textAlign: 'center',
            maxWidth: i18n.resolvedLanguage !== 'en' ? 370 : 350,
            width: '100%',
            marginX: 'auto',
          }}
        >
          <Typography
            variant="h8ExtraBold"
            component="span"
            sx={{ color: 'blue.500', fontWeight: 700, letterSpacing: '-2px' }}
          >
            {t('finance.splashListView.headingContent1')}
          </Typography>
          <Typography
            variant="h8ExtraBold"
            component="span"
            sx={{ color: 'gray.800', fontWeight: 700, letterSpacing: '-2px' }}
          >
            {t('finance.splashListView.headingContent2')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 8,
            width: '100%',
          }}
        >
          <ListImage
            src={`${Images.Finance.TOP_BANNER}`}
            alt={'Financial section top banner'}
            loading="lazy"
          />
        </Box>
        <Button
          variant="containedLarge"
          color="primary"
          fullWidth
          id={'Finance'}
          onClick={onEnableFinaceAccount}
          sx={{
            marginBottom: 2,
            maxWidth: i18n.resolvedLanguage !== 'en' ? 370 : 250,
            marginX: 'auto',
          }}
        >
          {t('finance.splashListView.button')}
        </Button>
      </Box>
      {items.map((item: any, i: any) => (
        <Fragment key={i}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 4,
              width: '100%',
            }}
          >
            <ListImage src={item.imagePath} alt={item.heading} loading="lazy" />
          </Box>
          <Box
            component={'div'}
            sx={{
              marginX: 'auto',
              width: i18n.resolvedLanguage !== 'en' ? 370 : 327,
              marginBottom: 10,
            }}
          >
            <HeadingSummaryBlock
              heading={item.heading}
              content={item.content}
              headingAlignment="center"
              contentAlignment="center"
              spacing={1}
            />
          </Box>
        </Fragment>
      ))}
    </SplashScreenListViewWrapper>
  );
};
