import { Box, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { ShadowCard } from '../../CommonNestedStyles/FormWrapper.style';
import { FinanceBankInfoCard } from '../FinanceBankInfoCard/FinanceBankInfoCard';
import { ManualLoader } from '../../OtherUtilities/Loader/ManualLoader';

import { useCurrencyFormatWithoutSymbol } from 'src/hooks/useCurrencyFormat';
import { useGetTransactionDetailsQuery } from 'src/services/account/accountServiceApi';

import { theme } from 'src/shared/theme/theme';
import { IFinanceMoveMoneySummaryCard } from 'src/models/component/base.model';

export const FinanceMoveMoneySummaryCard = ({
  currentBalance,
  isAmountBlockVisible = false,
  t,
}: IFinanceMoveMoneySummaryCard) => {
  const getStorageBankDetails: any = localStorage.getItem('link-bank-details');
  const getLinkedAccountDetails = JSON.parse(getStorageBankDetails);

  const {
    data: faData,
    isSuccess: isSuccessFaData,
    isLoading: isLoadingFaData,
  } = useGetTransactionDetailsQuery(true);

  return (
    <>
      {isLoadingFaData && <ManualLoader />}
      {isSuccessFaData && (
        <ShadowCard marginBottom={6} paddingY={4} display={'flex'} flexDirection={'column'}>
          {isAmountBlockVisible && (
            <Box
              display={'flex'}
              marginBottom={4}
              paddingX={4}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Typography variant="h1Bold" color="gray.600" marginBottom={1}>
                {t('finance.MoveMoney.financeMoveMoneySummaryCard.topTitle')}
              </Typography>
              <Box display={'flex'} width={'100%'} justifyContent={'center'}>
                <Typography component="span" variant="h4Bold" color={'gray.900'} fontWeight={500}>
                  $
                </Typography>
                <Typography
                  component="span"
                  variant="h8ExtraBold"
                  color={'gray.900'}
                  paddingBottom={3}
                >
                  {currentBalance
                    ? useCurrencyFormatWithoutSymbol(currentBalance)
                    : useCurrencyFormatWithoutSymbol(0)}
                </Typography>
              </Box>
              <Box height={'1px'} width={'100%'} sx={{ background: theme.palette.gray[100] }} />
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                flexDirection: 'row',
                justifyContent: 'center',
                paddingX: 4,
                paddingY: 4,
              },
              [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                flexDirection: 'column',
              },
            }}
          >
            <Box
              display={'flex'}
              flexDirection={'column'}
              sx={{
                [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                  width: '100%',
                  marginBottom: 4,
                },
                [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                  width: '220px',
                },
              }}
            >
              <Typography
                component="p"
                variant="h2Bold"
                textTransform={'uppercase'}
                sx={{
                  marginBottom: 2,
                  display: 'inline-flex',
                  alignItems: 'center',
                  color: 'gray.500',
                  paddingX: 4,
                }}
              >
                {t('finance.MoveMoney.financeMoveMoneySummaryCard.fromText')}
              </Typography>
              {isSuccessFaData && (
                <FinanceBankInfoCard
                  bankDetails={{
                    id: faData?.id,
                    account_holder_name: faData?.financial_addresses[0]?.aba?.account_holder_name,
                    last4: faData?.financial_addresses[0]?.aba?.account_number_last4,
                  }}
                  isUpdateEditOptionAvailable={false}
                  isSelected={false}
                  isCardClickable={false}
                  accountNumberPrefixText={t(
                    'finance.MoveMoney.financeMoveMoneySummaryCard.walletText'
                  )}
                  isDefaultTagVisible={false}
                />
              )}
            </Box>
            <Box
              sx={{
                color: theme.palette.gray[600],
                [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                  width: '100%',
                },
                [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                  width: '50px',
                },
              }}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              marginBottom={3}
            >
              <ArrowDownwardIcon
                color={'inherit'}
                sx={{
                  fontSize: '14px',
                  [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                    transform: 'rotate(-90deg)',
                  },
                }}
              />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              sx={{
                [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                  width: '100%',
                },
                [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                  width: '220px',
                },
              }}
            >
              <Typography
                component="p"
                variant="h2Bold"
                textTransform={'uppercase'}
                sx={{
                  marginBottom: 2,
                  display: 'inline-flex',
                  alignItems: 'center',
                  color: 'gray.500',
                  paddingX: 4,
                }}
              >
                {t('finance.MoveMoney.financeMoveMoneySummaryCard.toText')}
              </Typography>
              <FinanceBankInfoCard
                bankDetails={getLinkedAccountDetails}
                isUpdateEditOptionAvailable={false}
                isSelected={false}
                isCardClickable={false}
                isDefaultTagVisible={false}
                accountNumberPrefixText={t(
                  'finance.MoveMoney.financeMoveMoneySummaryCard.accountText'
                )}
              />
            </Box>
          </Box>
        </ShadowCard>
      )}
    </>
  );
};
