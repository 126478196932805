import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AccountParams } from '../../../../../shared/utilities/interface';
import { AddressCityStateZip } from '../../../../../shared/utilities/addressUtils';
import {
  phoneBusinessNumberMask,
  phoneNumberMask,
} from '../../../../../shared/utilities/phoneNumberMask';
import { getFormattedDate } from '../../../../../shared/utilities/dob';

import {
  Header,
  Wrapper,
  HeadingSummaryBlock,
  ReviewCard,
  DivWrapper,
  ContentContainerBottom,
  Messages,
  AccountVerificationStatusses,
  FooterNote,
  ShadowCard,
} from 'src/components/base/core';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';

import { RefreshIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import {
  useGetAccountQuery,
  useUpdateAccountMutation,
} from 'src/services/account/accountServiceApi';
import dtoToFormValues from 'src/shared/utilities/dto';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { trackEvent } from 'src/components/analytics/service';
import { accountCompany } from 'src/models/account/account.service.mapper';
import { FormEditPersonalDetailsSubmition } from './FormEditPersonalDetailsSubmition/FormEditPersonalDetailsSubmition';
import { FormEditBusinesslDetailsSubmition } from './FormEditBusinesslDetailsSubmition/FormEditBusinesslDetailsSubmition';
import { getAditionalPhoneDetails } from 'src/services/account/additional.phone.service';
import { maskedInput } from 'src/shared/utilities/inputHandlers';
import { commonSpacing, theme } from 'src/shared/theme/theme';
import { getUpdateRouteObj, routeType } from 'src/helpers/containers/helperRoutePreference';
import { useUpdatePreferenceMutation } from 'src/services/account/account.preferenceApi';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { pre_fill_params } from 'src/shared/content/content';

export const BusinessReviewContainer = () => {
  const editFormVisibility = {
    personaInfo: true,
    businessInfo: true,
    personaInfoEdit: false,
    businessInfoEdit: false,
  };

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { webauthnEnabled } = useFlags();
  const { data, isFetching } = useGetAccountQuery('');
  const [result, setResult]: any = useState();
  const [detailSummitted, setDetailSummitted] = useState(false);
  const [serverErrorsSSN, setServerErrorsSSN]: any = useState(null);
  const getErrorBlockVisibility = localStorage.getItem('review_error_block');
  const [updateAccount] = useUpdateAccountMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [editFormVisibilityStates, setEditFormVisibilityStates]: any = useState(editFormVisibility);
  const [updateData, setUpdateData]: any = useState();
  const [addtionalNumbers, setAddtionalNumbers]: any = useState();
  const [updatePreference] = useUpdatePreferenceMutation();

  const updatePreferenceOnServer = async (type: any, step: any = null) => {
    const obj = getUpdateRouteObj(type, step);
    await updatePreference({ data: obj });
  };
  //hook-forms
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<AccountParams>();

  React.useEffect(() => {
    if (data) {
      setResult(data);
      localStorage.removeItem('isBack');
      localStorage.removeItem('failed_company');
      if (detailSummitted && data?.tos_shown_and_accepted) {
        localStorage.setItem('enable_holdon', 'true');
        localStorage.removeItem('backFromReview');
        setTimeout(function () {
          setDetailSummitted(false);
          navigate('/hold-on');
        }, 100);
      }
    }
  }, [data]);

  React.useEffect(() => {
    if (updateData) {
      setResult(updateData);
    }
  }, [updateData]);

  React.useEffect(() => {
    dtoToFormValues(setValue, result);

    if (result) {
      const { status, details_code, details } = result?.individual?.verification;
      if (status === 'unverified' && details_code === 'failed_keyed_identity') {
        setServerErrorsSSN(details);
      } else {
        setServerErrorsSSN(null);
      }
      getAditionalPhoneDetails(result?.id).then((result: any) => {
        if (result.data.length > 0) {
          setAddtionalNumbers(maskedInput(result.data[0].phone_number?.replace('+1', '')) ?? '');
        } else {
          setAddtionalNumbers('');
        }
      });
    }
  }, [result]);

  const submitForm = async () => {
    trackEvent('Submit Click on Review');
    setIsLoading(true);
    const postData = getValues();
    const updateFormData = {
      ...postData,
      phone_number: postData?.phone_number?.replace(/[^0-9]/g, ''),
      inputCompanyPhone: postData?.inputCompanyPhone?.replace(/[^0-9]/g, ''),
      inputDescription: pre_fill_params.description,
      inputMCC: pre_fill_params.mcc,
      url: pre_fill_params.url,
      tos_shown_and_accepted: true,
    };
    const updateObj = accountCompany(updateFormData, false, false);
    const accountUpdateResponse = await updateAccount(updateObj);

    // @ts-ignore
    if (
      // @ts-ignore
      accountUpdateResponse?.data?.currently_due_requirements.includes('company.tax_id') && // @ts-ignore
      !accountUpdateResponse?.data?.company?.verification.document.front && // @ts-ignore
      !accountUpdateResponse?.data?.company?.verification.document.back
    ) {
      localStorage.setItem('ein-verification-enabled', 'true');
    }

    // @ts-ignore
    if (accountUpdateResponse?.data) {
      localStorage.removeItem('review_error_block');
      navigate('/hold-on');
    }
    setDetailSummitted(true);
    setIsLoading(false);
  };

  const handleEditIcon = () => {
    localStorage.setItem('isBack', 'true');
    // localStorage.setItem('step', '1');
    navigate('/business-info', {
      state: { isCompany: true, isBack: true },
    });
  };

  const handleEditRep = () => {
    localStorage.setItem('isBack', 'true');
    // localStorage.setItem('step', '2');
    navigate('/business-representative', {
      state: { isCompany: true, isBack: true },
    });
  };

  const handlePersonalInfoEditIcon = () => {
    setEditFormVisibilityStates({
      ...editFormVisibility,
      personaInfo: false,
      personaInfoEdit: true,
    });
  };
  const handleBusinessInfoEditIcon = () => {
    setEditFormVisibilityStates({
      ...editFormVisibility,
      businessInfoEdit: true,
      businessInfo: false,
    });
  };

  const handlePersonalInfoCancelEvent = () => {
    setEditFormVisibilityStates({
      ...editFormVisibility,
      personaInfo: true,
      personaInfoEdit: false,
    });
  };
  const handleBusinessInfoCancelEvent = () => {
    setEditFormVisibilityStates({
      ...editFormVisibility,
      businessInfoEdit: false,
      businessInfo: true,
    });
  };

  const isTaxIDProvided = result?.currently_due_requirements?.includes('company.tax_id');
  const haveCompanyDueReqsAvailable =
    result?.currently_due_requirements?.filter((item: any) => item?.startsWith('company.')).length >
    0;
  const verificationStatus = result?.individual?.verification?.status;
  const havePersonalDueReqsAvailable =
    result?.currently_due_requirements?.filter((item: any) => item?.startsWith('person_')).length >
    0;
  const businessVerificationStatus =
    isTaxIDProvided || haveCompanyDueReqsAvailable ? 'unverified' : verificationStatus;
  const personalVerificationStatus = havePersonalDueReqsAvailable
    ? 'unverified'
    : verificationStatus;
  const verificationDocumentFront = result?.individual?.verification?.document.front;
  const verificationDocumentBack = result?.individual?.verification?.document.back;
  const taxDocFront = result?.company?.verification.document.front;
  const taxDocBack = result?.company?.verification.document.back;
  const isTaxDocIDAdded =
    (taxDocFront && taxDocFront !== undefined) || (taxDocBack && taxDocBack !== undefined);

  /** Track User Event */
  // setUserForTracking(result?.data?.id, 'Onboarding into Business Review');
  // trackEvent('Onboarding into Business Review', result?.data);

  const navigateToSSNVerification = () => {
    updatePreferenceOnServer(routeType.VERIFICATION_FAIL_SSN);
    navigate('/ssn-verification-failed');
  };

  const handlePersonalInfoEditEinUpdate = () => {
    localStorage.setItem('ein-verification-enabled', 'true');
    updatePreferenceOnServer(routeType.EIN_FAIL_BUSINESS);
    navigate('/ein-verification-failed');
  };

  const dateObject = result?.individual?.dob;
  const checkDOBNull =
    dateObject && dateObject?.day !== null && dateObject.month !== null && dateObject.year !== null;

  return (
    result && (
      <>
        {isLoading && <ManualLoader />}
        <Wrapper>
          <DivWrapper className={'no_fullscreen'}>
            <Box component={'div'} className={'container_content with_note bottom_terms_padding'}>
              <Box
                component={'div'}
                className={'container_content_top'}
                sx={{
                  [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                    minHeight: 630 + commonSpacing.desktopPaddingB * 4,
                    paddingBottom: 2,
                  },
                }}
              >
                <Header
                  isBackButtonVisible={false}
                  isProgressBarVisible={true}
                  totalProgressSteps={5}
                  currentProgressStep={5}
                  paddingX={0}
                  isLanguageSwitcher
                  isLogoLeftAligned
                />
                <Box sx={{ marginBottom: 4 }}>
                  <HeadingSummaryBlock
                    heading={t('headingContent.business.review.heading')}
                    headingAlignment="left"
                    content={t('headingContent.business.review.content')}
                    contentAlignment="left"
                  />
                </Box>

                <Box>
                  {editFormVisibilityStates.businessInfo && (
                    <ShadowCard marginBottom={6} px={4} pt={6} pb={4}>
                      <ReviewCard
                        title={t('captions.businessDetails')}
                        name={`${result.company.name}`}
                        editIconClickEvent={handleBusinessInfoEditIcon}
                        type={businessVerificationStatus}
                        isInnerTitle
                        isShadowWrappedIcon
                      >
                        <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                          {`${phoneBusinessNumberMask(result.company.phone)}`}
                        </Typography>
                        <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                          {result?.company?.address?.line1 ?? ''}
                        </Typography>
                        <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                          {result?.company?.address?.line2 ?? ''}
                        </Typography>
                        <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                          {AddressCityStateZip(result?.company)}
                        </Typography>

                        <Typography
                          variant="h1"
                          color={'gray.700'}
                          marginBottom={2}
                          className="ssn_provided_text"
                        >
                          {t('reviewCardtexts.taxIdProvided')}
                          <div
                            className="icon_wrapper"
                            style={{ cursor: 'pointer' }}
                            onClick={handleBusinessInfoEditIcon}
                          >
                            <RefreshIcon />
                          </div>
                        </Typography>
                        {isTaxDocIDAdded && (
                          <Typography
                            variant="h1"
                            color={'gray.700'}
                            marginBottom={2}
                            className="ssn_provided_text"
                          >
                            {t('reviewCardtexts.idProvidedText')}
                            <div
                              className="icon_wrapper"
                              style={{ cursor: 'pointer' }}
                              onClick={handlePersonalInfoEditEinUpdate}
                            >
                              <RefreshIcon />
                            </div>
                          </Typography>
                        )}
                      </ReviewCard>
                    </ShadowCard>
                  )}
                  {editFormVisibilityStates.businessInfoEdit && (
                    <Box
                      component={'div'}
                      sx={{
                        [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                          paddingBottom: 6,
                        },
                        [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                          paddingBottom: 2,
                        },
                      }}
                    >
                      <FormEditBusinesslDetailsSubmition
                        title={t('captions.businessDetails')}
                        onCancelButton={handleBusinessInfoCancelEvent}
                        type={businessVerificationStatus}
                        setUpdateData={setUpdateData}
                      />
                    </Box>
                  )}

                  {editFormVisibilityStates.personaInfo && (
                    <ShadowCard marginBottom={6} px={4} pt={6} pb={4}>
                      <ReviewCard
                        title={t('captions.personalDetails')}
                        name={`${result.individual.first_name} ${result.individual.last_name}`}
                        editIconClickEvent={handlePersonalInfoEditIcon}
                        isInnerTitle
                        isShadowWrappedIcon
                        type={personalVerificationStatus}
                      >
                        <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                          {`${result?.individual.email}`}
                        </Typography>
                        {webauthnEnabled && (
                          <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                            {`•••••••••••••••`}
                          </Typography>
                        )}
                        <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                          {`${phoneNumberMask(result.individual.phone)}`}
                        </Typography>
                        {addtionalNumbers !== undefined && (
                          <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                            {`${addtionalNumbers}`}
                          </Typography>
                        )}
                        <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                          {checkDOBNull
                            ? `${t('reviewCardtexts.bornOnText')} ${getFormattedDate(
                                result?.individual?.dob
                              )}`
                            : null}
                        </Typography>

                        <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                          {result?.individual?.address?.line1 ?? ''}
                        </Typography>
                        <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                          {result?.individual?.address?.line2 ?? ''}
                        </Typography>
                        <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                          {AddressCityStateZip(result?.individual)}
                        </Typography>

                        {result?.individual?.ssn_last4_provided && (
                          <Typography
                            variant="h1"
                            color={'gray.700'}
                            marginBottom={2}
                            className="ssn_provided_text"
                          >
                            {t('reviewCardtexts.idProvidedLast4Text')}
                            <div
                              className="icon_wrapper"
                              style={{ cursor: 'pointer' }}
                              onClick={handlePersonalInfoEditIcon}
                            >
                              <RefreshIcon />
                            </div>
                          </Typography>
                        )}
                        {(verificationDocumentFront || verificationDocumentBack) && (
                          <Typography
                            variant="h1"
                            color={'gray.700'}
                            marginBottom={2}
                            className="ssn_provided_text"
                          >
                            {t('reviewCardtexts.idDocProvidedText')}
                            {verificationStatus === 'verified' && (
                              <div className="ssn_provided_text_verified">
                                <AccountVerificationStatusses type={'verified'} />
                              </div>
                            )}
                            {verificationStatus !== 'verified' && (
                              <div
                                className="icon_wrapper"
                                style={{ cursor: 'pointer' }}
                                onClick={handlePersonalInfoEditIcon}
                              >
                                <RefreshIcon />
                              </div>
                            )}
                          </Typography>
                        )}
                      </ReviewCard>
                    </ShadowCard>
                  )}
                  {editFormVisibilityStates.personaInfoEdit && (
                    <FormEditPersonalDetailsSubmition
                      title={t('captions.personalDetails')}
                      onCancelButton={handlePersonalInfoCancelEvent}
                      setUpdateData={setUpdateData}
                      type={personalVerificationStatus}
                      onFormSubmit={handlePersonalInfoCancelEvent}
                    />
                  )}
                </Box>
                <Box>
                  {(serverErrorsSSN ||
                    result?.errors?.length > 0 ||
                    result?.currently_due_requirements?.length > 0) &&
                    !isLoading && (
                      <Messages
                        topMargin={4}
                        bottomMargin={2}
                        messageHeading={t('errorMessages.reviewError.heading')}
                        messageContent={t('errorMessages.reviewError.content')}
                        messageLinkText={t('errorMessages.reviewError.linkText')}
                        messageLinkEvent={
                          businessVerificationStatus !== 'verified'
                            ? handleBusinessInfoEditIcon
                            : handlePersonalInfoEditIcon
                        }
                      />
                    )}
                </Box>
              </Box>
              <Box component={'div'} className="container_content_bottom ">
                <FooterNote />
              </Box>
            </Box>
            <ContentContainerBottom className={'no_fullscreen no_note'}>
              <Button
                variant="containedLarge"
                color="primary"
                type="button"
                fullWidth
                onClick={submitForm}
                sx={{ overflow: 'hidden' }}
                disabled={
                  editFormVisibilityStates.personaInfoEdit ||
                  editFormVisibilityStates.businessInfoEdit
                }
              >
                {t('buttonTexts.submitButton')}
              </Button>
            </ContentContainerBottom>
          </DivWrapper>
        </Wrapper>
      </>
    )
  );
};
