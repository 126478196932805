import { useState, useEffect } from 'react';
import { setTransactionCreatedDateRange } from 'src/helpers/helperHandlingDates';

export interface IFilterValues {
  dateSorting: string;
  order_by: string;
  filterNavActive: boolean;
  dateFilterSelected?: string;
  createdLessThan?: string;
  createdGreaterThan?: string;
  selectedCustomDateRange?: any;
  savedMinmumAmount?: any;
  savedMaximumAmount?: any;
}

export const handleTransactionFilter = (
  setFilterSchema: any,
  setSearchText: any,
  setFilterProcessing: any
) => {
  const initialFilterValues = {
    dateSorting: '',
    order_by: '',
    filterNavActive: false,
    dateFilterSelected: 'all',
    createdLessThan: '',
    createdGreaterThan: '',
    selectedCustomDateRange: null,
    savedMinmumAmount: null,
    savedMaximumAmount: null,
  };

  const [filterModalOpen, setFilterModalOpen] = useState(false); // initialize state for modal open and close
  const [dateSortingOption, setDateSortingOption] = useState(''); // initialize state to handle date filter selected option
  const [filterValues, setFilterValues] = useState<IFilterValues>(initialFilterValues); // initialize state to handle filter values updates, and related values chages
  const [tempSchemaObject, setTempSchemaObject]: any = useState({}); // initialize state for generate temprorary schema, it's not passed to api
  const [resetFilter, setResetFilter] = useState<boolean>(false); // initialize state reset the filter
  const [filterAmountRange, setFilterAmountRange] = useState<any>(undefined); // initialize state to handle amount filter max and min range
  const [filterdFilters, setFilteredFilters]: any = useState([]);
  /**
   * set the Applied filters in the initial load
   */
  useEffect(() => {
    const getStorageFilterSchema: any = localStorage.getItem('transaction_filter_schema'); // get filter scheme object from local storage
    const parseStorageFilterSchema: any =
      getStorageFilterSchema && getStorageFilterSchema !== 'undefined'
        ? JSON.parse(getStorageFilterSchema)
        : undefined; // get parse the stringified object
    const getStorageSortingData: any = localStorage.getItem('transaction_sorting_data'); // get sorting data from local storage
    const parseStorageSortingData: any =
      getStorageSortingData && getStorageSortingData !== 'undefined'
        ? JSON.parse(getStorageSortingData)
        : undefined; // get parse the string to remove double quotation
    const getStorageFilterValues: any = localStorage.getItem('transaction_filter_values'); // get filterButton Id from local storage
    const parseStorageFilterValues: any =
      getStorageFilterValues && getStorageFilterValues !== 'undefined'
        ? JSON.parse(getStorageFilterValues)
        : undefined; // get parse the string to remove double quotation

    // check filter schema localstogae item is available or not and set into the state using setFilterSchema() method
    if (getStorageFilterSchema !== 'undefined') {
      setFilterSchema(parseStorageFilterSchema);
      setTempSchemaObject(parseStorageFilterSchema);

      const getMinAmount: any =
        parseStorageFilterSchema && parseStorageFilterSchema.amount_gte
          ? parseStorageFilterSchema.amount_gte
          : false;
      const getMaxAmount: any =
        parseStorageFilterSchema && parseStorageFilterSchema.amount_lte
          ? parseStorageFilterSchema.amount_lte
          : null;

      if (getMinAmount !== null && getMaxAmount !== null) {
        setFilterAmountRange({
          min: getMinAmount,
          max: getMaxAmount,
        });
      }

      // this is used to select used filters depend on the we are crearing a new array, it's used to get applied filters and count
      if (parseStorageFilterSchema) {
        let setFilteredFiltersArray: any = [];

        if (parseStorageFilterSchema.created !== undefined) {
          if (parseStorageFilterValues && parseStorageFilterValues.dateFilterSelected !== 'all') {
            setFilteredFiltersArray = [...setFilteredFiltersArray, 'date'];
          }
        }

        if (parseStorageFilterSchema.order_by !== undefined) {
          setFilteredFiltersArray = [...setFilteredFiltersArray, 'sort'];
        }

        if (parseStorageFilterSchema.amount_lte !== undefined) {
          setFilteredFiltersArray = [...setFilteredFiltersArray, 'amount'];
        }

        setFilteredFilters(setFilteredFiltersArray);
      }
    }

    if (parseStorageFilterValues !== undefined) {
      setFilterValues(parseStorageFilterValues);
    } else {
      setFilterValues(initialFilterValues);
    }

    // check filter schema, and sorting data localstogae items are available or not
    // and set into the states using setFilterValues() and setDateSortingOption() methods
    if (getStorageSortingData !== 'undefined' && getStorageFilterSchema !== 'undefined') {
      setDateSortingOption(parseStorageSortingData);
    } else if (getStorageSortingData === 'undefined' && getStorageFilterSchema !== 'undefined') {
      setDateSortingOption('');
    } else if (getStorageSortingData !== 'undefined' && getStorageFilterSchema === 'undefined') {
      setDateSortingOption(parseStorageSortingData);
    } else {
      onClickFilterReset();
    }
  }, []);

  /**
   * handle the amount min and max values, based on the range change
   */
  useEffect(() => {
    if (filterAmountRange) {
      setTempSchemaObject({
        ...tempSchemaObject,
        amount_lte: filterAmountRange.max,
        amount_gte: filterAmountRange.min,
      });

      setFilterValues((filterValues: IFilterValues) => ({
        ...filterValues,
        savedMinmumAmount: filterAmountRange.min,
        savedMaximumAmount: filterAmountRange.max,
      }));
    }
  }, [filterAmountRange]);

  /**
   * onclick event to open the filter modal
   */
  const onClickModalOpen = (): void => {
    setFilterModalOpen(true);
  };

  /**
   * onclick event to close the filter modal
   */
  const onClickModalClose = (): void => {
    setFilterModalOpen(false);
  };

  /**
   * handle filter processing time, and display the loader
   */
  const handleFilterProcess = (isApplyEventClick: boolean = false) => {
    setFilterProcessing(true);
    setTimeout(function () {
      setFilterProcessing(false);
      if (isApplyEventClick) {
        onClickModalClose(); // close the modal once apply filter button clicked
      }
    }, 2000);
  };

  /**
   * onclick event to reset the filter
   */
  const onClickFilterReset = (): void => {
    setFilterSchema(null);
    setDateSortingOption('');
    setFilterValues(initialFilterValues);
    setTempSchemaObject({});
    setTempSchemaObject(undefined);
    localStorage.removeItem('transaction_sorting_data');
    localStorage.removeItem('transaction_filter_schema');
    localStorage.removeItem('transaction_filter_values');
    setResetFilter(true);
    setSearchText('');
    handleFilterProcess(false);
    setFilteredFilters([]);
  };

  /**
   * onChange handle date sorting
   */
  const onChangeDateSorting = (event: any) => {
    setFilterValues((filterValues: IFilterValues) => ({
      ...filterValues,
      dateSorting: event.target.id,
      order_by: 'created',
    }));

    setTempSchemaObject((tempSchemaObject: any) => ({
      ...tempSchemaObject,
      order_by: 'created',
    }));
  };

  /**
   * Handle Date filteration buttons onChange
   */
  const onChangeDateFilter = (event: any) => {
    const [created] = setTransactionCreatedDateRange(event.target.id);

    setFilterValues((filterValues: IFilterValues) => ({
      ...filterValues,
      dateFilterSelected: event.target.id,
      selectedCustomDateRange: null,
    }));

    if (created && event.target.id !== 'all') {
      setTempSchemaObject((tempSchemaObject: any) => ({
        ...tempSchemaObject,
        created: created,
      }));
    } else if (event.target.id === 'all') {
      delete tempSchemaObject.created;
      setTempSchemaObject({ ...tempSchemaObject });
    }
  };

  /**
   * Handle custom date filteration for datepicker change
   */

  const onChangeDateCustomEvent = (dates: any) => {
    const [created] = setTransactionCreatedDateRange('date', dates);

    setFilterValues((filterValues: IFilterValues) => ({
      ...filterValues,
      dateFilterSelected: 'date',
      selectedCustomDateRange: dates,
    }));

    if (created) {
      setTempSchemaObject((tempSchemaObject: any) => ({
        ...tempSchemaObject,
        created: created,
      }));
    } else if (tempSchemaObject && tempSchemaObject.created !== undefined) {
      delete tempSchemaObject.created;
      setTempSchemaObject({ ...tempSchemaObject });
    }
    setDateSortingOption('date');
  };

  /**
   * Handle custom date picker close
   */
  const onCancelDateCustomEvent = () => {
    setFilterValues((filterValues: IFilterValues) => ({
      ...filterValues,
      dateFilterSelected: 'all',
      selectedCustomDateRange: null,
    }));
    setDateSortingOption('all');
    if (tempSchemaObject && tempSchemaObject.created !== undefined) {
      delete tempSchemaObject.created;
      setTempSchemaObject({ ...tempSchemaObject });
    }
  };

  /**
   * onClick event to apply the filter
   */
  const onClickApplyFilter = (): void => {
    let schemeObj = tempSchemaObject; // initial state for schemeObj

    setFilterValues((filterValues: IFilterValues) => ({
      ...filterValues,
      filterNavActive: tempSchemaObject && tempSchemaObject.length !== 0 ? true : false,
    }));

    // check:: if filterValues.order_by is not equal to empty string ''
    if (filterValues && filterValues.order_by !== '') {
      setDateSortingOption(filterValues.dateSorting); // set the selected sorting into state using setDateSortingOption() method
      localStorage.setItem('transaction_sorting_data', JSON.stringify(filterValues.dateSorting)); // set the sorting data in to local storage
    }

    setFilterSchema(schemeObj); // set the filter schema in to state using the setFilterSchema() method
    localStorage.setItem('transaction_filter_schema', JSON.stringify(schemeObj)); // set the filter schema in to local storage
    localStorage.setItem(
      'transaction_filter_values',
      JSON.stringify({
        ...filterValues,
        filterNavActive: tempSchemaObject && tempSchemaObject.length !== 0 ? true : false,
      })
    ); // set the selected date button id in to local storage

    setResetFilter(false);
    setSearchText('');
    handleFilterProcess(true);
  };

  /**
   * Define the return values
   */
  return [
    onClickModalOpen,
    onClickModalClose,
    onClickFilterReset,
    filterModalOpen,
    dateSortingOption,
    onChangeDateSorting,
    onChangeDateFilter,
    onChangeDateCustomEvent,
    onCancelDateCustomEvent,
    onClickApplyFilter,
    filterValues,
    resetFilter,
    setFilterAmountRange,
    filterAmountRange,
    filterdFilters,
  ];
};
