import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Button } from '@mui/material';

import {
  ButtonAuthorizedWrapper,
  FinanceMoveMoneySummaryDetailsCard,
  DivAuthorizedWrapper,
  Header,
  HeaderFixed,
  Wrapper,
  WrpperAuthorizedContainer,
  ConfirmationModal,
  Messages,
  ManualLoader,
} from 'src/components/base/core';

import { FinanceMoveMoneySummaryCard } from 'src/components/base/core/Cards/FinanceMoveMoneySummaryCard/FinanceMoveMoneySummaryCard';

import { useCurrencyFormat } from 'src/hooks/useCurrencyFormat';
import { useGetBlockHeight } from 'src/hooks/useGetBlockHeight';
import {
  useSendMoneyToLinkedAccountMutation,
  useSendMoneyToPayeeAccountMutation,
} from 'src/services/account/accountServiceApi';

import { commonSpacing, theme } from 'src/shared/theme/theme';

type LocationState = {
  accountId?: boolean;
  accountType?: boolean;
  amount?: string;
  description?: string;
};

export const FinanceAccountMoveMoneySummaryContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [elementRef, height] = useGetBlockHeight();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [sendMoneyToLinkedAccount, { isLoading: isLoadingLA }] =
    useSendMoneyToLinkedAccountMutation();
  const [sendMoneyToPayeeAccount, { isLoading: isLoadingPA }] =
    useSendMoneyToPayeeAccountMutation();
  const [message, setMessage] = useState('');

  const { accountId, accountType, amount, description } =
    location?.state != null
      ? (location?.state as LocationState)
      : {
          accountId: null,
          accountType: 'linked-account',
          amount: null,
          description: null,
        };

  const onClickSummaryButton = async () => {
    let createtransferObjPayee;
    let createtransferObjLAccount;
    let response: any;

    if (description) {
      createtransferObjPayee = {
        amount: +`${amount}`,
        description: description,
        destination_payee_id: accountId,
      };
      createtransferObjLAccount = {
        amount: +`${amount}`,
        description: description,
        destination_bank_id: accountId,
      };
    } else {
      createtransferObjPayee = {
        amount: +`${amount}`,
        destination_payee_id: accountId,
      };
      createtransferObjLAccount = {
        amount: +`${amount}`,
        destination_bank_id: accountId,
      };
    }

    if (accountType === 'payee-account') {
      response = await sendMoneyToPayeeAccount(createtransferObjPayee);
    } else {
      response = await sendMoneyToLinkedAccount(createtransferObjLAccount);
    }

    if (response) {
      if (response && response?.error) {
        setMessage(
          response?.error?.data?.error ?? response?.error?.data?.message ?? response?.error?.error
        );
        setConfirmationModalOpen(false);
      } else {
        setMessage('');
        setConfirmationModalOpen(true);
      }
    }
  };

  const onClickConfirmButton = async () => {
    setConfirmationModalOpen(false);
    setMessage('');
    navigate('/finance/account/main');
  };

  return (
    <>
      {(isLoadingLA || isLoadingPA) && <ManualLoader />}
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonHide={false}
          isBackButtonVisible
          paddingX={0}
          isLogoVisible={false}
          headerText={t('finance.MoveMoney.headerText')}
          headerPaddingBottom={3}
          withCenteredHeaderText
          onBackButtonClick={() => navigate('/finance/account/move-money/transfer')}
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper>
        <WrpperAuthorizedContainer>
          <DivAuthorizedWrapper>
            <Box
              ref={elementRef}
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                  minHeight: height,
                  paddingBottom: height ? 4 : commonSpacing.desktopPaddingB,
                },
              }}
            >
              {message !== '' && (
                <Messages
                  messageHeading={t('finance.MoveMoney.headerText')}
                  messageContent={message}
                  closeEvent={() => setMessage('')}
                  topMargin={0}
                  bottomMargin={4}
                />
              )}
              <FinanceMoveMoneySummaryCard
                isAmountBlockVisible
                currentBalance={amount ? amount : '0'}
                t={t}
              />
              <FinanceMoveMoneySummaryDetailsCard
                valueDate={moment().format('MM/DD/YYYY')}
                valueMethod="ACH"
                valueNotes={description ? description : '----'}
                valueType="Payment"
                t={t}
              />
            </Box>
            {message !== 'success' && (
              <ButtonAuthorizedWrapper>
                <Button
                  variant="containedLarge"
                  color="primary"
                  fullWidth
                  id={'Finance'}
                  sx={{ backgroundColor: 'blue.500', color: 'white', marginBottom: 0 }}
                  onClick={onClickSummaryButton}
                >
                  {t('finance.MoveMoney.summary.button')}
                </Button>
              </ButtonAuthorizedWrapper>
            )}
          </DivAuthorizedWrapper>
        </WrpperAuthorizedContainer>
      </Wrapper>

      {confirmationModalOpen && (
        <ConfirmationModal
          title={t('finance.MoveMoney.summary.successModal.heading')}
          content={`${t(
            'finance.MoveMoney.summary.successModal.prefixContent'
          )} ${useCurrencyFormat(amount ? amount : '0')} ${t(
            'finance.MoveMoney.summary.successModal.postFixContent'
          )}`}
          onFormSubmit={onClickConfirmButton}
        />
      )}
    </>
  );
};
