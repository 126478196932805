import styled from 'styled-components';

export const TooltipWrapper = styled.div`
  display: inline-flex;
  position: relative;

  .tooltip-content {
    top: 4px;
    position: absolute;
    min-width: 155px;
    background: #ffffff;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
    font-weight: 400;
    font-size: 10px;
    line-height: 1.5;
    letter-spacing: 0.15px;
    color: #3c4257;
    z-index: 10;
    display: none;
  }

  .tooltip-icon {
    cursor: pointer;

    &:hover {
      + .tooltip-content {
        display: block;
      }
    }
  }

  &.right {
    .tooltip-content {
      padding: 10px 19px 10px 11px;
      border-radius: 0px 10px 10px 10px;
      left: 20px;
    }
  }

  &.topLeft {
    .tooltip-content {
      padding: 10px 19px 10px 11px;
      border-radius: 10px 10px 10px 0px;
      left: 20px;
      top: auto;
      bottom: 4px;
    }
  }

  &.left {
    .tooltip-content {
      padding: 10px 10px 10px 19px;
      border-radius: 10px 0 10px 10px;
      right: 20px;
    }
  }

  @media (max-width: 767px) {
    width: 13px;
    height: 14px;
  }

  @media (min-width: 768px) {
    width: 16px;
    height: 14px;
  }
`;
