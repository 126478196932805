import { styled } from '@mui/material/styles';

export const BankInfoCardTop = styled('div')(
  ({ theme }) => `
   display: flex;
   position: relative;
   justify-content: space-between;
   width: 100%;

   &.selected {
    background: ${theme.palette.gray[100]};
   }
`
);

export const BankInfoCardLeft = styled('div')(
  ({ theme }) => `
 display: flex;
 align-items: center;
 width: 100%;
 padding: ${theme.spacing(3)} ${theme.spacing(3)};
 cursor: pointer;

 .icon_arrow {
  margin-right: ${theme.spacing(2)};
  position: relative;
  top: 2px;

  svg {
   width: 20px;
   color: ${theme.palette.gray[600]};
  }
 }
`
);

export const BankInfoCardBottom = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(3)} ${theme.spacing(4)};
  border-top: 1px solid ${theme.palette.gray[50]};
`
);

export const BankInfoItem = styled('div')(
  ({ theme }) => `
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing(2)} 0;
  align-items: center;
  > * {
   display: flex;
   align-items: center;
  }

  svg {
   margin-right: ${theme.spacing(2)};
  }
`
);

export const BankInfoCardRight = styled('div')(
  ({ theme }) => `
 display: flex;
 align-items: center;
 position: absolute;
 position: absolute;
 right: 12px;
 top: 16px;
`
);

export const DefaultTag = styled('div')(
  ({ theme }) => `
 background: ${theme.palette.blue[75]};
 height: 18px;
 border-radius: 4px;
 padding: 0 ${theme.spacing(1.5)};
 display: flex;
 align-items: center;
 margin-right: ${theme.spacing(2)};
 top: 5px;
 position: relative;
`
);

export const ViewMoreDropDown = styled('div')(
  ({ theme }) => `
 position: relative;
`
);

export const ViewMoreDropDownIcon = styled('div')(
  ({ theme }) => `
  cursor: pointer;
 svg {
  color: ${theme.palette.gray[500]};
 }
`
);

export const ViewMoreDropDownList = styled('div')(
  ({ theme }) => `
 position: absolute;
 display: flex;
 flex-direction: column;
 top: 0;
 right: 24px;
 background: ${theme.palette.white};
 box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.1);
 border-radius: 8px 0px 8px 8px;
 padding: 8px 0;
 width: 140px;
 z-index: 1000;
`
);

export const ViewMoreDropDownListItem = styled('div')(
  ({ theme }) => `
 width: 100%;
 display: flex;
 align-items: center;
 cursor: pointer;
 padding: ${theme.spacing(3)} ${theme.spacing(4)};

 svg {
  margin-right: ${theme.spacing(1)};
  position: relative;
  top: 2px;
 }

 &:hover {
  background:${theme.palette.gray[50]};
 }

 &.remove-item {
  svg * {
   fill: ${theme.palette.red[500]};
  }
 }
`
);
