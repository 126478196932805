import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Error404Container } from '../../../containers';

export const Error404 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/404error');
  }, []);

  return <Error404Container />;
};
