import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createBrowserHistory } from 'history';

import { getAppInit } from '../../services/init/init.service';
import { GetLogginDetails } from '../../services/login/loggin.service';
import { useGetPreferenceMutation } from 'src/services/account/account.preferenceApi';
import { useGetAccountDataMutation } from 'src/services/account/accountServiceApi';

import { store } from 'src/store/store';
import { ActionType } from 'src/store/action-types';

import { setUserForTracking, trackPage } from '../analytics/service';

import { ManualLoader } from '../base/core/OtherUtilities/Loader/ManualLoader';
import { isJSONObject } from 'src/shared/utilities/dataConvertions';
import { getCookie } from 'src/shared/utilities/cookiesHelper';
import { decryptWithAES } from 'src/shared/utilities/encryptHelpper';
import {
  configs,
  nativeBridgeTypes,
  nativeHandlerForLogOut,
  removeFromLocalStorage,
} from 'src/shared/config/config';
import { useFlags } from 'launchdarkly-react-client-sdk';

/**
 * Use dispatch to store the first name, last name, and email to root state__
 * @param payload
 */
const dispatchAppState = (payload: any) => {
  store.dispatch({
    type: ActionType.USER_DETAILS,
    payload: payload,
  });
};

const dispatchLoggingInState = (v: boolean) => {
  store.dispatch({
    type: ActionType.LOGGING_IN,
    payload: v,
  });
};

/**
 * If authorized, return an outlet that will render child elements
 * If not, return element that will navigate to login page
 */
const PrivateRoute = () => {
  const navigate = useNavigate(); // set useNaviagate to navigate __
  const { i18n } = useTranslation(); // set translation __
  const history = createBrowserHistory(); // get browser history __

  const [getPreference] = useGetPreferenceMutation(); // get account preference call via RTK query __
  const [getAccountData]: any = useGetAccountDataMutation(); // get account data call via RTK query __

  const [path, setPath]: any = useState(); // used to set location path into state __
  const [loginResponse, setLoginResponse]: any = useState(); // used to set login api response in to state __
  const { webauthnEnabled } = useFlags();

  // Required to support native iOS app __
  window.addEventListener('message', (message) => {
    if (isJSONObject(message?.data)) {
      const jsonData = JSON.parse(message.data);
      if (jsonData?.navigateTo && jsonData?.navigateTo.length > 0) {
        navigate(jsonData?.navigateTo);
      }
    }
  });

  // Required to support native Android app
  document.addEventListener('message', (message) => {
    if (message instanceof MessageEvent && isJSONObject(message?.data)) {
      const jsonData = JSON.parse(message.data);
      if (jsonData?.navigateTo && jsonData?.navigateTo.length > 0) {
        navigate(jsonData?.navigateTo);
      }
    }
  });

  // when history got change, check the history location with current path __
  useEffect(() => {
    // listen and notify Segment of client-side page updates __
    if (history.location.pathname !== path) {
      setPath(history.location.pathname); // set history location path into state __
      // @ts-ignore
      trackPage();
    }
  }, [history]);

  // used to get the preference data __
  const getPreferenceData = async (account: any) => {
    const response = await getPreference({}); // get accountPreference call __

    // check response data is not null or undefined __
    // @ts-ignore
    if (response?.data?.data) {
      const lng = account?.data?.language === 'english' ? 'en' : 'es'; // check language __
      i18n.changeLanguage(lng); // set language __

      // set language for native apps __
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({ type: nativeBridgeTypes.SELECTED_LOCALE, locale: lng })
      );

      // check user onboarded or not __
      const isLoginAgainAfterOnBoarding =
        account?.data?.charges_enabled &&
        account?.data?.external_account_id &&
        localStorage.getItem('init_login');

      // used to keep in storage in temprary base to identify the user logedin state __
      if (localStorage.getItem('init_login')) {
        setTimeout(function () {
          localStorage.removeItem('init_login'); // this is only on one time __
        }, 2000);
      }

      // validate for on baording users __
      if (isLoginAgainAfterOnBoarding) {
        // if onboarding user loged in , it'll redirect into "/dashboard" __
        navigate('/dashboard');

        // communicate to native end, user was already onboarded __
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({ type: nativeBridgeTypes.LOGIN_STATUS, isLoggedIn: true })
        );
      }

      // validate for on baording users __
      if (!isLoginAgainAfterOnBoarding) {
        // @ts-ignore
        const { relativeRoutePath, currentStep } = response?.data?.data;
        const getHistoryLocationPath = history.location.search?.length < 1; // used to identify the route param redirection __
        const isNotSettings = history.location.pathname.indexOf('settings') < 0; // confirm is not a setting screen __
        const isNotEarnings = history.location.pathname.indexOf('earnings') < 0; // confirm is not a earnings screen __
        const isNotHome = history.location.pathname.indexOf('home') < 0; // confirm is not a home screen __
        // checks to validate loged users without complete the all signup flow __
        const getafterOnboardedScreens =
          !getHistoryLocationPath && !isNotSettings && !isNotEarnings && !isNotHome;

        // check and redirect to relavant screens, if user left in middle of the signup flow
        {
          getafterOnboardedScreens &&
            navigate(`${relativeRoutePath}`, { state: { step: currentStep }, replace: true });
        }
      }
    }
  };

  // create function call initApp  __
  const initApp = (data: any) => {
    // get idToken from cookie _
    const idTokenAdded: any = decryptWithAES(getCookie('T_added'), configs.ENCRYPT_PHRESE);

    // if idToken is not null __
    if (idTokenAdded != null) {
      getAppInit().then(async (response) => {
        // check accountByIdpId and accountByPhone from response __
        const myAccountHeader = response?.data?.accountByIdpId ?? response?.data?.accountByPhone;

        // if accountByIdpId or accountByPhone is not null
        if (myAccountHeader && !webauthnEnabled) {
          // get first name, last name, and email from the response __
          const { last_name, first_name, email } = myAccountHeader;

          // set first name, last name, and email into  root state via reduces __
          dispatchAppState({ last_name, first_name, email });

          // get account data __
          const accData = await getAccountData();

          // pass account data into getPreferenceData functon as a parameter __
          await getPreferenceData(accData);

          // analytics usage for tracking purpose __
          setUserForTracking(myAccountHeader.id);
        }
        // if accountByIdpId or accountByPhone is null __
        else {
          // navigate to customer addition screen __

          if (webauthnEnabled) {
            // get account data __
            const accData = await getAccountData();

            if (!accData || (accData && accData.error)) {
              dispatchLoggingInState(false);
              navigate('/sign-up', { replace: true });
            } else {
              await getPreferenceData(accData);
            }
          } else {
            dispatchLoggingInState(false);
            navigate('/customers-addition', { replace: true });
          }
        }

        // set the response into state __
        setLoginResponse(data);
      });
    }
  };

  // used to check user loged in or not and call relavant function and calls __
  const getData = async () => {
    // get isLoged in api call __
    const res = await GetLogginDetails();
    // if response is 200 (OK) load initAPP function and send the response.data as parameter
    if (res?.status === 200) {
      initApp(res.data);
    } else if (res?.status === 401) {
      removeFromLocalStorage();
      nativeHandlerForLogOut();
      setLoginResponse('Unauthorized401');
    } else {
      // if response is not 200 (OK) or 401 set the response in to state
      dispatchLoggingInState(false);
      setLoginResponse(res.response.data);
    }
  };

  // render data in app initial load __
  useEffect(() => {
    // call getData function __
    getData();

    // check the route addinto the 'auth_callback_route' storage __
    if (window.location.pathname.includes('batch/')) {
      localStorage.setItem('auth_callback_route', window.location.pathname ?? '');
    }
  }, []);

  // if loginResponse is 'Unauthorized' remove following local storage items __
  if (loginResponse === 'Unauthorized') {
    removeFromLocalStorage();
  }

  return (
    <>
      {loginResponse !== 'ok' && loginResponse !== 'Unauthorized' && <ManualLoader />}
      {loginResponse === 'ok' && <Outlet />}
      {loginResponse === 'Unauthorized' && <Navigate to="/" />}
      {loginResponse === 'Unauthorized401' && <Navigate to="/401" />}
    </>
  );
};

export default PrivateRoute;
