export const handleManageCardsNavData = (isCardActive?: boolean, t?: any) => {
  const manageCardNavObject = [
    {
      title: t('finance.manageCard.navigation.link1.content'),
      subTitle: t('finance.manageCard.navigation.link1.subContent'),
      id: 'accountDetails',
      icon: 'AccountDetailsIcon',
      buttonDetails: {
        buttonEnable: false,
        buttonText: '',
        color: '',
      },
      isHidden: false,
    },
    {
      title: t('finance.manageCard.navigation.link2.content'),
      subTitle: t('finance.manageCard.navigation.link2.subContent'),
      id: 'Limits',
      icon: 'LimitsIcon',
      buttonDetails: {
        buttonEnable: false,
        buttonText: '',
        color: '',
      },
      isHidden: false,
    },
    {
      title: t('finance.manageCard.navigation.link3.content'),
      subTitle: t('finance.manageCard.navigation.link3.subContent'),
      id: 'AddToAppleWallet',
      icon: 'AddToAppleWalletIcon',
      buttonDetails: {
        buttonEnable: false,
        buttonText: '',
        color: '',
      },
      isHidden: true,
    },
    {
      title: t('finance.manageCard.navigation.link3.content'),
      id: 'OrderPhysicalCard',
      icon: 'OrderPhysicalCardIcon',
      buttonDetails: {
        buttonEnable: false,
        buttonText: '',
        color: '',
      },
      isHidden: true,
    },
    {
      title: t('finance.manageCard.navigation.link5.content'),
      subTitle: t('finance.manageCard.navigation.link5.subContent'),
      id: 'SetUpPinCode',
      icon: 'SetUpPinCodeIcon',
      buttonDetails: {
        buttonEnable: false,
        buttonText: '',
        color: '',
      },
      isHidden: true,
    },
    {
      title: isCardActive
        ? t('finance.manageCard.navigation.link6.content')
        : t('finance.manageCard.navigation.link6.elseContent'),
      subTitle: t('finance.manageCard.navigation.link6.subContent'),
      id: 'FreezeCard',
      icon: 'FreezeCardcon',
      buttonDetails: {
        buttonEnable: false,
        buttonText: isCardActive ? 'Active' : 'Inactive',
        color: !isCardActive ? 'red.500' : 'green.500',
      },
      isHidden: false,
    },
    {
      title: t('finance.manageCard.navigation.link7.content'),
      id: 'CancelAccount',
      icon: 'CancelAccountIcon',
      buttonDetails: {
        buttonEnable: false,
        buttonText: '',
        color: '',
      },
      isHidden: true,
    },
  ];

  return [manageCardNavObject];
};
