import * as React from 'react';
import { GridAccordionProps, GridFilterStatus } from 'src/shared/utilities/interface';
import { ShadowCard } from 'src/components/base/core';

export type GridContextType = {
  accordion: GridAccordionProps;
};

type Props = {
  children?: React.ReactNode;
  accordion?: GridAccordionProps;
  filterStatus?: GridFilterStatus;
  isNoShadow?: boolean;
};

export const GridContext = React.createContext<any | null>(null);

const GridProvider: React.FC<Props> = ({
  children,
  accordion,
  filterStatus,
  isNoShadow = false,
}) => {
  //const [filterActive, setFilterActive] = React.useState();
  return (
    <GridContext.Provider value={{ accordion, filterStatus }}>
      <ShadowCard
        paddingX={4}
        paddingBottom={3}
        marginBottom={4}
        paddingTop={isNoShadow ? 4 : 0}
        className={isNoShadow ? 'no_shadow' : 'is_shadow'}
      >
        {children}
      </ShadowCard>
    </GridContext.Provider>
  );
};

export default GridProvider;
