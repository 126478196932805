import { Header, HeaderFixed, Wrapper, WrpperAuthorizedContainer } from 'src/components/base/core';
import { InvoiceDetailMiddle } from '../../Earnings/InvoiceDetail/InvoiceDetailMiddle/InvoiceDetailMiddle';
import { InvoiceDetailTop } from '../../Earnings/InvoiceDetail/InvoiceDetailTop/InvoiceDetailTop';
import { InvoiceDetailAfterBottom } from '../../Earnings/InvoiceDetail/InvoiceDetailAfterBottom/InvoiceDetailAfterBottom';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { useTranslation } from 'react-i18next';
import { getTransfersData } from 'src/helpers/getTransfersData';

export const TranDetailContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [invoiceDetailsData, setInvoiceDetailsData] = useState<any>();

  const tabType = 'transactions';

  useEffect(() => {
    const getTranData = async () => {
      const { data } = await getTransfersData({
        first: 1,
        pageOffset: 0,
        filter: { id: { equalTo: `\"${id}\"` } },
        details: true,
      });
      const nodes = data?.payoutBatchTransfers?.nodes;
      if (nodes?.length > 0) {
        setInvoiceDetailsData(nodes[0]);
      }
    };

    getTranData();
  }, []);

  const handleBackButton = () => {
    navigate(-1);
  };

  return (
    <>
      {!invoiceDetailsData && <ManualLoader />}
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonVisible={true}
          isBackButtonHide
          paddingX={0}
          headerText={t('earning.paymentSummaryText')}
          onBackButtonClick={handleBackButton}
          withCenteredHeaderText
        />
      </HeaderFixed>

      <Wrapper isAuthorizedWrapper>
        {invoiceDetailsData && (
          <WrpperAuthorizedContainer>
            <InvoiceDetailTop
              isDatesPendingInvoice={false}
              invoiceDetails={invoiceDetailsData}
              payoutId={id}
              setInvoiceDetailsData={setInvoiceDetailsData}
              tabType={tabType}
            />
            <InvoiceDetailMiddle
              isDatesPendingInvoice={false}
              invoiceDetails={invoiceDetailsData}
              tabType={tabType}
              payoutId={id}
            />
            <InvoiceDetailAfterBottom invoiceDetails={invoiceDetailsData} tabType={tabType} />
          </WrpperAuthorizedContainer>
        )}
      </Wrapper>
    </>
  );
};
