import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  DivWrapper,
  Header,
  HeadingSummaryBlock,
  Messages,
  Wrapper,
} from 'src/components/base/core';
import { commonSpacing, theme } from 'src/shared/theme/theme';
import { logout } from 'src/shared/utilities/logout';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

interface IAccessDeniedContainer {
  errorMessage?: string;
}
export const AccessDeniedContainer = ({ errorMessage }: IAccessDeniedContainer) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isOnNativeApp = useSelector((state: RootState) => state.appState);

  return (
    <>
      <Wrapper isFullScreen>
        <DivWrapper className="no_fullscreen">
          <Box
            component={'div'}
            className={'container_content'}
            sx={{
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                minHeight: 400 + commonSpacing.desktopPaddingB * 4,
              },
            }}
          >
            <Header
              isBackButtonVisible={!isOnNativeApp}
              onBackButtonClick={() => logout(navigate)}
              paddingX={0}
            />
            <Box>
              <HeadingSummaryBlock
                heading={t('headingContent.verifiedEmail.headingAccessDenied')}
                headingAlignment="center"
                mobileHeadingAlignment="center"
              />
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                marginBottom: 3,
              }}
            >
              <Box
                sx={{
                  width: '80%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block',
                  marginBottom: 11,
                }}
              >
                <Messages
                  type="error"
                  messageHeading="Verification failed"
                  messageContent={errorMessage}
                  hideCloseIcon
                />
              </Box>
            </Box>
          </Box>
        </DivWrapper>
      </Wrapper>
    </>
  );
};
