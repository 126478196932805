import { LogoutApi } from 'src/services/logout/logout.service';
import { nativeHandlerForLogOut, removeFromLocalStorage } from '../config/config';

export const logout = async (navigate?: any) => {
  const response = await LogoutApi();
  if (response && response?.status === 200) {
    // eslint-disable-next-line no-debugger
    const { sid, aud, iss } = response.data;
    nativeHandlerForLogOut();
    removeFromLocalStorage();
    window.location.replace(
      `${iss}oidc/logout?client_id=${aud}&logout_hint=${sid}&post_logout_redirect_uri=${window.location.origin}`
    );
  }
};
