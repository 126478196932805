import React, { useState } from 'react';

import { HeadingSummaryBlock } from '../../CommonNestedBlocks/ContentBlocks/HeadingSummaryBlock';
import { Box } from '@mui/material';

import { useSwipeable } from 'react-swipeable';
import { SliderImages } from 'src/shared/utilities/images';
import { useTranslation } from 'react-i18next';
import {
  LinearLoader,
  LoadedBg,
  LoaderBg,
  LoadersRow,
  Swiper,
  TransparentBox,
} from './SplashSlider.style';
import { configs } from 'src/shared/config/config';
import { theme } from 'src/shared/theme/theme';
import { PAY3342 } from 'src/constants/constants';

export const SplashSlider = () => {
  const { t, i18n } = useTranslation();
  const [displayIndex, setDisplayIndex] = useState(0);
  const [slideToLeft, setSlideToLeft] = useState(false);
  const [loaderPaused, setLoaderPaused] = useState(false);

  const slideLeft = () => {
    setSlideToLeft(true);
    setDisplayIndex(displayIndex + 1 < items.length ? displayIndex + 1 : 0);
  };

  const slideRight = () => {
    setSlideToLeft(false);
    setDisplayIndex(displayIndex > 0 ? displayIndex - 1 : items.length - 1);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => slideLeft(),
    onSwipedRight: () => slideRight(),
    onTouchStartOrOnMouseDown: () => setLoaderPaused(true),
    onTouchEndOrOnMouseUp: () => setLoaderPaused(false),
    delta: 5,
  });

  const items = [
    {
      imagePath: `${SliderImages.SLIDE01}`,
      heading: PAY3342 ? t('landing.slide1New.heading') : t('landing.slide1.heading'),
      content: t('landing.slide1.content'),
    },
    {
      imagePath: `${SliderImages.SLIDE02}`,
      heading: t('landing.slide2.heading'),
      content: t('landing.slide2.content'),
    },
    {
      imagePath: `${SliderImages.SLIDE03}`,
      heading: t('landing.slide3.heading'),
      content: t('landing.slide3.content'),
    },
    {
      imagePath: `${SliderImages.SLIDE04}`,
      heading: t('landing.slide4.heading'),
      content: t('landing.slide4.content'),
    },
    {
      imagePath: `${SliderImages.SLIDE05}`,
      heading: t('landing.slide5.heading'),
      content: t('landing.slide5.content'),
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
        '& > :not(style)': {
          m: 0,
          width: 370,
        },
      }}
    >
      {displayIndex < items.length ? (
        <Box sx={{ position: 'relative' }}>
          <LoadersRow>
            {items.map((_item, i) => {
              if (i < displayIndex) {
                return <LoadedBg key={i} />;
              }
              if (i === displayIndex) {
                return (
                  <LoaderBg key={i}>
                    <LinearLoader
                      style={{ animationPlayState: loaderPaused ? 'paused' : 'running' }}
                      onAnimationEnd={() => {
                        setSlideToLeft(true);
                        if (displayIndex + 1 < items.length) {
                          setDisplayIndex(displayIndex + 1);
                        } else {
                          setDisplayIndex(0);
                        }
                      }}
                      displayTime={configs.CAROUSEL_ITEM_DISPLAY_TIME}
                    />
                  </LoaderBg>
                );
              }
              return <LoaderBg key={i} />;
            })}
          </LoadersRow>
          {items.map((item, i) => {
            if (i === displayIndex) {
              return (
                <Swiper key={i} toLeft={slideToLeft}>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'center',
                      marginBottom: 12,
                      [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                        height: `240px !important`,
                      },
                      [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                        height: 'auto !important',
                      },
                    }}
                  >
                    <img
                      src={item.imagePath}
                      alt={item.heading}
                      loading="lazy"
                      style={{ height: 'auto', width: '100%' }}
                    />
                  </Box>
                  <Box
                    component={'div'}
                    sx={{
                      marginX: 'auto',
                      width: i18n.resolvedLanguage !== 'en' ? 370 : 327,
                    }}
                  >
                    <HeadingSummaryBlock
                      heading={item.heading}
                      content={item.content}
                      headingAlignment="center"
                      contentAlignment="center"
                      spacing={1}
                    />
                  </Box>
                </Swiper>
              );
            }
            return null;
          })}
          <TransparentBox {...swipeHandlers} />
        </Box>
      ) : null}
    </Box>
  );
};
