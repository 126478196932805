import Lottie from 'react-lottie-player';
import { Lotties } from '../../../../../shared/utilities/lottieJson';

type jsonType = 'APPROVED' | 'HOLD_ON' | 'LOADING_BUTTON' | 'SUCCESS_ICON';

interface LottieAnimationProps {
  width?: number;
  height?: number;
  json?: any;
  loop?: boolean;
}

export const LottieAnimation = ({
  width = 250,
  height = 275,
  loop = true,
  json = Lotties.HOLD_ON,
}: LottieAnimationProps) => {
  return <Lottie loop={loop} animationData={json} play style={{ width: width, height: height }} />;
};
