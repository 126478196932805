import React from 'react';
import {
  ProgressbarWrapper,
  ProgressbarContainer,
  ProgressbarElement,
  ProgressInfo,
  ProgressValue,
  ProgressLabel,
} from './Progressbar.style';

import { ProgreeBarProps } from 'src/models/component/base.model';

export const Progressbar = ({
  totalProgressSteps = 5,
  currentProgressStep = 1,
  isProgressInfoVisible = false,
  progressValue,
  progressLabel,
  overallProgressValue,
  overallProgressLabel,
  progressBarHeight = '8px',
  sxProgressValue = { fontWeight: '600', fontSize: '12px' },
  sxProgressLabel = { fontWeight: '600', fontSize: '12px' },
}: ProgreeBarProps) => {
  const progressElementWidth = `calc((100%/${totalProgressSteps}) * ${currentProgressStep})`;

  const handleProgressInfoClass = () => {
    let classes = 'aligned_info_right';

    if (currentProgressStep === 0) {
      classes = 'aligned_info_left';
    }

    if (totalProgressSteps === currentProgressStep && totalProgressSteps !== 0) {
      classes = 'equal_progress';
    }

    return classes;
  };

  return (
    <ProgressbarWrapper
      className={isProgressInfoVisible ? 'progress_info_visible' : 'progress_info_invisible'}
    >
      <ProgressbarContainer sx={{ height: progressBarHeight }}>
        <ProgressbarElement sx={{ width: progressElementWidth, height: progressBarHeight }}>
          {isProgressInfoVisible && (
            <ProgressInfo className={handleProgressInfoClass()}>
              {progressValue !== null && progressValue !== '' && (
                <ProgressValue
                  sx={sxProgressValue}
                  className={currentProgressStep > 25 ? 'align-right' : 'align-left'}
                >
                  {progressValue}
                </ProgressValue>
              )}
              <ProgressLabel
                sx={sxProgressLabel}
                className={currentProgressStep > 25 ? 'align-right' : 'align-left'}
              >
                {progressLabel}
              </ProgressLabel>
            </ProgressInfo>
          )}
        </ProgressbarElement>
        {isProgressInfoVisible && (
          <ProgressInfo>
            <ProgressValue sx={sxProgressValue}>
              {totalProgressSteps === currentProgressStep && totalProgressSteps !== 0
                ? ` ${progressValue} ${
                    overallProgressValue && progressValue ? '/' : ''
                  } ${overallProgressValue}`
                : `${overallProgressValue}`}
            </ProgressValue>
            <ProgressLabel sx={sxProgressLabel}>
              {totalProgressSteps === currentProgressStep && totalProgressSteps !== 0
                ? ` ${progressLabel} ${
                    overallProgressLabel && progressLabel ? '/' : ''
                  } ${overallProgressLabel}`
                : `${overallProgressLabel}`}
            </ProgressLabel>
          </ProgressInfo>
        )}
      </ProgressbarContainer>
    </ProgressbarWrapper>
  );
};
