import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';

import {
  Header,
  HeaderFixed,
  Messages,
  ModalBatchPayModeEdit,
  Wrapper,
  WrpperAuthorizedContainer,
} from 'src/components/base/core';
import { BatchDetailMiddle } from './BatchDetailMiddle/BatchDetailMiddle';
import { BatchDetailTop } from './BatchDetailTop/BatchDetailTop';
import { BatchDetailAfterBottom } from './BatchDetailAfterBottom/BatchDetailAfterBottom';

import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { useGetTransferDetailsQuery } from 'src/services/transfers/transfers.service';
import { BatchDetailBottom } from './BatchDetailBottom/BatchDetailBottom';
import { checkIsInstandPaymode } from 'src/shared/utilities/dataConvertions';
import { dateFormatWithTimeZoneForEarning } from 'src/shared/utilities/dob';
import { removeAuthCallbackRelatedStorages } from 'src/shared/config/config';

export const BatcheDetailContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { payoutId } = useParams();
  const { toAllowEditForAllPaymodesInTransfer } = useFlags(); // set feture flag

  const [paymodeEditModalVisible, setPaymodeEditModalVisible] = useState(false);
  const [errorState, setErrorState] = useState('');

  const { data, isLoading, isSuccess, isFetching, isError, error }: any =
    useGetTransferDetailsQuery(payoutId ?? '');

  /**
   * back button clcik event
   */
  const handleBackButton = () => {
    if (isSuccess) {
      if (data && data?.status) {
        navigate(`/batches/${data?.status === 'pending' ? 'submitted' : data?.status}`);
      } else {
        navigate('/batches/submitted');
      }
    } else if (isError) {
      navigate('/batches/submitted');
    }
  };

  /**
   * payMode edit modal open event
   */
  const onClickOpenPayModeEditModal = () => {
    setPaymodeEditModalVisible(true);
  };

  /**
   * handle the error messages
   */
  useEffect(() => {
    if (isError) {
      setErrorState(error?.data ?? error?.data?.message ?? 'Unable to find the transfer details');
    }
  }, [isError]);

  /**
   * remove authCalback route
   */
  useEffect(() => {
    removeAuthCallbackRelatedStorages();
  }, []);

  // check is the paymode instant or not
  const isInstantPayMode = checkIsInstandPaymode(data?.method ?? '');

  return (
    <>
      {(isLoading || isFetching) && <ManualLoader />}
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonVisible={true}
          paddingX={0}
          headerText={t('batches.details.headerTitle')}
          onBackButtonClick={handleBackButton}
          withCenteredHeaderText
        />
      </HeaderFixed>

      <Wrapper isAuthorizedWrapper>
        <WrpperAuthorizedContainer>
          {errorState !== '' && (
            <Messages
              type={'error'}
              messageHeading={'Batch summary'}
              messageContent={errorState}
              closeEvent={() => setErrorState('')}
            />
          )}
          {isSuccess && data && (
            <>
              <BatchDetailTop
                batchDetails={data}
                onClickEditPayMode={onClickOpenPayModeEditModal}
                isInstantPayMode={isInstantPayMode}
                isCheckPayModeEditFeaturedFlaged={toAllowEditForAllPaymodesInTransfer}
                t={t}
              />
              <BatchDetailMiddle
                isDatesPendingInvoice={false}
                batchDetails={data}
                payoutId={payoutId}
                isInstantPayMode={isInstantPayMode}
                t={t}
              />
              <BatchDetailBottom batchDetails={data} t={t} />
              <BatchDetailAfterBottom batchDetails={data} t={t} />
            </>
          )}
        </WrpperAuthorizedContainer>
      </Wrapper>

      {paymodeEditModalVisible && (
        <ModalBatchPayModeEdit
          onModalClose={() => setPaymodeEditModalVisible(false)}
          activePayMode={data?.method}
          estimatedPayoutDate={data?.estimated_approved_date}
          transferId={payoutId}
          paymentTriggeredDate={dateFormatWithTimeZoneForEarning(
            data?.paid_at_date ?? data?.estimated_payout_date
          )}
          paymentArrivalEstimatedDate={data?.payment_arrival_estimated_date}
          t={t}
        />
      )}
    </>
  );
};
