import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  WrpperAuthorizedContainer,
  Wrapper,
  TransactionCardGrid,
  TransactionCardEmpty,
  ManualLoader,
} from '../../../../components/base/core';
import { Box } from '@mui/material';
import { FinanceAccountListCommonHeader } from './Common/Header';

import {
  useGetTreasuryTransactionsQuery,
  useGetVertualCardDetailsQuery,
} from 'src/services/account/accountServiceApi';

import { EmptyStates } from 'src/shared/utilities/images';

const initialFilterObj = {
  tabType: undefined,
  searchText: '',
};

export const FinanceAccountListContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [tabNavTitle, setTabNavTitle] = useState<string | undefined>(undefined);
  const [gridData, setGridData] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [fiterObj, setFiterObj] = useState<any>(initialFilterObj);
  const [filterSchema, setFilterSchema] = useState(null);
  const [filterProcessing, setFilterProcessing] = useState(false);

  const { data, isSuccess, isLoading, isFetching } = useGetTreasuryTransactionsQuery(filterSchema);
  const { data: cardData, isSuccess: isSuccessCD } = useGetVertualCardDetailsQuery(true);

  // set the grid data in in the initial load __
  useEffect(() => {
    if (isSuccess) {
      setGridData(data);
    }
  }, [isSuccess]);

  // settab type in the initial load __
  useEffect(() => {
    const getTransactionFilterItem = localStorage.getItem('transactionFilter');
    const getTransactionFilterItemParse: any = getTransactionFilterItem
      ? JSON.parse(getTransactionFilterItem)
      : null;

    if (getTransactionFilterItem) {
      setFiterObj(getTransactionFilterItemParse);
    }

    if (!getTransactionFilterItem) {
      setFiterObj(initialFilterObj);
      setGridData(data);
      setTabNavTitle(undefined);
    }

    if (getTransactionFilterItem && getTransactionFilterItemParse.tabType === 'Earned') {
      const formatedData =
        data &&
        data.length > 0 &&
        data.filter((item: any) => {
          if (item && item.amount > 0) {
            return item;
          }
        });

      setGridData(formatedData);
      setTabNavTitle('Earned');
    }

    // if selected item title is Spent __
    if (getTransactionFilterItem && getTransactionFilterItemParse.tabType === 'Spent') {
      const formatedData =
        data &&
        data.length > 0 &&
        data.filter((item: any) => {
          if (item && item.amount < 0) {
            return item;
          }
        });

      setGridData(formatedData);
      setTabNavTitle('Spent');
    }
  }, [data]);

  // handle tab navigation item click __
  const handleTabNavClick = (itemTitle: string | undefined) => {
    setTabNavTitle(itemTitle);

    const fiterStateForTabClick = {
      tabType: itemTitle,
      searchText: '',
    };

    setFiterObj(fiterStateForTabClick);
    setSearchText('');
    localStorage.setItem('transactionFilter', JSON.stringify(fiterStateForTabClick));

    // if selected item title is Earned __
    if (itemTitle === 'Earned' && tabNavTitle !== 'Earned') {
      const formatedData =
        data &&
        data.length > 0 &&
        data.filter((item: any) => {
          if (item && item.amount > 0) {
            return item;
          }
        });

      setGridData(formatedData);
    }

    // if selected item title is Spent __
    if (itemTitle === 'Spent' && tabNavTitle !== 'Spent') {
      const formatedData =
        data &&
        data.length > 0 &&
        data.filter((item: any) => {
          if (item && item.amount < 0) {
            return item;
          }
        });

      setGridData(formatedData);
    }

    if (
      (itemTitle === 'Spent' && tabNavTitle === 'Spent') ||
      (itemTitle === 'Earned' && tabNavTitle === 'Earned')
    ) {
      setTabNavTitle(undefined);

      if (data && data.length > 0) {
        setGridData(data);
      }

      localStorage.removeItem('transactionFilter');
    }
  };

  const handleSearch = (event: { target: { value: any } }) => {
    setSearchText(event.target.value);
    localStorage.setItem(
      'transactionFilter',
      JSON.stringify({
        ...fiterObj,
        searchText: event.target.value,
      })
    );
  };

  const handleResetSearch = () => {
    setSearchText('');
    localStorage.setItem(
      'transactionFilter',
      JSON.stringify({
        ...fiterObj,
        searchText: '',
      })
    );
  };

  const getSpendLimit = cardData?.spending_controls?.spending_limits[0]?.amount ?? 0;

  return (
    <>
      {(isLoading || isFetching || filterProcessing) && <ManualLoader />}
      <FinanceAccountListCommonHeader navigate={navigate} />
      <Wrapper isAuthorizedWrapper>
        <WrpperAuthorizedContainer>
          <Box>
            {data && isSuccess && isSuccessCD && (
              <TransactionCardGrid
                data={gridData}
                title={t('finance.transaction.list.cardHeading')}
                onClickTabNavItem={handleTabNavClick}
                selectedTabNavTitle={tabNavTitle}
                onHandleResetSearch={handleResetSearch}
                onHandleSearch={handleSearch}
                searchText={searchText}
                setFilterSchema={setFilterSchema}
                amountSliderMaximumAmout={getSpendLimit}
                setSearchText={setSearchText}
                setFilterProcessing={setFilterProcessing}
              />
            )}

            {data && data.length === 0 && (
              <TransactionCardEmpty
                title={t('finance.transaction.list.cardHeading')}
                imagePath={EmptyStates.aprroved}
                emptyStateTitle={t('finance.transaction.list.emptyStateTitle')}
                emptyStateContent={t('finance.transaction.list.emptyStateContent')}
              />
            )}
          </Box>
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
