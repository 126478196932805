import { Accordion } from 'src/components/base/core/OtherUtilities/Accordions/Accordion/Accordion';
import { FilterTag } from '../FilterTag/FilterTag';
import { FilterContentWrapper } from '../FilterModal/FilterModal.style';
import { TransactionDataSortingProps } from 'src/models/component/base.model';

export const DataSorting = ({
  handleOnChangeDate,
  selectedOptionDateSorting,
  t,
}: TransactionDataSortingProps) => {
  return (
    <Accordion
      title={t('earning.filter.sortFilter.title')}
      isFilterActive={selectedOptionDateSorting !== ''}
      isNoBorder={true}
    >
      <FilterContentWrapper>
        <FilterTag
          inputName={'sort'}
          title={t('earning.filter.sortFilter.date')}
          idValue={'created_desc'}
          iconType="SortingIcon"
          inputValue={`created_desc`}
          handleOnChange={handleOnChangeDate}
          className={'sorting_tag'}
          selectedOption={selectedOptionDateSorting}
        />
        <FilterTag
          inputName={'sort'}
          title={t('earning.filter.sortFilter.date')}
          inputValue={`created_asc`}
          idValue={'created_asc'}
          iconType="SortingIconDSC"
          handleOnChange={handleOnChangeDate}
          className={'sorting_tag'}
          selectedOption={selectedOptionDateSorting}
        />
      </FilterContentWrapper>
    </Accordion>
  );
};
