export const COLORS = {
  white: '#fff',
  gray: {
    900: '#141518',
    800: '#2C2D30',
    700: '#404145',
    600: '#606266',
    500: '#7E8085',
    400: '#9FA1A6',
    300: '#BABDC2',
    200: '#D5D7DB',
    100: '#E8EAED',
    50: '#F8F9FA',
  },
  blue: {
    500: '#635BFF',
    400: '#726AFF',
    300: '#8C86FF',
    200: '#BDBAFF',
    100: '#DAD8FF',
    75: '#EDECFF',
    50: '#F7F8FF',
  },
  darkBlue: {
    500: '#05006D',
    400: '#1E188F',
    300: '#5A53DD',
    200: '#AEAAFF',
    100: '#DCDAFF',
    75: '#F0EFFF',
    50: '#F3FAFC',
  },
  green: {
    500: '#357219',
    400: '#469721',
    300: '#56B828',
    200: '#9AE279',
    100: '#C0ED96',
    75: '#DDF5C7',
    50: '#F5FCEE',
  },
  red: {
    500: '#A4233F',
    400: '#D84665',
    300: '#DF6881',
    200: '#E6899D',
    100: '#F1BCC7',
    75: '#FBE8F1',
    50: '#FCEEF1',
  },
  yellow: {
    500: '#E49900',
    400: '#FFBC21',
    300: '#FEC63D',
    200: '#FDD570',
    100: '#F9ECBF',
    75: '#FCF4DA',
    50: '#FDF9EC',
    40: '#FDF6E1',
  },
  orange: {
    500: '#F76F34',
    400: '#FC733F',
    300: '#FC895E',
    200: '#FCB59B',
    100: '#FCD4C6',
    75: '#FCE4DB',
    50: '#FCF4F0',
  },
  messageLink: {
    main: '#3C4257',
  },
  input: {
    normal: '#E8EAED',
  },
  neutral: {
    main: '#6A7383',
  },
  primary: {
    main: '#635BFF',
  },
  error: {
    main: '#A4233F',
    contrastText: '#A4233F',
  },
  warning: {
    main: '#E49900',
    contrastText: '#E49900',
  },
  success: {
    main: '#357219',
    contrastText: '#357219',
  },
  info: {
    main: '#05006D',
    contrastText: '#05006D',
  },
};

export interface ColorsInterface {
  white: string;
  gray: {
    900: string;
    800: string;
    700: string;
    600: string;
    500: string;
    400: string;
    300: string;
    200: string;
    100: string;
    50: string;
  };
  blue: {
    500: string;
    400: string;
    300: string;
    200: string;
    100: string;
    75: string;
    50: string;
  };
  darkBlue: {
    500: string;
    400: string;
    300: string;
    200: string;
    100: string;
    75: string;
    50: string;
  };
  green: {
    500: string;
    400: string;
    300: string;
    200: string;
    100: string;
    75: string;
    50: string;
  };
  red: {
    500: string;
    400: string;
    300: string;
    200: string;
    100: string;
    75: string;
    50: string;
  };
  yellow: {
    500: string;
    400: string;
    300: string;
    200: string;
    100: string;
    75: string;
    50: string;
    40: string;
  };
  orange: {
    500: string;
    400: string;
    300: string;
    200: string;
    100: string;
    75: string;
    50: string;
  };
  messageLink: {
    main: string;
  };
  input: {
    normal: string;
  };
  neutral: {
    main: string;
  };
}
