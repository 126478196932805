import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import {
  ButtonAuthorizedWrapper,
  DivAuthorizedWrapper,
  Header,
  HeaderFixed,
  NoteText,
  Wrapper,
  WrpperAuthorizedContainer,
  TransactionSummaryCard,
  TransactionSummaryPaymentInfoCard,
  ManualLoader,
} from '../../../../components/base/core';

import {
  useGetTransactionDetailsQuery,
  useGetTransactionSummaryQuery,
  useGetVertualCardDetailsQuery,
} from 'src/services/account/accountServiceApi';

import { handleTransactionAmountWithoutSymbol } from 'src/helpers/helperHandlingTableGrid';
import { getNameWords } from 'src/shared/utilities/dataConvertions';

export const FinanceAccountListDetailContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const { backRouteUrl } =
    location?.state != null
      ? (location?.state as { backRouteUrl: string })
      : {
          backRouteUrl: '/finance/account/list',
        };

  const { data, isSuccess, isLoading } = useGetTransactionSummaryQuery(id);
  const {
    data: tdData,
    isSuccess: isSuccessTD,
    isLoading: isLoadingTD,
  } = useGetTransactionDetailsQuery(null);
  const {
    data: cardData,
    isSuccess: isSuccessCD,
    isLoading: isLoadingCD,
  } = useGetVertualCardDetailsQuery(true);

  const getFirstName = cardData?.cardholder?.individual?.first_name ?? null;
  const getLastName = cardData?.cardholder?.individual?.last_name ?? null;
  const [amount, plusValue]: any = handleTransactionAmountWithoutSymbol(data?.amount ?? 0);
  const date =
    data && data?.created
      ? moment.unix(data?.created).tz('America/Chicago').format('MMM DD, YYYY')
      : '';
  const time =
    data && data?.created
      ? moment.unix(data?.created).tz('America/Chicago').format('hh:mm A z')
      : '';

  return (
    <>
      {isLoadingTD || isLoadingCD || (isLoading && <ManualLoader />)}
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonHide={false}
          isBackButtonVisible
          paddingX={0}
          isLogoVisible={false}
          headerText={'Transaction Details'}
          withCenteredHeaderText
          onBackButtonClick={() => navigate(backRouteUrl)}
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper>
        <WrpperAuthorizedContainer>
          <DivAuthorizedWrapper>
            {isSuccess && isSuccessTD && (
              <>
                {isSuccessCD && (
                  <TransactionSummaryPaymentInfoCard
                    dateStamp={date}
                    timeStamp={time}
                    currentBalance={amount}
                    name={cardData?.cardholder?.name ?? ''}
                    nameFirstCharacters={getNameWords(cardData?.cardholder?.name)}
                    isSpentTransaction={!plusValue}
                  />
                )}
                <TransactionSummaryCard
                  valueType={plusValue ? 'Incoming Payment' : 'Outgoing Payment'}
                  valueAccount={tdData?.financial_addresses[0]?.aba?.account_number_last4}
                  valueRouting={tdData?.financial_addresses[0]?.aba?.routing_number}
                  valueMethod={plusValue ? 'Instant' : 'ACH'}
                  valueId={id}
                  valueDescription={data?.description}
                  valueMethodContent={
                    plusValue
                      ? '(1-60 mins)'
                      : `(1-2 ${t(
                          'finance.MoveMoney.financeMoveMoneySummaryDetailsCard.methodContent'
                        )})`
                  }
                  isIncomingPayment={plusValue}
                  valueDestination={tdData?.metadata?.nickname ?? null}
                  t={t}
                />
              </>
            )}
            <ButtonAuthorizedWrapper>
              <NoteText>
                {t('finance.transaction.details.termsNote')}{' '}
                <a href="mailto:support@payup.com">support@payup.com</a>
              </NoteText>
            </ButtonAuthorizedWrapper>
          </DivAuthorizedWrapper>
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
