import { GetEarningsSubmitDetails } from 'src/services/earnings/submited.service';
import { configs } from 'src/shared/config/config';
import { payoutsProps } from 'src/shared/utilities/interface';

export const getInvoiceData = async (
  { filter, hideLoader, limit, pageOffset, first, sort }: payoutsProps,
  isCurrent: boolean
) => {
  const res = await GetEarningsSubmitDetails(filter, hideLoader, limit, pageOffset, first, sort);

  if (res?.status === 200) {
    const totalCnt = Math.ceil(res.data.payouts.totalCount / (limit ?? configs.DEFAULT_LIMIT)) - 1;
    return { data: res?.data, totalCnt: totalCnt };
  } else {
    return { data: res?.data, totalCnt: 0 };
  }
};
