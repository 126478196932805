import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

function appHeight() {
  const doc = document.documentElement;
  doc.style.setProperty('--vh', window.innerHeight * 0.01 + 'px');
}

window.addEventListener('resize', appHeight);
appHeight();

export const ModalWrapper = styled('div')(
  ({ theme }) => `
  display: flex;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  justify-content: center;
  align-items: center;

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    justify-content: center;
    align-items: center;
  }
`
);

export const ModalOverlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(26, 31, 54, 0.56);
  z-index: 1;
`;

export const ModalClose = styled('div')`
  width: 10px;
  height: 10px;
  position: absolute;
  right: 25px;
  right: 25px;
  cursor: pointer;
`;

export const ModalContainer = styled('div')(
  ({ theme }) => `
  background: ${theme.palette.white};
  padding: 24px;
  z-index: 2;
  width: calc(100% - 46px);
  overflow-y: auto;
  max-height: calc(var(--vh, 1vh) * 100);

  &:not(.confirmation_container) {
    min-height: calc(calc(var(--vh, 1vh) * 100) - 82px);
  }

  &.is_fixed {
    width: 100%;
    padding: 24px 16px 16px;
    background: linear-gradient(181.57deg, #e2e9ff -39.28%, #ffffff 49.33%);
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    height: auto;
    max-width: 312px;
    border-radius: 8px;

    &.is_fixed {
      border-radius: 8px 8px 0px 0px;
      position: absolute;
      bottom: 0;
      padding-bottom: 80px;
      max-width: 100%;
      overflow-y: auto;
    }
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    border-radius: 8px;
    position: relative;
    height: auto;
    max-width: 312px;

    &.is_fixed {
      max-width: 400px;
    }
  }
`
);

export const ModalHeader = styled('div')(
  ({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing(6)};
`
);

export const ModalHeaderContainer = styled('div')`
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
`;

export const ModalContent = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const BottomSpace = styled('div')(
  ({ theme }) => `
  height: ${theme.spacing(20)};
`
);

export const ModalButton = styled('div')(
  ({ theme }) => `
  z-index: 1;
  &.is_button_not_fixed {
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      max-width: 136px;
    }
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    &:not(.is_button_not_fixed) {
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;
      background-color: #fff;
      padding: 4px 16px 24px;
    }
  }
`
);

export const ModalTermCardWrapper = styled('label')(
  ({ theme }) => `
  display: flex;
  width: 100%;
  margin: 0 0 12px;
  position: relative;
  flex-direction: column;

  &.in_active {
    pointer-events: none;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: ${theme.palette.white};
      opacity: 0.6;
    }

    label {
      border: 1px solid ${theme.palette.gray[100]};
    }
  }

  input {
    position: absolute;
    visibility: hidden;
    height: 0;
    width: 0;
    overflow: hidden;
    opacity: 0;
  }

  .card_timeline {
    display: none;
    padding-top: 12px;
  }

  label {
    border: 1px solid ${theme.palette.gray[100]};
    background: #fff;
    display: flex;
    width: 100%;
    border-radius: 13px;
    min-height: 52px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &.default_label {
      border: 1px solid ${theme.palette.primary.main};
      background: ${theme.palette.white};
      pointer-events: none;

      + .card_timeline {
        display: none !important;
      }
    }
  }

  input:checked + label {
    border: 1px solid ${theme.palette.primary.main};
    background: ${theme.palette.blue[75]};

    &.default_label {
      border: 1px solid ${theme.palette.primary.main};
      background: ${theme.palette.white};
    }
  }

  input:checked ~ .card_timeline {
    display: block;
  }
`
);

export const ModalTermCardLeft = styled('div')(
  ({ theme }) => `
  display: flex;

  justify-content: start;
  padding-left: 12px;
  align-items: center;

  .visible_days {
    width: 112px;
    min-width: 112px;
    display: inline-flex;
    line-height: 1.2;
    margin-right: 4px;
    flex-direction: column;
  }

  span {
    font-size: 10px;
    line-height: 1.2;
  }

  .default_tag {
    margin-left: 12px;
    margin-right: 18px;
    padding:1px 6px;
    background: ${theme.palette.blue[50]};
    border: 1px solid ${theme.palette.blue[50]};
    border-radius: 4px;
    font-weight: 400;
    font-size: 10px;
    color: ${theme.palette.primary.main};
    letter-spacing: -0.14px;
    font-size: 12px;
    line-hight: 16px;
  }

  .discount_percent {
    text-decoration-line: line-through;
    margin-right: 5px;
    display: inline-flex;
    white-space: nowrap;
  }

  .discount_value {
    white-space: nowrap;
  }

  .fee_details {
    display: flex;
    align-items: center;
  }

  * {
    line-height: 1;
  }

  &.is_full_width {
    width: 100%;
    padding-right: 12px;
    justify-content: space-between;

    .fee_details {
      > span {
        order: 2;
      }

      .default_tag {
        order: 1;
      }
    }

    .discount_percent,
    .discount_value span {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }

    .discount_value {
      color: ${theme.palette.gray[500]};

      span {
        display: inline-flex;
      }

      .amount_text {
        padding-right: 6px;
        width: 38px;

        &.zero_amount {
          justify-content: flex-end;
        }
      }
    }

    .discount_percent {
      width: 24px;
      color: ${theme.palette.gray[300]};
    }
  }

  &.is_not_full_width {
    width: calc(100% - 120px);

    .discount_percent,
    .discount_value {
      color: ${theme.palette.gray[400]};
    }

    .discount_percent {
      width: 15px;
    }
  }
`
);

export const ModalTermCardRight = styled('div')`
  width: 100px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: end;

  span {
    color: #1a1f36;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding-right: 20px;
  }

  svg {
    stroke: #464646;
  }
`;

export const CustomerAdditionConfirmModalIconWrapper = styled(Box)(
  ({ theme }) => `
  display: flex;
  justify-content: center;

  svg {
   color: ${theme.palette.primary.main};
  }
`
);
