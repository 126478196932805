import { styled } from '@mui/material/styles';

function appHeight() {
  const doc = document.documentElement;
  doc.style.setProperty('--vh', window.innerHeight * 0.01 + 'px');
}

window.addEventListener('resize', appHeight);
appHeight();

const spacingContainer = {
  modalHeightDesktop: '100vh',
  modalHeightMobile: 'calc(var(--vh, 1vh) * 100)',
};

export const ModalWrapper = styled('div')(
  ({ theme }) => `
  display: flex;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  justify-content: center;
  align-items: center;
  
  @media (min-width: ${theme.breakpoints.values.sm}px) {
    justify-content: center;
    align-items: center;
    height: ${spacingContainer.modalHeightDesktop};
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    justify-content: center;
    align-items: center;
    height: ${spacingContainer.modalHeightMobile};
    height: -webkit-fill-available;
  }
`
);

export const ModalOverlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(26, 31, 54, 0.6);
  z-index: 1;
`;

export const ModalClose = styled('div')`
  width: 10px;
  height: 10px;
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
`;

export const ModalTitle = styled('div')(
  ({ theme }) => `
  width: 100%;
  display: flex;
  margin-bottom: ${theme.spacing(8)};
  text-align: center;
  justify-content: center;
`
);

export const ModalContainer = styled('div')(
  ({ theme }) => `
  background: linear-gradient(181.57deg, #E2E9FF -39.28%, #FFFFFF 49.33%);;
  border: 1px solid rgba(241, 241, 241, 0.25);
  padding: 16px 14px;
  z-index: 2;
  width: calc(100% - 46px);

  &.is_fixed {
    width: 100%;
    padding: 24px 16px;
  }
  

  @media (max-width: ${theme.breakpoints.values.sm}px) {
   height: auto;
   border-radius: 8px;

   &.is_fixed {
     border-radius: 8px 8px 0px 0px;
     position: absolute;
     bottom: 0;
     padding-bottom: 40px;
     max-width: 100%;
     max-height: ${spacingContainer.modalHeightMobile};
     overflow-y: auto;
     width: 100%;
   }

   &.mobile_bottom__not_fixed {
    width: calc(100% - 70px);
    max-width: 305px;
    box-sizing: border-box;
    min-height: 2px !important;
   }
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
   border-radius: 8px;
    position: relative;
    height: auto;
    max-width: 312px;

    &.is_fixed {
      max-width: 400px;
      max-height: calc(${spacingContainer.modalHeightDesktop} - 100px);
      overflow-y: auto;
    }
  }
`
);
