import { styled } from '@mui/material/styles';

export const LinearLoader = styled('div')<{ displayTime: number }>(({ theme, displayTime }) => ({
  height: 4,
  backgroundColor: theme.palette.blue[500],
  animation: `Loader ${displayTime}ms linear`,
  borderRadius: 8,

  '@keyframes Loader': {
    '0%': {
      width: 0,
      height: 4,
    },
    '100%': {
      width: 4,
      height: 4,
    },
  },
}));

export const LoaderBg = styled('div')(
  ({ theme }) => `
  flex: 1;
  height: 4px;
  width: 4px;
  margin-left: ${theme.spacing(1)};
  margin-right: ${theme.spacing(1)};
  background-color: ${theme.palette.blue[75]};
  border-radius: 8px;
`
);

export const LoadedBg = styled(LoaderBg)(
  ({ theme }) => `
  background-color: ${theme.palette.blue[500]};
  width: 4px;
`
);

export const LoadersRow = styled('div')(
  ({ theme }) => `
  position: relative;
  display: inline-flex;
  flex-direction: row;
  width: auto;
  justify-content: center; 
`
);

export const TransparentBox = styled('div')`
  position: absolute;
  display: flex;
  flex-direction: row;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
`;

export const Swiper = styled('div')<{ toLeft: boolean }>(({ theme, toLeft }) => ({
  animation: `${toLeft ? ' SwipeToLeft' : 'SwipeToRight'} 300ms ease-out`,

  [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
    minHeight: '408px',
  },

  '@keyframes SwipeToLeft': {
    '0%': {
      transform: 'translateX(100%)',
    },
    '100%': {
      transform: 'translateX(0)',
    },
  },

  '@keyframes SwipeToRight': {
    '0%': {
      transform: 'translateX(-100%)',
    },
    '100%': {
      transform: 'translateX(0)',
    },
  },
}));
