import { useState } from 'react';
export const initialStates = {
  openModal: false,
  comapnySelection: [],
};

export const helperAccountInfo = () => {
  const [states, setStates]: any = useState(initialStates);

  return [states, setStates];
};
