import { NavigateFunction } from 'react-router-dom';

import { Header, HeaderFixed } from '../../../../../components/base/core';

interface IFinanceAccountListCommonHeader {
  navigate: NavigateFunction;
}

export const FinanceAccountListCommonHeader = ({ navigate }: IFinanceAccountListCommonHeader) => {
  return (
    <HeaderFixed className="fixed_only_desktop">
      <Header
        isMainMunuVisible
        isBackButtonHide={false}
        isBackButtonVisible
        paddingX={0}
        isLogoVisible={false}
        headerText={'Transactions'}
        withCenteredHeaderText
        onBackButtonClick={() => navigate('/finance/account/main')}
      />
    </HeaderFixed>
  );
};
