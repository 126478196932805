import { useEffect, useState } from 'react';
import { EARNINGS, PAY3349 } from 'src/constants/constants';
import { useGetAccountQuery } from 'src/services/account/accountServiceApi';
import { getInvoiceData } from '../getInvoiceData';
import { getTransfersData } from '../getTransfersData';

export const handleHome = () => {
  const [responsePaid, setResponsePaid]: any = useState();
  const [responseSubmitted, setResponseSubmitted]: any = useState();
  const [responseApproved, setResponseApproved]: any = useState();
  const [responseTrans, setResponseTrans]: any = useState();
  const [account, setAccount]: any = useState();
  const { data, isFetching } = useGetAccountQuery('');

  const getParams = (type: any, sortOrder: string) => {
    return {
      first: 3,
      offset: 0,
      filter: {
        status: { equalTo: type },
      },
      sort: sortOrder,
    };
  };

  const dataParams: any = getParams(EARNINGS.PAID, EARNINGS.PAID_AT_DESC);

  const dataParamsSubmitted: any = getParams(EARNINGS.SUBMITTED, EARNINGS.INVOICED_AT_DESC);

  const dataParamsApproved: any = getParams(EARNINGS.APPROVED, EARNINGS.APPROVED_AT_DESC);

  const mapPaidData = async () => {
    const { data, totalCnt } = await getInvoiceData(dataParams, true);
    setResponsePaid(data);
  };

  const mapApproveData = async () => {
    const { data, totalCnt } = await getInvoiceData(dataParamsApproved, true);
    setResponseApproved(data);
  };

  const mapSubmittedData = async () => {
    const { data, totalCnt } = await getInvoiceData(dataParamsSubmitted, true);
    setResponseSubmitted(data);
  };

  const mapTransData = async () => {
    const { data } = await getTransfersData({
      first: 3,
      pageOffset: 0,
      details: false,
    });
    setResponseTrans(data);
  };

  useEffect(() => {
    if (PAY3349) {
      mapTransData();
    } else {
      mapPaidData();
    }
    mapSubmittedData();
    mapApproveData();
  }, []);

  useEffect(() => {
    setAccount(data);
  }, [data]);

  return [responsePaid, responseSubmitted, responseApproved, responseTrans, account];
};
