import axios from 'axios';
import config from '../config';

export const LogoutApi = async (hideLoader?: boolean) => {
  config(hideLoader);
  return axios
    .post(`/logout`)
    .then((res) => res)
    .catch((error) => error.response.data);
};
