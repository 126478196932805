import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UpdateFile } from 'src/services/account/account.service';
import {
  useGetDocumentTypesQuery,
  // useUpdateFileMutation,
} from 'src/services/account/accountServiceApi';

export const idDocumentStates = {
  modalVisbility: true,
  selectedDocumentType: null,
  uploadMode: null,
  selctedContainerVisible: false,
  front: undefined,
  back: undefined,
  termsConfirmDoc: false,
  termsConfirmName: false,
};

const visibleSteps = {
  step1: true,
  step2: false,
  step3: false,
  step4: false,
};

export const helperIdDocumentUpload = () => {
  const [modalStates, setModalStates]: any = useState(idDocumentStates);
  const [stepsVisibility, setStepsVisibility]: any = useState(visibleSteps);
  const [uploadLoaderEnabled, setUploadLoaderEnabled] = useState(false);
  const { t } = useTranslation();
  // const [updateFileMutation] = useUpdateFileMutation();

  /**
   * Handle Modal close event __
   */
  const handleCloseModal = () => {
    setModalStates(idDocumentStates);
    localStorage.removeItem('set_id_modal_states');
    setStepsVisibility(visibleSteps);
  };

  useEffect(() => {
    const getStorageItem = localStorage.getItem('set_id_modal_states');
    const updateTheObject = getStorageItem && JSON.parse(getStorageItem);
    const containerVisibility = getStorageItem && updateTheObject.selctedContainerVisible;

    if (containerVisibility) {
      setModalStates(updateTheObject);
    }

    if (!containerVisibility) {
      localStorage.removeItem('set_id_modal_states');
    }
  }, []);

  /**
   * handle the content __
   */
  const handleHeadingContent = () => {
    let contentSummary = {
      heading: t('idDocument.step1.heading'),
      content: t('idDocument.step1.content'),
    };

    if (stepsVisibility.step2) {
      contentSummary = {
        ...contentSummary,
        content: t('idDocument.step2.content'),
      };
    }

    if ((stepsVisibility.step3 || stepsVisibility.step4) && modalStates.uploadMode === 'camera') {
      contentSummary = {
        heading: t('idDocument.step3.heading2'),
        content: t('idDocument.step3.content2'),
      };
    }

    if ((stepsVisibility.step3 || stepsVisibility.step4) && modalStates.uploadMode === 'upload') {
      contentSummary = {
        ...contentSummary,
        content: t('idDocument.step3.content'),
      };
    }

    return contentSummary;
  };

  /**
   * Handle Document option click __
   */
  const onDocumentItemClick = (event: any) => {
    const getSelectedValue = event.target.getAttribute('data-label');
    setModalStates({
      ...modalStates,
      selectedDocumentType: getSelectedValue,
    });

    setStepsVisibility({
      ...stepsVisibility,
      step1: false,
      step2: true,
    });
  };

  const handleStepTwoVisibility = () => {
    setStepsVisibility({
      ...stepsVisibility,
      step2: false,
      step3: true,
    });
  };

  const onUploadButtonClick = () => {
    setModalStates((modalStates: any) => ({
      ...modalStates,
      uploadMode: 'upload',
    }));
    handleStepTwoVisibility();
  };

  const onCameraButtonClick = () => {
    setModalStates((modalStates: any) => ({
      ...modalStates,
      uploadMode: 'camera',
    }));
    handleStepTwoVisibility();
  };

  /**
   * Get file upload id __
   */
  const onFileChange = async (event: any) => {
    const files = event.target.files;
    const targetid = String(event.target.id);
    const data = new FormData();

    if (targetid !== undefined) {
      data.append(targetid, files[0]);
    }

    setUploadLoaderEnabled(true);

    try {
      const response = await UpdateFile(data);

      if (targetid && targetid === 'front') {
        setModalStates((modalStates: any) => ({ ...modalStates, front: response.data[0].id }));
        localStorage.setItem('set_id_modal_states', JSON.stringify(modalStates));
      } else {
        setModalStates((modalStates: any) => ({ ...modalStates, back: response.data[0].id }));
        localStorage.setItem('set_id_modal_states', JSON.stringify(modalStates));
      }
      setUploadLoaderEnabled(false);
    } catch (error: any) {
      console.log(error);
      setUploadLoaderEnabled(false);
    }
  };

  /**
   * Terms Confirmations
   */
  const onTermsConfirmationName = (event: any) => {
    setModalStates((modalStates: any) => ({
      ...modalStates,
      termsConfirmName: event?.target?.checked,
    }));
  };

  const onTermsConfirmationDocument = (event: any) => {
    setModalStates((modalStates: any) => ({
      ...modalStates,
      termsConfirmDoc: event?.target?.checked,
    }));
  };

  const handleSubmitButtonEvent = () => {
    setModalStates((modalStates: any) => ({
      ...modalStates,
      modalVisbility: false,
      selctedContainerVisible: true,
    }));
  };

  const onBackButtonClick = () => {
    if (stepsVisibility.step3 && modalStates.front !== undefined) {
      setStepsVisibility({
        ...stepsVisibility,
        step2: false,
        step3: true,
      });

      setModalStates((modalStates: any) => ({
        ...modalStates,
        front: undefined,
        back: undefined,
        termsConfirmDoc: false,
        termsConfirmName: false,
      }));
    }

    if (stepsVisibility.step3 && modalStates.front === undefined) {
      setStepsVisibility({
        ...stepsVisibility,
        step1: false,
        step2: true,
        step3: false,
      });

      setModalStates((modalStates: any) => ({
        ...modalStates,
        front: undefined,
        back: undefined,
        termsConfirmDoc: false,
        termsConfirmName: false,
      }));
    }
    if (stepsVisibility.step2) {
      setStepsVisibility({
        ...stepsVisibility,
        step1: true,
        step2: false,
        step3: false,
      });

      setModalStates((modalStates: any) => ({
        ...modalStates,
        uploadMode: null,
      }));
    }
  };

  const isPassportBackButtonVisible =
    modalStates.selectedDocumentType === 'Passport' &&
    modalStates.uploadMode === 'camera' &&
    modalStates.uploadMode !== null;

  const isFrontImageVisible =
    modalStates.front === undefined ||
    (modalStates.selectedDocumentType === 'Passport' && modalStates.uploadMode === 'camera');

  const isSubmitButtonVisibility =
    modalStates.front !== undefined &&
    modalStates.termsConfirmDoc === true &&
    modalStates.termsConfirmName === true;

  return [
    modalStates,
    setModalStates,
    handleCloseModal,
    stepsVisibility,
    setStepsVisibility,
    handleHeadingContent,
    onDocumentItemClick,
    onUploadButtonClick,
    onCameraButtonClick,
    onFileChange,
    isPassportBackButtonVisible,
    isFrontImageVisible,
    onTermsConfirmationDocument,
    onTermsConfirmationName,
    isSubmitButtonVisibility,
    handleSubmitButtonEvent,
    uploadLoaderEnabled,
    onBackButtonClick,
  ];
};

/**
 * Get the document List __
 */
export const getDocumentData = () => {
  const [documentData, setDocumentData] = useState<any>();
  const { data, isSuccess } = useGetDocumentTypesQuery(null);

  useEffect(() => {
    if (isSuccess) {
      var keys = Object.keys(data);
      var object = [];
      for (var i = 0, len = keys.length; i < len; i++) {
        object.push(data[keys[i]]);
      }

      setDocumentData(object);
    }
  }, [isSuccess]);

  return [documentData];
};

/**
 * Handle id document upload module visibility __
 */
export const handleIdModalVisibility = () => {
  const [openIdModal, setOpenIdModal]: any = useState(false);

  useEffect(() => {
    const getStorageItem = localStorage.getItem('set_id_modal_states');
    const updateTheObject = getStorageItem && JSON.parse(getStorageItem);
    const containerVisibility = getStorageItem && updateTheObject.selctedContainerVisible;

    if (containerVisibility) {
      setOpenIdModal(true);
    }

    if (!containerVisibility) {
      localStorage.removeItem('set_id_modal_states');
    }
  }, []);

  const onClickIdModalOpen = () => {
    const elmentWrapper: any = document.getElementById('wrapperContainer');
    const getStorageItem = localStorage.getItem('set_id_modal_states');
    const updateTheObject = getStorageItem && JSON.parse(getStorageItem);
    const containerVisibility = getStorageItem && updateTheObject.selctedContainerVisible;
    elmentWrapper.style.overflowY = 'hidden';

    if (!containerVisibility) {
      setOpenIdModal(true);
      localStorage.removeItem('set_id_modal_states');
    }
  };

  const onClickIdModalClose = () => {
    const elmentWrapper: any = document.getElementById('wrapperContainer');
    setOpenIdModal(false);
    localStorage.removeItem('set_id_modal_states');
    elmentWrapper.style.overflowY = 'auto';
  };

  return [openIdModal, onClickIdModalOpen, setOpenIdModal, onClickIdModalClose];
};

/**
 * EIN document Upload
 */

export const EINidDocumentStates = {
  modalVisbility: true,
  selctedContainerVisible: false,
  front: undefined,
  back: undefined,
};

export const helperEINDocumentUpload = () => {
  const [modalStates, setModalStates]: any = useState(EINidDocumentStates);
  const [uploadLoaderEnabled, setUploadLoaderEnabled] = useState(false);

  /**
   * Handle Modal close event __
   */
  const handleCloseModal = () => {
    const elmentWrapper: any = document.getElementById('wrapperContainer');
    setModalStates(EINidDocumentStates);
    elmentWrapper.style.overflowY = 'auto';
  };

  /**
   * Get file upload id __
   */
  const onFileChange = async (event: any) => {
    const elmentWrapper: any = document.getElementById('wrapperContainer');
    const files = event.target.files;
    const targetid = String(event.target.id);
    const data = new FormData();

    if (targetid !== undefined) {
      data.append(targetid, files[0]);
    }

    setUploadLoaderEnabled(true);

    try {
      const response = await UpdateFile(data);

      if (targetid && targetid === 'front') {
        setModalStates((modalStates: any) => ({
          ...modalStates,
          front: response.data[0].id,
          modalVisbility: false,
          selctedContainerVisible: true,
        }));
      } else {
        setModalStates((modalStates: any) => ({
          ...modalStates,
          back: response.data[0].id,
          modalVisbility: false,
          selctedContainerVisible: true,
        }));
      }

      setUploadLoaderEnabled(false);
      elmentWrapper.style.overflowY = 'auto';
    } catch (error: any) {
      console.log(error);
      setUploadLoaderEnabled(false);
      elmentWrapper.style.overflowY = 'auto';
    }
  };

  return [modalStates, handleCloseModal, onFileChange, uploadLoaderEnabled];
};
