import { useState, useEffect } from 'react';
import { NavigateFunction } from 'react-router-dom';

export const useHandleAuthCallbackRoute = (navigate: NavigateFunction) => {
  const [isPreRouteRedirectionAvailable, setIsPreRouteRedirectionAvailable] = useState(true);

  useEffect(() => {
    const getAfterAuthRoute = localStorage.getItem('auth_callback_route');

    if (getAfterAuthRoute && getAfterAuthRoute !== undefined && getAfterAuthRoute !== 'undefined') {
      navigate(getAfterAuthRoute);
      setIsPreRouteRedirectionAvailable(true);
    } else {
      setIsPreRouteRedirectionAvailable(false);
    }
  }, []);

  return { isPreRouteRedirectionAvailable };
};
