import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import {
  HeadingSummaryBlock,
  BankNavigationCards,
  Messages,
  DivWrapper,
  Header,
  NoteText,
  ContentContainerBottom,
  ShadowCard,
} from 'src/components/base/core';
import { TermsIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import {
  establishFinancialConnectionSession,
  linkExternalAccount,
} from '../../../../services/account/account.service';
import { useStripe } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { commonSpacing, theme } from 'src/shared/theme/theme';
import { nativeBridgeTypes } from 'src/shared/config/config';
import { isJSONObject } from 'src/shared/utilities/dataConvertions';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { useCreateTreasuryAccountMutation } from 'src/services/account/accountServiceApi';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';

export const BankLoginContainer = ({
  onClickManualSetup,
  fromSettings = false,
  isSettingDesktop = false,
  fromHome = false,
  isFromFinance = false,
  isAfterKycFromTermScreen = false,
  isFromPayMode = false,
}: any) => {
  const isOnNativeApp = useSelector((state: RootState) => state.appState);
  const stripe = useStripe();
  const navigate = useNavigate();
  const [errors, setErrors] = useState('');
  const { t } = useTranslation();
  const [createFinanceBankAccount, { isLoading: isLoadingFinaceBA }] =
    useCreateTreasuryAccountMutation();

  const handleResult = async (result: any) => {
    try {
      if (result.error) {
        setErrors(result.error?.message ?? result.error);
        // Inform the customer that there was an error.
      }
      if (result.token?.id) {
        if (!isFromFinance) {
          await linkExternalAccount({ token: result.token?.id }).then((resp: any) => {
            // @ts-ignore
            if (resp && resp?.response?.data?.message) {
              // @ts-ignore
              setErrors(resp?.response?.data?.message);
            } else if (resp && resp?.error) {
              // @ts-ignore
              setErrors(
                resp?.error?.data?.error ?? resp?.error?.data?.message ?? resp?.error?.error
              );
            } else {
              navigate('/bank-setup-success', {
                state: {
                  bankConnect: true,
                  last4: resp?.data?.last4,
                  isFromFinance: isFromFinance,
                  fromSettings: fromSettings,
                  fromHome: fromHome,
                  isSettingDesktop: isSettingDesktop,
                  isFromPayMode: isFromPayMode,
                  isFromFinConnection: true,
                },
              });

              localStorage.setItem('lastFourDigits', `${resp?.data?.last4}`);
              localStorage.setItem('finBankAccountId', `${resp?.data?.id}`);
            }
          });
        } else if (isFromFinance) {
          await createFinanceBankAccount({ token: result.token?.id }).then((resp: any) => {
            // @ts-ignore
            // @ts-ignore
            if (resp && resp?.response?.data?.message) {
              // @ts-ignore
              setErrors(resp?.response?.data?.message);
            } else if (resp && resp?.error) {
              // @ts-ignore
              setErrors(
                resp?.error?.data?.error ?? resp?.error?.data?.message ?? resp?.error?.error
              );
            } else {
              navigate('/bank-setup-success', {
                state: {
                  bankConnect: true,
                  last4: resp?.data?.last4,
                  isFromFinance: true,
                  isFromFinConnection: true,
                },
              });
              localStorage.setItem('lastFourDigits', `${resp?.data?.last4}`);
              localStorage.setItem('finBankAccountId', `${resp?.data?.id}`);
            }
          });
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  // Required to support native iOS app __
  window.addEventListener('message', (message) => {
    if (isJSONObject(message?.data)) {
      const jsonData = JSON.parse(message.data);
      if (jsonData?.type && jsonData?.type === nativeBridgeTypes.STRIPE_FIN_CONN_RESULT) {
        handleResult(jsonData?.result);
      }
    }
  });

  // Required to support native Android app
  document.addEventListener('message', (message) => {
    if (message instanceof MessageEvent && isJSONObject(message?.data)) {
      const jsonData = JSON.parse(message.data);
      if (jsonData?.type && jsonData?.type === nativeBridgeTypes.STRIPE_FIN_CONN_RESULT) {
        handleResult(jsonData?.result);
      }
    }
  });

  const createFinancialConnectionsSession = async () => {
    setErrors('');
    const connectionResponse: any = await establishFinancialConnectionSession();
    // send stripe PK to native
    if (isOnNativeApp && connectionResponse?.data?.client_secret) {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: nativeBridgeTypes.STRIPE_CLIENT_SECRET,
          clientSecret: connectionResponse?.data?.client_secret,
        })
      );
    } else {
      try {
        const result: any = await stripe?.collectBankAccountToken({
          clientSecret: connectionResponse?.data?.client_secret,
        });

        handleResult(result);
      } catch (error: any) {
        console.log(error);
      }
    }
  };

  return (
    <>
      {isLoadingFinaceBA && <ManualLoader />}
      <DivWrapper className="no_fullscreen">
        <Box component={'div'} className={'container_content bottom_terms_padding terms_note'}>
          <Box
            component={'div'}
            className={'container_content_top terms_note'}
            sx={{
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                minHeight: 365 + commonSpacing.desktopPaddingB * 4,
              },
            }}
          >
            <Header
              isBackButtonVisible={true}
              onBackButtonClick={() => {
                if (isAfterKycFromTermScreen) {
                  navigate('/payment-selection', {
                    state: {
                      fromHome: fromHome,
                      isFromFinance: isFromFinance,
                      fromSettings: fromSettings,
                      isAfterKycFromTermScreen: isAfterKycFromTermScreen,
                      isSettingDesktop: isSettingDesktop,
                    },
                  });
                } else {
                  if (fromHome) {
                    navigate('/dashboard');
                  } else if (isFromFinance) {
                    navigate('/finance/account/move-money');
                  } else if (isFromPayMode) {
                    navigate('/settings/terms-selection');
                  } else {
                    fromSettings
                      ? navigate('/settings/bank-setup')
                      : navigate('/payment-selection');
                  }
                }
              }}
              paddingX={0}
              isLanguageSwitcher
            />
            <Box sx={{ marginBottom: 6 }}>
              <HeadingSummaryBlock
                heading={t('headingContent.commonSteps.bankSelection.heading')}
                headingAlignment={'left'}
                content={t('headingContent.commonSteps.bankSelection.content')}
                contentAlignment="left"
              />
            </Box>
            <ShadowCard sx={{ marginBottom: 2 }} paddingTop={6} paddingX={4} paddingBottom={2}>
              <BankNavigationCards onClickEvent={createFinancialConnectionsSession} isArrowVisible>
                <span>{t('bankDetails.step1.connectAPIButton')}</span> <TermsIcon />
              </BankNavigationCards>
              <BankNavigationCards onClickEvent={onClickManualSetup} isArrowVisible>
                <span>{t('bankDetails.step1.mannualSetupButton')}</span>
              </BankNavigationCards>
            </ShadowCard>

            {errors !== '' && (
              <Messages messageHeading="PayUp - Vendor Web App" messageContent={errors}></Messages>
            )}
          </Box>
        </Box>
        <ContentContainerBottom className={'no_fullscreen no_note'}>
          <NoteText />
        </ContentContainerBottom>
      </DivWrapper>
    </>
  );
};
