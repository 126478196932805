import React, { Fragment, useEffect, useState } from 'react';
import { Accordion } from 'src/components/base/core/OtherUtilities/Accordions/Accordion/Accordion';
import { FilterTag } from '../../FilterTag/FilterTag';
import { FilterContentWrapper } from '../../FilterModal/FilterModal.style';
import { handleDatesFilterChange } from 'src/helpers/helperHandlingDates';
import { CustomDateFilter } from '../../CustomDateFilter/CustomDateFilter';
import { GridContext } from 'src/contexts/gridContext';
import { useTranslation } from 'react-i18next';

export interface DateFilterProps {
  setSelectedDateFilterOptions?: (filterObj: any) => void;
  selectedDateFilters?: any;
  resetFilter?: any;
  onDateChange?: (dateObj: any) => void;
  selectedDateRange?: any;
  setSelectedDateRange?: (filterObj: any) => void;
}

export const DateFilter = ({
  setSelectedDateFilterOptions,
  selectedDateFilters,
  resetFilter,
  onDateChange,
  selectedDateRange,
  setSelectedDateRange,
}: DateFilterProps) => {
  const { t } = useTranslation();
  const [dates, setDates]: any = useState([]);
  const [updateDate, setUpdateDate] = useState(false);
  const gridContext = React.useContext(GridContext);
  const { isDateFilterActive, selectedDateFilterValue } = gridContext?.filterStatus;
  const datesList = [
    // { name: 'Today', id: '1', active: false, type: 'checkbox' },
    { name: t('earning.filter.dateFilter.last7'), id: '7', active: false, type: 'checkbox' },
    { name: t('earning.filter.dateFilter.last30'), id: '30', active: false, type: 'checkbox' },
    { name: t('earning.filter.dateFilter.last90'), id: '90', active: false, type: 'checkbox' },
    { name: t('earning.filter.dateFilter.last12m'), id: '364', active: false, type: 'checkbox' },
    { name: t('earning.filter.dateFilter.lastYear'), id: '500', active: false, type: 'checkbox' },
    { name: t('earning.filter.dateFilter.all'), id: 'All', active: true, type: 'checkbox' },
    { name: 'date', id: 'date', active: false, type: 'date' },
  ];

  useEffect(() => {
    if (isDateFilterActive && selectedDateFilterValue !== null) {
      if (selectedDateFilterValue?.isRange) {
        onDateChange && onDateChange(selectedDateFilterValue?.value);
        setSelectedDateRange && setSelectedDateRange(selectedDateFilterValue?.value);
        setUpdateDate(true);
        setSelectedValue('date');
      } else if (selectedDateFilterValue?.value) {
        setSelectedValue(selectedDateFilterValue?.value);
        setSelectedDateRange && setSelectedDateRange([]);
      } else {
        setSelectedDateFilterOptions && setSelectedDateFilterOptions('All');
        setSelectedValue('All');
        setSelectedDateRange && setSelectedDateRange([]);
      }
    } else {
      setSelectedDateFilterOptions && setSelectedDateFilterOptions('All');
      setSelectedValue('All');
      setSelectedDateRange && setSelectedDateRange([]);
    }
  }, []);

  const setSelectedValue = (id: any) => {
    const newArr = datesList.map((obj: any) => {
      if (obj.id === id) {
        return { ...obj, active: true };
      } else {
        return { ...obj, active: false };
      }
    });
    setTimeout(function () {
      setDates(newArr);
    }, 500);
  };

  const cancelDateSelection = () => {
    setSelectedDateFilterOptions && setSelectedDateFilterOptions('All');
    setSelectedValue('All');
  };

  useEffect(() => {
    if (resetFilter) {
      setSelectedDateFilterOptions && setSelectedDateFilterOptions('All');
      setSelectedDateRange && setSelectedDateRange([]);
      setSelectedValue('All');
    } else {
      setDates(datesList);
    }
  }, [resetFilter]);

  return (
    <Accordion title={t('earning.filter.dateFilter.title')} isFilterActive={isDateFilterActive}>
      <FilterContentWrapper>
        {dates &&
          dates.map((option: any, index: any) => {
            return (
              <Fragment key={index}>
                {option.type === 'checkbox' && (
                  <FilterTag
                    title={option.name}
                    key={option.id}
                    inputName={option.id}
                    inputType="checkbox"
                    idValue={option.id}
                    inputValue={option.active}
                    handleOnChange={(e: any) =>
                      handleDatesFilterChange(dates, e, setDates, setSelectedDateFilterOptions)
                    }
                  />
                )}
                {option.type === 'date' && (
                  <CustomDateFilter
                    onDateChange={(event: any) => {
                      if (onDateChange) {
                        onDateChange(event);
                        handleDatesFilterChange(
                          dates,
                          { target: { id: 'date', checked: true } },
                          setDates,
                          setSelectedDateFilterOptions,
                          'date'
                        );
                      }
                    }}
                    key={option.id}
                    isActive={option.active}
                    dates={dates}
                    updateDate={updateDate}
                    selectedDateRange={selectedDateRange}
                    cancelDateSelection={cancelDateSelection}
                  />
                )}
              </Fragment>
            );
          })}
      </FilterContentWrapper>
    </Accordion>
  );
};
