import React from 'react';

import { Typography } from '@mui/material';

import { PayModeNoteWrapper, PayModeNoteRight, PayModeNoteIcon } from './PayModeNote.style';
import { PayModeNoteProps } from 'src/models/component/base.model';
import { TimeIcon } from '../../Media/Icon/Icons/Icons';

export const PayModeNote = ({ content, marginIndex = '0' }: PayModeNoteProps) => {
  return (
    <PayModeNoteWrapper style={{ margin: marginIndex }}>
      <PayModeNoteIcon>
        <TimeIcon width={'20'} height={'20'} fillColor="#635BFF" strokeColor="#635BFF" />
      </PayModeNoteIcon>
      <PayModeNoteRight>
        <Typography variant="h1" color={'gray.800'} lineHeight={1.5}>
          {content}
        </Typography>
      </PayModeNoteRight>
    </PayModeNoteWrapper>
  );
};
