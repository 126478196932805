import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Header,
  HeadingSummaryBlock,
  FormWrapper,
  ContentContainerBottom,
  FormInputSSN,
  TermsText,
  Messages,
} from 'src/components/base/core';
import { handleIdentityVerification } from 'src/helpers/containers/helperIdentityVerification';
import { ERROR_MESSAGES } from 'src/constants/Validation';
import { useEffect, useState } from 'react';
import dtoToFormValues from 'src/shared/utilities/dto';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import moment from 'moment';
import { trackEvent } from '../../../../../../components/analytics/service';
import { useGetBlockHeight } from 'src/hooks/useGetBlockHeight';
import { theme, commonSpacing } from 'src/shared/theme/theme';

export const FormSSNSubmition = ({
  onBackButtonClick,
  onFormSubmit,
  accountData,
  setAccountData,
}: any) => {
  const { t } = useTranslation();
  const [appLogin, isLoading, isNullDOB, errorState, setErrorState]: any =
    handleIdentityVerification(accountData, setAccountData);
  const [enableSSNUpdate, setEnableSSNUpdate]: any = useState(true);
  const navigate = useNavigate();
  const [btnSubmitted, setBtnSubmitted] = useState(false);
  const [elementRef, height] = useGetBlockHeight();

  useEffect(() => {
    if (accountData) {
      dtoToFormValues(setValue, accountData);
      if (!isNullDOB(accountData?.individual.dob)) {
        const dob = accountData?.individual?.dob;
        const formattedDOB: any = moment(new Date(dob.year, dob.month - 1, dob.day));
        setValue('dob', formattedDOB);
      }
      setEnableSSNUpdate(false);
      if (accountData?.individual?.ssn_last4_provided) {
        setEnableSSNUpdate(false);
      } else {
        setEnableSSNUpdate(true);
      }
    }
  }, [accountData]);

  const revieInfoSchema = yup.object({
    idNumber: yup
      .string()
      .test('required', ERROR_MESSAGES.REQUIRED, (value) => {
        if (!enableSSNUpdate || value) return true;
        return false;
      })
      .test('min', ERROR_MESSAGES.SSN_INVALID_LAST4, (value) => {
        if ((value && value?.length === 4) || !enableSSNUpdate) return true;
        return false;
      }),
  });

  //hook-forms
  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(revieInfoSchema),
    mode: 'onSubmit',
  });

  const onAppFormSubmit = handleSubmit(async (data: any) => {
    appLogin(data, onFormSubmit);
  });

  const updateBindingsChange = (event: any, feild: any) => {
    setValue(feild, event.target.value);
  };

  const handleNoSSNClick = () => {
    trackEvent("Don't have SSN");
    navigate('/sign-up');
  };

  return (
    <>
      {isLoading && <ManualLoader />}
      <FormWrapper onSubmit={onAppFormSubmit} className="no_fullscreen">
        <Box component={'div'} className={'container_content bottom_terms_padding terms_note'}>
          <Box
            component={'div'}
            className={'container_content_top terms_note'}
            ref={elementRef}
            sx={{
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                minHeight: height,
                paddingBottom: height ? 4 : commonSpacing.desktopPaddingB,
              },
            }}
          >
            <Header
              isBackButtonVisible={true}
              onBackButtonClick={onBackButtonClick}
              isProgressBarVisible={true}
              totalProgressSteps={5}
              currentProgressStep={2}
              paddingX={0}
              backButtonId={'SsnStepBack'}
            />
            <Box sx={{ marginBottom: 6 }}>
              <HeadingSummaryBlock
                heading={t('headingContent.individual.ssn.heading')}
                headingAlignment="left"
                content={t('headingContent.individual.ssn.content')}
                contentAlignment="left"
              />
            </Box>
            <Controller
              control={control}
              name="line1"
              render={({ field: { value, ref } }) => (
                <FormInputSSN
                  label={t('formInput.ssn')}
                  id={'securityNumber'}
                  only4={true}
                  {...register('idNumber')}
                  error={errors?.idNumber?.message}
                  inputError={errors?.idNumber?.message}
                  onChange={(event: any) => {
                    updateBindingsChange(event, 'idNumber');
                  }}
                  replace={enableSSNUpdate && enableSSNUpdate}
                  setEnableSSNUpdate={setEnableSSNUpdate}
                  inputFocus={(e: any) => {
                    trigger('idNumber');
                  }}
                />
              )}
            />
            <Box component={'div'} paddingY={2}>
              <Typography
                variant="h2"
                color={'primary'}
                sx={{ cursor: 'pointer' }}
                onClick={handleNoSSNClick}
              >
                {t('linkTexts.individual.ssnRedirectLink')}
              </Typography>
            </Box>
            {errorState && (
              <Messages
                topMargin={4}
                bottomMargin={4}
                messageHeading={errorState}
                messageContent={'Please provide valid details to use PayUp.'}
                closeEvent={() => setErrorState('')}
              />
            )}
          </Box>
          <Box component={'div'} className="container_content_bottom terms_note">
            <TermsText
              mainContent={t('noticeBlocks.termsBlock.heading')}
              subContent={t('noticeBlocks.termsBlock.content')}
            />
          </Box>
        </Box>
        <ContentContainerBottom className={'no_note no_fullscreen'}>
          <Button
            variant="containedLarge"
            color="primary"
            type="submit"
            fullWidth
            sx={{ overflow: 'hidden' }}
            id={'SsnStepContinueButton'}
            onClick={() => {
              setBtnSubmitted(true);
            }}
          >
            {t('buttonTexts.continueButton')}
          </Button>
        </ContentContainerBottom>
      </FormWrapper>
    </>
  );
};
