import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import {
  DollarWithBoarderIcon,
  CorrectIcon,
  MoveIcon,
  LongReceiptIcon,
} from '../../Media/Icon/Icons/Icons';

import { InvoiceCardWrapper, InvoiceCardIcon } from './InvoiceCardEmpty.style';
import { theme } from 'src/shared/theme/theme';
import { InvoiceCardProps } from 'src/models/component/base.model';

export const InvoiceCardEmpty = ({
  title,
  icon,
  imagePath,
  emptyStateTitle,
  emptyStateContent,
}: InvoiceCardProps) => {
  const { t } = useTranslation();

  const cardIcon = () => {
    switch (icon) {
      case 'LongReceiptIcon':
        return <LongReceiptIcon />;
      case 'DollarWithBoarderIcon':
        return <DollarWithBoarderIcon />;
      case 'CorrectIcon':
        return <CorrectIcon />;
      case 'MoveIcon':
        return <MoveIcon />;
    }
  };

  return (
    <InvoiceCardWrapper className={`card-wrapper-home-main`}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: 3,
          cursor: 'pointer',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <Typography
            component="div"
            variant="caption3"
            textTransform={'uppercase'}
            sx={{
              marginBottom: 1,
              display: 'inline-flex',
              alignItems: 'center',
            }}
          >
            <InvoiceCardIcon>{cardIcon()}</InvoiceCardIcon>
            {title}
          </Typography>
          <Typography
            component="p"
            variant={'h5Bold'}
            color="gray.800"
            sx={{
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                fontSize: '28px',
              },
            }}
          >
            {`$0`}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
        paddingTop={3}
      >
        <Typography
          variant="h2Bold"
          color={'gray.500'}
          component="p"
          textAlign={'center'}
          marginBottom={2}
        >
          {emptyStateTitle}
        </Typography>
        <Typography
          variant="h1"
          color={'gray.500'}
          component="p"
          fontWeight={400}
          textAlign={'center'}
        >
          {emptyStateContent}
        </Typography>
        <Box paddingTop={4} display={'flex'} justifyContent="center">
          <img src={imagePath} alt={title} height={'86px'} />
        </Box>
      </Box>
    </InvoiceCardWrapper>
  );
};
