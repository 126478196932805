import { Box, Button, Checkbox, FormControlLabel, RadioGroup, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import {
  Header,
  HeadingSummaryBlock,
  FormWrapper,
  ContentContainerBottom,
  FormInput,
  FormInputPhoneNumber,
  FormRadioButton,
  Messages,
  AddMorePhoneNumber,
} from 'src/components/base/core';
import { handleBusinessRepOwnership } from 'src/helpers/containers/helperBusinessRepresentative';
import { dtoToBusinessRepFormValues } from 'src/shared/utilities/dto';
import { businessRepOwnershipSchemaObj } from 'src/helpers/validationSchemas';
import moment from 'moment';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { ERROR_MESSAGES } from 'src/constants/Validation';
import { getAditionalPhoneDetails } from 'src/services/account/additional.phone.service';
import { maskedInput } from 'src/shared/utilities/inputHandlers';
import { FormInputDate } from 'src/components/base/core/FormElements/FormInputDate/FormInputDate';
import { useGetBlockHeight } from 'src/hooks/useGetBlockHeight';
import { theme, commonSpacing } from 'src/shared/theme/theme';
import { AccountParams } from 'src/shared/utilities/interface';
import { initAccount } from 'src/models/init/account.init';
import { handleVerifyPhone } from 'src/helpers/helperPhoneVerificaition';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PhoneVerifyCodeContainer } from '../../../VerifyCode/VerifyCode';
import { setCoupaInfoValuesForEmailLogin } from 'src/helpers/setCoupaValues';
import { getCookie } from 'src/shared/utilities/cookiesHelper';

export const FromVerifyRepresentSubmition = ({
  onBackButtonClick,
  onFormSubmit,
  accountData,
}: any) => {
  const { webauthnEnabled, disabledPhoneVerificationStep } = useFlags();
  const [formData, setFormData] = useState<AccountParams>(initAccount);
  const [verifyCodeContainerVisible, setVerifyCodeContainerVisible] = useState(false);
  const [handleVerificationCode, verifiedPhoneNumber, errorState, isPhoneVerificationLoading] =
    handleVerifyPhone();

  const [
    appSubmit,
    isLoading,
    onChangeHandlerPhone,
    onChangeHandlerDateInput,
    isNullDOB,
    serverSideErrors,
    setServerSideErrors,
    isNot18,
    isValidDOB,
  ]: any = handleBusinessRepOwnership();
  const { t } = useTranslation();
  const [elementRef, height] = useGetBlockHeight();
  const [radioChecked, setRadioChecked] = useState('');
  const [ownerPercentageChecked, setOwnerPercentageChecked] = useState(false);
  const [addtionalNumbers, setAddtionalNumbers]: any = useState();
  const [isSetInputNumber, setIsSetInputNumber]: any = useState(false);
  const [needToDeleteNumber, setNeedToDeleteNumber]: any = useState('');
  const [btnSubmitted, setBtnSubmitted] = useState(false);
  const [errorManualDate, setErrorManualDate] = useState(false);
  const [errorOwnership, setErrorOwnership] = useState('');

  useEffect(() => {
    if (getValues('ownership') === 'executive' && getValues('percentOwnership') > 0) {
      setOwnerPercentageChecked(true);
      setValue('percentOwnership', true);
    }
    if (accountData) {
      if (!getValues('dob') && !isNullDOB(accountData?.individual?.dob)) {
        const dob = accountData?.individual?.dob;
        const formattedDOB: any = moment(new Date(dob.year, dob.month - 1, dob.day));
        setValue('dob', moment(formattedDOB).format('MM/DD/yyyy'));
        if (accountData?.individual?.relationShip?.executive) {
          setRadioChecked('executive');
          setValue('ownership', 'executive');
          setOwnerPercentageChecked(accountData?.individual?.relationShip.percent_ownership > 0);
          setValue('percentOwnership', accountData?.individual?.relationShip.percent_ownership > 0);
        } else if (
          accountData?.individual?.relationShip?.owner &&
          accountData?.individual?.first_name
        ) {
          setRadioChecked('owner');
          setValue('ownership', 'owner');
          setOwnerPercentageChecked(false);
          setValue('percentOwnership', false);
        } else if (!radioChecked) {
          setRadioChecked('');
          setValue('ownership', '');
          setOwnerPercentageChecked(false);
          setValue('percentOwnership', false);
        }
        getAditionalPhoneDetails(accountData?.id).then((result: any) => {
          if (result.data.length > 0) {
            setValue(
              'phone_number_2',
              maskedInput(result.data[0].phone_number?.replace('+1', '')) ?? ''
            );
            setAddtionalNumbers(result.data[0].phone_number);
            setIsSetInputNumber(true);
            setNeedToDeleteNumber(getValues('phone_number_2'));
          } else {
            setValue('phone_number_2', '');
            setAddtionalNumbers('');
            setIsSetInputNumber(false);
            setNeedToDeleteNumber('');
          }
        });
      } else if (!radioChecked) {
        setRadioChecked('');
        setValue('ownership', '');
        setOwnerPercentageChecked(false);
        setValue('percentOwnership', false);
      }
      dtoToBusinessRepFormValues(getValues, setValue, accountData);
      if (!getValues('inputOwnerTitle') && !accountData?.individual?.relationShip?.title) {
        setValue('inputOwnerTitle', 'Manager', { shouldValidate: btnSubmitted && true });
      }

      if (!accountData.individual.email && getCookie('coupaObj')) {
        if (webauthnEnabled) {
          setCoupaInfoValuesForEmailLogin(setValue);
        }
      }
    }
  }, [accountData]);

  const revieInfoSchema = yup
    .object({
      ...businessRepOwnershipSchemaObj,
      phone_number_2: yup.string().test('len', ERROR_MESSAGES.PHONE_NUMBER_INVALID, (val: any) => {
        if (val === undefined || val?.length === 14 || val === '') return true;
        else return false;
      }),
      dob: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .test(
          'len',
          errorManualDate ? ERROR_MESSAGES.DATE_INVALID_LESS_THAN_18 : ERROR_MESSAGES.DATE_INVALID,
          (value) => {
            if (value && value?.length === 10 && isNot18(value) && isValidDOB(value)) return true;
            else return false;
          }
        ),
    })
    .required();
  //hook-forms
  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(revieInfoSchema),
  });

  const checkForOwnerShipError = () => {
    setErrorOwnership('');
    if (
      getValues('ownership') === '' ||
      (radioChecked === '' && getValues('ownership') === 'owner')
    ) {
      setErrorOwnership(t('errorMessages.ownershipError.content1'));
    } else if (getValues('ownership') === 'executive' && !getValues('percentOwnership')) {
      setErrorOwnership(t('errorMessages.ownershipError.content2'));
    }
  };

  const skipTheOTPVerification = (data: any) => {
    appSubmit(data, onFormSubmit, needToDeleteNumber);
    setFormData(initAccount);
    setVerifyCodeContainerVisible(false);
  };

  const moveToOTPVerification = (data: any) => {
    setFormData(data);
    // @ts-ignore
    handleVerificationCode(data, getValues, setVerifyCodeContainerVisible);
  };

  const onAppFormSubmit = handleSubmit(async (data: any) => {
    if (webauthnEnabled && errorOwnership === '' && !disabledPhoneVerificationStep) {
      if (accountData && accountData.individual.phone) {
        if (accountData.individual.phone.includes(data?.phone_number?.replace(/[^0-9]/g, ''))) {
          skipTheOTPVerification(data);
        } else if (accountData.company.phone.includes(data?.phone_number?.replace(/[^0-9]/g, ''))) {
          skipTheOTPVerification(data);
        } else {
          moveToOTPVerification(data);
        }
      } else {
        if (accountData.company.phone.includes(data?.phone_number?.replace(/[^0-9]/g, ''))) {
          skipTheOTPVerification(data);
        } else {
          moveToOTPVerification(data);
        }
      }
    } else {
      errorOwnership === '' && skipTheOTPVerification(data);
    }
  });

  const updateBindingsChange = (event: any, feild: any) => {
    setValue(feild, event.target.value, { shouldValidate: btnSubmitted && true });
  };

  const onChangeHandlerDate = (event: any) => {
    onChangeHandlerDateInput(event, setValue, setErrorManualDate);
  };

  const onChangeHandler = (event: any) => {
    onChangeHandlerPhone(event, getValues, setValue, btnSubmitted);
  };

  const onChangeHandlerAddionalInput = (event: any) => {};

  const handleRep = (val: any) => {
    const { name, id } = val.target;
    if (id !== radioChecked) {
      setValue('percentOwnership', false);
      setOwnerPercentageChecked(false);
      setErrorOwnership('');
    }
    setRadioChecked(id);
    setValue('ownership', id);
  };

  const chnageOwnerPercentage = (event: any) => {
    setOwnerPercentageChecked(event?.target?.checked);
    setValue('percentOwnership', event?.target?.checked);
    if (event?.target?.checked === true) {
      setErrorOwnership('');
    }
  };

  if (verifyCodeContainerVisible && errorOwnership === '') {
    return (
      <PhoneVerifyCodeContainer
        phone={verifiedPhoneNumber}
        setVerifyCodeContainerVisible={setVerifyCodeContainerVisible}
        onSubmitFunction={() =>
          appSubmit(formData, onFormSubmit, needToDeleteNumber, setVerifyCodeContainerVisible)
        }
        phoneWithMask={formData.phone_number}
      />
    );
  }

  return (
    <>
      {(isLoading || isPhoneVerificationLoading) && <ManualLoader />}
      <FormWrapper onSubmit={onAppFormSubmit} className="no_fullscreen">
        <Box component={'div'} className={'container_content'}>
          <Box
            component={'div'}
            className={'container_content_top'}
            ref={elementRef}
            sx={{
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                minHeight: height,
                paddingBottom: height ? 4 : commonSpacing.desktopPaddingB,
              },
            }}
          >
            <Header
              isBackButtonVisible={true}
              onBackButtonClick={onBackButtonClick}
              isProgressBarVisible={true}
              totalProgressSteps={6}
              currentProgressStep={2}
              paddingX={0}
            />
            <Box sx={{ marginBottom: 6 }}>
              <HeadingSummaryBlock
                heading={t('headingContent.business.ownerInfo.heading')}
                headingAlignment="left"
                content={t('headingContent.business.ownerInfo.content')}
                contentAlignment="left"
              />
            </Box>
            <Controller
              control={control}
              name="first_name"
              render={({ field: { value } }) => (
                <FormInput
                  label={t('formInput.firstName')}
                  id={'first_name'}
                  placeholder={t('placeHolder.firstName')}
                  {...register('first_name')}
                  error={errors?.first_name?.message}
                  onChange={(event: any) => {
                    event.target.value = event.target.value.replace('  ', ' ');
                    updateBindingsChange(event, 'first_name');
                  }}
                  value={value || ''}
                  inputFocus={(e: any) => {
                    trigger('first_name');
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="last_name"
              render={({ field: { value } }) => (
                <FormInput
                  label={t('formInput.lastName')}
                  id={'last_name'}
                  placeholder={t('placeHolder.lastName')}
                  error={errors?.last_name?.message}
                  {...register('last_name')}
                  onChange={(event: any) => {
                    event.target.value = event.target.value.replace('  ', ' ');
                    updateBindingsChange(event, 'last_name');
                  }}
                  value={value || ''}
                  inputFocus={(e: any) => {
                    trigger('last_name');
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="email"
              render={({ field: { value } }) => (
                <FormInput
                  label={t('formInput.email')}
                  id={'email'}
                  placeholder={t('placeHolder.emailAddress')}
                  {...register('email')}
                  inputMode="email"
                  error={errors?.email?.message}
                  onChange={(event: any) => {
                    updateBindingsChange(event, 'email');
                  }}
                  value={value || ''}
                  inputFocus={(e: any) => {
                    trigger('email');
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="inputOwnerTitle"
              render={({ field: { value } }) => (
                <FormInput
                  label={t('formInput.jobTitle')}
                  id={'inputOwnerTitle'}
                  placeholder={t('placeHolder.jobTitle')}
                  {...register('inputOwnerTitle')}
                  error={errors?.inputOwnerTitle?.message}
                  onChange={(event: any) => {
                    updateBindingsChange(event, 'inputOwnerTitle');
                  }}
                  value={value || ''}
                  inputFocus={(e: any) => {
                    trigger('inputOwnerTitle');
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="dob"
              render={({ field: { ref, value, ...rest } }) => (
                <FormInputDate
                  label={t('formInput.dob')}
                  id={'dob'}
                  {...register('dob')}
                  inputError={errors.dob?.message}
                  onChange={(event: any) => {
                    onChangeHandlerDate(event);
                  }}
                  inputMode="numeric"
                  inputFocus={(e: any) => {
                    trigger('dob');
                  }}
                  value={value || ''}
                />
              )}
            />
            <Controller
              control={control}
              name="phone_number"
              render={({ field: { value } }) => (
                <FormInputPhoneNumber
                  label={t('formInput.phone')}
                  id={'phone'}
                  placeholder={'(123) 543-3454'}
                  {...register('phone_number')}
                  inputError={errors.phone_number?.message || errorState}
                  onChange={onChangeHandler}
                  inputMode="numeric"
                  value={value || ''}
                  inputFocus={(e: any) => {
                    trigger('phone_number');
                  }}
                />
              )}
            />
            <AddMorePhoneNumber
              register={register}
              control={control}
              onChange={onChangeHandlerAddionalInput}
              setAddtionalNumbers={setAddtionalNumbers}
              setValue={setValue}
              error={getValues('phone_number_2')?.length !== 14 && errors?.phone_number_2?.message}
              isSetInputNumber={isSetInputNumber}
            />
            <Box>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                sx={{ marginBottom: 3 }}
              >
                <FormRadioButton
                  label={t('formInput.ownerLabel')}
                  name={'relationship'}
                  value={'owner'}
                  id={'owner'}
                  checked={radioChecked === 'owner'}
                  handleChange={handleRep}
                />
                <FormRadioButton
                  label={t('formInput.executiveLabel')}
                  name={'relationship'}
                  value={'executive'}
                  id={'executive'}
                  checked={radioChecked === 'executive'}
                  handleChange={handleRep}
                />
              </RadioGroup>
            </Box>
            {radioChecked === 'executive' && (
              <FormControlLabel
                sx={{ marginBottom: 3 }}
                control={
                  <Checkbox
                    name="confirm_subscription"
                    size="small"
                    onChange={chnageOwnerPercentage}
                    checked={ownerPercentageChecked}
                  />
                }
                label={
                  <Typography variant="body1" color={'gray.700'}>
                    {t('formInput.executiveConfirmInput')}
                  </Typography>
                }
              />
            )}

            {serverSideErrors && (
              <Messages
                topMargin={0}
                bottomMargin={2}
                messageHeading={
                  serverSideErrors && serverSideErrors.includes('phone')
                    ? t('errorMessages.phoneUniqueError.heading')
                    : serverSideErrors.includes('email')
                    ? t('errorMessages.emailUniqueError.heading')
                    : serverSideErrors
                }
                messageContent={
                  serverSideErrors.includes('phone')
                    ? t('errorMessages.phoneUniqueError.content')
                    : serverSideErrors.includes('email')
                    ? t('errorMessages.emailUniqueError.content')
                    : 'Please provide the valid details to use PayUp.'
                }
                closeEvent={() => setServerSideErrors('')}
              />
            )}

            {errorOwnership && errorOwnership !== '' && (
              <Messages
                topMargin={0}
                bottomMargin={0}
                messageHeading={t('errorMessages.ownershipError.heading')}
                messageContent={errorOwnership}
                closeEvent={() => setErrorOwnership('')}
              />
            )}
          </Box>
        </Box>
        <ContentContainerBottom className={'no_fullscreen'}>
          <Button
            variant="containedLarge"
            color="primary"
            type="submit"
            fullWidth
            sx={{ overflow: 'hidden' }}
            onClick={() => {
              setBtnSubmitted(true);
              checkForOwnerShipError();
            }}
          >
            {t('buttonTexts.continueButton')}
          </Button>
        </ContentContainerBottom>
      </FormWrapper>
    </>
  );
};
