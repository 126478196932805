import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  FormInput,
  FormInputPhoneNumber,
  UpdateFormWrapper,
  UpdateCardWrapper,
  UpdateFormTop,
  AccountVerificationStatusses,
  FormInputSSN,
  IdDocumentModal,
  DocVerifiedLink,
  Messages,
  FormInPutChangePassword,
  FormSelectionVerifyBusinessStructure,
  AddressFields,
} from 'src/components/base/core';
import { FormEditSubmitionprops } from 'src/models/component/base.model';
import { AccountParams } from 'src/shared/utilities/interface';
import { getCookie } from 'src/shared/utilities/cookiesHelper';
import { setCoupaInfoPhone, setCoupaInfoValuesForEmailLogin } from 'src/helpers/setCoupaValues';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { initAccount, initialRelationShipObject } from 'src/models/init/account.init';
import {
  businessVerifyOwnershipSchemaObj,
  identityVerificationSchemaObj,
} from 'src/helpers/validationSchemas';
import { dtoToBusinessPersonalDetailsEditFormValues } from 'src/shared/utilities/dto';
import moment from 'moment';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { handleIdModalVisibility } from 'src/helpers/containers/helperIdDocumentModal';
import { RefreshIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import { ERROR_MESSAGES } from 'src/constants/Validation';
import { FormInputDate } from 'src/components/base/core/FormElements/FormInputDate/FormInputDate';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { handleBusinessPersonalInfoEdit } from 'src/helpers/containers/helperBusinessOwnership';
import { isBillingAndShippingAreTheSame } from 'src/shared/utilities/addressUtils';
import { isNot18, isNullDOB, isValidDOB, onChangeHandlerDateInput } from 'src/shared/utilities/dob';
import { addressDefaultValues } from 'src/models/init/account.init';
import { IRelationShip } from 'src/models/api/account';

export const FormEditPersonalDetailsSubmition = ({
  result,
  onCancelButton,
  title,
  isSettings = false,
  isShadowCard = false,
  isVerified = false,
  onFormSubmit,
  isFromFinance,
}: FormEditSubmitionprops) => {
  const { t } = useTranslation();

  const [serverSideErrors, setServerSideErrors, appLogin, isLoading, onChangeHandlerPhone]: any =
    handleBusinessPersonalInfoEdit();

  const [openIdModal, onClickIdModalOpen, onClickIdModalClose] = handleIdModalVisibility();

  const { webauthnEnabled, allowEditEmail } = useFlags();

  const [DOBValue, setDOBValue] = useState(moment().subtract(18, 'years').format());
  const [radioChecked, setRadioChecked] = useState('');
  const [enableSSNUpdate, setEnableSSNUpdate]: any = useState(true);

  const [btnSubmitted, setBtnSubmitted] = useState(false);
  const [errorManualDate, setErrorManualDate] = useState(false);
  const [errorOwnership, setErrorOwnership] = useState('');
  const [isSameChecked, setSameChecked] = useState(false);
  const [addressStates, setAddressStates] = useState(addressDefaultValues);
  const [relationshipObj, setRelationshipObj] = useState<IRelationShip>(initialRelationShipObject);
  const [perCentageOwnershipConfirmed, setPerCentageOwnershipConfirmed] = useState(false);
  const [percentageOwnershipCheckBoxVisible, setPercentageOwnershipCheckBoxVisible] =
    useState(false);

  useEffect(() => {
    if (result) {
      const getIndividualObj = result?.individual;
      const getDOB = getIndividualObj?.dob; // get Dob value
      const isSSNLast4Provided = getIndividualObj?.ssn_last4_provided;
      const isSoleOwnerCompanyStructure =
        result?.company_structure === 'sole_proprietorship' ? true : false;
      const getCompanyAddressObject = result?.company?.address;
      const getIndividualAddressObject = result?.individual?.address;

      // check if it's 'executive' type true
      if (isSoleOwnerCompanyStructure) {
        setRadioChecked('yes');
        setRelationshipObj({
          director: true, // This person is a member of the governing board of the company: relationship[director]
          executive: true, // This person is an executive or senior manager with significant management responsibility: relationship[executive]
          owner: true, // This person owns 25% or more of the company: relationship[owner]
          percent_ownership: 100,
          representative: true, // This person is authorized as the primary representative of the account: relationship[representative]
        });
        setValue('company_structure', 'sole_proprietorship');
        setPerCentageOwnershipConfirmed(false);
        setPercentageOwnershipCheckBoxVisible(false);
      } else {
        setRadioChecked('no');
        setValue('company_structure', null);

        const getRelaObj = result?.individual?.relationShip;

        const isPercentageConfirmButtonClicked =
          !getRelaObj?.director &&
          getRelaObj?.executive &&
          getRelaObj?.owner &&
          getRelaObj?.representative &&
          getRelaObj?.percent_ownership === 25;

        if (isPercentageConfirmButtonClicked) {
          setPercentageOwnershipCheckBoxVisible(true);
          setPerCentageOwnershipConfirmed(true);
          setRelationshipObj({
            director: false, // This person is a member of the governing board of the company: relationship[director]
            executive: true, // This person is an executive or senior manager with significant management responsibility: relationship[executive]
            owner: true, // This person owns 25% or more of the company: relationship[owner]
            percent_ownership: 25,
            representative: true, // This person is authorized as the primary representative of the account: relationship[representative]
          });
        } else {
          setPerCentageOwnershipConfirmed(false);
          setPercentageOwnershipCheckBoxVisible(false);
          setRelationshipObj({
            director: false, // This person is a member of the governing board of the company: relationship[director]
            executive: true, // This person is an executive or senior manager with significant management responsibility: relationship[executive]
            owner: false, // This person owns 25% or more of the company: relationship[owner]
            percent_ownership: null,
            representative: true, // This person is authorized as the primary representative of the account: relationship[representative]
          });
        }
      }

      //@ts-ignore
      if (!isNullDOB(getDOB)) {
        const dob = getDOB;
        const formattedDOB: any = moment(new Date(dob.year, dob.month - 1, dob.day));
        setValue('dob', moment(formattedDOB).format('MM/DD/yyyy'));
        setDOBValue(moment(formattedDOB).format('MM/DD/yyyy'));

        if (isSSNLast4Provided || isVerified) {
          setEnableSSNUpdate(false);
        } else {
          setEnableSSNUpdate(true);
        }
      } else {
        setValue('dob', moment(DOBValue).format('MM/DD/yyyy'));
      }

      const isBothAddressAreTheSame = isBillingAndShippingAreTheSame(
        getCompanyAddressObject,
        getIndividualAddressObject
      );
      const address = getCompanyAddressObject ?? getIndividualAddressObject;

      if (isBothAddressAreTheSame) {
        setSameChecked(true);
      }

      if (address && isSameChecked) {
        setValue('city', address.city);
        setValue('line1', address.line1);
        setValue('line2', address.line2);
        setValue('state', address.state);
        setValue('postal_code', address.postal_code);
        trigger(['line1', 'state', 'city', 'postal_code']);
      }

      const isIndividualAddressProvided = getIndividualAddressObject?.line1 !== null;

      // set the rest the values into relavant field
      dtoToBusinessPersonalDetailsEditFormValues(
        getValues,
        setValue,
        result,
        isIndividualAddressProvided
      );
    } else if (!result && getCookie('coupaObj')) {
      if (webauthnEnabled) {
        setCoupaInfoValuesForEmailLogin(setValue, true);
      } else {
        setCoupaInfoPhone(setValue);
      }
    }
  }, [result]);

  const getVerificationData = result && result?.individual?.verification;
  const isTosAccepted = result && result?.tos_shown_and_accepted;
  const confirmVerificationDocumentSubmitted =
    getVerificationData?.document?.front !== null && getVerificationData?.status === 'verified';
  const confirmUnVerificationDocumentSubmitted =
    getVerificationData?.document?.front !== null && getVerificationData?.status !== 'verified';
  const confirmationNoUploadDone =
    !confirmVerificationDocumentSubmitted && !confirmUnVerificationDocumentSubmitted;
  const isSettingVerified = isSettings && isVerified;

  const isDOBadded = result && result?.individual?.dob?.day !== null;
  const isSSNProvided = result && result?.individual?.ssn_last4_provided ? true : false;
  const addressFieldEnabled = result && result?.individual?.address?.line1 !== null;
  const isSSNFieldsVisible =
    radioChecked === 'yes' ? true : isSSNProvided && isFromFinance ? true : false;

  const dobSchema = {
    dob: yup
      .string()
      .required(ERROR_MESSAGES.REQUIRED)
      .test(
        'len',
        errorManualDate ? ERROR_MESSAGES.DATE_INVALID_LESS_THAN_18 : ERROR_MESSAGES.DATE_INVALID,
        (value) => {
          if (value && value?.length === 10 && isNot18(value) && isValidDOB(value)) return true;
          else return false;
        }
      ),
  };

  const idNumberSchema = {
    idNumber: yup
      .string()
      .test('required', ERROR_MESSAGES.REQUIRED, (value) => {
        if (!enableSSNUpdate || value) return true;
        return false;
      })
      .test('min', ERROR_MESSAGES.SSN_INVALID_LAST4, (value) => {
        if ((value && value?.length === 4) || !enableSSNUpdate) return true;
        return false;
      }),
  };

  const handleErrorSchema = () => {
    let errorSchema = businessVerifyOwnershipSchemaObj;

    if (isDOBadded || radioChecked === 'yes') {
      errorSchema = {
        ...errorSchema,
        ...dobSchema,
      };
    }

    if (isSSNProvided || radioChecked === 'yes') {
      errorSchema = {
        ...errorSchema,
        ...idNumberSchema,
      };
    }

    if (addressFieldEnabled) {
      errorSchema = {
        ...errorSchema,
        ...identityVerificationSchemaObj,
      };
    }

    return errorSchema;
  };

  const revieInfoSchema = yup.object(handleErrorSchema()).required();

  //hook-forms
  const {
    register,
    handleSubmit,
    setValue,
    control,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<AccountParams>({
    resolver: yupResolver(revieInfoSchema),
    defaultValues: initAccount,
    mode: 'onSubmit',
  });

  const onAppFormSubmit = handleSubmit(async (data: any) => {
    const updatedData = {
      ...relationshipObj,
      ...data,
    };

    if (isFromFinance) {
      if (radioChecked !== '' && perCentageOwnershipConfirmed) {
        appLogin(
          updatedData,
          onFormSubmit,
          isVerified,
          confirmVerificationDocumentSubmitted,
          isTosAccepted && isSettings,
          isSettingVerified
        );
        setErrorOwnership('');
      } else {
        if (radioChecked === '' && perCentageOwnershipConfirmed) {
          setErrorOwnership('Please provide more information regarding ownership of the company.');
        } else if (radioChecked === 'no' && !perCentageOwnershipConfirmed) {
          setErrorOwnership(
            'Please ensure the representative has a 25% or more ownership in the company.'
          );
        } else if (radioChecked === 'yes' && !perCentageOwnershipConfirmed) {
          appLogin(
            updatedData,
            onFormSubmit,
            isVerified,
            confirmVerificationDocumentSubmitted,
            isTosAccepted && isSettings,
            isSettingVerified
          );
          setErrorOwnership('');
        }
      }
    } else {
      if (radioChecked !== '') {
        appLogin(
          updatedData,
          onFormSubmit,
          isVerified,
          confirmVerificationDocumentSubmitted,
          isTosAccepted && isSettings,
          isSettingVerified
        );
        setErrorOwnership('');
      } else {
        setErrorOwnership('Please provide more information regarding ownership of the company.');
      }
    }
  });

  const handleRep = (val: any) => {
    const { id } = val.target; // get the id from selected element
    setRadioChecked(id); // set the setRadioChecked state

    if (id === 'yes') {
      setRelationshipObj({
        director: true, // This person is a member of the governing board of the company: relationship[director]
        executive: true, // This person is an executive or senior manager with significant management responsibility: relationship[executive]
        owner: true, // This person owns 25% or more of the company: relationship[owner]
        percent_ownership: 100,
        representative: true, // This person is authorized as the primary representative of the account: relationship[representative]
      });
      setValue('company_structure', 'sole_proprietorship'); // if selected target id is 'owner' set the company_structure value in to form-data output
      setPerCentageOwnershipConfirmed(false);
    } else if (id === 'no') {
      setRelationshipObj({
        director: false, // This person is a member of the governing board of the company: relationship[director]
        executive: true, // This person is an executive or senior manager with significant management responsibility: relationship[executive]
        owner: false, // This person owns 25% or more of the company: relationship[owner]
        percent_ownership: null,
        representative: true, // This person is authorized as the primary representative of the account: relationship[representative]
      });
      setValue('company_structure', null); // if selected target id is 'executive' set the company_structure value in to form-data output
    }
  };

  const onChnageOwnerPercentage = (val: any) => {
    if (val.target.checked) {
      setPerCentageOwnershipConfirmed(true);
      setRelationshipObj({
        director: false, // This person is a member of the governing board of the company: relationship[director]
        executive: true, // This person is an executive or senior manager with significant management responsibility: relationship[executive]
        owner: true, // This person owns 25% or more of the company: relationship[owner]
        percent_ownership: 25,
        representative: true, // This person is authorized as the primary representative of the account: relationship[representative]
      });
    } else {
      setPerCentageOwnershipConfirmed(false);
      if (radioChecked === 'yes') {
        setRelationshipObj({
          director: true, // This person is a member of the governing board of the company: relationship[director]
          executive: true, // This person is an executive or senior manager with significant management responsibility: relationship[executive]
          owner: true, // This person owns 25% or more of the company: relationship[owner]
          percent_ownership: 100,
          representative: true, // This person is authorized as the primary representative of the account: relationship[representative]
        });
        setValue('company_structure', 'sole_proprietorship'); // if selected target id is 'owner' set the company_structure value in to form-data output
      } else if (radioChecked === 'no') {
        setRelationshipObj({
          director: false, // This person is a member of the governing board of the company: relationship[director]
          executive: true, // This person is an executive or senior manager with significant management responsibility: relationship[executive]
          owner: false, // This person owns 25% or more of the company: relationship[owner]
          percent_ownership: null,
          representative: true, // This person is authorized as the primary representative of the account: relationship[representative]
        });
        setValue('company_structure', null); // if selected target id is 'executive' set the company_structure value in to form-data output
      }
    }
  };

  const updateBindingsChange = (event: any, feild: any) => {
    setValue(feild, event.target.value);
  };

  const onChangeHandler = (value: any) => {
    onChangeHandlerPhone(value, setValue, btnSubmitted);
  };

  const onChangeHandlerDate = (event: any) => {
    onChangeHandlerDateInput(event, setValue, setErrorManualDate);
  };

  const emailId = result && result?.individual?.email;

  return (
    <>
      {isLoading && <ManualLoader />}
      <UpdateFormWrapper
        onSubmit={onAppFormSubmit}
        className={`no-mobile-padding ${isShadowCard ? 'is_shadow_card' : 'disable_shadow_card'}`}
      >
        {!isSettings && (
          <UpdateFormTop className={'disabled_status'}>
            <Typography
              textTransform={'uppercase'}
              variant="caption2"
              color={'gray.500'}
              marginBottom={1}
            >
              {title}
            </Typography>
          </UpdateFormTop>
        )}
        <UpdateCardWrapper className="update_wrapper_top">
          <Box
            className={isSettings ? 'is_shadow_card' : 'disabled_shadow_card'}
            paddingTop={isSettings ? 4 : 0}
            paddingBottom={isSettings ? 1 : 0}
            paddingX={isSettings ? 4 : 0}
            marginBottom={isSettings ? 4 : 0}
          >
            {isSettings && (
              <UpdateFormTop className={'disabled_status'}>
                <Typography
                  textTransform={'uppercase'}
                  variant="caption2"
                  color={'gray.500'}
                  marginBottom={4}
                >
                  {title}
                </Typography>
              </UpdateFormTop>
            )}
            <Controller
              control={control}
              name="first_name"
              render={({ field: { value } }) => (
                <FormInput
                  label={t('formInput.firstName')}
                  id={'first_name'}
                  placeholder={t('placeHolder.firstName')}
                  {...register('first_name')}
                  error={errors?.first_name?.message}
                  onChange={(event: any) => {
                    event.target.value = event.target.value.replace('  ', ' ');
                    updateBindingsChange(event, 'first_name');
                  }}
                  value={value || ''}
                  inputFocus={(e: any) => {
                    trigger('first_name');
                  }}
                  readonly={isSettingVerified && value !== '' && value !== null}
                />
              )}
            />
            <Controller
              control={control}
              name="last_name"
              render={({ field: { value } }) => (
                <FormInput
                  label={t('formInput.lastName')}
                  id={'last_name'}
                  placeholder={t('placeHolder.lastName')}
                  error={errors?.last_name?.message}
                  {...register('last_name')}
                  onChange={(event: any) => {
                    event.target.value = event.target.value.replace('  ', ' ');
                    updateBindingsChange(event, 'last_name');
                  }}
                  value={value || ''}
                  inputFocus={(e: any) => {
                    trigger('last_name');
                  }}
                  readonly={isSettingVerified && value !== '' && value !== null}
                />
              )}
            />
            <Controller
              control={control}
              name="email"
              render={({ field: { value } }) => (
                <FormInput
                  label={t('formInput.email')}
                  id={'email'}
                  type="email"
                  placeholder={t('placeHolder.emailAddress')}
                  {...register('email')}
                  inputMode="email"
                  error={errors?.email?.message}
                  // value={getValues('email')}
                  onChange={(event: any) => {
                    updateBindingsChange(event, 'email');
                  }}
                  value={value}
                  inputFocus={() => trigger('email')}
                  readonly={!allowEditEmail && isSettingVerified && value !== '' && value !== null}
                />
              )}
            />
            {webauthnEnabled && <FormInPutChangePassword email={emailId} />}
            <Controller
              control={control}
              name="phone_number"
              render={({ field: { value } }) => (
                <FormInputPhoneNumber
                  label={t('formInput.phone')}
                  id={'phone'}
                  placeholder={'(123) 543-3454'}
                  {...register('phone_number')}
                  inputError={errors.phone_number?.message}
                  onChange={onChangeHandler}
                  inputMode="numeric"
                  value={value}
                  inputFocus={() => trigger('phone_number')}
                />
              )}
            />

            <Box
              sx={{
                marginBottom: 4,
                pointerEvents: isSettingVerified ? 'none' : 'auto',
                opacity: isSettingVerified ? 0.6 : 1,
              }}
            >
              <FormSelectionVerifyBusinessStructure
                radioChecked={radioChecked}
                handleRep={handleRep}
                t={t}
                ownershipPercentageSelectionEnabled={
                  (isFromFinance && radioChecked === 'no') ||
                  (percentageOwnershipCheckBoxVisible && radioChecked === 'no')
                }
                chnageOwnerPercentage={onChnageOwnerPercentage}
                ownerPercentageChecked={perCentageOwnershipConfirmed}
              />
            </Box>

            {(radioChecked === 'yes' || (isDOBadded && isFromFinance)) && (
              <Controller
                control={control}
                name="dob"
                render={({ field: { value } }) =>
                  isSettingVerified && value !== '' && value !== null ? (
                    <FormInputDate
                      label={t('formInput.dob')}
                      id={'dob'}
                      placeholder={'(123) 543-3454'}
                      {...register('dob')}
                      onChange={(event: any) => {
                        onChangeHandlerDate(event);
                      }}
                      inputMode="numeric"
                      inputFocus={(e: any) => {
                        trigger('dob');
                      }}
                      value={value}
                      readonly={true}
                    />
                  ) : (
                    <FormInputDate
                      label={t('formInput.dob')}
                      id={'dob'}
                      placeholder={'(123) 543-3454'}
                      {...register('dob')}
                      inputError={errors.dob?.message}
                      onChange={(event: any) => {
                        onChangeHandlerDate(event);
                      }}
                      inputMode="numeric"
                      inputFocus={(e: any) => {
                        trigger('dob');
                      }}
                      value={value || ''}
                    />
                  )
                }
              />
            )}

            {isSSNFieldsVisible && (
              <>
                {(!isSettings || !isVerified) && (
                  <>
                    <Controller
                      control={control}
                      name="idNumber"
                      render={({ field: { value, ref } }) => (
                        <FormInputSSN
                          label={t('formInput.ssn')}
                          id={'securityNumber'}
                          only4={true}
                          {...register('idNumber')}
                          error={errors?.idNumber?.message}
                          inputError={errors?.idNumber?.message}
                          onChange={(event: any) => {
                            updateBindingsChange(event, 'idNumber');
                          }}
                          replace={enableSSNUpdate}
                          setEnableSSNUpdate={setEnableSSNUpdate}
                          inputFocus={() => trigger('idNumber')}
                        />
                      )}
                    />

                    {!isFromFinance && (
                      <Box component={'div'} paddingY={2} marginBottom={2}>
                        <DocVerifiedLink
                          className={`${
                            confirmVerificationDocumentSubmitted
                              ? 'pointer_disabled'
                              : 'pointer_enabled'
                          }`}
                          onClick={onClickIdModalOpen}
                        >
                          {confirmationNoUploadDone && (
                            <Typography
                              variant="h2"
                              color={'primary'}
                              sx={{ cursor: 'pointer', marginRight: 2 }}
                            >
                              {t('linkTexts.business.provideIDDocLink')}
                            </Typography>
                          )}

                          {(confirmUnVerificationDocumentSubmitted ||
                            confirmVerificationDocumentSubmitted) && (
                            <Typography
                              variant="h2"
                              color={'primary'}
                              sx={{ cursor: 'pointer', marginRight: 2 }}
                            >
                              {t('linkTexts.business.providedIDDoc')}
                            </Typography>
                          )}
                          {confirmUnVerificationDocumentSubmitted && !confirmationNoUploadDone && (
                            <RefreshIcon />
                          )}
                          {confirmVerificationDocumentSubmitted && (
                            <AccountVerificationStatusses type="verified" />
                          )}
                        </DocVerifiedLink>
                      </Box>
                    )}
                  </>
                )}
              </>
            )}

            {addressFieldEnabled && (
              <>
                <FormControlLabel
                  sx={{
                    marginBottom: 3,
                    pointerEvents: isSettingVerified ? 'none' : 'auto',
                  }}
                  control={
                    <Checkbox
                      checked={isSameChecked}
                      name="jason"
                      size="small"
                      onClick={() => setSameChecked(!isSameChecked)}
                    />
                  }
                  label={
                    <Typography variant="body1" color={'gray.700'}>
                      {t('formInput.sameBillingAddressCheckbox')}
                    </Typography>
                  }
                />
                <AddressFields
                  setAddressStates={setAddressStates}
                  addressStates={addressStates}
                  register={register}
                  errors={errors}
                  control={control}
                  getValue={getValues}
                  setValue={setValue}
                  trigger={trigger}
                  isVerifiedOnlyForSetting={isSettingVerified}
                />
              </>
            )}

            {serverSideErrors && (
              <Messages
                topMargin={0}
                bottomMargin={4}
                messageHeading={
                  serverSideErrors.includes('phone')
                    ? t('errorMessages.phoneUniqueError.heading')
                    : serverSideErrors.includes('email')
                    ? t('errorMessages.emailUniqueError.heading')
                    : serverSideErrors.includes('P.O. Box')
                    ? t('errorMessages.invalidAddressError.heading')
                    : serverSideErrors
                }
                messageContent={
                  serverSideErrors.includes('phone')
                    ? t('errorMessages.phoneUniqueError.content')
                    : serverSideErrors.includes('email')
                    ? t('errorMessages.emailUniqueError.content')
                    : serverSideErrors.includes('P.O. Box')
                    ? serverSideErrors
                    : 'Please provide valid details to use PayUp.'
                }
                closeEvent={() => setServerSideErrors('')}
              />
            )}

            {errorOwnership && errorOwnership !== '' && (
              <Messages
                topMargin={0}
                bottomMargin={4}
                messageHeading={'A required field is missing'}
                messageContent={errorOwnership}
                closeEvent={() => setErrorOwnership('')}
              />
            )}
          </Box>
          {radioChecked === 'yes' && (
            <IdDocumentModal
              register={register}
              setValue={setValue}
              verificationData={result && result.individual.verification}
              openIdModal={openIdModal}
              closeIdModalEvent={onClickIdModalClose}
            />
          )}
          <Box className="update_wrapper_bottom">
            {!isSettings && (
              <Button
                variant="text"
                color="primary"
                type="button"
                sx={{ overflow: 'hidden', fontWeight: 600 }}
                onClick={onCancelButton}
              >
                {t('buttonTexts.cancelButton')}
              </Button>
            )}
            <Button
              variant="containedLarge"
              color="primary"
              type="submit"
              sx={{ overflow: 'hidden', width: isSettings ? '100%' : 'auto' }}
              onClick={() => {
                setBtnSubmitted(true);
              }}
            >
              {!isSettings ? t('buttonTexts.updateButton') : t('buttonTexts.submitButton')}
            </Button>
          </Box>
        </UpdateCardWrapper>
      </UpdateFormWrapper>
    </>
  );
};
