import { FirstCell, SecondCell, ThirdCell } from '../TbodyCells';

export const COLUMNS = (
  title1 = 'INVOICE',
  title2 = 'DATE',
  title3 = 'AMOUNT',
  isTransactionGrid = false
) => {
  return [
    {
      Header: title1,
      accessor: isTransactionGrid ? 'customTitle' : 'business_name',
      Cell: (props: any) => {
        return <FirstCell props={props} />;
      },
    },
    {
      Header: title2,
      accessor: 'paid_at',
      Cell: (props: any) => {
        return <SecondCell props={props} />;
      },
    },
    {
      Header: title3,
      accessor: 'payout_amount',
      Cell: (props: any) => {
        return <ThirdCell props={props} />;
      },
    },
  ];
};
