import axios from 'axios';
import { DownloadFilterProps, FilterProps } from 'src/shared/utilities/interface';
import { configs } from '../../shared/config/config';
import config from '../config';

export const GetEarningsSubmitDetails = async (
  filter: FilterProps,
  hideLoader?: boolean,
  limit: number = configs.DEFAULT_LIMIT,
  offset: number = 0,
  first: number = 0,
  orderBy: string = 'CREATED_AT_ASC'
) => {
  config(hideLoader);
  localStorage.setItem('hide_loader', 'true');
  return axios
    .post('/query/account-payouts', {
      //  limit: limit,
      offset: offset,
      first: first,
      filter: filter,
      orderBy,
    })
    .then((res) => {
      localStorage.removeItem('hide_loader');
      return res;
    })
    .catch((error) => {
      localStorage.removeItem('hide_loader');
      return error.response.data;
    });
};

export const exportInvoices = async (
  filter: DownloadFilterProps,
  toEmail: string,
  orderBy: string = 'CREATED_AT_ASC',
  hideLoader = true
) => {
  config(hideLoader);
  localStorage.setItem('hide_loader', 'true');
  return axios
    .post('/query/account-payouts', {
      filter: filter,
      orderBy,
      email_id: toEmail,
      offset: 0,
      first: 5000,
    })
    .then((res) => {
      localStorage.removeItem('hide_loader');
      return res;
    })
    .catch((error) => {
      localStorage.removeItem('hide_loader');
      return error.response.data;
    });
};
