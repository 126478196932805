import { FaqBlockProps } from 'src/models/component/base.model';
import { FaqBlockWrapper, FaqBlockLeft, FaqBlockRight } from './FaqBlock.style';
import { Typography } from '@mui/material';
import {
  EasyConnection,
  SecureIcon,
  PrivateIcon,
  DateCalenderIcon,
  SetupIcon,
  FairFreeIcon,
} from 'src/components/base/core/Media/Icon/Icons/Icons';

export const FaqBlock = ({ heading, content, iconType }: FaqBlockProps) => {
  const handleFaqIconTypes = (_iconType: string | undefined) => {
    switch (_iconType) {
      case 'EasyConnection':
        return <EasyConnection />;
      case 'SecureIcon':
        return <SecureIcon />;
      case 'PrivateIcon':
        return <PrivateIcon />;
      case 'DateCalenderIcon':
        return <DateCalenderIcon />;
      case 'SetupIcon':
        return <SetupIcon />;
      case 'FairFreeIcon':
        return <FairFreeIcon />;
      default:
        break;
    }
  };

  return (
    <FaqBlockWrapper>
      <FaqBlockLeft>{handleFaqIconTypes(iconType)}</FaqBlockLeft>
      <FaqBlockRight>
        {heading && (
          <Typography variant="h3Bold" color={'gray.700'} sx={{ marginBottom: 2 }}>
            {heading}
          </Typography>
        )}
        {content && (
          <Typography variant="body1" color={'gray.700'}>
            {content}
          </Typography>
        )}
      </FaqBlockRight>
    </FaqBlockWrapper>
  );
};
