import { useTranslation } from 'react-i18next';

import { Box, Button } from '@mui/material';

import { Modal } from '../Modal/Modal';
import { FormSwitch } from '../../FormElements/FormSwitch/FormSwitch';

import { FreezeCardModalProps } from 'src/models/component/base.model';
import { ShadowCard } from '../../CommonNestedStyles/FormWrapper.style';

export const FreezeCardModal = ({
  closeIdModalEvent,
  onAcceptButtonClick,
  onFreezeCardOnchange,
  defaultChecked,
  isFreezeModalButtonDisabled,
}: FreezeCardModalProps) => {
  const { t } = useTranslation();

  // handle close event __
  const handleCloseEvent = () => {
    closeIdModalEvent && closeIdModalEvent();
  };

  return (
    <>
      <Modal
        onCloseEvent={handleCloseEvent}
        modalTitle={t('finance.manageCard.freezeCardModal.title')}
      >
        <ShadowCard paddingX={4} paddingTop={8} paddingBottom={1} marginBottom={18}>
          <FormSwitch
            id={'freezeCard'}
            label={t('finance.manageCard.freezeCardModal.formSwitch')}
            onChange={onFreezeCardOnchange}
            defaultChecked={defaultChecked}
          />
        </ShadowCard>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            position: 'absolute',
            left: '16px',
            right: '16px',
            bottom: 0,
            top: 'auto',
            paddingBottom: 6,
          }}
          component={'div'}
        >
          <Button
            variant="containedLarge"
            color="primary"
            type="button"
            fullWidth
            onClick={onAcceptButtonClick}
            sx={{ overflow: 'hidden' }}
            disabled={isFreezeModalButtonDisabled}
          >
            {t('buttonTexts.doneButton')}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
