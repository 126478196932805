import React, { useEffect, useMemo } from 'react';
import { useFilters, useTable } from 'react-table';
import { useTranslation } from 'react-i18next';

import { COLUMNS } from './Columns/Columns';

import {
  Thead,
  TheadTr,
  TheadTh,
  TbodyWrapper,
  Tbody,
  TbodyTr,
  TbodyTd,
  TbodyContainer,
} from './PaymentDataTable.style';

// import { TooltipInfo } from '../../TooltipInfo/TooltipInfo';
import { TableWrapper } from './TableWrapper/TableWrapper';
import { Icon } from '../../../..';

import {
  handleTheadColumnClassName,
  handleTbodyColumnClassName,
  isTermUpdate,
} from '../../../../../../../helpers/helperHandlingTableGrid';
import { SearchResultEmpty } from '../SearchResultEmpty/SearchResultEmpty';
import { PaymentDataTableProps } from 'src/shared/utilities/interface';

export const PaymentDataTable = ({
  dataObject,
  isOldInvoices,
  isHandleArrowClickable,
  isTermEditable,
  tabType,
  onClickTermId,
  onTermSelection,
  isTermEditEnabled,
  isToggle,
  isTermClicked,
  selectedPayoutId,
  isAllSelected,
  searchInProgress,
  hideHeader = false,
  iconArrowVisible = true,
  isTransactionGrid,
  searchText,
  onBachPayModeClick,
}: PaymentDataTableProps) => {
  const { t } = useTranslation(); // set translations

  // set the columns name into useMemo hook
  const columns: any = useMemo(
    () =>
      COLUMNS(
        isTransactionGrid ? t('earning.tableColumns.terms') : t('earning.tableColumns.invoice'),
        t('earning.tableColumns.date'),
        t('earning.tableColumns.amount'),
        isTransactionGrid
      ),
    []
  );

  // define the react-table related hooks and params
  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    // setFilter is the key!!!
    // @ts-ignore
    setFilter,
  } = useTable(
    {
      columns: columns,
      data: dataObject,
    },
    useFilters
  );

  // Listen for input changes outside
  useEffect(() => {
    // This will now use our custom filter for age
    if (isTransactionGrid) {
      if (searchText && searchText.length > 0) {
        setFilter('customTitle', searchText);
      } else {
        setFilter('customTitle', '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <>
      <TableWrapper
        {...getTableProps()}
        isToggle={isToggle}
        tabType={tabType}
        isTransactionGrid={isTransactionGrid}
      >
        <Thead className="invoices_table_head">
          {!hideHeader &&
            headerGroups.map((headerGroup) => {
              return (
                <TheadTr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => {
                    //remove isOldInvoices because need to show all column
                    return (
                      <TheadTh
                        {...column.getHeaderProps()}
                        className={handleTheadColumnClassName(
                          index,
                          isOldInvoices,
                          iconArrowVisible
                        )}
                      >
                        {index === 2 && (
                          <div className="hidden-mobile term_col">
                            <span className="hidden-mobile terms_th">
                              {isTransactionGrid
                                ? t('earning.tableColumns.mode')
                                : t('earning.tableColumns.terms')}
                            </span>
                          </div>
                        )}
                        <span>
                          {column.render('Header', {
                            iconArrowVisible,
                          })}
                        </span>
                      </TheadTh>
                    );
                  })}
                </TheadTr>
              );
            })}
        </Thead>

        <TbodyWrapper {...getTableBodyProps()}>
          {rows?.length > 0 ? (
            rows.map((row) => {
              prepareRow(row);
              //@ts-ignore
              const getRowDataID = row?.original?.id; // get the payout id from the data
              // @ts-ignore
              let isChecked = row?.original?.isChecked;
              isChecked = isChecked === undefined ? false : isChecked;
              return (
                <TbodyContainer {...row.getRowProps()}>
                  {((isTermEditEnabled && isOldInvoices) || isAllSelected) &&
                    isOldInvoices &&
                    isTermEditEnabled && (
                      <>
                        {isChecked && isChecked !== undefined ? (
                          <input
                            type="checkbox"
                            name={'checkboxPayout'}
                            id={getRowDataID || ''}
                            value={getRowDataID || ''}
                            className="input_bulk_edit"
                            onChange={onTermSelection}
                            checked={isChecked || ''}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            name={'checkboxPayout'}
                            id={getRowDataID || ''}
                            value={getRowDataID || ''}
                            className="input_bulk_edit"
                            onChange={onTermSelection}
                            checked={isChecked || ''}
                          />
                        )}
                        <label htmlFor={getRowDataID} className={`label_bulk_edit`}>
                          <Icon iconType="TickIconWhite" />
                        </label>
                      </>
                    )}
                  <Tbody
                    className={`payout_item invoices_table_body ${
                      isTermEditEnabled && 'is_editable'
                    } ${isOldInvoices && 'is_old'}`}
                  >
                    <TbodyTr className="payout_item_tr invoices_table_tr">
                      {row.cells.map((cell, index) => {
                        let isUpdateResently = false;
                        // @ts-ignore
                        // const isUpcomingTran = isTransactions && !cell?.row?.original?.paid_at;
                        // @ts-ignore
                        if (cell?.row?.original?.updated_at) {
                          // @ts-ignore
                          isUpdateResently = isTermUpdate(cell.row.original.updated_at);
                        }
                        return (
                          <TbodyTd
                            {...cell.getCellProps()}
                            className={handleTbodyColumnClassName(
                              index,
                              false,
                              isHandleArrowClickable,
                              isUpdateResently,
                              iconArrowVisible,
                              isTransactionGrid
                            )}
                          >
                            {cell.render('Cell', {
                              onClickTermId, // event,  for nclick net-term
                              isHandleArrowClickable, // boolean, the arrow is clickable or not based on this cursor class getting handled
                              isTermEditable, // boolean, is confirm the net term is editable or not
                              isTermEditEnabled, // boolean, is confirm the net term edit is enabled or not
                              isOldInvoices, // boolean, is this a old invoise or not
                              tabType, // string, tab type approved | paid | submitted
                              isTermClicked, // boolean, is confirm the net term is clicked or not
                              selectedPayoutId, // string, get the selected payout id
                              iconArrowVisible, // boolean, confirm is the right side arrow is clickable or not
                              isTransactionGrid, // boolean, is this a transaction type or not
                              onBachPayModeClick, // event, handle batch payment method edit
                            })}
                          </TbodyTd>
                        );
                      })}
                    </TbodyTr>
                  </Tbody>
                </TbodyContainer>
              );
            })
          ) : (
            <SearchResultEmpty
              content={searchInProgress ? 'Search result not found' : 'No results found'}
            />
          )}
        </TbodyWrapper>
      </TableWrapper>
    </>
  );
};
