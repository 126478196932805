import { Box, Typography } from '@mui/material';
import { LargeIcons } from 'src/shared/utilities/images';
import { InvoicesBatchesNavigation } from '../../Navigations/InvoicesBatchesNavigation/InvoicesBatchesNavigation';
import { EmptyListProps } from 'src/models/component/base.model';

export const EmptyList = ({
  heading,
  subHeading,
  secondParagraph,
  comingSoon = false,
  tabType,
  isInvoicesTab = true,
  t,
}: EmptyListProps) => {
  return (
    <>
      <Box paddingTop={comingSoon ? 8 : 16} paddingBottom={3}>
        <Box paddingBottom={8} justifyContent={'center'} display={'flex'}>
          <img src={LargeIcons.invoiceEmptyState} alt="Invoices empty state" height={180} />
        </Box>

        {heading && (
          <Typography
            variant={comingSoon ? 'h6ExtraBold' : 'h6Bold'}
            color="gray.800"
            component={'p'}
            marginBottom={3}
            textAlign="center"
          >
            {heading}
          </Typography>
        )}

        {subHeading && (
          <Typography
            variant={comingSoon ? 'body2Small' : 'body2'}
            color="gray.700"
            component={'p'}
            marginBottom={4}
            textAlign="center"
          >
            {subHeading}
          </Typography>
        )}

        {secondParagraph && (
          <Typography
            variant={comingSoon ? 'body2Small' : 'body2'}
            color="gray.700"
            component={'p'}
            marginBottom={3}
            textAlign="center"
          >
            {secondParagraph}
          </Typography>
        )}
      </Box>
      <Box display={'flex'} justifyContent={'center'} paddingTop={4}>
        <InvoicesBatchesNavigation
          tabType={tabType}
          isInvoicesActive={isInvoicesTab}
          isTextsAlwaysVisible
          t={t}
        />
      </Box>
    </>
  );
};
