import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { configs } from 'src/shared/config/config';
import { RowCountDropDownProps } from 'src/models/component/base.model';

export const RowCountDropDown = (props: RowCountDropDownProps) => {
  const handleChange = (event: SelectChangeEvent<number>) => {
    props?.onRowCountChange && props?.onRowCountChange(event.target.value as number);
  };

  return (
    <FormControl sx={{ my: 1, minWidth: '56px', height: '24px', padding: 0 }} size="small">
      <Select
        value={props?.selectedRowCount ?? configs.DEFAULT_LIMIT}
        onChange={handleChange}
        displayEmpty
        sx={{
          height: '24px',
          minWidth: '56px',
          '& .MuiSelect-select': {
            py: 0,
            pl: 2,
            pr: 4,
            fontSize: '12px',
          },
          '& svg': {
            right: '2px',
            top: 'calc(50% - 0.4em)',
            width: '0.8em',
            height: '0.8em',
          },
        }}
      >
        <MenuItem value={configs.DEFAULT_LIMIT * 1}>{`${configs.DEFAULT_LIMIT * 1}`}</MenuItem>
        <MenuItem value={configs.DEFAULT_LIMIT * 2}>{`${configs.DEFAULT_LIMIT * 2}`}</MenuItem>
        <MenuItem value={configs.DEFAULT_LIMIT * 3}>{`${configs.DEFAULT_LIMIT * 3}`}</MenuItem>
        <MenuItem value={configs.DEFAULT_LIMIT * 4}>{`${configs.DEFAULT_LIMIT * 4}`}</MenuItem>
        <MenuItem value={configs.DEFAULT_LIMIT * 5}>{`${configs.DEFAULT_LIMIT * 5}`}</MenuItem>
        <MenuItem value={configs.DEFAULT_LIMIT * 6}>{`${configs.DEFAULT_LIMIT * 6}`}</MenuItem>
      </Select>
    </FormControl>
  );
};
