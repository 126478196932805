import { ActionType } from '../action-types/index';
import { Action } from '../actions';

const initialState = '';
const reducer = (state: string = initialState, action: Action): any => {
  switch (action.type) {
    case ActionType.LOGOUT:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
