import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { Button } from '@mui/material';

export const InvoicesBatchesNavigationWrapper = styled(Box)(
  ({ theme }) => `
 display: flex;
 margin: 0 0 0 ${theme.spacing(2)};
`
);

export const InvoicesBatchesNavigationButton = styled(Button)(
  ({ theme }) => `
display: flex;
height: 31px;
padding: 0;

span {
 font-size: 10px;
 margin-left: 4px;
}

&.button_invoices {
 border-radius: 0 8px 8px 0;
}

&.button_batches {
  border-radius: 8px 0 0 8px;
}

@media (min-width: ${theme.breakpoints.values.sm}px) {
  min-width: 80px;
}

@media (max-width: ${theme.breakpoints.values.sm}px) {
  min-width: 32px;

  span {
    display: none;
  }

  &.text-visible {
    min-width: 80px;
    span {
      display: block;
    }
  }
}

`
);
