import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Wrapper, FormWrapper, Header, EINmodal } from 'src/components/base/core';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { handleIdModalVisibility } from 'src/helpers/containers/helperIdDocumentModal';
import { handleBusinessInfoEdit } from 'src/helpers/containers/helperBusinessInfo';

export type FormProps = {
  line1: string;
  line2: string;
  city: string;
  state: string;
  postal_code: string;
};

type LocationState = {
  isFromSettings: boolean;
  isFromFinance: boolean;
};

export const EINVerificationFailiureContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openIdModal, onClickIdModalOpen, setOpenIdModal, onClickIdModalClose] =
    handleIdModalVisibility();
  const [updatedData, setUpdateData]: any = useState();

  const { isFromSettings, isFromFinance } = location.state
    ? (location.state as LocationState)
    : { isFromSettings: false, isFromFinance: false };

  const [
    accountData,
    serverSideErrors,
    setServerSideErrors,
    appLogin,
    isLoading,
    onChangeHandlerPhone,
    onChangeHandlerTax,
  ]: any = handleBusinessInfoEdit();

  const { register, handleSubmit, setValue, getValues } = useForm<any>();

  const onCancelButton = () => {
    if (!isFromSettings) {
      localStorage.setItem('enable_holdon', 'true');
      localStorage.removeItem('first_time_9_digit_ssn_added');
      localStorage.removeItem('ein-verification-enabled');
      navigate('/hold-on', { state: { isFromFinance: isFromFinance } });
    } else {
      navigate('/settings/hold-on');
    }
  };

  const onAppFormSubmit = handleSubmit(async (data: any) => {
    appLogin(data, onCancelButton, setUpdateData);
  });

  return (
    <>
      {isLoading && !serverSideErrors && <ManualLoader />}
      <Wrapper>
        <FormWrapper onSubmit={onAppFormSubmit} className="no_fullscreen">
          <Header isLogoVisible={false} />
          <EINmodal
            setValue={setValue}
            register={register}
            verificationData={accountData && accountData.individual.verification}
            isSubmittedValuesHeaderVisible={false}
            isFormButtonBlock={true}
            onClickIdModalOpen={onClickIdModalOpen}
            openIdModal={openIdModal}
            closeIdModalEvent={onClickIdModalClose}
          />
        </FormWrapper>
      </Wrapper>
    </>
  );
};
