import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Wrapper, DivWrapper, ContentContainerBottom } from 'src/components/base/core';
import { Typography, Box, Button } from '@mui/material';

import { Images } from 'src/shared/utilities/images';

export const SignUpSuccessContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleContinue = () => {
    navigate('/enable-notifications');
  };

  React.useEffect(() => {
    localStorage.removeItem('enable_hold_sucsess');
    localStorage.removeItem('signup_done');
  }, []);

  return (
    <Wrapper>
      <DivWrapper className={'no_fullscreen'}>
        <Box component={'div'} className={'container_content no_fullscreen no_header'}>
          <Box component={'div'} className={'container_content_top add_bottom_padding'}>
            <div className="status_image">
              <img src={Images.SIGNUP_SUCCESS} alt="Status" height={200} />
            </div>
            <div className="addition_element">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: -4,
                  paddingX: 0,
                  textTransform: 'capitalize',
                }}
              >
                <Typography variant="h6ExtraBold" textAlign="center" lineHeight={1.3}>
                  {t('headingContent.statusses.allJobDone.headingLine1')}
                  <br />
                  {t('headingContent.statusses.allJobDone.headingLine2')}
                  <br />
                  {t('headingContent.statusses.allJobDone.headingLine3')}
                </Typography>
              </Box>
            </div>
          </Box>
        </Box>
        <ContentContainerBottom className={'no_note no_fullscreen'}>
          <Button
            variant="containedLarge"
            color="primary"
            type="button"
            fullWidth
            sx={{ overflow: 'hidden' }}
            onClick={handleContinue}
          >
            {t('buttonTexts.continuePayupButton')}
          </Button>
        </ContentContainerBottom>
      </DivWrapper>
    </Wrapper>
  );
};
