import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useCurrencyFormat } from '../../../../../../../../hooks/useCurrencyFormat';
import { TagWrapper } from '../PaymentDataTable.style';
import { Icon } from '../../../../..';
import { Typography } from '@mui/material';
import { handlePayModeDisplay } from 'src/shared/utilities/dataConvertions';

export const ThirdCell = (props: any) => {
  const navigate = useNavigate();
  const { toAllowEditForAllPaymodesInTransfer } = useFlags(); // set feture flag

  const { isHandleArrowClickable, isTransactionGrid, tabType, onBachPayModeClick } = props?.props; // get the props from the table props
  const getPropsOriginalData = props?.props?.row?.original; // get the row values
  const handleCursorClass = isHandleArrowClickable ? 'is_cursor' : 'no_cursor'; // handle the mouse cusor

  const payoutId = getPropsOriginalData?.id;
  const estPayout = getPropsOriginalData?.estimated_payout_date;
  const estApproved = getPropsOriginalData?.estimated_approved_date;
  const estActiveMethod = handlePayModeDisplay(getPropsOriginalData?.pay_mode, true);
  const estArrivalDate = getPropsOriginalData?.transfer_arrival_expected_date;
  const isCheckPayMode = getPropsOriginalData?.pay_mode === 'CHECK' ?? false;
  const isTabSubmitted = tabType === 'submitted' ?? false;

  const isBatchNumberAvailable =
    getPropsOriginalData &&
    getPropsOriginalData?.payoutBatchTransfer &&
    getPropsOriginalData?.payoutBatchTransfer?.batch_number; // get the batch number

  const isPayModeAvailable = getPropsOriginalData && getPropsOriginalData?.pay_mode; // check is paymode available

  const invoiceAmount = getPropsOriginalData?.payout_amount
    ? useCurrencyFormat(getPropsOriginalData?.payout_amount)
    : useCurrencyFormat(getPropsOriginalData?.amount);

  const disabledChip = (text: string, isInvoices: boolean) => {
    return (
      <TagWrapper className={`current paid ${!isInvoices ? 'batch' : 'invoice'}`}>
        <span className="disable_edit">{text}</span>
      </TagWrapper>
    );
  };

  return (
    <>
      <div className="sub-cell-first">
        {
          /** display the batch number for invoice type (not a transaction/batch type) */
          isBatchNumberAvailable && !isTransactionGrid ? (
            disabledChip(`Batch ${getPropsOriginalData?.payoutBatchTransfer?.batch_number}`, true)
          ) : /** display the batch pay mode for batch type (is a transaction/batch type) but it's not clickable */
          isPayModeAvailable && isTransactionGrid && !isTabSubmitted ? (
            disabledChip(handlePayModeDisplay(getPropsOriginalData?.pay_mode), false)
          ) : /** display the batch pay mode for batch type (is a transaction/batch type) but it's clickable */
          isPayModeAvailable && isTransactionGrid && isTabSubmitted ? (
            isCheckPayMode || toAllowEditForAllPaymodesInTransfer ? (
              <TagWrapper
                className={'current batch'}
                onClick={() =>
                  onBachPayModeClick(
                    estActiveMethod,
                    estPayout,
                    estApproved,
                    payoutId,
                    estArrivalDate
                  )
                }
              >
                <span className={'enable_edit'}>
                  {handlePayModeDisplay(getPropsOriginalData?.pay_mode)}
                </span>
              </TagWrapper>
            ) : (
              disabledChip(handlePayModeDisplay(getPropsOriginalData?.pay_mode), false)
            )
          ) : (
            /** exceptional option */
            <Typography variant="h2Bold" component={'p'} color={'gray.500'}>
              {'----'}
            </Typography>
          )
        }
      </div>

      <span
        onClick={() =>
          isTransactionGrid
            ? navigate(`/batch/${payoutId}`)
            : navigate(`/payout/${payoutId}`, {
                state: { tabType: props?.props?.tabType, payout_id: payoutId },
              })
        }
        className={`${handleCursorClass} amount_cell sub-cell-second fs-mask`}
      >
        {invoiceAmount}
      </span>
      {props?.props?.iconArrowVisible && (
        <div
          onClick={() =>
            isTransactionGrid
              ? navigate(`/batch/${payoutId}`)
              : navigate(`/payout/${getPropsOriginalData.id}`, {
                  state: { tabType: props?.props?.tabType, payout_id: payoutId },
                })
          }
          className="sub-cell-third"
        >
          <Icon iconType="RightAlignedArrow" />
        </div>
      )}
    </>
  );
};
