import { useTranslation } from 'react-i18next';
import {
  TermCardTimeLineWrapper,
  TermCardTimeLineLeft,
  TermCardTimeLineMiddle,
  TermCardTimeLineRight,
  TermCardTimeLineRange,
} from './TermCardTimeLine.style';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

export const TermCardTimeLine = ({ getPaidDuration, isModalLayout = false }: any) => {
  const { t } = useTranslation();

  return (
    <TermCardTimeLineWrapper
      className={`align_${getPaidDuration} ${isModalLayout ? 'modla_layout' : 'normal_layout'}`}
    >
      <TermCardTimeLineLeft>
        <TermCardTimeLineRange className="incoiced_submitted">
          <div className="tctl_top" />
          <div className="tctl_middle today">
            <div className="tctl_middle_container incoiced_submitted">
              <span className="tctl_middle_middle">Today</span>
            </div>
          </div>
          <div className="tctl_bottom">{t('termsSelection.timeline.invoiceSubmitted')}</div>
        </TermCardTimeLineRange>
        <TermCardTimeLineRange className="incoiced_approved">
          <div className="tctl_top">{t('termsSelection.timeline.invoiceApproved')}</div>
          <div className="tctl_middle">
            <div className="tctl_middle_container incoiced_approved">
              <span className="tctl_middle_top">5</span>
              <span className="tctl_middle_bottom">{t('termsSelection.timeline.daysText')}</span>
            </div>
          </div>
          <div className="tctl_bottom" />
        </TermCardTimeLineRange>
      </TermCardTimeLineLeft>
      <TermCardTimeLineMiddle
        className={`align_${getPaidDuration} ${isModalLayout ? 'modla_layout' : 'normal_layout'}`}
      >
        {getPaidDuration !== 30 && (
          <TermCardTimeLineRange className="get_paid">
            <div className="tctl_top">
              <div className="tctl_top_icon">
                <AttachMoneyIcon />
              </div>
            </div>
            <div className="tctl_middle">
              <div className="tctl_middle_container get_paid">
                <span className="tctl_middle_top">{getPaidDuration}</span>
                <span className="tctl_middle_bottom">{t('termsSelection.timeline.daysText')}</span>
              </div>
            </div>
            <div className="tctl_bottom">{t('termsSelection.timeline.youGetPaid')}</div>
          </TermCardTimeLineRange>
        )}
      </TermCardTimeLineMiddle>
      <TermCardTimeLineRight>
        <TermCardTimeLineRange className="customer_pays">
          <div className="tctl_top" />
          <div className="tctl_middle">
            <div className="tctl_middle_container customer_pays">
              <span className="tctl_middle_top">30+</span>
              <span className="tctl_middle_bottom">{t('termsSelection.timeline.daysText')}</span>
            </div>
          </div>
          <div className="tctl_bottom">{t('termsSelection.timeline.customerPays')}</div>
        </TermCardTimeLineRange>
      </TermCardTimeLineRight>
    </TermCardTimeLineWrapper>
  );
};
