import { Box, Typography } from '@mui/material';
import { PayupCard } from '../MainPayupCard/PayupCard';
import { MainPayupCardContainer, MainPayupCardWrapper } from '../MainPayupCard/MainPayupCard.style';
import { LimitsProgressCard } from '../LimitsProgressCard/LimitsProgressCard';
import { handleProgressBarValues } from 'src/helpers/containers/helperTransactions';
import { ManageVirtualCardProps } from 'src/models/component/base.model';

export const ManageVirtualCard = ({
  title = '',
  data,
  isSuccess,
  totalSpendAmount,
  t,
}: ManageVirtualCardProps) => {
  const getSpendingLimits = data?.spending_controls?.spending_limits[0]?.amount ?? 0;

  const [
    currentProgressStep,
    progressValue,
    progressLabel,
    overallProgressLabel,
    overallProgressValue,
  ] = handleProgressBarValues(
    getSpendingLimits,
    totalSpendAmount,
    t('finance.manageCard.manageVirtualCard.totalLimit'),
    t('finance.manageCard.manageVirtualCard.spentLimit')
  );

  return (
    <>
      <MainPayupCardWrapper>
        <MainPayupCardContainer>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              cursor: 'pointer',
              width: '100%',
            }}
          >
            <Typography
              component="p"
              variant="h2ExtraBold"
              textTransform={'uppercase'}
              sx={{
                marginBottom: 5,
                display: 'inline-flex',
                alignItems: 'center',
                color: 'gray.500',
              }}
            >
              {title}
            </Typography>
          </Box>
          <Box display={'flex'} justifyContent={'center'} width={'100%'} marginBottom={4}>
            {data && isSuccess && (
              <PayupCard
                data={data}
                isCardSwipable={false}
                cardShowNumberText={t('finance.manageCard.manageVirtualCard.showNumber')}
              />
            )}
          </Box>

          <Box display={'flex'} justifyContent={'center'} width={'100%'} marginBottom={4}>
            <Box
              display={'flex'}
              width={'100%'}
              flexDirection={'column'}
              maxWidth={'282px'}
              sx={{
                marginLeft: -11,
              }}
            >
              <LimitsProgressCard
                icon="LimitsIcon"
                totalProgressSteps={100}
                currentProgressStep={currentProgressStep}
                progressValue={progressValue}
                progressLabel={progressLabel}
                stepsContent={''}
                overallProgressLabel={overallProgressLabel}
                overallProgressValue={overallProgressValue}
                marginBottom={0}
                isLeftIconEnabled
              />
            </Box>
          </Box>
        </MainPayupCardContainer>
      </MainPayupCardWrapper>
    </>
  );
};
