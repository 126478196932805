import { styled } from '@mui/material/styles';

export const ActionCardSliderWrapper = styled('div')(
  ({ theme }) => `
  display: flex;
  height: 184px;
  margin-bottom: ${theme.spacing(6)};
  position: relative;
  justify-content: flex-start;
  width: 100%;
  margin-top: ${theme.spacing(4)};
`
);

export const ActionCard = styled('div')(
  ({ theme }) => `
  width: 144px;
  min-width: 144px;
  margin-right: 8px;
  cursor: pointer;
`
);

export const ActionCardWrapper = styled('div')(
  ({ theme }) => `
  border-radius: 4px;
  background: ${theme.palette.gray[100]};
  padding-top: 6px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.06), 0px 4px 12px rgba(0, 0, 0, 0.1);
`
);

export const ActionCardContainer = styled('div')(
  ({ theme }) => `
  background: ${theme.palette.white};
  border-radius: 0 0 4px 4px;
  padding: 10px 8px 18px;
`
);

export const ActionCardIcon = styled('div')(
  ({ theme }) => `  
  height: 34px;
  margin-bottom: ${theme.spacing(3)};

  img {
    height: 100%;
    width: auto;
  }
`
);

export const ActionCardHeading = styled('div')(
  ({ theme }) => `
  height: 60px;
`
);

export const ActionCardLink = styled('div')(
  ({ theme }) => `
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  svg {
    height: 13px;
  }
`
);

export const IconArrow = styled('div')(
  ({ theme }) => `
  display: inline-flex;
  width: 15px;
  overflow: hidden;
  cusrsor: pointer;

  svg {
    height: 22px;
    width: 22px;
    color: ${theme.palette.primary.main};
  }
`
);
