import { useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';

import { TransactionDataTable } from '../../Grids/TransactionGridElements/TransactionDataTable/TransactionDataTable';
import { TransactionTabNav } from '../../Navigations/TransactionTabNav/TransactionTabNav';
import { SearchBarTransaction } from '../../Grids/TransactionGridElements/SearchBar/SearchBar';
import { handleTransactionDescription } from 'src/helpers/helperHandlingTableGrid';
import { TransactionCardWrapper } from '../..';
import { TransactionFilterModal } from '../../Grids/TransactionGridElements/Filter/FilterModal/FilterModal';

import { TransactionCardProps } from 'src/models/component/base.model';
import { handleTransactionFilter } from './helperTransactionFilter';

/**
 * Primary UI component for user interaction
 */
export const TransactionCardGrid = ({
  data = [],
  title = '',
  onClickTabNavItem,
  selectedTabNavTitle,
  onHandleSearch,
  onHandleResetSearch,
  searchText,
  setFilterSchema,
  amountSliderMaximumAmout,
  setSearchText,
  setFilterProcessing,
}: TransactionCardProps) => {
  /**
   * call the handleTransactionFilter() function
   * this function is returning the filter modal required values
   */
  const [
    onClickModalOpen,
    onClickModalClose,
    onClickFilterReset,
    filterModalOpen,
    dateSortingOption,
    onChangeDateSorting,
    onChangeDateFilter,
    onChangeDateCustomEvent,
    onCancelDateCustomEvent,
    onClickApplyFilter,
    filterValues,
    resetFilter,
    setFilterAmountRange,
    filterAmountRange,
    filterdFilters,
  ]: any = handleTransactionFilter(setFilterSchema, setSearchText, setFilterProcessing);

  /**
   * Set the data asc and desc order
   */
  let updatedArray = data ? [...data] : [];

  if (dateSortingOption === 'created_asc') {
    updatedArray = data ? [...data].reverse() : [];
  }

  /**
   * Set the minimum and maximum amount in amount filter
   */
  const min = useMemo(() => 0, []);
  const max = useMemo(() => amountSliderMaximumAmout, []);

  const [selectedMinRange, setSelectedMinRange] = useState({
    min: min,
    max: max,
  });

  // based on the filterValues change update the min and max values
  useEffect(() => {
    if (filterValues) {
      if (filterValues.savedMinmumAmount !== null && filterValues.savedMaximumAmount !== null) {
        setSelectedMinRange({
          min: filterValues.savedMinmumAmount,
          max: filterValues.savedMaximumAmount,
        });
      }
    }
  }, [
    filterValues && filterValues.savedMinmumAmount,
    filterValues && filterValues.savedMaximumAmount,
  ]);

  /**
   * update the grid data and add new property call custom_description
   */
  const formatedGridData =
    data &&
    data.length > 0 &&
    updatedArray.map((item: any) => {
      return { ...item, custom_description: handleTransactionDescription(item.description) };
    });

  return (
    <>
      <SearchBarTransaction
        onchangeHandler={onHandleSearch}
        isSearchIconVisible={searchText === '' || searchText === undefined}
        onResetSearch={onHandleResetSearch}
        searchText={searchText}
      />

      <TransactionTabNav
        selectedTitle={selectedTabNavTitle}
        onClickItem={onClickTabNavItem}
        onClickFilterButton={onClickModalOpen}
        isFilterNavActive={filterdFilters && filterdFilters.length > 0 ? true : false}
        filterdFiltersCount={filterdFilters.length ?? 0}
      />

      {filterModalOpen && (
        <TransactionFilterModal
          onModalClose={onClickModalClose}
          onFilterReset={onClickFilterReset}
          onClickApplyFilter={onClickApplyFilter}
          handleDateSorting={onChangeDateSorting}
          selectedDateSortingOption={
            filterValues && filterValues.dateSorting ? filterValues.dateSorting : ''
          }
          onChangeDateFilter={onChangeDateFilter}
          onChangeDateCustomEvent={onChangeDateCustomEvent}
          onCancelDateCustomEvent={onCancelDateCustomEvent}
          selectedDateFilterOption={
            filterValues && filterValues.dateFilterSelected
              ? filterValues.dateFilterSelected
              : 'all'
          }
          selectedCustomDateRange={
            filterValues && filterValues.selectedCustomDateRange
              ? filterValues.selectedCustomDateRange
              : null
          }
          resetFilter={resetFilter}
          setFilterAmountRange={setFilterAmountRange}
          setSelectedMinRange={setSelectedMinRange}
          selectedMinRange={selectedMinRange}
          isAmountFilterActive={filterAmountRange !== undefined ? true : false}
          minmumAmount={0}
          maximumAmount={amountSliderMaximumAmout}
        />
      )}

      {formatedGridData && formatedGridData.length > 0 && (
        <TransactionCardWrapper className={`card-wrapper-home-main`}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              marginBottom: 3,
              cursor: 'pointer',
              width: '100%',
            }}
          >
            <Typography
              component="p"
              variant="h2ExtraBold"
              textTransform={'uppercase'}
              sx={{
                marginBottom: 1,
                display: 'inline-flex',
                alignItems: 'center',
                color: 'gray.500',
              }}
            >
              {title}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <TransactionDataTable
              isToggle={true}
              dataObject={formatedGridData}
              searchInProgress={false}
              iconArrowVisible={true}
              isHandleArrowClickable={true}
              tabType={'transactions'}
              searchText={searchText}
              routeState={{ backRouteUrl: '/finance/account/list' }}
            />
          </Box>
        </TransactionCardWrapper>
      )}
    </>
  );
};
