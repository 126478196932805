import { FC, forwardRef, useState } from 'react';

import { FormInputContainer } from './FormInputDate.style';
import { FormInputErrorMessage } from '../FormInputErrorMessage/FormInputErrorMessage';

import { TextField } from '@mui/material';

import { MaskInputProps } from 'src/models/component/base.model';
import { FormInputWrapper } from '../FormInput/FormInput.style';
import { t } from 'i18next';

export const FormInputDate: FC<MaskInputProps> = forwardRef<HTMLInputElement, MaskInputProps>(
  (
    {
      id,
      label,
      type = 'text',
      placeholder,
      inputError,
      isValid = false,
      maxWidth = '100%',
      inputMode = 'text',
      value,
      onChange,
      autoFocus,
      autoComplete,
      isShrink = false,
      inputFocus,
      readonly,
    },
    ref,
    ...props
  ) => {
    const handleInputClasses =
      inputError && !isValid ? 'error_input' : isValid ? 'valid_input' : 'normal_input';
    const [defaultVal, setDefaultVal] = useState('');
    const [preValue, setPreValue] = useState('');
    const [isFocus, setIsFocus] = useState(false);

    const getMaskedNumber = (event: any) => {
      let val = event.target.value;
      if (val.length > preValue.length && val.length < 8) {
        val = val.replace(/\D/g, '');
        let num = '';
        if (val.length < 3) {
          num = `${val.substring(0, 2)}`;
        } else {
          num = `${val.substring(0, 2)}/${val.substring(2, 4)}/${val.substring(4, 8)}`;
        }
        num = num.trim();
        val = num;
      } else {
        if (val?.length === 6 && val?.length > preValue.length) {
          val = `${val}/`;
          val = val.replace('//', '/');
        }
        if (val?.length > preValue?.length) {
          val = val?.replace(/[^\d/]/g, '');
        }
      }
      setPreValue(val?.slice(0, 10));
      return val?.slice(0, 10);
    };
    return (
      <FormInputWrapper style={{ maxWidth: maxWidth }} className={handleInputClasses}>
        <FormInputContainer>
          <TextField
            fullWidth
            label={label}
            value={!onChange ? defaultVal : value}
            id={id}
            ref={ref}
            type={type}
            aria-label={label}
            placeholder={isFocus && !value ? t('formInput.dobDefault') : placeholder}
            error={inputError?.length > 0}
            focused={value || isFocus ? true : false}
            onFocus={() => setIsFocus(true)}
            onBlur={inputFocus && inputFocus}
            disabled={readonly}
            inputProps={{
              inputMode: inputMode,
              autoFocus: autoFocus,
              autoComplete: autoComplete,
            }}
            onChange={(event: any) => {
              if (onChange) {
                onChange(getMaskedNumber(event));
              } else {
                setDefaultVal(getMaskedNumber(event));
              }
            }}
            {...props}
          />
        </FormInputContainer>
        {inputError && !isValid && <FormInputErrorMessage>{inputError}</FormInputErrorMessage>}
      </FormInputWrapper>
    );
  }
);
