export const enBatchesTranslation = {
  searchInputPlaceHolder: 'Search by Batch Number',
  emptyState: {
    commonTabHeading: 'No batches yet',
    commonTabContent: 'Looks like you have not any batches to view ',
    firstTabHeading: 'No batches yet',
    firstTabContent: 'Your submitted batches will appear here',
    secondTabHeading: 'No batches yet',
    secondTabContent: 'Your approved batches will appear here',
    thirdTabHeading: 'No batches yet',
    thirdTabContent: 'Your paid batches will appear here',
  },
  download: {
    noInvoicesAvailableError: 'There are no transfers within the period you selected.',
  },
  tabHeading: {
    tabOne: 'Submitted Batches',
    tabTwo: 'Approved Batches',
    tabThree: 'Paid Batches',
  },
  tabsHeader: {
    payoutsText: 'Payouts',
    tabOneLeftTitle: 'AMOUNT SUBMITTED',
    tabOneRightTitle: 'TOTAL SUBMITTED',
    tabTwoLeftTitle: 'AMOUNT RECIVED',
    tabTwoRightTitle: 'TOTAL APPROVED',
    tabThreeLeftTitle: 'PAID OUT',
    tabThreeRightTitle: 'TOTAL PAID',
  },
  invoicesBatchesNavigationButton: {
    batchesButton: 'Batches',
    invoicesButton: 'Invoices',
  },
  details: {
    headerTitle: 'Summary',
    timeline: {
      step1Title: 'Batch Submitted',
      step2Title: 'Batch Processing',
      step3Title: 'Batch Ready for Payout',
      step4Title: 'Batch Payout Triggered',
      step5Title: 'Batch Payout On The Way',
      step6Title: 'Batch Payout Complete',
      transcriptLink: 'View Transaction Receipt',
    },
  },
  payModeEditModal: {
    title: 'Edit Payout Method',
    content:
      'You can upgrade your payout method to a faster one before a payout is triggered on {{PaymentTriggedOn}}',
    footerNote:
      'Timing is measured in business days. Payout Mode cannot be changed once the payout has been triggered.',
  },
  payModeEditConfirmationModal: {
    title: 'Payout Method Updated!',
    content:
      'You are now set to receive payouts faster via {{selectedPayMode}}. The payout should arrive by {{arrivalDate}}.',
  },
};
