import { getTransfers } from 'src/services/transfers/transfers.service';
import { configs } from 'src/shared/config/config';
import { TransfersProps } from 'src/shared/utilities/interface';

export const getTransfersData = async ({
  filter,
  hideLoader,
  details,
  pageOffset,
  first,
  sort,
  batchStatus,
}: TransfersProps) => {
  const res = await getTransfers(filter, pageOffset, first, details, hideLoader, sort, batchStatus);

  if (res?.status === 200) {
    const totalCnt =
      Math.ceil(res.data?.payoutBatchTransfers?.totalCount / (first ?? configs.DEFAULT_LIMIT)) - 1;
    return { data: res?.data, totalCnt: totalCnt };
  } else {
    return { data: res?.data, totalCnt: 0 };
  }
};
