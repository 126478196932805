import React, { useCallback, useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import { RangeContainer } from './MultiRangeSlider.style';

export interface MultiRangeSliderProps {
  min: number;
  max: number;
  setFilterAmountRange?: any;
  resetFilter?: boolean;
  selectedMinRange?: any;
  setSelectedMinRange?: any;
  isCentsEnabled?: boolean;
}

export const MultiRangeSlider = ({
  min,
  max,
  setFilterAmountRange,
  resetFilter,
  selectedMinRange,
  setSelectedMinRange,
  isCentsEnabled = false,
}: MultiRangeSliderProps) => {
  const [minVal, setMinVal] = useState(selectedMinRange.min);
  const [maxVal, setMaxVal] = useState(selectedMinRange.max);
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value: any) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const selectedMinVal = selectedMinRange.min;
    const currentNode: any = maxValRef.current;
    if (maxValRef.current) {
      const minPercent = getPercent(selectedMinVal);
      const maxPercent = getPercent(+currentNode.value); // Preceding with '+' converts the value from type string to type number

      const rangeNode: any = range.current;
      if (range.current) {
        rangeNode.style.left = `${minPercent}%`;
        rangeNode.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const selectedMaxVal = selectedMinRange.max;
    const currentNode: any = minValRef.current;
    if (minValRef.current) {
      const minPercent = getPercent(+currentNode.value);
      const maxPercent = getPercent(selectedMaxVal);

      const rangeNode: any = range.current;
      if (range.current) {
        rangeNode.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  useEffect(() => {
    if (resetFilter) {
      const rangeNode: any = range.current;
      rangeNode.style.left = `${0}%`;
      rangeNode.style.right = `${100}%`;
      rangeNode.style.width = `${100}%`;
    }
    setMinVal(selectedMinRange.min);
    setMaxVal(selectedMinRange.max);
  }, [resetFilter]);

  // Get min and max values when their state changes
  // onChange && onChange({ min: minVal, max: maxVal });

  return (
    <RangeContainer>
      <input
        type="range"
        min={min}
        max={max}
        value={selectedMinRange.min}
        ref={minValRef}
        onChange={(event) => {
          const value = Math.min(+event.target.value, maxVal - 1);
          setMinVal(value);
          setSelectedMinRange({ ...selectedMinRange, min: value });
          setFilterAmountRange({ min: value, max: maxVal });
          event.target.value = value.toString();
        }}
        className={classnames('thumb thumb--zindex-3', {
          'thumb--zindex-5': minVal > max - 100,
        })}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={selectedMinRange.max}
        ref={maxValRef}
        onChange={(event) => {
          const value = Math.max(+event.target.value, minVal + 1);
          setMaxVal(value);
          setSelectedMinRange({ ...selectedMinRange, max: value });
          setFilterAmountRange({ min: minVal, max: value });
          event.target.value = value.toString();
        }}
        className="thumb thumb--zindex-4"
      />

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div className="slider__left-value">
          ${isCentsEnabled ? selectedMinRange?.min / 100 : selectedMinRange?.min}
        </div>
        <div className="slider__right-value">
          ${isCentsEnabled ? selectedMinRange?.max / 100 : selectedMinRange?.max}
        </div>
      </div>
    </RangeContainer>
  );
};
