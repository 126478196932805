import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TransactionSummaryPaymentInfoCardWrapper = styled(Box)(
  ({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing(4)};
  background: ${theme.palette.white};
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 6px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
`
);

export const TransactionSummaryPaymentInfoCardTop = styled(Box)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing(4)}; 
 `
);

export const TransactionSummaryPaymentInfoCardBottom = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(3)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .indicator {
   margin-left: ${theme.spacing(2)};
   height: 14px;
   position: relative;
   top: 5px;
  }

  &.separator {
   margin-left: 4px;
   margin-right: 4px;
  }

  &.earned {
   background: ${theme.palette.blue[50]};
  }

  &.spent {
   background: ${theme.palette.orange[50]};

   .indicator {
    transform: rotate(180deg);
   }
  }
 `
);
