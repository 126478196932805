import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import './datePicker.css';

import { Icon } from '../../../../..';

import {
  FilterTagWrapper,
  ButtonCustomDate,
  HeaderRow,
  YearContainer,
} from './CustomDateFilter.style';
import Button from '@mui/material/Button';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowRightLeft from '@mui/icons-material/KeyboardArrowLeft';
import { Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useTranslation } from 'react-i18next';
import { SelectionRow } from '../../Download/SelectionRow/SelectionRow';
import { getFormattedDate } from 'src/shared/utilities/dataConvertions';
import { DownloadCSVState } from 'src/shared/utilities/interface';
import { downloadPeriodOptions } from 'src/shared/utilities/initialStates';

export interface CustomDateFilterProps {
  onDateChange?: (dateObj: any) => void;
  isActive?: boolean;
  dates?: any;
  updateDate?: boolean;
  selectedDateRange?: any;
  cancelDateSelection?: any;
  isExportInvoices?: boolean;
  downloadStates?: DownloadCSVState;
  updateSelectedOption?: (selectedOptionId: string) => void;
}

export const CustomDateFilter = ({
  onDateChange,
  dates,
  updateDate,
  selectedDateRange,
  cancelDateSelection,
  isExportInvoices = false,
  downloadStates,
  updateSelectedOption,
}: CustomDateFilterProps) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [datpickerOpen, setDatpickerOpen] = useState(false);
  const [yearPickerOpen, setYearPickerOpen] = useState(false);
  const [monthPickerOpen, setMonthPickerOpen] = useState(false);
  const { t } = useTranslation();
  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    onDateChange && onDateChange(dates);
  };

  useEffect(() => {
    if (updateDate) {
      setTimeout(function () {
        if (selectedDateRange) {
          const [start, end] = selectedDateRange;
          setStartDate(start);
          setEndDate(end);
        }
      }, 500);
    }
  }, [updateDate]);

  const handlePickerOpen = () => {
    if (startDate === endDate) {
      setEndDate(null);
    }
    setDatpickerOpen(!datpickerOpen);
  };

  const handlePickerClose = () => {
    setDatpickerOpen(false);
  };

  const onDoneClick = () => {
    setEndDate(endDate ?? startDate);
    handlePickerClose();
  };

  const handleYearSelection = () => {
    if (monthPickerOpen) {
      setYearPickerOpen(false);
      setMonthPickerOpen(false);
    } else {
      setYearPickerOpen(!yearPickerOpen);
    }
  };

  const handlePickerCancelButton = () => {
    setDatpickerOpen(false);
    setStartDate(new Date());
    setEndDate(null);
  };

  useEffect(() => {
    const selectedTermIds = dates?.filter((item: any) => item.active && item.type === 'date');
    if (selectedTermIds?.length <= 0) {
      handlePickerCancelButton();
    }
  }, [dates]);

  const buttonLabel = endDate
    ? `${moment(startDate).format('LL')} - ${moment(endDate).format('LL')}`
    : t('earning.filter.dateFilter.custom');

  const getDateRangeText = (): string | undefined => {
    if (startDate && endDate) {
      return `${getFormattedDate(startDate)} - ${getFormattedDate(endDate)}`;
    }
    if (startDate) {
      return `${getFormattedDate(startDate)}`;
    }

    return undefined;
  };

  const handleRadioBtnSelection = (selectedOptionId: string) => {
    if (updateSelectedOption) {
      updateSelectedOption(selectedOptionId);
    }

    if (startDate === endDate) {
      setEndDate(null);
    }
    setDatpickerOpen(true);
  };

  return (
    <FilterTagWrapper>
      {isExportInvoices ? (
        <SelectionRow
          label={t('earning.download.custom')}
          isDatePicker
          dateRange={getDateRangeText()}
          checked={downloadPeriodOptions[6] === downloadStates?.selectedOption}
          optionId={downloadPeriodOptions[6]}
          handleChange={handleRadioBtnSelection}
        />
      ) : (
        <ButtonCustomDate
          type="button"
          className={datpickerOpen || endDate ? 'active' : 'inActive'}
        >
          <span className="lable_name" onClick={handlePickerOpen}>
            {!endDate && <Icon iconType={'DatePicker'} />}
            {buttonLabel}
          </span>
          {endDate && (
            <span
              className="close-icon"
              onClick={() => {
                handlePickerCancelButton();
                cancelDateSelection();
              }}
            >
              <Icon iconType={'CloseIcon'} />
            </span>
          )}
        </ButtonCustomDate>
      )}

      {datpickerOpen && (
        <div className="picker_wrapper">
          <div className="picker_container">
            <DatePicker
              renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                <HeaderRow>
                  <div className="icon_arrow" onClick={decreaseMonth}>
                    <KeyboardArrowRightLeft sx={{ width: 20, height: 20, color: 'gray.500' }} />
                  </div>
                  <YearContainer>
                    <Typography variant="h2ExtraBold" onClick={handleYearSelection}>
                      {moment(date).format('MMMM')}
                    </Typography>
                    <Typography
                      variant="h2ExtraBold"
                      sx={{ paddingLeft: 1, paddingRight: 1 }}
                      onClick={handleYearSelection}
                    >
                      {moment(date).format('yyyy')}
                    </Typography>
                    {yearPickerOpen || monthPickerOpen ? (
                      <ArrowDropUpIcon
                        onClick={handleYearSelection}
                        sx={{ width: 20, height: 20, color: 'gray.500' }}
                      />
                    ) : (
                      <ArrowDropDownIcon
                        onClick={handleYearSelection}
                        sx={{ width: 20, height: 20, color: 'gray.500' }}
                      />
                    )}
                  </YearContainer>
                  <div className="icon_arrow" onClick={increaseMonth}>
                    <KeyboardArrowRightIcon sx={{ width: 20, height: 20, color: 'gray.500' }} />
                  </div>
                </HeaderRow>
              )}
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
              calendarClassName="custom-picker"
              showYearPicker={yearPickerOpen}
              showMonthYearPicker={monthPickerOpen}
              yearItemNumber={12}
            />

            <div className="picker_button">
              <span
                onClick={() => {
                  if (yearPickerOpen || monthPickerOpen) {
                    setYearPickerOpen(false);
                    setMonthPickerOpen(false);
                  } else {
                    handlePickerCancelButton();
                    cancelDateSelection();
                  }
                }}
              >
                {t('earning.filter.dateFilter.cancel')}
              </span>
              <Button
                variant="containedLarge"
                color="primary"
                type="button"
                onClick={() => {
                  if (yearPickerOpen) {
                    setYearPickerOpen(false);
                    setMonthPickerOpen(true);
                  } else if (monthPickerOpen) {
                    setYearPickerOpen(false);
                    setMonthPickerOpen(false);
                  } else {
                    onDoneClick();
                  }
                }}
                sx={{ overflow: 'hidden', width: '83px' }}
              >
                {yearPickerOpen || monthPickerOpen
                  ? t('earning.filter.dateFilter.ok')
                  : t('earning.filter.dateFilter.done')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </FilterTagWrapper>
  );
};
