import { Box, Button, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

import { Modal } from '../Modal/Modal';

import { FinanceTermsAccordianWrapper, TermsLink } from './FinancesTermsModal.style';
import { FinancesTermsModalProps } from 'src/models/component/base.model';

export const FinancesTermsModal = ({
  closeIdModalEvent,
  onAcceptButtonClick,
  t,
}: FinancesTermsModalProps) => {
  // handle close event __
  const handleCloseEvent = () => {
    closeIdModalEvent && closeIdModalEvent();
  };

  const financeTermsContent = [
    {
      title: t('finance.FinancesTermsModal.financeTermsContent.link1'),
      linkPath: 'https://stripe.com/en-gb-us/legal/issuing/sutton/payout-card-terms',
    },
    {
      title: t('finance.FinancesTermsModal.financeTermsContent.link2'),
      linkPath: 'https://stripe.com/en-gb-us/legal/issuing/sutton/apple-pay-terms-and-conditions',
    },
    {
      title: t('finance.FinancesTermsModal.financeTermsContent.link3'),
      linkPath: 'https://stripe.com/en-gb-us/legal/issuing/sutton/payout-card-user-agreement',
    },
    {
      title: t('finance.FinancesTermsModal.financeTermsContent.link4'),
      linkPath: 'https://stripe.com/en-gb-us/legal/treasury-connect-account',
    },
  ];

  return (
    <>
      <Modal onCloseEvent={handleCloseEvent}>
        <Typography
          variant="h4ExtraBold"
          component="p"
          sx={{
            color: 'gray.900',
            textAlign: 'center',
            marginBottom: 6,
            lineHeight: 1.5,
          }}
        >
          {t('finance.FinancesTermsModal.heading')}
        </Typography>
        <Typography
          variant="h3"
          component="p"
          sx={{
            color: 'gray.900',
            textAlign: 'left',
            marginBottom: 6,
            lineHeight: 1.5,
          }}
        >
          {t('finance.FinancesTermsModal.content')}
        </Typography>

        <FinanceTermsAccordianWrapper>
          {financeTermsContent &&
            financeTermsContent.map(
              (
                item: {
                  title: string;
                  linkPath: string;
                },
                index: any
              ) => {
                return (
                  <TermsLink href={item.linkPath} key={index} target="_blank">
                    <CircleIcon sx={{ fontSize: '6px', marginRight: 2 }} /> {item.title}
                  </TermsLink>
                );
              }
            )}
        </FinanceTermsAccordianWrapper>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 3,
            flexDirection: 'column',
            position: 'absolute',
            left: '16px',
            right: '16px',
            bottom: 0,
            top: 'auto',
            paddingBottom: 6,
          }}
          component={'div'}
        >
          <Typography
            variant={'h1'}
            color={'gray.900'}
            paddingTop={4}
            marginBottom={10}
            sx={{ borderTop: '1px solid', borderColor: 'gray.100', lineHeight: 1.5 }}
          >
            {t('finance.FinancesTermsModal.footerNote')}
          </Typography>

          <Button
            variant="containedLarge"
            color="primary"
            type="button"
            fullWidth
            onClick={onAcceptButtonClick}
            sx={{ overflow: 'hidden' }}
          >
            {t('buttonTexts.acceptButton')}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
