import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../..';

import { SearchBarWrapper, SearchInputWrapper } from './SearchBar.style';

interface searchBarProps {
  onchangeHandler?: any;
  isSearchIconVisible?: boolean;
  onResetSearch?: () => void;
  searchText?: string;
}

export const SearchBarTransaction = ({
  onchangeHandler,
  isSearchIconVisible,
  onResetSearch,
  searchText,
}: searchBarProps) => {
  const { t } = useTranslation();

  return (
    <SearchBarWrapper>
      <SearchInputWrapper>
        <input
          type="text"
          placeholder={'Search for Transaction'}
          onChange={onchangeHandler}
          value={searchText}
        />
        {isSearchIconVisible && <Icon iconType="SearchIcon" />}
        {!isSearchIconVisible && (
          <span className="close_icon" onClick={onResetSearch}>
            <Icon iconType="CloseIcon" />
          </span>
        )}
      </SearchInputWrapper>
    </SearchBarWrapper>
  );
};
