import { styled } from '@mui/material/styles';

export const RowCard = styled('div')(
  ({ theme }) => `
  position: relative;

  &:before {
   content: '';
   display: block;
   position: absolute;
   left: 10px;
   top: 19px;
   bottom: 19px;
   width: 1px;
   z-index: 1;
   background: ${theme.palette.gray[100]};
  }
 `
);

export const MiddleRow = styled('div')(
  ({ theme }) => `
  display: flex;
  position: relative;
  z-index: 2;

  &.header {
   padding-bottom: ${theme.spacing(4)};
   justify-content: space-between;
  }

  .header {
    &_left {
     min-width: calc(100% - 100px);
     max-width: calc(100% - 100px);
     padding-right: 10px;
    }

    &_right {
     min-width: 100px;
     max-width: 100px;
     padding-left: 10px;
     display: flex;
     justify-content: flex-end;
    }
  }

  &.content {
   padding-bottom: ${theme.spacing(6)};
   align-items: center;

   &.no_padding {
    padding-bottom: ${theme.spacing(0)};
   }
  }

  .content {
   &_left {
    width: 20px;
   }

   &_right {
    padding-left: ${theme.spacing(4)};
   }
  }

  &.is_disabled {
   .content_right {
    opacity: 0.4;
   }
  }

  &.three_col {
   .content_right {
    padding-right: 12px;
    position: relative;
   }

   .ask_iocn {
     position: absolute;
     top: 1px;
     right: 0px;
     cursor: pointer;
   }
  }
`
);

export const ReceiptLink = styled('div')(
  ({ theme }) => `
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  color: ${theme.palette.blue[500]};
  margin-top: ${theme.spacing(3)};
  margin-left: ${theme.spacing(8.25)};
  padding: ${theme.spacing(3)};
  cursor: pointer;
 `
);
