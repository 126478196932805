import { styled } from '@mui/material/styles';

export const StepOneContentContainer = styled('div')(
  ({ theme }) => `
   display: flex;
   flex-direction: column;

   @media (max-width: ${theme.breakpoints.values.sm}px) {
    min-height: 300px;
   }

   @media (min-width: ${theme.breakpoints.values.sm}px) {
    min-height: 300px;
   }
`
);

export const StepTwoContentContainer = styled(StepOneContentContainer)(
  ({ theme }) => `
  @media (max-width: ${theme.breakpoints.values.sm}px) {
   min-height: 300px;
  }
`
);

export const StepThreeContentContainer = styled(StepOneContentContainer)(
  ({ theme }) => `

  .upload_view_image {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
   padding-bottom: 24px;
   .upload_view_image { 
    img {
     width: auto;
     height: 160px;
    }
   }
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
   padding-bottom: 28px;
   .upload_view_image {
    img {
     width: 100%;
     height: auto;
    }
   }
  }
`
);

export const DocumentType = styled('div')(
  ({ theme }) => `
 display: inline-flex;
 background: ${theme.palette.darkBlue[75]};
 color: ${theme.palette.blue[300]};
 font-size: 12px;
 line-height: 20px;
 padding: ${theme.spacing(0.5, 2)};
 margin-bottom: ${theme.spacing(1)};
 border-radius: 4px;
`
);

export const SelectedDocumentContainer = styled('div')(
  ({ theme }) => `
 display: flex;
 width: 100%;
 flex-direction: column;
 padding-top: ${theme.spacing(4)};
`
);

export const SelectedDocumentHeader = styled('div')(
  ({ theme }) => `
 display: flex;
 width: 100%;
 justify-content: space-between;
 align-items: center;
 margin-bottom: ${theme.spacing(4)};
`
);

export const SelectedDocumentContent = styled(SelectedDocumentContainer)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(4)};
    padding-top: 0;
`
);

export const BackIcon = styled('div')(
  ({ theme }) => `
   justify-content: flex-start;
   cursor: pointer;
   margin-right: ${theme.spacing(1)};

    svg {
     fill: ${theme.palette.gray[600]};
     position: relative;
     left: -8px;
    }
`
);
