import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const FormDatePickerWrapper = styled(Box)(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 ${theme.spacing(4)}; 
    svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-1jxdcj3-MuiSvgIcon-root {
            color:  ${theme.palette.gray[500]} !important;
            font-size: 16px;
            };
    input {
      border: none !important; 
      color: ${theme.palette.gray[900]}; 
      font-size: 14px;

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${theme.palette.gray[500]}; 
        opacity: 1; /* Firefox */
      }
  
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${theme.palette.gray[500]}; 
      }
  
      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${theme.palette.gray[500]}; 
      }

      &:active,
      &:focus,
      &:hover {
        background: ${theme.palette.white} !important;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.palette.gray[100]};
      border-radius: ${theme.shape.borderRadius}px;
    }

    &.valid_input {
      label {
        color: ${theme.palette.primary.main};
      }
      
      input {
        color: ${theme.palette.gray[900]}; 
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: ${theme.palette.primary.main};
        * {
          color: ${theme.palette.primary.main};
        }
      }
    }

    .Mui-error,
    &.error_input {
      label {
        color: ${theme.palette.error.main};
      }

      input {
        color: ${theme.palette.gray[900]}; 
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: ${theme.palette.gray[100]};
        * {
          color: ${theme.palette.error.main};
        }
      }
    }

    .MuiFormLabel-root.Mui-focused {
      color: ${theme.palette.gray[600]};
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.palette.gray[100]};
      border-width: 1px;
    }
    
    .MuiIconButton-root.MuiIconButton-edgeEnd {
      background: transparent !important;
    }

    label.MuiInputLabel-shrink.MuiFormLabel-root {
      font-size: 12px;
      transform: translate(14px, -9px) scale(1);
      background: transparent;
      padding-right: 2px;
      top: 0;
      left: -4px;
    }
`
);

export const FormDatePickerContainer = styled(Box)`
  display: flex;
  width: 100%;
  position: relative;

  input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 50px white inset;
    }

    &:active,
    &:focus,
    &:hover {
      background: var(--white) !important;
    }
  }
`;
