import { styled } from '@mui/material/styles';

export const AccordionSection = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing(4)};
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.06), 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`
);

export const AccordionButton = styled('div')(
  ({ theme }) => `
  height: 64px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  border-radius: 8px;
 
  background: ${theme.palette.white};
  padding-left: ${theme.spacing(6)};
  padding-right: ${theme.spacing(6)};

  .accordion__icon {
    margin-left: auto;
    transition: transform 0.4s ease;
  }

  &.active {
    border-radius: 8px 8px 0 0 ;
  }

  .rotate {
    transform: rotate(180deg);
  }

  &.none_border {
    border-bottom: none;
  }
`
);

export const AccordionContent = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  background-color: white;
  overflow: auto;
  transition: max-height 0.4s ease;
  padding-left: ${theme.spacing(6)};
  padding-right: ${theme.spacing(6)};

  &.active {
    border-radius: 0px 0px 8px 8px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.06), 0px 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
`
);

export const IconWrapper = styled('div')(
  ({ theme }) => `
  margin-top: 2px;
  margin-right: ${theme.spacing(2.5)};

  svg {
    color: ${theme.palette.gray[600]};
    height: 16px;
  }
`
);

export const ArrowLeft = styled('div')(
  ({ theme }) => `
  .icon_arrow {
    position: relative;
    right: -4px;

    svg {
      width: 20px;
      height: 20px;
      color: ${theme.palette.gray[400]};
      margin-top: 2px; 
    }
`
);
