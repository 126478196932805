import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

export const PayModeNoteWrapper = styled('div')(
  ({ theme }) => `
  width: 100%;
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  border: none;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    justify-content: flex-start;
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    justify-content: center;
  }
`
);

export const PayModeNoteRight = styled('div')(
  ({ theme }) => `
  margin-left: ${theme.spacing(3)};
`
);

export const PayModeNoteIcon = styled(Box)(
  ({ theme }) => `
 width: 30px;
`
);
