import { createApi } from '@reduxjs/toolkit/query/react';
import { prepareBaseQuery } from '../../shared/config/config';

export const customerApi = createApi({
  reducerPath: 'customerApi',
  tagTypes: ['Customer'],
  baseQuery: prepareBaseQuery(true),
  endpoints: (builder) => ({
    createCustomer: builder.mutation({
      query: (state) => ({
        url: '/customer/request',
        method: 'POST',
        body: JSON.stringify(state),
      }),
      invalidatesTags: ['Customer'],
    }),
    getCustomer: builder.query({
      query: (state: any) => ({
        url: `/customer/search`,
        method: 'POST',
        body: JSON.stringify({
          text: '',
        }),
      }),
      providesTags: ['Customer'],
    }),
    getInviteList: builder.query({
      query: () => ({
        url: `/query/customer-referrals`,
        method: 'POST',
        body: {
          first: 20,
          offset: 0,
          filter: {},
          orderBy: `CREATED_AT_DESC`,
        },
      }),
      providesTags: ['Customer'],
    }),
    updateCustomerRequest: builder.mutation({
      query: (data) => ({
        url: `/customer/request/update/${data.id}`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      invalidatesTags: ['Customer'],
    }),
    deleteCustomerRequest: builder.mutation({
      query: (id) => ({
        url: `/customer/request/delete/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Customer'],
    }),
    getCustomerReferralReward: builder.query({
      query: () => {
        return {
          url: '/customer/referrals/customer-referral-rewards',
          method: 'GET',
        };
      },
      providesTags: ['Customer'],
    }),
  }),
});
export const {
  useCreateCustomerMutation,
  useGetCustomerQuery,
  useGetInviteListQuery,
  useUpdateCustomerRequestMutation,
  useDeleteCustomerRequestMutation,
  useGetCustomerReferralRewardQuery,
} = customerApi;
