import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const ChoiceCardElement = styled(Box)(
  ({ theme }) => `
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing(6, 4)};
    margin: 0 0 ${theme.spacing(6)};
    border-radius: 8px;
    border: 1px solid ${theme.palette.gray[100]};
    background-color: ${theme.palette.white};
    cursor: pointer;
    box-sizing: border-box;
    position: relative;

    &:hover,
    &.active {
      cursor: pointer;
      border: 1px solid ${theme.palette.primary.main};
    }

    &.hover_disabled:hover {
      cursor: default;
      border: 1px solid ${theme.palette.gray[100]};
    }
`
);

export const ChoiceCardTextRow = styled(Box)(
  ({ theme }) => `
    width: 100%;
    display: flex;
    align-items: center;

    .icon_wrapper {
      margin-right: ${theme.spacing(3)};
    }
`
);

export const ChoiceCardArrow = styled(Box)(
  ({ theme }) => `
    width: auto;
    display: flex;
    right: 2px;
    top: 10px;
    position: absolute;
    align-items: center;
    color: ${theme.palette.gray[300]} !important;
`
);
