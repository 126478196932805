import { styled } from '@mui/material/styles';

export const InviteCustomerNoteCardWrapper = styled('div')(
  ({ theme }) => `
 display: flex;
 width: 100%;
 align-items: center;
 box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.1);
 border-radius: 8px;
 background: ${theme.palette.gray[50]};
 padding: ${theme.spacing(4)};
 cursor: pointer;
`
);

export const InviteCustomerNoteCardLeft = styled('div')(
  ({ theme }) => `
 display: flex;
 align-items: center;
 width: 20px;
 margin-right: ${theme.spacing(3)};
svg {
 ${theme.palette.gray[800]};
}
`
);

export const InviteCustomerNoteCardRight = styled('div')(
  ({ theme }) => `
 display: flex;
 align-items: center;
 flex-direction: column;
 justify-content: flex-start;
 
 > *  {
 text-align: left;
  width: 100%;
 }
`
);
