import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const ProgressbarWrapper = styled(Box)(
  ({ theme }) => `
    width: 100%;
    display: flex;
    flex-direction: column;

    &.progress_info_visible {
      height: 58px;
      align-items: center;
      flex-direction: row;
    }
`
);

export const ProgressbarContainer = styled(Box)(
  ({ theme }) => `
  width: 100%;
  display: flex;
  border-radius: 30px;
  background: ${theme.palette.gray[100]};
  position: relative;
`
);

export const ProgressbarElement = styled(Box)(
  ({ theme }) => `
  display: inline-flex;
  border-radius: 30px;
  background: ${theme.palette.primary.main};
  position: relative;
`
);

export const ProgressInfo = styled('div')`
  position: absolute;
  right: 0;
  height: 58px;
  width: auto;
  top: -28px;

  &.equal_progress {
    display: none;
  }

  &.aligned_info_left {
    left: 0;
    > div {
      left: 0;
      right: auto;
    }
  }
`;

export const ProgressValue = styled('div')(
  ({ theme }) => `
  background: ${theme.palette.white};
  color: ${theme.palette.gray[900]};
  border-radius: 4px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.1);
  top: 0;
  right: 0;
  position: absolute;
  padding: 3px 5px;
  line-height: 14px;
  white-space: nowrap;

  &.align-left {
    left: 0;
    right: auto;
  }
  `
);

export const ProgressLabel = styled('div')(
  ({ theme }) => `
  line-height: 14px;
  color: ${theme.palette.gray[500]};
  bottom: 0;
  right: 0;
  position: absolute;
  white-space: nowrap;

  &.align-left {
    left: 0;
    right: auto;
  }
  `
);
