import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { OnHoldBlock } from 'src/components/base/core';
import { useGetAccountDataMutation } from 'src/services/account/accountServiceApi';
import { routeType } from 'src/helpers/containers/helperRoutePreference';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';

const redirection_time = 4000;

export const SettingsOnHoldContainer = () => {
  const [getAccountData]: any = useGetAccountDataMutation();
  const [account, setAccount]: any = useState();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [updatePreferenceOnServer] = handlePreferences();

  const [timeoutId, setTimeoutId] = useState({});
  const location = useLocation();

  const { isFromHome } =
    location?.state != null
      ? (location?.state as { isFromHome: boolean })
      : {
          isFromHome: false,
        };

  useEffect(() => {
    const timeoutId = setInterval(async () => {
      const retryData = await getAccountData();
      setAccount(retryData);
    }, redirection_time);
    setTimeoutId(timeoutId);

    return () => {
      clearInterval(timeoutId as NodeJS.Timeout);
    };
  }, []);

  useEffect(() => {
    if (account) {
      const getAccountData = account?.data;
      const individual = getAccountData?.individual;
      const company = getAccountData?.company;
      const dueRequirements = getAccountData?.currently_due_requirements;

      const checkSSNUnVerified = individual.verification.status === 'unverified';
      const checkSSNVerified = individual.verification.status === 'verified';
      const checkTaxIdProvided =
        dueRequirements.includes('company.tax_id') || dueRequirements.includes('tax_id');
      const checkVerificationDOcumentrequirement = dueRequirements.includes(
        'individual.verification.document'
      );
      const checkTaxIdDocumentUpdated =
        company?.verification?.document?.front || company?.verification?.document?.back;

      if (
        account?.data?.charges_enabled &&
        !checkVerificationDOcumentrequirement &&
        (!checkTaxIdProvided || checkTaxIdDocumentUpdated) &&
        checkSSNVerified
      ) {
        localStorage.removeItem('enable_holdon');
        clearInterval(timeoutId as NodeJS.Timeout);
        updatePreferenceOnServer(routeType.ACCOUNT_APPROVED);

        if (isFromHome) {
          navigate('/dashboard');
        } else {
          navigate('/settings/account-info');
        }

        return;
      }

      if (checkSSNUnVerified || checkVerificationDOcumentrequirement) {
        if (isFromHome) {
          navigate('/dashboard');
        } else {
          localStorage.removeItem('enable_holdon');
          clearInterval(timeoutId as NodeJS.Timeout);
          setTimeout(function () {
            updatePreferenceOnServer(routeType.ID_DOC_FAIL);
            navigate('/continueto-id-doc-on-verification-failed', {
              state: { isFromSettings: true },
            });
          }, 2000);
        }
      } else if (
        checkTaxIdProvided &&
        !checkTaxIdDocumentUpdated &&
        account?.data?.business_type === 'company'
      ) {
        if (isFromHome) {
          navigate('/dashboard');
        } else {
          localStorage.removeItem('enable_holdon');
          clearInterval(timeoutId as NodeJS.Timeout);
          updatePreferenceOnServer(routeType.EIN_FAIL_BUSINESS);
          setTimeout(function () {
            navigate('/ein-verification-failed', {
              state: { isFromSettings: true },
            });
          }, 2000);
        }
      }
    }
  }, [account]);

  return <OnHoldBlock />;
};
