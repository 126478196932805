import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useLocation, useNavigate } from 'react-router-dom';
import { TermsListContainer } from './PaymentsSelection.style';
import {
  useGetAccountQuery,
  useUpdateAccountMutation,
} from 'src/services/account/accountServiceApi';
import { GetPayModes, GetTerms } from '../../../services/account/account.service';
import { AccountParams, BankandTermsLocationState } from '../../../shared/utilities/interface';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import dtoToFormValues from '../../../shared/utilities/dto';
import { Box, Button } from '@mui/material';

import {
  Header,
  Wrapper,
  HeadingSummaryBlock,
  ContentContainerBottom,
  FormWrapper,
  TermsCard,
  PayModeCard,
  SnackBar,
  PayModeNote,
} from 'src/components/base/core';
import { commonSpacing, theme } from 'src/shared/theme/theme';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';
import { routeType } from 'src/helpers/containers/helperRoutePreference';
import { PAY3342 } from 'src/constants/constants';
import { PayModesInterface } from 'src/models/component/base.model';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const PaymentsSelectionContainer = () => {
  const { t } = useTranslation();
  const { recipientDetailsFormEnabled } = useFlags();

  const [cardNet, setCardNet] = useState('');
  const [payMode, setPayMode] = useState('');
  const [responseType, setResponseType] = useState('');
  const [errorState, setErrorState] = useState('');
  const [terms, setTerms] = useState([]);
  const [payModes, setPayModes] = useState([]);
  const { data: account } = useGetAccountQuery({ count: 5 }, { refetchOnMountOrArgChange: true });

  const location = useLocation();

  const {
    fromSettings,
    fromHome,
    isSettingDesktop,
    isFromFinance,
    backFrom,
    isAfterKycFromTermScreen,
  } =
    location?.state != null
      ? (location?.state as BankandTermsLocationState)
      : {
          fromSettings: false,
          fromHome: false,
          isSettingDesktop: false,
          isFromFinance: false,
          backFrom: '',
          isAfterKycFromTermScreen: false,
        };

  const [accountFetchedType, setaccountFetched] = useState(null);
  const [updateAccount, { isLoading }] = useUpdateAccountMutation();
  const navigate = useNavigate();

  const [updatePreferenceOnServer] = handlePreferences();

  const initTerms = async () => {
    await GetTerms().then((response) => {
      setTerms(response.data.terms);
    });
  };

  const initPayModes = async () => {
    await GetPayModes().then((response) => {
      setPayModes(response.data);
    });
  };

  useEffect(() => {
    localStorage.removeItem('early-payout');
    localStorage.removeItem('enable_holdon');
    localStorage.removeItem('enable_account_edit');
    localStorage.removeItem('backFromReview');
    localStorage.removeItem('menuNav');
    if (PAY3342) {
      initPayModes();
    } else {
      initTerms();
    }
  }, []);

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AccountParams>();

  React.useEffect(() => {
    dtoToFormValues(setValue, account);
    if (account) {
      setaccountFetched(account?.business_type);
    }
    account && localStorage.setItem('account_id', account?.id);

    if (account && account.default_pay_mode !== '' && account.default_pay_mode !== null) {
      setPayMode(account.default_pay_mode);
    }
  }, [account]);

  const appSubmit = handleSubmit(async (data) => {
    const isPayModeIsCheck = payMode === 'check';

    if (isPayModeIsCheck && recipientDetailsFormEnabled) {
      navigate('/recipient-details', { state: location.state });
    } else {
      setResponseType('');
      setErrorState('');

      let dataModel;

      if (PAY3342) {
        dataModel = {
          default_pay_mode: payMode,
        };
      } else {
        dataModel = {
          default_payment_term_id: cardNet,
          accept_other_terms: false,
        };
      }

      const accountUpdateResponse = await updateAccount(dataModel);

      // @ts-ignore
      if (accountUpdateResponse?.data) {
        handleRedirection();
      } else {
        // @ts-ignore
        const responseError = accountUpdateResponse?.error?.data?.message;
        if (responseError && typeof responseError === 'string') {
          setResponseType('error');
          setErrorState(responseError);
        } else {
          setResponseType('error');
        }
      }
    }
  });

  /**
   * handle redirection with the logic
   */
  const handleRedirection = () => {
    const isPayModeIsCheck = payMode === 'check';

    if (recipientDetailsFormEnabled && isPayModeIsCheck) {
      navigate('/paymode-selection-success', { state: location.state });
    } else {
      if (isPayModeIsCheck && !recipientDetailsFormEnabled) {
        navigate('/paymode-selection-success', { state: location.state });
      } else {
        if (backFrom === 'account' && !isAfterKycFromTermScreen) {
          localStorage.setItem('enable_account_edit', 'true');
          navigate('/account');
        } else if (isAfterKycFromTermScreen) {
          updatePreferenceOnServer(routeType.BANK_CREATION);
          navigate('/bank-details', { state: location.state });
        } else {
          updatePreferenceOnServer(routeType.BANK_CREATION);
          navigate('/bank-details');
        }
      }
    }
  };

  const selectCard = (value: any) => {
    setCardNet(value);
  };

  const handleBackButton = () => {
    if (isAfterKycFromTermScreen) {
      if (fromHome) {
        navigate('/dashboard');
      } else if (isFromFinance) {
        navigate('/finance/account/move-money');
      } else if (fromSettings) {
        navigate('/settings/bank-setup');
      }
    } else {
      localStorage.setItem('early-payout', 'true');
      updatePreferenceOnServer(routeType.EARLY_PAYOUT);
      navigate('/early-payout');
    }
  };

  return (
    <Wrapper>
      {isLoading && <ManualLoader />}
      <FormWrapper className={'no_fullscreen'} onSubmit={appSubmit}>
        <Box component={'div'} className={'container_content bottom_terms_padding terms_note'}>
          <Box
            component={'div'}
            className={'container_content_top terms_note'}
            sx={{
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                minHeight: 448 + commonSpacing.desktopPaddingB * 4,
                paddingBottom: 4,
              },
            }}
          >
            <Header
              isBackButtonVisible={backFrom !== 'account'}
              onBackButtonClick={handleBackButton}
              paddingX={0}
              backButtonId={'IndividualReviewBack'}
              isLanguageSwitcher
            />
            <Box sx={{ marginBottom: 6 }}>
              <HeadingSummaryBlock
                heading={
                  PAY3342
                    ? t('headingContent.commonSteps.payModeSelection.heading')
                    : t('headingContent.commonSteps.termsSelection.heading')
                }
                headingAlignment="left"
                content={
                  PAY3342
                    ? t('headingContent.commonSteps.payModeSelection.content')
                    : t('headingContent.commonSteps.termsSelection.content')
                }
                contentAlignment="left"
              />
            </Box>
            <Box>
              {PAY3342 ? (
                <TermsListContainer>
                  {payModes?.map((option: PayModesInterface) => {
                    return (
                      <Fragment key={option.id}>
                        <PayModeCard
                          id={option.id}
                          modeName={option.modeName}
                          feePercentage={option.feePercentage}
                          isActive={payMode === option.id}
                          comingSoon={!option.active}
                          onCardClick={(id) => setPayMode(id)}
                          timeDuration={option.time}
                          isIconVisible={option.id === 'debit_card' ? true : false}
                        />
                      </Fragment>
                    );
                  })}
                </TermsListContainer>
              ) : (
                <TermsListContainer>
                  {terms &&
                    terms
                      .sort(function (a: any, b: any) {
                        return a.due_days - b.due_days;
                      })
                      .map((option: any, index) => {
                        const getNetAmount = parseInt(option.description);

                        return (
                          <TermsCard
                            isActive={cardNet === option.id}
                            key={index}
                            cost={option.id}
                            date={option.due_days}
                            net={100 - getNetAmount}
                            selectCard={selectCard}
                          />
                        );
                      })}
                </TermsListContainer>
              )}
            </Box>
            {PAY3342 && (
              <Box component={'div'} className="container_content_bottom terms_note">
                <PayModeNote content={t('termsSelection.payModeNote')} />
              </Box>
            )}
          </Box>
        </Box>
        <SnackBar
          messageHeading={responseType}
          messageContent={errorState ? errorState : undefined}
          type={responseType === 'error' ? 'error' : 'success'}
          onClose={() => {
            setResponseType('');
            setErrorState('');
          }}
        />
        <ContentContainerBottom className={'no_fullscreen no_note'}>
          <Button
            variant="containedLarge"
            color="primary"
            type="submit"
            fullWidth
            disabled={payMode !== '' ? false : true}
          >
            {backFrom === 'account'
              ? t('buttonTexts.updateButton')
              : PAY3342
              ? t('buttonTexts.continueButton')
              : t('buttonTexts.confirmTermsButton')}
          </Button>
        </ContentContainerBottom>
      </FormWrapper>
    </Wrapper>
  );
};
