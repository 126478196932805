import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetBlockHeight } from 'src/hooks/useGetBlockHeight';
import { useGetAccountQuery } from 'src/services/account/accountServiceApi';
import { getNotifyMeStatus, saveNotifyMeClicked } from 'src/services/finance/finance.service';
import { localPrefs, notificationTypes } from 'src/shared/config/config';
import {
  Header,
  HeaderFixed,
  WrpperAuthorizedContainer,
  ButtonAuthorizedWrapper,
  FormAuthorizedWrapper,
  Wrapper,
  Messages,
} from '../../../components/base/core';
import { NotificationIcon } from '../../../components/base/core/Media/Icon/Icons/Icons';
import { commonSpacing, theme } from '../../../shared/theme/theme';
import { Images } from '../../../shared/utilities/images';

export const FinanceContainerNotifyMe = () => {
  const { t } = useTranslation();
  const [elementRef, height] = useGetBlockHeight();
  const [notifyClicked, setNotifyClicked] = useState(
    localStorage.getItem(localPrefs.NOTIFY_FINANCES_PREF) === localPrefs.STATE_PRESSED
  );
  const { data } = useGetAccountQuery('');

  useEffect(() => {
    const updateNotifyMePreference = async () => {
      if (data && data?.id) {
        const response = await getNotifyMeStatus(data?.id);
        response?.data?.every((item: any) => {
          if (
            item?.notification_name === notificationTypes.PAYUP_FINANCIAL_CARD &&
            item?.is_active
          ) {
            setNotifyClicked(true);
            localStorage.setItem(localPrefs.NOTIFY_FINANCES_PREF, localPrefs.STATE_PRESSED);
            return false;
          }
          return true;
        });
      }
    };

    if (!notifyClicked) {
      updateNotifyMePreference();
    }
  }, [data]);

  const onNotifyMeClicked = async () => {
    const response = await saveNotifyMeClicked(data?.id);
    if (response?.data?.is_active) {
      setNotifyClicked(true);
      localStorage.setItem(localPrefs.NOTIFY_FINANCES_PREF, localPrefs.STATE_PRESSED);
    }
  };

  return (
    <>
      <HeaderFixed className="fixed_only_desktop">
        <Header isMainMunuVisible isSkipButtonHide isBackButtonHide paddingX={0} />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper>
        <WrpperAuthorizedContainer>
          <FormAuthorizedWrapper>
            <Box
              ref={elementRef}
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                  minHeight: height,
                  paddingBottom: height ? 4 : commonSpacing.desktopPaddingB,
                },
              }}
            >
              <Typography variant="h6ExtraBold" component="div" sx={{ fontWeight: 800 }}>
                {t('finance.meetSoon')}
              </Typography>
              <Typography
                variant="h6ExtraBold"
                component="div"
                sx={{ color: 'primary.main', fontWeight: 800, marginBottom: theme.spacing(8) }}
              >
                {t('finance.payupCard')}
              </Typography>
              <Box
                sx={{
                  marginBottom: theme.spacing(6),
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={Images.FINANCE_ACCOUNT_BANNER}
                  alt="PayUp finance account"
                  height="220px"
                />
              </Box>
              <Typography
                variant="h3"
                component="p"
                sx={{
                  color: 'gray.800',
                  textAlign: 'left',
                  marginBottom: theme.spacing(6),
                  letterSpacing: '-0.24px',
                  lineHeight: `24px`,
                  justifyContent: 'start',
                }}
              >
                {t('finance.financeText')}
              </Typography>
            </Box>
            <ButtonAuthorizedWrapper>
              {notifyClicked ? (
                <div>
                  <Messages
                    messageContent={t('finance.messageText')}
                    type="success"
                    hideCloseIcon
                  />
                </div>
              ) : (
                <Button
                  variant="outlinedLarge"
                  color="primary"
                  startIcon={<NotificationIcon />}
                  fullWidth
                  id={'Finance'}
                  onClick={onNotifyMeClicked}
                  sx={{ backgroundColor: 'blue.500', color: 'white', marginBottom: 2 }}
                >
                  {t('finance.btnText')}
                </Button>
              )}
            </ButtonAuthorizedWrapper>
          </FormAuthorizedWrapper>
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
