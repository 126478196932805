import { styled } from '@mui/material/styles';

export const Form = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: column;

  .top_content {
   font-weight: 400;
   font-size: 16px;
   line-height: 20px;
   color: #1A1F36;
   margin-bottom: ${theme.spacing(3)};
  }

 textarea {
  border: 1px solid ${theme.palette.gray[100]};
  border-radius: 8px;
  background: ${theme.palette.white};
  resize: none;
  overflow-y: auto !important;
  padding: 8px;
  min-height: 226px;
  max-height: 226px;
 }
 `
);

export const ButtonWrapper = styled('div')(
  ({ theme }) => `
 display: flex;
 position: absolute;
 bottom: 20px;
 left: 24px;
 right:  24px;
`
);
