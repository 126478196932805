import { useTranslation } from 'react-i18next';

import { FilterIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import { TransactionTabNavWrapper, TransactionTabNavItem } from './TransactionTabNav.style';

interface ITransactionTabNav {
  selectedTitle?: string;
  onClickItem?: any;
  onClickFilterButton?: any;
  isFilterNavActive?: boolean;
  filterdFiltersCount: number;
}

export const TransactionTabNav = ({
  selectedTitle,
  onClickItem,
  onClickFilterButton,
  isFilterNavActive,
  filterdFiltersCount = 0,
}: ITransactionTabNav) => {
  const { t } = useTranslation();

  const tabNavData = [
    { title: 'Earned', value: 'earned' },
    { title: 'Spent', value: 'spent' },
  ];

  return (
    <TransactionTabNavWrapper>
      <TransactionTabNavItem
        className={`filter ${isFilterNavActive ? 'active' : 'in-active'}`}
        onClick={onClickFilterButton}
      >
        <FilterIcon width={'11'} />{' '}
        {`Filter ${filterdFiltersCount === 0 ? '' : `(${filterdFiltersCount})`}`}
      </TransactionTabNavItem>
      {tabNavData &&
        tabNavData.map((navItem, index) => {
          return (
            <TransactionTabNavItem
              key={index}
              className={selectedTitle === navItem.title ? 'active' : 'none'}
              onClick={() => onClickItem(navItem.title)}
            >
              {navItem.title}
            </TransactionTabNavItem>
          );
        })}
    </TransactionTabNavWrapper>
  );
};
