import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorCardWrapper, Header, ManualLoader, Wrapper } from 'src/components/base/core';
import { navigateToScreen } from 'src/helpers/helperLanding';
import { getWebAuth } from 'src/shared/config/config';

export const Error401Container = () => {
  const navigate = useNavigate();
  const { webauthnEnabled } = useFlags();

  const [isLoader, setIsLoader] = useState(false); // handle login page visibility throgh the components __

  const { webAuth, isSuccess }: any = getWebAuth();

  const webAuthnLogin = () => {
    localStorage.setItem('from_login', 'yes');
    localStorage.setItem('from_signup', 'no');
    localStorage.setItem('from_resend', 'no');
    setIsLoader(true);

    webAuth.authorize({
      responseType: 'code',
      redirectUri: `${window.location.origin}/verify-code`,
      mode: 'login',
    });

    setTimeout(function () {
      setIsLoader(false);
    }, 1200);
  };

  return (
    <Wrapper>
      {isLoader && <ManualLoader />}
      <Header isBackButtonVisible={false} />
      <Box paddingTop={6} paddingX={10} sx={{ textAlign: 'center' }}>
        <ErrorCardWrapper>
          <Typography
            variant="h3"
            component={'p'}
            color={'gray.600'}
            textAlign={'center'}
            marginBottom={6}
          >
            {'Sorry, you have been logged out! Please log in again.'}
          </Typography>
          {isSuccess && webAuth && (
            <Button
              variant="contained"
              color="primary"
              type="button"
              sx={{ width: '100px', marginX: 'auto' }}
              onClick={webauthnEnabled ? webAuthnLogin : () => navigateToScreen(navigate, true)}
            >
              {'Sign In'}
            </Button>
          )}
        </ErrorCardWrapper>
      </Box>
    </Wrapper>
  );
};
