import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { OnHoldBlock } from 'src/components/base/core';
import { useGetAccountDataMutation } from 'src/services/account/accountServiceApi';
import { routeType } from 'src/helpers/containers/helperRoutePreference';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';
import { useFlags } from 'launchdarkly-react-client-sdk';

const redirection_time = 15000;

export const OnHoldContainer = () => {
  const [getAccountData]: any = useGetAccountDataMutation();
  const [account, setAccount]: any = useState();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [updatePreferenceOnServer] = handlePreferences();
  const { reduceTheBusinessFlowFields } = useFlags();

  const [timeoutId, setTimeoutId] = useState({});

  const { isFromFinance } =
    location?.state != null
      ? (location?.state as { isFromFinance: boolean })
      : { isFromFinance: false };

  useEffect(() => {
    localStorage.removeItem('backFromReview');
    localStorage.removeItem('menuNav');
    localStorage.removeItem('enable_account_edit');

    const timeoutId = setInterval(async () => {
      const retryData = await getAccountData();
      setAccount(retryData);
    }, redirection_time);
    setTimeoutId(timeoutId);

    return () => {
      clearInterval(timeoutId as NodeJS.Timeout);
    };
  }, []);

  useEffect(() => {
    if (account) {
      const getAccountData = account?.data;
      const individual = getAccountData?.individual;
      const errors = getAccountData?.errors;
      const dueRequirements = getAccountData?.currently_due_requirements;

      const isChargesEnabled = getAccountData?.charges_enabled;
      const isSSNLastProvided = individual?.ssn_last4_provided;
      const getVerificationObj = individual?.verification;
      const getVerificationDocumentObj = individual?.verification?.document;
      const getVerificationStatus = getVerificationObj?.status;
      const isTaxIDProvided = getAccountData?.company?.tax_id_provided;
      const isCompanyBusinessType = getAccountData?.business_type === 'company';
      const isFrontVerificationDocument = getVerificationDocumentObj?.front !== null;

      let handleTheApprovedStatus = false;

      if (
        isChargesEnabled &&
        dueRequirements.length === 0 &&
        isSSNLastProvided === false &&
        isTaxIDProvided &&
        reduceTheBusinessFlowFields &&
        isCompanyBusinessType
      ) {
        handleTheApprovedStatus = true;
      } else if (
        isSSNLastProvided === true &&
        getVerificationStatus === 'unverified' &&
        reduceTheBusinessFlowFields &&
        isCompanyBusinessType
      ) {
        handleTheApprovedStatus = false;
      } else if (
        isChargesEnabled &&
        dueRequirements.length === 0 &&
        isSSNLastProvided === true &&
        (getVerificationStatus === 'verified' || getVerificationStatus === 'pending') &&
        reduceTheBusinessFlowFields &&
        isCompanyBusinessType
      ) {
        handleTheApprovedStatus = true;
      } else if (
        isChargesEnabled &&
        dueRequirements.length === 0 &&
        isFrontVerificationDocument === true &&
        (getVerificationStatus === 'verified' || getVerificationStatus === 'pending') &&
        reduceTheBusinessFlowFields &&
        isCompanyBusinessType
      ) {
        handleTheApprovedStatus = true;
      } else if (
        isChargesEnabled &&
        dueRequirements.length === 0 &&
        reduceTheBusinessFlowFields &&
        !isCompanyBusinessType
      ) {
        handleTheApprovedStatus = true;
      } else {
        handleTheApprovedStatus = false;
      }

      //back from the account dashboard
      if (
        account?.data?.charges_enabled &&
        account?.data?.external_account_id &&
        (account?.data?.currently_due_requirements?.length === 0 ||
          account?.data?.errors?.length === 0)
      ) {
        clearInterval(timeoutId as NodeJS.Timeout);
        updatePreferenceOnServer(routeType.ACCOUNT_COMPLETED);
        if (isFromFinance) {
          navigate('/finance');
        } else {
          navigate('/account');
        }
        return;
      }

      if (
        reduceTheBusinessFlowFields
          ? handleTheApprovedStatus
          : isChargesEnabled && dueRequirements.length === 0
      ) {
        localStorage.removeItem('enable_holdon');
        localStorage.removeItem('early-payout');
        localStorage.removeItem('review_error_block');
        localStorage.removeItem('first_time_9_digit_ssn_added');
        localStorage.setItem('approved-account', 'true');
        localStorage.removeItem('ein-verification-failed');
        clearInterval(timeoutId as NodeJS.Timeout);
        updatePreferenceOnServer(routeType.ACCOUNT_APPROVED);

        if (isFromFinance) {
          navigate('/finance');
        } else {
          navigate('/account-approved');
        }
        return;
      }

      if (
        individual.verification.status === 'unverified' &&
        individual.ssn_last4_provided === true &&
        !localStorage.getItem('ein-verification-enabled')
      ) {
        localStorage.removeItem('enable_holdon');
        clearInterval(timeoutId as NodeJS.Timeout);
        setTimeout(function () {
          if (account?.data?.business_type === 'company') {
            if (localStorage.getItem('first_time_9_digit_ssn_added')) {
              updatePreferenceOnServer(routeType.ID_DOC_FAIL);
              navigate('/continueto-id-doc-on-verification-failed', {
                state: { isFromFinance: isFromFinance },
              });
            } else {
              updatePreferenceOnServer(routeType.VERIFICATION_FAIL_SSN);
              navigate('/ssn-verification-failed', { state: { isFromFinance: isFromFinance } });
            }
          } else if (account?.data?.business_type === 'individual') {
            if (localStorage.getItem('first_time_9_digit_ssn_added')) {
              updatePreferenceOnServer(routeType.ID_DOC_FAIL);
              navigate('/continueto-id-doc-on-verification-failed', {
                state: { isFromFinance: isFromFinance },
              });
            } else {
              updatePreferenceOnServer(routeType.VERIFICATION_FAIL_SSN);
              navigate('/ssn-verification-failed', { state: { isFromFinance: isFromFinance } });
            }
          }
        }, 2000);
      } else if (
        localStorage.getItem('ein-verification-enabled') &&
        account?.data?.business_type === 'company'
      ) {
        localStorage.removeItem('enable_holdon');
        clearInterval(timeoutId as NodeJS.Timeout);
        updatePreferenceOnServer(routeType.EIN_FAIL_BUSINESS);
        setTimeout(function () {
          navigate('/ein-verification-failed', { state: { isFromFinance: isFromFinance } });
        }, 2000);
      } else if (
        (errors.length > 0 || dueRequirements.length > 0) &&
        !localStorage.getItem('ein-verification-enabled')
      ) {
        localStorage.removeItem('enable_holdon');
        clearInterval(timeoutId as NodeJS.Timeout);
        setTimeout(function () {
          if (account?.data?.business_type === 'company') {
            updatePreferenceOnServer(routeType.VERIFICATION_FAIL_BUSINESS);
            navigate('/verification-failed', { state: { isFromFinance: isFromFinance } });
            localStorage.setItem('failed_company', 'true');
          }
          if (account?.data?.business_type === 'individual') {
            updatePreferenceOnServer(routeType.VERIFICATION_FAIL_INDIVIDUAL);
            navigate('/verification-failed', { state: { isFromFinance: isFromFinance } });
          }
        }, 2000);
      }
    }
  }, [account]);

  return <OnHoldBlock />;
};
