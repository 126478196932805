import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PaymentTabNavWrapper, PaymentTabNavContainer } from './PaymentTabNav.style';

export const PaymentTabNav = ({ isInvoices = true }: { isInvoices?: boolean }) => {
  const { t } = useTranslation();
  const tabNavData = [
    {
      title: t('earning.firstTabHeading'),
      urlPath: isInvoices ? '/earnings/submitted' : '/batches/submitted',
    },
    {
      title: t('earning.secondTabHeading'),
      urlPath: isInvoices ? '/earnings/approved' : '/batches/approved',
    },
    {
      title: t('earning.thirdTabHeading'),
      urlPath: isInvoices ? '/earnings/paid' : '/batches/paid',
    },
  ];
  return (
    <PaymentTabNavWrapper>
      <PaymentTabNavContainer>
        {tabNavData &&
          tabNavData.map((navItem, index) => {
            return (
              <NavLink to={navItem.urlPath} key={index}>
                {navItem.title}
              </NavLink>
            );
          })}
      </PaymentTabNavContainer>
    </PaymentTabNavWrapper>
  );
};
