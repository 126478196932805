import React, { useEffect, useMemo } from 'react';
import { useFilters, useTable } from 'react-table';

import { COLUMNS } from './Columns/Columns';

import {
  Thead,
  TheadTr,
  TheadTh,
  // TheadInfoIconWrapper,
  TbodyWrapper,
  Tbody,
  TbodyTr,
  TbodyTd,
  TbodyContainer,
} from './TransactionDataTable.style';

// import { TooltipInfo } from '../../TooltipInfo/TooltipInfo';
import { TableWrapper } from './TableWrapper/TableWrapper';
import { Icon } from '../../..';

import {
  handleTheadColumnClassName,
  // handleColumnTooltipContent,
  handleTbodyColumnClassName,
  isTermUpdate,
} from '../../../../../../helpers/helperHandlingTableGrid';
import { SearchResultEmpty } from '../SearchResultEmpty/SearchResultEmpty';
import { useTranslation } from 'react-i18next';
import { TransactionDataTableProps } from 'src/models/component/base.model';
// import { fuzzyTextFilterFn } from './fuzzyTextFilterFn';

export const TransactionDataTable = ({
  dataObject,
  isOldInvoices,
  isHandleArrowClickable,
  isTermEditable,
  tabType,
  onClickTermId,
  onTermSelection,
  isTermEditEnabled,
  isToggle,
  isTermClicked,
  selectedPayoutId,
  isAllSelected,
  searchInProgress,
  hideHeader = false,
  iconArrowVisible = true,
  searchText,
  limit = 20000,
  routeState,
}: TransactionDataTableProps) => {
  const { t } = useTranslation();

  const columns: any = useMemo(
    () =>
      COLUMNS(
        t('earning.tableColumns.invoice'),
        t('earning.tableColumns.date'),
        t('earning.tableColumns.amount')
      ),
    []
  );

  const data: any = useMemo(() => dataObject, [dataObject]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // setFilter is the key!!!
    // @ts-ignore
    setFilter,
  } = useTable(
    {
      columns: columns,
      data: data,
    },
    useFilters
  );

  const isTransactions = false;

  // Listen for input changes outside
  useEffect(() => {
    // This will now use our custom filter for age
    if (searchText && searchText.length > 2) {
      setFilter('custom_description', searchText);
    } else {
      setFilter('custom_description', '');
    }
  }, [searchText]);

  return (
    <>
      <TableWrapper {...getTableProps()} isToggle={true} tabType={'transactions'}>
        <Thead className="invoices_table_head">
          {!hideHeader &&
            headerGroups.map((headerGroup) => {
              return (
                <TheadTr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => {
                    //remove isOldInvoices because need to show all column
                    return (
                      <TheadTh
                        {...column.getHeaderProps()}
                        className={handleTheadColumnClassName(
                          index,
                          isOldInvoices,
                          iconArrowVisible
                        )}
                      >
                        <span>
                          {column.render('Header', {
                            iconArrowVisible,
                          })}
                        </span>
                      </TheadTh>
                    );
                  })}
                </TheadTr>
              );
            })}
        </Thead>

        <TbodyWrapper {...getTableBodyProps()}>
          {rows?.length > 0 ? (
            rows.map((row, index) => {
              prepareRow(row);
              //@ts-ignore
              const getRowDataID = row?.original?.id; // get the payout id from the data
              // @ts-ignore
              let isChecked = row?.original?.isChecked;
              isChecked = isChecked === undefined ? false : isChecked;
              return (
                index < limit && (
                  <TbodyContainer {...row.getRowProps()}>
                    {((isTermEditEnabled && isOldInvoices) || isAllSelected) &&
                      isOldInvoices &&
                      isTermEditEnabled && (
                        <>
                          {isChecked && isChecked !== undefined ? (
                            <input
                              type="checkbox"
                              name={'checkboxPayout'}
                              id={getRowDataID || ''}
                              value={getRowDataID || ''}
                              className="input_bulk_edit"
                              onChange={onTermSelection}
                              checked={isChecked || ''}
                            />
                          ) : (
                            <input
                              type="checkbox"
                              name={'checkboxPayout'}
                              id={getRowDataID || ''}
                              value={getRowDataID || ''}
                              className="input_bulk_edit"
                              onChange={onTermSelection}
                              checked={isChecked || ''}
                            />
                          )}
                          <label htmlFor={getRowDataID} className={`label_bulk_edit`}>
                            <Icon iconType="TickIconWhite" />
                          </label>
                        </>
                      )}
                    <Tbody
                      className={`payout_item invoices_table_body ${
                        isTermEditEnabled && 'is_editable'
                      } ${isOldInvoices && 'is_old'}`}
                    >
                      <TbodyTr className="payout_item_tr invoices_table_tr">
                        {row.cells.map((cell, index) => {
                          let isUpdateResently = false;
                          // @ts-ignore
                          const isUpcomingTran = isTransactions && !cell?.row?.original?.paid_at;
                          // @ts-ignore
                          if (cell?.row?.original?.updated_at) {
                            // @ts-ignore
                            isUpdateResently = isTermUpdate(cell.row.original.updated_at);
                          }
                          return (
                            <TbodyTd
                              {...cell.getCellProps()}
                              className={handleTbodyColumnClassName(
                                index,
                                false,
                                isHandleArrowClickable,
                                isUpdateResently,
                                iconArrowVisible,
                                isUpcomingTran
                              )}
                            >
                              {cell.render('Cell', {
                                onClickTermId,
                                isHandleArrowClickable: isUpcomingTran
                                  ? false
                                  : isHandleArrowClickable,

                                isTermEditable,
                                isTermEditEnabled,
                                isOldInvoices,
                                tabType,
                                isTermClicked,
                                selectedPayoutId,
                                iconArrowVisible: iconArrowVisible,
                                routeState,
                              })}
                            </TbodyTd>
                          );
                        })}
                      </TbodyTr>
                    </Tbody>
                  </TbodyContainer>
                )
              );
            })
          ) : (
            <SearchResultEmpty
              content={searchInProgress ? 'Search result not found' : 'No results found'}
            />
          )}
        </TbodyWrapper>
      </TableWrapper>
    </>
  );
};
