import { createApi } from '@reduxjs/toolkit/query/react';
import { prepareBaseQuery } from '../../shared/config/config';

export const accountPreferenceApi = createApi({
  reducerPath: 'accountPreferenceApi',
  tagTypes: ['Preference'],
  baseQuery: prepareBaseQuery(false),
  endpoints: (builder) => ({
    updatePreference: builder.mutation({
      query: (data: any) => {
        return {
          url: '/account-preference',
          method: 'POST',
          body: JSON.stringify(data),
        };
      },
      invalidatesTags: ['Preference'],
    }),
    getPreference: builder.mutation({
      query: () => ({
        url: `/account-preference`,
        method: 'GET',
      }),
      invalidatesTags: ['Preference'],
    }),
  }),
});

export const { useUpdatePreferenceMutation, useGetPreferenceMutation } = accountPreferenceApi;
