import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Header,
  HeaderFixed,
  InformationList,
  Wrapper,
  WrpperAuthorizedContainer,
} from 'src/components/base/core';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { handleHelperSettings } from 'src/helpers/containers/helperHandleSettings';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';
import { routeType } from 'src/helpers/containers/helperRoutePreference';
import { getSettingsData } from 'src/helpers/helperSettings';
import { logout } from 'src/shared/utilities/logout';
import { Box } from '@mui/material';
import { UserIdentitify } from 'src/components/base/core/OtherUtilities/UserIdentitify/UserIdentitify';

export const SettingsContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [updatePreferenceOnServer] = handlePreferences();
  const [accountData, isLoading] = handleHelperSettings();

  useEffect(() => {
    setTimeout(function () {
      updatePreferenceOnServer(routeType.SETTINGS);
    }, 2000);
  }, []);

  const clickAction = (type: any) => {
    switch (type) {
      case 'LogOut':
        logout(navigate);
        break;
      case 'AccountInformation':
        navigate('/settings/account-info');
        break;
      case 'LinkedBankAccount':
        navigate('/settings/bank-setup');
        break;
      case 'Notifications':
        navigate('/settings/notifications');
        break;
      case 'Customers':
        navigate('/settings/customers-addition');
        break;
      case 'PayoutTerms':
        navigate('/settings/terms-selection');
        break;
      case 'DisablePayoutTerms':
        navigate('/settings/account-info');
        break;
      case 'Referrals':
        navigate('/settings/referrals');
        break;
      case 'Languages':
        navigate('/settings/languages');
        break;
    }
  };

  let settingsObj = getSettingsData(accountData, t);

  return (
    <>
      {isLoading && <ManualLoader />}
      {accountData && settingsObj && (
        <>
          <HeaderFixed className="fixed_only_desktop">
            <Header
              isMainMunuVisible
              isBackButtonHide
              paddingX={0}
              isLogoVisible={false}
              headerText={t('settings.HeaderTitle')}
              withCenteredHeaderText
            />
          </HeaderFixed>
          <Wrapper isAuthorizedWrapper>
            <WrpperAuthorizedContainer>
              <UserIdentitify />
              <Box>
                <InformationList onClick={clickAction} data={settingsObj} />
              </Box>
            </WrpperAuthorizedContainer>
          </Wrapper>
        </>
      )}
    </>
  );
};
