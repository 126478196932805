import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const PayModeComponent = styled('div')(
  ({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing(3)};
`
);

export const PayModeContainer = styled(Box)(
  ({ theme }) => `
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;

  &.active_card {
    border: 1px solid ${theme.palette.primary.main};
    pointer-events: none;
    margin-bottom: 0;
  }

  &.in_active_card {
    border: 1px solid ${theme.palette.gray[100]};
    cursor: pointer;
  }

  &.coming_soon_card {
    border: 1px solid ${theme.palette.gray[100]};
    cursor: pointer;
    opacity: 0.3;
  }
`
);

export const PayModeLeft = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const PayModeMiddle = styled(Box)(
  ({ theme }) => `
  background: ${theme.palette.white};
  padding-left: ${theme.spacing(1.5)};
  padding-right: ${theme.spacing(1.5)};
  border: 1px solid #F7F8FF;
  border-radius: 4px;
`
);

export const PayModeRight = styled('div')`
  display: flex;
  flex-direction: row;

  * {
    font-weight: 400;
  }
`;
