import { styled } from '@mui/material/styles';

export const TransactionTabNavWrapper = styled('div')(
  ({ theme }) => `
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: ${theme.spacing(4)};
`
);

export const TransactionTabNavItem = styled('div')(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  border-radius: 30px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.palette.gray[600]};
  border: 1px solid ${theme.palette.gray[200]};
  background-color: ${theme.palette.white};
  margin-right: ${theme.spacing(1)};
  cursor: pointer;

  &.filter {
    width: 76px;
    svg {
      margin-right: 4px;
      margin-top: -1px;
    }

    &:hover {
      border: 1px solid ${theme.palette.primary.main};
      background: transparent;
      color: ${theme.palette.primary.main};
    }
  }

  &.active {
    border: 1px solid ${theme.palette.primary.main};
    background: transparent;
    color: ${theme.palette.primary.main};
    font-weight: 600;
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    height: 30px;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    height: 24px;
  }
`
);
