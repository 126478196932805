import { Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import {
  HomeIdentityIcon,
  HomeLinkBankIcon,
} from 'src/components/base/core/Media/Icon/Icons/Icons';
import {
  CardArrow,
  IconWrapper,
  MessageContent,
  NotificationMessageWrapper,
} from './NotificationMessage.style';
import { MessagesProps } from 'src/models/component/base.model';

export const NotificationMessage = ({
  messageHeading,
  messageContent,
  messageLinkEvent,
  isDesktopView,
  topMargin = 6,
  bottomMargin = 6,
  type = 'error',
}: MessagesProps) => {
  return (
    <>
      <NotificationMessageWrapper
        className={`${isDesktopView ? 'is_desktop' : 'is_mobile'}`}
        sx={{ marginTop: topMargin, marginBottom: bottomMargin }}
        onClick={messageLinkEvent}
      >
        <IconWrapper>
          {type === 'warning' && <HomeIdentityIcon />}
          {type === 'neutral' && <HomeLinkBankIcon />}
        </IconWrapper>
        <MessageContent>
          {messageHeading && (
            <Typography variant="h2Bold" color={'gray.900'} sx={{ margin: 0 }}>
              {messageHeading}
            </Typography>
          )}
          {messageContent && (
            <Typography variant="h1" color={'gray.900'} sx={{ marginTop: 1, marginBottom: 0 }}>
              {messageContent}
            </Typography>
          )}
        </MessageContent>
        <CardArrow>
          <KeyboardArrowRightIcon color={'inherit'} />
        </CardArrow>
      </NotificationMessageWrapper>
    </>
  );
};
