import { styled } from '@mui/material/styles';

export const InvoiceItemRow = styled('div')(
  ({ theme }) => `
  display: flex;

  .invoice_content {
    display: flex;
    justify-content: space-between;

    &.accordion_unavailable {
      width: 100%;
    }
  
    &.accordion_available {
      width: calc(100% - 30px);
    }

    &_left,
    &_right {
      display: flex;
      flex-direction: column;
    }

    &_icon {
      width: 30px;
      justify-content: flex-end;
      display: flex;
      align-items: center;

      svg {
        transform: rotate(90deg);
      }

      &.active {
        svg {
          transform: rotate(-90deg);
        }  
      }
    }
  }

  .amount_value,
  .title_value {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #404145;
    margin-bottom: 4px;
  }

  .date_value {
    color: #7E8085;
    font-weight: 400;
    font-size: 12px;
  }
`
);

export const BottomRow = styled('div')(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;

  &.header {
   padding-top: ${theme.spacing(4)};
   padding-bottom: ${theme.spacing(2)};
   border-bottom: 1px solid ${theme.palette.gray[50]};
  }

  &.content {
   padding-top: ${theme.spacing(4)};
   padding-bottom: ${theme.spacing(3)};
  }

  &.total {
   padding-top: ${theme.spacing(3)};
   border-top: 1px solid ${theme.palette.gray[50]};
  }

  &.header,
  &.content,
  &.total {
   &_left {
    min-width: calc(100% - 100px);
    max-width: calc(100% - 100px);
    padding-right: 10px;
   }
  }

  &.header,
  &.content,
  &.total {
   &_right {
    min-width: 100px;
    max-width: 100px;
    padding-left: 10px;
   }
  }
`
);
