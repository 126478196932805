import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import {
  AccountVerificationStatusses,
  Messages,
  ReviewCard,
  ShadowCard,
} from 'src/components/base/core';
import { ManualLoader } from '../../../components/base/core/OtherUtilities/Loader/ManualLoader';
import { RefreshIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import { AccountInfoEditModal } from './AccountInfoEdit/AccountInfoEdit';

import {
  useGetAccountQuery,
  useUpdateAccountMutation,
} from '../../../services/account/accountServiceApi';

import { phoneNumberMask } from 'src/shared/utilities/phoneNumberMask';
import {
  AddressCityStateZip,
  RecipentAddressCityStateZip,
} from 'src/shared/utilities/addressUtils';
import { getFormattedDate } from 'src/shared/utilities/dob';

import { helperAccountInfo } from 'src/helpers/containers/helperAccountInfo';
import { checkIsAnyOfTheRecipentDetailsAreAddedOrNot } from 'src/helpers/helperHandlingTerms';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface AccountInfoIndividualProps {
  accountData: any;
  isCardBorderEnabled?: boolean;
  fromHome?: boolean;
}
export const AccountInfoIndividual = ({
  accountData,
  isCardBorderEnabled = false,
  fromHome = false,
}: AccountInfoIndividualProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { recipientDetailsFormEnabled } = useFlags();

  const { data, isFetching, isLoading } = useGetAccountQuery('');
  const [visibleUpdateForm, setVisibleUpdateForm] = useState(false);
  const [result, setResult]: any = useState();
  const [updateAccount, { isLoading: isUALoading }] = useUpdateAccountMutation();
  const [serverErrorsSSN, setServerErrorsSSN]: any = useState(null);
  const [states, setStates]: any = helperAccountInfo();

  const handleEditIcon = () => {
    setStates({ ...states, openModal: true });
  };

  useEffect(() => {
    if (data) {
      setResult(data);
    }
  }, [data]);

  useEffect(() => {
    if (fromHome) {
      setStates({ ...states, openModal: true });
    } else {
      setStates({ ...states, openModal: false });
    }
  }, [fromHome]);

  const verificationStatus = data?.individual?.verification?.status;
  const isChargesEnabled = data?.charges_enabled;

  const onFormSubmit = () => {
    if (!isChargesEnabled) {
      handleCancelEvent();
      navigate('/settings/hold-on');
    } else {
      handleCancelEvent();
    }
  };

  const handleModalClose = () => {
    setStates({ ...states, openModal: false });
  };

  const handleCancelEvent = () => {
    setStates({ ...states, openModal: false });
    setVisibleUpdateForm(false);
  };

  const handleBackButton = () => {
    setStates({ ...states, openModal: false });
    setVisibleUpdateForm(false);
  };

  const getFirstName = data?.individual?.first_name ?? '';
  const getLastName = data?.individual?.last_name ?? '';

  const verificationStatusVerified = result?.individual?.verification?.status === 'verified';
  const verificationStatusUnVerified = result?.individual?.verification?.status === 'unverified';

  const dateObject = data?.individual?.dob;
  const checkDOBNull =
    dateObject && dateObject?.day !== null && dateObject.month !== null && dateObject.year !== null;

  const isCommonErrorMessageBockVisible =
    verificationStatusUnVerified ||
    result?.errors?.length > 0 ||
    result?.currently_due_requirements?.length > 0;

  const isTosShownErrorBlockVisible =
    verificationStatusVerified &&
    result?.errors?.length === 0 &&
    result?.currently_due_requirements?.length === 0 &&
    !result?.tos_shown_and_accepted;

  const acceptTerms = async () => {
    const response = await updateAccount({
      tos_shown_and_accepted: true,
    });

    // @ts-ignore
    if (response && !response?.error) {
      onFormSubmit();
    }
  };

  const [isRecipentDetailsAreAvailable] = checkIsAnyOfTheRecipentDetailsAreAddedOrNot(data);

  const onClickEditRecipientInfo = () => {
    navigate('/recipient-details', { state: { isFromSettingRPI: true } });
  };

  return (
    <>
      {(isLoading || isUALoading) && <ManualLoader />}
      {data && (
        <>
          <ShadowCard
            display={'flex'}
            flexDirection={'column'}
            paddingLeft={isCardBorderEnabled ? 0 : 4}
            paddingRight={isCardBorderEnabled ? 0 : 4}
            paddingTop={isCardBorderEnabled ? 0 : 4}
            paddingBottom={isCardBorderEnabled ? 0 : 1}
            marginTop={isCardBorderEnabled ? 0 : 6}
            className={isCardBorderEnabled ? 'remove_all_shadow' : 'normal_shadow'}
          >
            <ReviewCard
              title={t('captions.personalInfo')}
              name={`${getFirstName} ${getLastName}`}
              editIconClickEvent={handleEditIcon}
              isShadowWrappedIcon={true}
              isInnerTitle={true}
              type={!isCardBorderEnabled && verificationStatus}
            >
              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {data?.individual.email ? `${data?.individual.email}` : null}
              </Typography>
              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {`•••••••••••••••`}
              </Typography>
              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {data?.individual.phone ? `${phoneNumberMask(data?.individual.phone)}` : null}
              </Typography>
              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {checkDOBNull
                  ? `${t('reviewCardtexts.bornOnText')} ${getFormattedDate(data?.individual?.dob)}`
                  : null}
              </Typography>
              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {data?.individual?.address?.line1 ?? ''}
              </Typography>
              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {data?.individual?.address?.line2 ?? ''}
              </Typography>
              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {AddressCityStateZip(data?.individual)}
              </Typography>
              {data?.individual?.ssn_last4_provided && (
                <Typography
                  variant="h1"
                  color={'gray.700'}
                  marginBottom={2}
                  className="ssn_provided_text"
                >
                  {t('reviewCardtexts.idProvidedLast4Text')}
                  {verificationStatus === 'verified' && (
                    <div className="ssn_provided_text_verified">
                      <AccountVerificationStatusses type="verified" />
                    </div>
                  )}
                  {verificationStatus !== 'verified' && (
                    <div
                      className="icon_wrapper"
                      style={{ cursor: 'pointer' }}
                      onClick={handleEditIcon}
                    >
                      <RefreshIcon />
                    </div>
                  )}
                </Typography>
              )}
            </ReviewCard>
          </ShadowCard>

          {isRecipentDetailsAreAvailable && recipientDetailsFormEnabled && (
            <ShadowCard
              display={'flex'}
              flexDirection={'column'}
              paddingLeft={isCardBorderEnabled ? 0 : 4}
              paddingRight={isCardBorderEnabled ? 0 : 4}
              paddingTop={isCardBorderEnabled ? 0 : 4}
              paddingBottom={isCardBorderEnabled ? 0 : 1}
              marginBottom={isCardBorderEnabled ? 0 : 4}
              className={isCardBorderEnabled ? 'remove_all_shadow' : 'normal_shadow'}
            >
              <ReviewCard
                title={t('captions.recipientInfo')}
                name={data?.stripe_account_metadata?.check_recipient_details_name ?? ''}
                isShadowWrappedIcon={true}
                isInnerTitle={true}
                editIconClickEvent={onClickEditRecipientInfo}
              >
                {data?.stripe_account_metadata?.check_recipient_details_address && (
                  <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                    {data?.stripe_account_metadata?.check_recipient_details_address ?? ''}
                  </Typography>
                )}
                {data?.stripe_account_metadata?.check_recipient_details_address_2 && (
                  <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                    {data?.stripe_account_metadata?.check_recipient_details_address_2 ?? ''}
                  </Typography>
                )}
                <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                  {RecipentAddressCityStateZip(data)}
                </Typography>
              </ReviewCard>
            </ShadowCard>
          )}
          <Box>
            {isCommonErrorMessageBockVisible && (
              <Messages
                topMargin={4}
                bottomMargin={2}
                messageHeading={t('errorMessages.reviewError.heading')}
                messageContent={t('errorMessages.reviewError.content')}
                messageLinkText={t('errorMessages.reviewError.linkText')}
                messageLinkEvent={handleEditIcon}
              />
            )}

            {isTosShownErrorBlockVisible && (
              <Messages
                topMargin={4}
                bottomMargin={2}
                messageHeading={'Account Restricted'}
                messageContent={'This account must accept the terms of service'}
                messageLinkText={'Accept'}
                messageLinkEvent={() => acceptTerms()}
              />
            )}
          </Box>
          {states.openModal && (
            <AccountInfoEditModal
              handleCancelEvent={handleCancelEvent}
              onFormSubmit={onFormSubmit}
              setResult={setResult}
              result={result}
              title={t('captions.personalInfo')}
              bType={'individual'}
              isPersonal={true}
              navigate={navigate}
              verificationStatus={verificationStatus}
            />
          )}
        </>
      )}
    </>
  );
};
