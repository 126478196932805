import React from 'react';
import { Accordion } from 'src/components/base/core/OtherUtilities/Accordions/Accordion/Accordion';
import { MinMaxRange } from './AmountFilter.style';
import { MultiRangeSlider } from 'src/components/base/core/Sliders/MultiRangeSlider/MultiRangeSlider';
import { useTranslation } from 'react-i18next';

export interface TransactionAmountFilterProps {
  setFilterAmountRange?: any;
  resetFilter?: boolean;
  minmumAmount?: any;
  maximumAmount?: any;
  selectedMinRange?: any;
  setSelectedMinRange?: any;
  isAmountFilterActive?: boolean;
}
export const AmountFilter = ({
  setFilterAmountRange,
  resetFilter,
  minmumAmount = 0,
  maximumAmount = 100,
  selectedMinRange,
  setSelectedMinRange,
  isAmountFilterActive,
}: TransactionAmountFilterProps) => {
  const { t } = useTranslation();
  const minValue = minmumAmount > 0 ? minmumAmount : 0;
  const maxValue = maximumAmount > 0 ? maximumAmount : 100;

  return (
    <Accordion title={t('earning.filter.amountFilter.title')} isFilterActive={isAmountFilterActive}>
      <MinMaxRange>
        {t('earning.filter.amountFilter.from')} ${minValue / 100}{' '}
        {t('earning.filter.amountFilter.to')} ${maxValue / 100}
      </MinMaxRange>
      <MultiRangeSlider
        min={minValue}
        max={maxValue}
        selectedMinRange={selectedMinRange}
        setSelectedMinRange={setSelectedMinRange}
        setFilterAmountRange={setFilterAmountRange}
        resetFilter={resetFilter}
        isCentsEnabled={true}
      />
    </Accordion>
  );
};
