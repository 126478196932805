import { createApi } from '@reduxjs/toolkit/query/react';
import { prepareBaseQuery } from '../../shared/config/config';

export const supportServiceApi = createApi({
  reducerPath: 'supportServiceApi',
  tagTypes: ['faq'],
  baseQuery: prepareBaseQuery(false),
  endpoints: (builder) => ({
    contactSupport: builder.mutation({
      query: (question: string) => {
        return {
          url: '/support',
          method: 'POST',
          body: {
            question: question,
          },
        };
      },
    }),
    getAllFaqs: builder.query({
      query: () => ({
        url: `/support/faq`,
      }),
      providesTags: ['faq'],
    }),
  }),
});

export const { useContactSupportMutation, useGetAllFaqsQuery } = supportServiceApi;
