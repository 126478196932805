export function getEnvFromLocation() {
  switch (window.location.hostname) {
    case 'app.dev.payup.com':
      return 'dev';
    case 'app.qa.payup.com':
      return 'qa';
    case 'app.payup.com':
      return 'prod';
    default:
      return 'other';
  }
}
