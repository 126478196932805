import { localeType } from './locale.type';
import { esBatchesTranslation } from './sections/batches/es';
import { esFinanceTranslation } from './sections/finance/es';

export const es: localeType = {
  formInput: {
    firstName: 'Nombre',
    lastName: 'Apellido',
    comapanyName: 'Nombre de la empresa',
    companyContactName: 'Nombre de contacto de la empresa',
    email: 'Correo electrónico',
    phone: 'Número de teléfono',
    phone2: 'Número de teléfono 2',
    ssn: 'Número de Seguro Social',
    ssnInfo: 'Ingrese los últimos 4 dígitos de su SSN',
    ssnLast4: 'Número de Seguro Social',
    ssnReplaceButtonText: 'SSN proporcionado',
    ssnNotProvidedText: 'SSN no proporcionado',
    ssnNotVerifiedText: 'SSN no verificado',
    dob: 'Fecha de nacimiento (mm/dd/aaaa) ',
    addressLine1: 'Dirección',
    addressLine1HiglightText:
      'Su dirección debe ser una dirección física válida y no puede ser un P.O. Caja. Esta dirección no se comparte públicamente y puede ser su dirección personal.',
    addressLine2: 'Línea de dirección 2',
    city: 'Ciudad',
    state: 'Estado',
    zipCode: 'Código postal',
    accountName: 'Nombre completo',
    accountNumber: 'Cuenta #',
    confirmAccountNumber: 'Confirmar cuenta #',
    routingNumber: 'Enrutamiento #',
    ein: 'Número de identificación del empleador (EIN)',
    einReplaceButtonText: 'Identificación fiscal proporcionada',
    jobTitle: 'Título',
    sameBillingAddressCheckbox: 'Igual que la dirección de la empresa',
    ownerLabel: 'Dueño',
    executiveLabel: 'Ejecutivo',
    executiveConfirmInput: 'Confirmo que poseo más del 25% de la empresa',
    required: 'obligatorio',
    dobDefault: '01/31/2000',
    businessName: 'Nombre comercial (si está registrado como una organización)',
    fullName: 'Nombre completo',
    password: 'Contraseña',
    changePasswordText: 'Cambiar la contraseña',
    accountType: {
      label: 'Tipo de cuenta',
      option1: 'Comprobación',
      option2: 'Ahorros',
    },
    businessType: {
      label: 'Tipo de negocio',
      option1: 'Individual',
      option2: 'Organización',
    },
    amountRequired: 'Cantidad requerida)',
    addNote: 'Añadir la nota',
    nickName: 'Apodo',
    recipientName: 'Nombre de la destinataria',
    recipientAddressFieldNote: 'Utilice la dirección donde recibe el correo postal',
    recipientNameFieldNote: 'Puede ser un individuo u organización.',
    sameHomeAddressCheckbox: 'La misma que la dirección de casa',
    taxId: 'Identificación fiscal (EIN)',
  },
  buttonTexts: {
    loginButtonText: 'Inicia sesión',
    signupButtonText: 'Crear cuenta gratuita ',
    buttonTextSignup: 'Únete',
    buttonTextSignIn: 'Inicia sesión',
    continueButton: 'Continuar',
    addButton: 'Agregar',
    inviteUserButton: 'Invitar a uno más',
    doneButton: 'Hecho',
    cancelButton: 'Cancelar',
    updateButton: 'Actualizar',
    submitButton: 'Enviar',
    confirmButton: 'Confirmar',
    nextButton: 'Siguiente',
    followNextStepButton: 'Siguiente',
    confirmTermsButton: 'Confirmar los términos de pago ',
    saveContinueButton: 'Guardar y continuar ',
    continuePayupButton: 'Siguiente',
    enableNotificationButton: 'Habilitar notificaciones',
    ShareLinkText: 'Compartir enlace a un amigo',
    continueWithIdButton: 'Continuar con ID',
    continueWithEINDocButton: 'Continuar con documentos comerciales',
    reviwDataButton: 'Revisar datos',
    goBackButton: 'Regresa',
    acceptButton: 'Aceptar',
  },
  linkTexts: {
    sendAgainLink: 'Enviar de nuevo',
    skipLink: 'Saltarse',
    individual: {
      ssnRedirectLink:
        '¿No tienes un SSN? Regístrese como empresa con su EIN y un documento de identidad.',
    },
    business: {
      ssnRedirectLink:
        'Si utiliza su SSN para fines de impuestos comerciales, regístrese como individuo.',
      provideIDDocLink: '¿No tienes un SSN? Haga clic aquí para cargar su documento de identidad.',
      providedIDDoc: 'Documento proporcionado',
    },
    addMoreNumberLink: 'Añadir un número más',
  },
  statusTexts: {
    pending: 'Pendiente',
    verified: 'Verificado',
    unverified: 'Sin verificar',
    restricted: 'Restringido',
  },
  noticeBlocks: {
    customerAddition: {
      heading: '¿No encuentras a tu cliente? Invítalos aquí',
      content: 'Obtén $50 si el cliente al que invitas se une a PayUp.',
    },
    termsBlock: {
      heading: 'Su información está segura y encriptada',
      content: 'PayUp utiliza medidas de seguridad de última generación.',
    },
    bankdetailSelection: {
      heading: 'Si su banco no está en la lista, contáctenos para obtener asistencia:',
      emailPrefix: 'Correo electrónico',
      callPrefix: 'o llámenos al',
    },
    notificationModal: {
      content:
        'Estos mensajes son obligatorios. PayUp te enviará notificaciones importantes sobre tus pagos.',
    },
  },
  faqBlocks: {
    ownPayoutTerms: {
      heading: 'Sus propias condiciones de pago',
      content:
        'Seleccione sus condiciones de pago preferidas para pagos futuros. Podría ser de 7, 14, 21 o 28 días. ',
    },
    fairFree: {
      heading: 'Tarifa justa',
      content:
        'Cobramos una tarifa razonable por pagos anticipados. Aún puede elegir la opción "Sin pago anticipado" y recibir el pago cuando su cliente pague.',
    },
    flexibleSetup: {
      heading: 'Configuración flexible',
      content: 'Según sus necesidades, puede elegir diferentes términos de pago para cada factura.',
    },
    ownPayoutTermsNew: {
      heading: 'PayUp paga por defecto en Net30+',
      content:
        'Net 30+ se refiere al pago en 30 días o más desde el envío de la Factura. El cronograma aquí depende de la propiedad en función de cuándo la propiedad realiza el pago.',
    },
    fairFeeNew: {
      heading: 'Tarifa justa',
      content: 'Cobramos una tarifa razonable por los pagos según el modo elegido.',
    },
    flexibleSetupNew: {
      heading: 'Configuración flexible',
      content:
        'Según sus necesidades, puede elegir diferentes métodos de pago para cada factura o recibir el pago con el método predeterminado que seleccione.',
    },
    private: {
      heading: 'Privado',
      content: 'No compartiremos sus datos con nadie.',
    },
    secure: {
      heading: 'Seguro',
      content: 'Todos sus datos estarán seguros y se utilizarán solo con su permiso.',
    },
    easyConnection: {
      heading: 'Fácil conexión',
      content: 'Agregue o cambie los detalles de su cuenta bancaria en cualquier momento.',
    },
  },
  captions: {
    personalInfo: 'INFORMACIÓN PERSONAL',
    businessDetails: 'DETALLES DEL NEGOCIO',
    personalDetails: 'DATOS PERSONALES',
    invitedText: 'INVITADO',
    submittedText: 'PRESENTADO',
    termDetails: 'INFORMACIÓN DEL TÉRMINO',
    bankInfotext: 'DETALLES DEL BANCO',
    editDetails: 'Editar detalles',
    recipientInfo: 'CONSULTAR DATOS DEL DESTINATARIO',
  },
  reviewCardtexts: {
    taxIdProvided: 'Número de identificación fiscal proporcionado',
    taxIdNotProvided: 'Proporcione una identificación fiscal válida',
    idProvidedText: 'Documento EIN proporcionado',
    idProvidedLast4Text: 'SSN proporcionado',
    idDocProvidedText: 'Documento proporcionado',
    bornOnText: 'Nacido en',
    noBankDetails: 'No se agregó ninguna cuenta bancaria',
  },
  headingContent: {
    verifyPhone: {
      heading: 'Comencemos',
      content: 'Usa tu número de teléfono para registrarte',
    },
    verifyCode: {
      heading: 'Verifica tu número',
      content: 'Por favor ingrese el código de verificación enviado al',
    },
    unVerifiedEmail: {
      heading: 'Verificar correo electrónico',
      content:
        'Busque en su bandeja de entrada un correo de PayUp y haga clic en el enlace para verificar su correo electrónico.',
      additionalContent: '¿No recibiste un correo electrónico? Revisa tu correo no deseado',
    },
    verifiedEmail: {
      headingEmailVerified: 'Correo Electrónico Verificado',
      headingLoginCompletion: 'Inicio de sesión completo',
      headingAccessDenied: 'Acceso denegado',
      content:
        'Su dirección de correo electrónico fue verificada con éxito. Continúe configurando su cuenta.',
    },
    verifyCodeSuccess: {
      loginComplete: 'Inicio de sesión completo',
    },
    verifyCodeFailiure: {
      loginInComplete: 'Inicio de sesión incompleto',
    },
    customerSelection: {
      heading: 'Elige a tus clientes',
      content:
        'Seleccione los clientes con los que trabaja para que podamos vincular su cuenta payup a ellos.',
    },
    addCustomerModal: {
      heading: 'Añade tu cliente',
      content: 'Invite a un cliente a ofrecerle pagos anticipados. ¡Obtén $50 si se unen a PayUp!',
    },
    updateCustomerModal: {
      heading: 'Actualiza a tu cliente',
    },
    choiceScreen: {
      heading: '¿Cómo te gustaría inscribirte?',
    },
    individual: {
      personalInfo: {
        heading: 'Regístrate como individuo',
        content: 'Introduce o confirma los datos de tu perfil.',
      },
      ssn: {
        heading: 'Verificar identidad',
        content:
          'Sin verificaciones de crédito. Los últimos 4 dígitos de su SSN solo se utilizan para verificar su identidad.',
      },
      dob: {
        heading: 'Verificar identidad',
        content: 'Ingrese su fecha de nacimiento para verificar que tiene más de 18 años',
      },
      address: {
        heading: 'Verificar identidad',
        content: 'Introduce tu dirección.',
      },
      review: {
        heading: 'Repasemos',
        content:
          'Por favor, verifique la información que proporcionó para asegurarse de que sea precisa.',
      },
    },
    business: {
      businessInfo: {
        heading: 'Regístrate como empresa',
        content: 'Cuéntanos sobre tu negocio.',
      },
      businessAddress: {
        heading: 'Regístrate como empresa',
        content:
          'Ingrese su dirección comercial registrada. Puede usar la dirección de su casa si no tiene una dirección comercial.',
      },
      ownerInfo: {
        heading: 'Verificar la propiedad',
        content:
          'Verifique que represente a esta empresa como propietario o ejecutivo. De lo contrario, asegúrese de pedirle a la persona adecuada que continúe.',
      },
      ownerAddress: {
        heading: 'Verificar la propiedad',
        content: 'Introduce la dirección de tu casa.',
      },
      ssn: {
        heading: 'Verificar la propiedad',
        content:
          'Sin verificaciones de crédito. Los últimos 4 dígitos de su SSN solo se utilizan para verificar su identidad.',
      },
      review: {
        heading: 'Repasemos',
        content:
          'Por favor, verifique la información que proporcionó para asegurarse de que sea precisa.',
      },
    },
    statusses: {
      verificationFailed: {
        heading: 'Error de verificación',
        content:
          'No podemos verificar su SSN. Proporcione una identificación válida para continuar registrándose. No se preocupe, todos sus datos se almacenarán de forma segura.',
      },
      ssnVerificationFailed: {
        heading: 'Error de verificación',
        content: 'No pudimos verificar su SSN. Ingrese su SSN completo de nueve dígitos aquí.',
      },
      einVerificationFailed: {
        heading: 'Error de verificación',
        content:
          ' No pudimos verificar su Número de Identificación del Empleador (EIN). Para completar la verificación, proporcione un documento comercial en su lugar.',
      },
      einDocUploadDone: {
        heading: 'Documento cargado',
        content:
          'Gracias por proporcionarnos los documentos requeridos. Enviar para finalizar la verificación.',
      },
      idDocUploadDone: {
        heading: 'Documento cargado',
        content:
          'Gracias por proporcionarnos los documentos requeridos. Enviar para finalizar la verificación.',
      },
      onHold: {
        heading: 'Manténgase firme',
        content:
          'Estamos verificando tu información. Por favor, mantenga esta ventana abierta mientras espera. Puede tardar hasta 1 minuto.',
      },
      onHoldBeforeSuccess: {
        heading: '¡Casi allí!',
        content:
          'Estamos habilitando pagos en su cuenta. Esto puede tardar hasta 1 minuto. Por favor, mantenga esta ventana abierta mientras espera.',
      },
      approved: {
        heading: '¡Aprobado!',
        content:
          '¡Felicitaciones, su verificación fue exitosa! Solo quedan unos pocos pasos más para configurar su cuenta.',
      },
      bankSetupSuccess: {
        heading: '¡Éxito!',
        contentForManualSetup: 'Enhorabuena, su cuenta bancaria se ha configurado correctamente.',
        contentForFinConnection:
          'Enhorabuena, su cuenta bancaria se ha configurado correctamente. ¡Establezca un apodo a continuación para una identificación más fácil!',
      },
      allJobDone: {
        headingLine1: '¡Gran trabajo!',
        headingLine2: 'You’re all set to receive',
        headingLine3: 'payments in PayUp',
      },
      weFoundVendor: {
        heading: '¡Encontramos una coincidencia!',
        content1: 'Pudimos encontrar una coincidencia en {{vendor}} bajo {{customer}}.',
        content2: 'Establezca una contraseña para su cuenta en la siguiente pantalla.',
      },
      weNotFoundVendor: {
        heading: 'Uh-oh, no pudimos localizar tu perfil',
        content1:
          'Lo sentimos, no pudimos encontrar un perfil de proveedor en {{customer}} con la dirección de correo electrónico, el nombre o el número de teléfono proporcionados.',
        content2:
          '¿Tiene contacto alternativo? Comparta el correo electrónico, el nombre y el número que utilizó para registrarse con {{customer}}.',
      },
      accountALreadyAvailable: {
        heading: 'Ya estás en PayUp',
        content1: 'Encontramos un perfil ya registrado en PayUp con el {{numberOrEmail}}.',
        content2:
          'Inicie sesión en su perfil o regrese para crear uno nuevo con un número o correo electrónico diferente.',
      },
      payModeSelectionSuccess: {
        heading: '¡Éxito!',
        content:
          'Está configurado para recibir pagos a través de cheques. Para cambiar a pagos más rápidos, simplemente vincule su cuenta bancaria en la configuración.',
      },
    },
    commonSteps: {
      earlyPayouts: {
        heading: '¿Qué es el pago anticipado?',
        headingNew: '¿Cómo paga PayUp?',
      },
      termsSelection: {
        heading: 'Elija los términos de pago ',
        content:
          'Seleccione sus condiciones de pago preferidas para pagos futuros. Siempre puedes cambiar los términos de pago predeterminados en Configuración. ',
      },
      payModeSelection: {
        heading: 'Elija el modo de pago',
        content:
          'Seleccione su modo preferido para los pagos. Siempre puedes cambiar tu modo de pago predeterminado en Configuración.',
        comingSoon: 'Muy pronto',
        default: 'Por defecto',
      },
      bankSelection: {
        heading: 'Datos bancarios',
        content:
          'Seleccione cómo vincular su cuenta bancaria. Puede cambiar los datos bancarios más adelante si es necesario.',
      },
      bankInfo: {
        heading: 'Mantenemos la conexión segura y protegida',
      },
      bankDetails: {
        heading: 'Datos bancarios',
        content:
          'Proporcione la cuenta y el número de ruta del banco donde desea recibir los pagos.',
      },
      notification: {
        heading: 'Manténgase informado',
        content: 'Recibe notificaciones de pagos, aprobaciones y otras actualizaciones.',
      },
      notificationModal: {
        heading: 'Configuración de notificaciones',
        content: 'Configura tus preferencias de notificación.',
      },
      referFriend: {
        heading: 'Recomiende a un amigo y gane',
        content: '¡Recibe un pago de $10 por cada amigo que se una a tu invitación! ',
      },
      searchVendor: {
        heading: 'Empecemos',
        content:
          'Utilice el correo electrónico y el contacto que utilizó para registrarse con sus clientes comerciales.',
      },
      recipientDetails: {
        heading: 'Comprobar la información del destinatario',
        content: 'Confirme los siguientes detalles para enviar el cheque a la persona correcta',
      },
    },
  },
  errorMessages: {
    verifyCodeError: {
      heading: 'Error de verificación',
    },
    dobError: {
      heading: 'Parece que tu edad es menor de 18 años',
      content:
        'Debes tener al menos 18 años para usar PayUp. Por favor, asegúrese de introducir su fecha de nacimiento correctamente.',
    },
    reviewError: {
      heading: 'Verificación incompleta',
      content:
        'Revise su información, como nombre, apellido, dirección, fecha de nacimiento, EIN o SSN.',
      linkText: 'Verificar ahora',
    },
    phoneUniqueError: {
      heading: 'El número de teléfono debe ser único',
      content: 'Debes usar un número de teléfono único para usar PayUp.',
    },
    emailUniqueError: {
      heading: 'El ID de correo electrónico debe ser único',
      content: 'Debe usar una identificación de correo electrónico única para usar PayUp.',
    },
    ownershipError: {
      heading: 'Error de selección de propiedad',
      content1: 'Establezca el tipo de propiedad',
      content2: 'Confirme que posee más del 25 por ciento de la empresa.',
    },
    invalidAddressError: {
      heading: 'Dirección inválida',
    },
  },
  idDocument: {
    step1: {
      heading: 'Enviar documento',
      content: 'Seleccione uno de los siguientes tipos de documentos.',
    },
    step2: {
      heading: 'Enviar documento',
      content: 'Seleccione una opción.',
      uploadButtonText: 'Cargar documento',
      photoButtonText: 'Toma fotos',
    },
    step3: {
      heading: 'Enviar documento',
      content: 'Por favor, cargue el documento de identidad seleccionado.',
      heading2: 'Toma una foto',
      content2:
        'Al tomar una foto de sus documentos, asegúrese de que su nombre completo sea visible.',
      modalTerm:
        'Asegúrese de que el documento que está a punto de cargar cumpla con los requisitos a continuación. Confirme comprobando:',
      firstModalCheckBox: 'El documento cargado está en color.',
      secondModalCheckBox: 'Este documento muestra mi nombre legal.',
      photoWord: 'Foto',
      frontWord: 'Frente',
      backWord: 'Atrás',
      replaceWord: 'Reemplazar',
      takeWord: 'Tomar',
      uploadWord: 'Subir',
    },
  },
  einModal: {
    heading: 'Enviar documento',
    content: 'Seleccione uno de los siguientes tipos de documentos para cargar. ',
    confirmationLetterButton: 'Carta de confirmación SS-4 del IRS',
    irsLetterButton: 'Carta 147C del IRS',
    replaceWord: 'Reemplazar',
  },
  landing: {
    slide1: {
      heading: 'Obtén pagos anticipados',
      content:
        'Comience a usar PayUp para que le paguen por el trabajo más rápido y en sus propios términos',
    },
    slide1New: {
      heading: 'Obtenga pagos rápidos',
    },
    slide2: {
      heading: 'Seguimiento de sus facturas',
      content: 'PayUp le permite crear, rastrear y administrar todas sus facturas en un solo lugar',
    },
    slide3: {
      heading: 'Regístrese simplemente',
      content:
        'Cree una cuenta gratuita en menos de 2 minutos, sin verificaciones de crédito ni solicitudes largas',
    },
    slide4: {
      heading: 'Cuenta financiera PayUp',
      content:
        'Reciba el pago al instante tras la aprobación y tenga acceso inmediato a sus fondos',
    },
    slide5: {
      heading: 'No más deuda',
      content: 'Dile adiós a pagar los préstamos de día que te ahogan en deudas',
    },
  },
  verifyCode: {
    notRecivedText: '¿No recibiste un mensaje de texto?',
  },
  customerAddition: {
    inviteUserModal: {
      companiesAddedText: 'Empresas añadidas',
      companyText: 'EMPRESA',
    },
    confirmModal: {
      heading: 'Cliente invitado',
      content:
        '¡Gracias! Hemos invitado a tu cliente a unirse a PayUp. Cuando se unan, obtendrás {{payment}} en tu cuenta.',
    },
    multiCustomerConfirmModal: {
      heading: '¡Cliente enviado!',
    },
    updateConfirmationModal: {
      heading: 'Cliente actualizado',
    },
  },
  choice: {
    business: {
      heading: 'Negocio',
      info1: 'Verificación empresarial con EIN',
      info2: 'Verificación del propietario con SSN o ID',
      info3: 'Presentar impuestos como propietario único, sociedad, corporación, S corp o LLC',
    },
    individual: {
      heading: 'Individuo',
      info1: 'Verificación de identidad con SSN',
      info2: 'Presente impuestos como W2, 1099 o 1040',
    },
    signOut: 'Desconectar',
  },
  termsSelection: {
    default: 'Defecto',
    termCardDays: 'Pago de días',
    termCardFee: 'Honorario',
    termNoEalryPayoutHeading: 'Sin pago anticipado',
    termNoEalryPayoutContent: 'Est. {{Fee}} días',
    timeline: {
      daysText: 'Días',
      invoiceSubmitted: 'Factura enviada',
      invoiceApproved: 'Factura aprobada (Est)',
      youGetPaid: 'Te pagan',
      customerPays: 'País del cliente (Este)',
    },
    payModeNote:
      'Todos los pagos se realizan solo después de la aprobación. Los tiempos de pago son desde el momento de la liberación de fondos del cliente. Los días reflejan los días hábiles.',
  },
  bankDetails: {
    step1: {
      connectAPIButton: 'Conéctese a través del inicio de sesión del banco',
      mannualSetupButton: 'Ingrese los detalles de la cuenta manualmente',
    },
    success: {
      inputLabel: 'Cuenta',
    },
  },
  notifications: {
    updateSuccess: '¡Actualizado con éxito!',
    modal: {
      subHeading1: '¿Qué notificaciones recibes?',
      subHeading2: '¿Dónde recibes notificaciones?',
      emailText: 'Correo electrónico',
      textMessageText: 'Mensaje de texto',
      notificationPrefs: {
        DEFAULT_PAYMENT_TERMS_UPDATED: 'Se han cambiado los términos de pago predeterminados',
        INVOICE_PAYMENT_TERMS_UPDATED: 'Cambios en los términos de pago de facturas',
        CUSTOMER_PAYOUT_APPROVED: 'Factura aprobada',
        INVOICE_DISPUTED: 'Factura disputada',
        PAYOUT_COMPLETED: 'Factura pagada',
        CUSTOMER_PAYOUT_INVOICED: 'Factura enviada',
        READERS_DIGEST: 'Resumen de información de PayUp',
        weekly: 'Semanal',
        daily: 'Diariamente',
      },
    },
  },
  menu: {
    invoices: 'Facturas',
    settings: 'Ajustes',
    finances: 'Finanzas',
    dashboard: 'Hogar',
    notification: 'Notificación',
    referFriend: 'Recomendar amigas',
    transactions: 'Actas',
    payments: 'Ganancias',
  },
  earning: {
    firstTabHeading: 'Presentada',
    secondTabHeading: 'Aprobado',
    thirdTabHeading: 'Pagado',
    messageHeader: 'Ciertas facturas tienen los términos netos cambiados',
    messageContent:
      'Algunas facturas se actualizaron automáticamente a los términos "Sin pagos anticipados" porque se enviaron sobre propiedad morosa.',
    headerText: 'Facturas',
    searchText: 'Buscar factura por número de factura',
    termsEnableClose: 'Cerca',
    termsEnableEdit: 'Editar',
    tranSummaryText: 'Resumen de Transacciones',
    paymentSummaryText: 'Resumen de pago',
    invoiceSummaryText: 'Resumen de factura',
    submitted: {
      accordionHeadingCurrent: 'Facturas Actuales',
      tooltipContentCurrent: 'Estas son facturas nuevas que creó después de registrarse en PayUp.',
      accordionHeadingExisting: 'Facturas existentes',
      tooltipContentExisting:
        'Estas son facturas antiguas que fueron creadas antes de registrarse en PayUp. Extraemos las facturas impagas para ayudarlo a que le paguen más rápido a mitad de precio.',
      firstColTitle: 'ESTIMADA',
      secondColTitle: 'TOTAL PRESENTADA',
    },
    download: {
      sendBtn: 'Enviar',
      title: 'Exportar Facturas',
      selectPeriod: 'Seleccionar periodo',
      noInvoicesAvailableTitle: 'No hay facturas para este período',
      noInvoicesAvailableError: 'No hay facturas dentro del período que seleccionó.',
      whereToSent: '¿A dónde le gustaría que le enviaran su CSV?',
      today: 'Este Dia',
      ytd: 'el año pasado',
      custom: 'Disfraz',
    },
    filter: {
      applyBtn: 'Aplicar',
      title: 'Filtrar y Ordenar',
      resetAll: 'Resetear todo',
      amountFilter: {
        title: 'Monto',
        from: 'De',
        to: 'a',
      },
      dateFilter: {
        title: 'Fecha',
        last7: 'Los últimos 7 días',
        last30: 'Últimos 30 días',
        last12m: 'últimos 12 meses',
        lastYear: 'El año hasta la fecha',
        last90: 'últimos 90 días',
        all: 'Todos',
        custom: 'Período personalizado',
        ok: 'Ok',
        done: 'Hecho',
        cancel: 'Cancelar',
      },
      termFilter: {
        title: 'Condiciones de pago',
        all: 'Seleccionar todo',
      },
      sortFilter: {
        title: 'Ordenar por ',
        date: 'Fecha',
        amount: 'Monto',
      },
    },
    tableColumns: {
      invoice: 'FACTURA',
      date: 'FECHA',
      amount: 'MONTO',
      terms: 'LOTE #',
      mode: 'MODO',
      batch: 'BATCH',
    },
  },
  earningEmptyState: {
    commonTabHeading: 'No hay facturas todavía',
    commonTabContent: 'Parece que no tiene ninguna factura para ver',
    firstTabHeading: 'No hay facturas todavía',
    firstTabContent: 'Sus facturas enviadas aparecerán aquí',
    secondTabHeading: 'No hay facturas todavía',
    secondTabContent: 'Tus facturas aprobadas aparecerán aquí',
    thirdTabHeading: 'No hay facturas todavía',
    thirdTabContent: 'Sus facturas pagadas aparecerán aquí',
    paidTabComingSoonHeading: 'Muy pronto',
    paidTabComingSoonContent1:
      'Trabajamos arduamente para implementar la conciliación de facturas. Actualmente, procesamos los pagos en los plazos de la propiedad. Una vez que la conciliación de facturas esté disponible, todas sus facturas pagadas comenzarán a aparecer aquí.',
    paidTabComingSoonContent2:
      'Sin embargo, no se preocupe, ¡mientras tanto, seguirá recibiendo todos los pagos!',
  },
  accountInfo: {
    heading: 'Informacion de cuenta',
    buttonText: 'De vuelta a casa',
    needHelpText: '¿Necesitas ayuda? ¡Pregúntenos cualquier cosa!',
    supportEmail: 'support@payup.com',
    logoutLink: 'Cerrar sesión',
    languageSwitch: 'Elige lengua',
  },
  placeHolder: {
    firstName: 'Primer nombre',
    lastName: 'Apellido',
    emailAddress: 'Dirección de correo electrónico',
    comapnyName: 'Squeaky Cleaners LLC',
    firstLineAddress: 'Dirección Línea 1',
    secondLineAddress: 'Línea de dirección 2',
    city: 'Ciudad',
    state: 'Estado',
    zipCode: 'Código postal',
    accountName: 'Nombre del titular de la cuenta',
    jobTitle: 'Título',
  },
  notificationPrefs: {
    DEFAULT_PAYMENT_TERMS_UPDATED: 'Se han cambiado los términos de pago predeterminados',
    INVOICE_PAYMENT_TERMS_UPDATED: 'Cambios en los términos de pago de facturas',
    CUSTOMER_PAYOUT_APPROVED: 'Factura aprobada',
    INVOICE_DISPUTED: 'Factura disputada',
    PAYOUT_COMPLETED: 'Factura pagada',
    CUSTOMER_PAYOUT_INVOICED: 'Factura enviada',
    READERS_DIGEST: 'Resumen de información de PayUp',
    weekly: 'Semanal',
    daily: 'Diariamente',
  },
  home: {
    viewAll: 'Ver todo',
    linkBank: {
      title: 'Cuentas bancarias vinculadas',
      linkDefaultButton: 'Defecto',
      bankName: 'Golden Bank',
      accountNumberText: 'Cuenta    • • • • • ',
      addMoreText: 'Vincular otra cuenta',
    },
  },
  settings: {
    HeaderTitle: 'Ajustes',
    AccountInformation: {
      title: 'Información de la cuenta',
      buttonTextVerified: 'Verificada',
      buttonTextPending: 'Pendiente',
      buttonTextRestricted: 'Restringida',
      buttonTextUnverified: 'Inconfirmado',
    },
    ChnagePasswordConfirmation: {
      title: 'Enlace enviado',
      content:
        'Busque en su bandeja de entrada un correo de PayUp y haga clic en el enlace para cambiar la contraseña.',
    },
    PayoutTerms: {
      title: 'Condiciones de pago',
      buttonText: 'Neta 7',
    },
    PayoutMode: {
      title: 'Modo de pago',
    },
    LinkedBankAccount: {
      title: 'Cuentas bancarias vinculadas',
      buttonText: 'Añadir cuenta',
      remove: 'Remover',
      default: 'Hacer por defecto',
    },
    Notifications: {
      title: 'Notificaciones',
      buttonText: '',
    },
    Customers: {
      title: 'Clientes',
      buttonText: '',
    },
    Languages: {
      title: 'Idiomas',
      buttonText: 'Español',
    },
    LogOut: {
      title: 'Cerrar sesión',
      buttonText: 'Verificada',
    },
    Referrals: {
      title: 'Referencias',
      buttonText: '',
    },
  },
  settingsReferals: {
    heading: 'Invite a sus amigos y obtenga $10 cuando presenten su primera factura',
    content: `Gana hasta $100 invitando a tus amigos a usar PayUp. Por cada amigo que se una, recibirá $10 en su cuenta bancaria vinculada cuando envíe su primera factura en PayUp.`,
    headerHeading: 'Referencias',
    progressTitle: 'Tu recompensa',
    stepsContent: 'Amigas unidas',
    progressValue: 'Ganada',
    overallProgressValue: 'Disponible',
  },
  settingsCustomers: {
    heading: 'Invite a los clientes y obtenga $50 cuando se registren con nosotros',
    content: `Por cada cliente que invites, recibirás $50 cuando se unan. ¡Puedes obtener hasta $250!`,
    subHeading1: 'Clientas activas',
    subHeading2: 'Clientes invitadas',
    progressTitle: 'Tu recompensa',
    stepsContent: 'Cliente unida',
    progressValue: 'Ganada',
    overallProgressValue: 'Disponible',
    confirmButton: 'Invitar a nueva cliente',
  },
  finance: esFinanceTranslation,
  transactions: {
    searchTransByProperty: 'Buscar transacciones por propiedad',
    searchTrans: 'Buscar transacciones',
    searchPaymentsByProperty: 'Buscar ganancias por propiedad',
    searchPayments: 'Buscar ganancias',
    payouts: 'pagos',
  },
  actionCards: {
    inviteCustomer: {
      heading: 'Invite a los clientes, obtenga $ 50 cuando se unan',
      content: 'Invitar Clientes',
    },
    inviteFriend: {
      heading: 'Invite a un amigo y obtenga $10 cuando envíe su primera factura',
      content: 'Invitar a amigos',
    },
    enableNotifications: {
      heading: 'Notificación de configuración, mantente informada',
      content: 'Establecer notificaciones',
    },
  },
  inVoiceCards: {
    total: 'TOTAL',
    saved: 'SALVADO',
    onTheWay: 'EN CAMINO',
    nextPayout: 'PRÓXIMO PAGO',
    approvedInvoices: 'Facturas Aprobadas',
    approvedHeading: 'FACTURAS APROBADAS',
    paidHeading: 'FACTURAS PAGADAS',
    submittedHeading: 'FACTURAS ENVIADAS',
    transHeading: 'ACTAS',
    paymentsHeading: 'GANANCIAS',
    paidOut: 'PAGADO',
    upcoming: 'PRÓXIMO',
  },
  notificationMessages: {
    linkAccount: {
      heading: 'Vincular cuenta bancaria',
      content: 'Configure su cuenta bancaria para recibir todos los pagos en PayUp',
    },
    verifyIdentity: {
      heading: 'Verificar identidad',
      content: 'Complete su verificación para usar todas las funciones de PayUp',
    },
    payoutTerms: {
      heading: 'Elija los términos de pago',
      content:
        'Seleccione sus términos de pago predeterminados. Siempre puedes cambiarlos en la configuración.',
    },
    blockAddingBankAccount: {
      heading: 'No se puede vincular el nuevo banco',
      content: `Al usar pagos instantáneos, el banco vinculado debe ser su cuenta financiera de PayUp. Para vincular una nueva cuenta bancaria, deberá cambiar a pagos ACH.`,
      btnText: 'Pay Mode',
    },
  },
  invoiceSummary: {
    topInv: 'inversión',
    topPaymentText: 'Pago (estimado)',
    timeLineText: 'LÍNEA DE TIEMPO',
    top: {
      termModal: {
        title: 'Editar condiciones de pago',
        editOld: 'Seleccione los nuevos términos de pago en los que le gustaría recibir este pago.',
        editBulck:
          'Seleccione sus condiciones de pago. ¡Para facturas existentes, ofrecemos un 50% de descuento en las tarifas de pago! Los cambios se aplicarán a todas las facturas seleccionadas.',
        editBoulkNotOld:
          'Seleccione sus condiciones de pago. ¡Para facturas existentes, ofrecemos un 50% de descuento en las tarifas de pago!',
      },
      datePending: 'Fecha pendiente',
      estdToArriveOn: 'Se estima que llegue el',
      arrivedOn: 'Llegó el',
      wasSubmittedOn: 'fue presentado el',
      waitingForApproval: 'Está esperando que el cliente apruebe',
      mailedOn: 'enviado por correo',
      parentBatchText: 'Lote principal',
      batchMethodText: 'Método de pago por lotes',
    },
    bottom: {
      activity: 'ACTIVIDAD',
      origin: 'Origen',
      payUp: 'PayUp',
      account: 'Cuenta',
      routing: 'Enrutamiento',
      method: 'Método',
      transactionID: 'ID de transacción',
      batchNumber: 'Número de lote',
      daysText: 'días',
      destination: 'Destino',
      recipientName: 'Nombre de la destinataria',
      recipientAddress: 'Dirección de la destinataria',
      checkNumber: 'Verifique el número',
      checkStatus: 'Comprobar estado',
    },
    work: {
      workText: 'TRABAJAR',
      itemText: 'ARTÍCULO',
      amountText: 'MONTO',
      totalText: 'TOTAL',
    },
    middle: {
      invApproveText: 'Factura aprobada',
      invSubmittedText: 'Factura enviada',
      invDelinquentText: 'Factura enviada sobre propiedad morosa',
      notYetApproveText: 'Factura aún no aprobada',
      paymentTrigger: 'Pago activado',
      paymentOntheWay: 'Pago en Camino',
      paymentArrived: 'Pago llegado',
      netUpdatedTo: 'Términos netos actualizados a',
      pendingCustomerPay: 'Pago pendiente del cliente',
      estimated: 'Estimada',
      waitingForCustomerToApprove: 'Esperando a que la Cliente apruebe',
      viewTranReceipt: 'Ver recibo de transacción',
      termsUpdatedTo: 'Términos actualizados a',
      details: 'Detalles',
      less: 'Menos',
      yourCustomer: 'Tu cliente',
      approvalDeadlineMissed: 'Plazo de aprobación perdido',
      approvalDeadlineMissedDescription:
        'Parece que su cliente aún no ha aprobado esta factura. Esto ha resultado en la actualización de sus términos para esta factura. Recuérdele a su cliente que le dé el visto bueno para que podamos seguir adelante con su pago.',
      delinquentTermChangeDescription:
        'Lamentamos informarle que los términos de esta factura han cambiado. El motivo de este cambio se debe a que {{customer}} superó sus límites de préstamo con PayUp. Una vez que {{customer}} reconcilie sus facturas vencidas con PayUp, iniciaremos su pago de inmediato.',
      fundsRecivedFromCustomer: 'Fondos recibidos del cliente',
    },
  },
  emptyStates: {
    invoiceCards: {
      transactions: {
        emptyStateTitle: 'Aún no hay transacciones',
        emptyStateContent: 'Sus pagos aparecerán aquí',
      },
      payments: {
        emptyStateTitle: 'Aún no hay ganancias',
        emptyStateContent: 'Sus pagos aparecerán aquí',
      },
      paid: {
        emptyStateTitle: 'No hay facturas todavía',
        emptyStateContent: 'Continúa tu varificación para enviar tu primera factura',
      },
      approved: {
        emptyStateTitle: 'No hay facturas todavía',
        emptyStateContent: 'Continúa tu varificación para enviar tu primera factura',
      },
      submitted: {
        emptyStateTitle: 'No hay facturas todavía',
        emptyStateContent: 'Continúa tu varificación para enviar tu primera factura',
      },
    },
  },
  batches: esBatchesTranslation,
};
