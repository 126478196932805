import { getSpaceRemovedString } from './dataConvertions';

export function fullAddress(result: any) {
  try {
    return `${result.address.line1} ${result.address?.line2 ?? ''},  ${result.address.city},  ${
      result.address.state
    },  ${result.address.postal_code}`;
  } catch (e) {
    return null;
  }
}

export function AddressCityStateZip(result: any) {
  try {
    const checkNull =
      result &&
      result.address.city !== null &&
      result.address.state !== null &&
      result.address.postal_code !== null;
    return checkNull
      ? `${result.address.city},  ${result.address.state},  ${result.address.postal_code}`
      : null;
  } catch (e) {
    return null;
  }
}

export function RecipentAddressCityStateZip(result: any) {
  try {
    const checkNull =
      result &&
      result.stripe_account_metadata.check_recipient_details_city !== null &&
      result.stripe_account_metadata.check_recipient_details_state !== null &&
      result.stripe_account_metadata.check_recipient_details_zip !== null;
    return checkNull
      ? `${result.stripe_account_metadata.check_recipient_details_city},  ${result.stripe_account_metadata.check_recipient_details_state},  ${result.stripe_account_metadata.check_recipient_details_zip}`
      : null;
  } catch (e) {
    return null;
  }
}

//${result.address.line1} ${result.address?.line2 ?? ''}
export function errorsEmpty(errors: any) {
  return (
    errors && // 👈 null and undefined check
    Object.keys(errors).length === 0 &&
    Object.getPrototypeOf(errors) === Object.prototype
  );
}

// Check Business Address and Shipping Address both are same or not.
export const isBillingAndShippingAreTheSame = (companyAddress: any, individualAddress: any) => {
  const isLine1Equal =
    getSpaceRemovedString(companyAddress?.line1) ===
    getSpaceRemovedString(individualAddress?.line1);
  const isLine2Equal =
    getSpaceRemovedString(companyAddress?.line2) ===
    getSpaceRemovedString(individualAddress?.line2);
  const isCityEqual =
    getSpaceRemovedString(companyAddress?.city) === getSpaceRemovedString(individualAddress?.city);
  const isCountryEqual =
    getSpaceRemovedString(companyAddress?.country) ===
    getSpaceRemovedString(individualAddress?.country);
  const isCodeEqual =
    getSpaceRemovedString(companyAddress?.postal_code) ===
    getSpaceRemovedString(individualAddress?.postal_code);
  const isStateEqual =
    getSpaceRemovedString(companyAddress?.state) ===
    getSpaceRemovedString(individualAddress?.state);

  const isValuesAreNotNull =
    companyAddress?.line1 !== null &&
    individualAddress?.line1 !== null &&
    companyAddress?.city !== null &&
    individualAddress?.city !== null &&
    companyAddress?.country !== null &&
    individualAddress?.country !== null &&
    companyAddress?.postal_code !== null &&
    individualAddress?.postal_code !== null &&
    companyAddress?.state !== null &&
    individualAddress?.state !== null;

  const _isBillingAndShippingAreTheSame =
    isLine1Equal &&
    isLine2Equal &&
    isCityEqual &&
    isCountryEqual &&
    isCodeEqual &&
    isStateEqual &&
    isValuesAreNotNull;

  return _isBillingAndShippingAreTheSame;
};

export const isBillingAndRecipentAddressAreTheSame = (address: any, recipentAddress: any) => {
  const isLine1Equal =
    getSpaceRemovedString(address?.line1) ===
    getSpaceRemovedString(recipentAddress?.check_recipient_details_address);
  const isLine2Equal =
    getSpaceRemovedString(address?.line2) ===
    getSpaceRemovedString(recipentAddress?.check_recipient_details_address_2);
  const isCityEqual =
    getSpaceRemovedString(address?.city) ===
    getSpaceRemovedString(recipentAddress?.check_recipient_details_city);
  const isCodeEqual =
    getSpaceRemovedString(address?.postal_code) ===
    getSpaceRemovedString(recipentAddress?.check_recipient_details_zip);
  const isStateEqual =
    getSpaceRemovedString(address?.state) ===
    getSpaceRemovedString(recipentAddress?.check_recipient_details_state);

  const isValuesAreNotNull =
    address?.line1 !== null &&
    recipentAddress?.check_recipient_details_address !== null &&
    address?.city !== null &&
    recipentAddress?.check_recipient_details_city !== null &&
    address?.postal_code !== null &&
    recipentAddress?.check_recipient_details_zip !== null &&
    address?.state !== null &&
    recipentAddress?.check_recipient_details_state !== null;

  const _isBillingAndRecipentAddressAreTheSame =
    isLine1Equal &&
    isCityEqual &&
    isCodeEqual &&
    isStateEqual &&
    isValuesAreNotNull &&
    isLine2Equal;

  return _isBillingAndRecipentAddressAreTheSame;
};
