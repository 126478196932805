import { localeType } from './locale.type';
import { enBatchesTranslation } from './sections/batches/en';
import { enFinanceTranslation } from './sections/finance/en';

export const en: localeType = {
  formInput: {
    firstName: 'First name',
    lastName: 'Last name',
    comapanyName: 'Company name',
    companyContactName: 'Company contact name',
    email: 'Email',
    phone: 'Phone number',
    phone2: 'Phone number 2',
    ssn: 'Social Security Number',
    ssnInfo: 'Enter last 4 digits of your SSN',
    ssnLast4: 'Social Security Number',
    ssnReplaceButtonText: 'SSN Provided',
    ssnNotProvidedText: 'SSN Not Provided',
    ssnNotVerifiedText: 'SSN Not Verified',
    dob: 'Date of birth  (mm/dd/yyyy) ',
    addressLine1: 'Address',
    addressLine1HiglightText:
      'Your address must be a valid physical address and cannot be a P.O. Box. This address is not shared publicly and can be your personal address.',
    addressLine2: 'Address line 2',
    city: 'City',
    state: 'State',
    zipCode: 'Zip code',
    accountName: 'Full name',
    accountNumber: 'Account #',
    confirmAccountNumber: 'Confirm account #',
    routingNumber: 'Routing #',
    ein: 'Employer Identification Number (EIN)',
    einReplaceButtonText: 'Tax ID Provided',
    jobTitle: 'Title',
    sameBillingAddressCheckbox: 'Same as business address',
    ownerLabel: 'Owner',
    executiveLabel: 'Executive',
    executiveConfirmInput: 'I confirm that I own more than 25% of the company',
    required: 'required',
    dobDefault: '01/31/2000',
    businessName: 'Business Name (if registered as an org)',
    fullName: 'Full name',
    password: 'Password',
    changePasswordText: 'Change password',
    accountType: {
      label: 'Account Type',
      option1: 'Checking',
      option2: 'Savings',
    },
    businessType: {
      label: 'Business Type',
      option1: 'Individual',
      option2: 'Organization',
    },
    amountRequired: 'Amount (required)',
    addNote: 'Add Note',
    nickName: 'Nickname',
    recipientName: 'Recipient Name',
    recipientAddressFieldNote: 'Use the address where you receive post mail',
    recipientNameFieldNote: 'This can be an individual or organization.',
    sameHomeAddressCheckbox: 'Same as home address',
    taxId: 'Tax Id (EIN)',
  },
  buttonTexts: {
    loginButtonText: 'Log In',
    signupButtonText: 'Create Free Account ',
    buttonTextSignup: 'Sign Up',
    buttonTextSignIn: 'Log In',
    continueButton: 'Continue',
    addButton: 'Add',
    inviteUserButton: 'Invite one more',
    doneButton: 'Done',
    cancelButton: 'Cancel',
    updateButton: 'Update',
    submitButton: 'Submit',
    confirmButton: 'Confirm',
    nextButton: 'Next',
    followNextStepButton: 'Next',
    confirmTermsButton: 'Confirm Payout Terms ',
    saveContinueButton: 'Save & Continue ',
    continuePayupButton: 'Next',
    enableNotificationButton: 'Enable Notifications',
    ShareLinkText: 'Share link to friend',
    continueWithIdButton: 'Submit With ID',
    continueWithEINDocButton: 'Continue With Business Docs',
    reviwDataButton: 'Review Data',
    goBackButton: 'Go Back',
    acceptButton: 'Accept',
  },
  linkTexts: {
    sendAgainLink: 'Send Again',
    skipLink: 'Skip',
    individual: {
      ssnRedirectLink:
        'Don’t have a SSN? Sign up as a business with your EIN and an identity document.',
    },
    business: {
      ssnRedirectLink:
        'If you use your SSN for business tax purposes, please sign up as an individual.',
      provideIDDocLink: 'Don’t have a SSN? Click here to upload your identity document.',
      providedIDDoc: 'Document Provided',
    },
    addMoreNumberLink: 'Add one more number',
  },
  statusTexts: {
    pending: 'pending',
    verified: 'verified',
    unverified: 'unverified',
    restricted: 'restricted',
  },
  noticeBlocks: {
    customerAddition: {
      heading: 'Can’t find your customer? Invite them here',
      content: 'Get $50 if the customer you invite joins PayUp.',
    },
    termsBlock: {
      heading: 'Your information is secure and encrypted',
      content: 'PayUp uses state-of-the-art security measures.',
    },
    bankdetailSelection: {
      heading: 'If your bank is not on the list, contact us for support:',
      emailPrefix: 'email',
      callPrefix: 'or call us at',
    },
    notificationModal: {
      content:
        'These messages are required. PayUp will send you an important notifications about your payouts.',
    },
  },
  faqBlocks: {
    ownPayoutTerms: {
      heading: 'Your own payout terms',
      content:
        'Select your prefered payout terms for future payments. It could be 7, 14, 21, or 28 days. ',
    },
    fairFree: {
      heading: 'Fair fee',
      content:
        'We charge a reasonable fee for early payouts. You can still choose the “No Early Payout” option and get paid when your customer pays.',
    },
    flexibleSetup: {
      heading: 'Flexible setup',
      content: 'Based on your needs, you can choose different payout terms for each invoice.',
    },
    ownPayoutTermsNew: {
      heading: 'PayUp pays by default on Net30+',
      content:
        'Net 30+ refers to payment on 30 days or more from Invoice submission. The timeline here is dependent on the property based on when the property makes the payout.',
    },
    fairFeeNew: {
      heading: 'Fair fee',
      content: 'We charge a reasonable fee for payouts depending on the mode chosen.',
    },
    flexibleSetupNew: {
      heading: 'Flexible setup',
      content:
        'Based on your needs, you can choose different payout methods for each invoice or get paid on the default method you select.',
    },
    private: {
      heading: 'Private',
      content: 'We will not share your data with anyone.',
    },
    secure: {
      heading: 'Secure',
      content: 'All your data will be safe and used only with your permission.',
    },
    easyConnection: {
      heading: 'Easy connection',
      content: 'Add or change your bank account details at any time.',
    },
  },
  captions: {
    personalInfo: 'PERSONAL INFORMATION',
    businessDetails: 'BUSINESS DETAILS',
    personalDetails: 'PERSONAL DETAILS',
    invitedText: 'INVITED',
    submittedText: 'SUBMITTED',
    termDetails: 'TERM INFORMATION',
    bankInfotext: 'BANK DETAILS',
    editDetails: 'Edit details',
    recipientInfo: 'CHECK RECIPIENT DETAILS',
  },
  reviewCardtexts: {
    taxIdProvided: 'Tax ID Provided',
    taxIdNotProvided: 'Provide Valid Tax ID',
    idProvidedText: 'EIN Document Provided',
    idProvidedLast4Text: 'SSN Provided',
    idDocProvidedText: 'Document Provided',
    bornOnText: 'Born on',
    noBankDetails: 'No Bank Account Added',
  },
  headingContent: {
    verifyPhone: {
      heading: 'Let’s get started',
      content: 'Use your phone number to sign up',
    },
    verifyCode: {
      heading: 'Verify your number',
      content: 'Please enter the verification code sent to',
    },
    unVerifiedEmail: {
      heading: 'Verify Email',
      content: 'Check your inbox for a mail from PayUp and click the link to verify your email.',
      additionalContent: 'Didn’t receive a email? Check your spam',
    },
    verifiedEmail: {
      headingEmailVerified: 'Email verified',
      headingLoginCompletion: 'Login Complete',
      headingAccessDenied: 'Access Denied',
      content: 'Your email address was successfully verified. Continue to set up your account.',
    },
    verifyCodeSuccess: {
      loginComplete: 'Login Complete',
    },
    verifyCodeFailiure: {
      loginInComplete: 'Login Incomplete',
    },
    customerSelection: {
      heading: 'Choose your customers',
      content: 'Select the customers you work with so we can link your PayUp account to them.',
    },
    addCustomerModal: {
      heading: 'Add your customer',
      content: 'Invite a customer to offer you early payments. Get $50 if they join PayUp!',
    },
    updateCustomerModal: {
      heading: 'Update your customer',
    },
    choiceScreen: {
      heading: 'How would you like to sign up?',
    },
    individual: {
      personalInfo: {
        heading: 'Sign up as an individual',
        content: 'Enter or confirm your profile details.',
      },
      ssn: {
        heading: 'Verify identity',
        content:
          'No credit checks. The last 4 digits of your SSN are only used to verify your identity.',
      },
      dob: {
        heading: 'Verify identity',
        content: 'Enter your date of birth to verify you are 18+',
      },
      address: {
        heading: 'Verify identity',
        content: 'Enter your address.',
      },
      review: {
        heading: 'Let’s review',
        content: 'Please double-check the info you provided to make sure it’s accurate.',
      },
    },
    business: {
      businessInfo: {
        heading: 'Sign up as a business',
        content: 'Tell us about your business.',
      },
      businessAddress: {
        heading: 'Sign up as a business',
        content:
          'Enter your registered business address. You can use your home address if you don’t have a business address.',
      },
      ownerInfo: {
        heading: 'Verify ownership',
        content:
          'Verify you represent this business as an owner or executive. If not, make sure to ask the right person to continue.',
      },
      ownerAddress: {
        heading: 'Verify ownership',
        content: 'Enter your home address.',
      },
      ssn: {
        heading: 'Verify ownership',
        content:
          'No credit checks. The last 4 digits of your SSN are only used to verify your identity.',
      },
      review: {
        heading: 'Let’s review',
        content: 'Please double-check the info you provided to make sure it’s accurate.',
      },
    },
    statusses: {
      verificationFailed: {
        heading: 'Verification Failed',
        content: `We can't verify your SSN. Please provide a valid ID to continue signing up. Don't worry, all your data will be stored securely.`,
      },
      ssnVerificationFailed: {
        heading: 'Verification failed',
        content: `We couldn't verify your SSN. Please enter your full nine-digit SSN here.`,
      },
      einVerificationFailed: {
        heading: 'Verification failed',
        content: ` We couldn't verify your Employer Identification Number (EIN). To complete verification, please provide a business document instead.`,
      },
      einDocUploadDone: {
        heading: 'Document uploaded',
        content:
          'Thank you for providing us the required documents. Submit to finish verification.',
      },
      idDocUploadDone: {
        heading: 'Document uploaded',
        content:
          'Thank you for providing us the required documents. Submit to finish verification.',
      },
      onHold: {
        heading: 'Hold tight',
        content:
          'We’re verifying your information. Please keep this window open while you wait. It can take up to 1 minute.',
      },
      onHoldBeforeSuccess: {
        heading: 'Almost There!',
        content:
          'We are enabling payouts on your account. This can take up to 1 minute. Please keep this window open while you wait.',
      },
      approved: {
        heading: 'Approved!',
        content: `Congratulations, your verification was successful! There are just a few more steps left to set up your account.`,
      },
      bankSetupSuccess: {
        heading: 'Success!',
        contentForManualSetup: 'Congratulations, your bank account was successfully set up.',
        contentForFinConnection:
          'Congratulation, your bank account was successfully set up. Set a nickname below for easier identification!',
      },
      allJobDone: {
        headingLine1: 'Great job!',
        headingLine2: 'You’re all set to receive',
        headingLine3: 'payments in PayUp',
      },
      weFoundVendor: {
        heading: 'We found a match!',
        content1: 'We were able to find a match at {{vendor}} under {{customer}}.',
        content2: 'Please set a password for your account on the following screen.',
      },
      weNotFoundVendor: {
        heading: 'Uh-oh, we couldn’t locate your profile',
        content1:
          'Sorry, we were unable to find a vendor profile in {{customer}} against the provided email address, name or phone number.',
        content2:
          'Do you have alternate contact? Please share the email, name and number you used to sign up with {{customer}}.',
      },
      accountALreadyAvailable: {
        heading: 'You’re already on PayUp',
        content1: 'We found a profile already registered on PayUp with the {{numberOrEmail}}.',
        content2:
          'Log in to your profile or go back to create a new one with a different number or email.',
      },
      payModeSelectionSuccess: {
        heading: 'Success!',
        content:
          'You are set up to receive payouts via checks. To switch to faster payouts, just link your bank account under settings.',
      },
    },
    commonSteps: {
      earlyPayouts: {
        heading: 'What is early payout?',
        headingNew: 'How does PayUp pay?',
      },
      termsSelection: {
        heading: 'Choose payout terms ',
        content:
          'Select your prefered payout terms for future payments. You can always change your default payout terms in Settings. ',
      },
      payModeSelection: {
        heading: 'Choose payout mode',
        content:
          'Select your preferred mode for payouts. You can always change your default payout mode in Settings.',
        comingSoon: 'Coming Soon',
        default: 'Default',
      },
      bankSelection: {
        heading: 'Bank details',
        content:
          'Select how to link your bank account. You can change bank details later if needed.',
      },
      bankInfo: {
        heading: 'We keep connection safe and secure',
      },
      bankDetails: {
        heading: 'Bank details',
        content:
          'Provide the account and routing number of the bank where you would like to receive payments.',
      },
      notification: {
        heading: 'Stay Informed',
        content: 'Get notified of payouts, approvals, and other updates.',
      },
      notificationModal: {
        heading: 'Notifications set up',
        content: 'Set your notification preferences.',
      },
      referFriend: {
        heading: 'Refer a friend and earn',
        content: 'Get paid $10 for every friend who joins from your invite! ',
      },
      searchVendor: {
        heading: 'Let’s get started',
        content:
          'Please use the email and contact you used to sign up with your business customers.',
      },
      recipientDetails: {
        heading: 'Check Recipient Info',
        content: 'Please confirm the following details to get the check to the right individual',
      },
    },
  },
  errorMessages: {
    verifyCodeError: {
      heading: 'Verification failed',
    },
    dobError: {
      heading: 'It seems your age is less than 18',
      content:
        'You must be at least 18 years old to use PayUp. Please make sure you enter your birth date correctly.',
    },
    reviewError: {
      heading: 'Verification incomplete',
      content: 'Please review your  info such as first name, last name, address, DOB, EIN or SSN.',
      linkText: 'Verify Now',
    },
    phoneUniqueError: {
      heading: 'Phone number must be unique',
      content: 'You must use unique phone number  to use PayUp.',
    },
    emailUniqueError: {
      heading: 'Email ID must be unique',
      content: 'You must use unique email-id to use PayUp.',
    },
    ownershipError: {
      heading: 'Ownership Selection Error',
      content1: 'Please set ownership type',
      content2: 'Please confirm you own more than 25 percent of the company',
    },
    invalidAddressError: {
      heading: 'Invalid Address',
    },
  },
  idDocument: {
    step1: {
      heading: 'Submit document',
      content: 'Please select one of the following document types.',
    },
    step2: {
      heading: 'Submit document',
      content: 'Please select an option.',
      uploadButtonText: 'Upload document',
      photoButtonText: 'Take Photo',
    },
    step3: {
      heading: 'Submit document',
      content: 'Please upload selected identity document.',
      heading2: 'Take a photo',
      content2: '',
      modalTerm:
        'Please ensure the document you’re about to upload meets the requirements below. Confirm by checking:',
      firstModalCheckBox: 'The uploaded document is in color.',
      secondModalCheckBox: 'This document shows my legal name.',
      photoWord: 'Photo',
      frontWord: 'Front',
      backWord: 'Back',
      replaceWord: 'Replace',
      takeWord: 'Take',
      uploadWord: 'Upload',
    },
  },
  einModal: {
    heading: 'Submit document',
    content: 'Please select one of the following document types to upload. ',
    confirmationLetterButton: 'IRS SS-4 Confirmation Letter',
    irsLetterButton: 'IRS Letter 147C',
    replaceWord: 'Replace',
  },
  landing: {
    slide1: {
      heading: 'Get Early Payouts',
      content: 'Start using PayUp to get paid for work faster and on your own terms',
    },
    slide1New: {
      heading: 'Get Fast Payouts',
    },
    slide2: {
      heading: 'Track Your Invoices',
      content: 'PayUp allows you to create, track and manage all of your invoices in one place',
    },
    slide3: {
      heading: 'Simple Sign Up',
      content: 'Create a free account in under 2 minutes, no credit checks or lengthy applications',
    },
    slide4: {
      heading: 'PayUp Finance Account',
      content: 'Get paid instantly upon approval and have immediate access to your funds',
    },
    slide5: {
      heading: 'No More Debt ',
      content: 'Say goodbye to pay day loans that drown you in debt',
    },
  },
  verifyCode: {
    notRecivedText: 'Didn’t receive a text?',
  },
  customerAddition: {
    inviteUserModal: {
      companiesAddedText: 'Companies Added',
      companyText: 'COMPANY',
    },
    confirmModal: {
      heading: 'Customer invited',
      content:
        'Thank you! We’ve invited your customer to join PayUp. When they join, you’ll get {{payment}} in your account.',
    },
    multiCustomerConfirmModal: {
      heading: 'Customer submitted!',
    },
    updateConfirmationModal: {
      heading: 'Customer updated',
    },
  },
  choice: {
    business: {
      heading: 'Business',
      info1: 'Business verification with EIN',
      info2: 'Owner verification with SSN or ID',
      info3: 'File taxes as sole proprietor, partnership, corporation, S corp or LLC',
    },
    individual: {
      heading: 'Individual',
      info1: 'Identity verification with SSN',
      info2: 'File taxes as W2, 1099 or 1040',
    },
    signOut: 'Sign Out',
  },
  termsSelection: {
    default: 'Default',
    termCardDays: 'Days Payout',
    termCardFee: 'Fee',
    termNoEalryPayoutHeading: 'No Early Payout',
    termNoEalryPayoutContent: 'Est. {{Fee}} days',
    timeline: {
      daysText: 'Days',
      invoiceSubmitted: 'Invoice Submitted',
      invoiceApproved: 'Invoice Approved (Est)',
      youGetPaid: 'You get paid',
      customerPays: 'Customer Pays (Est)',
    },
    payModeNote:
      'All payouts are made only upon approval. Payout timings are from the time of funds release from the customer. Days reflect business days.',
  },
  bankDetails: {
    step1: {
      connectAPIButton: 'Connect via Bank Login',
      mannualSetupButton: 'Enter Account Details Manually',
    },
    success: {
      inputLabel: 'Account',
    },
  },
  notifications: {
    updateSuccess: 'Updated Successfully!',
    modal: {
      subHeading1: 'What notifications you receive?',
      subHeading2: 'Where you receive notifications?',
      emailText: 'Email',
      textMessageText: 'Text message',
      notificationPrefs: {
        DEFAULT_PAYMENT_TERMS_UPDATED: 'Default Payout Terms Changed',
        INVOICE_PAYMENT_TERMS_UPDATED: 'Invoice Payout Terms Changed',
        CUSTOMER_PAYOUT_APPROVED: 'Invoice Approved',
        INVOICE_DISPUTED: 'Invoice Disputed',
        PAYOUT_COMPLETED: 'Invoice Paid',
        CUSTOMER_PAYOUT_INVOICED: 'Invoice Submitted',
        READERS_DIGEST: 'PayUp info digest',
        weekly: 'Weekly',
        daily: 'Daily',
      },
    },
  },
  menu: {
    invoices: 'Invoices',
    settings: 'Settings',
    finances: 'Finances',
    dashboard: 'Home',
    notification: 'Notification',
    referFriend: 'Refer Friends',
    transactions: 'Transactions',
    payments: 'Earnings',
  },
  earning: {
    firstTabHeading: 'Submitted',
    secondTabHeading: 'Approved',
    thirdTabHeading: 'Paid',
    messageHeader: 'Certain invoices have the Net terms changed',
    searchText: 'Search by Invoice Number',
    termsEnableClose: 'Close',
    termsEnableEdit: 'Edit',
    messageContent:
      'Some invoices were automatically updated to “No early Payouts” terms because they were submitted on delinquent property.',
    headerText: 'Invoices',
    tranSummaryText: 'Transaction Summary',
    paymentSummaryText: 'Payment Summary',
    invoiceSummaryText: 'Invoice Summary',
    submitted: {
      accordionHeadingCurrent: 'Current Invoices',
      tooltipContentCurrent: 'These are new invoices that you created post sign up on PayUp.',
      accordionHeadingExisting: 'Existing Invoices',
      tooltipContentExisting:
        'These are old invoices that were created pre sign up on PayUp. We pull unpaid invoices to help you get paid faster on them at half rates.',
      firstColTitle: 'ESTIMATED',
      secondColTitle: 'TOTAL SUBMITTED',
    },
    download: {
      sendBtn: 'Send',
      title: 'Export Invoices',
      selectPeriod: 'Select period',
      noInvoicesAvailableTitle: 'No Invoices for this period',
      noInvoicesAvailableError: 'There are no invoices within the period you selected.',
      whereToSent: 'Where would you like your CSV sent?',
      today: 'Today',
      ytd: 'YTD',
      custom: 'Custom',
    },
    filter: {
      applyBtn: 'Apply',
      title: 'Filter and Sort',
      resetAll: 'Reset All',
      amountFilter: {
        title: 'Amount',
        from: 'From',
        to: 'to',
      },
      dateFilter: {
        title: 'Date',
        last7: 'Last 7 Days',
        last30: 'Last 30 Days',
        last12m: 'Last 12 months',
        lastYear: 'Year To Date',
        last90: 'Last 90 days',
        all: 'All',
        custom: 'Custom Period',
        ok: 'Ok',
        done: 'Done',
        cancel: 'Cancel',
      },
      termFilter: {
        title: 'Payout terms',
        all: 'Select All',
      },
      sortFilter: {
        title: 'Sort by ',
        date: 'Date',
        amount: 'Amount',
      },
    },
    tableColumns: {
      invoice: 'INVOICE',
      date: 'DATE',
      amount: 'AMOUNT',
      terms: 'BATCH #',
      mode: 'MODE',
      batch: 'BATCH',
    },
  },
  earningEmptyState: {
    commonTabHeading: 'No invoices yet',
    commonTabContent: 'Looks like you have not any invoices to view ',
    firstTabHeading: 'No invoices yet',
    firstTabContent: 'Your submitted invoices will appear here',
    secondTabHeading: 'No invoices yet',
    secondTabContent: 'Your approved invoices will appear here',
    thirdTabHeading: 'No invoices yet',
    thirdTabContent: 'Your paid invoices will appear here',
    paidTabComingSoonHeading: 'Coming Soon',
    paidTabComingSoonContent1:
      'We’re hard at work to get invoice reconciliation in place. Currently, we process payouts on property’s timelines. Once invoice reconciliation is available, all your paid invoices will start showing up here.',
    paidTabComingSoonContent2:
      'Do not worry though, you’ll still continue to receive all the payouts in the meantime!',
  },
  accountInfo: {
    heading: 'Account info',
    buttonText: 'Back to Home',
    needHelpText: 'Need help? Ask us anything!',
    supportEmail: 'support@payup.com',
    logoutLink: 'Log Out',
    languageSwitch: 'Choose Language',
  },
  placeHolder: {
    firstName: 'First name',
    lastName: 'Last name',
    emailAddress: 'Email',
    comapnyName: 'Squeaky Cleaners LLC',
    firstLineAddress: 'Address',
    secondLineAddress: 'Address line 2',
    city: 'City',
    state: 'State',
    zipCode: 'Zip code',
    accountName: 'Account holder name',
    jobTitle: 'Title',
  },
  notificationPrefs: {
    DEFAULT_PAYMENT_TERMS_UPDATED: 'Default Payout Terms Changed',
    INVOICE_PAYMENT_TERMS_UPDATED: 'Invoice Payout Terms Changed',
    CUSTOMER_PAYOUT_APPROVED: 'Invoice Approved',
    INVOICE_DISPUTED: 'Invoice Disputed',
    PAYOUT_COMPLETED: 'Invoice Paid',
    CUSTOMER_PAYOUT_INVOICED: 'Invoice Submitted',
    READERS_DIGEST: 'PayUp info digest',
    weekly: 'Weekly',
    daily: 'Daily',
  },
  home: {
    viewAll: 'View All',
    linkBank: {
      title: 'Linked Bank Accounts',
      linkDefaultButton: 'Default',
      bankName: 'Golden Bank',
      accountNumberText: 'Account    • • • • • ',
      addMoreText: 'Link another account',
    },
  },
  settings: {
    HeaderTitle: 'Settings',
    AccountInformation: {
      title: 'Account information',
      buttonTextVerified: 'Verified',
      buttonTextPending: 'Pending',
      buttonTextRestricted: 'Restricted',
      buttonTextUnverified: 'Unverified',
    },
    ChnagePasswordConfirmation: {
      title: 'Link Sent',
      content: 'Check your inbox for a mail from PayUp and click the link to change the password.',
    },
    PayoutTerms: {
      title: 'Payout Terms',
      buttonText: 'Net 7',
    },
    PayoutMode: {
      title: 'Payout Mode',
    },
    LinkedBankAccount: {
      title: 'Linked Bank Accounts',
      buttonText: 'Add Account',
      remove: 'Remove',
      default: 'Make Default',
    },
    Notifications: {
      title: 'Notifications',
      buttonText: '',
    },
    Customers: {
      title: 'Customers',
      buttonText: '',
    },
    Languages: {
      title: 'Languages',
      buttonText: 'English',
    },
    LogOut: {
      title: 'Log Out',
      buttonText: 'Verified',
    },
    Referrals: {
      title: 'Referrals',
      buttonText: '',
    },
  },
  settingsReferals: {
    heading: 'Invite your friends, get $10 when they submit their first invoice',
    content: `Earn up to $100 by inviting your friends to use PayUp. For every friend who joins, you’ll receive $10 in your linked bank account when they submit their first invoice on PayUp.`,
    headerHeading: 'Referrals',
    progressTitle: 'Your Reward',
    stepsContent: 'Friends joined',
    progressValue: 'Earned',
    overallProgressValue: 'Available',
  },
  settingsCustomers: {
    heading: 'Invite customers, get $50 when they sign up with us',
    content: `For every customer that you invite, you’ll receive $50 when they join. You can get up to $250!`,
    subHeading1: 'Active customers',
    subHeading2: 'Invited Customers',
    progressTitle: 'Your Reward',
    stepsContent: 'Customer joined',
    progressValue: 'Earned',
    overallProgressValue: 'Available',
    confirmButton: 'Invite New Customer',
  },
  finance: enFinanceTranslation,
  transactions: {
    searchTransByProperty: 'Search Transactions by Property',
    searchTrans: 'Search transactions',
    searchPaymentsByProperty: 'Search Earnings by Property',
    searchPayments: 'Search earnings',
    payouts: 'Payouts',
  },
  actionCards: {
    inviteCustomer: {
      heading: 'Invite customers, get $50 when they join',
      content: 'Invite Customers',
    },
    inviteFriend: {
      heading: 'Invite a friend, get $10 when they submit their first invoice',
      content: 'Invite Friends',
    },
    enableNotifications: {
      heading: 'SetUp Notification, stay informed',
      content: 'Set Notifications',
    },
  },
  inVoiceCards: {
    total: 'TOTAL',
    saved: 'SAVED',
    onTheWay: 'ON THE WAY',
    nextPayout: 'NEXT PAYOUT',
    approvedInvoices: 'Approved Invoices',
    approvedHeading: 'APPROVED INVOICES',
    submittedHeading: 'SUBMITTED INVOICES',
    paidHeading: 'PAID INVOICES',
    transHeading: 'TRANSACTIONS',
    paymentsHeading: 'EARNINGS',
    paidOut: 'PAID OUT',
    upcoming: 'UPCOMING',
  },
  notificationMessages: {
    linkAccount: {
      heading: 'Link Bank Account',
      content: 'Set up your bank account to receive all payments in PayUp',
    },
    verifyIdentity: {
      heading: 'Verify Identity',
      content: 'Complete your verification to use all features of PayUp',
    },
    payoutTerms: {
      heading: 'Choose Payout Terms',
      content: 'Select your default payout terms. You can always change them in settings.',
    },
    blockAddingBankAccount: {
      heading: 'Unable to link new bank',
      content: `When using Instant payouts, the linked bank must be your PayUp Financial Account. To link a new bank account, you'll have to switch to ACH payouts.`,
      btnText: 'Pay Mode',
    },
  },
  invoiceSummary: {
    topInv: 'Inv',
    topPaymentText: 'Payment (est.)',
    timeLineText: 'TIMELINE',
    top: {
      termModal: {
        title: 'Edit Payout Terms',
        editOld: 'Select the new payout terms that you would like to receive this payment on.',
        editBulck:
          'Please select your payout terms.  For existing invoices, we offer a 50% discount on payout fees! Changes will apply to all selected invoices.',
        editBoulkNotOld:
          'Please select your payout terms.  For existing invoices, we offer a 50% discount on payout fees!',
      },
      datePending: 'Date Pending',
      estdToArriveOn: 'Is estimated to arrive on',
      arrivedOn: 'Arrived on',
      wasSubmittedOn: 'Was submitted on',
      waitingForApproval: 'Is waiting for customer to approve',
      mailedOn: 'Mailed on',
      parentBatchText: 'Parent batch',
      batchMethodText: 'Batch payment method',
    },
    bottom: {
      activity: 'ACTIVITY',
      origin: 'Origin',
      payUp: 'PayUp',
      account: 'Account',
      routing: 'Routing',
      method: 'Method',
      transactionID: 'Transaction ID',
      batchNumber: 'Batch Number',
      daysText: 'days',
      destination: 'Destination',
      recipientName: 'Recipient Name',
      recipientAddress: 'Recipient Address',
      checkNumber: 'Check Number',
      checkStatus: 'Check Status',
    },
    work: {
      workText: 'WORK',
      itemText: 'ITEM',
      amountText: 'AMOUNT',
      totalText: 'TOTAL',
    },
    middle: {
      invApproveText: 'Invoice Approved',
      invSubmittedText: 'Invoice Submitted',
      invDelinquentText: 'Invoice Submitted on Delinquent Property',
      notYetApproveText: 'Invoice Not Yet Approved',
      paymentTrigger: 'Payment Triggered',
      paymentOntheWay: 'Payment On the Way',
      paymentArrived: 'Payment Arrived',
      netUpdatedTo: 'Net Terms Updated to',
      pendingCustomerPay: 'Pending Customer Payment',
      estimated: 'Estimated',
      waitingForCustomerToApprove: 'Waiting for Customer to approve',
      viewTranReceipt: 'View Transaction Receipt',
      termsUpdatedTo: 'Terms Updated to',
      details: 'Details',
      less: 'Less',
      yourCustomer: 'your customer',
      approvalDeadlineMissed: 'Approval Deadline Missed',
      approvalDeadlineMissedDescription:
        'It looks like your customer has not approved this invoice yet. This has resulted in your terms being updated for this invoice. Please remind your customer to give this the thumbs up so that we can move forward with your payment.',
      delinquentTermChangeDescription:
        'We are sorry to inform you that the terms have changed on this invoice. The reason for this change is due to {{customer}} being over their lending limits with PayUp. Once {{customer}} reconciles their past due invoices with PayUp, we will immediately initiate your payment.',
      fundsRecivedFromCustomer: 'Funds received from Customer',
    },
  },
  emptyStates: {
    invoiceCards: {
      transactions: {
        emptyStateTitle: 'No transactions yet',
        emptyStateContent: 'Your payouts will appear here',
      },
      payments: {
        emptyStateTitle: 'No earnings yet',
        emptyStateContent: 'Your payouts will appear here',
      },
      paid: {
        emptyStateTitle: 'No invoices yet',
        emptyStateContent: 'Continue your verification to submit your first invoice',
      },
      approved: {
        emptyStateTitle: 'No invoices yet',
        emptyStateContent: 'Continue your verification to submit your first invoice',
      },
      submitted: {
        emptyStateTitle: 'No invoices yet',
        emptyStateContent: 'Continue your verification to submit your first invoice',
      },
    },
  },
  batches: enBatchesTranslation,
};
