import styled, { keyframes } from 'styled-components';

const Spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
`;

export const LoaderWrapper = styled.div`
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  margin-bottom: 20px;
`;

export const WrapperContainerLoader = styled.div`
  top: 0;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  background: #fff;
  display: block;
`;
