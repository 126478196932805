import { styled } from '@mui/material/styles';

export const TabNav = styled('div')(
  ({ theme }) => `
  width: 100%;
  display: flex;
  margin-bottom: ${theme.spacing(8)};
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray[200]};
  border-radius: 40px;
 `
);

export const TabNavItem = styled('div')(
  ({ theme }) => `
   width: 50%;
   border-radius: 40px;
   height: 38px;
   display: flex;
   align-items: center;
   justify-content: center;
   background: ${theme.palette.white};
   font-weight: 600;
   font-size: 14px;
   line-height: 16px;
   color: ${theme.palette.gray[900]};
   cursor: pointer;

   &.active {
    color: ${theme.palette.white};
    background: ${theme.palette.primary.main};
    pointer-events: none;
   }

  `
);
