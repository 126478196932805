import { Box, Typography } from '@mui/material';
import { FinanceBankInfoCard, ShadowCard } from 'src/components/base/core';
import { theme } from 'src/shared/theme/theme';
import { handleUserIconColor } from 'src/shared/utilities/dataConvertions';

interface IFinanceLinkedAccountsCard {
  blockTitle?: string;
  linkText?: string;
  onClickLink?: () => void;
  bankData?: any;
  emptyBlockContent?: string;
  blockMarginBottom?: string | number;
  onDeleteAccount?: any;
  onUpdateDefaulAccount?: any;
  onClickBankCard?: any;
  selectedItemID?: string;
  isPayeeType?: boolean;
}

export const FinanceLinkedAccountsCard = ({
  blockTitle,
  linkText,
  onClickLink,
  bankData,
  emptyBlockContent = 'Create your first account',
  blockMarginBottom = 4,
  onDeleteAccount,
  onUpdateDefaulAccount,
  onClickBankCard,
  selectedItemID,
  isPayeeType = false,
}: IFinanceLinkedAccountsCard) => {
  return (
    <ShadowCard marginBottom={blockMarginBottom} paddingY={4}>
      <Typography
        component="p"
        variant="h2ExtraBold"
        textTransform={'uppercase'}
        sx={{
          marginBottom: 3,
          display: 'inline-flex',
          alignItems: 'center',
          color: 'gray.500',
          paddingX: 4,
        }}
      >
        {blockTitle}
      </Typography>
      {bankData &&
        bankData.length > 0 &&
        bankData.map((bankItem?: any, index?: any) => {
          let bankitemObj = bankItem;

          if (isPayeeType) {
            const setLast4 =
              bankItem?.us_bank_account?.last4 ?? bankItem?.metadata?.account_number.slice(-4);
            bankitemObj = {
              default_for_currency: false,
              bank_name: bankItem?.us_bank_account?.bank_name ?? null,
              account_holder_name:
                bankItem?.billing_details?.name ?? bankItem?.us_bank_account?.bank_name,
              last4: setLast4,
              id: bankItem?.id,
              metadata: { nickname: bankItem?.metadata?.nickname ?? 'PayUp Account' },
            };
          }

          return (
            <FinanceBankInfoCard
              bankDetails={bankitemObj}
              deleteAccount={onDeleteAccount}
              key={index}
              updateDefaultAccount={onUpdateDefaulAccount}
              userIconBgColor={handleUserIconColor(index)}
              onClickBankCard={onClickBankCard}
              isSelected={bankItem.id === selectedItemID}
              isDefaultUpdateOptionAvailable={!isPayeeType}
            />
          );
        })}

      {bankData && bankData.length === 0 && (
        <Box
          display={'flex'}
          paddingX={4}
          sx={{
            width: '100%',
          }}
        >
          <Typography
            variant="h1"
            component={'div'}
            sx={{
              background: theme.palette.gray[100],
              display: 'flex',
              width: '100%',
              padding: 4,
              borderRadius: '4px',
            }}
          >
            {emptyBlockContent}
          </Typography>
        </Box>
      )}
      <Typography
        component="span"
        variant="h1Bold"
        color={'primary'}
        textTransform={'capitalize'}
        onClick={onClickLink}
        textAlign={'right'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          fontSize: '14px',
          paddingX: 4,
          marginY: 4,
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        {linkText}
      </Typography>
    </ShadowCard>
  );
};
