import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Wrapper } from 'src/components/base/core';
import { FromVerifyRepresentSubmition } from './FromVerifyRepresentSubmition/FromVerifyRepresentSubmition';
import { FormSSNSubmition } from './FormSSNSubmition/FormSSNSubmition';
import { FormHomeAddressSubmition } from './FormHomeAddressSubmition/FormHomeAddressSubmition';
import { useGetAccountQuery } from 'src/services/account/accountServiceApi';
import { routeType } from 'src/helpers/containers/helperRoutePreference';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';

export const businessAddressInit = {
  city: '',
  line1: '',
  line2: '',
  postal_code: '',
  state: '',
};

type LocationState = {
  edit?: boolean;
  isBack?: boolean;
  backFrom?: string;
  step?: string;
};

export const BusinessRepresentativeContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isForword, setIsForword] = useState('');
  const { data } = useGetAccountQuery('');
  const [backFromStep, setBackFromStep] = useState('');
  const { webEnableIdDocumentUpload } = useFlags();
  const [updatePreferenceOnServer] = handlePreferences();

  const initalState = {
    step1: false,
    step2: false,
    step3: false,
  };

  const [stepVisibility, setStepVisibility]: any = useState(initalState);

  const { backFrom, step } =
    location?.state != null ? (location?.state as LocationState) : { backFrom: '', step: 'step1' };

  useEffect(() => {
    if (data && data?.company) {
      if (isForword === '') {
        switch (step) {
          case 'step1':
            setStepVisibility({ ...initalState, step1: true });
            break;
          case 'step2':
            setStepVisibility({ ...initalState, step2: true });
            break;
          case 'step3':
            setStepVisibility({ ...initalState, step3: true });
            break;
          default:
            setStepVisibility({ ...initalState, step1: true });
        }
      }
    }
  }, [data]);

  const handleFirstStepClick = () => {
    if (backFrom === 'account') {
      setBackFromStep('step2');
    }
    setIsForword('step2');
    backFrom !== 'account' && updatePreferenceOnServer(routeType.BUSINESS_REP, 'step2');
    setStepVisibility({ ...stepVisibility, step1: false, step2: true, step3: false });
  };
  const handleSecondStepClick = () => {
    if (backFrom === 'account') {
      navigate('/business-review');
      setBackFromStep('');
    }
    setIsForword('step3');
    backFrom !== 'account' && updatePreferenceOnServer(routeType.BUSINESS_REP, 'step3');
    if (backFrom === 'account') {
      navigate('/business-review');
      localStorage.removeItem('set_id_modal_states');
    } else {
      setStepVisibility({ ...stepVisibility, step1: false, step2: false, step3: true });
    }
  };
  const handleThirdStepClick = () => {
    backFrom !== 'account' && updatePreferenceOnServer(routeType.BUSINESS_REVIEW);
    navigate('/business-review');
    localStorage.removeItem('set_id_modal_states');
  };

  const handleBackButtonClick = () => {
    if (stepVisibility.step1) {
      navigate('/sign-up', {
        state: { type: 'company' },
      });
    }

    if (stepVisibility.step2) {
      setStepVisibility({ ...initalState, step1: true });
    }
    if (stepVisibility.step3) {
      setStepVisibility({ ...initalState, step2: true });
    }
  };

  const handleBackButtonRepClick = () => {
    if (stepVisibility.step1) {
      navigate('/business-info', {
        state: { type: 'company', step: 'step2', backFromRepWithAccount: backFrom },
      });
    }
  };

  return (
    <Wrapper>
      {stepVisibility.step2 && (
        <FormHomeAddressSubmition
          onBackButtonClick={handleBackButtonClick}
          onFormSubmit={handleSecondStepClick}
          accountData={data}
        />
      )}

      {stepVisibility.step3 && (
        <FormSSNSubmition
          onBackButtonClick={handleBackButtonClick}
          onFormSubmit={handleThirdStepClick}
          accountData={data}
        />
      )}
      {stepVisibility.step1 && (
        <FromVerifyRepresentSubmition
          onBackButtonClick={handleBackButtonRepClick}
          onFormSubmit={handleFirstStepClick}
          accountData={data}
        />
      )}
    </Wrapper>
  );
};
