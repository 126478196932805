import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
/* use the AES encryption algorithm and secret passphrase store in the config*/
const encryptWithAES = (text: string, passphrase: string) => {
  return AES.encrypt(text, passphrase).toString();
};

const decryptWithAES = (ciphertext: string, passphrase: string) => {
  const bytes = AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(Utf8);
  return originalText;
};

export { encryptWithAES, decryptWithAES };
