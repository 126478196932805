import * as React from 'react';
import { FormSelectInputContainer, FormSelectInputWrapper } from './DropdownState.style';
import { Autocomplete, TextField } from '@mui/material';
import { DropdownData as dropdownData } from './DropdownState.data';
import { FormInputErrorMessage } from '../FormInputErrorMessage/FormInputErrorMessage';

export interface InputProps extends React.SelectHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string;
  htmlFor?: string;
  error?: any;
  isValid?: boolean;
  highLightText?: React.ReactNode;
  maxWidth?: string;
  defaultText?: string;
  options?: any;
  useId?: boolean;
  onChange?: (val: any) => void;
  onInputChange?: (val: any) => void;
  selectedState?: string;
  inputValue?: any;
  inputFocus?: any;
  readonly?: boolean;
}

export const DropdownState: React.FC<InputProps> = React.forwardRef<HTMLSelectElement, InputProps>(
  (
    {
      id,
      label = 'State',
      error,
      isValid = false,
      maxWidth = '100%',
      options = dropdownData,
      readonly,
      inputValue,
      onChange,
      inputFocus,
    },
    ...props
  ) => {
    const handleInputClasses =
      error && !isValid ? 'error_input' : isValid ? 'valid_input' : 'normal_input';

    let selectedStateVal = inputValue
      ? options.find(({ label }: any) => label === inputValue)
      : null;
    selectedStateVal = selectedStateVal || null;

    return (
      <FormSelectInputWrapper style={{ maxWidth: maxWidth }} className={handleInputClasses}>
        <FormSelectInputContainer>
          <Autocomplete
            disablePortal
            id={id}
            options={options && options}
            onChange={onChange}
            fullWidth
            disabled={readonly}
            value={selectedStateVal && selectedStateVal}
            onBlur={inputFocus && inputFocus}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                error={error?.length > 0}
                focused={inputValue ? true : false}
              />
            )}
          />
        </FormSelectInputContainer>
        {error && !isValid && <FormInputErrorMessage>{error}</FormInputErrorMessage>}
      </FormSelectInputWrapper>
    );
  }
);
