import { useTranslation } from 'react-i18next';

import {
  DivWrapper,
  HeadingSummaryBlock,
  Header,
  ShareButton,
  ContentContainerBottom,
  Wrapper,
} from 'src/components/base/core';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Images } from 'src/shared/utilities/images';
import { useGetReferralLinkQuery } from '../../../services/account/referralServiceApi';
import { routeType } from 'src/helpers/containers/helperRoutePreference';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';

export const ReferFriendContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data, isSuccess } = useGetReferralLinkQuery('');
  const [updatePreferenceOnServer] = handlePreferences();

  const handleBackButton = () => {
    updatePreferenceOnServer(routeType.ENABLE_NOTIFICATIONS);
    navigate('/enable-notifications');
  };

  const handleSkipButtonClick = () => {
    updatePreferenceOnServer(routeType.OPEN_ROUTE, null, 'open', '/dashboard');
    navigate('/dashboard');
  };

  return (
    <Wrapper>
      <DivWrapper className={'no_fullscreen'}>
        <Box component={'div'} className={'container_content'}>
          <Box component={'div'} className={'container_content_top'}>
            <Header
              isBackButtonVisible={true}
              onBackButtonClick={handleBackButton}
              paddingX={0}
              isSkipButtonVisible={true}
              onSkipButtonClick={handleSkipButtonClick}
            />
            <div className="status_image">
              <img src={Images.REFER_A_FRIEND} alt="Status" height={200} />
            </div>
            <div className="content_block add_bottom_padding">
              <Box marginBottom={6}>
                <HeadingSummaryBlock
                  heading={t('headingContent.commonSteps.referFriend.heading')}
                  headingAlignment="left"
                  content={t('headingContent.commonSteps.referFriend.content')}
                  contentAlignment="left"
                />
              </Box>
            </div>
          </Box>
        </Box>
        {isSuccess && (
          <ContentContainerBottom className={'no_fullscreen'}>
            <ShareButton
              label={t('buttonTexts.ShareLinkText')}
              text="Start using PayUp to get paid for work faster and on your own terms."
              title="PayUp"
              url={data.url}
              onClick={() => console.log('shared successfully!')}
            />
          </ContentContainerBottom>
        )}
      </DivWrapper>
    </Wrapper>
  );
};
