import { useState } from 'react';
import {
  GetPayoutTerms,
  SetPayoutTerms,
  SetMultiplePayoutTerms,
  GetCurrentInvoicesPayoutTerms,
} from '../services/payout-terms/payoutTerms.service';
import { initialTermsDetail } from '../shared/utilities/initialStates';
import { getIntVal } from '../shared/utilities/dataConvertions';
// import { configs } from 'src/shared/config/config';

/**
 * Used to handle the Edit Term functionality and Edit term modal visiblility
 * @returns  termsSetDetails, handleTermClick, handleTermChange, handleConfirmButtonClick, handleYesButtonClick,  handleModalClose,
 */
export const helperHandlingTerms = () => {
  // set the termsdetail state __
  const [termsSetDetails, setTermsSetDetails] = useState<any>(initialTermsDetail);
  const [activeAccordion, setActiveAccordion] = useState(true);
  const [activeAccordionOldInv, setActiveAccordionOldInv] = useState(false);

  // trigger the Edit term modal __
  const handleTermClick = async (e: any, isCurrentInvoce: boolean = false) => {
    const payoutId = e.currentTarget.dataset.id; // get the payout id from data-id
    const amount = e.currentTarget.dataset.amount; // get the payout id from data-amount
    const termID = e.currentTarget.dataset.term; // get the payout id from data-term
    const payoutType = e.currentTarget.dataset.payoutType; // get the payout type is old invoices or not

    let termPayouts; // terms list

    setTermsSetDetails({
      ...termsSetDetails,
      id: payoutId,
      isTermClicked: true,
    });

    if (isCurrentInvoce) {
      //current invoices
      termPayouts = await GetCurrentInvoicesPayoutTerms(amount, payoutId);
    } else {
      // get the terms
      termPayouts = await GetPayoutTerms(amount, payoutId);
    }

    // handle the response
    if (termPayouts?.status === 200) {
      setTermsSetDetails({
        ...termsSetDetails,
        modalOpen: true,
        id: payoutId,
        termsData: termPayouts.data,
        showConfirmModal: false,
        updatedTerm: termID,
        selectedTerm: termID,
        initialLoadingTerm: termID,
        error: null,
        isTermClicked: false,
        isOldInvoiceType: payoutType,
      });
    } else {
      setTermsSetDetails({
        ...termsSetDetails,
        modalOpen: true,
        id: payoutId,
        error: termPayouts.response.data.error,
        showConfirmModal: false,
        updatedTerm: termID,
        selectedTerm: termID,
        initialLoadingTerm: termID,
        isTermClicked: false,
        isOldInvoiceType: payoutType,
      });
    }
  };

  // handle multiple term selection for bulk edit __
  const handleMultiplePaymentSelection = (
    e: any,
    existingInvicedData: any,
    setExistingInvicedData: any
  ) => {
    let setMultipleTerms: any = termsSetDetails?.selectedMultipleTerms; // get the selected term property value from termsSetDetails state
    let setSelectedTermsCount = termsSetDetails?.selectedTermsCount; // get the selected term count from termsSetDetails state
    const getCurrentTargetValue = e?.currentTarget?.value; // get the current target value

    if (e?.currentTarget?.checked !== undefined && e?.currentTarget?.checked) {
      setMultipleTerms = [...setMultipleTerms, getCurrentTargetValue];
      setSelectedTermsCount = setMultipleTerms?.length;
      setTermsSetDetails &&
        setTermsSetDetails((termsSetDetails: any) => ({
          ...termsSetDetails,
          selectedMultipleTerms: setMultipleTerms,
          selectedTermsCount: setSelectedTermsCount,
        }));
    } else {
      setMultipleTerms =
        setMultipleTerms &&
        setMultipleTerms?.filter(function (item: any) {
          return item !== getCurrentTargetValue;
        });
      setSelectedTermsCount = setMultipleTerms.length;

      setTermsSetDetails &&
        setTermsSetDetails({
          ...termsSetDetails,
          selectedMultipleTerms: setMultipleTerms,
          selectedTermsCount: setSelectedTermsCount,
        });
    }

    //if at least one unchecked should change to select all
    let isAllEnable = true;

    //set checkbox selection
    const newexistingInvoceArray = existingInvicedData.map((obj: any) => {
      if (obj.id === e?.target?.id) {
        return { ...obj, isChecked: e?.target?.checked };
      }
      if (isAllEnable && (!obj.isChecked || !e?.target?.checked)) {
        isAllEnable = false;
      }
      return obj;
    });
    setExistingInvicedData(newexistingInvoceArray);
  };

  /**
   * handle select/ deselect All
   * @param existingInvicedData
   * @param setExistingInvicedData
   */
  const handleAllPayoutSelector = (existingInvicedData: any, setExistingInvicedData: any) => {
    const newArr = existingInvicedData.map((obj: any) => {
      return { ...obj, isChecked: termsSetDetails && !termsSetDetails.isAllTermChecked };
      return obj;
    });
    setExistingInvicedData(newArr);

    const getAllTermIds = existingInvicedData.map((item: any) => item.id);

    setTermsSetDetails({
      ...termsSetDetails,
      isAllTermChecked: termsSetDetails && !termsSetDetails.isAllTermChecked,
      selectedMultipleTerms:
        termsSetDetails && !termsSetDetails.isAllTermChecked ? getAllTermIds : [],
    });
  };

  // handle the term selection in the model __
  const handleTermChange = (e: any) => {
    const selectedTerm = e.target.value; // get the term from input[radio] onChange event
    // set the selected term into state
    setTermsSetDetails((termsSetDetails: any) => ({
      ...termsSetDetails,
      updatedTerm: selectedTerm,
    }));
  };

  const handleEditInvoicesClick = async () => {
    const payoutId: any = termsSetDetails.selectedMultipleTerms[0]; // get the firts payout id from the array
    const amount = null;
    // get the terms
    const res = await GetPayoutTerms(amount, payoutId);

    // handle the response
    if (res?.status === 200) {
      setTermsSetDetails({
        ...termsSetDetails,
        modalOpen: true,
        termsData: res.data,
        showConfirmModal: false,
        isEditInvoiceLinkClicked: true,
      });
    } else {
      setTermsSetDetails({
        ...termsSetDetails,
        modalOpen: true,
        termsData: res.response.data,
        showConfirmModal: false,
        isEditInvoiceLinkClicked: true,
      });
    }
  };

  // handle the confirmation button click __
  const handleConfirmButtonClick = async () => {
    const selectedNetTerm = termsSetDetails.updatedTerm; // get the selected term
    const selectedMultipleTerms = termsSetDetails.selectedMultipleTerms;
    const payoutId = termsSetDetails.id; // get payout id
    let res: any = null;

    setTermsSetDetails({ ...termsSetDetails, error: null });

    // update the term against the payout id
    if (!termsSetDetails.isEditInvoiceLinkClicked) {
      res = await SetPayoutTerms(payoutId, selectedNetTerm);
    }

    if (termsSetDetails.isEditInvoiceLinkClicked) {
      res = await SetMultiplePayoutTerms(selectedMultipleTerms, selectedNetTerm);
    }

    // handle the response
    if (res?.status === 200) {
      // set the confirmation modal visibility
      setTermsSetDetails({ ...termsSetDetails, showConfirmModal: true });
    } else {
      // set the error and confirmation model visibility
      setTermsSetDetails({
        ...termsSetDetails,
        showConfirmModal: false,
        error: !termsSetDetails.isEditInvoiceLinkClicked
          ? res?.response?.data?.error ?? res?.response.data
          : res?.response?.data?.message,
      });
    }
  };

  // create function for confirmation modal DOne button click __
  const handleYesButtonClick = () => {
    setTermsSetDetails({ ...initialTermsDetail, curentInvoiceOpen: true });
  };

  // handle the model close __
  const handleModalClose = () => {
    if (!termsSetDetails.showConfirmModal) {
      // if confirmation modal is visble, user unable to close the popup
      setTermsSetDetails({ ...termsSetDetails, modalOpen: false, isEditInvoiceLinkClicked: false });
    } else {
      // if confirmation modal is not-visble, user can able to close the popup
      setTermsSetDetails({ ...termsSetDetails, modalOpen: true });
    }
  };

  const handleOldInvoiceToggle = () => {
    setActiveAccordionOldInv(!activeAccordionOldInv);
    const getOldInvoiceCurrentState = termsSetDetails.oldInvoiceOpen;
    setTermsSetDetails({
      ...termsSetDetails,
      oldInvoiceOpen: !getOldInvoiceCurrentState,
      curentInvoiceOpen: false,
      selectedMultipleTerms: [],
      selectedTermsCount: 0,
      termBulkEditable: false,
    });
  };

  const handleCurrentInvoiceToggle = () => {
    setActiveAccordion(!activeAccordion);
    const getCurrentInvoiceCurrentState = termsSetDetails.curentInvoiceOpen;
    setTermsSetDetails({
      ...termsSetDetails,
      curentInvoiceOpen: !getCurrentInvoiceCurrentState,
      oldInvoiceOpen: false,
      selectedMultipleTerms: [],
      selectedTermsCount: 0,
      termBulkEditable: false,
    });
  };

  const getSelectedCount = (existingData: any) => {
    const checkedCount = existingData?.filter((item: any) => item.isChecked).length;
    return checkedCount;
  };

  // return the value as array
  return [
    termsSetDetails,
    handleTermClick,
    handleTermChange,
    handleConfirmButtonClick,
    handleYesButtonClick,
    handleModalClose,
    handleMultiplePaymentSelection,
    handleAllPayoutSelector,
    handleEditInvoicesClick,
    handleOldInvoiceToggle,
    handleCurrentInvoiceToggle,
    getSelectedCount,
    activeAccordion,
    activeAccordionOldInv,
    setActiveAccordion,
    setActiveAccordionOldInv,
  ];
};

/**
 * Used to get term name using term id or other string values
 * @param dataString: pass the string value to genarate the term name
 * @returns termName
 */
export const getTermName = (dataString?: string) => {
  const termId = getIntVal(dataString); // get the integer value
  let termName = '';

  if (dataString?.includes('old')) {
    termName = termId === '30' ? `Net 30+` : `Net ${termId}`;
  } else {
    termName = termId === '30' ? `Net 30+` : `Net ${termId}`;
  }
  // set the name

  return termName;
};

/**
 * Used to get term class using term id or other string values
 * @param dataString: pass the string value to genarate the term name
 * @returns termName
 */
export const getTermTagClass = (dataString?: string) => {
  let termTagClass = 'new_term';
  const termId = getIntVal(dataString);

  if (dataString?.includes('old') && termId !== '30') {
    termTagClass = 'old_term';
  }
  // set the name

  return termTagClass;
};

/**
 * Used to get term due_days using term id or other string values
 * @param dataString: pass the string value to genarate the term due_days
 * @returns termName
 */
export const getTermDueDays = (dataString?: string) => {
  const termId = getIntVal(dataString); // get the integer value
  const termDueDays = termId !== '30' ? termId : '30+'; // set the due days

  return termDueDays;
};

/**
 * Used to reorder the array using due_days
 * @param dataString: pass the terms option, wich return from the api
 * @returns sorted array
 */
export const reOrderingTheTerms = (options: any) => {
  const sorted: any = options?.terms?.sort(
    (a: any, b: any) => parseFloat(a.due_days) - parseFloat(b.due_days)
  );

  return sorted;
};

/**
 *
 * @param nameKey : property of the array, used to find the value
 * @param myArray : array object
 * @returns boolean value
 */
export const searchFromArray = (nameKey?: any, myArray?: any) => {
  for (var i = 0; i < myArray.length; i++) {
    if (myArray[i].id === nameKey) {
      return true;
    }
  }
};

/**
 * scroll to bottom
 */
// export const scrollToPosition = () => {
//   setTimeout(function () {
//     window.scrollTo({
//       top: window.innerHeight + configs.OFFSET_LIMIT * configs.ONE_ROW_HEIGHT,
//       behavior: 'smooth',
//     });
//   }, 500);
// };

/**
 *
 * @param error : error message from api end
 * @param isOldTermAvailable : set parameter for old term available confirmation
 * @param isSameTermAvailable : set parameter for same term available confirmation
 * @returns : object with title, message and errortype
 */
export const handleTheTermModalErrorMessage = (
  error: any,
  isOldTermAvailable?: any,
  isSameTermAvailable?: any
) => {
  let errorObj: any = {
    title: null,
    message: null,
    type: 'warning',
  };

  // handle the default error message return from stripe__
  if (error) {
    errorObj = {
      ...errorObj,
      message: error,
      type: 'error',
    };
  }

  // handle warning message for old components__
  if (isOldTermAvailable) {
    errorObj = {
      ...errorObj,
      title: 'Cannot change the term',
      message: 'Invoice cannot have old category payment term',
    };
  }

  // handle warning message for if only one term similar to existing term__
  if (isSameTermAvailable) {
    errorObj = {
      ...errorObj,
      message: 'Cannot change the term',
    };
  }

  return errorObj;
};

/**
 *
 * @param terms
 */
export const getNetTerms = (payout_term_id: string) => {
  return payout_term_id === 'net30' ? 'Net 30+' : payout_term_id.split('net').join('Net ');
};

/**
 *
 * @param terms : terms details
 * @param e : input event
 * @param isAllSelect : select all flag
 * @param setTerms : set terms after alter the current object
 * @param setAllSelect check uncheck all
 */
export const handleTermsFilterChange = (
  terms: any,
  e: any,
  isAllSelect: any,
  setTerms: any,
  setAllSelect: any,
  setSelectedFilterOptions: any
) => {
  let isAllEnable = true;
  const newArr = terms.map((obj: any) => {
    let id = e?.target?.id;
    if (obj.id === id) {
      return { ...obj, active: e?.target?.checked };
    }
    if ((isAllSelect && !e?.target?.checked) || obj.active === false) {
      isAllEnable = false;
    }
    return obj;
  });
  setTerms(newArr);
  setAllSelect(isAllEnable);
  setFilterTermObj(newArr, setSelectedFilterOptions);
};

/**
 *
 * @param terms : terms details
 * @param e : input event
 * @param isAllSelect : select all flag
 * @param setTerms : set terms after alter the current object
 * @param setAllSelect check uncheck all
 */
export const handleAllSelectChange = (
  terms: any,
  isAllSelect: any,
  setTerms: any,
  setAllSelect: any,
  setSelectedFilterOptions: any
) => {
  setAllSelect(!isAllSelect);
  const newArr = terms.map((obj: any) => {
    return { ...obj, active: !isAllSelect };
    return obj;
  });
  setTerms(newArr);
  setFilterTermObj(newArr, setSelectedFilterOptions);
};

/**
 *
 * @param newArr
 * @param setSelectedFilterOptions
 */
export const setFilterTermObj = (newArr: [], setSelectedFilterOptions: any) => {
  const selectedTermIds = newArr?.filter((item: any) => item.active).map((o: any) => o.id);
  setSelectedFilterOptions({ payout_term_ids: selectedTermIds });
};

/**
 *
 * @param searchInProgress
 * @param existingInvicedData
 * @param submitData
 * @param setActiveAccordion
 * @param setActiveAccordionOldInv
 * @param searchText
 */
export const handleAccordion = (
  searchInProgress: any,
  existingInvicedData: any,
  submitData: any,
  setActiveAccordion: any,
  setActiveAccordionOldInv: any,
  searchText: string,
  activeAccordionOldInv: boolean,
  showMoreInprogressOld: boolean
) => {
  if (searchInProgress) {
    if (submitData?.length === 0 && existingInvicedData?.length === 0) {
      setActiveAccordion(false);
      setActiveAccordionOldInv(false);
    } else if (submitData?.length === 0 && existingInvicedData?.length !== 0) {
      setActiveAccordion(false);
      setActiveAccordionOldInv(true);
    } else if (submitData?.length !== 0 && existingInvicedData?.length === 0) {
      setActiveAccordion(true);
      setActiveAccordionOldInv(false);
    } else {
      setActiveAccordion(true);
      setActiveAccordionOldInv(true);
    }
  }
  if (searchText === '') {
    setActiveAccordion(true);
    setActiveAccordionOldInv(showMoreInprogressOld);
  }

  if (!searchInProgress && submitData?.length === 0 && existingInvicedData?.length !== 0) {
    setActiveAccordion(false);
    setActiveAccordionOldInv(true);
  }
};

/**
 *
 * @param terms
 */
export const getFilteredTerms = (terms: any) => {
  return terms.filter((item: any) => item.active && item.category === 'NEW');
};

/**
 * Check recipent details are added or not
 */

export const checkIsRecipentDetailsAreAddedOrNot = (data: any) => {
  let isAvailable = false;
  if (data) {
    const getStripeAccountMetadata = data?.stripe_account_metadata;
    const checkDetails =
      getStripeAccountMetadata?.check_recipient_details_address &&
      getStripeAccountMetadata?.check_recipient_details_city &&
      getStripeAccountMetadata?.check_recipient_details_name &&
      getStripeAccountMetadata?.check_recipient_details_state &&
      getStripeAccountMetadata?.check_recipient_details_zip;

    if (checkDetails) {
      isAvailable = true;
    }
  }

  return [isAvailable];
};

/**
 * Check recipent details are added or not
 */

export const checkIsAnyOfTheRecipentDetailsAreAddedOrNot = (data: any) => {
  let isAvailable = false;
  if (data) {
    const getStripeAccountMetadata = data?.stripe_account_metadata;
    const checkDetails =
      getStripeAccountMetadata?.check_recipient_details_address ||
      getStripeAccountMetadata?.check_recipient_details_city ||
      getStripeAccountMetadata?.check_recipient_details_name ||
      getStripeAccountMetadata?.check_recipient_details_state ||
      getStripeAccountMetadata?.check_recipient_details_zip;

    if (checkDetails) {
      isAvailable = true;
    }
  }

  return [isAvailable];
};
