import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const ConditionalTextWrapper = styled(Box)(
  ({ theme }) => `
    width: 100%;
    display: flex;
    margin: 0;

    p,
    a {
      font-weight: 300;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.15px;
      color: ${theme.palette.gray[800]};
      margin: 0;
    }

    a {
      text-decoration: underline;
    }
`
);
