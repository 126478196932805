import { styled } from '@mui/material/styles';

export const LimitsProgressCardBottom = styled('div')`
  display: flex;
  align-items: center;
`;

export const LimitsProgressCardIcon = styled('div')(
  ({ theme }) => `
 height: 48px;
 width: 48px;
 margin-right: ${theme.spacing(1.5)};
 display: inline-flex;
 align-items: center;

 img {
  height: 48px;
  width: 48px;
 }
`
);
