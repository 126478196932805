import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';
import classNames from 'classnames';

import { ModalTermEdit, ShadowCard } from 'src/components/base/core';
import { Typography } from '@mui/material';
import {
  TopRow,
  TagWrapper,
  AmountSection,
  SecondRow,
  BottomSection,
} from './InvoiceDetailTop.style';
import { InvoiceDetailProps } from 'src/models/component/base.model';
import moneyFormat from 'src/shared/utilities/moneyFormat';
import { getTermName, helperHandlingTerms } from 'src/helpers/helperHandlingTerms';
import {
  dateFormatMMMSpaceDD,
  dateFormatMMMSpaceDDYYYY,
  getCalculatedPayArrivalDate,
} from 'src/shared/utilities/dob';

import {
  GetCurrentInvoicesPayoutTerms,
  SetPayoutTerms,
} from 'src/services/payout-terms/payoutTerms.service';
import { useGetInvoiceDetailsMutation } from 'src/services/invoice/invoiceServiceApi';
import { handlePayModeDisplay } from 'src/shared/utilities/dataConvertions';
import { theme } from 'src/shared/theme/theme';

export const InvoiceDetailTop = ({
  invoiceDetails,
  payoutId,
  setInvoiceDetailsData,
  tabType,
  isDatesPendingInvoice = false,
}: InvoiceDetailProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [modalOpen, setModalOpen] = useState(false);
  const [terms, setTerms] = useState([]);
  const [serverErrors, setServerErrors]: any = useState('');
  const [getInvoiceDetails, isFetching]: any = useGetInvoiceDetailsMutation();
  // set feature flag __
  const { enablePay1181 } = useFlags();

  const [
    termsSetDetails,
    handleTermClick,
    handleTermChange,
    handleYesButtonClick,
    handleMultiplePaymentSelection,
  ] = helperHandlingTerms();

  const isTransactions = tabType === 'transactions';

  const initTerms = async () => {
    await GetCurrentInvoicesPayoutTerms(invoiceDetails?.amount, payoutId).then((response) => {
      setTerms(response?.data?.terms);
    });
  };

  useEffect(() => {
    if (!isTransactions) {
      initTerms();
    }
  }, []);

  const handleConfirmButtonClick = async () => {
    const selectedNetTerm = termsSetDetails.updatedTerm; // get the selected term

    setServerErrors('');
    // update the term against the payout id
    const res = await SetPayoutTerms(payoutId, selectedNetTerm);
    if (res?.data) {
      const retryData = await getInvoiceDetails({ payout_id: payoutId });
      setInvoiceDetailsData(retryData?.data);
      setModalOpen(false);
    } else {
      // @ts-ignore
      const responseError = res?.response.data.error ?? res?.response.data;
      setServerErrors(responseError);
    }
  };

  const handleModalClose = () => {
    setServerErrors('');
    setModalOpen(false);
  };

  const hasSystemNetTermUpdates = () => {
    let temp = false;
    invoiceDetails?.netTermChangeTimeline?.every((item: any) => {
      if (item?.updatedByUserType !== 'ACCOUNT') {
        temp = true;
        return false;
      }
      return true;
    });

    return temp;
  };

  // get the location states __
  const getLocationState: any = location.state;
  const isPaid = tabType === 'paid';

  const getBottomLineText = () => {
    if (isTransactions) {
      if (
        invoiceDetails?.invoice_lines?.length > 0 &&
        invoiceDetails?.invoice_lines[0]['po-number']
      ) {
        return `${invoiceDetails?.payup_transfer_number ?? 'N/A'} | ${
          invoiceDetails?.property?.name ?? invoiceDetails?.customer
        } | ${invoiceDetails?.invoice_lines[0]['po-number']}`;
      }

      return `${invoiceDetails?.payup_transfer_number ?? 'N/A'} | ${
        invoiceDetails?.property?.name ?? invoiceDetails?.customer
      }`;
    }

    if (
      invoiceDetails?.invoice_lines?.length > 0 &&
      invoiceDetails?.invoice_lines[0]['po-number']
    ) {
      return `${invoiceDetails?.coupa_invoice_number ?? 'N/A'} | ${invoiceDetails?.customer} | ${
        invoiceDetails?.invoice_lines[0]['po-number']
      }`;
    }

    return `${invoiceDetails?.coupa_invoice_number ?? 'N/A'} | ${invoiceDetails?.customer}`;
  };

  const getTagWrapperClass = () => {
    if (isTransactions) {
      return 'un_editable';
    }

    return hasSystemNetTermUpdates()
      ? 'warning'
      : getLocationState.tabType === 'paid'
      ? 'un_editable'
      : 'editable';
  };

  const getDateTextFirstPart = () => {
    if (isTransactions) {
      const EstdPayArrivalOrSettlementDate =
        invoiceDetails?.details?.payment_arrival_estimated_date ??
        invoiceDetails?.expected_settlement_date;

      const payArrivalDate = getCalculatedPayArrivalDate(EstdPayArrivalOrSettlementDate);
      const isPayArrivalDatePassed = moment(payArrivalDate).isBefore(moment());

      if (isPayArrivalDatePassed) {
        return t('invoiceSummary.top.arrivedOn');
      }

      return t('invoiceSummary.top.estdToArriveOn');
    }

    const isPayModeCheck = invoiceDetails?.method === 'check' ? true : false;
    const dateForCheckMailed = invoiceDetails?.check_mailed_on ?? null;
    const dateForCheckPaid = invoiceDetails?.check_paid_on ?? null;

    return isDatesPendingInvoice
      ? t('invoiceSummary.top.wasSubmittedOn')
      : isPaid
      ? isPayModeCheck
        ? dateForCheckPaid
          ? t('invoiceSummary.top.arrivedOn')
          : dateForCheckMailed
          ? t('invoiceSummary.top.mailedOn')
          : t('invoiceSummary.top.estdToArriveOn')
        : t('invoiceSummary.top.arrivedOn')
      : t('invoiceSummary.top.estdToArriveOn');
  };

  const getDateTextSecondPart = () => {
    if (isTransactions) {
      const EstdPayArrivalOrSettlementDate =
        invoiceDetails?.details?.payment_arrival_estimated_date ??
        invoiceDetails?.expected_settlement_date;

      const payArrivalDate = getCalculatedPayArrivalDate(EstdPayArrivalOrSettlementDate);
      return dateFormatMMMSpaceDDYYYY(payArrivalDate);
    }

    const isPayModeCheck = invoiceDetails?.method === 'check' ? true : false;
    const dateForCheckMailed = invoiceDetails?.check_mailed_on ?? null;
    const dateForCheckPaid = invoiceDetails?.check_paid_on ?? null;

    return isDatesPendingInvoice
      ? dateFormatMMMSpaceDDYYYY(invoiceDetails?.invoice_submitted_date)
      : isPaid
      ? isPayModeCheck
        ? dateForCheckPaid
          ? dateFormatMMMSpaceDDYYYY(dateForCheckPaid)
          : dateForCheckMailed
          ? dateFormatMMMSpaceDDYYYY(dateForCheckMailed)
          : dateFormatMMMSpaceDDYYYY(invoiceDetails?.payment_arrival_estimated_date)
        : dateFormatMMMSpaceDDYYYY(invoiceDetails?.paid_at_date)
      : dateFormatMMMSpaceDD(
          isPayModeCheck
            ? invoiceDetails?.payment_arrival_estimated_date
            : invoiceDetails?.estimated_payout_date
        );
  };

  const isBatchNumberAvailable =
    invoiceDetails && invoiceDetails?.batch_number !== '' && invoiceDetails?.batch_number !== null;

  return (
    <>
      <ShadowCard padding={6} marginBottom={4}>
        <TopRow>
          <AmountSection>
            <Typography variant="h3" color={'gray.900'}>
              $
            </Typography>
            <Typography variant="invAmount" color={'gray.900'}>
              {moneyFormat(
                isTransactions ? invoiceDetails?.paid_amount : invoiceDetails?.earnings
              )?.replace('$', '')}
            </Typography>
          </AmountSection>
          <TagWrapper
            className={classNames([
              getTagWrapperClass(),
              !enablePay1181 ? 'complete-disabled' : '',
            ])}
            data-id={'1234'}
            data-amount={invoiceDetails?.amount}
            data-term={invoiceDetails?.payment_term}
            onClick={isPaid || isTransactions ? () => {} : () => setModalOpen(true)}
          >
            {isTransactions
              ? `${invoiceDetails?.pay_mode ?? 'N/A'}`
              : invoiceDetails?.method === 'debit_card'
              ? 'INSTANT'
              : getTermName(invoiceDetails?.payment_term)}
          </TagWrapper>
        </TopRow>
        <BottomSection
          sx={{
            paddingBottom: 2,
          }}
        >
          <SecondRow>
            <Typography variant="h2" color={'gray.900'} sx={{ marginRight: 0.75 }}>
              {getDateTextFirstPart()}
            </Typography>
            <Typography variant="h2ExtraBold" color={'gray.900'}>
              {getDateTextSecondPart()}
            </Typography>
          </SecondRow>
          <Typography variant="h1Bold" color={'gray.500'} sx={{ marginTop: 1.5 }}>
            {getBottomLineText()}
          </Typography>
        </BottomSection>
        <BottomSection
          sx={{
            borderTop: `1px solid ${theme.palette.gray[100]}`,
            paddingTop: 2,
          }}
        >
          <SecondRow sx={{ justifyContent: 'space-between', marginBottom: 3, marginTop: 2 }}>
            <Typography variant="h2" color={'gray.900'}>
              {t('invoiceSummary.top.parentBatchText')}
            </Typography>
            {isBatchNumberAvailable ? (
              <TagWrapper className={'complete-disabled un_editable'} onClick={() => {}}>
                {`${invoiceDetails?.batch_number}`}
              </TagWrapper>
            ) : (
              <Typography variant="h2Bold" component={'p'} color={'gray.500'}>
                {'----'}
              </Typography>
            )}
          </SecondRow>

          <SecondRow sx={{ justifyContent: 'space-between' }}>
            <Typography variant="h2" color={'gray.900'}>
              {t('invoiceSummary.top.batchMethodText')}
            </Typography>
            <TagWrapper className={'complete-disabled un_editable'} onClick={() => {}}>
              {handlePayModeDisplay(invoiceDetails?.method)}
            </TagWrapper>
          </SecondRow>
        </BottomSection>
      </ShadowCard>

      {modalOpen && terms && enablePay1181 && (
        <ModalTermEdit
          onModalClose={handleModalClose}
          onTermChange={handleTermChange}
          isConfirmButtonDisable={
            termsSetDetails.initialLoadingTerm === termsSetDetails.updatedTerm
          }
          options={{ terms: terms }}
          onConfirmButtonClick={handleConfirmButtonClick}
          isConfirmed={termsSetDetails.showConfirmModal}
          updatedTerm={termsSetDetails.updatedTerm}
          selectedTerm={invoiceDetails?.payment_term}
          error={serverErrors}
          onMessageClose={() => setServerErrors('')}
        />
      )}
    </>
  );
};
