import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Header,
  HeaderFixed,
  WrpperAuthorizedContainer,
  Wrapper,
  TransactionCard,
  MainPayupCard,
  TransactionCardEmpty,
} from '../../../../components/base/core';
import { Box } from '@mui/material';

import {
  useGetTransactionDetailsQuery,
  useGetTreasuryTransactionsQuery,
} from 'src/services/account/accountServiceApi';

import { EmptyStates } from 'src/shared/utilities/images';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';

export const FinanceAccountMainContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading } = useGetTreasuryTransactionsQuery(null);
  const {
    data: tdData,
    isSuccess: isSuccessTD,
    isLoading: isLoadingTD,
  } = useGetTransactionDetailsQuery(null);

  return (
    <>
      {isLoading && isLoadingTD && <ManualLoader />}
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isSkipButtonHide
          isBackButtonHide
          paddingX={0}
          headerPaddingBottom={0}
          isLogoOnlyForDesktop
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper>
        <WrpperAuthorizedContainer>
          <Box>
            <MainPayupCard
              title={t('finance.mainScreen.cardTitle')}
              tdData={tdData}
              isSuccessTD={isSuccessTD}
              navigate={navigate}
              buttonTexts={{
                moveMoney: t('finance.mainScreen.btnTextMoveMoney'),
                manageCard: t('finance.mainScreen.btnTextmanageCard'),
              }}
              cardTexts={{
                balanceSummaryTitle: t('finance.mainScreen.balanceSummaryTitle'),
                cardShowNumberText: t('finance.mainScreen.cardShowNumberText'),
              }}
            />
          </Box>
          <Box>
            {data && data.length > 0 && (
              <TransactionCard
                data={data}
                title={t('finance.mainScreen.recentTransaction.title')}
                onClick={() => navigate('/finance/account/list')}
                clickOnCard={() => navigate('/finance/account/list')}
                viewAllLinkText={t('finance.mainScreen.recentTransaction.viewAllLinkText')}
                isRecentTransaction={true}
              />
            )}

            {data && data.length === 0 && (
              <TransactionCardEmpty
                title={t('finance.mainScreen.recentTransaction.title')}
                imagePath={EmptyStates.aprroved}
                emptyStateTitle={t('finance.mainScreen.recentTransaction.emptyStateTitle')}
                emptyStateContent={t('finance.mainScreen.recentTransaction.emptyStateContent')}
              />
            )}
          </Box>
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
