import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { LoaderWrapper } from './Loader.style';

import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { CircularProgress } from '@mui/material';
import { store } from 'src/store';
import { ActionType } from 'src/store/action-types';

const domNode: any = document.getElementById('app-root');

const dispatchLoggingIn = (v: boolean) => {
  store.dispatch({
    type: ActionType.LOGGING_IN,
    payload: v,
  });
};

export const Loader = () => {
  const ajaxLoading = useSelector((state: RootState) => state.ajaxLoading);
  const userDetails = useSelector((state: RootState) => state.userDetails);

  useEffect(() => {
    if (userDetails?.isLoggingIn) {
      setTimeout(function () {
        if (userDetails?.isLoggingIn) {
          dispatchLoggingIn(false);
        }
      }, 6000);
    }
  }, [userDetails]);

  return ReactDOM.createPortal(
    (ajaxLoading || userDetails?.isLoggingIn) && (
      <LoaderWrapper>
        <CircularProgress color="primary" size={'60px'} />
      </LoaderWrapper>
    ),
    domNode
  );
};
