import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Wrapper,
  HeadingSummaryBlock,
  DivWrapper,
  LottieAnimation,
  ContentContainerBottom,
} from 'src/components/base/core';
import { Lotties } from 'src/shared/utilities/lottieJson';
import { Box, Button } from '@mui/material';
import { getUpdateRouteObj, routeType } from 'src/helpers/containers/helperRoutePreference';
import { useUpdatePreferenceMutation } from 'src/services/account/account.preferenceApi';

export const AccountApprovedContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [updatePreference] = useUpdatePreferenceMutation();

  const updatePreferenceOnServer = async (type: any, step: any = null) => {
    const obj = getUpdateRouteObj(type, step);
    await updatePreference({ data: obj });
  };

  useEffect(() => {
    localStorage.removeItem('enable_holdon');
    localStorage.removeItem('enable_account_edit');
    localStorage.removeItem('backFromReview');
    localStorage.removeItem('menuNav');
  }, []);
  const handleDoneButtonClick = () => {
    localStorage.removeItem('approved-account');
    localStorage.setItem('early-payout', 'true');
    updatePreferenceOnServer(routeType.EARLY_PAYOUT);
    navigate('/early-payout');
    return;
  };

  return (
    <Wrapper>
      <DivWrapper className="no_fullscreen">
        <Box component={'div'} className={'container_content no_header'}>
          <Box component={'div'} className={'container_content_top add_bottom_padding'}>
            <div className="status_animation">
              <LottieAnimation json={Lotties.APPROVED} loop={false} />
            </div>
            <div className="content_block">
              <HeadingSummaryBlock
                heading={t('headingContent.statusses.approved.heading')}
                headingAlignment="center"
                content={t('headingContent.statusses.approved.content')}
                contentAlignment="center"
              />
            </div>
          </Box>
        </Box>
        <ContentContainerBottom className={'no_fullscreen'}>
          <Button
            variant="containedLarge"
            color="primary"
            type="button"
            fullWidth
            onClick={handleDoneButtonClick}
            sx={{ overflow: 'hidden' }}
          >
            {t('buttonTexts.followNextStepButton')}
          </Button>
        </ContentContainerBottom>
      </DivWrapper>
    </Wrapper>
  );
};
