import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { prepareBaseQuery } from '../../shared/config/config';

export const configApi = createApi({
  reducerPath: 'configApi',
  tagTypes: ['Config'],
  baseQuery: prepareBaseQuery(false),
  endpoints: (builder) => ({
    getConfig: builder.query({
      query: (phone: string) => ({
        url: '/config',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetConfigQuery } = configApi;
