export const enFinanceTranslation = {
  meetSoon: 'Meet Soon Your',
  payupCard: 'PayUp Card',
  btnText: 'Notify Me',
  messageText: 'We will notify you on our next round of invitations',
  financeText:
    ' Your own PayUp finance account allows to get payments even more convenient and faster along with tracking your spendings.',
  splashSlider: {
    slide1: {
      heading: 'Get Paid On Payday',
      content: 'Recive your payments on time. No waiting for business days to access your funds.',
    },
    slide2: {
      heading: 'Move Money Easily',
      content: 'Easy earn, easy spend. Move your money instantly.',
    },
    slide3: {
      heading: 'Track Financial History',
      content: 'Track your spendings and earnings. Get access to account statistics.',
    },
    slide4: {
      heading: 'Your Personal Account',
      content: 'Manage your revenue, taxes, control and analyse your budget.',
    },
    button: 'Get Finance Account',
    messages: {
      title: 'Create Finance Account',
    },
  },
  splashListView: {
    headingContent1: 'PayUp Finance Account.',
    headingContent2: 'Your Convenient Payments',
    button: 'Get Free Finance Account',
    mainViewButton: 'Get Finance Account',
  },
  announcementScreen: {
    heading: 'PayUp Finance Account',
    content:
      'Your own PayUp finance account allows to get payments even more convenient and faster along with tracking your spendings.',
    button: 'Enable PayUp Finance Account',
    infoNote:
      'Oops! You need to complete verification and have a bank account linked to move forward.',
  },
  accountCreation: {
    heading: 'Congrats!',
    content: 'Your PayUp finance account was set up! Start using your virtual card right away.',
    button: 'Go To Account',
  },
  accountLoading: {
    content: 'Preparing  PayUp Finance Account',
  },
  mainScreen: {
    cardTitle: 'PayUp Card',
    balanceSummaryTitle: 'Current Balance',
    cardShowNumberText: 'Show number',
    btnTextMoveMoney: 'Move Money',
    btnTextmanageCard: 'Manage Card',
    recentTransaction: {
      title: 'RECENT TRANSACTIONS',
      emptyStateTitle: 'No transactions yet',
      emptyStateContent: 'Your most recent transactions will appear here.',
      viewAllLinkText: 'View All',
    },
  },
  transaction: {
    details: {
      termsNote: 'If you have question about transaction contact us for support at',
      summaryCard: {
        heading: 'Details',
        viewRecipetLink: 'View Transaction Receipt',
        type: 'Type',
        account: 'Account',
        routing: 'Routing',
        method: 'Method',
        transactionID: 'Transaction ID',
        description: 'Description',
        destination: 'Destination',
      },
    },
    list: {
      cardHeading: 'TRANSACTIONS LIST',
      emptyStateTitle: 'No transactions yet',
      emptyStateContent: 'Your transactions will appear here.',
    },
    tabNavs: {
      filter: 'Filter',
      tab1: 'Earned',
      tab2: 'Spent',
    },
  },
  FinancesTermsModal: {
    heading: 'Terms of Service',
    content:
      'By clicking Accept, you agree to the below documents and to receiving automated text messages from Stripe.',
    financeTermsContent: {
      link1: 'Card Services Agreement',
      link2: 'Payout Card Apple Pay Terms and Conditions',
      link3: 'Stripe Services Agreement and E-SIGN',
      link4: 'Commercial Account Agreement',
    },
    footerNote:
      'Card products are issued by Sutton Bank, Member FDIC and serviced by Stripe, Inc. and its affiliate Stripe Servicing, Inc. Services provided by Stripe Payments Company with funds held at Evolve Bank & Trust, Member FDIC.',
  },
  MoveMoney: {
    headerText: 'Move Money',
    landing: {
      topContent: 'Select where do you want to send money?',
      linkedAccountHeading: 'My Accounts',
      linkedAccountLink: 'Link New Account',
      linkedAccountEmptyBlockContent: 'Create your first Linked Account.',
      payeeAccountHeading: 'Payees',
      payeeAccountLink: 'Add New Payee',
      payeeAccountEmptyBlockContent: 'Add your first payee to start sending them funds.',
    },
    addPayeeAccountModal: {
      modalTitle: 'Add New Account',
    },
    form: {
      title: 'Transfer  Details',
    },
    financeMoveMoneySummaryCard: {
      topTitle: 'Amount',
      fromText: 'From',
      walletText: 'PayUp Wallet',
      toText: 'To',
      accountText: 'Account',
    },
    summary: {
      button: 'Confirm Payment',
      successModal: {
        heading: 'Success',
        prefixContent: 'Your',
        postFixContent:
          'payment has been sent. You can expect this payment to arrive in 1-2 business days.',
      },
    },
    financeMoveMoneySummaryDetailsCard: {
      heading: 'Transaction Details',
      type: 'Type',
      date: 'Date',
      method: 'Method',
      methodContent: 'Business days',
      notes: 'Notes',
    },
  },
  manageCard: {
    navigation: {
      title: 'Manage card',
      link1: {
        content: 'Account Details',
        subContent: 'View your account informations',
      },
      link2: {
        content: 'Limits',
        subContent: 'View your spending & withdrawal limits',
      },
      link3: {
        content: 'Add to Apple Wallet or Google Pay',
        subContent: 'Card has not added',
      },
      link4: {
        content: 'Order Physical Card',
      },
      link5: {
        content: 'Set Up Pin Code',
        subContent: 'For your physical card',
      },
      link6: {
        content: 'Freeze Card',
        elseContent: 'Unfreeze Card',
        subContent: 'Freeze/unfreeze your card based on needs',
      },
      link7: {
        content: 'Cancel Account',
      },
    },
    manageVirtualCard: {
      title: 'PayUp Card',
      totalLimit: 'Total limit',
      spentLimit: 'Spent limit',
      showNumber: 'Show number',
    },
    cardAccountInfoModal: {
      title: 'Account Details',
      dataRow1: 'Account Number',
      dataRow2: 'Routing Number',
      dataRow3: 'Account Holder Name',
    },
    manageCardLimitsModal: {
      title: 'Limits',
      cardTitle: 'Current Limit',
      progressBlock: {
        title: 'Card Spendings',
        totalLimit: 'Available',
        spentLimit: 'Spent',
      },
    },
    freezeCardModal: {
      title: 'Freeze / Unfreeze Card',
      formSwitch: 'Freeze / Unfreeze your card',
    },
  },
};
