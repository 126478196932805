import { useNavigate } from 'react-router-dom';
import { Separator, TdContainer } from '../PaymentDataTable.style';

import { handlePayoutDate } from '../../../../../../../../helpers/helperHandlingTableGrid';

export const FirstCell = (props: any) => {
  const navigate = useNavigate();
  const getPropsOriginalData = props?.props?.row?.original;
  const { customer, coupa_invoice_number, id, customTitle } = getPropsOriginalData;

  const isTransactionGridType = props?.props?.isTransactionGrid ? true : false;

  const date = handlePayoutDate(getPropsOriginalData, props?.props?.tabType, isTransactionGridType);
  const contentText = isTransactionGridType ? customTitle : customer.business_name;
  const contentTextLength = `${contentText} | ${coupa_invoice_number ? coupa_invoice_number : ''}`
    .length;

  let customTabType = props?.props?.tabType;

  if (isTransactionGridType) {
    customTabType = 'paid';
  }

  return (
    <TdContainer>
      <span
        className={`first-link-text-${customTabType}`}
        onClick={() =>
          isTransactionGridType
            ? navigate(`/batch/${id}`)
            : navigate(`/payout/${id}`, {
                state: { tabType: props?.props?.tabType, payout_id: id },
              })
        }
      >
        <span className={`link-to-summary-${customTabType}`}>
          {contentText}
          {!isTransactionGridType && coupa_invoice_number && <Separator />}
          {!isTransactionGridType && (
            <span
              className={
                contentTextLength > 24 ? 'long-invoice-number-wrapper' : 'invoice-number-wrapper'
              }
            >
              {coupa_invoice_number}
            </span>
          )}
        </span>
      </span>
      {/* {property && (
        <span className="property-location">{`${property?.name} ${property?.city} ${property?.state}`}</span>
      )} */}
      <span
        onClick={() =>
          isTransactionGridType
            ? navigate(`/batch/${id}`)
            : navigate(`/payout/${id}`, {
                state: { tabType: props?.props?.tabType, payout_id: id },
              })
        }
        className="date_val hidden-desktop"
      >
        {date}
      </span>
    </TdContainer>
  );
};
