import { useNavigate } from 'react-router-dom';

import { BatchesTabIcon, InvoicesIcon } from '../../Media/Icon/Icons/Icons';
import {
  InvoicesBatchesNavigationWrapper,
  InvoicesBatchesNavigationButton,
} from './InvoicesBatchesNavigation.style';
import { InvoicesBatchesNavigationProps } from 'src/models/component/base.model';

export const InvoicesBatchesNavigation = ({
  tabType = 'submitted',
  isInvoicesActive = true,
  isTextsAlwaysVisible = false,
  t,
}: InvoicesBatchesNavigationProps) => {
  const navigate = useNavigate();

  return (
    <InvoicesBatchesNavigationWrapper>
      <InvoicesBatchesNavigationButton
        variant={!isInvoicesActive ? 'contained' : 'outlined'}
        className={`button_batches ${isTextsAlwaysVisible ? 'text-visible' : ''}`}
        sx={{
          pointerEvents: !isInvoicesActive ? 'none' : 'auto',
        }}
        onClick={!isInvoicesActive ? () => {} : () => navigate(`/batches/${tabType}`)}
      >
        <BatchesTabIcon />
        <span>{t('batches.invoicesBatchesNavigationButton.batchesButton')}</span>
      </InvoicesBatchesNavigationButton>
      <InvoicesBatchesNavigationButton
        variant={isInvoicesActive ? 'contained' : 'outlined'}
        className={`button_invoices ${isTextsAlwaysVisible ? 'text-visible' : ''}`}
        sx={{
          pointerEvents: isInvoicesActive ? 'none' : 'auto',
        }}
        onClick={isInvoicesActive ? () => {} : () => navigate(`/earnings/${tabType}`)}
      >
        <InvoicesIcon height={'17'} width="14" fillColor="currentColor" />
        <span>{t('batches.invoicesBatchesNavigationButton.invoicesButton')}</span>
      </InvoicesBatchesNavigationButton>
    </InvoicesBatchesNavigationWrapper>
  );
};
