import { UserDetailsInterface } from 'src/shared/utilities/interface';
import { ActionType } from '../action-types/index';
import { Action } from '../actions';

const initialState = { first_name: '', last_name: '', email: '', isLoggingIn: false };

const reducer = (state = initialState, action: Action): UserDetailsInterface => {
  switch (action.type) {
    case ActionType.USER_DETAILS:
      return action.payload ? { ...action.payload, isLoggingIn: state.isLoggingIn } : state;
    case ActionType.LOGGING_IN:
      return { ...state, isLoggingIn: action.payload };
    default:
      return state;
  }
};

export default reducer;
