import { configs } from 'src/shared/config/config';
import { getCookie } from 'src/shared/utilities/cookiesHelper';
import { decryptWithAES } from 'src/shared/utilities/encryptHelpper';
import { maskedInput } from '../shared/utilities/inputHandlers';

/*individual  init details*/
export const setCoupaInfoValues = (setValue: Function): string => {
  const prefilData = decryptWithAES(getCookie('coupaObj'), configs.ENCRYPT_PHRESE) as string;
  const data = prefilData && JSON.parse(prefilData);
  {
    data.name !== null && setValue('first_name', data.name);
  }
  {
    data.lastName !== null && setValue('last_name', data.lastName);
  }
  {
    data.email !== null && setValue('email', data.email);
  }
  {
    data.phone !== null && setValue('phone_number', maskedInput(data.phone));
  }
  {
    data.phone !== null && setValue('inputCompanyPhone', maskedInput(data.phone));
  }
  return data?.id;
};

export const setCoupaInfoValuesForEmailLogin = (
  setValue: Function,
  isBusinessEdit?: boolean
): string => {
  const prefilData = decryptWithAES(getCookie('coupaObj'), configs.ENCRYPT_PHRESE) as string;
  const data = prefilData && JSON.parse(prefilData);
  {
    data.name !== null && setValue('first_name', data.name);
  }
  {
    data.lastName !== null && setValue('last_name', data.lastName);
  }
  {
    data.email !== null && setValue('email', data.email);
  }
  {
    data.phone !== null && !isBusinessEdit && setValue('phone_number', data.phone);
  }
  {
    data.phone !== null && setValue('inputCompanyPhone', data.phone);
  }
  {
    data.name !== null && setValue('full_name', data.name + ' ' + data.lastName);
  }
  return data?.id;
};

/*individual  init details*/
export const setCoupaInfoPhone = (setValue: Function): string => {
  const prefilData = decryptWithAES(getCookie('coupaObj'), configs.ENCRYPT_PHRESE) as string;
  const data = prefilData && JSON.parse(prefilData);
  {
    data.phone !== null && setValue('inputCompanyPhone', maskedInput(data.phone));
  }
  return data?.id;
};

export const setCoupaInfoBusinessDetails = (setValue: Function): string => {
  const prefilData = decryptWithAES(getCookie('coupaObj'), configs.ENCRYPT_PHRESE) as string;
  const data = prefilData && JSON.parse(prefilData);
  {
    data.phone !== null && setValue('inputCompanyPhone', data.phone);
  }
  {
    data.businessName !== null && setValue('inputCompanyName', data.businessName);
  }
  return data?.id;
};

/*individual  address details*/
export const setCoupaAddress = (setValue: Function): string => {
  const prefilData = decryptWithAES(getCookie('coupaObj'), configs.ENCRYPT_PHRESE) as string;
  const data = prefilData && JSON.parse(prefilData);
  if (data) {
    {
      data.street1 && setValue('line1', data.street1);
    }
    {
      data.street2 && setValue('line2', data.street2);
    }
    {
      data.city && setValue('city', data.city);
    }
    {
      data.postal_code && setValue('postal_code', data.postal_code);
    }
    if (data.state) {
      setValue('state', data.state);
    }
  }
  return data?.state;
};

/*business address details*/

export const setCoupaBusinessAddress = (setValue: Function): { state: string; id: string } => {
  const prefilData = decryptWithAES(getCookie('coupaObj'), configs.ENCRYPT_PHRESE) as string;
  const data = prefilData && JSON.parse(prefilData);
  if (data) {
    setValue('inputComLine1', data.street1);
    setValue('inputComLine2', data.street2);
    setValue('inputComCity', data.city);
    setValue('inputComPostal', data.postal_code);
    setValue('inputCompanyPhone', maskedInput(data.phone));
    if (data.state) {
      setValue('inputComState', data.state);
    }
  }
  return { state: data?.state, id: data?.id };
};
