import { useTranslation } from 'react-i18next';

import { Logo, Progressbar, LanguageSwitcher } from '../..';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Button, Typography } from '@mui/material';
import { Stack, SxProps, Theme } from '@mui/system';

import { HeaderProps } from 'src/models/component/base.model';
import { BackIcon, HeaderTextBlock, InvoiceDownLoadLink } from './Header.style';
import { MainMenu } from '../../Navigations/MainMenu/MainMenu';
import { theme } from 'src/shared/theme/theme';
import { LargeIcons } from 'src/shared/utilities/images';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { hideTabsOnNativeApp } from 'src/shared/config/config';

export const Header = ({
  isLogoClickable,
  logoMarginIndex,
  totalProgressSteps,
  currentProgressStep,
  isBackButtonVisible = false,
  isSkipButtonVisible = false,
  isProgressBarVisible = false,
  isLogoLeftAligned = false,
  onBackButtonClick,
  onSkipButtonClick,
  headerHeight = 96,
  paddingX = 3,
  backButtonId,
  isLogoVisible = true,
  isLanguageSwitcher = false,
  isMainMunuVisible = false,
  isBackButtonHide = false,
  isSkipButtonHide = false,
  headerText,
  isInvoiceDownLoadLink = false,
  withCenteredHeaderText = false,
  invoiceDownloadLink = '#',
  isLogoOnlyForDesktop,
  headerPaddingTop = 6,
  headerPaddingBottom = 8,
}: HeaderProps) => {
  const { t } = useTranslation();
  const isOnNativeApp = useSelector((state: RootState) => state.appState);

  const getSxForHeaderText = (): SxProps<Theme> | undefined => {
    if (withCenteredHeaderText || isInvoiceDownLoadLink) {
      return {
        position: 'absolute',
        top: 24,
        left: 56,
        right: 56,
        textAlign: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
      };
    }

    return undefined;
  };

  return (
    <Stack paddingX={paddingX} paddingTop={headerPaddingTop} paddingBottom={headerPaddingBottom}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        display="flex"
        marginBottom={isProgressBarVisible ? 4 : 0}
      >
        {!isLogoLeftAligned && (
          <BackIcon
            sx={{
              visibility: isBackButtonVisible ? 'visible' : 'hidden',
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                display: isBackButtonHide ? 'none' : 'inline-flex',
              },
            }}
            onClick={onBackButtonClick}
          >
            <ArrowBackIosNewIcon sx={{ height: 18 }} id={backButtonId} />
          </BackIcon>
        )}
        {isLogoVisible && !headerText && (
          <Logo
            isLogoClickable={isLogoClickable}
            logoMarginIndex={logoMarginIndex}
            isLogoOnlyForDesktop={isLogoOnlyForDesktop}
          />
        )}

        {headerText && (
          <HeaderTextBlock>
            <Logo isLogoClickable={isLogoClickable} logoMarginIndex={logoMarginIndex} />
            <Typography variant="h4ExtraBold" className="heading_content" sx={getSxForHeaderText()}>
              {headerText}
            </Typography>
          </HeaderTextBlock>
        )}

        {!isLanguageSwitcher && (
          <Button
            variant="text"
            sx={{
              visibility: isSkipButtonVisible ? 'visible' : 'hidden',
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                display: isSkipButtonHide ? 'none' : 'inline-flex',
              },
            }}
            onClick={onSkipButtonClick}
          >
            {t('linkTexts.skipLink')}
          </Button>
        )}
        {isLanguageSwitcher && <LanguageSwitcher />}
        {isMainMunuVisible && !(isOnNativeApp && hideTabsOnNativeApp) && <MainMenu />}
        {isInvoiceDownLoadLink && (
          <InvoiceDownLoadLink href={invoiceDownloadLink}>
            <img src={LargeIcons.invoiceDownload} alt="Invoice Download icon" />
          </InvoiceDownLoadLink>
        )}
      </Stack>
      {isProgressBarVisible && (
        <Progressbar
          totalProgressSteps={totalProgressSteps}
          currentProgressStep={currentProgressStep}
        />
      )}
    </Stack>
  );
};
