import { SwitchProps, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IOSSwitch, StylesFormControlLabel } from './FormSwitch.style';
import { FormRadioButton } from 'src/components/base/core/FormElements/FormRadioButton/FormRadioButton';
import { useState } from 'react';

export interface FormSwitchProps extends SwitchProps {
  label?: any;
  onChange?: any;
  isDigest?: boolean;
  isWeeklySelected?: boolean;
  onRadioChange?: (isWeekly: boolean) => void;
}

export const FormSwitch = ({
  label,
  defaultChecked,
  disabled,
  onChange,
  isDigest = false,
  isWeeklySelected = false,
  onRadioChange,
}: FormSwitchProps) => {
  const { t } = useTranslation();
  const [isActive, setActive] = useState(defaultChecked);
  const [isWeekly, setWeekly] = useState(isWeeklySelected);

  return (
    <div>
      <StylesFormControlLabel
        // @ts-ignore
        includechilds={isDigest ? 'true' : 'false'}
        control={<IOSSwitch defaultChecked={defaultChecked} />}
        onChange={(e) => {
          onChange(e);
          // @ts-ignore
          setActive(e.target.checked);
        }}
        disabled={disabled}
        label={
          <Typography variant="h2" color={'gray.700'}>
            {label}
          </Typography>
        }
        labelPlacement="start"
      />
      {isDigest && (
        <Box
          flexDirection={'row'}
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            marginBottom: 1,
            marginLeft: 1,
          }}
        >
          <FormRadioButton
            label={t('notifications.modal.notificationPrefs.weekly')}
            id={'weekly'}
            checked={isActive && isWeekly}
            handleChange={() => {
              if (isActive) {
                setWeekly(true);
                onRadioChange && onRadioChange(true);
              }
            }}
          />
          <FormRadioButton
            label={t('notifications.modal.notificationPrefs.daily')}
            id={'daily'}
            checked={isActive && !isWeekly}
            handleChange={() => {
              if (isActive) {
                setWeekly(false);
                onRadioChange && onRadioChange(false);
              }
            }}
          />
        </Box>
      )}
    </div>
  );
};
