import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Wrapper, FormWrapper, Header, IdDocumentModal } from 'src/components/base/core';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import {
  useUpdateAccountMutation,
  useGetAccountQuery,
} from 'src/services/account/accountServiceApi';
import { handleIdModalVisibility } from 'src/helpers/containers/helperIdDocumentModal';
import { routeType } from 'src/helpers/containers/helperRoutePreference';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';

type LocationState = {
  isFromSettings: boolean;
  isFromFinance: boolean;
};

export const VerificationFailedContinueToIDContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = useGetAccountQuery(null);
  const [isLoading, setIsLoading] = useState(false);
  const [updateAccount] = useUpdateAccountMutation();
  const [openIdModal, onClickIdModalOpen, onClickIdModalClose] = handleIdModalVisibility();
  const { register, handleSubmit, setValue } = useForm<any>();
  const [updatePreferenceOnServer] = handlePreferences();

  const { isFromSettings, isFromFinance } = location.state
    ? (location.state as LocationState)
    : { isFromSettings: false, isFromFinance: false };

  const onAppFormSubmit = handleSubmit(async (data: any) => {
    setIsLoading(true);

    const updatedData = {
      individual: {
        verification: {
          document: {
            ...data,
          },
        },
      },
      tos_shown_and_accepted: true,
    };

    const accountUpdateResponse: any = await updateAccount(updatedData);

    if (!isFromSettings) {
      localStorage.setItem('enable_holdon', 'true');
      localStorage.removeItem('first_time_9_digit_ssn_added');
      localStorage.removeItem('set_id_modal_states');
      navigate('/hold-on', { state: { isFromFinance: isFromFinance } });
    } else {
      navigate('/settings/hold-on');
    }
  });

  const handleBackButton = () => {
    if (!isFromSettings) {
      updatePreferenceOnServer(routeType.VERIFICATION_FAIL_SSN);
      navigate('/ssn-verification-failed', { state: { isFromFinance: isFromFinance } });
    } else {
      navigate('/settings/account-info');
    }
  };

  return (
    <>
      {isLoading && <ManualLoader />}
      <Wrapper>
        <FormWrapper onSubmit={onAppFormSubmit} className="no_fullscreen">
          <Header
            isBackButtonVisible={true}
            onBackButtonClick={handleBackButton}
            isLogoVisible={false}
          />
          <IdDocumentModal
            setValue={setValue}
            register={register}
            verificationData={data && data.individual.verification}
            isSubmittedValuesHeaderVisible={false}
            isFormButtonBlock={true}
            onClickIdModalOpen={onClickIdModalOpen}
            openIdModal={openIdModal}
            closeIdModalEvent={onClickIdModalClose}
          />
        </FormWrapper>
      </Wrapper>
    </>
  );
};
