import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Wrapper } from 'src/components/base/core';
import { FormSSNSubmition } from './FormSSNSubmition/FormSSNSubmition';
import { FormDOBSubmition } from './FormDOBSubmition/FormDOBSubmition';
import { FormAddressSubmition } from './FormAddressSubmition/FormAddressSubmition';
import { useGetAccountQuery } from 'src/services/account/accountServiceApi';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { routeType } from 'src/helpers/containers/helperRoutePreference';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';

type LocationState = {
  isBack?: boolean;
  step?: string;
  backFrom?: string;
};

export const IdentityVerificationContainer = () => {
  // constatnt with initialstates
  const initalState = {
    step1: true,
    step2: false,
    step3: false,
  };

  const navigate = useNavigate();
  const location = useLocation();

  const [accountData, setAccountData]: any = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [stepForward, setStepForward] = useState('');
  const [stepVisibility, setStepVisibility]: any = useState(initalState);

  const { data } = useGetAccountQuery('');
  const [updatePreferenceOnServer] = handlePreferences();

  const { isBack, step, backFrom } =
    location?.state != null
      ? (location?.state as LocationState)
      : { isBack: null, step: 'step1', backFrom: '' };

  // get account data in the inital render __
  useEffect(() => {
    if (data) {
      setAccountData(data);
    }
  }, [data]);

  // set step visibility in the initial render __
  useEffect(() => {
    if (accountData && accountData?.charges_enabled) {
      // check accountData not-null and charges_enabled = false
      if (stepForward === 'step2') {
        setStepVisibility({ step1: false, step2: false, step3: true });
      } else if (backFrom === 'review' && isBack) {
        setStepVisibility({ step1: false, step2: false, step3: true });
      } else {
        setStepVisibility({ step1: false, step2: true, step3: false });
      }
    } else if (accountData && step && stepForward === '') {
      switch (step) {
        case 'step1':
          setStepVisibility({ step1: true, step2: false, step3: false });
          break;
        case 'step2':
          setStepVisibility({ step1: false, step2: true, step3: false });
          break;
        case 'step3':
          setStepVisibility({ step1: false, step2: false, step3: true });
          break;
        default:
          setStepVisibility({ step1: true, step2: false, step3: false });
      }
    }
  }, [accountData]);

  // create function to click first-step form sumition click __
  const handleFirstStepClick = async () => {
    !(backFrom === 'account' || backFrom === 'review') &&
      updatePreferenceOnServer(routeType.IDENTITY_VERIFICATION, 'step2');
    setStepForward('step1');
    setStepVisibility({ step1: false, step2: true, step3: false });
  };

  // create function to click second-step form sumition click __
  const handleSecondStepClick = async () => {
    !(backFrom === 'account' || backFrom === 'review') &&
      updatePreferenceOnServer(routeType.IDENTITY_VERIFICATION, 'step3');
    setStepForward('step2');
    setStepVisibility({ step1: false, step2: false, step3: true });
  };

  // create function to click third-step form sumition click __
  const handleThirdStepClick = async () => {
    setStepForward('step3');
    !(backFrom === 'account' || backFrom === 'review') &&
      updatePreferenceOnServer(routeType.INDIVIDUAL_REVIEW);
    navigate('/individual-review');
  };

  // handle back button click on each screen __
  const handleBackButtonClick = async () => {
    if (
      stepVisibility.step1 ||
      (backFrom === 'review' && isBack && accountData?.charges_enabled && stepVisibility.step2)
    ) {
      navigate('/review-info', { state: { isBack: true, backFrom: backFrom } });
    }
    if (stepVisibility.step2 && backFrom === 'account') {
      navigate('/review-info', { state: { isBack: true, backFrom: backFrom } });
    }
    if (stepVisibility.step2) {
      setStepVisibility({ ...initalState });
    }
    if (stepVisibility.step3) {
      setStepVisibility({ step1: false, step2: true, step3: false });
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <ManualLoader />}
      <Wrapper>
        {stepVisibility.step1 && (
          <FormSSNSubmition
            onBackButtonClick={handleBackButtonClick}
            onFormSubmit={handleFirstStepClick}
            accountData={accountData}
            setAccountData={setAccountData}
          />
        )}

        {stepVisibility.step2 && (
          <FormDOBSubmition
            onBackButtonClick={handleBackButtonClick}
            onFormSubmit={handleSecondStepClick}
            accountData={accountData}
            setAccountData={setAccountData}
            pageFromBack={backFrom}
          />
        )}

        {stepVisibility.step3 && (
          <FormAddressSubmition
            onBackButtonClick={handleBackButtonClick}
            onFormSubmit={handleThirdStepClick}
            accountData={accountData}
            setAccountData={setAccountData}
          />
        )}
      </Wrapper>
    </>
  );
};
