import { styled } from '@mui/material/styles';

export const CustomerCardWrapper = styled('label')(
  ({ theme }) => `
 display: flex;
 width: 100%;
 align-items: center;
 position: relative;

 input {
  position: absolute;
  left: -10px;
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden !important;
  margin: 0;
  padding: 0;
 }

 .input_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  cursor: pointer;
  background: ${theme.palette.white};
  padding: ${theme.spacing(2, 4)};
  justify-content: center;

  * {
   text-align: left;
   width: 100%;
  }
 }

 input:checked + .input_container {
  background: ${theme.palette.blue[50]};
 }

 &.isBordered {
  .input_container {
    border-radius: 8px;
    border: 1px solid ${theme.palette.gray[100]};
    min-height: 68px;
   }
  
   input:checked + .input_container {
    border: 1px solid ${theme.palette.blue[500]};
   }
 }

 &.noneBordered {
  .input_container {
    min-height: 45px;
  }
   .input_inner_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
   }

   .input_address {
    text-align: right;
   }
 }
`
);
