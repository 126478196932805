import { Box, Typography } from '@mui/material';

import { TransactionCardEmptyWrapper } from './TransactionCardEmptystyle';
import { TransactionCardProps } from 'src/models/component/base.model';

export const TransactionCardEmpty = ({
  title,
  imagePath,
  emptyStateTitle,
  emptyStateContent,
}: TransactionCardProps) => {
  return (
    <TransactionCardEmptyWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          marginBottom: 3,
          cursor: 'pointer',
          width: '100%',
        }}
      >
        <Typography
          component="p"
          variant="h2ExtraBold"
          textTransform={'uppercase'}
          sx={{
            marginBottom: 1,
            display: 'inline-flex',
            alignItems: 'center',
            color: 'gray.500',
          }}
        >
          {title}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
        paddingTop={3}
      >
        <Typography
          variant="h2Bold"
          color={'gray.500'}
          component="p"
          textAlign={'center'}
          marginBottom={2}
        >
          {emptyStateTitle}
        </Typography>
        <Typography
          variant="h1"
          color={'gray.500'}
          component="p"
          fontWeight={400}
          textAlign={'center'}
        >
          {emptyStateContent}
        </Typography>
        <Box paddingTop={4} display={'flex'} justifyContent="center">
          <img src={imagePath} alt={title} height={'86px'} />
        </Box>
      </Box>
    </TransactionCardEmptyWrapper>
  );
};
