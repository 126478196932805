import { FC, forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import classNames from 'classnames';

import {
  FormInputWrapper,
  DotsWrapper,
  DashWrapper,
  ReplaceCard,
  SecondDashWrapper,
} from './FormInputSSN.style';
import { FormInputErrorMessage } from '../FormInputErrorMessage/FormInputErrorMessage';
import { RefreshIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';

import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
  InputLabel,
} from '@mui/material';

import { SSNInputProps } from 'src/models/component/base.model';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { trackEvent } from '../../../../analytics/service';

interface State {
  amount: string;
  password: string;
  weight: string;
  weightRange: string;
  showPassword: boolean;
}

export const FormInputSSN: FC<SSNInputProps> = forwardRef<HTMLInputElement, SSNInputProps>(
  (
    {
      id,
      label,
      inputError,
      isValid = false,
      value,
      onChange,
      only4 = true,
      replace = false,
      setEnableSSNUpdate,
      inputFocus,
      ssnNotProvided = false,
      ssnVerificationFailed = false,
    },
    ref,
    ...props
  ) => {
    const [replaceEnabled, setReplaceEnabled] = useState(replace);
    const { t } = useTranslation();
    const isOnNativeApp = useSelector((state: RootState) => state.appState);

    useEffect(() => {
      setReplaceEnabled(replace);
      setEnableSSNUpdate && setEnableSSNUpdate(replace);
    }, [replace]);

    const handleInputClasses =
      inputError && !isValid ? 'error_input' : isValid ? 'valid_input' : 'normal_input';
    const [values, setValues] = useState<State>({
      amount: '',
      password: '',
      weight: '',
      weightRange: '',
      showPassword: false,
    });

    const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const val = event.target.value;
      const getOnlyNumberVal = val.replace(/\D/g, '');
      setValues({ ...values, [prop]: getOnlyNumberVal });
      onChange(event);
    };

    const handleClickShowPassword = () => {
      setValues({
        ...values,
        password: values.password,
        showPassword: !values.showPassword,
      });
      trackEvent('Hide/Unhide SSN', { show: values.showPassword });
    };

    const handleClickEvent = () => {
      trackEvent('SSN Input Click', { id, label, last4: values.password });
    };

    const handleInputChangeEvent = () => {
      trackEvent('SSN Input Change', { id, label, last4: values.password });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    return (
      <FormInputWrapper
        className={classNames([handleInputClasses, isOnNativeApp ? 'is-native' : ''])}
        onClick={handleClickEvent}
        onKeyUp={handleInputChangeEvent}
      >
        {!replaceEnabled && (
          <ReplaceCard
            onClick={() => {
              setReplaceEnabled(true);
              setEnableSSNUpdate && setEnableSSNUpdate(true);
            }}
          >
            <Typography
              variant="h1"
              color={ssnNotProvided || ssnVerificationFailed ? 'red.500' : 'gray.700'}
              marginBottom={2}
            >
              {ssnNotProvided
                ? t('formInput.ssnNotProvidedText')
                : ssnVerificationFailed
                ? t('formInput.ssnNotVerifiedText')
                : t('formInput.ssnReplaceButtonText')}
            </Typography>
            <div
              className={
                ssnNotProvided || ssnVerificationFailed ? 'error_icon_wrapper' : 'icon_wrapper'
              }
            >
              <RefreshIcon />
            </div>
          </ReplaceCard>
        )}
        {replaceEnabled && (
          <>
            <FormControl sx={{ m: 0, width: '100%', backgroundColor: 'white' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Social Security Number</InputLabel>
              <OutlinedInput
                fullWidth
                id="outlined-adornment-password"
                type={values.showPassword ? 'text' : 'text'}
                value={values.password}
                onChange={handleChange('password')}
                inputProps={{ maxLength: only4 ? 4 : 9, inputMode: 'numeric' }}
                error={inputError?.length > 0}
                onBlur={inputFocus && inputFocus}
                startAdornment={
                  only4 ? (
                    <DotsWrapper className={isOnNativeApp ? 'is-native' : ''}>
                      &#8226;&#8226;&#8226;<DashWrapper>&#8211;</DashWrapper>
                      &#8226;&#8226;<SecondDashWrapper>&#8211;</SecondDashWrapper>
                    </DotsWrapper>
                  ) : (
                    ''
                  )
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      sx={{ marginRight: 0.5 }}
                    >
                      {values.showPassword ? (
                        <VisibilityOffOutlinedIcon sx={{ color: 'gray.300', fontSize: 16 }} />
                      ) : (
                        <RemoveRedEyeOutlinedIcon sx={{ color: 'gray.300', fontSize: 16 }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Social Security Number"
                className={values.showPassword ? 'text' : 'password'}
              />
            </FormControl>
            {inputError && <FormInputErrorMessage>{inputError}</FormInputErrorMessage>}
            {only4 && (
              <Typography
                variant="h1"
                color={'gray.600'}
                sx={{ marginTop: 2, letterSpacing: 0.15 }}
              >
                {t('formInput.ssnInfo')}
              </Typography>
            )}
          </>
        )}
      </FormInputWrapper>
    );
  }
);
