import { styled } from '@mui/material/styles';

export const TopRow = styled('div')(
  ({ theme }) => `
  flex-direction: row;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${theme.spacing(1)};
`
);

export const SecondRow = styled('div')`
  flex-direction: row;
  flex: 1;
  display: flex;
`;

export const BottomSection = styled('div')(
  ({ theme }) => `
  flex-direction: column;
  display: flex;
  margin-top: ${theme.spacing(2.5)};
  margin-left: ${theme.spacing(3.5)};
`
);

export const AmountSection = styled('div')(
  ({ theme }) => `
  flex-direction: row;
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin-right: ${theme.spacing(2)};
 `
);

export const TagWrapper = styled('div')(
  ({ theme }) => `
  border-radius: 8px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  padding-top: ${theme.spacing(1)};
  padding-bottom: ${theme.spacing(1)};
  padding-left: ${theme.spacing(3)};
  padding-right: ${theme.spacing(3)};
  border-radius: 11px;

  &.un_editable {
   background: #EBEEF1 !important;
   color: ${theme.palette.gray[900]} !important;
   pointer-events: none;

   &.invoice_line_item {
    min-width: 60px;
    text-align: center;
    font-weight: 500;
   }
  }

  &.editable {
   background: ${theme.palette.blue[50]};
   color: ${theme.palette.blue[500]};
   cursor: pointer;
   display: flex;
   align-items: center;
   min-width: 60px;
   justify-content: center;
  }

  &.warning {
   background: ${theme.palette.yellow[75]};
   color: ${theme.palette.gray[900]} !important;
   cursor: pointer;
  }

  &.complete-disabled {
    pointer-events: none !important;
  }
 `
);

export const EditIconWrapper = styled('div')(
  ({ theme }) => `
  cursor: pointer;
  margin-right: 4px;
  
  svg * {
    fill: ${theme.palette.primary.main};
  }
`
);
