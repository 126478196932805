import styled, { keyframes } from 'styled-components';

const Spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
`;

export const LoaderWrapper = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.4);

  @media (min-width: 600px) {
    position: absolute;
  }

  @media (max-width: 600px) {
    position: fixed;
  }
`;
