import { createApi } from '@reduxjs/toolkit/query/react';
import { prepareBaseQuery } from '../../shared/config/config';

export const invoiceApi = createApi({
  reducerPath: 'invoiceApi',
  tagTypes: ['Invoice'],
  baseQuery: prepareBaseQuery(false),
  endpoints: (builder) => ({
    getInvoiceDetails: builder.mutation({
      query: (state) => ({
        url: `/payout/payoutdetail/${state.payout_id}`,
        method: 'GET',
      }),
      invalidatesTags: ['Invoice'],
    }),
  }),
});

export const { useGetInvoiceDetailsMutation } = invoiceApi;
