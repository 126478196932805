import { useTranslation } from 'react-i18next';
import {
  TimeIcon,
  CloseStatusIcon,
  DoneIcon,
} from 'src/components/base/core/Media/Icon/Icons/Icons';
import { Typography } from '@mui/material';
import { TagWrapper } from './AccountVerificationStatusses.style';
import { AccountVerificationStatussesProps } from 'src/models/component/base.model';

export const AccountVerificationStatusses = ({ type }: AccountVerificationStatussesProps) => {
  const { t } = useTranslation();

  const handleColorCodes = () => {
    let color;

    if (type === 'pending') {
      color = 'darkBlue.300';
    }

    if (type === 'restricted' || type === 'unverified') {
      color = 'red.500';
    }

    if (type === 'verified') {
      color = 'green.500';
    }

    return color;
  };
  return (
    <TagWrapper className={type ? type : ''}>
      <div className="icon_wrapper">
        {(type === 'pending' || type === 'unverified') && <TimeIcon />}
        {type === 'restricted' && <CloseStatusIcon />}
        {type === 'verified' && <DoneIcon />}
      </div>
      <Typography variant="h1" color={handleColorCodes} textTransform={'capitalize'}>
        {t(`statusTexts.${type}`)}
      </Typography>
    </TagWrapper>
  );
};
