import React from 'react';
import { TooltipInfo } from '../../TooltipInfo/TooltipInfo';
import {
  HeaderWrapper,
  HeaderContainer,
  Headerheading,
  SelectTag,
  ArrowiconContainer,
  HeaderLeft,
  TooltipInfoContainer,
  HeadingCounter,
} from './PaymentTableHeader.style';
import { Icon } from '../../../..';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface PaymentTableHeaderprops {
  heading?: string;
  tooltipContent?: any;
  isActive?: boolean;
  arrowClick?: () => void;
  isLast?: boolean;
  selectClick?: (isClicked: boolean) => void;
  isSelectTagClickable?: boolean;
  isSelectTagClicked?: boolean;
  isTooltipInfoEnabled?: boolean;
  isAccordionArrowVisible?: boolean;
  isSelectTagVisible?: boolean;
  dataCount?: string | number;
  toggleWrapperClick?: any;
}

export const PaymentTableHeader = ({
  heading,
  tooltipContent,
  isActive,
  arrowClick,
  selectClick,
  isLast,
  isSelectTagClickable,
  isSelectTagClicked,
  isTooltipInfoEnabled,
  isAccordionArrowVisible = true,
  isSelectTagVisible = true,
  dataCount,
  toggleWrapperClick,
}: PaymentTableHeaderprops) => {
  const { t } = useTranslation();
  const { enablePay1181 } = useFlags();
  return (
    <HeaderWrapper onClick={toggleWrapperClick}>
      <HeaderContainer
        className={isActive ? 'active' : isLast ? 'is-last' : 'in-active'}
        onClick={arrowClick}
      >
        <HeaderLeft>
          {isAccordionArrowVisible && (
            <ArrowiconContainer className={isActive ? 'active' : 'in-active'}>
              <Icon iconType="AccordionArrow" />
            </ArrowiconContainer>
          )}
          <Headerheading>{heading}</Headerheading>
          {isTooltipInfoEnabled && (
            <TooltipInfo aligned={isActive ? 'right' : 'topLeft'}>
              <TooltipInfoContainer>
                <p>
                  <strong>{heading}</strong>
                </p>
                <p>{tooltipContent}</p>
              </TooltipInfoContainer>
            </TooltipInfo>
          )}

          <HeadingCounter>{dataCount}</HeadingCounter>
        </HeaderLeft>
      </HeaderContainer>
      {isSelectTagVisible && enablePay1181 && (
        <SelectTag
          onClick={() => {
            selectClick && selectClick(!isSelectTagClicked);
          }}
          // className={isSelectTagClickable ? 'active' : 'non_clickable'}
        >
          {isSelectTagClicked ? t('earning.termsEnableClose') : t('earning.termsEnableEdit')}
        </SelectTag>
      )}
    </HeaderWrapper>
  );
};
