import { useTranslation } from 'react-i18next';
import {
  AccountVerificationStatusses,
  Messages,
  ReviewCard,
  ShadowCard,
} from '../../../components/base/core';
import { Box, Typography } from '@mui/material';
import { AddressCityStateZip } from 'src/shared/utilities/addressUtils';
import { RefreshIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { helperAccountInfo } from 'src/helpers/containers/helperAccountInfo';
import { AccountInfoEditModal } from './AccountInfoEdit/AccountInfoEdit';
import { phoneBusinessNumberMask, phoneNumberMask } from 'src/shared/utilities/phoneNumberMask';
import { getFormattedDate } from 'src/shared/utilities/dob';
import { getAditionalPhoneDetails } from 'src/services/account/additional.phone.service';
import { maskedInput } from 'src/shared/utilities/inputHandlers';

export const AccountInfoBusiness = ({
  accountData,
  isCardBorderEnabled = false,
  fromHome = false,
}: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [visibleUpdateForm, setVisibleUpdateForm] = useState(false);
  const [result, setResult]: any = useState();
  const [serverErrorsSSN, setServerErrorsSSN]: any = useState(null);
  const [states, setStates]: any = helperAccountInfo();
  const [addtionalNumbers, setAddtionalNumbers]: any = useState();
  const [isPersonal, setIsPersonal]: any = useState();

  const handleEditIcon = () => {
    setStates({ ...states, openModal: true });
  };

  useEffect(() => {
    if (accountData) {
      setResult(accountData);
    }
  }, [accountData]);

  useEffect(() => {
    if (fromHome) {
      setStates({ ...states, openModal: true });
    } else {
      setStates({ ...states, openModal: false });
    }
  }, [fromHome]);

  useEffect(() => {
    if (result) {
      getAditionalPhoneDetails(result?.id).then((result: any) => {
        if (result?.data?.length > 0) {
          setAddtionalNumbers(maskedInput(result.data[0].phone_number?.replace('+1', '')) ?? '');
        } else {
          setAddtionalNumbers('');
        }
      });
    }
  }, [result]);

  const isTaxIDProvided = result?.currently_due_requirements.includes('company.tax_id');
  const verificationStatus = result?.individual?.verification?.status;

  const onFormSubmit = () => {
    const isSSnProvidedTrue = result?.individual?.ssn_last4_provided;

    if (isSSnProvidedTrue && verificationStatus === 'verified' && !isTaxIDProvided) {
      handleCancelEvent();
      localStorage.removeItem('review_error_block');
    } else {
      handleCancelEvent();
      localStorage.removeItem('review_error_block');
      navigate('/settings/hold-on');
    }
  };

  const handleModalClose = () => {
    setStates({ ...states, openModal: false });
  };

  const handleCancelEvent = () => {
    setStates({ ...states, openModal: false });
    setVisibleUpdateForm(false);
  };

  const handleBackButton = () => {
    setStates({ ...states, openModal: false });
    setVisibleUpdateForm(false);
  };

  const editDetails = (isPersonal: any) => {
    setIsPersonal(isPersonal);
    setStates({ ...states, openModal: true });
  };

  const businessVerificationStatus = isTaxIDProvided ? 'unverified' : verificationStatus;
  const verificationDocumentFront = result?.individual?.verification?.document.front;
  const verificationDocumentBack = result?.individual?.verification?.document.back;
  const taxDocFront = result?.company?.verification.document.front;
  const taxDocBack = result?.company?.verification.document.back;
  const isTaxDocIDAdded =
    (taxDocFront && taxDocFront !== undefined) || (taxDocBack && taxDocBack !== undefined);

  const getFirstName = result?.individual?.first_name ?? '';
  const getLastName = result?.individual?.last_name ?? '';

  const dateObject = result?.individual?.dob;
  const checkDOBNull =
    dateObject && dateObject?.day !== null && dateObject.month !== null && dateObject.year !== null;

  return (
    <>
      {result && (
        <Box
          display={'flex'}
          flexDirection={isCardBorderEnabled ? 'row' : 'column'}
          justifyContent={isCardBorderEnabled ? 'space-between' : 'start'}
        >
          <ShadowCard
            display={'flex'}
            flexDirection={'column'}
            paddingLeft={4}
            paddingRight={4}
            paddingTop={4}
            paddingBottom={1}
            marginTop={isCardBorderEnabled ? 0 : 2}
            marginBottom={isCardBorderEnabled ? 0 : 4}
            className={isCardBorderEnabled ? 'full_border' : 'normal_shadow'}
            sx={{ width: isCardBorderEnabled ? 'calc(50% - 12px)' : '100%' }}
          >
            <ReviewCard
              title={t('captions.businessDetails')}
              name={`${result.company.name}`}
              editIconClickEvent={() => editDetails(false)}
              isShadowWrappedIcon={true}
              isInnerTitle={true}
              type={!isCardBorderEnabled ? businessVerificationStatus : null}
            >
              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {`${phoneBusinessNumberMask(result.company.phone)}`}
              </Typography>
              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {result?.company?.address?.line1 ?? ''}
              </Typography>
              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {result?.company?.address?.line2 ?? ''}
              </Typography>
              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {AddressCityStateZip(result?.company)}
              </Typography>

              <Typography
                variant="h1"
                color={'gray.700'}
                marginBottom={2}
                className="ssn_provided_text"
              >
                {t('reviewCardtexts.taxIdProvided')}
                {isTaxIDProvided && (
                  <div
                    className="icon_wrapper"
                    style={{ cursor: 'pointer' }}
                    onClick={() => editDetails(false)}
                  >
                    <RefreshIcon />
                  </div>
                )}
                {!isTaxIDProvided && (
                  <div className="ssn_provided_text_verified">
                    <AccountVerificationStatusses type={'verified'} />
                  </div>
                )}
              </Typography>
              {isTaxDocIDAdded && (
                <Typography
                  variant="h1"
                  color={'gray.700'}
                  marginBottom={2}
                  className="ssn_provided_text"
                >
                  {t('reviewCardtexts.idProvidedText')}
                  {!isTaxIDProvided && (
                    <div className="ssn_provided_text_verified">
                      <AccountVerificationStatusses type={'verified'} />
                    </div>
                  )}
                </Typography>
              )}
            </ReviewCard>
          </ShadowCard>
          <ShadowCard
            display={'flex'}
            flexDirection={'column'}
            paddingLeft={4}
            paddingRight={4}
            paddingTop={4}
            paddingBottom={1}
            marginBottom={isCardBorderEnabled ? 0 : 4}
            className={isCardBorderEnabled ? 'full_border' : 'normal_shadow'}
            sx={{ width: isCardBorderEnabled ? 'calc(50% - 12px)' : '100%' }}
          >
            <ReviewCard
              title={t('captions.personalDetails')}
              name={`${getFirstName} ${getLastName}`}
              editIconClickEvent={() => editDetails(true)}
              isShadowWrappedIcon={true}
              isInnerTitle={true}
              type={!isCardBorderEnabled ? verificationStatus : null}
            >
              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {result?.individual.email ? `${result?.individual.email}` : ''}
              </Typography>
              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {`•••••••••••••••`}
              </Typography>
              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {result.individual.phone ? `${phoneNumberMask(result.individual.phone)}` : ''}
              </Typography>
              {addtionalNumbers !== undefined && (
                <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                  {`${addtionalNumbers}`}
                </Typography>
              )}
              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {checkDOBNull
                  ? `${t('reviewCardtexts.bornOnText')} ${getFormattedDate(
                      result?.individual?.dob
                    )}`
                  : null}
              </Typography>

              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {result?.individual?.address?.line1 ?? ''}
              </Typography>
              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {result?.individual?.address?.line2 ?? ''}
              </Typography>
              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {AddressCityStateZip(result?.individual)}
              </Typography>

              {result?.individual?.ssn_last4_provided && (
                <Typography
                  variant="h1"
                  color={'gray.700'}
                  marginBottom={2}
                  className="ssn_provided_text"
                >
                  {t('reviewCardtexts.idProvidedLast4Text')}
                  {verificationStatus !== 'verified' && (
                    <div
                      className="icon_wrapper"
                      style={{ cursor: 'pointer' }}
                      onClick={() => editDetails(true)}
                    >
                      <RefreshIcon />
                    </div>
                  )}
                  {verificationStatus === 'verified' && (
                    <div className="ssn_provided_text_verified">
                      <AccountVerificationStatusses type={'verified'} />
                    </div>
                  )}
                </Typography>
              )}

              {(verificationDocumentFront || verificationDocumentBack) && (
                <Typography
                  variant="h1"
                  color={'gray.700'}
                  marginBottom={2}
                  className="ssn_provided_text"
                >
                  {t('reviewCardtexts.idDocProvidedText')}
                  {verificationStatus === 'verified' && (
                    <div className="ssn_provided_text_verified">
                      <AccountVerificationStatusses type={'verified'} />
                    </div>
                  )}
                  {verificationStatus !== 'verified' && (
                    <div
                      className="icon_wrapper"
                      style={{ cursor: 'pointer' }}
                      onClick={() => editDetails(true)}
                    >
                      <RefreshIcon />
                    </div>
                  )}
                </Typography>
              )}
            </ReviewCard>
          </ShadowCard>

          {(serverErrorsSSN ||
            result?.errors?.length > 0 ||
            result?.currently_due_requirements?.length > 0) && (
            <Messages
              topMargin={4}
              bottomMargin={2}
              messageHeading={t('errorMessages.reviewError.heading')}
              messageContent={t('errorMessages.reviewError.content')}
              messageLinkText={t('errorMessages.reviewError.linkText')}
              messageLinkEvent={() => editDetails(true)}
            />
          )}

          {states.openModal && (
            <AccountInfoEditModal
              handleCancelEvent={handleCancelEvent}
              onFormSubmit={onFormSubmit}
              setResult={setResult}
              result={result}
              title={t('captions.personalInfo')}
              bType={'company'}
              isPersonal={isPersonal}
              navigate={navigate}
              verificationStatus={verificationStatus}
            />
          )}
        </Box>
      )}
    </>
  );
};
