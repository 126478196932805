import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Wrapper,
  ContentContainerBottom,
  DivWrapper,
  HeadingSummaryBlock,
  PayModeCard,
  Header,
} from 'src/components/base/core';
import { Box, Button } from '@mui/material';
import { Images } from 'src/shared/utilities/images';
import { useGetAccountQuery } from 'src/services/account/accountServiceApi';
import { useFlags } from 'launchdarkly-react-client-sdk';

type LocationState = {
  bankConnect?: boolean;
  last4?: string;
  fromSettings?: boolean;
  fromHome?: boolean;
  isSettingDesktop?: boolean;
  isFromFinance?: boolean;
  isFromSettingsPayMode?: boolean;
  ifFromRecipentForm?: boolean;
};

export const PayModeSelectionSuccessContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const { recipientDetailsFormEnabled } = useFlags();

  const { data: account } = useGetAccountQuery(null);

  const {
    fromSettings,
    fromHome,
    isSettingDesktop,
    isFromFinance,
    isFromSettingsPayMode,
    ifFromRecipentForm,
  } =
    location?.state != null
      ? (location?.state as LocationState)
      : {
          fromSettings: false,
          fromHome: false,
          isSettingDesktop: false,
          isFromFinance: false,
          isFromSettingsPayMode: false,
          ifFromRecipentForm: false,
        };

  const handleNextButtonClick = () => {
    localStorage.removeItem('enable_hold_sucsess');
    localStorage.removeItem('signup_done');
    localStorage.removeItem('lastFourDigits');

    if (fromSettings && !isSettingDesktop && !isFromFinance) {
      navigate('/settings/bank-setup');
    } else if (fromSettings && isSettingDesktop && !isFromFinance) {
      navigate('/settings');
    } else if (fromHome && !isFromFinance) {
      navigate('/dashboard');
    } else if (isFromFinance) {
      navigate('/finance/account/move-money');
    } else if (isFromSettingsPayMode && recipientDetailsFormEnabled) {
      navigate('/settings/terms-selection', { state: location.state });
    } else {
      localStorage.removeItem('enable_hold_sucsess');
      localStorage.setItem('signup_done', 'true');
      localStorage.setItem('account_id', account?.id);
      navigate('/enable-notifications', { state: { isFromPayModeKyc: true } });
    }
  };

  const option = {
    id: 'check',
    modeName: 'Checks',
    feePercentage: 'No Fee',
    active: true,
    time: '30-60 days',
  };

  const handleBackButton = () => {
    if (ifFromRecipentForm && recipientDetailsFormEnabled) {
      navigate('/recipient-details', {
        state: location.state,
      });
    } else {
      navigate('/payment-selection', {
        state: location.state,
      });
    }
  };

  return (
    <Wrapper>
      <DivWrapper className="no_fullscreen">
        <Box component={'div'} className={'container_content'}>
          <Header
            isBackButtonVisible={true}
            onBackButtonClick={handleBackButton}
            paddingX={0}
            isLogoVisible={false}
          />
          <Box component={'div'} className={'container_content_top add_bottom_padding'}>
            <div className="status_image">
              <img src={Images.BANK_SETUP_SUCCESS} alt="Status" height={200} />
            </div>
            <div className="content_block">
              <HeadingSummaryBlock
                heading={t('headingContent.statusses.payModeSelectionSuccess.heading')}
                headingAlignment="center"
                content={t('headingContent.statusses.payModeSelectionSuccess.content')}
                contentAlignment="left"
              />
            </div>
            <div className="addition_element">
              <PayModeCard
                id={option.id}
                modeName={option.modeName}
                feePercentage={option.feePercentage}
                isActive={false}
                comingSoon={false}
                timeDuration={option.time}
                isIconVisible={false}
                feePercentageTextAlignment={'right'}
              />
            </div>
          </Box>
        </Box>
        <ContentContainerBottom className={'no_note no_fullscreen'}>
          <Button
            variant="containedLarge"
            color="primary"
            type="button"
            fullWidth
            onClick={handleNextButtonClick}
            sx={{ overflow: 'hidden' }}
          >
            {t('buttonTexts.nextButton')}
          </Button>
        </ContentContainerBottom>
      </DivWrapper>
    </Wrapper>
  );
};
