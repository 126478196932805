import {
  Header,
  HeaderFixed,
  LanguageSwitcher,
  Wrapper,
  WrpperAuthorizedContainer,
} from 'src/components/base/core';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';
import { routeType } from 'src/helpers/containers/helperRoutePreference';

export const SettingLanguagesContainer = ({ isSettingDesktop = false }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [updatePreferenceOnServer] = handlePreferences();

  const handleBackButton = () => {
    navigate('/settings');
    updatePreferenceOnServer(routeType.SETTINGS);
  };

  return (
    <>
      {!isSettingDesktop && (
        <HeaderFixed className="fixed_only_desktop">
          <Header
            isMainMunuVisible
            isBackButtonHide
            onBackButtonClick={handleBackButton}
            isBackButtonVisible
            paddingX={0}
            headerText={t('settings.Languages.title')}
            withCenteredHeaderText
          />
        </HeaderFixed>
      )}
      <Wrapper
        isAuthorizedWrapper
        idSelector={!isSettingDesktop ? 'wrapperContainer' : 'settingDesktopWraper'}
        isSettingDesktop={isSettingDesktop}
      >
        <WrpperAuthorizedContainer>
          <LanguageSwitcher isDropDownType={false} isBorderEnabled={isSettingDesktop} />
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
