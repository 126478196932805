import { styled } from '@mui/material/styles';

export const TermCardComponent = styled('div')(
  ({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing(3)};
`
);

export const TermCardContainer = styled('div')(
  ({ theme }) => `
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;

  &.active_card {
    border: 1px solid ${theme.palette.primary.main};
    background: ${theme.palette.blue[50]};
    pointer-events: none;
    margin-bottom: ${theme.spacing(3)};
  }

  &.in_active_card {
    border: 1px solid ${theme.palette.gray[100]};
    background: ${theme.palette.white};
    cursor: pointer;
  }
`
);

export const TermCardLeft = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const TermCardRight = styled('div')`
  display: flex;
  flex-direction: column;

  * {
    font-weight: 400;
  }
`;
