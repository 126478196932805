import React from 'react';
import { Link } from './AnchorLink.style';

interface AnchorLinkProps {
  /**
   * Set label
   */
  children?: React.ReactNode;
  /**
   * Set UrlPath
   */
  urlPath?: string;
  /**
   * set Target
   */
  target?: '_blank' | '_self';
}

export const AnchorLink = ({ children, urlPath, target = '_self' }: AnchorLinkProps) => {
  return (
    <>
      <Link href={urlPath} target={target}>
        {children}
      </Link>
    </>
  );
};
