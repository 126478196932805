import { DownloadCSVState } from './interface';

export const initialTermsDetail = {
  id: null, // payout id
  amount: null, // payout amount
  modalOpen: false, // initial modal display false
  termsData: [], // terms Array object data
  selectedTerm: null, // selected term state from modal
  showConfirmModal: false, // display the confirmation modal
  error: null, // error
  updatedTerm: null, // updated term to display in grid or page
  initialLoadingTerm: null, // get initial term before the update
  selectedMultipleTerms: [],
  selectedTermsCount: 0,
  isEditInvoiceLinkClicked: false,
  oldInvoiceOpen: false,
  curentInvoiceOpen: true,
  termBulkEditable: false,
  isAllTermChecked: false,
  isTermClicked: false,
  isOldInvoiceType: false,
};

export const initialFilterOptions = {
  isFilterEnable: false,
  sort: null,
  selectedTerms: [],
};

export const downloadPeriodOptions = ['All', '1', '7', '30', '90', '500', 'date'];

export const initialDownloadOptions: DownloadCSVState = {
  isDownloadEnable: false,
  selectedDateRange: [],
  selectedOption: downloadPeriodOptions[0],
};

export const initialFilterStatus = {
  isAmountFilterActive: false,
  isDateFilterActive: false,
  isTermFilterActive: false,
  isSortBFilterActive: false,
};

export const initialPayoutData = {
  account_id: '',
  amount: '',
  approved_at: null,
  coupa_invoice_id: '',
  coupa_invoice_number: '',
  created_at: '',
  customer: { id: '', business_name: '' },
  description: '',
  estimated_approved_at: '',
  estimated_invoiced_at: '',
  id: '',
  invoiced_at: null,
  number: '',
  paid_at: null,
  payoutTerm: null,
  payout_amount: null,
  payout_due_at: null,
  payout_term_amount: null,
  payout_term_id: null,
  property: { id: '', name: '=', city: '', state: '', zip: '' },
  status: '',
  stripe_transfer_id: null,
  title: '',
  updated_at: '',
  isChecked: false || '',
};

export const initFilterStatus = {
  isAmountFilterActive: false,
  isDateFilterActive: false,
  isTermFilterActive: false,
  isSortBFilterActive: false,
  selectedDateFilterValue: { isRange: false, value: null },
};
