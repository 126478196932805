import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { Modal } from '../Modal/Modal';
import { HeadingSummaryBlock } from '../../CommonNestedBlocks/ContentBlocks/HeadingSummaryBlock';
import { TermsText } from '../../NotesBlocks/TermsText/TermsText';

import { VerifyImageContentBlock } from '../../CommonNestedBlocks/VerifyImageContentBlock/VerifyImageContentBlock';

import { helperEINDocumentUpload } from '../../../../../helpers/containers/helperIdDocumentModal';
import { Images } from 'src/shared/utilities/images';
import {
  StepThreeContentContainer,
  SelectedDocumentContent,
  SelectedDocumentContainer,
} from './EINmodal.style';
import { FileInput } from '../../FormElements/FileInput/FileInput';
import { ContentContainerBottom } from '../../CommonNestedStyles/FormWrapper.style';
import { ManualLoader } from '../../OtherUtilities/Loader/ManualLoader';
import { useEffect } from 'react';
import { commonSpacing, theme } from 'src/shared/theme/theme';

export interface EINmodalProps {
  register?: any;
  setValue?: any;
  setOpenIdModal?: any;
  verificationData?: any;
  isSubmittedValuesHeaderVisible?: boolean;
  setHandleVisible?: any;
  isFormButtonBlock?: boolean;
  onClickIdModalOpen?: () => void;
  openIdModal?: any;
  closeIdModalEvent?: () => void;
}

export const EINmodal = ({
  register,
  setValue,
  isFormButtonBlock = false,
  onClickIdModalOpen,
  openIdModal,
  closeIdModalEvent,
}: EINmodalProps) => {
  const { t } = useTranslation();
  const [modalStates, handleCloseModal, onFileChange, uploadLoaderEnabled] =
    helperEINDocumentUpload();
  const elmentWrapper: any = document.getElementById('wrapperContainer');

  // handle close event __
  const handleCloseEvent = () => {
    handleCloseModal && handleCloseModal();
    closeIdModalEvent && closeIdModalEvent();
    elmentWrapper.style.overflowY = 'auto';
  };

  useEffect(() => {
    setValue('companyFront', modalStates.front, { shouldValidate: false });
    setValue('companyBack', modalStates.back, { shouldValidate: false });
  }, [modalStates]);

  return (
    <>
      <Box
        className={
          isFormButtonBlock ? 'addition_element_padding_bottom' : 'addition_element_padding_none'
        }
        sx={{
          paddingX: isFormButtonBlock ? 4 : 0,
          [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
            minHeight: isFormButtonBlock ? 320 + commonSpacing.desktopPaddingB * 4 : 1,
          },
        }}
      >
        {isFormButtonBlock && (
          <>
            {!modalStates.selctedContainerVisible && (
              <VerifyImageContentBlock
                content={t('headingContent.statusses.einVerificationFailed.content')}
                contentAlignment="center"
                heading={t('headingContent.statusses.einVerificationFailed.heading')}
                headingAlignment="center"
                imagePath={Images.VERIFICATION_FAILED}
              />
            )}
            {modalStates.selctedContainerVisible && (
              <VerifyImageContentBlock
                content={t('headingContent.statusses.einDocUploadDone.content')}
                contentAlignment="left"
                heading={t('headingContent.statusses.einDocUploadDone.heading')}
                headingAlignment="left"
                imagePath={Images.DOC_UPLOAD_DONE}
              />
            )}
          </>
        )}

        {modalStates.selctedContainerVisible && openIdModal && (
          <>
            {uploadLoaderEnabled && <ManualLoader />}
            <SelectedDocumentContainer>
              <SelectedDocumentContent>
                {modalStates.front !== undefined && (
                  <FileInput
                    id={'companyFront'}
                    {...register('companyFront')}
                    onChange={onFileChange}
                    accept="image/*"
                    fileValue={t('einModal.confirmationLetterButton')}
                    label={t('einModal.replaceWord')}
                    isCaptureButton={false}
                    isReplace={true}
                  />
                )}
                {modalStates.back !== undefined && (
                  <FileInput
                    id={'companyBack'}
                    {...register('companyBack')}
                    onChange={onFileChange}
                    accept="image/*"
                    fileValue={t('einModal.irsLetterButton')}
                    label={t('einModal.replaceWord')}
                    isCaptureButton={false}
                    isReplace={true}
                  />
                )}
              </SelectedDocumentContent>
            </SelectedDocumentContainer>
          </>
        )}
      </Box>

      {modalStates.modalVisbility && openIdModal && (
        <Modal onCloseEvent={handleCloseEvent}>
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 3 }}
            component={'div'}
          >
            <img src={Images.SUBMIT_DOCUMENT} alt="Submit Document" height={124} />
          </Box>
          <Box sx={{ marginBottom: 6 }} component={'div'}>
            <HeadingSummaryBlock
              heading={t('einModal.heading')}
              headingAlignment="left"
              content={t('einModal.content')}
              contentAlignment="left"
            />
          </Box>
          <StepThreeContentContainer>
            <Box marginBottom={3}>
              <FileInput
                id={'front'}
                {...register('front')}
                onChange={onFileChange}
                accept="image/*"
                fileValue={t('einModal.confirmationLetterButton')}
                label={t('einModal.confirmationLetterButton')}
                isCaptureButton={false}
                isReplace={modalStates.front !== undefined}
              />

              <FileInput
                id={'back'}
                {...register('back')}
                onChange={onFileChange}
                accept="image/*"
                fileValue={t('einModal.irsLetterButton')}
                label={t('einModal.irsLetterButton')}
                isCaptureButton={false}
                isReplace={modalStates.back !== undefined}
              />
            </Box>
            <TermsText
              mainContent={t('noticeBlocks.termsBlock.heading')}
              subContent={t('noticeBlocks.termsBlock.content')}
            />
          </StepThreeContentContainer>
        </Modal>
      )}

      {isFormButtonBlock && (
        <ContentContainerBottom className={'no_note no_fullscreen'}>
          {!modalStates ||
            (!modalStates.selctedContainerVisible && (
              <Button
                variant="containedLarge"
                color="primary"
                type="button"
                fullWidth
                onClick={onClickIdModalOpen}
                sx={{ overflow: 'hidden' }}
              >
                {t('buttonTexts.continueWithEINDocButton')}
              </Button>
            ))}
          {modalStates.selctedContainerVisible && (
            <Button
              variant="containedLarge"
              color="primary"
              type="submit"
              fullWidth
              sx={{ overflow: 'hidden' }}
            >
              {t('buttonTexts.submitButton')}
            </Button>
          )}
        </ContentContainerBottom>
      )}
    </>
  );
};
