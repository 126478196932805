import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const MessageWrapper = styled(Box)(
  ({ theme }) => `
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;   
    box-sizing: border-box;

    .message_content {
     display: flex;
     flex-direction: column;
    }

    .message_content_no_button {
      display: flex;
      flex-direction: column;
    }

    .block-clickable {
      cursor: pointer;
    }

    .icon_wrapper {
     width: 12px;
    }

    .message_button {
     width: auto;
     display: flex;
     align-items: center;
     min-width: 150px;

     button {
      inline-flex;
     }
    }

    .icon_close {
     width: 13px;
     top: 16px;
     right: 16px;
     position: absolute;
     cursor: pointer;

     * {
      fill: ${theme.palette.gray[400]};
     }
    }
    
    &.is_desktop {
     @media(min-width: ${theme.breakpoints.values.sm}px){
      padding-left: ${theme.spacing(8)};
      padding-right: ${theme.spacing(8)};
      align-items: center;
      
      .message_content {
       width: calc(100% - 178px);
      }
      
      .message_content_no_button {
       width: calc(100% - 148px);
      }

      .icon_wrapper {
       margin-right: ${theme.spacing(4)};
      }

      .message_button {

       justify-content: end;
      }

      .icon_close {
       display: none;
      }
     }


     @media(max-width: ${theme.breakpoints.values.sm}px){
      padding-left: ${theme.spacing(4)};
      padding-right: ${theme.spacing(4)};

      .message_content {
       width: calc(100% - 40px);
      }
      
      .message_content_no_button {
       width: calc(100% - 30px);
      }

      .icon_wrapper {
       margin-right: ${theme.spacing(3)};
      }

      .icon_close {
       display: block;
      }

      .message_button {
       padding-left: ${theme.spacing(6)};
       margin-top: ${theme.spacing(4)};
      }
     }
    }

    &.is_mobile {
     width: 100%;
     max-width: 100%;
     padding-left: ${theme.spacing(4)};
     padding-right: ${theme.spacing(4)};

     .message_content {
      width: calc(100% - 40px);
     }

     .message_content_no_button {
      width: calc(100% - 30px);
     }

     .icon_wrapper {
      margin-right: ${theme.spacing(3)};
     }

     .icon_close {
      display: block;
     }

     .message_button {
      padding-left: ${theme.spacing(6)};
      margin-top: ${theme.spacing(4)};
     }
    }

    &.neutral {
     background-color: ${theme.palette.gray[50]};

     .icon_wrapper {
      * {
       fill: ${theme.palette.neutral.main};
      }
     }
    }

    &.default {
     background-color: ${theme.palette.blue[50]};

     .icon_wrapper {
      * {
       fill: ${theme.palette.blue[500]};
      }
     }
    }

    &.warning {
     background-color: ${theme.palette.yellow[100]};
     .icon_wrapper {
      * {
       fill: ${theme.palette.yellow[500]};
      }
     }
    }

    &.delinquent {
     background-color: ${theme.palette.yellow[50]};
     color: ${theme.palette.gray[600]};
     .icon_wrapper {
      * {
       fill: ${theme.palette.yellow[500]};
      }
     }
    }

    &.success {
     background-color: ${theme.palette.green[75]};
     .icon_wrapper {
      * {
       fill: ${theme.palette.green[500]};
       width: 14px;
       height: 14px;
      }
     }
    }

    &.error {
     background-color: ${theme.palette.red[75]};
     .icon_wrapper {
      * {
       fill: ${theme.palette.red[500]};
      }
     }
    }

    &.info {
      background-color: ${theme.palette.white};
    }
`
);
