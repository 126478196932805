import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/store';
import { getAccountAction } from '../../store/actions/account';

import { getCookie } from 'src/shared/utilities/cookiesHelper';
import { decryptWithAES } from 'src/shared/utilities/encryptHelpper';
import { configs, getWebAuth, nativeBridgeTypes } from 'src/shared/config/config';

import { Box, Button } from '@mui/material';
import { Header, SplashSlider, Wrapper, DivWrapper } from 'src/components/base/core';

import { navigateToScreen } from 'src/helpers/helperLanding';
import { theme } from 'src/shared/theme/theme';
import { useTranslation } from 'react-i18next';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';

type LocationState = {
  isBack?: boolean;
  verifiedEmail?: boolean;
};

export const LandingContainer = () => {
  const navigate = useNavigate(); // set useNaviagate to navigate __
  const location = useLocation(); // set useLocation to location __
  const dispatch = useDispatch(); // set useDispatchto dispatch __
  const { t, i18n } = useTranslation(); // set translation __
  const { webauthnEnabled, disabledTheCustomerAddition } = useFlags();

  const account = useSelector((state: RootState) => state.account); // get account state from root state __
  const [hideLogin, setHideLogin] = useState(true); // handle login page visibility throgh the components __
  const [isLoader, setIsLoader] = useState(false); // handle login page visibility throgh the components __

  const { verifiedEmail } = location.state
    ? (location.state as LocationState)
    : { verifiedEmail: false };

  // render in the initial load __
  useEffect(() => {
    // get idToken from cookie __
    const idTokenAdded: any = decryptWithAES(getCookie('T_added'), configs.ENCRYPT_PHRESE);

    // set default state to logout __
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({ type: nativeBridgeTypes.LOGIN_STATUS, isLoggedIn: false })
    );

    // get idToken from cookie __
    if (account && idTokenAdded) {
      setHideLogin(true);
      navigate('/dashboard');

      localStorage.removeItem('from_resend');
      localStorage.removeItem('from_login');
      localStorage.removeItem('from_reset');

      if (account?.account) {
        i18n.changeLanguage('en');
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({ type: nativeBridgeTypes.LOGIN_STATUS, isLoggedIn: true })
        );
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({ type: nativeBridgeTypes.SELECTED_LOCALE, locale: 'en' })
        );
      }
    }
    // trackEvent('User Onboarding into Landing Page');
  }, [account]);

  useEffect(() => {
    account == null && dispatch<any>(getAccountAction(true, false));
    if ((location?.state as LocationState)?.isBack) {
      setHideLogin(false);
    } else {
      setTimeout(function () {
        setHideLogin(false);
      }, 2000);
    }
    if (verifiedEmail) {
      setHideLogin(true);
      localStorage.removeItem('from_resend');
      localStorage.removeItem('from_login');
      localStorage.removeItem('from_signup');
      localStorage.removeItem('from_reset');
    }
  }, []);

  const { webAuth, isSuccess } = getWebAuth();

  const webAuthnLogin = () => {
    localStorage.setItem('from_login', 'yes');
    localStorage.setItem('from_signup', 'no');
    localStorage.setItem('from_resend', 'no');
    setIsLoader(true);

    webAuth.authorize({
      responseType: 'code',
      redirectUri: `${window.location.origin}/verify-code`,
      mode: 'login',
    });

    setTimeout(function () {
      setIsLoader(false);
    }, 1200);
  };

  const webAuthnSignUp = () => {
    if (webauthnEnabled) {
      if (disabledTheCustomerAddition) {
        navigate('/search-supplier');
      } else {
        navigate('/customers-addition');
      }
      localStorage.setItem('from_signup', 'yes');
      localStorage.setItem('from_login', 'no');
      localStorage.setItem('from_resend', 'no');
    } else {
      navigateToScreen(navigate, false);
    }
  };

  // Check from reset password and navigate to Login screen __
  useEffect(() => {
    const searchValue = location ? location.search : '';
    const checkSearchValue = searchValue.includes('iss=') && searchValue.includes('auth');
    const checkFromResetPass = localStorage.getItem('from_reset');
    const IsFromResetPass = checkFromResetPass && checkFromResetPass === 'yes';

    if (checkSearchValue && !IsFromResetPass) {
      navigate('/', { replace: true });
      localStorage.setItem('from_reset', 'inprogress');
      setHideLogin(true);
    }

    if (checkFromResetPass === 'inprogress' && isSuccess) {
      setHideLogin(true);
      setTimeout(function () {
        webAuthnLogin();
        localStorage.setItem('from_reset', 'yes');
      }, 100);
    }

    if (IsFromResetPass) {
      setHideLogin(false);
      navigate('/', { replace: true });
      localStorage.removeItem('from_reset');
    }
  }, [location, isSuccess]);

  return (
    <>
      {hideLogin && (
        <Wrapper>
          <Box
            sx={{
              display: 'flex',
              height: '100vh',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        </Wrapper>
      )}

      {!hideLogin && (
        <>
          {isLoader && <ManualLoader />}
          <Wrapper isFullScreen>
            <DivWrapper>
              <Box
                component={'div'}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  position: 'relative',
                  [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                    flex: 1,
                  },
                }}
              >
                <Header isLogoLeftAligned isLanguageSwitcher />
                <Box
                  component={'div'}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                      paddingBottom: 8,
                      paddingTop: 6,
                    },
                    [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                      flex: 1,
                    },
                  }}
                >
                  <SplashSlider />
                </Box>
                <Box
                  sx={{
                    paddingX: 4,
                    marginTop: 8,
                    [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                      marginTop: 0,
                      marginBottom: 8,
                    },
                  }}
                >
                  <Button
                    variant="containedLarge"
                    color="primary"
                    onClick={webAuthnSignUp}
                    fullWidth
                    sx={{ marginBottom: 4 }}
                    id={'SignUp'}
                  >
                    {t('buttonTexts.signupButtonText')}
                  </Button>

                  <Button
                    variant="outlinedLarge"
                    color="primary"
                    onClick={
                      webauthnEnabled ? webAuthnLogin : () => navigateToScreen(navigate, true)
                    }
                    fullWidth
                    id={'Login'}
                  >
                    {t('buttonTexts.loginButtonText')}
                  </Button>
                </Box>
              </Box>
            </DivWrapper>
          </Wrapper>
        </>
      )}
    </>
  );
};
