import axios from 'axios';
import config from '../config';

export const getNotifyMeStatus = async (id: string) => {
  config(false);
  return await axios
    .get(`/account-notify/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return JSON.parse(error.request.response);
    });
};

export const saveNotifyMeClicked = async (id: string) => {
  config(false);
  return await axios
    .post(`/account-notify/payup-financial-card-notification/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return JSON.parse(error.request.response);
    });
};
