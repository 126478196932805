import { TermParams } from '../../shared/utilities/interface';

export const initTerms: TermParams = {
  id: '',
  name: '',
  due_days: 0,
  active: false,
  created_at: '',
  updated_at: '',
  description: '',
  payout_percentage: 0,
  payout_amount: 0,
};
