import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import {
  Header,
  Wrapper,
  HeadingSummaryBlock,
  ChoiceCard,
  DivWrapper,
} from 'src/components/base/core';
import { trackEvent } from '../../../../components/analytics/service';
import { logout } from 'src/shared/utilities/logout';
import { useFlags } from 'launchdarkly-react-client-sdk';

type LocationState = {
  type?: string;
};

export const SignupChoiceContainer = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { webauthnEnabled } = useFlags();

  const [selectedType, setSelectedType] = useState('');
  const [pageVisible, setPageVisible] = useState(true);

  const { type } = location?.state !== null ? (location?.state as LocationState) : { type: null };

  /** declare navigation */
  const navigate = useNavigate();
  const onTypePicked = (name: string, isBusiness: boolean) => {
    trackEvent('Vendor Type Selection', { selection: name });
    setSelectedType(name);
    if (!isBusiness) {
      navigate('/review-info', {
        state: { isCompany: false },
      });
    } else {
      navigate('/business-info', {
        state: { isCompany: true, step: 'step1' },
      });
    }
  };

  const handleBackButton = () => {
    if (webauthnEnabled) {
      logout(navigate);
    } else {
      navigate('/customers-addition');
    }
  };

  useEffect(() => {
    location?.state && type && setSelectedType(type);
  }, []);

  return (
    <Wrapper>
      <DivWrapper className={'no_fullscreen'}>
        <Header
          isBackButtonVisible={true}
          onBackButtonClick={handleBackButton}
          isLanguageSwitcher
        />
        <Box sx={{ paddingLeft: 3, marginBottom: 6, paddingRight: 7 }}>
          <HeadingSummaryBlock
            heading={t('headingContent.choiceScreen.heading')}
            headingAlignment={'left'}
          />
        </Box>
        {pageVisible && (
          <Box sx={{ paddingX: 3 }}>
            <ChoiceCard
              onClick={() => onTypePicked('company', true)}
              isIndividual={false}
              isSelected={selectedType === 'company'}
            />

            <ChoiceCard
              onClick={() => onTypePicked('individual', false)}
              isSelected={selectedType === 'individual'}
              isIndividual
            />
          </Box>
        )}
      </DivWrapper>
    </Wrapper>
  );
};
