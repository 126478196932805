import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import {
  ModalWrapper,
  ModalOverlay,
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalButton,
  ModalClose,
  CustomerAdditionConfirmModalIconWrapper,
} from './ModalBatchPayModeEdit.style';

import { Icon, ManualLoader, NoteText, PayModeCard, ShadowCard } from '../..';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Messages } from 'src/components/base/core';
import { ConfirmIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import { GetPayModes } from 'src/services/account/account.service';
import { ModalBatchEditProps, PayModesInterface } from 'src/models/component/base.model';
import { getEstDatesForPayMode, handlePayModeDisplay } from 'src/shared/utilities/dataConvertions';
import { useGetAccountQuery } from 'src/services/account/accountServiceApi';
import { useUpdatePayModeMutation } from 'src/services/transfers/transfers.service';
import { dateFormatMMMSpaceDDYYYY } from 'src/shared/utilities/dob';

const domNode: any = document.getElementById('app-root');

export const ModalBatchPayModeEdit = ({
  onModalClose,
  activePayMode,
  estimatedPayoutDate = '',
  transferId,
  paymentTriggeredDate = '',
  paymentArrivalEstimatedDate = '',
  t,
}: ModalBatchEditProps) => {
  const [payModes, setPayModes] = useState([]);
  const [errorState, setErrorState] = useState('');
  const [payMode, setPayMode] = useState('');
  const [selectedPayMode, setSelectedPayMode] = useState('');
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [arrivalDate, setArrivalDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { data } = useGetAccountQuery('');
  const [updateBatchPayMode] = useUpdatePayModeMutation();

  /**
   * Function for intit the paymodes
   */
  const initPayModes = async () => {
    await GetPayModes().then((response) => {
      setPayModes(response.data);
    });
  };

  /**
   * Fetch the available paymodes
   */
  useEffect(() => {
    initPayModes();

    if (activePayMode) {
      setPayMode(activePayMode);
    }
  }, []);

  // handle the modal submit button visibility
  const isConfirmButtonDisable = selectedPayMode === '' || selectedPayMode === activePayMode;

  /**
   * Modal close event
   */
  const onModalCloseEvent = () => {
    onModalClose && onModalClose();
    setPayMode('');
    setSelectedPayMode('');
  };

  /**
   * event to handle the submit button
   */
  const onModalSubmitEvent = async () => {
    if (!isConfirmButtonDisable && data && data?.id && transferId) {
      setIsLoading(true);
      const response: any = await updateBatchPayMode({
        transfer_id: transferId,
        pay_mode: payMode,
        account_id: data?.id,
      });

      if (response) {
        if (response?.data) {
          setIsConfirmModalOpen(true);
          setArrivalDate(response?.data?.payment_arrival_estimated_date);
          setIsLoading(false);
        } else {
          setIsConfirmModalOpen(false);
          setErrorState(
            response?.error?.data?.error ?? response?.error?.data?.message ?? response?.error?.error
          );
          setArrivalDate('');
          setIsLoading(false);
        }
      }
    }
  };

  /**
   * event to handle the confirmation btn click
   */
  const onYesButtonClick = () => {
    if (isConfirmModalOpen) {
      setIsConfirmModalOpen(false);
      onModalCloseEvent();
      setArrivalDate('');
      setIsLoading(false);
    }
  };

  return ReactDOM.createPortal(
    <>
      {isLoading && <ManualLoader />}
      <ModalWrapper>
        <ModalOverlay onClick={onModalCloseEvent} />
        {!isConfirmModalOpen && (
          <ModalContainer className="is_fixed">
            <ModalClose onClick={onModalClose}>
              <Icon iconType="CloseIcon" />
            </ModalClose>
            <ModalHeader>
              <Typography
                variant="h4ExtraBold"
                color="gray.800"
                component={'p'}
                sx={{ marginBottom: 4 }}
              >
                {t('batches.payModeEditModal.title')}
              </Typography>
              <Typography variant="body2" color="gray.800" component={'p'}>
                {t('batches.payModeEditModal.content', {
                  PaymentTriggedOn: paymentTriggeredDate,
                })}
              </Typography>
            </ModalHeader>

            {errorState !== '' && (
              <Messages
                type={'error'}
                messageHeading={t('batches.payModeEditModal.title')}
                messageContent={errorState}
                closeEvent={() => setErrorState('')}
              />
            )}

            <ModalContent>
              <ShadowCard
                paddingX={0}
                paddingTop={0}
                paddingBottom={0}
                minHeight={'248px'}
                className="is_transparent remove_all_shadow"
              >
                {payModes &&
                  payModes.length > 0 &&
                  payModes?.map((option: PayModesInterface) => {
                    return (
                      <PayModeCard
                        key={option.id}
                        id={option.id}
                        modeName={handlePayModeDisplay(option.id)}
                        feePercentage={option.feePercentage}
                        isActive={payMode === option.id}
                        comingSoon={!option.active}
                        onCardClick={(id) => {
                          setPayMode(id);
                          setSelectedPayMode(id);
                        }}
                        timeDuration={
                          option.id === 'check'
                            ? '30 days'
                            : option.id === 'ach'
                            ? '1-3 days'
                            : option.time
                        }
                        isIconVisible={option.id === 'debit_card' ? true : false}
                        isEstDateVisible
                        estDateValue={getEstDatesForPayMode(
                          option.id,
                          estimatedPayoutDate,
                          activePayMode,
                          paymentArrivalEstimatedDate
                        )}
                        isTransparentBackground
                      />
                    );
                  })}
              </ShadowCard>
              <NoteText isIconVisible={false} isBorder={false}>
                {t('batches.payModeEditModal.footerNote')}
              </NoteText>
            </ModalContent>
            <ModalButton>
              <Button
                variant="containedLarge"
                color="primary"
                type="button"
                fullWidth
                onClick={onModalSubmitEvent}
                sx={{ overflow: 'hidden' }}
                disabled={isConfirmButtonDisable}
              >
                {t('buttonTexts.submitButton')}
              </Button>
            </ModalButton>
          </ModalContainer>
        )}

        {isConfirmModalOpen && arrivalDate !== '' && (
          <ModalContainer className="confirmation_container">
            <CustomerAdditionConfirmModalIconWrapper marginBottom={6}>
              <ConfirmIcon />
            </CustomerAdditionConfirmModalIconWrapper>
            <Box marginBottom={6} paddingX={2}>
              <Stack spacing={3}>
                <Typography variant={'h4ExtraBold'} textAlign={'center'}>
                  {t('batches.payModeEditConfirmationModal.title')}
                </Typography>
                <Typography variant="body2" textAlign="center" color={'gray.700'}>
                  {t('batches.payModeEditConfirmationModal.content', {
                    selectedPayMode: handlePayModeDisplay(selectedPayMode),
                    arrivalDate: dateFormatMMMSpaceDDYYYY(arrivalDate),
                  })}
                </Typography>
              </Stack>
            </Box>
            <Box marginBottom={2} justifyContent={'center'} display={'flex'}>
              <Button
                variant="containedLarge"
                color="primary"
                type="button"
                fullWidth
                onClick={onYesButtonClick}
                sx={{ overflow: 'hidden', width: 120 }}
              >
                {t('buttonTexts.doneButton')}
              </Button>
            </Box>
          </ModalContainer>
        )}
      </ModalWrapper>
    </>,
    domNode
  );
};
