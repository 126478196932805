import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';

import {
  Wrapper,
  ContentContainerBottom,
  HeadingSummaryBlock,
  AccountNumberCard,
  FormWrapper,
  FormInput,
  Messages,
  ManualLoader,
  FinancesTermsModal,
} from 'src/components/base/core';
import { Box, Button } from '@mui/material';
import { Images } from 'src/shared/utilities/images';

import { getBankAction } from '../../../store/actions/account';
import { RootState } from 'src/store';
import { BankDetailsParams, BankSuccessLocationState } from 'src/shared/utilities/interface';
import { ERROR_MESSAGES } from 'src/constants/Validation';
import {
  useGetAccountQuery,
  useTreasuryFinancialCardMutation,
  useUpdateBankAccountFieldsMutation,
} from 'src/services/account/accountServiceApi';
import { handleMissingKYCDetails } from 'src/helpers/helperHandleAccountValues';

export const BankSetupSuccessContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const bankAccount = useSelector((state: RootState) => state.bank);
  const [updateBankAccount, { isLoading }] = useUpdateBankAccountFieldsMutation();
  const [errorMessage, setErrorMessage] = useState('');
  const [termsModalOpen, setTermsModalOpen] = useState<boolean>(false);

  const { data: account } = useGetAccountQuery(null);
  const [cardCreation, { isLoading: isLoadingFATeemsAccept }] = useTreasuryFinancialCardMutation();

  const {
    fromSettings,
    fromHome,
    isSettingDesktop,
    isFromFinance,
    isFromPayMode,
    isFromFinConnection,
  } =
    location?.state != null
      ? (location?.state as BankSuccessLocationState)
      : {
          fromSettings: false,
          fromHome: false,
          isSettingDesktop: false,
          isFromFinance: false,
          isFromPayMode: false,
          isFromFinConnection: false,
        };

  const handleNextButtonClick = () => {
    localStorage.removeItem('enable_hold_sucsess');
    localStorage.removeItem('signup_done');
    localStorage.removeItem('lastFourDigits');
    localStorage.removeItem('finBankAccountId');
    setTermsModalOpen(false);

    if (account) {
      const isPayModeInstantPayout = account?.default_pay_mode === 'debit_card';
      const isKYCDetailsMissing = handleMissingKYCDetails(account);

      if (isPayModeInstantPayout && !isKYCDetailsMissing && account?.business_type === 'company') {
        navigate('/business-representative', {
          state: { isFromFinance: true, isFromAccountSuccessScreen: true },
        });
      } else if (
        isPayModeInstantPayout &&
        isKYCDetailsMissing &&
        account?.business_type === 'individual'
      ) {
        setTermsModalOpen(true);
      } else {
        if (fromSettings && !isSettingDesktop && !isFromFinance) {
          navigate('/settings/bank-setup');
        } else if (fromSettings && isSettingDesktop && !isFromFinance) {
          navigate('/settings');
        } else if (fromHome && !isFromFinance) {
          navigate('/dashboard');
        } else if (isFromFinance) {
          navigate('/finance/account/move-money');
        } else if (isFromPayMode) {
          navigate('/settings/terms-selection');
        } else {
          localStorage.setItem('enable_hold_sucsess', 'true');
          localStorage.removeItem('account_id');
          navigate('/hold-sucess');
        }
      }
    }
  };

  useEffect(() => {
    const id = localStorage.getItem('account_id') as string;
    bankAccount == null && dispatch<any>(getBankAction(id));

    if (isFromFinConnection) {
      setValue('nickname', '');
    }
  }, []);

  const bankAccountNumber = useMemo(() => localStorage.getItem('lastFourDigits'), []);
  const bankAccountId = useMemo(() => localStorage.getItem('finBankAccountId'), []);

  const schema = yup
    .object({
      nickname: isFromFinConnection
        ? yup.string().required(ERROR_MESSAGES.REQUIRED)
        : yup.string().notRequired(),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<BankDetailsParams>({
    resolver: yupResolver(schema),
  });

  const appLogin = handleSubmit(async (data) => {
    if (isFromFinConnection) {
      const setUpdateParams = {
        id: bankAccountId,
        field: 'nickname',
        body: {
          nickname: data.nickname,
        },
      };

      const resp: any = await updateBankAccount(setUpdateParams);

      if (resp && resp?.error) {
        // @ts-ignore
        setErrorMessage(
          resp?.error?.data?.error ?? resp?.error?.data?.message ?? resp?.error?.error
        );
      } else {
        handleNextButtonClick();
        setValue('nickname', '');
      }
    } else {
      handleNextButtonClick();
    }
  });

  const updateBindingsChange = (event: any, feild: any) => {
    setValue(feild, event.target.value);
  };

  const onTermsModalClose = () => setTermsModalOpen(false);

  const onAcceptButtonClick = async () => {
    setErrorMessage('');
    const response: any = await cardCreation(null);

    if (response) {
      if (response.error) {
        setErrorMessage(response.error.data.message ?? response.error.data.error);
        setTermsModalOpen(false);
      } else {
        navigate('/finance/account-loading', {
          state: { isFromAccountSuccessScreen: true },
        });
        setErrorMessage('');
        setTermsModalOpen(false);
        localStorage.setItem('cardIssue', 'processed');
      }
    }
  };

  return (
    <>
      <Wrapper>
        {(isLoading || isLoadingFATeemsAccept) && (
          <ManualLoader isLoaderEnable={isLoadingFATeemsAccept ? false : true} />
        )}
        <FormWrapper onSubmit={appLogin} className="no_fullscreen">
          <Box component={'div'} className={'container_content no_header'}>
            <Box component={'div'} className={'container_content_top add_bottom_padding'}>
              <div className="status_image">
                <img src={Images.SIGNUP_SUCCESS} alt="Status" height={200} />
              </div>
              <div className="content_block">
                <HeadingSummaryBlock
                  heading={t('headingContent.statusses.bankSetupSuccess.heading')}
                  headingAlignment="center"
                  content={
                    isFromFinConnection
                      ? t('headingContent.statusses.bankSetupSuccess.contentForFinConnection')
                      : t('headingContent.statusses.bankSetupSuccess.contentForManualSetup')
                  }
                  contentAlignment="left"
                />
              </div>
              <div className="addition_element">
                <AccountNumberCard
                  // @ts-ignore
                  accountNumber={bankAccountNumber}
                />
                {isFromFinConnection && (
                  <Controller
                    control={control}
                    name="nickname"
                    render={({ field: { onChange, value } }) => (
                      <FormInput
                        label={t('formInput.nickName')}
                        id={'nickname'}
                        {...register('nickname')}
                        error={errors?.nickname?.message}
                        maxLength={9}
                        onChange={(event: any) => {
                          event.target.value = event.target.value.replace('  ', ' ');
                          updateBindingsChange(event, 'nickname');
                        }}
                        value={value}
                      />
                    )}
                  />
                )}
              </div>
              {errorMessage !== '' && (
                <Messages
                  messageHeading="PayUp - Vendor Web App"
                  messageContent={errorMessage}
                  closeEvent={() => setErrorMessage('')}
                ></Messages>
              )}
            </Box>
          </Box>
          <ContentContainerBottom className={'no_note no_fullscreen'}>
            <Button
              variant="containedLarge"
              color="primary"
              type="submit"
              fullWidth
              sx={{ overflow: 'hidden' }}
            >
              {t('buttonTexts.nextButton')}
            </Button>
          </ContentContainerBottom>
        </FormWrapper>
      </Wrapper>

      {termsModalOpen && (
        <FinancesTermsModal
          onAcceptButtonClick={onAcceptButtonClick}
          closeIdModalEvent={onTermsModalClose}
          t={t}
        />
      )}
    </>
  );
};
