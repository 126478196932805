import { useState } from 'react';
import {
  useCreateCustomerMutation,
  useDeleteCustomerRequestMutation,
  useUpdateCustomerRequestMutation,
} from 'src/services/customer/customerServiceApi';

export const initialStates = {
  openModal: false,
  comapnySelection: [],
};

export const handleCustomerInfo = () => {
  const [states, setStates]: any = useState(initialStates);
  const [isLoading, setIsLoading] = useState(false);
  const [createCustomer] = useCreateCustomerMutation();
  const [updateCustomerRequest] = useUpdateCustomerRequestMutation();
  const [deleteCustomerRequest] = useDeleteCustomerRequestMutation();
  const handleCompanySelection = (event: any) => {
    let setComapnySelection: any = states?.comapnySelection; // get the selected term property value from termsSetDetails state
    const getCurrentTargetValue = event?.currentTarget?.value;

    if (event?.currentTarget?.checked !== undefined && event?.currentTarget?.checked) {
      setComapnySelection = [...setComapnySelection, getCurrentTargetValue];

      setStates &&
        setStates((states: any) => ({
          ...states,
          comapnySelection: setComapnySelection,
        }));
    } else {
      setComapnySelection =
        setComapnySelection &&
        setComapnySelection?.filter(function (item: any) {
          return item !== getCurrentTargetValue;
        });

      setStates &&
        setStates({
          ...states,
          comapnySelection: setComapnySelection,
        });
    }
  };

  const deleteCustomerHandler = async (id: string, handleModalClose: any) => {
    const deleteResponse = await deleteCustomerRequest(id);
    if (deleteResponse) {
      handleModalClose();
    }
  };

  const addCustomer = async (
    data: any,
    setCustomerAddCompleted: any,
    handleModalClose: any,
    setCustomerCount: any
  ) => {
    try {
      if (data) {
        setIsLoading(true);
        setCustomerCount(data?.customer?.length);
        let promises = data.customer.map((item: any) =>
          createCustomer({
            email: item.email,
            city: item.city,
            business_name: item.companyName,
            phone: item?.phone_number?.replace(/[^0-9]/g, ''),
            contact_name: item.contactName,
          }).unwrap()
        );
        let response = await Promise.all(promises);
        // @ts-ignore
        if (response?.length > 0) {
          setCustomerAddCompleted(true);
          handleModalClose();
        }

        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateCustomer = async (data: any, setCustomerAddCompleted: any, handleModalClose: any) => {
    try {
      if (data) {
        setIsLoading(true);
        if (data.customer[0]?.id) {
          const updataData = data.customer[0];
          const updateResponse = await updateCustomerRequest({
            email: updataData.email,
            city: updataData.city,
            business_name: updataData.companyName,
            phone: updataData?.phone_number?.replace(/[^0-9]/g, ''),
            contact_name: updataData.contactName,
            id: updataData?.id,
          });
          if (updateResponse) {
            setCustomerAddCompleted(true);
            handleModalClose();
          }
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return [
    handleCompanySelection,
    states,
    setStates,
    addCustomer,
    isLoading,
    deleteCustomerHandler,
    updateCustomer,
  ];
};
