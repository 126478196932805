import { useNavigate } from 'react-router-dom';
import { TdContainer } from '../TransactionDataTable.style';
import { handleTransactionCreatedAt } from 'src/helpers/helperHandlingTableGrid';
import { Typography } from '@mui/material';

export const FirstCell = (props: any) => {
  const navigate = useNavigate();
  const getPropsOriginalData = props?.props?.row?.original;
  const { custom_description, created, id } = getPropsOriginalData;

  const date = handleTransactionCreatedAt(created);
  const contentText = custom_description;
  const { routeState } = props?.props;

  return (
    <TdContainer>
      <Typography
        className={`first-link-text-transactions`}
        onClick={() => navigate(`/finance/account/transaction/detail/${id}`, { state: routeState })}
        color={'gray.900'}
        component={'span'}
      >
        {contentText}
      </Typography>
      {/* {property && (
        <span className="property-location">{`${property?.name} ${property?.city} ${property?.state}`}</span>
      )} */}
      <span
        onClick={() => navigate(`/finance/account/transaction/detail/${id}`, { state: routeState })}
        className="date_val hidden-desktop"
      >
        {date}
      </span>
    </TdContainer>
  );
};
