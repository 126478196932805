import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PaymentTableHeader } from '../PaymentTableHeader/PaymentTableHeader';
import { Pagination } from '../Pagination/Pagination';
import { PaymentDataTable } from '../PaymentDataTable/PaymentDataTable';
import { FilterModal } from '../Filter/FilterModal/FilterModal';

import { initialTermsDetail } from '../../../../../../../shared/utilities/initialStates';
import { PaginationLoader, PaginationLoaderWrapper, VerticalSpace } from './PaymentTableGrid.style';

import { GridContext } from 'src/contexts/gridContext';
import { configs } from 'src/shared/config/config';
import { DownloadCSVState, invoiceBatchTabsType } from 'src/shared/utilities/interface';
import { DownloadModal } from '../Download/DownloadModal/DownloadModal';
import { ModalBatchPayModeEdit } from 'src/components/base/core';
import { dateFormatWithTimeZoneForEarning } from 'src/shared/utilities/dob';

export interface PaymentTableGridProps {
  termsSetDetails?: any;
  handleInvoiceToggle?: () => void;
  handleTermClick?: any;
  handleMultiplePaymentSelection?: any;
  handleShowMore?: any;
  dataObject?: any;
  isOldInvoiceGrid?: boolean;
  isTermEditable?: boolean;
  offset?: number;
  pageCount?: number;
  paginationDisplayCondition?: boolean;
  isAccordion?: boolean;
  isHandleArrowClickable?: boolean;
  tabType?: invoiceBatchTabsType;
  showLoader?: boolean;
  accordionHeading?: string;
  tooltipContent?: any;
  isTooltipInfoEnabled?: boolean;
  onModalClose?: () => void;
  handleDataSorting?: any;
  onClickApplyFilter?: any;
  selectedSortingOption?: string;
  onFilterReset?: () => void;
  setFilterAmountRange?: any;
  setSelectedFilterOptions?: (filterObj: any) => void;
  selectedFilterOptions?: any;
  resetFilter?: any;
  setSelectedDateFilterOptions?: (filterObj: any) => void;
  selectedDateFilters?: any;
  minmumAmount?: any;
  maximumAmount?: any;
  onDateChange?: (filterObj: any) => void;
  selectedDateRange?: any;
  isFilterEnable?: boolean;
  setSelectedDateRange?: (filterObj: any) => void;
  setAllSelect?: any;
  isAllSelect?: any;
  isAccordionArrowVisible?: boolean;
  isSelectTagVisible?: boolean;
  dataCount?: string | number;
  closeEditTerms?: any;
  searchInProgress?: boolean;
  enableQuerySearch?: boolean;
  savedMinmumAmount?: any;
  savedMaximumAmount?: any;
  iconArrowVisible?: boolean;
  selectedRowCount?: number;
  onRowCountChange?(param: number): void;
  downloadStates?: DownloadCSVState;
  setDownloadStates?: (data: DownloadCSVState) => void;
  isTransactionGrid?: boolean;
  searchText?: string;
  setTriggerTheData?: any;
  triggerTheData?: boolean;
  /** Start Paymode Edit related types */
  onBachPayModeClick?: any;
  onBachPayModeClose?: any;
  invoiceDetailsData?: {
    method: string;
    estimated_payout_date: string;
    estimated_approved_date: string;
    payoutId: string;
    estimated_arival_date: string;
  };
  paymodeEditModalVisible?: boolean;
  /** End Paymode Edit related types */
}

const paginationAlwaysVisiblePAY2637 = true;

export const PaymentTableGrid = ({
  termsSetDetails = initialTermsDetail,
  handleInvoiceToggle,
  handleTermClick,
  handleShowMore,
  dataObject,
  handleMultiplePaymentSelection,
  isOldInvoiceGrid,
  isTermEditable,
  offset,
  pageCount,
  paginationDisplayCondition,
  isAccordion = false,
  isHandleArrowClickable = true,
  tabType = 'submitted',
  showLoader = false,
  accordionHeading,
  tooltipContent,
  isTooltipInfoEnabled = true,
  onModalClose,
  handleDataSorting,
  onClickApplyFilter,
  selectedSortingOption,
  onFilterReset,
  setFilterAmountRange,
  setSelectedFilterOptions,
  selectedFilterOptions,
  resetFilter,
  setSelectedDateFilterOptions,
  selectedDateFilters,
  minmumAmount = 0,
  maximumAmount = 100,
  onDateChange,
  selectedDateRange,
  isFilterEnable,
  setSelectedDateRange,
  setAllSelect,
  isAllSelect,
  isAccordionArrowVisible,
  isSelectTagVisible,
  dataCount,
  closeEditTerms,
  searchInProgress,
  savedMinmumAmount,
  savedMaximumAmount,
  iconArrowVisible,
  selectedRowCount = configs.DEFAULT_LIMIT,
  onRowCountChange,
  downloadStates,
  setDownloadStates,
  isTransactionGrid,
  searchText,
  /** Start Paymode Edit related props */
  onBachPayModeClick,
  onBachPayModeClose,
  invoiceDetailsData,
  paymodeEditModalVisible,
}: /** End Paymode Edit related props */
PaymentTableGridProps) => {
  const { t } = useTranslation();

  const gridContextAccordion = React.useContext(GridContext);
  let min = useMemo(() => minmumAmount, []);
  let max = useMemo(() => maximumAmount, []);
  const [selectedMinRange, setSelectedMinRange] = useState({
    min: minmumAmount,
    max: maximumAmount,
  });
  const [enableEditTerm, setEnableEditTerm] = useState(false);
  const [accorStatus, setAccordionStatus] = useState(false);
  const [forceCollapse, setForceCollapse] = useState(false);
  const [initEdit, setInitEdit] = useState(false);

  if (savedMaximumAmount && savedMinmumAmount) {
    min = savedMinmumAmount;
    max = savedMaximumAmount;
  }
  let accordionStatus = gridContextAccordion?.accordion?.isOldInvoice
    ? gridContextAccordion?.accordion?.activeAccordionOld
    : gridContextAccordion?.accordion?.activeAccordion;

  let showTableEmptyMessage =
    (gridContextAccordion?.accordion?.enableAccordion ? accordionStatus : true) &&
    dataObject &&
    dataObject.length === 0;
  if (
    dataObject &&
    dataObject.length === 0 &&
    localStorage.removeItem('activeCurrentInv') === undefined
  ) {
    showTableEmptyMessage = true;
  }
  if (
    gridContextAccordion?.accordion?.activeAccordion == null &&
    !gridContextAccordion?.accordion?.activeAccordionOld &&
    gridContextAccordion?.accordion?.selectedCount > 0 &&
    gridContextAccordion?.accordion?.isOldInvoice &&
    !accordionStatus
  ) {
    accordionStatus = true;
  }
  if (forceCollapse && accordionStatus) {
    accordionStatus = false;
  }

  const toggleWrapperClick = (e: any) => {
    handleInvoiceToggle && handleInvoiceToggle();
  };

  const handleEditTagClickEvent = (isSelected: boolean) => {
    setEnableEditTerm(!enableEditTerm);
    if (initEdit) {
      if (closeEditTerms) {
        !accordionStatus && setAccordionStatus(true);
        closeEditTerms(!isSelected);
      } else {
        !gridContextAccordion?.accordion?.isOldInvoice &&
          !accordionStatus &&
          setAccordionStatus(true);
      }
    } else {
      setInitEdit(true);
      setAccordionStatus(accordionStatus);
    }
  };
  const manualClickRow = (currentStatus: boolean) => {
    if (currentStatus) {
      setForceCollapse(true);
      setAccordionStatus(false);
    } else {
      accorStatus && setAccordionStatus(false);
      !currentStatus && setAccordionStatus(true);
    }
    handleInvoiceToggle && handleInvoiceToggle();
  };

  const accordionOpen = gridContextAccordion?.accordion?.enableAccordion
    ? accordionStatus || accorStatus
    : true;

  const paginationStatus = paginationDisplayCondition && accordionOpen;

  const paginationVisible =
    (paginationAlwaysVisiblePAY2637 || paginationDisplayCondition) && accordionOpen;

  return (
    <>
      {isFilterEnable && !isOldInvoiceGrid && (
        <FilterModal
          onModalClose={onModalClose}
          handleDataSorting={handleDataSorting}
          onClickApplyFilter={onClickApplyFilter}
          selectedSortingOption={selectedSortingOption}
          setSelectedFilterOptions={setSelectedFilterOptions}
          onFilterReset={onFilterReset}
          setFilterAmountRange={setFilterAmountRange}
          selectedFilterOptions={selectedFilterOptions}
          setSelectedDateFilterOptions={setSelectedDateFilterOptions}
          selectedDateFilters={selectedDateFilters}
          resetFilter={resetFilter}
          minmumAmount={min}
          maximumAmount={max}
          onDateChange={onDateChange}
          tabType={tabType}
          selectedDateRange={selectedDateRange}
          selectedMinRange={selectedMinRange}
          setSelectedMinRange={setSelectedMinRange}
          setSelectedDateRange={setSelectedDateRange}
          setAllSelect={setAllSelect}
          isAllSelect={isAllSelect}
          isTransactionGrid={isTransactionGrid}
        />
      )}
      {downloadStates?.isDownloadEnable && setDownloadStates ? (
        <DownloadModal
          downloadStates={downloadStates}
          setDownloadStates={setDownloadStates}
          tabType={tabType}
          isTransactionGrid={isTransactionGrid}
          t={t}
        />
      ) : null}

      {/** Accordion heading */}
      {isAccordion && (
        <PaymentTableHeader
          heading={accordionHeading}
          tooltipContent={tooltipContent}
          arrowClick={() => manualClickRow(accordionStatus || accorStatus)}
          selectClick={handleEditTagClickEvent}
          isActive={accordionStatus || accorStatus}
          isSelectTagClickable={accordionStatus}
          isLast={gridContextAccordion?.accordion?.isOldInvoice}
          isSelectTagClicked={enableEditTerm}
          isTooltipInfoEnabled={isTooltipInfoEnabled}
          isAccordionArrowVisible={isAccordionArrowVisible}
          isSelectTagVisible={isSelectTagVisible}
          dataCount={dataCount}
          toggleWrapperClick={toggleWrapperClick}
        />
      )}

      {dataObject && (
        <PaymentDataTable
          isToggle={
            gridContextAccordion?.accordion?.enableAccordion ? accordionStatus || accorStatus : true
          }
          dataObject={dataObject}
          isOldInvoices={isOldInvoiceGrid}
          isHandleArrowClickable={isHandleArrowClickable}
          isTermEditable={isTermEditable ? isTermEditable : enableEditTerm}
          isTermEditEnabled={enableEditTerm}
          tabType={tabType}
          onClickTermId={handleTermClick}
          onTermSelection={handleMultiplePaymentSelection}
          isTermClicked={termsSetDetails.isTermClicked}
          selectedPayoutId={termsSetDetails.id}
          isAllSelected={termsSetDetails.isAllTermChecked}
          searchInProgress={searchInProgress}
          iconArrowVisible={iconArrowVisible}
          isTransactionGrid={isTransactionGrid}
          searchText={searchText}
          onBachPayModeClick={onBachPayModeClick}
        />
      )}
      {showLoader ? (
        <PaginationLoaderWrapper className={tabType}>
          <PaginationLoader />
        </PaginationLoaderWrapper>
      ) : paginationVisible ? (
        <VerticalSpace />
      ) : null}

      {/** Pagination */}
      {paginationVisible && (
        <Pagination
          handleShowMore={handleShowMore}
          offset={offset}
          pageCount={pageCount}
          tabType={tabType}
          selectedRowCount={selectedRowCount}
          onRowCountChange={onRowCountChange}
          showOffsetChangeButtons={paginationStatus}
        />
      )}

      {/**
       * Paymode Edit modal
       */}
      {paymodeEditModalVisible && (
        <ModalBatchPayModeEdit
          onModalClose={onBachPayModeClose}
          activePayMode={invoiceDetailsData?.method}
          estimatedPayoutDate={invoiceDetailsData?.estimated_payout_date ?? ''}
          transferId={invoiceDetailsData?.payoutId}
          paymentTriggeredDate={dateFormatWithTimeZoneForEarning(
            invoiceDetailsData?.estimated_payout_date
          )}
          t={t}
          paymentArrivalEstimatedDate={invoiceDetailsData?.estimated_arival_date ?? ''}
        />
      )}
    </>
  );
};
