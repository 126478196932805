import {
  ActionCardSliderWrapper,
  HorizontalScroller,
  ActionCard,
  ActionCardContainer,
  ActionCardIcon,
  ActionCardHeading,
  ActionCardLink,
  IconArrow,
  ActionCardWrapper,
} from './ActionCardSlider.style';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LargeIcons } from 'src/shared/utilities/images';
import { Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { removeFriendReferrals } from 'src/constants/constants';

export const ActionCardSlider = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ActionCardSliderWrapper className="hidden-desktop">
      <HorizontalScroller>
        <ActionCard>
          <ActionCardWrapper
            onClick={() => navigate('/settings/customers-addition', { state: { fromHome: true } })}
          >
            <ActionCardContainer>
              <ActionCardIcon>
                <img src={LargeIcons.inviteCustomer} alt="Invite customers" />
              </ActionCardIcon>
              <ActionCardHeading>
                <Typography variant="h1ExtraBold" component={'p'} color={'gray.800'}>
                  {t('actionCards.inviteCustomer.heading')}
                </Typography>
              </ActionCardHeading>
              <ActionCardLink>
                <Typography variant="h1Bold" component={'p'} color={'primary'}>
                  {t('actionCards.inviteCustomer.content')}
                </Typography>
                <IconArrow>
                  <KeyboardArrowRightIcon color="primary" />
                </IconArrow>
              </ActionCardLink>
            </ActionCardContainer>
          </ActionCardWrapper>
        </ActionCard>

        {!removeFriendReferrals && (
          <ActionCard
            onClick={() => navigate('/settings/referrals', { state: { fromHome: true } })}
          >
            <ActionCardWrapper>
              <ActionCardContainer>
                <ActionCardIcon>
                  <img src={LargeIcons.inviteFriend} alt="Invite friends" />
                </ActionCardIcon>
                <ActionCardHeading>
                  <Typography variant="h1ExtraBold" component={'p'} color={'gray.800'}>
                    {t('actionCards.inviteFriend.heading')}
                  </Typography>
                </ActionCardHeading>
                <ActionCardLink>
                  <Typography variant="h1Bold" component={'p'} color={'primary'}>
                    {t('actionCards.inviteFriend.content')}
                  </Typography>
                  <IconArrow>
                    <KeyboardArrowRightIcon color="primary" />
                  </IconArrow>
                </ActionCardLink>
              </ActionCardContainer>
            </ActionCardWrapper>
          </ActionCard>
        )}

        <ActionCard
          onClick={() => navigate('/settings/notifications', { state: { fromHome: true } })}
        >
          <ActionCardWrapper>
            <ActionCardContainer>
              <ActionCardIcon>
                <img src={LargeIcons.inviteFriend} alt="Invite friends" />
              </ActionCardIcon>
              <ActionCardHeading>
                <Typography variant="h1ExtraBold" component={'p'} color={'gray.800'}>
                  {t('actionCards.enableNotifications.heading')}
                </Typography>
              </ActionCardHeading>
              <ActionCardLink>
                <Typography variant="h1Bold" component={'p'} color={'primary'}>
                  {t('actionCards.enableNotifications.content')}
                </Typography>
                <IconArrow>
                  <KeyboardArrowRightIcon color="primary" />
                </IconArrow>
              </ActionCardLink>
            </ActionCardContainer>
          </ActionCardWrapper>
        </ActionCard>
      </HorizontalScroller>
    </ActionCardSliderWrapper>
  );
};
