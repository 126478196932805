import { styled } from '@mui/material/styles';

export const TermCardTimeLineWrapper = styled('div')(
  ({ theme }) => `
  display: flex;
  width: 100%;
  position: relative;
  margin-bottom: ${theme.spacing(3)};

  &:before {
    content: '';
    position: absolute;
    left: 0;
    height: 1px;
    top: calc(50% - 1px);
    display: block;
    border-bottom: 1px solid ${theme.palette.gray[100]};
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    top: calc(50% - 1px);
    display: block;
    border-bottom: 1px dashed ${theme.palette.gray[100]};
  }

  &.align_7 {
    &:before {
      right: calc(100% - 110px);
    }
  }

  &.align_30{
    &:before {
      right: 0;
    }

    &:after {
      display: none;
    }
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    &.align_14 {
      &:before {
        right: calc(100% - (150px + (((100% - 150px) * 9)/100) + 32px));
      }
    }
  
    &.align_21{
      &:before {
        right: calc(100% - (150px + (((100% - 150px) * 22)/100) + 32px));
      }
    }
  
    &.align_28{
      &:before {
        right: calc(100% - (150px + (((100% - 150px) * 37)/100) + 32px));
      }
    }
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    &.align_14 {
      &:before {
        right: calc(100% - (150px + (((100% - 150px) * 25)/100) + 32px));
      }
    }
  
    &.align_21{
      &:before {
        right: calc(100% - (150px + (((100% - 150px) * 54)/100) + 32px));
      }
    }
  
    &.align_28{
      &:before {
        right: calc(100% - (150px + (((100% - 150px) * 67)/100) + 32px));
      }
    }
  }
`
);

export const TermCardTimeLineLeft = styled('div')`
  display: flex;
  width: 88px;
  position: relative;
  z-index: 1;
`;

export const TermCardTimeLineMiddle = styled('div')(
  ({ theme }) => `
  display: flex;
  width: calc(100% - 138px);
  position: relative;
  z-index: 1;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    &.align_14 {
      padding-left: 22%;
    }
  
    &.align_21 {
      padding-left: 32%;
    }
  
    &.align_28 {
      padding-left: 48%;
    }
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    &.align_14 {
      padding-left: 34.5%;
    }
  
    &.align_21 {
      padding-left: 48%;
    }
  
    &.align_28 {
      padding-left: 65%;
    }

    &.modla_layout {
      &.align_14 {
        padding-left: 22%;
      }
    
      &.align_21 {
        padding-left: 32%;
      }
    
      &.align_28 {
        padding-left: 48%;
      }
    }
  }
`
);

export const TermCardTimeLineRight = styled('div')`
  display: flex;
  width: 50px;
  justify-content: flex-end;
  position: relative;
  z-index: 1;
`;

export const TermCardTimeLineRange = styled('div')(
  ({ theme }) => `
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;

  &.incoiced_submitted {
   justify-content: flex-start;
   width: 55px;

   .tctl_bottom {
    text-align: left;
   }
  }

  &.incoiced_approved {
    width: 80px;

   .tctl_top {
    position: relative;
    left: -20px;
    width: 80px;
   }
  }

  &.get_paid {
   width: 50px;
   justify-content: center;

   .tctl_top {
    display: flex;
    justify-content: center;
    align-items: end;
   }

   .tctl_top,
   .tctl_middle {
    justify-content: center;
   }
  }

  &.customer_pays {
   justify-content: flex-end;
   width: 55px;

   .tctl_middle {
    justify-content: flex-end;
   }

   .tctl_bottom {
    text-align: right;
   }
  }

  .tctl_top,
  .tctl_bottom,
  .tctl_middle {
   width: 100%;
  }

  .tctl_top,
  .tctl_bottom {
   font-weight: 500;
   font-size: 10px;
   line-height: 12px;
   color: ${theme.palette.gray[400]};
   text-align: center;
   height: 24px;
  }

  .tctl_top,
  .tctl_middle{
   margin-bottom: ${theme.spacing(2)};
  }

  .tctl_middle {
   height: 40px;
   display: flex;

   &_top,
   &_middle,
   &_bottom  {
    color: ${theme.palette.gray[700]};
   }

   &_top {
    font-weight: 600;
    font-size: 12px;
   }

   &_middle {
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
   }

   &_bottom {
    font-weight: 500;
    font-size: 8px;
    margin-top: -1px;
   }

   &_container {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 4px solid ${theme.palette.white}; 
   }

   .incoiced_approved,
   .incoiced_submitted {
    background: ${theme.palette.blue[75]}; 
   }

   .get_paid {
    background: ${theme.palette.blue[400]}; 

    .tctl_middle {
     &_top,
     &_middle,
     &_bottom  {
      color: ${theme.palette.white};
     }
    }
   }

   .customer_pays {
    background: ${theme.palette.yellow[75]}; 
   }
  }

  .tctl_top {
   &_icon {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.palette.blue[75]}; 

    svg {
     color: ${theme.palette.blue[400]}; 
     height: 14px;
    }
   }
  }
`
);
