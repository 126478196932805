import moment from 'moment';
import { useEffect, useState } from 'react';
import { accountCompany } from 'src/models/account/account.service.mapper';
import {
  useGetAccountQuery,
  useUpdateAccountMutation,
} from 'src/services/account/accountServiceApi';
import {
  AddAditionalPhoneDetails,
  deleteAditionalPhoneDetails,
} from 'src/services/account/additional.phone.service';
import { pre_fill_params } from 'src/shared/content/content';

import { DOB } from 'src/shared/utilities/interface';

export const handleBusinessRepAddress = (setAddressError?: any) => {
  const { data, isFetching } = useGetAccountQuery(
    { count: 5 },
    { refetchOnMountOrArgChange: true }
  );
  const [updateAccount] = useUpdateAccountMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [accountCompanyData, setAccountData]: any = useState();
  useEffect(() => {
    if (data) {
      setAccountData(data);
    }
  }, [data]);

  const appSubmit = async (data: any, onFormSubmit: any, isBack: any, navigate: any) => {
    try {
      setIsLoading(true);
      if (accountCompanyData) {
        if (accountCompanyData?.individual?.dob?.day === null) {
          delete data.dob;
        }
        const updateFormData = {
          ...data,
          inputDescription: pre_fill_params.description,
          inputMCC: pre_fill_params.mcc,
          url: pre_fill_params.url,
          line1: data.line1,
          line2: data.line2,
          city: data.city,
          postal_code: data.postal_code,
          state: data.state,
          inputCompanyPhone: data?.inputCompanyPhone?.replace(/[^0-9]/g, ''),
          phone_number: data?.phone?.replace(/[^0-9]/g, ''),
        };

        const updateObj = accountCompany(updateFormData, false, false);
        const accountUpdateResponse: any = await updateAccount(updateObj);
        // @ts-ignore
        if (accountUpdateResponse?.data) {
          let addressError = false;
          const checkAddressError = accountUpdateResponse?.data?.errors;

          checkAddressError.findIndex((object: any) => {
            if (object.code === 'invalid_address_po_boxes_disallowed') {
              setAddressError(object.reason);
              addressError = true;
            } else {
              setAddressError('');
              addressError = false;
            }
          });

          if (!addressError) {
            localStorage.removeItem('isBack');
            if (isBack) {
              navigate('/business-review');
            } else {
              onFormSubmit();
            }
          }
        } else {
          // @ts-ignore
          if (accountUpdateResponse?.error) {
            // @ts-ignore
            setErrorState('errors');
            setAddressError(
              accountUpdateResponse?.error?.data?.error ??
                accountUpdateResponse?.error?.data?.message ??
                accountUpdateResponse?.error?.error
            );
          }
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return [appSubmit, isLoading, accountCompanyData];
};

export const handleBusinessRepSSN = () => {
  const { data, isFetching } = useGetAccountQuery({ refetchOnMountOrArgChange: true });
  const [updateAccount] = useUpdateAccountMutation();
  const [isLoading, setIsLoading] = useState(false);

  const [accountData, setAccountData]: any = useState();
  useEffect(() => {
    if (data) {
      setAccountData(data);
    }
  }, [data]);

  const appSubmit = async (
    data: any,
    onFormSubmit: any,
    enableSSNUpdate: any,
    openIdModal: any
  ) => {
    try {
      setIsLoading(true);
      if (accountData) {
        if (!enableSSNUpdate) {
          onFormSubmit();
          return;
        }
        if (accountData?.individual?.dob?.day === null) {
          delete data.dob;
        }
        if (!openIdModal) {
          const getIDNumber = data.idNumber;
          const getLastFour = getIDNumber.slice(-4);
          data.idNumber = getLastFour;
        }
        let updatedData = null;
        if (accountData?.charges_enabled) {
          updatedData = {
            ...data,
            inputCompanyPhone: data?.inputCompanyPhone?.replace(/[^0-9]/g, ''),
            phone_number: data?.phone?.replace(/[^0-9]/g, ''),
            inputDescription: pre_fill_params.description,
            inputMCC: pre_fill_params.mcc,
            url: pre_fill_params.url,
            tos_shown_and_accepted: true,
          };
        } else {
          updatedData = {
            ...data,
            inputCompanyPhone: data?.inputCompanyPhone?.replace(/[^0-9]/g, ''),
            phone_number: data?.phone?.replace(/[^0-9]/g, ''),
            inputDescription: pre_fill_params.description,
            inputMCC: pre_fill_params.mcc,
            url: pre_fill_params.url,
          };
        }

        const updateObj = accountCompany(updatedData, true, false);
        const accountUpdateResponse = await updateAccount(updateObj);
        // @ts-ignore
        if (accountUpdateResponse?.data) {
          onFormSubmit();
        } else {
          // @ts-ignore
          const responseError = accountUpdateResponse?.error?.data;
          if (responseError) {
            // @ts-ignore
            setErrorState('errors');
          }
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
    setIsLoading(false);
  };

  return [appSubmit, isLoading];
};

export const handleBusinessRepOwnership = () => {
  const { data, isFetching } = useGetAccountQuery({ refetchOnMountOrArgChange: true, skip: false });
  const [updateAccount] = useUpdateAccountMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [serverSideErrors, setServerSideErrors] = useState('');

  const [accountData, setAccountData]: any = useState();
  useEffect(() => {
    if (data) {
      setAccountData(data);
    }
  }, [data]);

  const isNullDOB = (dob: DOB) => {
    return !dob?.day || !dob?.month || !dob?.year;
  };

  /**
   * add additonal phone number
   */
  const addAditionalPhone = async (additonalNumber: string, id: any) => {
    return await AddAditionalPhoneDetails([additonalNumber.replace(/[^0-9]/g, '')], id);
  };

  const deleteAditionalPhone = async (additonalNumber: string, id: any) => {
    return await deleteAditionalPhoneDetails([`${additonalNumber.replace(/[^0-9]/g, '')}`], id);
  };

  const onChangeHandlerPhone = (val: any, getValues: any, setValue: any, btnSubmitted: any) => {
    setValue('phone_number', val); // Set value
  };

  const onChangeHandlerDateInput = (val: any, setValue: any, setErrorManualDateType: any) => {
    if (val) {
      const dateMoment = moment(val);
      const toDate = moment(val).toDate();
      const dateWithFormat = moment(toDate).format('MM/DD/yyyy');
      if (dateWithFormat === 'Invalid date') {
        setErrorManualDateType(true);
      }
      if (
        !dateMoment?.isValid() ||
        !toDate?.getFullYear() ||
        toDate?.getFullYear() < 1900 ||
        (toDate?.getFullYear() > new Date().getFullYear() && val.length !== 10)
      ) {
        setErrorManualDateType(false);
      } else {
        const formattedDate = moment(toDate).format('MM/DD/yyyy');
        const years = moment().diff(formattedDate, 'years', true);
        if (years < 18) {
          val.length === 10 ? setErrorManualDateType(true) : setErrorManualDateType(false);
        } else {
          val.length < 9 ? setErrorManualDateType(false) : setErrorManualDateType(false);
        }
      }
    }
    setValue('dob', val); // Set value
  };

  const isNot18 = (val: any) => {
    const dateMoment = moment(val);
    const toDate = moment(val).toDate();
    const dateWithFormat = moment(toDate).format('MM/DD/yyyy');
    const years = moment().diff(dateWithFormat, 'years', true);
    if (years < 18) {
      return val.length === 10 ? false : true;
    }
    return true;
  };

  const appSubmit = async (
    data: any,
    onFormSubmit: any,
    needToDeleteNumber: any,
    setVerifyCodeContainerVisible?: any
  ) => {
    try {
      setIsLoading(true);
      setServerSideErrors('');
      if (accountData) {
        data.dob = data.dob = moment(data?.dob).format('MMDDYYYY');
        const updatedData = {
          ...data,
          phone_number: data?.phone_number?.replace(/[^0-9]/g, ''),
          inputCompanyPhone: data?.inputCompanyPhone?.replace(/[^0-9]/g, ''),
        };
        const updateObj = accountCompany(updatedData, true, false);
        const accountUpdateResponse = await updateAccount(updateObj);
        // @ts-ignore
        if (accountUpdateResponse?.data) {
          if (data.phone_number_2 === needToDeleteNumber) {
            onFormSubmit();
          } else {
            // @ts-ignore
            const id = accountUpdateResponse?.data?.id;
            if (data.phone_number_2) {
              if (needToDeleteNumber !== '') {
                await deleteAditionalPhone(needToDeleteNumber, id);
              }
              const response = await addAditionalPhone(data.phone_number_2, id);
              if (response?.data.length > 0) {
                onFormSubmit();
              } else {
                setServerSideErrors(response?.error);
                setVerifyCodeContainerVisible && setVerifyCodeContainerVisible(false);
              }
            } else {
              if (data.phone_number_2 === '' && needToDeleteNumber) {
                await deleteAditionalPhone(needToDeleteNumber, accountData.id);
              }
              onFormSubmit();
            }
          }
        } else {
          // @ts-ignore
          const responseError = accountUpdateResponse?.error?.data?.message;
          if (responseError && typeof responseError === 'string') {
            setServerSideErrors(responseError);
            setVerifyCodeContainerVisible && setVerifyCodeContainerVisible(false);
          }
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setVerifyCodeContainerVisible && setVerifyCodeContainerVisible(false);
    }
  };

  const isValidDOB = (value: any) => {
    const dateMoment = moment(value);
    return dateMoment?.isValid();
  };

  return [
    appSubmit,
    isLoading,
    onChangeHandlerPhone,
    onChangeHandlerDateInput,
    isNullDOB,
    serverSideErrors,
    setServerSideErrors,
    isNot18,
    isValidDOB,
  ];
};
