import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../components/base/core/Modals/Modal/Modal';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { FormEditSubmition } from 'src/containers/Account/SignUp/Individual/Review/FormEditSubmition/FormEditSubmition';
import { FormEditBusinesslDetailsSubmition } from 'src/containers/Account/SignUp/Business/Review/FormEditBusinesslDetailsSubmition/FormEditBusinesslDetailsSubmition';
import { FormEditBusinesslDetailsSubmition as FormEditBusinesslDetailsSubmitionNew } from 'src/containers/Account/SignUp/Business/LetsReview/FormEditBusinesslDetailsSubmition/FormEditBusinesslDetailsSubmition';
import { FormEditPersonalDetailsSubmition } from 'src/containers/Account/SignUp/Business/Review/FormEditPersonalDetailsSubmition/FormEditPersonalDetailsSubmition';
import { FormEditPersonalDetailsSubmition as FormEditPersonalDetailsSubmitionNew } from 'src/containers/Account/SignUp/Business/LetsReview/FormEditPersonalDetailsSubmition/FormEditPersonalDetailsSubmition';
import { useFlags } from 'launchdarkly-react-client-sdk';

export type FormProps = {
  companyName: string;
  contactName: string;
  city: string;
  phone_number: string;
};

export type FormValues = {
  customer: {
    companyName: string;
    contactName: string;
    city: string;
    email: string;
    phone_number: string;
    id?: string;
  }[];
};

export const AccountInfoEditModal = ({
  handleCancelEvent,
  onFormSubmit,
  isLoading,
  setResult,
  result,
  navigate,
  bType,
  isPersonal,
  verificationStatus,
  businessInfoVerificationStatus,
  personalInfoVerificationStatus,
  onFormSubmitBusinsessPersonalInfo,
  onFormSubmitBusinsessInfo,
}: any) => {
  const { t } = useTranslation();
  const { reduceTheBusinessFlowFields } = useFlags();

  return (
    <Modal onCloseEvent={handleCancelEvent} modalTitle={t('captions.editDetails')}>
      {isLoading && <ManualLoader />}
      {bType === 'individual' && (
        <FormEditSubmition
          onBackButtonClick={handleCancelEvent}
          onCancelButton={handleCancelEvent}
          onFormSubmit={onFormSubmit}
          setResult={setResult}
          result={result}
          title={t('captions.personalInfo')}
          navigate={navigate}
          isSettings={true}
          isShadowCard
          isVerified={verificationStatus === 'verified'}
        />
      )}
      {bType === 'company' &&
        isPersonal &&
        (!reduceTheBusinessFlowFields ? (
          <FormEditPersonalDetailsSubmition
            title={t('captions.personalDetails')}
            onCancelButton={handleCancelEvent}
            setUpdateData={setResult}
            isSettings={true}
            isShadowCard
            isVerified={verificationStatus === 'verified'}
            onFormSubmit={onFormSubmit}
          />
        ) : (
          <FormEditPersonalDetailsSubmitionNew
            title={t('captions.personalDetails')}
            onCancelButton={handleCancelEvent}
            isSettings={true}
            isShadowCard
            result={result}
            isVerified={personalInfoVerificationStatus === 'verified'}
            onFormSubmit={onFormSubmitBusinsessPersonalInfo}
          />
        ))}
      {bType === 'company' &&
        !isPersonal &&
        (!reduceTheBusinessFlowFields ? (
          <FormEditBusinesslDetailsSubmition
            title={t('captions.businessDetails')}
            onCancelButton={handleCancelEvent}
            setUpdateData={setResult}
            isSettings={true}
            isShadowCard
            isVerified={verificationStatus === 'verified'}
            onFormSubmit={onFormSubmit}
          />
        ) : (
          <FormEditBusinesslDetailsSubmitionNew
            title={t('captions.businessDetails')}
            onCancelButton={handleCancelEvent}
            isSettings={true}
            result={result}
            isShadowCard
            isVerified={businessInfoVerificationStatus === 'verified'}
            onFormSubmit={onFormSubmitBusinsessInfo}
          />
        ))}
    </Modal>
  );
};
