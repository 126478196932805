import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Wrapper } from 'src/components/base/core';
import { FormBusinessInformationSubmition } from './FormBusinessInformationSubmition/FormBusinessInformationSubmition';
import { FormBusinessAddressSubmition } from './FormBusinessAddressSubmition/FormBusinessAddressSubmition';
import { useGetAccountDataMutation } from 'src/services/account/accountServiceApi';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { routeType } from 'src/helpers/containers/helperRoutePreference';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';

type LocationState = {
  isBack?: boolean;
  edit?: boolean;
  backFrom?: string;
  step?: string;
  backFromRepWithAccount?: string;
};

export const BusinessInfoContainer = () => {
  const navigate = useNavigate();
  const [getAccountData]: any = useGetAccountDataMutation();
  const [accountData, setAccountData]: any = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [step1Forward, setStep1Forward] = useState(false);
  const location = useLocation();
  const initalState = {
    step1: false,
    step2: false,
  };
  const [isChangeBusinessType, setIsChangeBusinessType] = useState(false);
  let { isBack, edit, backFrom, step, backFromRepWithAccount } =
    location?.state != null
      ? (location?.state as LocationState)
      : { edit: null, isBack: null, backFrom: '', step: 'step1', backFromRepWithAccount: '' };
  const [step1Completed, setStep1Completed] = useState(false);

  const [stepVisibility, setStepVisibility]: any = useState(initalState);
  const [updatePreferenceOnServer] = handlePreferences();

  useEffect(() => {
    const fetchData = async () => {
      const retryData = await getAccountData();
      !retryData?.error && setAccountData(retryData.data);
    };

    // call the function
    fetchData();
  }, []);

  useEffect(() => {
    if (accountData) {
      if (accountData?.business_type === 'individual') {
        setIsChangeBusinessType(true);
      } else {
        setIsChangeBusinessType(false);
      }
      setAccountData(accountData);
      if (backFrom === 'account') {
        if (step1Completed) {
          setStepVisibility({ ...stepVisibility, step1: false, step2: true });
        } else {
          setStepVisibility({ ...stepVisibility, step2: false, step1: true });
        }
      } else {
        if (step !== '' && !step1Forward) {
          switch (step) {
            case 'step2':
              setStepVisibility({ ...initalState, step2: true });
              break;
            default:
              setStepVisibility({ ...initalState, step1: true });
          }
        } else if (step1Forward) {
          setStepVisibility({ ...initalState, step2: true });
        }
      }
    }
    setTimeout(function () {
      if (!accountData) {
        if (!stepVisibility.step1) {
          setStepVisibility({ ...stepVisibility, step1: true, step2: false });
        }
      }
    }, 1000);
  }, [accountData]);

  /**
   * Handle the naviagation __
   */
  const handleFirstStepClick = () => {
    backFrom !== 'account' && updatePreferenceOnServer(routeType.BUSINESS_INFO, 'step2');
    backFrom === 'account' && setStep1Completed(true);
    setStepVisibility({ ...stepVisibility, step1: false, step2: true });
    setStep1Forward(true);
  };

  const handleSecondStepClick = () => {
    !(backFrom === 'account' || backFromRepWithAccount === 'account') &&
      updatePreferenceOnServer(routeType.BUSINESS_REP, 'step1');
    setStep1Completed(false);
    navigate('/business-representative', {
      state: { backFrom: backFrom },
    });
  };

  const handleBackButtonClick = async () => {
    if (stepVisibility.step1) {
      navigate('/sign-up', {
        state: { type: 'company' },
      });
    }

    if (stepVisibility.step2) {
      setStepVisibility({ ...initalState, step1: true });
    }
  };

  return (
    <>
      {isLoading && <ManualLoader />}
      <Wrapper>
        {stepVisibility.step1 && (
          <FormBusinessInformationSubmition
            onBackButtonClick={handleBackButtonClick}
            onFormSubmit={handleFirstStepClick}
            accountData={accountData}
            setAccountData={setAccountData}
            isChangeBusinessType={isChangeBusinessType}
            isBackFromDashboard={backFrom === 'account' || backFromRepWithAccount === 'account'}
          />
        )}

        {stepVisibility.step2 && (
          <FormBusinessAddressSubmition
            onBackButtonClick={handleBackButtonClick}
            onFormSubmit={handleSecondStepClick}
            isBack={isBack}
            navigate={navigate}
            accountData={accountData}
            setAccountData={setAccountData}
            isBackFromDashboard={backFrom === 'account' || backFromRepWithAccount === 'account'}
          />
        )}
      </Wrapper>
    </>
  );
};
