import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import {
  Header,
  HeadingSummaryBlock,
  FormWrapper,
  ContentContainerBottom,
  AddressFields,
  Messages,
} from 'src/components/base/core';
import { handleIdentityVerificationAddress } from 'src/helpers/containers/helperIdentityVerification';
import dtoToFormValues from 'src/shared/utilities/dto';
import { useEffect, useState } from 'react';
import { identityVerificationSchemaObj } from 'src/helpers/validationSchemas';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { commonSpacing, theme } from 'src/shared/theme/theme';
import { useGetBlockHeight } from 'src/hooks/useGetBlockHeight';
export type FormProps = {
  line1: string;
  line2: string;
  city: string;
  state: string;
  postal_code: string;
};

export const FormAddressSubmition = ({
  onBackButtonClick,
  onFormSubmit,
  accountData,
  setAccountData,
}: any) => {
  const { t } = useTranslation();
  const [addressError, setAddressError] = useState('');
  const [appLogin, isLoading, isNullDOB]: any = handleIdentityVerificationAddress(
    accountData,
    setAccountData,
    setAddressError
  );
  let defaultValues: FormProps = { line1: '', line2: '', city: '', state: '', postal_code: '' };
  const [addressStates, setAddressStates] = useState(defaultValues);
  const navigate = useNavigate();
  const [elementRef, height] = useGetBlockHeight();

  const revieInfoSchema = yup
    .object({
      ...identityVerificationSchemaObj,
    })
    .required();

  //hook-forms
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    formState: { errors },
  } = useForm<any>({
    mode: 'onSubmit', //used to check error in realtime
    shouldFocusError: true,
    resolver: yupResolver(revieInfoSchema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (accountData) {
      dtoToFormValues(setValue, accountData);
      if (!isNullDOB(accountData?.individual.dob)) {
        const dob = accountData?.individual?.dob;
        const formattedDOB: any = moment(new Date(dob.year, dob.month - 1, dob.day));
        setValue('dob', formattedDOB);
      }
    }
  }, [accountData]);

  const onAppFormSubmit = handleSubmit(async (data: any) => {
    appLogin(data, onFormSubmit);
  });

  const handleBackButton = () => {
    localStorage.setItem('isBack', 'true');
    navigate('/identity-verification', {
      state: { isCompany: false, isBack: true, pageFromBack: 'address' },
    });
  };

  return (
    <>
      {isLoading && <ManualLoader />}
      <FormWrapper onSubmit={onAppFormSubmit} className="no_fullscreen">
        <Box
          component={'div'}
          className={'container_content'}
          ref={elementRef}
          sx={{
            [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
              minHeight: height,
              paddingBottom: height ? 4 : commonSpacing.desktopPaddingB,
            },
          }}
        >
          <Header
            isBackButtonVisible={true}
            onBackButtonClick={onBackButtonClick}
            isProgressBarVisible={true}
            totalProgressSteps={5}
            currentProgressStep={4}
            paddingX={0}
            backButtonId={'AddressStepBack'}
          />
          <Box sx={{ marginBottom: 6 }}>
            <HeadingSummaryBlock
              heading={t('headingContent.individual.address.heading')}
              headingAlignment="left"
              content={t('headingContent.individual.address.content')}
              contentAlignment="left"
            />
          </Box>
          <AddressFields
            setAddressStates={setAddressStates}
            addressStates={addressStates}
            register={register}
            errors={errors}
            control={control}
            getValue={getValues}
            setValue={setValue}
            trigger={trigger}
          />
          {addressError !== '' && (
            <Messages
              topMargin={2}
              type={'error'}
              messageHeading={t('headingContent.individual.address.heading')}
              messageContent={addressError}
              closeEvent={() => setAddressError('')}
            />
          )}
        </Box>
        <ContentContainerBottom className={'no_note no_fullscreen'}>
          <Button
            variant="containedLarge"
            color="primary"
            type="submit"
            fullWidth
            sx={{ overflow: 'hidden' }}
            id={'AddressStepContinueButton'}
          >
            {t('buttonTexts.continueButton')}
          </Button>
        </ContentContainerBottom>
      </FormWrapper>
    </>
  );
};
