import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Wrapper,
  DivWrapper,
  ContentContainerBottom,
  HeadingSummaryBlock,
  Header,
  InviteCustomerNoteCard,
  CustomerCard,
  InviteUseModal,
  CustomerAdditionConfirmModal,
} from 'src/components/base/core';
import { Box, Button, Grid } from '@mui/material';
import { useGetCustomerQuery } from 'src/services/customer/customerServiceApi';
import { handleCustomerInfo } from 'src/helpers/containers/helperCustomerInfo';
import { InvitedList } from './InvitedList/InvitedList';
import { useGetInviteListQuery } from 'src/services/customer/customerServiceApi';
import { commonSpacing, theme } from 'src/shared/theme/theme';

export const CustomersAdditionAcontainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data } = useGetCustomerQuery(null);
  const { data: invitedList, isSuccess } = useGetInviteListQuery(null);
  const [updateData, setUpdateData]: any = useState(null);
  const [isUpdate, setIsUpdate]: any = useState(null);
  const [customerCount, setCustomerCount] = useState(1);
  const [
    handleCompanySelection,
    states,
    setStates,
    addCustomer,
    isLoading,
    deleteCustomerHandler,
    updateCustomer,
  ]: any = handleCustomerInfo();

  const [isCustomerAddCompleted, setCustomerAddCompleted] = useState(false);

  const handleSkipButtonClick = () => {
    navigate('/sign-up');
  };

  const handleModalOpen = () => {
    setCustomerCount(1);
    setStates({ ...states, openModal: true });
  };

  const handleModalClose = () => {
    setUpdateData(null);
    setStates({ ...states, openModal: false });
  };

  const handleModalSubmit = (data: any) => {
    updateData === null
      ? addCustomer(data, setCustomerAddCompleted, handleModalClose, setCustomerCount)
      : updateCustomer(data, setCustomerAddCompleted, handleModalClose);
  };

  const handleCustomerModalSubmit = (data: any) => {
    setIsUpdate(false);
    setCustomerAddCompleted(false);
  };

  const handleUpdateCustomer = (data: any) => {
    setCustomerAddCompleted(false);
    setUpdateData(data);
    setStates({ ...states, openModal: true });
    setIsUpdate(true);
  };

  useEffect(() => {
    const el: any = document.getElementById('wrapperContainer');
    (states.openModal || isCustomerAddCompleted) && (el.style.overflowY = 'hidden');
    !states.openModal && !isCustomerAddCompleted && (el.style.overflowY = 'auto');
  }, [states.openModal, isCustomerAddCompleted]);

  const buttonVisibility =
    (states && states.comapnySelection.length > 0) ||
    (invitedList && invitedList?.customerReferrals?.nodes.length > 0);

  return (
    <Wrapper>
      <DivWrapper className={'no_fullscreen'}>
        <Box component={'div'} className={'container_content bottom_terms_padding'}>
          <Box
            component={'div'}
            className={'container_content_top'}
            sx={{
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                minHeight: 295 + commonSpacing.desktopPaddingB * 4,
              },
            }}
          >
            <Header
              isLogoLeftAligned
              paddingX={0}
              isSkipButtonVisible={true}
              onSkipButtonClick={handleSkipButtonClick}
            />
            <div className="content_block">
              <Box marginBottom={6}>
                <HeadingSummaryBlock
                  heading={t('headingContent.customerSelection.heading')}
                  headingAlignment="left"
                  content={t('headingContent.customerSelection.content')}
                  contentAlignment="left"
                />
              </Box>

              <Box
                display={'flex'}
                marginBottom={8}
                flexWrap="nowrap"
                sx={{
                  flexGrow: 1,
                }}
              >
                <Grid container spacing={3}>
                  {data &&
                    data.map((option: any, index: any) => {
                      if (option?.business_name?.includes('Resprop')) {
                        return (
                          <Grid item xs={6} key={index}>
                            <CustomerCard
                              Address="Austin, TX"
                              inputId={option?.id}
                              companyName={option?.business_name}
                              onChangeEvent={handleCompanySelection}
                            />
                          </Grid>
                        );
                      } else {
                        return null;
                      }
                    })}
                </Grid>
              </Box>
              {invitedList && invitedList?.customerReferrals?.nodes.length > 0 && (
                <InvitedList
                  data={invitedList}
                  isSuccess={isSuccess}
                  onClickEventHandler={handleUpdateCustomer}
                />
              )}
            </div>
          </Box>
          {buttonVisibility && (
            <Box component={'div'} className="container_content_bottom">
              <InviteCustomerNoteCard
                mainContent={t('noticeBlocks.customerAddition.heading')}
                subContent={t('noticeBlocks.customerAddition.content')}
                onClickEvent={handleModalOpen}
              />
            </Box>
          )}
        </Box>
        <ContentContainerBottom className={'no_fullscreen'}>
          {!buttonVisibility && (
            <InviteCustomerNoteCard
              mainContent={t('noticeBlocks.customerAddition.heading')}
              subContent={t('noticeBlocks.customerAddition.content')}
              onClickEvent={handleModalOpen}
            />
          )}

          {buttonVisibility && (
            <Button
              variant="containedLarge"
              color="primary"
              type="button"
              fullWidth
              sx={{ overflow: 'hidden' }}
              onClick={handleSkipButtonClick}
            >
              {t('buttonTexts.continueButton')}
            </Button>
          )}
        </ContentContainerBottom>
        {states.openModal && (
          <InviteUseModal
            onCloseEvent={handleModalClose}
            onFormSubmit={handleModalSubmit}
            isLoading={isLoading}
            updateRef={updateData}
            deleteCustomer={deleteCustomerHandler}
          />
        )}
        {isCustomerAddCompleted && (
          <CustomerAdditionConfirmModal
            onCloseEvent={handleModalClose}
            onFormSubmit={handleCustomerModalSubmit}
            isUpdate={isUpdate}
            customerCount={customerCount}
          />
        )}
      </DivWrapper>
    </Wrapper>
  );
};
