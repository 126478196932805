import { createApi } from '@reduxjs/toolkit/query/react';
import { configs, prepareBaseQuery } from '../../shared/config/config';

export const authApi = createApi({
  reducerPath: 'authApi',
  tagTypes: ['Auth'],
  baseQuery: prepareBaseQuery(false),
  endpoints: (builder) => ({
    createVerificationCode: builder.mutation({
      query: (phone: string) => ({
        url: '/verify/code',
        method: 'POST',
        body: {
          phone_number:
            phone.indexOf('+') > -1
              ? phone
              : `+${configs.COUNTRY_PREFIX}${phone.replace(/\D/g, '')}`,
        },
      }),
    }),
    createConfirmVerificationCode: builder.mutation({
      query: (data: any) => ({
        url: '/verify/confirm',
        method: 'POST',
        body: data,
      }),
    }),
    resendEmail: builder.mutation({
      query: (email) => ({
        url: `/verify/email`,
        method: 'POST',
      }),
    }),
    resetPasswordLink: builder.mutation({
      query: (email: string) => ({
        url: `/verify/change-password`,
        method: 'POST',
        body: {
          email: email,
        },
      }),
    }),
  }),
});
export const {
  useCreateVerificationCodeMutation,
  useCreateConfirmVerificationCodeMutation,
  useResendEmailMutation,
  useResetPasswordLinkMutation,
} = authApi;

export const authTokenApi = createApi({
  reducerPath: 'authTokenApi',
  tagTypes: ['AuthToken'],
  baseQuery: prepareBaseQuery(true),
  endpoints: (builder) => ({
    createAuthLogin: builder.mutation({
      query: (token: string) => ({
        url: `/auth`,
        method: 'POST',
        body: {},
      }),
    }),
  }),
});

export const { useCreateAuthLoginMutation } = authTokenApi;

/**
 * Used for coupa supplier search
 */
export interface ISearchVendor {
  email?: string;
  fullName?: string;
  businessName?: string;
  phone?: string;
  phoneType?: string;
  tax_id?: string;
}
export const searchVendorApi = createApi({
  reducerPath: 'searchVendorApi',
  baseQuery: prepareBaseQuery(false),
  endpoints: (builder) => ({
    searchVendor: builder.mutation({
      query: ({ email, fullName, businessName, phone, tax_id }: ISearchVendor) => {
        const defineFullNameURL =
          fullName && fullName !== undefined ? `primary_contact_name=${fullName}` : '';
        const defineBusinessNameURL =
          businessName && businessName !== undefined ? `&display_name=${businessName}` : '';
        const defineEmailURL = email && email !== undefined ? `&email=${email}` : '';
        const definePhoneURLParam =
          phone && phone !== undefined ? `&phone=${phone.replace(/[^0-9]/g, '')}` : '';
        const defineTaxIdURL = tax_id && tax_id !== undefined ? `&tax_id=${tax_id}` : '';
        return {
          url: `/coupa/suppliers?${defineFullNameURL}${defineBusinessNameURL}${defineEmailURL}${definePhoneURLParam}${defineTaxIdURL}&api_version=2`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useSearchVendorMutation } = searchVendorApi;
