export const esFinanceTranslation = {
  meetSoon: 'Conozca pronto a su',
  payupCard: 'Tarjeta de pago',
  btnText: 'Notificarme',
  messageText: 'Le notificaremos en nuestra próxima ronda de invitaciones.',
  financeText:
    'Su propia cuenta financiera de PayUp le permite recibir pagos de manera aún más conveniente y rápida junto con el seguimiento de sus gastos.',
  splashSlider: {
    slide1: {
      heading: 'Reciba su pago el día de pago',
      content: 'Reciba sus pagos a tiempo. No espere días hábiles para acceder a sus fondos.',
    },
    slide2: {
      heading: 'Mueva dinero fácilmente',
      content: 'Fácil de ganar, fácil de gastar. Mueve tu dinero al instante.',
    },
    slide3: {
      heading: 'Seguimiento del historial financiero',
      content:
        'Seguimiento de sus gastos y ganancias. Obtenga acceso a las estadísticas de la cuenta.',
    },
    slide4: {
      heading: 'Tu cuenta personal',
      content: 'Administra tus ingresos, impuestos, controla y analiza tu presupuesto.',
    },
    button: 'Obtener cuenta financiera',
    messages: {
      title: 'Crear cuenta de finanzas',
    },
  },
  splashListView: {
    headingContent1: 'Cuenta financiera PayUp.',
    headingContent2: 'Tus Pagos Convenientes',
    button: 'Obtenga una cuenta de finanzas gratis',
    mainViewButton: 'Obtener financiera PayUp',
  },
  announcementScreen: {
    heading: 'Cuenta financiera PayUp',
    content:
      'Su propia cuenta financiera de PayUp le permite recibir pagos de manera aún más conveniente y rápida junto con el seguimiento de sus gastos.',
    button: 'Habilitar cuenta financiera de PayUp',
    infoNote:
      '¡Ups! Debe completar la verificación y tener una cuenta bancaria vinculada para seguir adelante.',
  },
  accountCreation: {
    heading: '¡Felicitaciones!',
    content:
      '¡Su cuenta financiera de PayUp fue configurada! Comienza a usar tu tarjeta virtual de inmediato.',
    button: 'Ir a la cuenta',
  },
  accountLoading: {
    content: 'Preparación de la cuenta financiera de PayUp',
  },
  mainScreen: {
    cardTitle: 'PayUp Tarjeta',
    balanceSummaryTitle: 'Saldo actual',
    cardShowNumberText: 'Mostrar numero',
    btnTextMoveMoney: 'Mover dinero',
    btnTextmanageCard: 'Administrar tarjeta',
    recentTransaction: {
      title: 'TRANSACCIONES RECIENTES',
      emptyStateTitle: 'Aún no hay transacciones',
      emptyStateContent: 'Sus transacciones más recientes aparecerán aquí.',
      viewAllLinkText: 'Ver todo',
    },
  },
  transaction: {
    details: {
      termsNote:
        'Si tiene alguna pregunta sobre la transacción, contáctenos para obtener asistencia en',
      summaryCard: {
        heading: 'Detalles',
        viewRecipetLink: 'Ver recibo de transacción',
        type: 'Tipo',
        account: 'Cuenta',
        routing: 'Enrutamiento',
        method: 'Método',
        transactionID: 'ID de transacción',
        description: 'Descripción',
        destination: 'Destino',
      },
    },
    list: {
      cardHeading: 'LISTA DE TRANSACCIONES',
      emptyStateTitle: 'Aún no hay transacciones',
      emptyStateContent: 'Sus transacciones aparecerán aquí.',
    },
    tabNavs: {
      filter: 'Filtrar',
      tab1: 'Earned',
      tab2: 'Spent',
    },
  },
  FinancesTermsModal: {
    heading: 'Términos de servicio',
    content:
      'Al hacer clic en Aceptar, acepta los siguientes documentos y la recepción de mensajes de texto automáticos de Stripe',
    financeTermsContent: {
      link1: 'Acuerdo de Servicios de Tarjeta',
      link2: 'Términos y condiciones de Apple Pay de la tarjeta de pago',
      link3: 'Acuerdo de servicios de Stripe y E-SIGN',
      link4: 'Acuerdo de cuenta comercial',
    },
    footerNote:
      'Los productos de tarjeta son emitidos por Sutton Bank, miembro de FDIC y atendidos por Stripe, Inc. y su afiliado Stripe Servicing, Inc. Servicios proporcionados por Stripe Payments Company con fondos retenidos en Evolve Bank & Trust, miembro de FDIC.',
  },
  MoveMoney: {
    headerText: 'Mover dinero',
    landing: {
      topContent: 'Seleccione dónde desea enviar el dinero?',
      linkedAccountHeading: 'Mis cuentas',
      linkedAccountLink: 'Vincular nueva cuenta',
      linkedAccountEmptyBlockContent: 'Cree su primera cuenta vinculada.',
      payeeAccountHeading: 'Beneficiarios',
      payeeAccountLink: 'Agregar nuevo beneficiario',
      payeeAccountEmptyBlockContent:
        'Agregue su primer beneficiario para comenzar a enviarle fondos.',
    },
    addPayeeAccountModal: {
      modalTitle: 'Agregar nueva cuenta',
    },
    form: {
      title: 'Detalles de la transferencia',
    },
    financeMoveMoneySummaryCard: {
      topTitle: 'Cantidad',
      fromText: 'De',
      walletText: 'PayUp Billetera',
      toText: 'A',
      accountText: 'Cuenta',
    },
    summary: {
      button: 'Confirmar pago',
      successModal: {
        heading: 'Éxito',
        prefixContent: 'Su',
        postFixContent:
          'el pago ha sido enviado. Puede esperar que este pago llegue en 1-2 días hábiles.',
      },
    },
    financeMoveMoneySummaryDetailsCard: {
      heading: 'Detalles de la transacción',
      type: 'Tipo',
      date: 'Fecha',
      method: 'Método',
      methodContent: 'Días hábiles',
      notes: 'Notas',
    },
  },
  manageCard: {
    navigation: {
      title: 'Administrar tarjeta',
      link1: {
        content: 'Detalles de la cuenta',
        subContent: 'Ver la información de su cuenta',
      },
      link2: {
        content: 'Límites',
        subContent: 'Ver sus límites de gasto y retiro',
      },
      link3: {
        content: 'Agregar a Apple Wallet o Google Pay',
        subContent: 'Tarjeta no ha agregado',
      },
      link4: {
        content: 'Solicitar tarjeta física',
      },
      link5: {
        content: 'Configurar código PIN',
        subContent: 'Para tu tarjeta física',
      },
      link6: {
        content: 'Congelar tarjeta',
        elseContent: 'Descongelar tarjeta',
        subContent: 'Congele/descongele su tarjeta según sus necesidades',
      },
      link7: {
        content: 'Cancelar cuenta',
      },
    },
    manageVirtualCard: {
      title: 'PayUp Tarjeta',
      totalLimit: 'Límite total',
      spentLimit: 'límite gastado',
      showNumber: 'Mostrar numero',
    },
    cardAccountInfoModal: {
      title: 'Detalles de la cuenta',
      dataRow1: 'Número de cuenta',
      dataRow2: 'Número de ruta',
      dataRow3: 'Nombre del titular de la cuenta',
    },
    manageCardLimitsModal: {
      title: 'Límites',
      cardTitle: 'Límite actual',
      progressBlock: {
        title: 'Gastos con tarjeta',
        totalLimit: 'Disponible',
        spentLimit: 'Gastado',
      },
    },
    freezeCardModal: {
      title: 'Congelar/descongelar tarjeta',
      formSwitch: 'Congelar / Descongelar su tarjeta',
    },
  },
};
