import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, Button, Checkbox, FormControlLabel, RadioGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  FormInput,
  FormInputPhoneNumber,
  UpdateFormWrapper,
  UpdateCardWrapper,
  UpdateFormTop,
  AccountVerificationStatusses,
  AddressFields,
  FormRadioButton,
  FormInputSSN,
  IdDocumentModal,
  DocVerifiedLink,
  AddMorePhoneNumber,
  Messages,
  FormInPutChangePassword,
  FixedParentWrapper,
  FixedParentWrapperContainer,
} from 'src/components/base/core';
import { FormEditSubmitionprops } from 'src/models/component/base.model';
import { AccountParams } from 'src/shared/utilities/interface';
import { handlePersonalInfoEdit } from 'src/helpers/containers/helperBusinessInfo';
import { getCookie } from 'src/shared/utilities/cookiesHelper';
import { setCoupaInfoPhone, setCoupaInfoValuesForEmailLogin } from 'src/helpers/setCoupaValues';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { initAccount } from 'src/models/init/account.init';
import {
  businessRepOwnershipSchemaObj,
  identityVerificationSchemaObj,
} from 'src/helpers/validationSchemas';
import dtoToFormValues, { dtoToBusinessInforFormValue } from 'src/shared/utilities/dto';
import moment from 'moment';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { handleIdModalVisibility } from 'src/helpers/containers/helperIdDocumentModal';
import { RefreshIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import { ERROR_MESSAGES } from 'src/constants/Validation';
import { getAditionalPhoneDetails } from 'src/services/account/additional.phone.service';
import { maskedInput } from 'src/shared/utilities/inputHandlers';
import { FormInputDate } from 'src/components/base/core/FormElements/FormInputDate/FormInputDate';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { handleVerifyPhone } from 'src/helpers/helperPhoneVerificaition';
import { PhoneVerifyCodeContainer } from '../../../VerifyCode/VerifyCode';
import { isBillingAndShippingAreTheSame } from 'src/shared/utilities/addressUtils';

export type FormProps = {
  line1: string;
  line2: string;
  city: string;
  state: string;
  postal_code: string;
};

export const FormEditPersonalDetailsSubmition = ({
  onCancelButton,
  title,
  type,
  setUpdateData,
  isSettings = false,
  isShadowCard = false,
  isVerified = false,
  onFormSubmit,
}: FormEditSubmitionprops) => {
  const { t } = useTranslation();
  let defaultValues: FormProps = { line1: '', line2: '', city: '', state: '', postal_code: '' };

  const { webauthnEnabled, allowEditEmail, disabledPhoneVerificationStep } = useFlags();
  const [formData, setFormData] = useState<AccountParams>(initAccount);
  const [verifyCodeContainerVisible, setVerifyCodeContainerVisible] = useState(false);
  const [handleVerificationCode, verifiedPhoneNumber, errorState, isPhoneVerificationLoading] =
    handleVerifyPhone();

  const [addressStates, setAddressStates] = useState(defaultValues);
  const [DOBValue, setDOBValue] = useState(moment().subtract(18, 'years').format());
  const [radioChecked, setRadioChecked] = useState('owner');
  const [ownerPercentageChecked, setOwnerPercentageChecked] = useState(false);
  const [enableSSNUpdate, setEnableSSNUpdate]: any = useState(true);
  const [addtionalNumbers, setAddtionalNumbers]: any = useState();
  const [isSetInputNumber, setIsSetInputNumber]: any = useState(false);
  const [needToDeleteNumber, setNeedToDeleteNumber]: any = useState('');
  const [openIdModal, onClickIdModalOpen, onClickIdModalClose] = handleIdModalVisibility();
  const [btnSubmitted, setBtnSubmitted] = useState(false);
  const [errorManualDate, setErrorManualDate] = useState(false);
  const [isSameChecked, setSameChecked] = useState(false);

  const [enableEIN, setEnableEIN]: any = useState(true);
  const [
    accountData,
    serverSideErrors,
    setServerSideErrors,
    appLogin,
    isLoading,
    onChangeHandlerPhone,
    onChangeHandlerTax,
    onChangeHandlerDateInput,
    isNullDOB,
    isNot18,
    isValidDOB,
  ]: any = handlePersonalInfoEdit();

  useEffect(() => {
    if (accountData) {
      if (!isNullDOB(accountData?.individual.dob)) {
        const dob = accountData?.individual?.dob;
        const formattedDOB: any = moment(new Date(dob.year, dob.month - 1, dob.day));
        setValue('dob', moment(formattedDOB).format('MM/DD/yyyy'));
        setDOBValue(moment(formattedDOB).format('MM/DD/yyyy'));
        setOwnerPercentageChecked(accountData?.individual?.relationShip.percent_ownership > 0);
        if (accountData?.individual?.relationShip?.executive) {
          setRadioChecked('executive');
        }
        if (accountData?.individual?.ssn_last4_provided || isVerified) {
          setEnableSSNUpdate(false);
        } else {
          setEnableSSNUpdate(true);
        }
      } else {
        setValue('dob', moment(DOBValue).format('MM/DD/yyyy'));
      }
      getAditionalPhoneDetails(accountData?.id).then((result) => {
        if (result.data.length > 0) {
          setValue(
            'phone_number_2',
            maskedInput(result.data[0].phone_number?.replace('+1', '')) ?? ''
          );
          setAddtionalNumbers(result.data[0].phone_number);
          setIsSetInputNumber(true);
          setNeedToDeleteNumber(getValues('phone_number_2'));
        }
      });
      dtoToFormValues(setValue, accountData);
    }
    //prefill info with coupa
    if (!accountData && getCookie('coupaObj')) {
      setCoupaInfoPhone(setValue);
    }

    if (!accountData && getCookie('coupaObj')) {
      if (webauthnEnabled) {
        setCoupaInfoValuesForEmailLogin(setValue);
      }
    }
  }, [accountData]);

  const revieInfoSchema = yup
    .object({
      ...businessRepOwnershipSchemaObj,
      ...identityVerificationSchemaObj,
      phone_number_2: yup.string().test('len', ERROR_MESSAGES.PHONE_NUMBER_INVALID, (val: any) => {
        if (val === undefined || val?.length === 14 || val === '') return true;
        else return false;
      }),
      idNumber: yup
        .string()
        .test('required', ERROR_MESSAGES.REQUIRED, (value) => {
          if (!enableSSNUpdate || value) return true;
          return false;
        })
        .test('min', ERROR_MESSAGES.SSN_INVALID_LAST4, (value) => {
          if ((value && value?.length === 4) || !enableSSNUpdate) return true;
          return false;
        }),
      dob: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .test(
          'len',
          errorManualDate ? ERROR_MESSAGES.DATE_INVALID_LESS_THAN_18 : ERROR_MESSAGES.DATE_INVALID,
          (value) => {
            if (value && value?.length === 10 && isNot18(value) && isValidDOB(value)) return true;
            else return false;
          }
        ),
    })
    .required();

  //hook-forms
  const {
    register,
    handleSubmit,
    setValue,
    control,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<AccountParams>({
    resolver: yupResolver(revieInfoSchema),
    defaultValues: initAccount,
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (accountData) {
      const getCompanyAddressObject = accountData?.company?.address;
      const getIndividualAddressObject = accountData?.individual?.address;
      const isBothAddressAreTheSame = isBillingAndShippingAreTheSame(
        getCompanyAddressObject,
        getIndividualAddressObject
      );
      const address = getCompanyAddressObject ?? getIndividualAddressObject;

      if (isBothAddressAreTheSame) {
        setSameChecked(true);
      }

      if (address && isSameChecked) {
        setValue('city', address.city);
        setValue('line1', address.line1);
        setValue('line2', address.line2);
        setValue('state', address.state);
        setValue('postal_code', address.postal_code);
        trigger(['line1', 'state', 'city', 'postal_code']);
      }

      if (!getValues('inputOwnerTitle') && !accountData?.individual?.relationShip?.title) {
        setValue('inputOwnerTitle', 'Manager', { shouldValidate: btnSubmitted && true });
      }

      dtoToBusinessInforFormValue(getValues, setValue, accountData);
    }
  }, [accountData, isSameChecked]);

  const skipTheOTPVerification = (data: any) => {
    appLogin(data, onFormSubmit, setUpdateData, needToDeleteNumber);
    setFormData(initAccount);
    setVerifyCodeContainerVisible(false);
  };

  const moveToOTPVerification = (data: any) => {
    setFormData(data);
    // @ts-ignore
    handleVerificationCode(data, getValues, setVerifyCodeContainerVisible);
  };

  const onAppFormSubmit = handleSubmit(async (data: any) => {
    let setData = data;
    // check tos_shown_and_accepted true or false __
    const getToshShownAccepted = accountData && accountData?.tos_shown_and_accepted ? true : false;
    // if tos_shown_and_accepted false __
    if (!getToshShownAccepted) {
      setData = { ...data, tos_shown_and_accepted: true };
    }
    // if webauthnEnabled featureflag enable or not __
    if (webauthnEnabled && !disabledPhoneVerificationStep) {
      if (accountData && accountData.individual.phone) {
        if (
          accountData.individual.phone.includes(data?.phone_number?.replace(/[^0-9]/g, '')) ||
          accountData.company.phone.includes(data?.phone_number?.replace(/[^0-9]/g, ''))
        ) {
          skipTheOTPVerification(setData);
        } else {
          moveToOTPVerification(setData);
        }
      } else {
        moveToOTPVerification(setData);
      }
    } else {
      skipTheOTPVerification(setData);
    }
  });

  const handleRep = (val: any) => {
    const { name, id } = val.target;
    setRadioChecked(id);
    setValue('ownership', id);
  };

  const onChangeHandlerAddionalInput = (event: any) => {};

  const updateBindingsChange = (event: any, feild: any) => {
    setValue(feild, event.target.value);
  };

  const onChangeHandler = (value: any) => {
    onChangeHandlerPhone(value, getValues, setValue, btnSubmitted);
  };
  const onChangeHandlerTaxValue = (event: any) => {
    onChangeHandlerTax(event, getValues, setValue, btnSubmitted);
  };

  const chnageOwnerPercentage = (event: any) => {
    setOwnerPercentageChecked(event?.target?.checked);
    setValue('percentOwnership', event?.target?.checked);
  };

  const onChangeHandlerDate = (event: any) => {
    onChangeHandlerDateInput(event, setValue, setErrorManualDate);
  };

  const getIndivisualData = accountData && accountData?.individual;
  const getVerificationData = accountData && accountData?.individual?.verification;
  const confirmVerificationDocumentSubmitted =
    getVerificationData?.document?.front !== null && getVerificationData?.status === 'verified';
  const confirmUnVerificationDocumentSubmitted =
    getVerificationData?.document?.front !== null && getVerificationData?.status !== 'verified';
  const confirmationNoUploadDone =
    !confirmVerificationDocumentSubmitted && !confirmUnVerificationDocumentSubmitted;

  const setVerifiedOnlyForSetting = getVerificationData?.status === 'verified' && isSettings;
  const disabledTheFirstNameField =
    setVerifiedOnlyForSetting &&
    getIndivisualData?.first_name !== null &&
    getIndivisualData?.first_name !== '';
  const disabledTheLastNameField =
    setVerifiedOnlyForSetting &&
    getIndivisualData?.last_name !== null &&
    getIndivisualData?.last_name !== '';

  const emailId = accountData && accountData?.individual?.email;

  if (verifyCodeContainerVisible) {
    return (
      <FixedParentWrapper>
        <FixedParentWrapperContainer>
          <PhoneVerifyCodeContainer
            phone={verifiedPhoneNumber}
            setVerifyCodeContainerVisible={setVerifyCodeContainerVisible}
            onSubmitFunction={() =>
              appLogin(
                formData,
                onFormSubmit,
                setUpdateData,
                needToDeleteNumber,
                setVerifyCodeContainerVisible
              )
            }
            phoneWithMask={formData.phone_number}
          />
        </FixedParentWrapperContainer>
      </FixedParentWrapper>
    );
  }

  return (
    <>
      {(isLoading || isPhoneVerificationLoading) && <ManualLoader />}
      <UpdateFormWrapper
        onSubmit={onAppFormSubmit}
        className={`no-mobile-padding ${isShadowCard ? 'is_shadow_card' : 'disable_shadow_card'}`}
      >
        {!isSettings && (
          <UpdateFormTop className={type ? 'enabled_status' : 'disabled_status'}>
            <Typography
              textTransform={'uppercase'}
              variant="caption2"
              color={'gray.500'}
              marginBottom={1}
            >
              {title}
            </Typography>
            {type && type !== undefined && <AccountVerificationStatusses type={type} />}
          </UpdateFormTop>
        )}
        <UpdateCardWrapper className="update_wrapper_top">
          <Box
            className={isSettings ? 'is_shadow_card' : 'disabled_shadow_card'}
            paddingTop={isSettings ? 4 : 0}
            paddingBottom={isSettings ? 1 : 0}
            paddingX={isSettings ? 4 : 0}
            marginBottom={isSettings ? 4 : 0}
          >
            {isSettings && (
              <UpdateFormTop className={type ? 'enabled_status' : 'disabled_status'}>
                <Typography
                  textTransform={'uppercase'}
                  variant="caption2"
                  color={'gray.500'}
                  marginBottom={4}
                >
                  {title}
                </Typography>
                {type && type !== undefined && <AccountVerificationStatusses type={type} />}
              </UpdateFormTop>
            )}
            <Controller
              control={control}
              name="first_name"
              render={({ field: { value } }) => (
                <FormInput
                  label={t('formInput.firstName')}
                  id={'first_name'}
                  placeholder={t('placeHolder.firstName')}
                  {...register('first_name')}
                  error={errors?.first_name?.message}
                  onChange={(event: any) => {
                    event.target.value = event.target.value.replace('  ', ' ');
                    updateBindingsChange(event, 'first_name');
                  }}
                  value={value}
                  inputFocus={() => trigger('first_name')}
                  readonly={disabledTheFirstNameField}
                />
              )}
            />
            <Controller
              control={control}
              name="last_name"
              render={({ field: { value } }) => (
                <FormInput
                  label={t('formInput.lastName')}
                  id={'lastName'}
                  placeholder={t('placeHolder.lastName')}
                  {...register('last_name')}
                  error={errors?.last_name?.message}
                  onChange={(event: any) => {
                    event.target.value = event.target.value.replace('  ', ' ');
                    updateBindingsChange(event, 'last_name');
                  }}
                  value={value}
                  inputFocus={() => trigger('last_name')}
                  readonly={disabledTheLastNameField}
                />
              )}
            />
            <Controller
              control={control}
              name="email"
              render={({ field: { value } }) => (
                <FormInput
                  label={t('formInput.email')}
                  id={'email'}
                  type="email"
                  placeholder={t('placeHolder.emailAddress')}
                  {...register('email')}
                  inputMode="email"
                  error={errors?.email?.message}
                  // value={getValues('email')}
                  onChange={(event: any) => {
                    updateBindingsChange(event, 'email');
                  }}
                  value={value}
                  inputFocus={() => trigger('email')}
                  readonly={
                    !allowEditEmail && setVerifiedOnlyForSetting && value !== '' && value !== null
                  }
                />
              )}
            />
            {webauthnEnabled && <FormInPutChangePassword email={emailId} />}
            <Controller
              control={control}
              name="inputOwnerTitle"
              render={({ field: { value } }) => (
                <FormInput
                  label={t('formInput.jobTitle')}
                  id={'inputOwnerTitle'}
                  placeholder={t('placeHolder.jobTitle')}
                  {...register('inputOwnerTitle')}
                  error={errors?.inputOwnerTitle?.message}
                  onChange={(event: any) => {
                    updateBindingsChange(event, 'inputOwnerTitle');
                  }}
                  value={value}
                  inputFocus={() => trigger('inputOwnerTitle')}
                />
              )}
            />
            <Controller
              control={control}
              name="dob"
              render={({ field: { value } }) =>
                setVerifiedOnlyForSetting && value !== '' && value !== null ? (
                  <FormInputDate
                    label={t('formInput.dob')}
                    id={'dob'}
                    placeholder={'(123) 543-3454'}
                    {...register('dob')}
                    onChange={(event: any) => {
                      onChangeHandlerDate(event);
                    }}
                    inputMode="numeric"
                    inputFocus={(e: any) => {
                      trigger('dob');
                    }}
                    value={value}
                    readonly={true}
                  />
                ) : (
                  <FormInputDate
                    label={t('formInput.dob')}
                    id={'dob'}
                    placeholder={'(123) 543-3454'}
                    {...register('dob')}
                    inputError={errors.dob?.message}
                    onChange={(event: any) => {
                      onChangeHandlerDate(event);
                    }}
                    inputMode="numeric"
                    inputFocus={(e: any) => {
                      trigger('dob');
                    }}
                    value={value || ''}
                  />
                )
              }
            />
            <Controller
              control={control}
              name="phone_number"
              render={({ field: { value } }) => (
                <FormInputPhoneNumber
                  label={t('formInput.phone')}
                  id={'phone'}
                  placeholder={'(123) 543-3454'}
                  {...register('phone_number')}
                  inputError={errors.phone_number?.message || errorState}
                  onChange={onChangeHandler}
                  inputMode="numeric"
                  value={value}
                  inputFocus={() => trigger('phone_number')}
                />
              )}
            />
            <AddMorePhoneNumber
              register={register}
              control={control}
              onChange={onChangeHandlerAddionalInput}
              setAddtionalNumbers={setAddtionalNumbers}
              setValue={setValue}
              error={getValues('phone_number_2')?.length !== 14 && errors?.phone_number_2?.message}
              isSetInputNumber={isSetInputNumber}
            />
            <Box
              sx={{ marginBottom: 4, pointerEvents: setVerifiedOnlyForSetting ? 'none' : 'auto' }}
            >
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                sx={{ marginBottom: -2 }}
              >
                <FormRadioButton
                  label={t('formInput.ownerLabel')}
                  name={'relationship'}
                  value={'owner'}
                  id={'owner'}
                  checked={radioChecked === 'owner'}
                  handleChange={handleRep}
                />
                <FormRadioButton
                  label={t('formInput.executiveLabel')}
                  name={'relationship'}
                  value={'executive'}
                  id={'executive'}
                  checked={radioChecked !== 'owner'}
                  handleChange={handleRep}
                />
              </RadioGroup>

              {radioChecked !== 'owner' && (
                <FormControlLabel
                  sx={{ marginTop: 2 }}
                  control={
                    <Checkbox
                      name="confirm_subscription"
                      size="small"
                      onChange={chnageOwnerPercentage}
                      checked={ownerPercentageChecked}
                    />
                  }
                  label={
                    <Typography variant="body1" color={'gray.700'}>
                      {t('formInput.executiveConfirmInput')}
                    </Typography>
                  }
                />
              )}
            </Box>

            <FormControlLabel
              sx={{ marginBottom: 3, pointerEvents: setVerifiedOnlyForSetting ? 'none' : 'auto' }}
              control={
                <Checkbox
                  checked={isSameChecked}
                  name="jason"
                  size="small"
                  onClick={() => setSameChecked(!isSameChecked)}
                />
              }
              label={
                <Typography variant="body1" color={'gray.700'}>
                  {t('formInput.sameBillingAddressCheckbox')}
                </Typography>
              }
            />
            <AddressFields
              setAddressStates={setAddressStates}
              addressStates={addressStates}
              register={register}
              errors={errors}
              control={control}
              getValue={getValues}
              setValue={setValue}
              trigger={trigger}
              isVerifiedOnlyForSetting={setVerifiedOnlyForSetting}
            />
            {(!isSettings || !isVerified) && (
              <>
                <Controller
                  control={control}
                  name="idNumber"
                  render={({ field: { value, ref } }) => (
                    <FormInputSSN
                      label={t('formInput.ssn')}
                      id={'securityNumber'}
                      only4={true}
                      {...register('idNumber')}
                      error={errors?.idNumber?.message}
                      inputError={errors?.idNumber?.message}
                      onChange={(event: any) => {
                        updateBindingsChange(event, 'idNumber');
                      }}
                      replace={enableSSNUpdate}
                      setEnableSSNUpdate={setEnableSSNUpdate}
                      inputFocus={() => trigger('idNumber')}
                    />
                  )}
                />
                <Box component={'div'} paddingY={2} marginBottom={2}>
                  <DocVerifiedLink
                    className={`${
                      confirmVerificationDocumentSubmitted ? 'pointer_disabled' : 'pointer_enabled'
                    }`}
                    onClick={onClickIdModalOpen}
                  >
                    {confirmationNoUploadDone && (
                      <Typography
                        variant="h2"
                        color={'primary'}
                        sx={{ cursor: 'pointer', marginRight: 2 }}
                      >
                        {t('linkTexts.business.provideIDDocLink')}
                      </Typography>
                    )}

                    {(confirmUnVerificationDocumentSubmitted ||
                      confirmVerificationDocumentSubmitted) && (
                      <Typography
                        variant="h2"
                        color={'primary'}
                        sx={{ cursor: 'pointer', marginRight: 2 }}
                      >
                        {t('linkTexts.business.providedIDDoc')}
                      </Typography>
                    )}
                    {confirmUnVerificationDocumentSubmitted && !confirmationNoUploadDone && (
                      <RefreshIcon />
                    )}
                    {confirmVerificationDocumentSubmitted && (
                      <AccountVerificationStatusses type="verified" />
                    )}
                  </DocVerifiedLink>
                </Box>
              </>
            )}
            {serverSideErrors && (
              <Messages
                topMargin={0}
                bottomMargin={4}
                messageHeading={
                  serverSideErrors.includes('phone')
                    ? t('errorMessages.phoneUniqueError.heading')
                    : serverSideErrors.includes('email')
                    ? t('errorMessages.emailUniqueError.heading')
                    : serverSideErrors.includes('P.O. Box')
                    ? t('errorMessages.invalidAddressError.heading')
                    : serverSideErrors
                }
                messageContent={
                  serverSideErrors.includes('phone')
                    ? t('errorMessages.phoneUniqueError.content')
                    : serverSideErrors.includes('email')
                    ? t('errorMessages.emailUniqueError.content')
                    : serverSideErrors.includes('P.O. Box')
                    ? serverSideErrors
                    : 'Please provide valid details to use PayUp.'
                }
                closeEvent={() => setServerSideErrors('')}
              />
            )}
          </Box>
          <IdDocumentModal
            register={register}
            setValue={setValue}
            verificationData={accountData && accountData.individual.verification}
            openIdModal={openIdModal}
            closeIdModalEvent={onClickIdModalClose}
          />
          <Box className="update_wrapper_bottom">
            {!isSettings && (
              <Button
                variant="text"
                color="primary"
                type="button"
                sx={{ overflow: 'hidden', fontWeight: 600 }}
                onClick={onCancelButton}
              >
                {t('buttonTexts.cancelButton')}
              </Button>
            )}
            <Button
              variant="containedLarge"
              color="primary"
              type="submit"
              sx={{ overflow: 'hidden', width: isSettings ? '100%' : 'auto' }}
              onClick={() => {
                setBtnSubmitted(true);
              }}
            >
              {!isSettings ? t('buttonTexts.updateButton') : t('buttonTexts.submitButton')}
            </Button>
          </Box>
        </UpdateCardWrapper>
      </UpdateFormWrapper>
    </>
  );
};
