import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Box, Button } from '@mui/material';
import {
  Header,
  HeadingSummaryBlock,
  FormWrapper,
  ContentContainerBottom,
  AddressFields,
  Messages,
  ManualLoader,
} from 'src/components/base/core';

import { theme, commonSpacing } from 'src/shared/theme/theme';
import { dtoToFormValuesBusinessAddress } from 'src/shared/utilities/dto';

import { identityVerificationSchemaObj } from 'src/helpers/validationSchemas';
import { handleBusinessInfoAddress } from 'src/helpers/containers/helperBusinessInfo';

import { useGetBlockHeight } from 'src/hooks/useGetBlockHeight';
import { addressDefaultValues } from 'src/models/init/account.init';

export const FormBusinessAddressSubmition = ({
  onBackButtonClick,
  onFormSubmit,
  isBack,
  navigate,
  accountData,
  setAccountData,
}: any) => {
  const { t } = useTranslation();

  /**
   * set states using useState react hooks
   */
  const [addressStates, setAddressStates] = useState(addressDefaultValues);
  const [addressError, setAddressError] = useState('');

  /**
   * call the cutome hooks and helper function
   */
  const [appSubmit, isLoading]: any = handleBusinessInfoAddress(
    accountData,
    setAccountData,
    setAddressError
  );
  const [elementRef, height] = useGetBlockHeight();

  /**
   * update with custom validation
   */
  const revieInfoSchema = yup
    .object({
      ...identityVerificationSchemaObj,
    })
    .required();

  /**
   * initialize react hook form
   */
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    formState: { errors },
  } = useForm<any>({
    mode: 'onChange', //used to check error in realtime
    shouldFocusError: true,
    resolver: yupResolver(revieInfoSchema),
    defaultValues: addressDefaultValues,
  });

  /**
   * render data in the initial load
   */
  useEffect(() => {
    if (accountData) {
      dtoToFormValuesBusinessAddress(setValue, accountData);
    }
  }, [accountData]);

  /**
   * onSubmit form function
   */
  const onAppFormSubmit = handleSubmit(async (data: any) => {
    appSubmit(data, onFormSubmit, isBack, navigate);
  });

  /**
   * Defaul Ui interaction
   */
  return (
    <>
      {isLoading && <ManualLoader />}
      <FormWrapper onSubmit={onAppFormSubmit} className="no_fullscreen">
        <Box
          component={'div'}
          className={'container_content no_note'}
          ref={elementRef}
          sx={{
            [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
              minHeight: height,
              paddingBottom: height ? 4 : commonSpacing.desktopPaddingB,
            },
          }}
        >
          <Header
            isBackButtonVisible={true}
            onBackButtonClick={onBackButtonClick}
            isProgressBarVisible={true}
            totalProgressSteps={5}
            currentProgressStep={2}
            paddingX={0}
            backButtonId={'BusinessAddressBack'}
          />
          <Box sx={{ marginBottom: 6 }}>
            <HeadingSummaryBlock
              heading={t('headingContent.business.businessInfo.heading')}
              headingAlignment="left"
              content={t('headingContent.business.businessInfo.content')}
              contentAlignment="left"
            />
          </Box>
          <AddressFields
            setAddressStates={setAddressStates}
            addressStates={addressStates}
            register={register}
            errors={errors}
            control={control}
            getValue={getValues}
            setValue={setValue}
            trigger={trigger}
          />

          {addressError !== '' && (
            <Messages
              topMargin={2}
              type={'error'}
              messageHeading={t('headingContent.business.businessInfo.heading')}
              messageContent={addressError}
              closeEvent={() => setAddressError('')}
            />
          )}
        </Box>
        <ContentContainerBottom className={'no_note no_fullscreen'}>
          <Button
            variant="containedLarge"
            color="primary"
            type="submit"
            fullWidth
            sx={{ overflow: 'hidden' }}
          >
            {t('buttonTexts.continueButton')}
          </Button>
        </ContentContainerBottom>
      </FormWrapper>
    </>
  );
};
