import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import {
  Header,
  HeadingSummaryBlock,
  FormWrapper,
  ContentContainerBottom,
  FormInputSSN,
  TermsText,
  IdDocumentModal,
  DocVerifiedLink,
  AccountVerificationStatusses,
} from 'src/components/base/core';
import { ERROR_MESSAGES } from 'src/constants/Validation';
import { handleBusinessRepSSN } from 'src/helpers/containers/helperBusinessRepresentative';
import dtoToFormValues from 'src/shared/utilities/dto';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetAccountQuery } from 'src/services/account/accountServiceApi';
import { handleIdModalVisibility } from 'src/helpers/containers/helperIdDocumentModal';
import { RefreshIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import { useGetBlockHeight } from 'src/hooks/useGetBlockHeight';
import { theme, commonSpacing } from 'src/shared/theme/theme';

export const FormSSNSubmition = ({ onBackButtonClick, onFormSubmit, accountData }: any) => {
  const { t } = useTranslation();
  const [appSubmit, isLoading]: any = handleBusinessRepSSN();
  const [enableSSNUpdate, setEnableSSNUpdate]: any = useState(true);
  const [companyData, setCompanyData]: any = useState();
  const [openIdModal, onClickIdModalOpen, onClickIdModalClose] = handleIdModalVisibility();
  const [btnSubmitted, setBtnSubmitted] = useState(false);
  const { data, isFetching } = useGetAccountQuery(
    { count: 5 },
    { refetchOnMountOrArgChange: true }
  );
  const [elementRef, height] = useGetBlockHeight();

  useEffect(() => {
    if (data) {
      setCompanyData(data);
      dtoToFormValues(setValue, data);
      if (data?.individual?.ssn_last4_provided) {
        setEnableSSNUpdate(false);
      } else {
        setEnableSSNUpdate(true);
      }
    }
  }, [data]);

  const revieInfoSchema = yup.object({
    idNumber: yup.string().when('front', {
      is: (front: any) => {
        if (front || !enableSSNUpdate) {
          return false;
        } else {
          return true;
        }
      },
      then: yup.string().required(ERROR_MESSAGES.REQUIRED).min(4, ERROR_MESSAGES.SSN_INVALID_LAST4),
    }),
  });

  //hook-forms
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    control,
    getValues,
    formState: { errors },
  } = useForm<any>({
    reValidateMode: 'onBlur',
    mode: 'onSubmit',
    resolver: yupResolver(revieInfoSchema),
  });

  useEffect(() => {
    if (openIdModal) {
      trigger('idNumber');
    }
  }, [openIdModal]);

  const onAppFormSubmit = handleSubmit(async (data: any) => {
    appSubmit(data, onFormSubmit, enableSSNUpdate, openIdModal);
  });

  const updateBindingsChange = (event: any, feild: any) => {
    setEnableSSNUpdate(true);
    setValue(feild, event.target.value, { shouldValidate: btnSubmitted && true });
  };

  const getVerificationData = companyData && companyData?.individual?.verification;
  const confirmVerificationDocumentSubmitted =
    getVerificationData?.document?.front !== null && getVerificationData?.status === 'verified';
  const confirmUnVerificationDocumentSubmitted =
    getVerificationData?.document?.front !== null && getVerificationData?.status !== 'verified';
  const confirmationNoUploadDone =
    !confirmVerificationDocumentSubmitted && !confirmUnVerificationDocumentSubmitted;

  return (
    <>
      {isLoading && <ManualLoader />}
      <FormWrapper onSubmit={onAppFormSubmit} className="no_fullscreen">
        <Box component={'div'} className={'container_content bottom_terms_padding terms_note'}>
          <Box
            component={'div'}
            className={'container_content_top terms_note'}
            ref={elementRef}
            sx={{
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                minHeight: height,
                paddingBottom: height ? 4 : commonSpacing.desktopPaddingB,
              },
            }}
          >
            <Header
              isBackButtonVisible={true}
              onBackButtonClick={onBackButtonClick}
              isProgressBarVisible={true}
              totalProgressSteps={6}
              currentProgressStep={5}
              paddingX={0}
            />
            <Box sx={{ marginBottom: 6 }}>
              <HeadingSummaryBlock
                heading={t('headingContent.business.ssn.heading')}
                headingAlignment="left"
                content={t('headingContent.business.ssn.content')}
                contentAlignment="left"
              />
            </Box>
            <Controller
              control={control}
              name="idNumber"
              render={({ field: { value, ref } }) => (
                <FormInputSSN
                  label={t('formInput.ssn')}
                  id={'securityNumber'}
                  only4={true}
                  {...register('idNumber')}
                  error={!openIdModal && errors?.idNumber?.message}
                  inputError={!openIdModal && errors?.idNumber?.message}
                  onChange={(event: any) => {
                    updateBindingsChange(event, 'idNumber');
                  }}
                  inputFocus={(e: any) => {
                    trigger('idNumber');
                  }}
                  replace={enableSSNUpdate}
                  setEnableSSNUpdate={setEnableSSNUpdate}
                />
              )}
            />
            <Box component={'div'} paddingY={2} marginBottom={2}>
              <DocVerifiedLink
                className={`${
                  confirmVerificationDocumentSubmitted ? 'pointer_disabled' : 'pointer_enabled'
                }`}
                onClick={onClickIdModalOpen}
              >
                {confirmationNoUploadDone && (
                  <Typography
                    variant="h2"
                    color={'primary'}
                    sx={{ cursor: 'pointer', marginRight: 2 }}
                  >
                    {t('linkTexts.business.provideIDDocLink')}
                  </Typography>
                )}

                {(confirmUnVerificationDocumentSubmitted ||
                  confirmVerificationDocumentSubmitted) && (
                  <Typography
                    variant="h2"
                    color={'primary'}
                    sx={{ cursor: 'pointer', marginRight: 2 }}
                  >
                    {t('linkTexts.business.providedIDDoc')}
                  </Typography>
                )}
                {confirmUnVerificationDocumentSubmitted && !confirmationNoUploadDone && (
                  <RefreshIcon />
                )}
                {confirmVerificationDocumentSubmitted && (
                  <AccountVerificationStatusses type="verified" />
                )}
              </DocVerifiedLink>
            </Box>
            {/* {openIdModal && ( */}
            <IdDocumentModal
              register={register}
              setValue={setValue}
              verificationData={companyData && companyData.individual.verification}
              openIdModal={openIdModal}
              closeIdModalEvent={onClickIdModalClose}
            />
            {/* )} */}
          </Box>
          <Box component={'div'} className="container_content_bottom terms_note">
            <TermsText
              mainContent={t('noticeBlocks.termsBlock.heading')}
              subContent={t('noticeBlocks.termsBlock.content')}
            />
          </Box>
        </Box>
        <ContentContainerBottom className={'no_note no_fullscreen'}>
          <Button
            variant="containedLarge"
            color="primary"
            type="submit"
            fullWidth
            sx={{ overflow: 'hidden' }}
            onClick={() => {
              setBtnSubmitted(true);
            }}
          >
            {t('buttonTexts.continueButton')}
          </Button>
        </ContentContainerBottom>
      </FormWrapper>
    </>
  );
};
