import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '@mui/material';
import {
  Header,
  HeaderFixed,
  WrpperAuthorizedContainer,
  ButtonAuthorizedWrapper,
  FormAuthorizedWrapper,
  Wrapper,
  HeadingSummaryBlock,
  ManualLoader,
} from '../../../../components/base/core';
import { PayupCard } from 'src/components/base/core/Cards/MainPayupCard/PayupCard';

import { useGetVertualCardDetailsQuery } from 'src/services/account/accountServiceApi';

import { commonSpacing, theme } from '../../../../shared/theme/theme';
import { useGetBlockHeight } from 'src/hooks/useGetBlockHeight';

export const FinanceAccountCreationSuccessContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [elementRef, height] = useGetBlockHeight();
  const {
    data: cardData,
    isSuccess: isSuccessCardData,
    isLoading: isLoadingCardData,
  } = useGetVertualCardDetailsQuery(true);

  return (
    <>
      {isLoadingCardData && <ManualLoader />}
      <HeaderFixed className="fixed_only_desktop">
        <Header isMainMunuVisible isSkipButtonHide paddingX={0} isBackButtonHide />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper>
        <WrpperAuthorizedContainer>
          <FormAuthorizedWrapper>
            <Box
              ref={elementRef}
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                  minHeight: height,
                  paddingBottom: height ? 4 : commonSpacing.desktopPaddingB,
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  marginBottom: 6,
                  [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                    height: `179px !important`,
                  },
                  [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                    height: 'auto !important',
                  },
                }}
              >
                {isSuccessCardData && (
                  <PayupCard
                    data={cardData}
                    isCardSwipable={false}
                    isNameVisible={false}
                    isCardCopyVisible={false}
                    isCardShowNumberVisible={false}
                    isCardExpiryVisible={false}
                    isCardDotsIconVisible={false}
                    logoWidth={'92'}
                    logoHeight={'31'}
                    cardType="onlyNumber"
                  />
                )}
              </Box>
              <Box
                component={'div'}
                sx={{
                  marginX: 'auto',
                  width: '100%',
                }}
              >
                <HeadingSummaryBlock
                  heading={t('finance.accountCreation.heading')}
                  content={t('finance.accountCreation.content')}
                  headingAlignment="center"
                  contentAlignment="center"
                  spacing={1}
                />
              </Box>
            </Box>
            <ButtonAuthorizedWrapper>
              <Button
                variant="containedLarge"
                color="primary"
                fullWidth
                id={'Finance'}
                sx={{ backgroundColor: 'blue.500', color: 'white', marginBottom: 2 }}
                onClick={() => navigate('/finance/account/main')}
              >
                {t('finance.accountCreation.button')}
              </Button>
            </ButtonAuthorizedWrapper>
          </FormAuthorizedWrapper>
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
