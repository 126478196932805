import React from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { Messages } from '../Messages/Messages';
import { Wrapper } from '../../Layouts/Wrapper/Wrapper';
import { ApiFailMessageProps } from 'src/models/component/base.model';

export const ApiFailMessage = ({
  message = 'This is a message from Payup',
}: ApiFailMessageProps) => {
  const apiTimeout = useSelector((state: RootState) => state.apiTimeout);
  const domNode: any = document.getElementById('app-root');

  return ReactDOM.createPortal(
    apiTimeout && (
      <Wrapper>
        <Messages messageHeading={message} />
      </Wrapper>
    ),
    domNode
  );
};
