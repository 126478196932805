import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import {
  Header,
  HeadingSummaryBlock,
  FaqBlock,
  ContentContainerBottom,
  DivWrapper,
} from 'src/components/base/core';
import { commonSpacing, theme } from 'src/shared/theme/theme';

export const BankSetupInfoContainer = ({ onContinueClick, onBackButtonClick }: any) => {
  const { t } = useTranslation();

  return (
    <DivWrapper className="no_fullscreen">
      <Box component={'div'} className={'container_content'}>
        <Box
          component={'div'}
          className={'container_content_top'}
          sx={{
            [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
              minHeight: 349 + commonSpacing.desktopPaddingB * 4,
            },
          }}
        >
          <Header
            isBackButtonVisible={true}
            isLanguageSwitcher
            onBackButtonClick={onBackButtonClick}
            paddingX={0}
          />
          <Box sx={{ marginBottom: 6 }}>
            <HeadingSummaryBlock
              heading={t('headingContent.commonSteps.bankInfo.heading')}
              headingAlignment={'left'}
            />
          </Box>
          <Box>
            <FaqBlock
              heading={t('faqBlocks.private.heading')}
              content={t('faqBlocks.private.content')}
              iconType="PrivateIcon"
            />
            <FaqBlock
              heading={t('faqBlocks.secure.heading')}
              content={t('faqBlocks.secure.content')}
              iconType="SecureIcon"
            />
            <FaqBlock
              heading={t('faqBlocks.easyConnection.heading')}
              content={t('faqBlocks.easyConnection.content')}
              iconType="EasyConnection"
            />
          </Box>
        </Box>
      </Box>
      <ContentContainerBottom className={'no_fullscreen'}>
        <Button
          variant="containedLarge"
          color="primary"
          type="submit"
          fullWidth
          onClick={onContinueClick}
        >
          {t('buttonTexts.continueButton')}
        </Button>
      </ContentContainerBottom>
    </DivWrapper>
  );
};
