import { useNavigate } from 'react-router-dom';
import { handleTransactionCreatedAt } from '../../../../../../../helpers/helperHandlingTableGrid';

export const SecondCell = (props: any) => {
  const navigate = useNavigate();
  const getPropsOriginalData = props?.props?.row?.original;
  const { routeState } = props?.props;
  const { id, created } = getPropsOriginalData;
  const date = handleTransactionCreatedAt(created);

  return (
    <>
      <span
        className="hidden-mobile date_val"
        onClick={() => navigate(`/finance/account/transaction/detail/${id}`, { state: routeState })}
      >
        {date}
      </span>
    </>
  );
};
