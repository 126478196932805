import { styled } from '@mui/material/styles';

export const FormHeader = styled('div')(
  ({ theme }) => `
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 11px;
   padding-bottom: ${theme.spacing(2)};
   border-bottom: 1px solid ${theme.palette.gray[100]};

   .icon_delete {
    cusor: pointer !important;
   }
`
);

export const AddMoreButtonWrapper = styled('div')(
  ({ theme }) => `
 margin-bottom: 32px;

  button {
   height: 44px;
   font-size: 14px;
    line-height: 20px;

   .plus_text {
    margin-right: ${theme.spacing(3)};
    font-size: 20px;
   }
  }
`
);

export const CompanyCounter = styled('div')(
  ({ theme }) => `
 margin-bottom: ${theme.spacing(4)};
 display: flex;
 justify-content: center;
`
);
