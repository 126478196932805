export const esBatchesTranslation = {
  searchInputPlaceHolder: 'Buscar por número de lote',
  emptyState: {
    commonTabHeading: 'No hay facturas todavía',
    commonTabContent: 'Parece que no tiene ninguna factura para ver',
    firstTabHeading: 'No hay facturas todavía',
    firstTabContent: 'Sus facturas enviadas aparecerán aquí',
    secondTabHeading: 'No hay facturas todavía',
    secondTabContent: 'Tus facturas aprobadas aparecerán aquí',
    thirdTabHeading: 'No hay facturas todavía',
    thirdTabContent: 'Sus facturas pagadas aparecerán aquí',
  },
  download: {
    noInvoicesAvailableError: 'No hay transferencias dentro del período que seleccionaste.',
  },
  tabHeading: {
    tabOne: 'Lotes enviados',
    tabTwo: 'Lotes aprobados',
    tabThree: 'Lotes pagados',
  },
  tabsHeader: {
    payoutsText: 'Pagos',
    tabOneLeftTitle: 'CANTIDAD PRESENTADA',
    tabOneRightTitle: 'TOTAL PRESENTADA',
    tabTwoLeftTitle: 'CANTIDAD RECIBIDA',
    tabTwoRightTitle: 'TOTAL RECIBIDA',
    tabThreeLeftTitle: 'PAGADO',
    tabThreeRightTitle: 'TOTAL PAGADO',
  },
  invoicesBatchesNavigationButton: {
    batchesButton: 'Lotes',
    invoicesButton: 'Facturas',
  },
  details: {
    headerTitle: 'Resumen',
    timeline: {
      step1Title: 'Lote enviado',
      step2Title: 'Procesamiento por lotes',
      step3Title: 'Lote lista para el pago',
      step4Title: 'Pago por lotes activado',
      step5Title: 'Pago por lotes en camino',
      step6Title: 'Pago por lotes completado',
      transcriptLink: 'Ver recibo de transacción',
    },
  },
  payModeEditModal: {
    title: 'Editar método de pago',
    content:
      'Puede actualizar su método de pago a uno más rápido antes de que se active un pago el  {{PaymentTriggedOn}}',
    footerNote:
      'El tiempo se mide en días hábiles. El modo de pago no se puede cambiar una vez que se ha activado el pago.',
  },
  payModeEditConfirmationModal: {
    title: '¡Método de pago actualizado!',
    content:
      'Ahora está configurado para recibir pagos más rápido a través de {{selectedPayMode}}. El pago debería llegar antes del {{arrivalDate}}.',
  },
};
