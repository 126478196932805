import React from 'react';

import { SkeltonWrapper } from './SkeltonBar.style';

export interface SkeltonBarProps {
  width?: string;
  height?: string;
  minWidth?: string;
  marginIndex?: string;
}

export const SkeltonBar = ({
  width = '100%',
  height = '9px',
  minWidth = '50px',
  marginIndex = '0',
}: SkeltonBarProps) => {
  return (
    <SkeltonWrapper
      style={{ maxWidth: width, height: height, minWidth: minWidth, margin: marginIndex }}
    />
  );
};
