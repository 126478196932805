import { Snackbar } from '@mui/material';
import { Messages } from 'src/components/base/core';

interface SnackBarProps {
  messageHeading: string;
  messageContent?: string;
  duration?: number;
  onClose: () => void;
  type?: 'neutral' | 'warning' | 'success' | 'error' | 'delinquent' | 'default';
  messageLinkText?: string;
  messageLinkEvent?: any;
  hideCloseIcon?: boolean;
}

export const SnackBar = ({
  messageHeading,
  messageContent,
  onClose,
  duration = 2000, // Time of Android Toast.LENGTH_SHORT
  type = 'error',
  messageLinkText,
  messageLinkEvent,
  hideCloseIcon = true,
}: SnackBarProps) => {
  return (
    <Snackbar
      open={messageHeading !== ''}
      autoHideDuration={duration}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      sx={{ marginBottom: 16, marginLeft: 2, marginRight: 2 }}
    >
      <div style={{ display: 'flex', minWidth: 212 }}>
        {messageHeading !== '' ? (
          <Messages
            messageHeading={messageHeading}
            messageContent={messageContent}
            messageLinkEvent={messageLinkEvent}
            messageLinkText={messageLinkText}
            type={type}
            hideCloseIcon={hideCloseIcon}
          ></Messages>
        ) : null}
      </div>
    </Snackbar>
  );
};
