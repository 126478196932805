export class Address {
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  postal_code?: string;

  constructor(line1?: string, line2?: string, city?: string, state?: string, postal?: string) {
    this.line1 = line1;
    this.line2 = line2;
    this.city = city;
    this.state = state;
    this.postal_code = postal;
  }
}

export class Document {
  front?: string;
  back?: string;

  constructor(front?: string, back?: string) {
    this.front = front;
    this.back = back;
  }
}

export class Verification {
  document?: Document;

  constructor(document?: Document) {
    this.document = document;
  }
}

export class Metadata {
  documentType?: string;

  constructor(documentType?: string) {
    this.documentType = documentType;
  }
}

export class Individual {
  address: Address | undefined;
  dob: DOB | undefined;
  first_name: string | undefined;
  last_name: string | undefined;
  phone: string | undefined;
  email: string | undefined;
  id_number?: string | undefined;
  ssn_last_4?: string | undefined;
  verification?: Verification | undefined;
  metadata?: Metadata | undefined;

  constructor(
    address: Address | undefined,
    dob: DOB | undefined,
    first_name: string | undefined,
    last_name: string | undefined,
    phone: string | undefined,
    email: string | undefined,
    id_number?: string | undefined,
    ssn_last_4?: string | undefined,
    verification?: Verification | undefined,
    metadata?: Metadata | undefined
  ) {
    if (address && address !== undefined) {
      this.address = address;
    }

    if (dob && dob !== undefined) {
      this.dob = dob;
    }

    if (first_name && first_name !== undefined) {
      this.first_name = first_name;
    }
    if (last_name && last_name !== undefined) {
      this.last_name = last_name;
    }
    if (phone && phone !== undefined) {
      this.phone = phone;
    }
    if (email && email !== undefined) {
      this.email = email;
    }
    if (id_number && id_number !== undefined) {
      this.id_number = id_number;
    }
    if (ssn_last_4 && ssn_last_4 !== undefined) {
      this.ssn_last_4 = ssn_last_4;
    }
    if (
      (verification && verification?.document && verification?.document?.front !== undefined) ||
      verification?.document?.back !== undefined
    ) {
      this.verification = verification;
    }
    if (metadata && metadata?.documentType !== undefined) {
      this.metadata = metadata;
    }
  }
}

export class IndividualOwner extends Individual {
  relationship: RelationshipOwner;

  constructor(
    address: Address,
    dob: DOB,
    first_name: string,
    last_name: string,
    phone: string,
    email: string,
    relationship: RelationshipOwner,
    id_number?: string | undefined,
    ssn_last_4?: string | undefined,
    verification?: Verification | undefined,
    metadata?: Metadata | undefined
  ) {
    super(
      address,
      dob,
      first_name,
      last_name,
      phone,
      email,
      id_number,
      ssn_last_4,
      verification,
      metadata
    );

    this.relationship = relationship;
  }
}

export class IndividualExecutive extends Individual {
  relationship: RelationshipExecutive;

  constructor(
    address: Address,
    dob: DOB,
    first_name: string,
    last_name: string,
    phone: string,
    email: string,
    relationship: RelationshipExecutive,
    id_number?: string | undefined,
    ssn_last_4?: string | undefined,
    verification?: Verification | undefined,
    metadata?: Metadata | undefined
  ) {
    super(
      address,
      dob,
      first_name,
      last_name,
      phone,
      email,
      id_number,
      ssn_last_4,
      verification,
      metadata
    );

    this.relationship = relationship;
  }
}

export class RelationshipExecutive {
  representative: boolean;
  title: string;
  executive: boolean;
  percent_ownership?: number;
  constructor(rep: boolean, title: string, exectve: boolean, percent_ownership?: number) {
    this.representative = rep;
    this.title = title;
    this.executive = exectve;
    if (percent_ownership && percent_ownership !== undefined) {
      this.percent_ownership = percent_ownership;
    }
  }
}

export class RelationshipOwner {
  representative: boolean;
  title: string;
  owner: boolean;
  executive = false;

  constructor(rep: boolean, title: string, owner: boolean) {
    this.representative = rep;
    this.title = title;
    this.owner = owner;
  }
}

export class DOB {
  day: string;
  month: string;
  year: string;

  constructor(day: string, month: string, year: string) {
    this.day = day;
    this.month = month;
    this.year = year;
  }
}

export class BussinessProfile {
  mcc: string;
  product_description: string;
  name: string;
  url: string;

  constructor(mcc: string, prod_desc: string, name: string, url: string) {
    this.mcc = mcc;
    this.product_description = prod_desc;
    this.name = name;
    this.url = url;
  }
}

class Register {
  business_type: string;
  language: string;
  individual: Individual;
  business_profile: BussinessProfile;
  id: string;
  tos_shown_and_accepted: boolean;
  company_structure: string;
  default_payment_term_id: string;
  accept_other_terms: string;
  is_email_notification_active?: boolean;
  is_sms_notification_active?: boolean;
  default_pay_mode?: string;
  constructor(
    business_type: string,
    language: string,
    individual: Individual,
    business_profile: BussinessProfile,
    id: string,
    tos_shown_and_accepted: boolean,
    company_structure: string,
    default_payment_term_id: string,
    accept_other_terms: string,
    is_email_notification_active?: boolean,
    is_sms_notification_active?: boolean,
    default_pay_mode?: string
  ) {
    this.business_type = business_type;
    this.language = language;
    this.individual = individual;
    this.business_profile = business_profile;
    this.id = id;
    this.tos_shown_and_accepted = tos_shown_and_accepted;
    this.company_structure = company_structure;
    this.default_payment_term_id = default_payment_term_id;
    this.accept_other_terms = accept_other_terms;
    this.is_email_notification_active = is_email_notification_active;
    this.is_sms_notification_active = is_sms_notification_active;
    this.default_pay_mode = default_pay_mode;
  }
}

export class Company {
  name: string;
  phone: string;
  address: Address;
  tax_id: string | undefined;
  verification?: Verification | undefined;
  metadata?: Metadata | undefined;
  structure?: any;

  constructor(
    name: string,
    phone: string,
    address: Address,
    tax_id = undefined,
    verification?: Verification | undefined,
    metadata?: Metadata | undefined,
    structure?: any
  ) {
    this.name = name;
    this.phone = phone;
    this.address = address;
    if (tax_id && tax_id !== '') {
      this.tax_id = tax_id;
    }
    if (verification) {
      this.verification = verification;
    }
    if (metadata && metadata?.documentType !== 'Document Type') {
      this.metadata = metadata;
    }
    this.structure = structure;
  }
}

export class RegisterCompany extends Register {
  company: Company;

  constructor(
    business_type: string,
    language: string,
    individual: Individual,
    business_profile: BussinessProfile,
    id: string,
    tos_shown_and_accepted: boolean,
    company_structure: string,
    default_payment_term_id: string,
    accept_other_terms: string,
    company: Company
  ) {
    super(
      business_type,
      language,
      individual,
      business_profile,
      id,
      tos_shown_and_accepted,
      company_structure,
      default_payment_term_id,
      accept_other_terms
    );

    this.company = company;
  }
}

export default Register;

/**
 * init model for create account
 */
export class CreateIndividual {
  individual: BasicInfo;
  business_type: string;
  coupa_supplier_id: string | undefined | null;

  constructor(
    individual: BasicInfo,
    business_type: string,
    coupa_supplier_id: string | undefined | null
  ) {
    this.individual = individual;
    this.business_type = business_type;
    if (coupa_supplier_id && coupa_supplier_id !== undefined && coupa_supplier_id !== '') {
      this.coupa_supplier_id = coupa_supplier_id;
    }
  }
}

/**
 * basic info model for create account
 */
export class BasicInfo {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  constructor(first_name: string, last_name: string, email: string, phone: string) {
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.phone = phone;
  }
}
