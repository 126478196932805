import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const FormSelectInputWrapper = styled(Box)(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 ${theme.spacing(4)}; 

    input {
      border: none !important; 
      color: ${theme.palette.gray[900]}; 
      font-size: 14px;

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${theme.palette.gray[500]}; 
        opacity: 1; /* Firefox */
      }
  
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${theme.palette.gray[500]}; 
      }
  
      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${theme.palette.gray[500]}; 
      }

      &:active,
      &:focus,
      &:hover {
        background: ${theme.palette.white} !important;
      }
    }

    .MuiOutlinedInput-root {
      background: ${theme.palette.white} !important;
      box-shadow: 0 0 0 50px white inset !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.palette.gray[100]};
      border-radius: ${theme.shape.borderRadius}px;

      &.Mui-focused {
        border-width: 1px;
      }

      &:hover {
        border-color: ${theme.palette.gray[100]};
      }
    }

    &.valid_input {
      label {
        color: ${theme.palette.primary.main};
      }
      
      input {
        color: ${theme.palette.gray[900]}; 
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: ${theme.palette.primary.main};
        * {
          color: ${theme.palette.primary.main};
        }
      }
    }

    .Mui-error,
    &.error_input {
      label {
        color: ${theme.palette.error.main};
      }

      input {
        color: ${theme.palette.gray[900]}; 
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: ${theme.palette.gray[100]};
        * {
          color: ${theme.palette.error.main};
        }
      }
    }

    .MuiAutocomplete-popper {
      min-width: 200px;
    }

    .MuiFormLabel-root.Mui-focused {
      color: ${theme.palette.gray[600]};
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.palette.gray[100]};
      border-width: 1px;
    }

    .MuiIconButton-root.MuiAutocomplete-clearIndicator {
      display: none;
    }

    .MuiInputBase-root.MuiInputBase-formControl.MuiAutocomplete-inputRoot {
      .MuiInputBase-input.MuiAutocomplete-input {
        padding-left: 0;
        padding-right: 0;
        min-width: 48px;
      }
    }

    .MuiInputBase-root.MuiInputBase-formControl.MuiAutocomplete-inputRoot {
      padding-right: 34px;
    }

    label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-outlined{
      top: -2px;
      line-height: 16px;

      &.MuiInputLabel-shrink.MuiFormLabel-root {
        font-size: 12px;
        transform: translate(14px, -9px) scale(1);
        background: transparent;
        padding-right: 2px;
        top: 0;
        left: -4px;
      }
    }
`
);

export const FormSelectInputContainer = styled(Box)(
  ({ theme }) => `
  display: flex;
  width: 100%;
  position: relative;

  .MuiAutocomplete-root {
    width: 100%;
    box-sizing: border-box;
  }

  input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 50px ${theme.palette.white} inset;
    }

    &:active,
    &:focus,
    &:hover {
      background: ${theme.palette.white} !important;
    }
  }

  .MuiInputBase-input.MuiOutlinedInput-input {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    line-height: 16px;
  }
`
);
