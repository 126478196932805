import { Box, Button } from '@mui/material';
import { RWebShare } from 'react-web-share';
import { ShareIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';

export interface ShareButtonProps {
  label?: string;
  onClick?: any;
  title?: string;
  url?: string;
  text?: string;
  isButton?: boolean;
}

export const ShareButton = ({
  label,
  onClick,
  title,
  url,
  text,
  isButton = true,
}: ShareButtonProps) => {
  return (
    <Box component={'div'} sx={{ width: '100%' }}>
      <RWebShare
        data={{
          text: text,
          url: url,
          title: title,
        }}
        onClick={onClick}
      >
        <Button
          variant={isButton ? 'containedLarge' : 'menuButton'}
          color="primary"
          type="button"
          fullWidth
          sx={{ overflow: 'hidden' }}
        >
          {isButton && <ShareIcon />} <span style={{ marginLeft: isButton ? 8 : 0 }}>{label}</span>
        </Button>
      </RWebShare>
    </Box>
  );
};
