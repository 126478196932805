export const phoneNumberMask = (val: any) => {
  let num = '';
  if (val) {
    val = val?.replace(/\D/g, '');
    val = val.substring(1);
    if (val.length < 3) {
      num = `(${val.substring(0, 3)}`;
    } else {
      num = `(${val.substring(0, 3)}) ${val.substring(3, 6)}-${val.substring(6, 10)}`;
    }
  }
  return (num = num.trim());
};

export const phoneBusinessNumberMask = (val: any) => {
  let num = '';
  if (val) {
    val = val?.replace(/\D/g, '');
    if (val.length < 3) {
      num = `(${val.substring(0, 3)}`;
    } else {
      num = `(${val.substring(0, 3)}) ${val.substring(3, 6)}-${val.substring(6, 10)}`;
    }
  }
  return (num = num.trim());
};
