import {
  useCreateConfirmVerificationCodeMutation,
  useCreateVerificationCodeMutation,
} from 'src/services/auth/authServiceApi';
import { useState } from 'react';
import { ERROR_MESSAGES } from 'src/constants/Validation';
import { typeVerificationCodeFrom } from 'src/models/component/base.model';

export const handlePhoneVerifyCode = () => {
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);
  const [createConfirmVerificationCode] = useCreateConfirmVerificationCodeMutation();
  const [createVerificationCode] = useCreateVerificationCodeMutation();

  const handleChange = async (
    values?: any,
    onSubmitFunction?: any,
    phone?: any,
    setError?: any,
    setisVerified?: any,
    setisWrong?: any
  ) => {
    setIsVerifyLoading(true);
    // @ts-ignore
    if (values?.length === 6) {
      if (phone != null) {
        setisWrong(false);
        // @ts-ignore
        const response = await createConfirmVerificationCode({
          phone_number: phone,
          code: values,
        });
        // @ts-ignore
        if (response && response?.data?.error) {
          // @ts-ignore
          setError(response?.data?.error_description);
          setisVerified(false);
          setisWrong(true);
          setIsVerifyLoading(false);
          return;
        } else {
          setIsVerifyLoading(false);
          setisVerified(true);
          onSubmitFunction && onSubmitFunction();
        }
      }
    } else {
      setisVerified(false);
      setIsVerifyLoading(false);
    }
  };

  const handleResendCode = async (phone: any, setisWrong: any) => {
    setIsVerifyLoading(true);
    try {
      if (phone != null) {
        const response = createVerificationCode(phone);
        // @ts-ignore
        if (response && response?.data?.error) {
          setIsVerifyLoading(false);
        } else {
          setIsVerifyLoading(false);
        }
      }
    } catch (error: any) {
      console.log(error);
      setIsVerifyLoading(false);
    }
  };

  const handleOnKeydown = (e: any) => {
    if (e.keyCode === 8 && e.target.value === '') {
      const previousIndex =
        e.target?.id.substr(e.target?.id.length - 1) !== 0
          ? e.target?.id.substr(e.target?.id.length - 1) - 1
          : 0;
      let code: any = document.getElementById(`code-${previousIndex}`);
      code.focus();
    }
  };

  return [handleChange, handleResendCode, handleOnKeydown, isVerifyLoading];
};

/**
 * create function to handle the Phone verification in KYC page
 * @returns
 */
export const handleVerifyPhone = () => {
  const [errorState, setErrorState] = useState('');
  const [verifiedPhoneNumber, setverifiedPhoneNumber] = useState('');
  const [createVerificationCode, { isLoading: isPhoneVerificationLoading }] =
    useCreateVerificationCodeMutation();

  const handleVerificationCode = async (
    data: any,
    getValues: any,
    setContainerVisibility?: any,
    fromWhere?: typeVerificationCodeFrom
  ) => {
    setContainerVisibility(false);

    try {
      let phoneParameter: any = data.phone_number;

      if (fromWhere === 'business_info') {
        phoneParameter = data.inputCompanyPhone;
      }

      const verifyCodeResponse = await createVerificationCode(phoneParameter);
      // @ts-ignore
      if (verifyCodeResponse?.data?._id != null) {
        // @ts-ignore
        setverifiedPhoneNumber(verifyCodeResponse?.data?.phone_number);
        setContainerVisibility(true);
      } else {
        /**
         * display the server error along with input
         * if the error happen return error_description form catch block
         */
        // @ts-ignore
        const responseError = verifyCodeResponse?.data?.error;
        if (responseError) {
          /**
           * display a custom message because server error containes some code & for improve readability
           * ex: The 'To' number +10000000000 is not a valid phone number. (Code: 21211)"
           */
          if (responseError === 'sms_provider_error') {
            setErrorState(ERROR_MESSAGES.INVALID_SMS_PHONE.replace('{phone}', getValues('phone')));
          } else {
            // @ts-ignore
            setErrorState(verifyCodeResponse?.data?.error_description?.toString());
          }
        }
        setContainerVisibility(false);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  return [handleVerificationCode, verifiedPhoneNumber, errorState, isPhoneVerificationLoading];
};
