import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAccountDataMutation } from 'src/services/account/accountServiceApi';

export const handleHelperSettings = () => {
  const [accountData, setAccountData] = useState();
  const { t } = useTranslation();
  const [getAccountData]: any = useGetAccountDataMutation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const retryData = await getAccountData();
      setAccountData(retryData.data);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return [accountData, isLoading];
};
