import axios from 'axios';
import config from '../config';

export const GetPayoutTerms = async (earnings: any, id?: any, hideLoader?: boolean) => {
  config(hideLoader);
  return axios
    .get(`/account/payout-terms`, {
      params: {
        amount: earnings,
        payoutId: id,
      },
    })
    .then((res) => res)
    .catch((error) => error.response.data);
};

export const SetPayoutTerms = (id: any, net_term: any, hideLoader?: boolean) => {
  config(hideLoader);
  return axios
    .post(`/payout/${id}/updateTerms`, {
      payout_term_id: `${net_term}`,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return JSON.parse(error.request.response);
    });
};

export const SetMultiplePayoutTerms = (payoutIds: any, net_term: any, hideLoader?: boolean) => {
  config(hideLoader);

  return axios
    .post(`/payout/update-terms-bulk`, {
      payout_term_id: net_term,
      payoutIds: payoutIds,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return JSON.parse(error.request.response);
    });
};

export const GetCurrentInvoicesPayoutTerms = async (
  amount: any,
  id?: any,
  hideLoader?: boolean
) => {
  config(hideLoader);
  return axios
    .get(`/account/payout-terms-current-invoices`, {
      params: {
        amount: amount,
        payoutId: id,
      },
    })
    .then((res) => res)
    .catch((error) => error.response.data);
};
