import { createApi } from '@reduxjs/toolkit/query/react';
import { prepareBaseQuery } from '../../shared/config/config';

export const referralServiceApi = createApi({
  reducerPath: 'referralServiceApi',
  tagTypes: ['ReferralLink'],
  baseQuery: prepareBaseQuery(false),
  endpoints: (builder) => ({
    getReferralLink: builder.query({
      query: () => {
        return {
          url: '/account/referrals/ref-link',
          method: 'GET',
        };
      },
      providesTags: ['ReferralLink'],
    }),
    getAccountReferralReward: builder.query({
      query: () => {
        return {
          url: '/account/referrals/account-referral-rewards',
          method: 'GET',
        };
      },
      providesTags: ['ReferralLink'],
    }),
  }),
});
export const {
  useLazyGetReferralLinkQuery,
  useGetReferralLinkQuery,
  useGetAccountReferralRewardQuery,
} = referralServiceApi;
