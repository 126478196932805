import { Typography } from '@mui/material';
import {
  BankSetupIcon,
  DeleteIcon,
  USAflag,
  LinedTickIcon,
  MoreVerticalIcon,
  DownArrowIcon,
} from '../../Media/Icon/Icons/Icons';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ShadowCard } from '../../CommonNestedStyles/FormWrapper.style';
import {
  BankInfoCardTop,
  BankInfoCardBottom,
  BankInfoItem,
  BankInfoCardLeft,
  BankInfoCardRight,
  DefaultTag,
  ViewMoreDropDown,
  ViewMoreDropDownIcon,
  ViewMoreDropDownList,
  ViewMoreDropDownListItem,
} from '../../CommonNestedStyles/BankInfoCard.style';
import { useState } from 'react';
import { useComponentVisible } from 'src/hooks/useComponentVisible';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export const BankInfoCard = ({
  bankDetails,
  deleteAccount,
  updateDefaultAccount,
  isBorderEnabled = false,
  cardClassesType = 'normal_shadow',
}: {
  bankDetails: any;
  deleteAccount: any;
  updateDefaultAccount: any;
  isBorderEnabled?: boolean;
  cardClassesType?: 'remove_all_shadow' | 'full_border' | 'normal_shadow' | 'is_transparent';
}) => {
  const [dropdownState, setDropdownState] = useState(false);
  const { t } = useTranslation();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const handleDropdownClick = () => {
    setDropdownState(!dropdownState);
  };

  const handleCardClasses = () => {
    let cardClasses = cardClassesType;

    if (isBorderEnabled) {
      cardClasses = 'full_border';
    }

    return cardClasses;
  };

  return (
    <ShadowCard marginBottom={4} className={handleCardClasses()}>
      <BankInfoCardTop>
        <BankInfoCardLeft onClick={handleDropdownClick}>
          <div className="icon_arrow">
            {!dropdownState ? <KeyboardArrowRightIcon /> : <DownArrowIcon />}
          </div>
          <div className="bank_details">
            <Typography variant="h2Bold" color="gray.900" marginBottom={1} component="p">
              {bankDetails?.metadata?.nickname ?? bankDetails?.bank_name}
            </Typography>
            <Typography variant="h1" color="gray.600">
              {bankDetails?.last4
                ? `${t('home.linkBank.accountNumberText')}${bankDetails?.last4}`
                : ''}
            </Typography>
          </div>
        </BankInfoCardLeft>

        <BankInfoCardRight>
          {bankDetails?.default_for_currency && (
            <DefaultTag>
              <Typography variant="h1" color="darkBlue.300">
                {`Default`}
              </Typography>
            </DefaultTag>
          )}
          {!bankDetails?.default_for_currency && (
            <ViewMoreDropDown ref={ref}>
              <ViewMoreDropDownIcon onClick={() => setIsComponentVisible(!isComponentVisible)}>
                <MoreVerticalIcon />
              </ViewMoreDropDownIcon>
              {isComponentVisible && (
                <ViewMoreDropDownList>
                  <ViewMoreDropDownListItem className="remove-item">
                    <Typography
                      variant="h2"
                      color="red.500"
                      onClick={() => deleteAccount(bankDetails.id)}
                    >
                      <DeleteIcon />
                      {t('settings.LinkedBankAccount.remove')}
                    </Typography>
                  </ViewMoreDropDownListItem>
                  <ViewMoreDropDownListItem>
                    <Typography
                      variant="h2"
                      color="gray.900"
                      onClick={() => updateDefaultAccount(bankDetails.id)}
                    >
                      <LinedTickIcon />
                      {t('settings.LinkedBankAccount.default')}
                    </Typography>
                  </ViewMoreDropDownListItem>
                </ViewMoreDropDownList>
              )}
            </ViewMoreDropDown>
          )}
        </BankInfoCardRight>
      </BankInfoCardTop>

      {dropdownState && (
        <BankInfoCardBottom>
          <BankInfoItem>
            <Typography variant="h2Bold" color="gray.900">
              Type
            </Typography>
            <Typography variant="h2" color="gray.500">
              <BankSetupIcon />
              Bank account
            </Typography>
          </BankInfoItem>
          {bankDetails?.metadata?.created_at && (
            <BankInfoItem>
              <Typography variant="h2Bold" color="gray.900">
                Connected on
              </Typography>
              <Typography variant="h2" color="gray.500">
                {bankDetails?.metadata?.created_at
                  ? moment(bankDetails?.metadata?.created_at).format('MM/DD/yyyy h:mm A')
                  : '---'}
              </Typography>
            </BankInfoItem>
          )}

          {bankDetails?.metadata?.nickname && (
            <BankInfoItem>
              <Typography variant="h2Bold" color="gray.900">
                Bank Name
              </Typography>
              <Typography variant="h2" color="gray.500">
                {bankDetails?.bank_name ?? '---'}
              </Typography>
            </BankInfoItem>
          )}

          <BankInfoItem>
            <Typography variant="h2Bold" color="gray.900">
              Account holder
            </Typography>
            <Typography variant="h2" color="gray.500">
              {bankDetails?.account_holder_name ?? '---'}
            </Typography>
          </BankInfoItem>

          <BankInfoItem>
            <Typography variant="h2Bold" color="gray.900">
              Routing
            </Typography>
            <Typography variant="h2" color="gray.500">
              {bankDetails?.routing_number ?? '---'}
            </Typography>
          </BankInfoItem>
          <BankInfoItem>
            <Typography variant="h2Bold" color="gray.900">
              Country
            </Typography>
            <Typography variant="h2" color="gray.500">
              <USAflag /> {bankDetails?.country ?? '---'}
            </Typography>
          </BankInfoItem>
        </BankInfoCardBottom>
      )}
    </ShadowCard>
  );
};
