import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuItem, MenuNav } from './MainMenu.style';
import { Typography } from '@mui/material';
import { LargeDollarIcon, HomeIcon, InvoicesIcon, SettingIcon } from '../../Media/Icon/Icons/Icons';
import { PAY3349 } from 'src/constants/constants';

export const MainMenu = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const NavData = PAY3349
    ? [
        { title: t('menu.dashboard'), urlPath: '/dashboard', iconType: 'dashboard' },
        { title: t('menu.payments'), urlPath: '/payments', iconType: 'finances' },
        { title: t('menu.invoices'), urlPath: '/earnings/submitted', iconType: 'invoices' },
        { title: t('menu.settings'), urlPath: '/settings', iconType: 'settings' },
      ]
    : [
        { title: t('menu.dashboard'), urlPath: '/dashboard', iconType: 'dashboard' },
        { title: t('menu.finances'), urlPath: '/finance', iconType: 'finances' },
        { title: t('menu.invoices'), urlPath: '/earnings/submitted', iconType: 'invoices' },
        { title: t('menu.settings'), urlPath: '/settings', iconType: 'settings' },
      ];

  /**
   * used to handle the icons for mobile menu
   * @param _title this will retrive from NavData json
   * @returns Icon type
   */
  const handleIcon = (_title?: string) => {
    switch (_title) {
      case t('menu.dashboard'):
        return <HomeIcon />;
      case t('menu.invoices'):
        return <InvoicesIcon />;
      case t('menu.finances'):
        return <LargeDollarIcon />;
      case t('menu.payments'):
        return <LargeDollarIcon />;
      case t('menu.settings'):
        return <SettingIcon />;
      default:
        break;
    }
  };

  /**
   * used to hand the Invoices menu item active
   * @returns class name of invoices menu item active
   */
  const handleInvoiceTabActive = () => {
    let classValue = 'menu_list';

    if (
      location.pathname === '/earnings/approved' ||
      location.pathname === '/earnings/paid' ||
      location.pathname.includes('payout/')
    ) {
      classValue = 'invoice_tab_active';
    }

    if (location.pathname.includes('transfer/')) {
      classValue = 'tran_tab_active';
    }

    return classValue;
  };

  return (
    <MenuNav>
      <ul className={handleInvoiceTabActive()}>
        {NavData &&
          NavData.map((navitem: any, index: any) => {
            return (
              <MenuItem key={index}>
                <NavLink to={navitem.urlPath} className={navitem.iconType}>
                  <Typography component={'div'} variant={'h2'} color="gray.900">
                    <div className="meun_icon">{handleIcon(navitem.title)}</div>
                    <div className="meun_text">{navitem.title}</div>
                  </Typography>
                </NavLink>
              </MenuItem>
            );
          })}
      </ul>
    </MenuNav>
  );
};
