import styled, { keyframes } from 'styled-components';

const Load = keyframes`
0% {background-size: 0% 100%}
`;

export const PaginationLoader = styled.div`
  width: 52.5px;
  height: 8px;
  padding: 1.5px;
  margin: 2px auto 0;
  border: 1px solid rgba(41, 87, 245, 0.5);
  background: repeating-linear-gradient(90deg, rgba(41, 87, 245, 0.75) 0 12px, #fff 0 13.5px) left /
    67.5px 100% no-repeat content-box;
  animation: ${Load} 1s steps(5) infinite;
  border-radius: 5px;
`;

export const VerticalSpace = styled.div`
  height: 20px;
`;

export const PaginationLoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
  justify-content: center;

  @media (min-width: 768px) {
    &.submitted {
      padding-left: 28px;
    }
  }
`;
