import axios from 'axios';
import { configs } from '../../shared/config/config';
import config from '../config';

const headers = {
  'Content-Type': configs.CONTENT_TYPE,
};

export const AuthCookie = async (token: string) => {
  const apiConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': configs.CONTENT_TYPE,
    },
  };
  config(false);
  try {
    return await axios
      .post(`/auth`, {}, apiConfig)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } catch (e) {
    return e;
  }
};

export const GenerateAccessToken = async (
  code: string,
  codeVerifier: string,
  returnUrl: string
) => {
  const config = {
    headers: headers,
  };
  try {
    return await axios
      .post(
        `/login`,
        {
          code: code,
          code_verifier: codeVerifier,
          redirect_uri: returnUrl,
        },
        config
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } catch (e) {
    return e;
  }
};

export const getRefreshToken = async (rToken: string) => {
  const config = {
    headers: headers,
  };
  try {
    return await axios
      .post(
        `/login/refresh`,
        {
          refresh_token: rToken,
        },
        config
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } catch (e) {
    return e;
  }
};
