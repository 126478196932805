import { styled } from '@mui/material/styles';
export const LogoWrapper = styled('div')(
  ({ theme }) => `
  width: 98px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
    
  @media(max-width: ${theme.breakpoints.values.sm}px){
    &.logo-for-desktop {
      display: none !important;
    }
  }
  `
);
