import { Typography } from '@mui/material';
import { LockIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import { AccountNumberCardWrapper, AccountNumberCardLeft } from './AccountNumberCard.style';
import { useTranslation } from 'react-i18next';

export interface IAccountNumberCard {
  accountNumber?: string | number;
}

export const AccountNumberCard = ({ accountNumber }: IAccountNumberCard) => {
  const { t } = useTranslation();
  return (
    <AccountNumberCardWrapper>
      <AccountNumberCardLeft>
        <Typography variant="h2Bold" color={'gray.900'} sx={{ marginRight: 3 }}>
          {t('bankDetails.success.inputLabel')}
        </Typography>
        <Typography variant="h2Bold" color={'gray.600'} sx={{ marginRight: 1 }}>
          • • • • •
        </Typography>
        <Typography variant="h2" color={'gray.600'}>
          {accountNumber}
        </Typography>
      </AccountNumberCardLeft>
      <LockIcon />
    </AccountNumberCardWrapper>
  );
};
