import React, { useState } from 'react';
import { Box, Button, Checkbox, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  ButtonAuthorizedWrapper,
  FormAuthorizedWrapper,
  Header,
  HeaderFixed,
  ShadowCard,
  WrpperAuthorizedContainer,
  FormSwitch,
  NoteText,
  NoteWrapperAuthorizedWrapper,
  Wrapper,
  SnackBar,
} from 'src/components/base/core';
import { RootState } from 'src/store';
import { useSelector } from 'react-redux';
import { getAppInit } from 'src/services/init/init.service';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { accountIndividual } from 'src/models/account/account.service.mapper';
import { useUpdateAccountMutation } from 'src/services/account/accountServiceApi';
import {
  useGetNotificationPreferencesQuery,
  useUpdateNotificationPreferencesMutation,
} from '../../../services/account/notificationServiceApi';
import { StylesFormControlLabel } from 'src/containers/Account/SignUp/Notifications/NotificationModal/NotificationModal.style';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';
import { routeType } from 'src/helpers/containers/helperRoutePreference';
import { getMyAccountHeader } from 'src/shared/config/config';

type LocationState = {
  fromHome?: boolean;
};

export const SettingsNotificationsContainer = ({ isSettingDesktop = false }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { sendDigest } = useFlags();
  const { data, isLoading: isPrefsLoading } = useGetNotificationPreferencesQuery('');
  const [mapPrefs, setMapPrefs] = useState(new Map<string, boolean>());
  const [updateAccount] = useUpdateAccountMutation();
  const updateMapPrefs = (key: string, value: boolean) => {
    setMapPrefs((map) => new Map(map.set(key, value)));
  };
  const [mutation] = useUpdateNotificationPreferencesMutation();
  const account = useSelector((state: RootState) => state.account);
  const [accEmailNotifActive, setAccEmailNotifActive] = useState(false);
  const [accSMSNotifActive, setAccSMSNotifActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [accountData, setAccountData] = useState(account);
  const [responseType, setResponseType] = useState('');
  const [updatePreferenceOnServer] = handlePreferences();
  const fixedNotificationPrefs = [
    'DEFAULT_PAYMENT_TERMS_UPDATED',
    'INVOICE_PAYMENT_TERMS_UPDATED',
    'PAYOUT_COMPLETED',
  ];
  React.useEffect(() => {
    appInit();
  }, []);

  const appInit = () => {
    setIsLoading(true);
    getAppInit().then((response: any) => {
      const myAccountHeader = getMyAccountHeader(response);
      setAccountData(myAccountHeader);
      setAccEmailNotifActive(myAccountHeader?.is_email_notification_active);
      setAccSMSNotifActive(myAccountHeader?.is_sms_notification_active);
    });
    setIsLoading(false);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseType('');
    // @ts-ignore
    for (let [key, value] of mapPrefs.entries()) {
      await mutation({ id: key, is_active: value });
    }
    const dataModel = accountIndividual(
      {
        // business_type: accountData?.business_type === 'COMPANY' ? 'company' : 'individual',
        is_email_notification_active: accEmailNotifActive,
        is_sms_notification_active: accSMSNotifActive,
        id: accountData?.id,
      },
      true,
      false
    );
    const accountUpdateResponse = await updateAccount(dataModel);
    // @ts-ignore
    if (!accountUpdateResponse?.data) {
      // @ts-ignore
      const responseError = accountUpdateResponse?.error?.data;
      if (responseError) {
        setResponseType('error');
      }
    } else {
      setResponseType(t('notifications.updateSuccess'));
    }
    setIsLoading(false);
  };

  const appSubmitEmailCheckBox = async (
    event: React.ChangeEvent<HTMLInputElement>,
    val: boolean
  ) => {
    event.target.checked = val;
    setAccEmailNotifActive(val);
  };

  const appSubmitSMSCheckBox = async (event: React.ChangeEvent<HTMLInputElement>, val: boolean) => {
    event.target.checked = val;
    setAccSMSNotifActive(val);
  };

  if (isPrefsLoading) {
    return <div></div>;
  }

  const handleBackButton = () => {
    if ((location?.state as LocationState)?.fromHome) {
      navigate('/dashboard');
      updatePreferenceOnServer(routeType.HOME);
    } else {
      navigate('/settings');
      updatePreferenceOnServer(routeType.SETTINGS);
    }
  };

  const weeklyDigestItem = sendDigest
    ? data?.accountNotificationPreferences?.nodes?.filter(
        (item: any) => item?.notification_name === 'IS_WEEKLY_DIGEST'
      )[0]
    : undefined;

  const excludingList = [
    'VENDOR_ONBOARDING_COMPLETE',
    'CUSTOMER_SIGNUP_COMPLETE',
    'OLD_INVOICES_FOUND',
    'DELINQUENT_PROPERTY_INVOICE_SUBMITTED',
    'CUSTOMER_INVITATION',
    'CUSTOMER_SIGN_UP_REQUEST',
    'REFERRED_VENDOR_ONBOARDING_COMPLETE',
    'ACCOUNT_DELETION_REQUEST',
    'CANCEL_ACCOUNT_DELETION_REQUEST',
    'PAYUP_FINANCIAL_CARD',
    'MISSED_APPROVAL',
    'EXPORT_PAYOUTS_CSV_REQUEST',
    'IS_WEEKLY_DIGEST',
    'LINK_BANK_ACCOUNT_REMINDER',
    'CUSTOMER_BANK_ACCOUNT_ADDED',
    'VENDOR_PAYOUT_MODE_CHANGE',
    'CHECK_STATUS_CHANGED',
    'CHARGE_FAILED_ACHFILE',
    'TRANSFER_PAYOUT_MISMATCH',
    'VENDOR_PAYOUT_DISABLED',
    'COUPA_SUPPLIER_NOT_FOUND',
    'PAYMENTS_BALANCE_CARD_BALANCE_LOW',
    'TRANSFER_PAYOUT_ACCOUNT_INACTIVE',
    'TREASURY_BALANCE_LOW',
    'CHECKBOOK_PREFUNDING',
    'ACH_FILE_UPLOAD_FAIL_DUE_TO_VENDOR_MODE_CHECK',
    'PAYMENTS_BALANCE_BANK_ACCOUNT_BALANCE_LOW',
    'TRANSFER_PAYOUT_ACCOUNT_SUPPLIER_NOT_LINKED',
    'PAYMENT_FROM_PAYUP_OUTSIDE_OF_APP_DASHBOARD',
    'TRANSFER_NOT_CREATED_OR_PAID_TIMELY',
    'CHECK_RECEPIENT_DETAILS_NOT_PRESENT',
    'INACTIVE_COUPA_SUPPLIER_IS_LINKED_WITH_ACCOUNT_ID',
    'DOUBLE_ACCOUNT_ID_ON_COUPA_SUPPLIER',
  ];

  if (!sendDigest) {
    excludingList.push('READERS_DIGEST');
  }

  return (
    <>
      {isLoading && <ManualLoader />}
      {!isSettingDesktop && (
        <HeaderFixed className="fixed_only_desktop">
          <Header
            isMainMunuVisible
            isBackButtonHide
            isBackButtonVisible
            paddingX={0}
            headerText={t('settings.Notifications.title')}
            withCenteredHeaderText
            onBackButtonClick={handleBackButton}
          />
        </HeaderFixed>
      )}
      <Wrapper
        isAuthorizedWrapper
        idSelector={!isSettingDesktop ? 'wrapperContainer' : 'settingDesktopWraper'}
        isSettingDesktop={isSettingDesktop}
      >
        <WrpperAuthorizedContainer>
          <FormAuthorizedWrapper onSubmit={handleSubmit} className="note_notify">
            <ShadowCard
              paddingX={4}
              paddingTop={6}
              paddingBottom={1}
              marginBottom={4}
              className={isSettingDesktop ? 'full_border' : 'normal_shadow'}
            >
              <Box marginBottom={6}>
                <Typography variant="caption3" color="gray.400" textTransform={'uppercase'}>
                  {t('notifications.modal.subHeading1')}
                </Typography>
              </Box>
              <Box>
                {data?.accountNotificationPreferences?.nodes
                  ?.filter((item: any) => !excludingList.includes(item.notification_name))
                  .sort((a: any, b: any) => {
                    const a1 = fixedNotificationPrefs.includes(a.notification_name);
                    const b1 = fixedNotificationPrefs.includes(b.notification_name);
                    const c1 = 'READERS_DIGEST' === b.notification_name;
                    if ((a1 && !b1) || c1) {
                      return -1;
                    } else if (!a1 && b1) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((item: any) => {
                    return (
                      <FormSwitch
                        id={item.notification_name}
                        key={item.notification_name}
                        label={t(`notifications.modal.notificationPrefs.${item.notification_name}`)}
                        defaultChecked={item.is_active}
                        onChange={(e: any) => {
                          updateMapPrefs(item.id, e.target.checked);
                        }}
                        disabled={fixedNotificationPrefs.includes(item.notification_name)}
                        isDigest={item?.notification_name === 'READERS_DIGEST'}
                        isWeeklySelected={weeklyDigestItem?.is_active}
                        onRadioChange={(isWeekly) => updateMapPrefs(weeklyDigestItem?.id, isWeekly)}
                      />
                    );
                  })}
              </Box>
            </ShadowCard>
            <ShadowCard
              paddingX={4}
              paddingTop={6}
              paddingBottom={1}
              marginBottom={4}
              className={isSettingDesktop ? 'full_border' : 'normal_shadow'}
            >
              <Box marginBottom={6}>
                <Typography variant="caption3" color="gray.400" textTransform={'uppercase'}>
                  {t('notifications.modal.subHeading2')}
                </Typography>
              </Box>
              <Box>
                <StylesFormControlLabel
                  control={
                    <Checkbox
                      name="email"
                      size="small"
                      checked={accEmailNotifActive}
                      onChange={appSubmitEmailCheckBox}
                    />
                  }
                  label={
                    <Typography variant="body1" color={'gray.700'}>
                      {t('notifications.modal.emailText')}
                    </Typography>
                  }
                  labelPlacement="start"
                />
                <StylesFormControlLabel
                  control={
                    <Checkbox
                      name="text"
                      size="small"
                      checked={accSMSNotifActive}
                      onChange={appSubmitSMSCheckBox}
                    />
                  }
                  label={
                    <Typography variant="body1" color={'gray.700'}>
                      {t('notifications.modal.textMessageText')}
                    </Typography>
                  }
                  labelPlacement="start"
                />
              </Box>
            </ShadowCard>
            <SnackBar
              messageHeading={responseType}
              type={responseType === 'error' ? 'error' : 'success'}
              onClose={() => setResponseType('')}
            />
            <NoteWrapperAuthorizedWrapper>
              <NoteText iconType="LockedIcon" isBorder={false}>
                {t('noticeBlocks.notificationModal.content')}
              </NoteText>
            </NoteWrapperAuthorizedWrapper>
            <ButtonAuthorizedWrapper>
              <Button
                variant="containedLarge"
                color="primary"
                type="submit"
                fullWidth
                sx={{ overflow: 'hidden' }}
              >
                {t('buttonTexts.confirmButton')}
              </Button>
            </ButtonAuthorizedWrapper>
          </FormAuthorizedWrapper>
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
