import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import {
  Wrapper,
  HeadingSummaryBlock,
  FormInputSSN,
  FormWrapper,
  TermsText,
  ContentContainerBottom,
  Header,
  Messages,
} from 'src/components/base/core';
import { Box, Button } from '@mui/material';

import { handleEditIndividual } from 'src/helpers/containers/helperEditIndividual';
import { ERROR_MESSAGES } from 'src/constants/Validation';

import { Images } from 'src/shared/utilities/images';

import dtoToFormValues from 'src/shared/utilities/dto';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';

import { accountIndividual, accountCompany } from 'src/models/account/account.service.mapper';
import { useUpdateAccountMutation } from 'src/services/account/accountServiceApi';
import { commonSpacing, theme } from 'src/shared/theme/theme';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';
import { routeType } from 'src/helpers/containers/helperRoutePreference';

type LocationState = {
  fromHome?: boolean;
  isFromFinance: boolean;
};

export const SSNVerificationFailedContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [accountData, appLogin, isNullDOB]: any = handleEditIndividual();
  const [isLoading, setIsLoading] = useState(false);
  const [updateAccount] = useUpdateAccountMutation();
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [updatePreferenceOnServer] = handlePreferences();
  const [serverError, setServerError] = useState('');

  const { fromHome, isFromFinance } = location.state
    ? (location.state as LocationState)
    : { fromHome: false, isFromFinance: false };

  useEffect(() => {
    if (accountData) {
      dtoToFormValues(setValue, accountData);
      if (accountData && !isNullDOB(accountData.individual.dob)) {
        const dob = accountData?.individual?.dob;
        const formattedDOB: any = moment(new Date(dob.year, dob.month - 1, dob.day));
        setValue('dob', formattedDOB);
      }
    }
  }, [accountData]);

  const revieInfoSchema = yup
    .object({
      idNumber: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .min(9, ERROR_MESSAGES.SSN_INVALID_FULL),
    })
    .required();

  //hook-forms
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<any>({
    mode: 'onSubmit',
    resolver: yupResolver(revieInfoSchema),
  });

  const onAppFormSubmit = handleSubmit(async (data: any) => {
    setIsLoading(true);
    const getIDNumber = data.idNumber;
    // const getLastFour = getIDNumber.slice(-4);
    const dob = accountData?.individual?.dob;
    const formattedDOB: any = moment(new Date(dob.year, dob.month - 1, dob.day));
    const dobRes = moment(formattedDOB).format('MMDDYYYY');
    const updatedData = {
      ...data,
      idNumber: data.business_type === 'company' ? getIDNumber : getIDNumber,
      dob: dobRes,
      phone_number: data?.phone_number?.replace(/[^0-9]/g, ''),
      tos_shown_and_accepted: true,
    };

    const dataModel =
      data.business_type === 'company'
        ? accountCompany(updatedData, true, true)
        : accountIndividual(updatedData, true, true);

    setServerError('');
    const accountUpdateResponse = await updateAccount(dataModel);
    // @ts-ignore
    if (accountUpdateResponse?.data) {
      localStorage.setItem('enable_holdon', 'true');
      localStorage.setItem('first_time_9_digit_ssn_added', 'true');
      navigate('/hold-on', { state: { isFromFinance: isFromFinance } });
      // @ts-ignore
    } else if (accountUpdateResponse?.error?.data?.message) {
      setServerError('Please verify your profile details from the Account Information section.');
    }
    setIsLoading(false);
  });

  const updateBindingsChange = (event: any, feild: any) => {
    event.target.value.length === 9 ? setIsButtonEnabled(true) : setIsButtonEnabled(false);
    setValue(feild, event.target.value);
  };

  const handleBackButtonClick = () => {
    if (fromHome) {
      updatePreferenceOnServer(routeType.HOME);
      navigate('/dashboard');
    } else if (accountData?.business_type === 'company') {
      updatePreferenceOnServer(routeType.BUSINESS_REVIEW);
      navigate('/business-review', { state: { isFromFinance: isFromFinance } });
    } else {
      updatePreferenceOnServer(routeType.INDIVIDUAL_REVIEW);
      navigate('/individual-review', { state: { isFromFinance: isFromFinance } });
    }
  };

  return (
    <>
      {isLoading && <ManualLoader />}
      <Wrapper>
        <FormWrapper onSubmit={onAppFormSubmit} className="no_fullscreen">
          <Box component={'div'} className={'container_content bottom_terms_padding terms_note'}>
            <Header
              isBackButtonVisible={true}
              onBackButtonClick={handleBackButtonClick}
              isProgressBarVisible={false}
              paddingX={0}
              backButtonId={'SSNFailBack'}
            />
            <Box
              component={'div'}
              className={'container_content_top terms_note'}
              sx={{
                [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                  minHeight: 400 + commonSpacing.desktopPaddingB * 4,
                },
              }}
            >
              <div className="status_image">
                <img src={Images.VERIFICATION_FAILED} alt="Status" height={200} />
              </div>
              <div className="content_block">
                <HeadingSummaryBlock
                  heading={t('headingContent.statusses.ssnVerificationFailed.heading')}
                  headingAlignment="left"
                  content={t('headingContent.statusses.ssnVerificationFailed.content')}
                  contentAlignment="left"
                />
              </div>
              <div className="addition_element">
                {/* <FormInputSSN label={'Social Security Number'} id={'securityNumber'} only4={false} /> */}
                <FormInputSSN
                  label={t('formInput.ssn')}
                  id={'securityNumber'}
                  only4={false}
                  {...register('idNumber')}
                  replace
                  error={errors?.idNumber?.message}
                  inputError={errors?.idNumber?.message}
                  onChange={(event: any) => {
                    updateBindingsChange(event, 'idNumber');
                  }}
                  inputFocus={(e: any) => {
                    trigger('idNumber');
                  }}
                />
              </div>
              {serverError && (
                <Messages
                  type="error"
                  messageContent={serverError}
                  closeEvent={() => setServerError('')}
                />
              )}
            </Box>
            <Box component={'div'} className="container_content_bottom terms_note">
              <TermsText
                mainContent={t('noticeBlocks.termsBlock.heading')}
                subContent={t('noticeBlocks.termsBlock.content')}
              />
            </Box>
          </Box>
          <ContentContainerBottom className={'no_note no_fullscreen'}>
            <Button
              variant="containedLarge"
              color="primary"
              type="submit"
              fullWidth
              //onClick={() => navigate('/hold-on')}
              sx={{ overflow: 'hidden' }}
              disabled={!isButtonEnabled}
            >
              {t('buttonTexts.submitButton')}
            </Button>
          </ContentContainerBottom>
        </FormWrapper>
      </Wrapper>
    </>
  );
};
