import { useTranslation } from 'react-i18next';
import { ConditionalText } from '../ConditionalText/ConditionalText';

export const FooterNote = () => {
  const { i18n } = useTranslation();
  return (
    <>
      {i18n.resolvedLanguage === 'en' && (
        <ConditionalText>
          By clicking Submit, you agree to the PayUp's{' '}
          <a
            target="_blank"
            href="https://payup-docs.s3.us-east-2.amazonaws.com/PayUp+TOS+2022_04_22.pdf"
          >
            Terms Of Services
          </a>{' '}
          and{' '}
          <a
            target="_blank"
            href="https://payup-docs.s3.us-east-2.amazonaws.com/PayUp+-+Vendor+Receivables+Purchase+Agreement(154531217.10)-05_04_2022.pdf"
          >
            Vendor Agreement
          </a>
          as well as receiving automated text messages from PayUp and its partner Stripe. You also
          agree to Stripe's{' '}
          <a target="_blank" href="https://stripe.com/issuing/payout-card-user-agreement/legal">
            Stripe Services Agreement and E-SIGN
          </a>
          , and{' '}
          <a target="_blank" href="https://stripe.com/treasury-connect-account/legal">
            Commercial Account Agreement
          </a>
          .
        </ConditionalText>
      )}

      {i18n.resolvedLanguage === 'es' && (
        <ConditionalText>
          Al hacer clic en Enviar, acepta los{' '}
          <a
            target="_blank"
            href="https://payup-docs.s3.us-east-2.amazonaws.com/PayUp+TOS+2022_04_22.pdf"
          >
            Términos de servicio
          </a>{' '}
          y el{' '}
          <a
            target="_blank"
            href="https://payup-docs.s3.us-east-2.amazonaws.com/PayUp+-+Vendor+Receivables+Purchase+Agreement(154531217.10)-05_04_2022.pdf"
          >
            Acuerdo de proveedor
          </a>{' '}
          de PayUp, así como la recepción de mensajes de texto automáticos de PayUp y su socio
          Stripe. También acepta el{' '}
          <a target="_blank" href="https://stripe.com/issuing/payout-card-user-agreement/legal">
            Acuerdo de servicios de Stripe y E-SIGN
          </a>{' '}
          y el{' '}
          <a target="_blank" href="https://stripe.com/treasury-connect-account/legal">
            Acuerdo de cuenta comercial
          </a>{' '}
          de Stripe.
        </ConditionalText>
      )}
    </>
  );
};
