import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { theme } from 'src/shared/theme/theme';
import { AddIcon } from '../../Media/Icon/Icons/Icons';
import {
  BankAccountIconContainer,
  BankIconWrapper,
  InnerContent,
  LinkAnotherWrapper,
  LinkCardContainer,
} from './LinkBankCard.style';

export const LinkBankCard = ({
  title,
  onClick,
  data,
}: {
  title: string;
  onClick?: any;
  data: any;
}) => {
  const { t } = useTranslation();

  return (
    <LinkCardContainer>
      <BankIconWrapper>
        <BankAccountIconContainer />
        {title && (
          <Typography
            textTransform={'uppercase'}
            variant="caption3"
            sx={{ marginTop: '-12px', marginLeft: '22px' }}
          >
            {title}
          </Typography>
        )}
      </BankIconWrapper>
      {data &&
        data?.data?.map((option: any, index: any) => {
          return (
            <InnerContent key={index}>
              <Grid container sx={{ marginTop: index === 0 ? '16px' : '4px' }}>
                <Grid
                  item
                  xs={10}
                  sm={8}
                  sx={{
                    [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    },
                  }}
                >
                  <Typography variant="h2ExtraBold" textTransform={'capitalize'} color={'gray.800'}>
                    {option?.metadata?.nickname ?? option?.bank_name}
                  </Typography>
                  <Typography
                    variant="h1"
                    color={'gray.600'}
                    sx={{
                      marginTop: '4px',
                      display: 'flex',
                      alignItems: 'center',
                      minWidth: '136px',
                      [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                        marginTop: 0,
                        fontSize: '14px',
                      },
                    }}
                  >
                    {option?.last4 ? `${t('home.linkBank.accountNumberText')}${option?.last4}` : ''}
                  </Typography>
                </Grid>
                {option?.default_for_currency && (
                  <Grid
                    item
                    xs={2}
                    sm={4}
                    sx={{
                      [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                        marginTop: 1.5,
                      },
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        padding: '2px 6px',
                        backgroundColor: 'gray.100',
                        borderRadius: 1,
                        maxWidth: '54px',
                        float: 'right',
                      }}
                      color={'gray.900'}
                    >
                      {t('home.linkBank.linkDefaultButton')}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </InnerContent>
          );
        })}

      <Typography variant="h1" sx={{ fontWeight: 600, textAlign: 'center', alignItems: 'center' }}>
        <LinkAnotherWrapper onClick={onClick}>
          <AddIcon />
          <Typography
            variant="h1Bold"
            sx={{
              marginLeft: '10px',
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                fontSize: '14px',
              },
            }}
          >
            {t('home.linkBank.addMoreText')}
          </Typography>
        </LinkAnotherWrapper>
      </Typography>
    </LinkCardContainer>
  );
};
