import { styled } from '@mui/material/styles';

export const FilterTagWrapper = styled('label')(
  ({ theme }) => `
  width: calc(50% - 7.5px);
  display: flex;

  &:first-of-type,
  &:nth-of-type(2n + 1) {
    margin-right: 11px;
  }

  input {
    position: absolute;
    visibility: hidden;
    height: 0;
    width: 0;
    overflow: hidden;
    opacity: 0;
  }

  svg {
    fill: #1a1f36;
    margin-right: 5px;
  }

  label {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 11px 12px;
    margin-bottom: ${theme.spacing(4)};
    border: 1px solid ${theme.palette.gray[100]};
    border-radius: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: ${theme.palette.gray[900]};
    white-space: nowrap;
    cursor: pointer;
  }

  input:checked + label {
    background: ${theme.palette.blue[75]};
    border: 1px solid ${theme.palette.primary.main};
    color: ${theme.palette.primary.main};

    svg {
      fill: ${theme.palette.primary.main};
    }
  }

  &.sorting_tag {
    label {
      justify-content: left;
      padding-left: 42px;
    }

    svg {
      margin-right: 6px;
    }
  }
`
);
