import React from 'react';
import { ErrorContainer } from '../Common/Common';

export const Error404Container = () => {
  return (
    <ErrorContainer
      errorType={404}
      errorText="We cant seem to find the page you are looking for."
    />
  );
};
