import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  // Wrapper,
  // DivWrapper,
  // ContentContainerBottom,
  HeadingSummaryBlock,
  Header,
  // InviteCustomerNoteCard,
  CustomerCard,
  InviteUseModal,
  CustomerAdditionConfirmModal,
  HeaderFixed,
  WrpperAuthorizedContainer,
  ProgressCard,
  FormAuthorizedWrapper,
  ButtonAuthorizedWrapper,
  ShadowCard,
  Wrapper,
} from 'src/components/base/core';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useGetCustomerQuery } from 'src/services/customer/customerServiceApi';
import { handleCustomerInfo } from 'src/helpers/containers/helperCustomerInfo';
import { InvitedList } from './InvitedList/InvitedList';
import {
  useGetInviteListQuery,
  useGetCustomerReferralRewardQuery,
} from 'src/services/customer/customerServiceApi';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';
import { routeType } from 'src/helpers/containers/helperRoutePreference';

type LocationState = {
  fromHome?: boolean;
};

export const SettingsCustomersAdditionAcontainer = ({ isSettingDesktop = false }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { data } = useGetCustomerQuery(null);
  const { data: invitedList, isSuccess } = useGetInviteListQuery(null);
  const { data: customerReferralData, isSuccess: customerReferralSuccess } =
    useGetCustomerReferralRewardQuery(null);

  const [updateData, setUpdateData]: any = useState(null);
  const [isUpdate, setIsUpdate]: any = useState(null);
  const [customerCount, setCustomerCount] = useState(1);
  const [updatePreferenceOnServer] = handlePreferences();
  const [
    handleCompanySelection,
    states,
    setStates,
    addCustomer,
    isLoading,
    deleteCustomerHandler,
    updateCustomer,
  ]: any = handleCustomerInfo();

  const [isCustomerAddCompleted, setCustomerAddCompleted] = useState(false);

  const handleSkipButtonClick = () => {
    navigate('/sign-up');
  };

  const handleModalOpen = () => {
    setCustomerCount(1);
    setStates({ ...states, openModal: true });
  };

  const handleModalClose = () => {
    setUpdateData(null);
    setStates({ ...states, openModal: false });
  };

  const handleModalSubmit = (data: any) => {
    updateData === null
      ? addCustomer(data, setCustomerAddCompleted, handleModalClose, setCustomerCount)
      : updateCustomer(data, setCustomerAddCompleted, handleModalClose);
  };

  const handleCustomerModalSubmit = (data: any) => {
    setIsUpdate(false);
    setCustomerAddCompleted(false);
  };

  const handleUpdateCustomer = (data: any) => {
    setCustomerAddCompleted(false);
    setUpdateData(data);
    setStates({ ...states, openModal: true });
    setIsUpdate(true);
  };

  useEffect(() => {
    const el: any = document.getElementById('wrapperContainer');
    (states.openModal || isCustomerAddCompleted) && (el.style.overflowY = 'hidden');
    !states.openModal && !isCustomerAddCompleted && (el.style.overflowY = 'auto');
  }, [states.openModal, isCustomerAddCompleted]);

  const buttonVisibility =
    (states && states.comapnySelection.length > 0) ||
    (invitedList && invitedList?.customerReferrals?.nodes.length > 0);

  const handleBackButton = () => {
    if ((location?.state as LocationState)?.fromHome) {
      navigate('/dashboard');
      updatePreferenceOnServer(routeType.HOME);
    } else {
      navigate('/settings');
      updatePreferenceOnServer(routeType.SETTINGS);
    }
  };

  return (
    <>
      {!isSettingDesktop && (
        <HeaderFixed className="fixed_only_desktop">
          <Header
            isMainMunuVisible
            onBackButtonClick={handleBackButton}
            isBackButtonVisible
            isBackButtonHide
            paddingX={0}
            headerText={t('settings.Customers.title')}
            withCenteredHeaderText
          />
        </HeaderFixed>
      )}

      <Wrapper
        isAuthorizedWrapper
        idSelector={!isSettingDesktop ? 'wrapperContainer' : 'settingDesktopWraper'}
        isSettingDesktop={isSettingDesktop}
      >
        <WrpperAuthorizedContainer>
          <FormAuthorizedWrapper>
            <Box marginBottom={6}>
              <HeadingSummaryBlock
                heading={t('settingsCustomers.heading')}
                headingAlignment="left"
              />
            </Box>
            <ProgressCard
              title={t('settingsCustomers.progressTitle')}
              overallProgressLabel={t('settingsCustomers.overallProgressValue')}
              overallProgressValue={`$${
                customerReferralSuccess && customerReferralData.amountAvailable
                  ? customerReferralData.amountAvailable
                  : 0
              }`}
              progressLabel={t('settingsCustomers.progressValue')}
              progressValue={`$${
                customerReferralSuccess && customerReferralData.amountEarned
                  ? customerReferralData.amountEarned
                  : 0
              }`}
              totalProgressSteps={
                customerReferralSuccess && customerReferralData.totalReferred
                  ? customerReferralData.totalReferred
                  : 0
              }
              currentProgressStep={
                customerReferralSuccess && customerReferralData.totalJoined
                  ? customerReferralData.totalJoined
                  : 0
              }
              stepsContent={t('settingsCustomers.stepsContent')}
              isBorderCardEnabled={isSettingDesktop}
            />
            <Box marginBottom={isSettingDesktop ? 6 : 8}>
              <HeadingSummaryBlock
                content={t('settingsCustomers.content')}
                contentAlignment="left"
              />
            </Box>

            <Box
              display={'flex'}
              marginBottom={isSettingDesktop ? 3 : 8}
              flexWrap="nowrap"
              sx={{
                flexGrow: 1,
              }}
            >
              <ShadowCard
                paddingY={4}
                className={isSettingDesktop ? 'full_border' : 'normal_shadow'}
              >
                <Typography
                  variant="caption3"
                  color="gray.400"
                  textTransform={'uppercase'}
                  marginBottom={4}
                  paddingX={4}
                  component={'p'}
                >
                  {t('settingsCustomers.subHeading1')}
                </Typography>
                <Grid container spacing={0}>
                  {data &&
                    data.map((option: any, index: any) => {
                      return (
                        <Grid item xs={12} key={index}>
                          <CustomerCard
                            Address="Austin, TX"
                            inputId={option?.id}
                            companyName={option?.business_name}
                            onChangeEvent={handleCompanySelection}
                            isBordered={false}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </ShadowCard>
            </Box>

            {invitedList && invitedList?.customerReferrals?.nodes.length > 0 && (
              <ShadowCard
                padding={4}
                marginBottom={isSettingDesktop ? 0 : 8}
                marginTop={isSettingDesktop ? 4 : 0}
                className={isSettingDesktop ? 'full_border' : 'normal_shadow'}
              >
                <Typography
                  variant="caption3"
                  color="gray.400"
                  textTransform={'uppercase'}
                  marginBottom={4}
                  component={'p'}
                >
                  {t('settingsCustomers.subHeading2')}
                </Typography>
                <InvitedList
                  data={invitedList}
                  isSuccess={isSuccess}
                  onClickEventHandler={handleUpdateCustomer}
                  spacing={0}
                  isTitleVIsible={false}
                  isBordered={false}
                />
              </ShadowCard>
            )}

            <ButtonAuthorizedWrapper>
              <Button
                variant="containedLarge"
                color="primary"
                type="button"
                fullWidth
                onClick={handleModalOpen}
              >
                <Typography variant="h5Bold" color="white" sx={{ marginRight: 2 }}>
                  +
                </Typography>
                {t('settingsCustomers.confirmButton')}
              </Button>
            </ButtonAuthorizedWrapper>
          </FormAuthorizedWrapper>
        </WrpperAuthorizedContainer>
      </Wrapper>
      {states.openModal && (
        <InviteUseModal
          onCloseEvent={handleModalClose}
          onFormSubmit={handleModalSubmit}
          isLoading={isLoading}
          updateRef={updateData}
          deleteCustomer={deleteCustomerHandler}
        />
      )}
      {isCustomerAddCompleted && (
        <CustomerAdditionConfirmModal
          onCloseEvent={handleModalClose}
          onFormSubmit={handleCustomerModalSubmit}
          isUpdate={isUpdate}
          customerCount={customerCount}
        />
      )}
    </>
  );
};
