import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { AccountParams } from '../../../../../shared/utilities/interface';
import {
  AddressCityStateZip,
  RecipentAddressCityStateZip,
} from '../../../../../shared/utilities/addressUtils';
import {
  phoneBusinessNumberMask,
  phoneNumberMask,
} from '../../../../../shared/utilities/phoneNumberMask';
import { getFormattedDate } from '../../../../../shared/utilities/dob';

import {
  Header,
  Wrapper,
  HeadingSummaryBlock,
  ReviewCard,
  DivWrapper,
  ContentContainerBottom,
  Messages,
  AccountVerificationStatusses,
  FooterNote,
  ShadowCard,
  FinancesTermsModal,
} from 'src/components/base/core';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';

import { RefreshIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import {
  useGetAccountQuery,
  useTreasuryFinancialCardMutation,
  useUpdateAccountMutation,
} from 'src/services/account/accountServiceApi';
import dtoToFormValues from 'src/shared/utilities/dto';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { trackEvent } from 'src/components/analytics/service';
import { FormEditPersonalDetailsSubmition } from './FormEditPersonalDetailsSubmition/FormEditPersonalDetailsSubmition';
import { FormEditBusinesslDetailsSubmition } from './FormEditBusinesslDetailsSubmition/FormEditBusinesslDetailsSubmition';
import { commonSpacing, theme } from 'src/shared/theme/theme';
import { getUpdateRouteObj, routeType } from 'src/helpers/containers/helperRoutePreference';
import { useUpdatePreferenceMutation } from 'src/services/account/account.preferenceApi';
import { handleMissingKYCDetails } from 'src/helpers/helperHandleAccountValues';
import { checkIsAnyOfTheRecipentDetailsAreAddedOrNot } from 'src/helpers/helperHandlingTerms';

type LocationState = {
  isFromFinance: boolean;
  isFromAccountSuccessScreen: boolean;
};

export const BusinessLetsReviewContainer = () => {
  const editFormVisibility = {
    personaInfo: true,
    businessInfo: true,
    personaInfoEdit: false,
    businessInfoEdit: false,
  };

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { webauthnEnabled, recipientDetailsFormEnabled } = useFlags();
  const location = useLocation();

  const { data } = useGetAccountQuery(null);
  const [updateAccount] = useUpdateAccountMutation();
  const [updatePreference] = useUpdatePreferenceMutation();

  const [result, setResult]: any = useState();
  const [detailSummitted, setDetailSummitted] = useState(false);
  const [serverErrorsSSN, setServerErrorsSSN]: any = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editFormVisibilityStates, setEditFormVisibilityStates]: any = useState(editFormVisibility);
  const [termsModalOpen, setTermsModalOpen] = useState<boolean>(false);
  const [errorMessageFA, setErrorMessageFA] = useState<string>('');
  const [isRefressed, setIsRefressed] = useState<boolean>(false);

  const { isFromFinance, isFromAccountSuccessScreen } =
    location?.state != null
      ? (location?.state as LocationState)
      : {
          isFromFinance: false,
          isFromAccountSuccessScreen: false,
        };

  const updatePreferenceOnServer = async (type: any, step: any = null) => {
    const obj = getUpdateRouteObj(type, step);
    await updatePreference({ data: obj });
  };
  //hook-forms
  const { setValue } = useForm<AccountParams>();

  useEffect(() => {
    if (data) {
      setResult(data);

      localStorage.removeItem('isBack');
      localStorage.removeItem('failed_company');

      if (detailSummitted && data?.tos_shown_and_accepted && !isFromFinance) {
        localStorage.setItem('enable_holdon', 'true');
        localStorage.removeItem('backFromReview');
        setTimeout(function () {
          setDetailSummitted(false);
          navigate('/hold-on');
        }, 100);
      }
    }
  }, [data, isRefressed]);

  useEffect(() => {
    dtoToFormValues(setValue, result);

    if (result) {
      const { status, details_code, details } = result?.individual?.verification;
      const { executive, percent_ownership } = result?.individual?.relationShip;

      if (
        status === 'unverified' &&
        details_code === 'failed_keyed_identity' &&
        executive &&
        percent_ownership > 0
      ) {
        setServerErrorsSSN(details);
      } else {
        setServerErrorsSSN(null);
      }
    }
  }, [result]);

  const submitForm = async () => {
    trackEvent('Submit Click on Review');
    setIsLoading(true);

    const updateFormData = {
      tos_shown_and_accepted: true,
    };

    const accountUpdateResponse: any = await updateAccount(updateFormData);

    // @ts-ignore
    if (
      // @ts-ignore
      accountUpdateResponse?.data?.currently_due_requirements.includes('company.tax_id') && // @ts-ignore
      !accountUpdateResponse?.data?.company?.verification.document.front && // @ts-ignore
      !accountUpdateResponse?.data?.company?.verification.document.back
    ) {
      localStorage.setItem('ein-verification-enabled', 'true');
    }

    // @ts-ignore
    if (accountUpdateResponse?.data) {
      if (isFromFinance) {
        const isKYCDetailsMissing = handleMissingKYCDetails(accountUpdateResponse?.data);
        // @ts-ignore
        if (isKYCDetailsMissing) {
          setTermsModalOpen(true);
        } else {
          setTermsModalOpen(false);
        }
      } else {
        localStorage.removeItem('review_error_block');
        navigate('/hold-on');
      }
    }

    setDetailSummitted(true);
    setIsLoading(false);
  };

  const handlePersonalInfoEditIcon = () => {
    setEditFormVisibilityStates({
      ...editFormVisibility,
      personaInfo: false,
      personaInfoEdit: true,
    });
  };

  const handleBusinessInfoEditIcon = () => {
    setEditFormVisibilityStates({
      ...editFormVisibility,
      businessInfoEdit: true,
      businessInfo: false,
    });
  };

  const handlePersonalInfoCancelEvent = () => {
    setEditFormVisibilityStates({
      ...editFormVisibility,
      personaInfo: true,
      personaInfoEdit: false,
    });
  };

  const handleBusinessInfoCancelEvent = () => {
    setEditFormVisibilityStates({
      ...editFormVisibility,
      businessInfoEdit: false,
      businessInfo: true,
    });
  };

  const isTaxIDProvided = result?.currently_due_requirements?.includes('company.tax_id');
  const haveCompanyDueReqsAvailable =
    result?.currently_due_requirements?.filter((item: any) => item?.startsWith('company.')).length >
    0;

  const verificationStatus = result?.individual?.verification?.status;
  const businessVerificationStatus =
    isTaxIDProvided || haveCompanyDueReqsAvailable ? 'unverified' : 'verified';

  const verificationDocumentFront = result?.individual?.verification?.document.front;
  const verificationDocumentBack = result?.individual?.verification?.document.back;
  const taxDocFront = result?.company?.verification.document.front;
  const taxDocBack = result?.company?.verification.document.back;
  const isTaxDocIDAdded =
    (taxDocFront && taxDocFront !== undefined) || (taxDocBack && taxDocBack !== undefined);

  const handlePersonalInfoEditEinUpdate = () => {
    localStorage.setItem('ein-verification-enabled', 'true');
    updatePreferenceOnServer(routeType.EIN_FAIL_BUSINESS);
    navigate('/ein-verification-failed', {
      state: {
        isFromFinance: isFromFinance,
        isFromAccountSuccessScreen: isFromAccountSuccessScreen,
      },
    });
  };

  const dateObject = result?.individual?.dob;
  const checkDOBNull =
    dateObject && dateObject?.day !== null && dateObject.month !== null && dateObject.year !== null;

  const onTermsModalClose = () => setTermsModalOpen(false);

  const [cardCreation, { isLoading: isLoadingFATeemsAccept }] = useTreasuryFinancialCardMutation();

  const onAcceptButtonClick = async () => {
    setErrorMessageFA('');
    const response: any = await cardCreation(null);

    if (response) {
      if (response.error) {
        setErrorMessageFA(response.error.data.message ?? response.error.data.error);
        setTermsModalOpen(false);
      } else {
        navigate('/finance/account-loading', {
          state: { isFromAccountSuccessScreen: isFromAccountSuccessScreen },
        });
        setErrorMessageFA('');
        setTermsModalOpen(false);
        localStorage.setItem('cardIssue', 'processed');
      }
    }
  };

  const onMessageClose = () => setErrorMessageFA('');

  const isVerificationErrorMessageVisible =
    (serverErrorsSSN ||
      result?.errors?.length > 0 ||
      result?.currently_due_requirements?.length > 0) &&
    !isLoading;

  const handleRefreshForPendingStatus = () => {
    setIsRefressed(!isRefressed);
  };

  const [isRecipentDetailsAreAvailable] = checkIsAnyOfTheRecipentDetailsAreAddedOrNot(result);

  return (
    result && (
      <>
        {(isLoading || isLoadingFATeemsAccept) && (
          <ManualLoader isLoaderEnable={isLoadingFATeemsAccept ? false : true} />
        )}
        <Wrapper>
          <DivWrapper className={'no_fullscreen'}>
            <Box component={'div'} className={'container_content with_note bottom_terms_padding'}>
              <Box
                component={'div'}
                className={'container_content_top'}
                sx={{
                  [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                    minHeight: 630 + commonSpacing.desktopPaddingB * 4,
                    paddingBottom: 2,
                  },
                }}
              >
                <Header
                  isBackButtonVisible={false}
                  isProgressBarVisible={true}
                  totalProgressSteps={5}
                  currentProgressStep={5}
                  paddingX={0}
                  isLanguageSwitcher
                  isLogoLeftAligned
                />
                <Box sx={{ marginBottom: 4 }}>
                  <HeadingSummaryBlock
                    heading={t('headingContent.business.review.heading')}
                    headingAlignment="left"
                    content={t('headingContent.business.review.content')}
                    contentAlignment="left"
                  />
                </Box>

                <Box>
                  {editFormVisibilityStates.businessInfo && (
                    <ShadowCard marginBottom={6} px={4} pt={6} pb={4}>
                      <ReviewCard
                        title={t('captions.businessDetails')}
                        name={`${result.company.name}`}
                        editIconClickEvent={handleBusinessInfoEditIcon}
                        isInnerTitle
                        isShadowWrappedIcon
                      >
                        <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                          {`${phoneBusinessNumberMask(result.company.phone)}`}
                        </Typography>
                        <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                          {result?.company?.address?.line1 ?? ''}
                        </Typography>
                        <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                          {result?.company?.address?.line2 ?? ''}
                        </Typography>
                        <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                          {AddressCityStateZip(result?.company)}
                        </Typography>

                        <Typography
                          variant="h1"
                          color={'gray.700'}
                          marginBottom={2}
                          className="ssn_provided_text"
                        >
                          {t('reviewCardtexts.taxIdProvided')}
                          <div
                            className="icon_wrapper"
                            style={{ cursor: 'pointer' }}
                            onClick={handleBusinessInfoEditIcon}
                          >
                            <RefreshIcon />
                          </div>
                        </Typography>
                        {isTaxDocIDAdded && (
                          <Typography
                            variant="h1"
                            color={'gray.700'}
                            marginBottom={2}
                            className="ssn_provided_text"
                          >
                            {t('reviewCardtexts.idProvidedText')}
                            <div
                              className="icon_wrapper"
                              style={{ cursor: 'pointer' }}
                              onClick={handlePersonalInfoEditEinUpdate}
                            >
                              <RefreshIcon />
                            </div>
                          </Typography>
                        )}
                      </ReviewCard>
                    </ShadowCard>
                  )}
                  {editFormVisibilityStates.businessInfoEdit && (
                    <Box
                      component={'div'}
                      sx={{
                        [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                          paddingBottom: 6,
                        },
                        [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                          paddingBottom: 2,
                        },
                      }}
                    >
                      <FormEditBusinesslDetailsSubmition
                        title={t('captions.businessDetails')}
                        onCancelButton={handleBusinessInfoCancelEvent}
                        result={result}
                        isFromFinance={isFromFinance}
                      />
                    </Box>
                  )}

                  {editFormVisibilityStates.personaInfo && (
                    <ShadowCard marginBottom={6} px={4} pt={6} pb={4}>
                      <ReviewCard
                        title={t('captions.personalDetails')}
                        name={`${result.individual.first_name} ${result.individual.last_name}`}
                        editIconClickEvent={handlePersonalInfoEditIcon}
                        isInnerTitle
                        isShadowWrappedIcon
                      >
                        <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                          {`${result?.individual.email}`}
                        </Typography>
                        {webauthnEnabled && (
                          <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                            {`•••••••••••••••`}
                          </Typography>
                        )}
                        <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                          {`${phoneNumberMask(result.individual.phone)}`}
                        </Typography>
                        <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                          {checkDOBNull
                            ? `${t('reviewCardtexts.bornOnText')} ${getFormattedDate(
                                result?.individual?.dob
                              )}`
                            : null}
                        </Typography>

                        {result?.individual?.address?.line1 && (
                          <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                            {result?.individual?.address?.line1 ?? ''}
                          </Typography>
                        )}
                        {result?.individual?.address?.line2 && (
                          <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                            {result?.individual?.address?.line2 ?? ''}
                          </Typography>
                        )}
                        {result?.individual?.address?.city && (
                          <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                            {AddressCityStateZip(result?.individual)}
                          </Typography>
                        )}

                        {result?.individual?.ssn_last4_provided && (
                          <Typography
                            variant="h1"
                            color={'gray.700'}
                            marginBottom={2}
                            className="ssn_provided_text"
                          >
                            {t('reviewCardtexts.idProvidedLast4Text')}

                            {verificationStatus === 'verified' && (
                              <div className="ssn_provided_text_verified">
                                <AccountVerificationStatusses type={'verified'} />
                              </div>
                            )}

                            {verificationStatus === 'pending' && (
                              <div className="ssn_provided_text_verified">
                                <AccountVerificationStatusses type={'pending'} />
                              </div>
                            )}

                            {verificationStatus !== 'verified' && (
                              <div
                                className="icon_wrapper"
                                style={{ cursor: 'pointer' }}
                                onClick={
                                  verificationStatus === 'pending'
                                    ? handleRefreshForPendingStatus
                                    : handlePersonalInfoEditIcon
                                }
                              >
                                <RefreshIcon />
                              </div>
                            )}
                          </Typography>
                        )}

                        {(verificationDocumentFront || verificationDocumentBack) && (
                          <Typography
                            variant="h1"
                            color={'gray.700'}
                            marginBottom={2}
                            className="ssn_provided_text"
                          >
                            {t('reviewCardtexts.idDocProvidedText')}
                            {verificationStatus === 'verified' && (
                              <div className="ssn_provided_text_verified">
                                <AccountVerificationStatusses type={'verified'} />
                              </div>
                            )}

                            {verificationStatus === 'pending' && (
                              <div className="ssn_provided_text_verified">
                                <AccountVerificationStatusses type={'pending'} />
                              </div>
                            )}

                            {verificationStatus !== 'verified' && (
                              <div
                                className="icon_wrapper"
                                style={{ cursor: 'pointer' }}
                                onClick={
                                  verificationStatus === 'pending'
                                    ? handleRefreshForPendingStatus
                                    : handlePersonalInfoEditIcon
                                }
                              >
                                <RefreshIcon />
                              </div>
                            )}
                          </Typography>
                        )}
                      </ReviewCard>
                    </ShadowCard>
                  )}
                  {editFormVisibilityStates.personaInfoEdit && (
                    <FormEditPersonalDetailsSubmition
                      title={t('captions.personalDetails')}
                      onCancelButton={handlePersonalInfoCancelEvent}
                      result={result}
                      onFormSubmit={handlePersonalInfoCancelEvent}
                      isFromFinance={isFromFinance}
                    />
                  )}

                  {isRecipentDetailsAreAvailable && recipientDetailsFormEnabled && (
                    <ShadowCard marginBottom={6} px={4} pt={6} pb={4}>
                      <ReviewCard
                        title={t('captions.recipientInfo')}
                        name={result?.stripe_account_metadata?.check_recipient_details_name ?? ''}
                        isInnerTitle
                        isShadowWrappedIcon
                        hideEdit
                      >
                        {result?.stripe_account_metadata?.check_recipient_details_address && (
                          <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                            {result?.stripe_account_metadata?.check_recipient_details_address ?? ''}
                          </Typography>
                        )}
                        {result?.stripe_account_metadata?.check_recipient_details_address_2 && (
                          <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                            {result?.stripe_account_metadata?.check_recipient_details_address_2 ??
                              ''}
                          </Typography>
                        )}
                        <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                          {RecipentAddressCityStateZip(result)}
                        </Typography>
                      </ReviewCard>
                    </ShadowCard>
                  )}
                </Box>
                <Box>
                  {isVerificationErrorMessageVisible && (
                    <Messages
                      topMargin={4}
                      bottomMargin={2}
                      messageHeading={t('errorMessages.reviewError.heading')}
                      messageContent={t('errorMessages.reviewError.content')}
                      messageLinkText={t('errorMessages.reviewError.linkText')}
                      messageLinkEvent={
                        businessVerificationStatus !== 'verified'
                          ? handleBusinessInfoEditIcon
                          : handlePersonalInfoEditIcon
                      }
                    />
                  )}

                  {errorMessageFA !== '' && (
                    <Messages
                      type="error"
                      messageContent={errorMessageFA}
                      closeEvent={onMessageClose}
                    />
                  )}
                </Box>
              </Box>
              <Box component={'div'} className="container_content_bottom ">
                <FooterNote />
              </Box>
            </Box>
            <ContentContainerBottom className={'no_fullscreen no_note'}>
              <Button
                variant="containedLarge"
                color="primary"
                type="button"
                fullWidth
                onClick={submitForm}
                sx={{ overflow: 'hidden' }}
                disabled={
                  editFormVisibilityStates.personaInfoEdit ||
                  editFormVisibilityStates.businessInfoEdit
                }
              >
                {t('buttonTexts.submitButton')}
              </Button>
            </ContentContainerBottom>
          </DivWrapper>
        </Wrapper>

        {termsModalOpen && (
          <FinancesTermsModal
            onAcceptButtonClick={onAcceptButtonClick}
            closeIdModalEvent={onTermsModalClose}
            t={t}
          />
        )}
      </>
    )
  );
};
