import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { FormInputPhoneNumber } from '../FormInputPhoneNumber/FormInputPhoneNumber';
import { Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';

export const AddMorePhoneNumber = ({
  control,
  register,
  setAddtionalNumbers,
  setValue,
  isSetInputNumber,
  error,
}: any) => {
  const { t } = useTranslation();
  const [additionalNumbers, setAdditionalNumbers]: any = useState([]);

  const handleAddMorePhoneNumber = () => {
    setAdditionalNumbers((additionalNumbers: any) => [
      ...additionalNumbers,
      additionalNumbers.length + 1,
    ]);
    setValue(`phone_number_2`, '');
    setAddtionalNumbers('');
  };

  useEffect(() => {
    if (isSetInputNumber) {
      setAdditionalNumbers((additionalNumbers: any) => [
        ...additionalNumbers,
        additionalNumbers.length + 1,
      ]);
    }
  }, [isSetInputNumber]);

  return (
    <Box component={'div'}>
      <Box component={'div'}>
        {additionalNumbers &&
          additionalNumbers.map((input: any, index: any) => {
            return (
              <Controller
                control={control}
                key={index}
                name={`phone_number_${index + 2}`}
                render={({ field: { value } }) => (
                  <FormInputPhoneNumber
                    label={`${t('formInput.phone')} ${index + 2}`}
                    id={`phone_${index + 2}`}
                    placeholder={'(123) 543-3454'}
                    {...register(`phone_number_${index + 2}`)}
                    inputMode="numeric"
                    value={value}
                    onChange={(value: any) => {
                      setValue(`phone_number_${index + 2}`, value);
                      setAddtionalNumbers(value);
                    }}
                    inputError={error}
                  />
                )}
              />
            );
          })}
      </Box>
      {additionalNumbers?.length <= 0 && (
        <Box
          component={'div'}
          sx={{ display: 'flex', width: '100%', paddingTop: 0, marginBottom: 2 }}
        >
          <Typography
            variant="h2Bold"
            color={'primary'}
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={handleAddMorePhoneNumber}
          >
            <AddIcon
              sx={{ marginRight: 2, height: '18px', width: '18px', color: 'primary.main' }}
            />{' '}
            {t('linkTexts.addMoreNumberLink')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
