import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Box, Button, FormLabel, RadioGroup } from '@mui/material';

import { Modal } from '../Modal/Modal';
import { FormRadioButton } from '../../FormElements/FormRadioButton/FormRadioButton';
import { ManualLoader } from '../../OtherUtilities/Loader/ManualLoader';
import { Messages } from '../../MessageElements/Messages/Messages';
import { FormInput } from '../../FormElements/FormInput/FormInput';

import { ShadowCard } from '../../CommonNestedStyles/FormWrapper.style';

import { BankDetailsParams } from 'src/shared/utilities/interface';
import { ManageCardInfoModalProps } from 'src/models/component/base.model';

import { ERROR_MESSAGES } from 'src/constants/Validation';

import { useCreateTreasuryPayeeAccountMutation } from 'src/services/account/accountServiceApi';

export const AddPayeeAccountModal = ({ closeIdModalEvent, t }: ManageCardInfoModalProps) => {
  const [createPayeeBankAccount, { isLoading: isLoadingBankAccount }] =
    useCreateTreasuryPayeeAccountMutation();

  const [addAccountError, setAddAccountError] = useState('');
  const [accountHolderType, setAccountHolderType] = useState('individual');
  const [accountType, setAccountType] = useState('checking');

  // handle close event __
  const handleCloseEvent = () => {
    closeIdModalEvent && closeIdModalEvent();
  };

  const schema = yup
    .object({
      account_holder_name: yup.string().required(ERROR_MESSAGES.REQUIRED),
      account_number: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .min(5, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID)
        .max(17, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID),
      routing_number: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .min(9, ERROR_MESSAGES.SSN_INVALID_FULL)
        .max(9, ERROR_MESSAGES.SSN_INVALID_FULL),
      account_number_conf: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .min(5, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID)
        .max(17, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID)
        .oneOf([yup.ref('account_number')], ERROR_MESSAGES.ACCOUNTS_NOT_MATCH),
      nickname: yup.string().required(ERROR_MESSAGES.REQUIRED),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<BankDetailsParams>({
    resolver: yupResolver(schema),
  });

  const isValidAccNumConf = () => {
    if (addAccountError !== '') {
      return false;
    }
    if (errors?.account_number_conf?.message) {
      return false;
    }

    const accNum = getValues('account_number');
    const accNumConf = getValues('account_number_conf');
    if (!accNum || !accNumConf) {
      return false;
    }
    if (accNum !== accNumConf) {
      return false;
    }

    return true;
  };

  const isValidAccNum = () => {
    if (addAccountError !== '') {
      return false;
    }
    if (errors?.account_number?.message) {
      return false;
    }

    const accNum = getValues('account_number') ?? '';
    if (accNum.length < 5 || accNum.length > 17) {
      return false;
    }

    return true;
  };

  const updateBindingsChange = (event: any, feild: any) => {
    setValue(feild, event.target.value);
  };

  const handleRadioOnChange = (val: any) => {
    const { name, id } = val.target;
    if (name === 'account_holder_type') {
      setAccountHolderType(id);
    } else if (name === 'account_type') {
      setAccountType(id);
    }
  };

  const appSubmit = handleSubmit(async (data) => {
    const payloadParams = {
      account_holder_name: data.account_holder_name,
      routing_number: data.routing_number,
      account_number: data.account_number,
      account_holder_type: accountHolderType,
      account_type: accountType,
      nickname: data.nickname,
    };

    const response: any = await createPayeeBankAccount(payloadParams);

    if (response && response?.error) {
      // @ts-ignore
      setAddAccountError(
        response?.error?.data?.error ?? response?.error?.data?.message ?? response?.error?.error
      );
      // @ts-ignore
    } else {
      handleCloseEvent();
      setAddAccountError('');
    }
  });

  return (
    <>
      {isLoadingBankAccount && <ManualLoader />}
      <Modal
        onCloseEvent={handleCloseEvent}
        modalTitle={t('finance.MoveMoney.addPayeeAccountModal.modalTitle')}
      >
        <form onSubmit={appSubmit}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxHeight={'calc(100vh - 166px)'}
            sx={{
              overflowY: 'auto',
            }}
            marginBottom={12}
          >
            {addAccountError !== '' && (
              <Messages
                messageHeading={t('headingContent.commonSteps.bankDetails.heading')}
                messageContent={addAccountError}
                closeEvent={() => setAddAccountError('')}
                topMargin={0}
                bottomMargin={4}
              />
            )}
            <ShadowCard paddingX={4} paddingTop={8} paddingBottom={1} marginBottom={6}>
              <Controller
                control={control}
                name="account_holder_name"
                render={({ field: { onChange, value } }) => (
                  <FormInput
                    label={t('formInput.accountName')}
                    id={'accountName'}
                    placeholder={t('placeHolder.accountName')}
                    {...register('account_holder_name')}
                    error={errors?.account_holder_name?.message}
                    onChange={(event: any) => {
                      event.target.value = event.target.value.replace('  ', ' ');
                      updateBindingsChange(event, 'account_holder_name');
                    }}
                    value={value}
                  />
                )}
              />
              <Controller
                control={control}
                name="account_number"
                render={({ field: { onChange, value } }) => (
                  <FormInput
                    label={t('formInput.accountNumber')}
                    id={'accountNumber'}
                    placeholder={'XXXXXXXXX'}
                    {...register('account_number')}
                    isValidationIconVisible
                    inputMode="numeric"
                    onChange={(e: any) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                      onChange(e);
                    }}
                    error={errors?.account_number?.message}
                    isValid={isValidAccNum()}
                    maxLength={17}
                    value={value}
                  />
                )}
              />
              <Controller
                control={control}
                name="account_number_conf"
                render={({ field: { onChange, value } }) => (
                  <FormInput
                    label={t('formInput.confirmAccountNumber')}
                    id={'confirmAccountNumber'}
                    placeholder={'XXXXXXXXX'}
                    {...register('account_number_conf')}
                    onChange={(e: any) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                      onChange(e);
                    }}
                    isValidationIconVisible
                    inputMode="numeric"
                    error={errors?.account_number_conf?.message}
                    isValid={isValidAccNumConf()}
                    maxLength={17}
                    disableCopyPast={true}
                    value={value}
                  />
                )}
              />
              <Controller
                control={control}
                name="routing_number"
                render={({ field: { onChange, value } }) => (
                  <FormInput
                    label={t('formInput.routingNumber')}
                    id={'routingNumber'}
                    placeholder={'XXXXXXXXX'}
                    {...register('routing_number')}
                    inputMode="numeric"
                    error={errors?.routing_number?.message}
                    maxLength={9}
                    onChange={(e: any) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                      onChange(e);
                    }}
                    value={value}
                  />
                )}
              />
              <Controller
                control={control}
                name="nickname"
                render={({ field: { onChange, value } }) => (
                  <FormInput
                    label={t('formInput.nickName')}
                    id={'nickname'}
                    {...register('nickname')}
                    error={errors?.nickname?.message}
                    maxLength={9}
                    onChange={(event: any) => {
                      event.target.value = event.target.value.replace('  ', ' ');
                      updateBindingsChange(event, 'nickname');
                    }}
                    value={value}
                  />
                )}
              />

              <FormLabel id="demo-radio-buttons-group-label">
                {t('formInput.businessType.label')}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                sx={{ marginBottom: 3 }}
              >
                <FormRadioButton
                  label={t('formInput.businessType.option1')}
                  name={'account_holder_type'}
                  value={'individual'}
                  id={'individual'}
                  checked={accountHolderType === 'individual'}
                  handleChange={handleRadioOnChange}
                />
                <FormRadioButton
                  label={t('formInput.businessType.option2')}
                  name={'account_holder_type'}
                  value={'company'}
                  id={'company'}
                  checked={accountHolderType === 'company'}
                  handleChange={handleRadioOnChange}
                />
              </RadioGroup>

              <FormLabel id="demo-radio-buttons-group-label">
                {t('formInput.accountType.label')}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                sx={{ marginBottom: 3 }}
              >
                <FormRadioButton
                  label={t('formInput.accountType.option1')}
                  name={'account_type'}
                  value={'checking'}
                  id={'checking'}
                  checked={accountType === 'checking'}
                  handleChange={handleRadioOnChange}
                />
                <FormRadioButton
                  label={t('formInput.accountType.option2')}
                  name={'account_type'}
                  value={'savings'}
                  id={'savings'}
                  checked={accountType === 'savings'}
                  handleChange={handleRadioOnChange}
                />
              </RadioGroup>
            </ShadowCard>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              position: 'absolute',
              left: '16px',
              right: '16px',
              bottom: 0,
              top: 'auto',
              paddingBottom: 6,
            }}
            component={'div'}
          >
            <Button
              variant="containedLarge"
              color="primary"
              type="submit"
              fullWidth
              sx={{ overflow: 'hidden' }}
            >
              {t('buttonTexts.addButton')}
            </Button>
          </Box>
        </form>
      </Modal>
    </>
  );
};
