import moment from 'moment';

const Dates = {
  Week: 7,
  Months: 30,
  Last90: 90,
  YTD: 500,
  All: 0,
  Last12: 364,
};

export const invoiceAtRange = (dateSelected: any) => {
  dateSelected = dateSelected === 'All' ? '5000' : dateSelected;
  let rangeSelector = dateSelected && parseFloat(dateSelected?.replace(/[^0-9]/g, ''));
  rangeSelector = isNaN(rangeSelector) ? 0 : rangeSelector;
  let dateEnd = moment().format();

  let dateStart = moment().format();
  switch (rangeSelector) {
    // case Dates.Today:
    //   dateStart = moment().startOf('day').format();
    //   dateEnd = moment().endOf('day').format();
    //   break;
    case Dates.Week:
      dateStart = moment().subtract(6, 'day').format();
      break;
    case Dates.Months:
      dateStart = moment().subtract(28, 'day').format();
      break;
    case Dates.Last90:
      dateStart = moment().subtract(89, 'day').format();
      break;
    case Dates.Last12:
      dateStart = moment().subtract(364, 'day').format();
      break;
    case Dates.YTD:
      dateStart = getYearRange().start;
      dateEnd = getYearRange().end;
      break;
    default:
      dateEnd = '';
      dateStart = '';
  }

  const invoicedAt = {
    lessThan: dateEnd,
    greaterThan: dateStart,
  };
  return invoicedAt;
};

export const getQuarterRange = () => {
  const start = moment().quarter(moment().quarter()).startOf('quarter');
  const end = moment().quarter(moment().quarter()).endOf('quarter');
  return { start, end };
};

export const getYearRange = () => {
  //for Year To Date
  const start = moment().startOf('year').format();
  const end = moment().format();
  return { start, end };
};
