import {
  combineReducers,
  configureStore,
  isRejectedWithValue,
  Middleware,
  createAction,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Reducer } from 'redux';

import { reducers } from './reducers';
import { notificationPrefsApi } from '../services/account/notificationServiceApi';
import { referralServiceApi } from '../services/account/referralServiceApi';
import { accountApi } from 'src/services/account/accountServiceApi';
import { customerApi } from 'src/services/customer/customerServiceApi';
import { supportServiceApi } from '../services/account/supportServiceApi';
import { accountPreferenceApi } from 'src/services/account/account.preferenceApi';
import { configApi } from 'src/services/config/configAPI';
import { authApi } from 'src/services/auth/authServiceApi';
import { searchVendorApi } from 'src/services/auth/authServiceApi';
import { nativeHandlerForLogOut, removeFromLocalStorage } from 'src/shared/config/config';
import { batchesApi } from 'src/services/transfers/transfers.service';

export const RESET_STATE_ACTION_TYPE = 'resetState';

export const resetStateAction = createAction(RESET_STATE_ACTION_TYPE, () => {
  // Error checking is unavailable for following routes
  const errorNoNeedToCheck =
    window.location.pathname !== '/' &&
    window.location.pathname !== '/verify-code' &&
    window.location.pathname !== '/verify-login' &&
    window.location.pathname !== '/search-supplier' &&
    window.location.pathname !== '/401' &&
    window.location.pathname !== '/404error';

  if (typeof window !== 'undefined' && errorNoNeedToCheck) {
    removeFromLocalStorage();
    nativeHandlerForLogOut();
    window.location.href = '/401';
  }

  return { payload: null };
});

// set middleware for error handling
export const unauthenticatedMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action) && action.payload.status === 401) {
      dispatch(resetStateAction());
    }

    return next(action);
  };

const combinedReducer = combineReducers<typeof reducers>(reducers);

export const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === RESET_STATE_ACTION_TYPE) {
    state = {} as RootState;
  }

  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      configApi.middleware,
      notificationPrefsApi.middleware,
      referralServiceApi.middleware,
      accountApi.middleware,
      customerApi.middleware,
      supportServiceApi.middleware,
      accountPreferenceApi.middleware,
      authApi.middleware,
      searchVendorApi.middleware,
      batchesApi.middleware,
      unauthenticatedMiddleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducer>;
export const useTypedDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
