import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Header,
  HeaderFixed,
  HeadingSummaryBlock,
  ShareButton,
  WrpperAuthorizedContainer,
  ProgressCard,
  Wrapper,
  ButtonAuthorizedWrapper,
  FormAuthorizedWrapper,
} from 'src/components/base/core';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';
import { routeType } from 'src/helpers/containers/helperRoutePreference';
import {
  useGetReferralLinkQuery,
  useGetAccountReferralRewardQuery,
} from 'src/services/account/referralServiceApi';

type LocationState = {
  fromHome?: boolean;
};

export const SettingsReferealsContainer = ({ isSettingDesktop = false }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const { data, isSuccess } = useGetReferralLinkQuery('');
  const { data: referealData, isSuccess: referalSuccess } = useGetAccountReferralRewardQuery('');
  const [updatePreferenceOnServer] = handlePreferences();

  const handleBackButton = () => {
    if ((location?.state as LocationState)?.fromHome) {
      navigate('/dashboard');
      updatePreferenceOnServer(routeType.HOME);
    } else {
      navigate('/settings');
      updatePreferenceOnServer(routeType.SETTINGS);
    }
  };

  return (
    <>
      {!isSettingDesktop && (
        <HeaderFixed className="fixed_only_desktop">
          <Header
            isMainMunuVisible
            isBackButtonHide
            onBackButtonClick={handleBackButton}
            isBackButtonVisible
            paddingX={0}
            headerText={t('settingsReferals.headerHeading')}
            withCenteredHeaderText
          />
        </HeaderFixed>
      )}

      <Wrapper
        isAuthorizedWrapper
        idSelector={!isSettingDesktop ? 'wrapperContainer' : 'settingDesktopWraper'}
        isSettingDesktop={isSettingDesktop}
      >
        <WrpperAuthorizedContainer>
          <FormAuthorizedWrapper>
            <Box marginBottom={6}>
              <HeadingSummaryBlock
                heading={t('settingsReferals.heading')}
                headingAlignment="left"
              />
            </Box>
            <ProgressCard
              title={t('settingsReferals.progressTitle')}
              overallProgressLabel={t('settingsReferals.overallProgressValue')}
              overallProgressValue={`$${referalSuccess && referealData.amountAvailable}`}
              progressLabel={t('settingsReferals.progressValue')}
              progressValue={`$${referalSuccess && referealData.amountEarned}`}
              totalProgressSteps={referalSuccess && referealData.totalReferred}
              currentProgressStep={referalSuccess && referealData.totalJoined}
              stepsContent={t('settingsReferals.stepsContent')}
              isBorderCardEnabled={isSettingDesktop}
            />
            <Box marginBottom={isSettingDesktop ? 6 : 8}>
              <HeadingSummaryBlock
                content={t('settingsReferals.content')}
                contentAlignment="left"
              />
            </Box>
            {isSuccess && (
              <ButtonAuthorizedWrapper>
                <ShareButton
                  label={t('buttonTexts.ShareLinkText')}
                  text="Start using PayUp to get paid for work faster and on your own terms."
                  title="PayUp"
                  url={data.url}
                  onClick={() => console.log('shared successfully!')}
                />
              </ButtonAuthorizedWrapper>
            )}
          </FormAuthorizedWrapper>
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
