import { styled } from '@mui/material/styles';

export const FaqContent = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-bottom: 12px;

  * {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray[700]};
  }
 `
);

export const AccordianWrapper = styled('div')(
  ({ theme }) => `
 display: flex;
 flex-direction: column;
 width: 100%;

 .accordion__section {
  padding: 0;
 }
`
);
