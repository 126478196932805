import { Box, Button, Typography } from '@mui/material';
import { Modal } from '../Modal/Modal';

import { ShadowCard } from '../../CommonNestedStyles/FormWrapper.style';

import { CardAccountInfoModalProps } from 'src/models/component/base.model';
import { AfterBottomRow } from '../../CommonNestedStyles/CommonNestedStyles.style';

export const CardAccountInfoModal = ({
  closeIdModalEvent,
  cardData,
  t,
}: CardAccountInfoModalProps) => {
  // handle close event __
  const handleCloseEvent = () => {
    closeIdModalEvent && closeIdModalEvent();
  };

  const getFinancialAddressesArray = cardData?.financial_addresses[0]?.aba;

  return (
    <>
      <Modal
        onCloseEvent={handleCloseEvent}
        modalTitle={t('finance.manageCard.cardAccountInfoModal.title')}
      >
        <ShadowCard paddingX={4} paddingY={4} marginBottom={20}>
          <Box display={'flex'} flexDirection={'column'}>
            <AfterBottomRow className="content icon_available">
              <Box className="content_left">
                <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
                  {t('finance.manageCard.cardAccountInfoModal.dataRow1')}
                </Typography>
              </Box>
              <Box className="content_right">
                <Typography
                  variant="h2"
                  component={'p'}
                  color={'gray.500'}
                  fontWeight={300}
                  textTransform={'capitalize'}
                >
                  {getFinancialAddressesArray?.account_number ?? '----'}
                </Typography>
              </Box>
            </AfterBottomRow>
            <AfterBottomRow className="content icon_available">
              <Box className="content_left">
                <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
                  {t('finance.manageCard.cardAccountInfoModal.dataRow2')}
                </Typography>
              </Box>
              <Box className="content_right">
                <Typography variant="h2" component={'p'} color={'gray.500'} fontWeight={300}>
                  {getFinancialAddressesArray?.routing_number ?? '----'}
                </Typography>
              </Box>
            </AfterBottomRow>
            <AfterBottomRow className="content icon_available">
              <Box className="content_left">
                <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
                  {t('finance.manageCard.cardAccountInfoModal.dataRow3')}
                </Typography>
              </Box>
              <Box className="content_right">
                <Typography variant="h2" component={'p'} color={'gray.500'} fontWeight={300}>
                  {getFinancialAddressesArray?.account_holder_name ?? '----'}
                </Typography>
              </Box>
            </AfterBottomRow>
          </Box>
        </ShadowCard>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 3,
            flexDirection: 'column',
            position: 'absolute',
            left: '16px',
            right: '16px',
            bottom: 0,
            top: 'auto',
            paddingBottom: 6,
          }}
          component={'div'}
        >
          <Button
            variant="containedLarge"
            color="primary"
            type="button"
            fullWidth
            onClick={handleCloseEvent}
            sx={{ overflow: 'hidden' }}
          >
            {t('buttonTexts.doneButton')}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
