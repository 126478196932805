import { useNavigate } from 'react-router-dom';
import { handlePayoutDate } from '../../../../../../../../helpers/helperHandlingTableGrid';

export const SecondCell = (props: any) => {
  const navigate = useNavigate();
  const getPropsOriginalData = props?.props?.row?.original;

  const isTransactionGridType = props?.props?.isTransactionGrid ? true : false;
  const date = handlePayoutDate(getPropsOriginalData, props?.props?.tabType, isTransactionGridType);

  const { id } = getPropsOriginalData;

  return (
    <>
      <span
        className="hidden-mobile date_val"
        onClick={() =>
          isTransactionGridType
            ? navigate(`/batch/${getPropsOriginalData.id}`)
            : navigate(`/payout/${getPropsOriginalData.id}`, {
                state: { tabType: props?.props?.tabType, payout_id: id },
              })
        }
      >
        {date}
      </span>
    </>
  );
};
