import * as yup from 'yup';
import { ERROR_MESSAGES } from '../constants/Validation';

/*individual reviewinfo*/
export const revieInfoSchemaObj = {
  first_name: yup
    .string()
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED)
    .trim()
    .min(2, ERROR_MESSAGES.INVALID_FIRST_NAME)
    .matches(/[A-Za-z 0-9]/g, ERROR_MESSAGES.INVALID_FIRST_NAME),
  last_name: yup
    .string()
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED)
    .trim()
    .min(2, ERROR_MESSAGES.INVALID_LAST_NAME)
    .matches(/[A-Za-z 0-9]/g, ERROR_MESSAGES.INVALID_LAST_NAME),
  // display_name: yup
  //   .string()
  //   .typeError(ERROR_MESSAGES.REQUIRED)
  //   .required(ERROR_MESSAGES.REQUIRED)
  //   .trim()
  //   .min(2, ERROR_MESSAGES.INVALID_LAST_NAME)
  //   .matches(/[A-Za-z 0-9]/g, ERROR_MESSAGES.INVALID_LAST_NAME),
  // primary_contact_name: yup
  //   .string()
  //   .typeError(ERROR_MESSAGES.REQUIRED)
  //   .required(ERROR_MESSAGES.REQUIRED)
  //   .trim()
  //   .min(2, ERROR_MESSAGES.INVALID_LAST_NAME)
  //   .matches(/[A-Za-z 0-9]/g, ERROR_MESSAGES.INVALID_LAST_NAME),
  email: yup
    .string()
    .email(ERROR_MESSAGES.EMAIL_INVALID)
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED),
  phone_number: yup.string().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
};

/*individual reviewinfo*/
export const searchVendorSchemaObj = {
  primary_contact_name: yup
    .string()
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED)
    .trim()
    .min(2, ERROR_MESSAGES.INVALID_LAST_NAME)
    .matches(/[A-Za-z 0-9]/g, ERROR_MESSAGES.INVALID_LAST_NAME),
  // display_name: yup
  //   .string()
  //   .typeError(ERROR_MESSAGES.REQUIRED)
  //   .required(ERROR_MESSAGES.REQUIRED)
  //   .trim()
  //   .min(2, ERROR_MESSAGES.INVALID_LAST_NAME)
  //   .matches(/[A-Za-z 0-9]/g, ERROR_MESSAGES.INVALID_LAST_NAME),
  email: yup
    .string()
    .email(ERROR_MESSAGES.EMAIL_INVALID)
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED),
  phone_number: yup.string().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
};

/*individual IdentityVerification*/
export const identityVerificationSchemaObj = {
  line1: yup.string().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
  city: yup.string().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
  postal_code: yup
    .string()
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED)
    .max(5, ERROR_MESSAGES.ZIPCODE_INVALID)
    .min(5, ERROR_MESSAGES.ZIPCODE_INVALID),
  state: yup.string().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
};

/* company businessinfo */
export const businessInfoSchemaObj = {
  inputCompanyName: yup
    .string()
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED),
  inputComLine1: yup.string().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
  inputComCity: yup.string().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
  inputComPostal: yup
    .string()
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED)
    .max(5, ERROR_MESSAGES.ZIPCODE_INVALID)
    .min(5, ERROR_MESSAGES.ZIPCODE_INVALID),
  inputCompanyPhone: yup.string().required(ERROR_MESSAGES.REQUIRED),
};

export const businessRepOwnershipSchemaObj = {
  first_name: yup
    .string()
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED)
    .trim()
    .min(2, ERROR_MESSAGES.INVALID_FIRST_NAME)
    .matches(/[A-Za-z 0-9]/g, ERROR_MESSAGES.INVALID_FIRST_NAME),
  last_name: yup
    .string()
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED)
    .trim()
    .min(2, ERROR_MESSAGES.INVALID_LAST_NAME)
    .matches(/[A-Za-z 0-9]/g, ERROR_MESSAGES.INVALID_LAST_NAME),
  email: yup
    .string()
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED)
    .email(ERROR_MESSAGES.EMAIL_INVALID),
  phone_number: yup
    .string()
    .required(ERROR_MESSAGES.REQUIRED)
    .min(14, ERROR_MESSAGES.PHONE_NUMBER_INVALID),
};

export const businessVerifyOwnershipSchemaObj = {
  first_name: yup
    .string()
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED)
    .trim()
    .min(2, ERROR_MESSAGES.INVALID_FIRST_NAME)
    .matches(/[A-Za-z 0-9]/g, ERROR_MESSAGES.INVALID_FIRST_NAME),
  last_name: yup
    .string()
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED)
    .trim()
    .min(2, ERROR_MESSAGES.INVALID_LAST_NAME)
    .matches(/[A-Za-z 0-9]/g, ERROR_MESSAGES.INVALID_LAST_NAME),
  email: yup
    .string()
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED)
    .email(ERROR_MESSAGES.EMAIL_INVALID),
  phone_number: yup
    .string()
    .required(ERROR_MESSAGES.REQUIRED)
    .min(14, ERROR_MESSAGES.PHONE_NUMBER_INVALID),
};

/*individual IdentityVerification*/
export const inviteUserSchemaObj = {
  companyName: yup.string().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
  city: yup.string().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
  email: yup.string().email(ERROR_MESSAGES.EMAIL_INVALID).nullable().notRequired(),
  // phone_number: yup
  //   .string()
  //   .nullable()
  //   .notRequired().min(14, ERROR_MESSAGES.PHONE_NUMBER_INVALID),
};

export const addPayeeAccountSchemaObj = {
  city: yup.string().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
  postal_code: yup
    .string()
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED)
    .max(5, ERROR_MESSAGES.ZIPCODE_INVALID)
    .min(5, ERROR_MESSAGES.ZIPCODE_INVALID),
  state: yup.string().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
  email: yup
    .string()
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED)
    .email(ERROR_MESSAGES.EMAIL_INVALID),
  phone: yup
    .string()
    .required(ERROR_MESSAGES.REQUIRED)
    .min(14, ERROR_MESSAGES.PHONE_NUMBER_INVALID),
};
