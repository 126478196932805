import { useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { AuthResponse } from '../../../models/login.model';

import { FormInputPhoneNumber } from 'src/components/base/core/FormElements/FormInputPhoneNumber/FormInputPhoneNumber';

import { Box, Button } from '@mui/material';
import {
  Header,
  Wrapper,
  HeadingSummaryBlock,
  FormWrapper,
  ContentContainerBottom,
} from 'src/components/base/core';
import { handleVerifyPhone } from 'src/helpers/helperVerifyPhone';
import { commonSpacing, theme } from 'src/shared/theme/theme';
import { useGetBlockHeight } from 'src/hooks/useGetBlockHeight';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';

interface AccountVerifyPhoneProps {
  verificationData?: (data: AuthResponse) => void;
}

type LocationState = {
  isLogin?: boolean;
};

export const AccountVerifyPhoneContainer = ({ verificationData }: AccountVerifyPhoneProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [elementRef, height] = useGetBlockHeight();

  const { isLogin } =
    location?.state != null ? (location?.state as LocationState) : { isLogin: null };
  const loginState = useMemo(() => isLogin, []);
  /**
   * TODO
   * need to remove yap validation because no point when button disable
   */
  const AccountLoginSchema = yup.object().shape({
    phone: yup.string().required('Please enter a full 10 digit phone number'),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(AccountLoginSchema) });

  const [buttonDisable, setButtonDisable] = useState(true);

  const [handleVerificationCode, errorState, onChangeHandler, isLoading] = handleVerifyPhone();

  // @ts-ignore
  const appLogin = handleSubmit(async (data) => {
    // @ts-ignore
    handleVerificationCode(data, getValues, navigate);
  });

  const handleMaskInputChange = (value: any) => {
    // @ts-ignore
    onChangeHandler(value, getValues, setValue, setButtonDisable);
  };

  const handleButtonLabel = () => {
    let loginText = '';
    if (loginState) {
      loginText = t('buttonTexts.buttonTextSignIn');
    }
    if (!loginState) {
      loginText = t('buttonTexts.buttonTextSignup');
    }

    return loginText;
  };

  return (
    <>
      {isLoading && <ManualLoader />}
      <Wrapper>
        <FormWrapper className={'no_fullscreen'} onSubmit={appLogin}>
          <Box component={'div'} className={'container_content'}>
            <Box
              component={'div'}
              className={'container_content_top'}
              ref={elementRef}
              sx={{
                [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                  minHeight: height,
                  paddingBottom: height ? 0 : commonSpacing.desktopPaddingB,
                },
              }}
            >
              <Header
                isBackButtonVisible
                onBackButtonClick={() => navigate('/', { state: { isBack: true } })}
                paddingX={0}
              />
              <Box sx={{ marginBottom: 6 }}>
                <HeadingSummaryBlock
                  heading={t('headingContent.verifyPhone.heading')}
                  headingAlignment="left"
                  content={t('headingContent.verifyPhone.content')}
                  contentAlignment="left"
                />
              </Box>

              <Box component="div">
                <FormInputPhoneNumber
                  label={t('formInput.phone')}
                  id={'phone'}
                  placeholder={'(000) 000-0000'}
                  {...register('phone')}
                  onChange={handleMaskInputChange}
                  inputMode="numeric"
                  inputError={errors?.phone?.message || errorState}
                  autoComplete="off"
                />
              </Box>
            </Box>
          </Box>
          <ContentContainerBottom className={'no_fullscreen'}>
            <Button
              variant="containedLarge"
              color="primary"
              type="submit"
              fullWidth
              sx={{ overflow: 'hidden' }}
              disabled={buttonDisable}
            >
              {handleButtonLabel()}
            </Button>
          </ContentContainerBottom>
        </FormWrapper>
      </Wrapper>
    </>
  );
};
