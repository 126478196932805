import { useEffect, useState } from 'react';
import { pre_fill_params } from 'src/shared/content/content';
import {
  accountIndividual,
  accountIndividualInitMapper,
} from 'src/models/account/account.service.mapper';
import {
  useCreateAccountMutation,
  useDeleteAccountMutation,
  useGetAccountQuery,
  useUpdateAccountMutation,
} from 'src/services/account/accountServiceApi';
import { SearchCoupaSupplierByEmail } from '../../services/coupa/coupa.service';
import { setPrimaryContacts } from '../../shared/utilities/cooupaObject';
import { handlePreferences } from './helperPreferences';
import { routeType } from './helperRoutePreference';
export const handleReviewInfo = () => {
  const [createAccount] = useCreateAccountMutation();
  const [updateAccount] = useUpdateAccountMutation();
  const [deleteAccount] = useDeleteAccountMutation();
  const [serverSideErrors, setServerSideErrors] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [accountData, setAccountData]: any = useState();
  const { data, isFetching } = useGetAccountQuery(
    { count: 5 },
    { refetchOnMountOrArgChange: true }
  );

  const [updatePreferenceOnServer] = handlePreferences();

  useEffect(() => {
    if (data) {
      setAccountData(data);
    }
  }, [data]);

  const getCoupaSupplyerByEmail = async (getValues: any) => {
    let response =
      getValues('email') &&
      getValues('email') !== undefined &&
      (await SearchCoupaSupplierByEmail(getValues('email')));
    if (response?.data?.length > 0) {
      setPrimaryContacts(
        response?.data[0]['primary-contact'],
        response?.data[0]['primary-address'],
        response?.data[0]?.id,
        null,
        null,
        null,
        null,
        null
      );
    }
  };

  /**
   * handle redirection with the logic
   */
  const handleRedirection = (isBack: any, edit: any, navigate: any) => {
    localStorage.setItem('step', '2');
    if (isBack) {
      localStorage.removeItem('enable_account_edit');
    } else {
      edit && localStorage.setItem('enable_account_edit', 'true');
    }
    navigate('/identity-verification', {
      state: { edit: edit },
    });
  };

  const onChangeHandlerPhone = (val: any, getValues: any, setValue: any, btnSubmitted: any) => {
    setValue('phone_number', val, { shouldValidate: btnSubmitted && true }); // Set value
  };

  const getPhoneNumberList = (number: string) => {
    const initArr = [];
    initArr.push(number);
    return initArr;
  };

  const handleBackButton = (navigate: any) =>
    navigate('/sign-up', {
      state: { type: 'individual' },
    });

  const getMaxId = (multipleInputValues: any) => {
    const maxId =
      multipleInputValues?.length !== 0
        ? Math.max(
            ...multipleInputValues.map((item: any) => {
              return item?.id?.replace('phoneInput-', '');
            })
          )
        : 0;
    return maxId;
  };

  const additionalInputValidation = (multipleInputValues: any, detailSubmitted: any) => {
    return (
      multipleInputValues.length > 0 &&
      multipleInputValues[0]?.value?.replace(/[^0-9]/g, '').length !== 10 &&
      detailSubmitted
    );
  };

  const submitInfo = async (
    data: any,
    setBtnSubmitted: any,
    coupaId: any,
    navigate: any,
    accountEditEnable: any,
    isChangeBusinessType: boolean,
    backFrom: string,
    setVerifyCodeContainerVisible?: any
  ) => {
    try {
      setBtnSubmitted(true);
      setIsLoading(true);
      setServerSideErrors('');
      const accountDataTemp = accountData;
      if (isChangeBusinessType) {
        await deleteAccount(null);
      }
      if (accountDataTemp && !isChangeBusinessType) {
        delete data.id;
        if (accountData?.individual?.dob?.day === null) {
          delete data.dob;
        }
        //  delete data.dob;
        const updateFormData = {
          ...data,
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          business_type: 'individual',
          phone_number: data?.phone_number?.replace(/[^0-9]/g, ''),
          inputDescription: pre_fill_params.description,
          inputMCC: pre_fill_params.mcc,
          url: pre_fill_params.url,
        };
        const dataModel = accountIndividual(updateFormData, false, false);
        const accountUpdateResponse = await updateAccount(dataModel);
        // @ts-ignore
        if (accountUpdateResponse?.data) {
          if (accountEditEnable) {
            !(backFrom === 'account' || backFrom === 'review') &&
              updatePreferenceOnServer(routeType.IDENTITY_VERIFICATION, 'step1');
            navigate('/identity-verification', {
              state: {
                isCompany: false,
                isBack: true,
                pageFromBack: 'edit_review',
                backFrom: backFrom,
              },
            });
          } else {
            navigate('/identity-verification', {
              state: { pageFromBack: 'info' },
            });
          }
          // @ts-ignore
        } else {
          // @ts-ignore
          const responseError = accountUpdateResponse?.error?.data?.message;
          if (responseError && typeof responseError === 'string') {
            setServerSideErrors(responseError);
            setVerifyCodeContainerVisible && setVerifyCodeContainerVisible(false);
          }
          setIsLoading(false);
        }
      } else {
        const createAccObj = {
          first_name: data.first_name,
          last_name: data.last_name,
          phone_number: data?.phone_number?.replace(/[^0-9]/g, ''),
          email: data.email,
          business_type: 'individual',
          coupa_supplier_id: coupaId,
        };

        //account init data mapping
        const obj = accountIndividualInitMapper({ ...createAccObj, dob: '' });

        const accountResponse = await createAccount(obj);
        // @ts-ignore
        if (accountResponse?.data) {
          updatePreferenceOnServer(routeType.IDENTITY_VERIFICATION, 'step1');
          navigate('/identity-verification');

          // @ts-ignore
        } else {
          // @ts-ignore
          const responseError = accountResponse?.error?.data?.message;
          if (responseError && typeof responseError === 'string') {
            setServerSideErrors(responseError);
            setVerifyCodeContainerVisible && setVerifyCodeContainerVisible(false);
          }
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return [
    getCoupaSupplyerByEmail,
    accountData,
    handleRedirection,
    onChangeHandlerPhone,
    handleBackButton,
    getMaxId,
    additionalInputValidation,
    submitInfo,
    serverSideErrors,
    setServerSideErrors,
    isLoading,
  ];
};
