import { useState } from 'react';

import { MoveMoneyIcon } from '../../Media/Icon/Icons/Icons';
import { MainPayupCardWrapper, MainPayupCardContainer } from './MainPayupCard.style';
import { Box, Button, Typography } from '@mui/material';
import { MainBalanceSummary } from './BalanceSummary';
import { useGetVertualCardDetailsQuery } from 'src/services/account/accountServiceApi';

export interface MainPayupCardProps {
  title: string;
  navigate?: any;
  tdData?: any;
  isSuccessTD?: boolean;
  buttonTexts?: {
    moveMoney?: string;
    manageCard?: string;
  };
  cardTexts?: {
    balanceSummaryTitle?: string;
    cardShowNumberText?: string;
  };
}

export const MainPayupCard = ({
  title = '',
  navigate,
  tdData,
  isSuccessTD,
  buttonTexts,
  cardTexts,
}: MainPayupCardProps) => {
  const [cardMovingEnabled, setCardMovingEnabled] = useState(false);
  const { data: cardData, isSuccess: isSuccessCardData } = useGetVertualCardDetailsQuery(true);

  return (
    <>
      <MainPayupCardWrapper>
        <MainPayupCardContainer>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              cursor: 'pointer',
              width: '100%',
            }}
          >
            <Typography
              component="p"
              variant="h2ExtraBold"
              textTransform={'uppercase'}
              sx={{
                marginBottom: 5,
                display: 'inline-flex',
                alignItems: 'center',
                color: 'gray.500',
              }}
            >
              {title}
            </Typography>
          </Box>
          {isSuccessTD && (
            <MainBalanceSummary
              currentBalance={tdData?.balance?.cash?.usd / 100}
              balanceSummaryTitle={cardTexts?.balanceSummaryTitle}
            />
          )}

          <Box display={'flex'} justifyContent={'center'} width={'100%'} marginBottom={2}>
            <Button
              variant="outlined"
              color="primary"
              type="button"
              startIcon={<MoveMoneyIcon />}
              sx={{
                height: '32px',
                borderRadius: '60px',
                minWidth: '145px',
                marginX: 1,
                fontSize: '12px',
                lineHeight: 1,
              }}
              onClick={() => navigate('/finance/account/move-money')}
            >
              {buttonTexts?.moveMoney}
            </Button>
          </Box>
        </MainPayupCardContainer>
      </MainPayupCardWrapper>
    </>
  );
};
