import { Box, Grid, Typography } from '@mui/material';
import {
  CustomerIcon,
  DolarCurcleIcon,
  ReferalIcon,
  NotificationIcon,
  PersonIcon,
  WorldIcon,
  LogoutIcon,
  BankSetupIcon,
  LimitsIcon,
  AddToAppleWalletIcon,
  OrderPhysicalCardIcon,
  SetUpPinCodeIcon,
  FreezeCardcon,
  CancelAccountIcon,
  UserIcon,
} from '../../../Media/Icon/Icons/Icons';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { IconWrapper, InformationListItemBlockItem, ArrowLeft } from './InformationListItem.style';

import { theme } from 'src/shared/theme/theme';
import { SettingsItemProps } from 'src/models/component/base.model';

export const InformationListItem = ({
  title,
  onClick,
  data,
  icon,
  buttonEnable,
  color,
  buttonText,
  id,
  subTitle,
}: SettingsItemProps) => {
  const cardIcon = () => {
    switch (icon) {
      case 'PersonIcon':
        return <PersonIcon />;
      case 'DolarCurcleIcon':
        return <DolarCurcleIcon />;
      case 'GiftIcon':
        return <ReferalIcon />;
      case 'bankIcon':
        return <BankSetupIcon />;
      case 'NotificationIcon':
        return <NotificationIcon />;
      case 'CustomerIcon':
        return <CustomerIcon />;
      case 'WorldIcon':
        return <WorldIcon />;
      case 'LogoutIcon':
        return <LogoutIcon />;
      case 'LimitsIcon':
        return <LimitsIcon />;
      case 'AccountDetailsIcon':
        return (
          <UserIcon height={22} strokeColor={theme.palette.primary.main} strokeWidth={'1.5'} />
        );
      case 'AddToAppleWalletIcon':
        return <AddToAppleWalletIcon />;
      case 'OrderPhysicalCardIcon':
        return <OrderPhysicalCardIcon />;
      case 'SetUpPinCodeIcon':
        return <SetUpPinCodeIcon />;
      case 'FreezeCardcon':
        return <FreezeCardcon />;
      case 'CancelAccountIcon':
        return <CancelAccountIcon />;
      default:
        break;
    }
  };

  const actionButton = () => {
    let bColor = '';
    let fColor = '';
    switch (color) {
      case 'green.500':
        bColor = 'green.75';
        fColor = 'green.500';
        break;
      case 'red.500':
        bColor = 'red.75';
        fColor = 'red.500';
        break;
      case 'darkBlue.300':
        bColor = 'darkBlue.75';
        fColor = 'darkBlue.300';
        break;
      case 'gray-blue':
        bColor = 'blue.50';
        fColor = 'primary.main';
        break;
      default:
        break;
    }

    return (
      <Box
        sx={{ display: 'flex', alignItems: 'end', justifyContent: 'end' }}
        onClick={() => onClick(id)}
      >
        <Typography
          component="span"
          variant="h1"
          sx={{
            backgroundColor: bColor,
            padding: '3px 6px',
            borderRadius: 1,
          }}
          color={fColor}
        >
          {buttonText}
        </Typography>
      </Box>
    );
  };

  const isLogOut = id === 'LogOut';

  return (
    <InformationListItemBlockItem
      className={isLogOut ? '' : 'bottom_border'}
      onClick={() => onClick(id)}
    >
      <Grid container spacing={2}>
        <Grid item xs={1} sx={{ display: 'inline-flex', justifyContent: 'center' }}>
          <IconWrapper color="gray.500">{cardIcon()}</IconWrapper>
        </Grid>
        <Grid item xs={buttonEnable ? 6 : 10}>
          <Typography variant="h2ExtraBold" color={isLogOut ? 'primary.main' : 'gray.800'}>
            {title}
          </Typography>
          {subTitle && (
            <Typography variant="h1" color={'gray.600'} fontWeight={400}>
              {subTitle}
            </Typography>
          )}
        </Grid>
        {buttonEnable && (
          <Grid item xs={buttonEnable ? 4 : 0} sx={{ marginTop: '2px' }}>
            {actionButton()}
          </Grid>
        )}
        <Grid item xs={1}>
          {!isLogOut && (
            <ArrowLeft>
              <div className="icon_arrow">
                <KeyboardArrowRightIcon />
              </div>
            </ArrowLeft>
          )}
        </Grid>
      </Grid>
    </InformationListItemBlockItem>
  );
};
