import React from 'react';

import { Icon } from '../..';
import { Typography } from '@mui/material';

import { TermsTextWrapper, TermsTextRight } from './TermsText.style';
import { TermsTextProps } from 'src/models/component/base.model';

export const TermsText = ({ mainContent, subContent, marginIndex = '0' }: TermsTextProps) => {
  return (
    <TermsTextWrapper style={{ margin: marginIndex }}>
      <Icon iconType="TermsIcon" />
      <TermsTextRight>
        <Typography variant="h1Bold" color={'gray.800'} sx={{ marginBottom: 1 }}>
          {mainContent}
        </Typography>
        <Typography variant="h1" color={'gray.800'}>
          {subContent}
        </Typography>
      </TermsTextRight>
    </TermsTextWrapper>
  );
};
