import { styled } from '@mui/material/styles';

export const SearchBarWrapper = styled('div')(
  ({ theme }) => `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    margin-bottom: 20px;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin-bottom: 16px;
  }
`
);

export const SearchInputWrapper = styled('div')(
  ({ theme }) => `
  width: calc(100% - 60px);
  position: relative;

  &.no_download {
    width: calc(100% - 30px);
  }

  .close_icon {
    cursor: pointer;
  }

  input,
  input:focus,
  input:hover {
    width: 100%;
    height: 32px ;
    padding: 8px 16px 8px 36px;
    background: transparent;
    border-radius: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    border: none;
    color: ${theme.palette.gray[800]};
    border: 1px solid ${theme.palette.gray[200]};

    &::-webkit-input-placeholder {
      /* Edge */
      color: ${theme.palette.gray[500]};
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${theme.palette.gray[500]};
    }

    &::placeholder {
      color: ${theme.palette.gray[500]};
    }
  }

  svg {
    position: absolute;
    left: 18px;
    top: 10px;
    opacity: 0.5;
    fill: ${theme.palette.gray[600]};
  }
`
);

export const FilterButton = styled('div')(
  ({ theme }) => `
  width: 42px;
  height: 31px;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.palette.primary.main};
  border-radius: 8px 0 0 8px;

  &.enable_full_radius {
    border-radius: 8px;
  }

  &.enable_half_radius {
    border-radius: 8px 0 0 8px;
  }

  svg {
    color: ${theme.palette.primary.main};
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    margin-left: ${theme.spacing(2)};
  }
  
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin-left: ${theme.spacing(2)};
  }
`
);

export const DownloadButton = styled('div')(
  ({ theme }) => `
  width: 42px;
  height: 31px;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.palette.primary.main};

  &.enable_full_radius {
    border-radius: 8px;
  }

  &.enable_half_radius {
    border-radius: 0 8px 8px 0;
  }

  svg {
    color: ${theme.palette.primary.main};
  }
`
);

export const FilterCounter = styled('div')(
  ({ theme }) => `
  width: 12px;
  height: 12px;
  background: ${theme.palette.primary.main};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 8px;
  line-height: 11px;
  color: #ffffff;
  position: absolute;
  top: -4px;
  right: -6px;
`
);

export const FilterRowIndicator = styled('div')(
  ({ theme }) => `
  width: 12px;
  height: 12px;
  background: ${theme.palette.primary.main};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 8px;
  line-height: 11px;
  color: #ffffff;
  position: relative;
  right: -4px;
`
);
