import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material/styles';

import App from './App';
import { GlobalStyle } from './shared/styles/globalStyles';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import { theme } from './shared/theme/theme';

// eslint-disable-next-line unused-imports/no-unused-imports-ts
import i18n from './locale/i18n';
import { I18nextProvider } from 'react-i18next';
import moment from 'moment-timezone';

moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LD_KEY ?? '61c35a35d8fdf20ec09592ac',
    user: { key: 'anon-user', anonymous: true },
  });

  root.render(
    <HelmetProvider>
      <GlobalStyle />
      <LDProvider>
        <ThemeProvider theme={theme}>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </ThemeProvider>
      </LDProvider>
    </HelmetProvider>
  );
})();
