import React from 'react';
import { LoaderWrapper } from './Loader.style';

import { CircularProgress } from '@mui/material';

export const ManualLoader = ({ isLoaderEnable = true }) => {
  return (
    <LoaderWrapper>
      {isLoaderEnable && <CircularProgress color="primary" size={'60px'} />}
    </LoaderWrapper>
  );
};
