import React from 'react';
import { AnchorLink, Wrapper } from 'src/components/base/core';
import { Box, Typography } from '@mui/material';

export interface ErrorContainerProps {
  errorType?: 404 | 500 | 401;
  errorText?: string;
}

export const ErrorContainer = ({ errorType, errorText }: ErrorContainerProps) => {
  const goBack = () => {
    window.history.go(-2);
  };

  return (
    <Wrapper>
      <Box paddingTop={10} sx={{ textAlign: 'center' }}>
        {/***/}
        <Typography
          variant="h8Bold"
          component={'p'}
          color={'gray.900'}
          textAlign={'center'}
          marginBottom={7.5}
        >
          {errorType}
        </Typography>
        <Typography
          variant="h2"
          component={'p'}
          color={'gray.900'}
          textAlign={'center'}
          marginBottom={12}
        >
          {errorText}
        </Typography>
        <span onClick={goBack}>
          <AnchorLink urlPath="/">Back</AnchorLink>
        </span>
      </Box>
    </Wrapper>
  );
};
