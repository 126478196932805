import Register from '../../models/account/account.register.model';
import axios from 'axios';
import config from '../config';
import { configs } from '../../shared/config/config';
export const RegisterAccount = (data: any) => {
  config(false);
  return axios
    .post(`/account`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return JSON.parse(error.request.response);
    });
};

export const UpdateAccount = async (data: Register) => {
  config(false);
  return await axios
    .post(`/account`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return JSON.parse(error.request.response);
    });
};

export const UpdateFile = async (data: any) => {
  config(false);
  return await axios
    .post(`/account/upload-stripe-documents`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return JSON.parse(error.request.response);
    });
};

export const GetDocumentTypes = async () => {
  config(false);
  return await axios
    .get(`/account/get-stripe-document-types/all`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return JSON.parse(error.request.response);
    });
};

export const GenerateAccountLink = async (id: string) => {
  config(false);
  try {
    return await axios.post(`/account/link/${id}`, {
      use_local_app_urls: configs.USE_APP_LOCAL_URL,
    });
  } catch (e: any) {
    return JSON.parse(e?.request?.response);
  }
};

export const RegisterCompanyAccount = (data: any) => {
  config(false);
  return axios
    .post(`/account`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return JSON.parse(error.request.response);
    });
};

export const RedirectToAccount = (url: Location) => {
  config(false);
  window.location = url;
};

export const GetAccount = async (hideLoader?: boolean, isNonLoggedIn?: boolean) => {
  config(hideLoader, isNonLoggedIn);
  return axios
    .get(`/account`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return JSON.parse(error?.request?.response);
    });
};

export const GetTerms = async () => {
  config(false);
  return axios
    .get(`/account/payout-terms`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return JSON.parse(error.request.response);
    });
};

export const GetPayModes = async () => {
  config(false);
  return axios
    .get(`/account/payment-modes/all`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return JSON.parse(error.request.response);
    });
};

export const GetAccountLink = async (linkType: string) => {
  config(true);
  return axios
    .post(`/account/link`, {
      use_local_app_urls: configs.USE_APP_LOCAL_URL,
      link_type: linkType,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return JSON.parse(error.request.response);
    });
};

export const CreateBank = (data: any, id: string) => {
  config(false);
  return axios
    .post(`/account/external-account`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return JSON.parse(error.request.response);
    });
};

export const GetBankDetails = async (id: string) => {
  config(true);
  return axios
    .get(`account/external-account/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return JSON.parse(error.request.response);
    });
};

export const GetAllTerms = async () => {
  config(false);
  return axios
    .post(`/query/payment-terms`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return JSON.parse(error.request.response);
    });
};

export const establishFinancialConnectionSession = async () => {
  config(false);
  return axios
    .post(`/financial-connection/session`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return JSON.parse(error.request.response);
    });
};

export const linkExternalAccount = async (data: any) => {
  config(false);
  return axios.post(`/account/external-account/link`, data).then((response) => {
    return response;
  });
};
