import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Wrapper,
  DivWrapper,
  ContentContainerBottom,
  HeadingSummaryBlock,
  Header,
} from 'src/components/base/core';
import { NotificationModal } from './NotificationModal/NotificationModal';
import { Box, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Images } from 'src/shared/utilities/images';
import { commonSpacing, theme } from 'src/shared/theme/theme';
import { useGetBlockHeight } from 'src/hooks/useGetBlockHeight';
import { routeType } from 'src/helpers/containers/helperRoutePreference';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';
import { removeFriendReferrals } from 'src/constants/constants';

export const NotificationsContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [elementRef, height] = useGetBlockHeight();
  const { t } = useTranslation();
  const [updatePreferenceOnServer] = handlePreferences();
  const elmentWrapper: any = document.getElementById('wrapperContainer');

  const { isFromPayModeKyc } =
    location?.state != null
      ? (location?.state as { isFromPayModeKyc: boolean })
      : {
          isFromPayModeKyc: false,
        };

  const handleModalOpen = () => {
    setOpenModal(true);
    elmentWrapper.style.overflowY = 'hidden';
  };

  const handleModalClose = () => {
    setOpenModal(false);
    handleSkipButtonClick();
    elmentWrapper.style.overflowY = 'hidden';
  };

  const handleBackButton = () => {
    if (isFromPayModeKyc) {
      navigate('/paymode-selection-success');
    } else {
      updatePreferenceOnServer(routeType.SIGNUP_SUCCESS);
      navigate('/signup-success');
    }
  };

  const handleSkipButtonClick = () => {
    if (removeFriendReferrals) {
      updatePreferenceOnServer(routeType.OPEN_ROUTE, null, 'open', '/dashboard');
      navigate('/dashboard');
    } else {
      updatePreferenceOnServer(routeType.REFER_A_FRIEND);
      navigate('/refer-a-friend');
    }
  };

  return (
    <Wrapper>
      <DivWrapper className={'no_fullscreen'}>
        <Box component={'div'} className={'container_content'}>
          <Box
            component={'div'}
            className={'container_content_top'}
            ref={elementRef}
            sx={{
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                minHeight: height,
                paddingBottom: height ? 0 : commonSpacing.desktopPaddingB,
              },
            }}
          >
            <Header
              isBackButtonVisible={true}
              onBackButtonClick={handleBackButton}
              paddingX={0}
              isSkipButtonVisible={true}
              onSkipButtonClick={handleSkipButtonClick}
            />
            <div className="status_image">
              <img src={Images.NOTIFICATIONS} alt="Status" height={200} />
            </div>
            <div className="content_block">
              <HeadingSummaryBlock
                heading={t('headingContent.commonSteps.notification.heading')}
                headingAlignment="center"
                content={t('headingContent.commonSteps.notification.content')}
                contentAlignment="center"
              />
            </div>
          </Box>
        </Box>
        <ContentContainerBottom className={'no_fullscreen'}>
          <Button
            variant="containedLarge"
            color="primary"
            type="button"
            fullWidth
            sx={{ overflow: 'hidden' }}
            onClick={handleModalOpen}
          >
            {t('buttonTexts.enableNotificationButton')}
          </Button>
        </ContentContainerBottom>
      </DivWrapper>
      {openModal && <NotificationModal onCloseEvent={handleModalClose} />}
    </Wrapper>
  );
};
