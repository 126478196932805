import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { BusinessReviewContainer, BusinessLetsReviewContainer } from '../../../../../containers';

export const BusinessLetsReview = () => {
  const { reduceTheBusinessFlowFields } = useFlags();
  return (
    <>
      {reduceTheBusinessFlowFields ? <BusinessLetsReviewContainer /> : <BusinessReviewContainer />}
    </>
  );
};
