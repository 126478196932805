import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { TransactionCardWrapper, TransactionRowIconArrow } from '../..';
import { TransactionDataTable } from '../../Grids/TransactionGridElements/TransactionDataTable/TransactionDataTable';

import { configs } from 'src/shared/config/config';
import { theme } from 'src/shared/theme/theme';

import { handleTransactionDescription } from 'src/helpers/helperHandlingTableGrid';

import { TransactionCardProps } from 'src/models/component/base.model';

/**
 * Primary UI component for user interaction
 */
export const TransactionCard = ({
  onClick,
  data = [],
  title = '',
  clickOnCard,
  viewAllLinkText,
  isRecentTransaction,
}: TransactionCardProps) => {
  const { t } = useTranslation();

  const formatedGridData =
    data &&
    data.length > 0 &&
    data.map((item: any) => {
      return { ...item, custom_description: handleTransactionDescription(item.description) };
    });

  return (
    <TransactionCardWrapper className={`card-wrapper-home-main`}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          marginBottom: 3,
          width: '100%',
        }}
      >
        <Typography
          component="p"
          variant="h2ExtraBold"
          textTransform={'uppercase'}
          sx={{
            marginBottom: 1,
            display: 'inline-flex',
            alignItems: 'center',
            color: 'gray.500',
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        {formatedGridData && formatedGridData.length > 0 && (
          <>
            <TransactionDataTable
              isToggle={true}
              dataObject={formatedGridData}
              searchInProgress={false}
              iconArrowVisible={true}
              isHandleArrowClickable={true}
              tabType={'transactions'}
              limit={isRecentTransaction ? 3 : configs.TRANSACTIONS_ROW_LIMIT}
              routeState={{ backRouteUrl: '/finance/account/main' }}
            />
          </>
        )}
      </Box>
      <Typography
        component="span"
        variant="h1Bold"
        color={'primary'}
        textTransform={'capitalize'}
        onClick={onClick}
        textAlign={'right'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          fontSize: '14px',
          [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
            paddingTop: 3,
          },
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            paddingTop: 2,
          },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        {t('home.viewAll')}
        <TransactionRowIconArrow>
          <KeyboardArrowRightIcon color="primary" />
        </TransactionRowIconArrow>
      </Typography>
    </TransactionCardWrapper>
  );
};
