import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  CustomersAdditionAcontainer,
  PreLoginAdditionCustomersAdditionAcontainer,
} from 'src/containers';

export const CustomersAddition = () => {
  const { webauthnEnabled } = useFlags();
  return (
    <>
      {webauthnEnabled ? (
        <PreLoginAdditionCustomersAdditionAcontainer />
      ) : (
        <CustomersAdditionAcontainer />
      )}
    </>
  );
};
