import { useEffect, useState } from 'react';
import { useUpdatePreferenceMutation } from 'src/services/account/account.preferenceApi';
import {
  useGetAccountQuery,
  useGetBankDetailsMutation,
} from 'src/services/account/accountServiceApi';
import { getUpdateRouteObj } from './helperRoutePreference';

export const handlePreferences = () => {
  const [updatePreference] = useUpdatePreferenceMutation();

  const updatePreferenceOnServer = async (
    type: any,
    step: any = null,
    status: any = null,
    relativeRoutePath: string = '/'
  ) => {
    const obj = getUpdateRouteObj(type, step, status, relativeRoutePath);
    await updatePreference({ data: obj });
  };

  return [updatePreferenceOnServer];
};

/**
 * @returns Get external bank account details
 */
export const handleBankDetails = () => {
  const [getBankDetails] = useGetBankDetailsMutation({});
  const { data, isLoading } = useGetAccountQuery('');
  const [reviewDetails, setReviewDetails]: any = useState(null);

  useEffect(() => {
    const genBankData = async () => {
      if (data && data?.id) {
        const bankData = await getBankDetails({ id: data?.id });
        setReviewDetails(bankData);
      }
    };

    genBankData();
  }, [data]);

  return [reviewDetails];
};
