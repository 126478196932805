import { FC, forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormInputContainer } from './FormEINInput.style';
import { FormInputErrorMessage } from '../FormInputErrorMessage/FormInputErrorMessage';

import { TextField, Typography, IconButton } from '@mui/material';

import { EINProps } from 'src/models/component/base.model';
import { ReplaceCard } from '../FormInputSSN/FormInputSSN.style';
import { RefreshIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import { FormInputWrapper } from '../FormInput/FormInput.style';

import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

export const FormEINInput: FC<EINProps> = forwardRef<HTMLInputElement, EINProps>(
  (
    {
      id,
      label,
      placeholder,
      inputError,
      isValid = false,
      value,
      onChange,
      autoFocus,
      autoComplete,
      replace = true,
      setEnableEIN,
      inputFocus,
      inValidEIN = false,
      inputMaxLength = 9,
    },
    ref,
    ...props
  ) => {
    const { t } = useTranslation();
    const handleInputClasses =
      inputError && !isValid ? 'error_input' : isValid ? 'valid_input' : 'normal_input';
    const [defaultVal, setDefaultVal] = useState('');
    const [preValue, setPreValue] = useState('');
    const [replaceEnabled, setReplaceEnabled] = useState(replace);
    const [inputMode, setInputMode]: any = useState('password');

    useEffect(() => {
      setReplaceEnabled(replace);
    }, [replace]);

    useEffect(() => {
      setEnableEIN && setEnableEIN(replaceEnabled);
    }, [replaceEnabled]);

    const getMaskedNumber = (event: any) => {
      let val = event.target.value;
      if (val.length > preValue.length) {
        val = val.replace(/\D/g, '');
        let num = '';
        num = val;
        num = num.trim();
        val = num;
      }
      setPreValue(val);
      return val;
    };

    const handleIconClickEvent = () => {
      if (inputMode === 'password') {
        setInputMode('text');
      }
      if (inputMode === 'text') {
        setInputMode('password');
      }
    };

    return (
      <FormInputWrapper className={handleInputClasses}>
        {!replaceEnabled && (
          <ReplaceCard onClick={() => setReplaceEnabled(true)}>
            <Typography variant="h1" color={inValidEIN ? 'red.500' : 'gray.700'} marginBottom={2}>
              {inValidEIN
                ? t('reviewCardtexts.taxIdNotProvided')
                : t('formInput.einReplaceButtonText')}
            </Typography>
            <div className="icon_wrapper">
              <RefreshIcon />
            </div>
          </ReplaceCard>
        )}
        {replaceEnabled && (
          <>
            <FormInputContainer>
              <TextField
                fullWidth
                label={label}
                value={!onChange ? defaultVal : value}
                id={id}
                ref={ref}
                type={inputMode}
                aria-label={label}
                placeholder={placeholder}
                error={inputError?.length > 0}
                focused={value ? true : false}
                inputProps={{
                  inputMode: inputMode,
                  autoFocus: autoFocus,
                  autoComplete: autoComplete,
                  maxLength: inputMaxLength,
                }}
                onBlur={inputFocus && inputFocus}
                onChange={(event: any) => {
                  if (onChange) {
                    onChange(getMaskedNumber(event));
                  } else {
                    setDefaultVal(getMaskedNumber(event));
                  }
                }}
                {...props}
              />

              <IconButton
                aria-label="toggle password visibility"
                onClick={handleIconClickEvent}
                onMouseDown={handleIconClickEvent}
                edge="end"
                sx={{ marginRight: 0.5, position: 'absolute', right: '10px', top: '11px' }}
              >
                {inputMode !== 'password' ? (
                  <VisibilityOffOutlinedIcon sx={{ color: 'gray.300', fontSize: 16 }} />
                ) : (
                  <RemoveRedEyeOutlinedIcon sx={{ color: 'gray.300', fontSize: 16 }} />
                )}
              </IconButton>
            </FormInputContainer>
            {inputError && !isValid && <FormInputErrorMessage>{inputError}</FormInputErrorMessage>}
          </>
        )}
      </FormInputWrapper>
    );
  }
);
