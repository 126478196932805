import moment from 'moment';

export const handleDatesFilterChange = (
  dates: any,
  e: any,
  setTerms: any,
  setSelectedDateFilterOptions: any,
  type: any = 'checkbox'
) => {
  let isAllEnable = true;
  const newArr = dates.map((obj: any) => {
    let id = e?.target?.id;
    if (obj.id === id) {
      return { ...obj, active: e?.target?.checked };
    } else {
      return { ...obj, active: false };
    }

    return obj;
  });
  setTerms(newArr);
  setFilterTermObj(newArr, setSelectedDateFilterOptions);
};

/**
 *
 * @param newArr
 * @param setSelectedFilterOptions
 */
export const setFilterTermObj = (newArr: [], setSelectedDateFilterOptions: any) => {
  const selectedDateId = newArr.filter((item: any) => item.active).map((o: any) => o.id);
  if (selectedDateId[0] === 'date') {
    setSelectedDateFilterOptions(null);
  } else {
    setSelectedDateFilterOptions(selectedDateId[0]);
  }
};

export const getSelectedDate = (selectedDate: any) => {
  const dateSelected = selectedDate && selectedDate !== undefined ? selectedDate : null;
  return dateSelected;
};

/**
 * Get Year to Today day count
 * @returns
 */
export const getYTD = () => {
  const now: any = new Date();
  const start: any = new Date(now.getFullYear(), 0, 0);
  const diff: any = now - start;
  const oneDay = 1000 * 60 * 60 * 24;
  const day = Math.floor(diff / oneDay);

  return day;
};

/**
 * Help to get unix form for transaction date filter
 * @param startDate
 * @param endDate
 * @param targetDays
 * @param isRangeDate
 * @returns
 */
export const convertToUnixFormat = (
  startDate: any,
  endDate: any,
  targetDays = 0,
  isRangeDate = true
) => {
  let convertToUnixED;
  let convertToUnixSD;

  // check if it's custome date picker or not
  if (isRangeDate) {
    const formatedStartDate = moment(startDate).subtract(targetDays, 'day').format('YYYY.MM.DD');
    const convertToStringSD = new Date(formatedStartDate).toString();
    convertToUnixSD = Math.floor(new Date(convertToStringSD).getTime() / 1000); // conver to unix format

    if (endDate) {
      const formatedEndDate = moment(endDate).add(1, 'day').format('YYYY.MM.DD');
      const convertToStringED = new Date(formatedEndDate).toString();
      convertToUnixED = Math.floor(new Date(convertToStringED).getTime() / 1000); // conver to unix format
    }
  } else {
    const formatedStartDate = moment()
      .subtract(targetDays - 1, 'day')
      .format('YYYY.MM.DD');
    const convertToStringSD = new Date(formatedStartDate).toString();
    convertToUnixSD = Math.floor(new Date(convertToStringSD).getTime() / 1000); // conver to unix format

    const formatedEndDate = moment().add(1, 'day').format('YYYY.MM.DD');
    const convertToStringED = new Date(formatedEndDate).toString();
    convertToUnixED = Math.floor(new Date(convertToStringED).getTime() / 1000); // conver to unix format
  }

  return [convertToUnixSD, convertToUnixED];
};

/**
 * set the created value based on the lessThan and Greater than date for Transaction data filteration
 * @param dateId
 * @param dateRange
 * @returns
 */
export const setTransactionCreatedDateRange = (dateId: string, dateRange?: any) => {
  let created: any;

  if (dateId !== 'date' && dateId !== 'all') {
    const convertDateID = parseInt(dateId);

    const [convertToUnixSD, convertToUnixED]: any = convertToUnixFormat(
      null,
      null,
      convertDateID,
      false
    );

    created = {
      gt: convertToUnixSD,
      lt: convertToUnixED,
    };
  } else if (dateId === 'date') {
    const getFirstIndexVal = dateRange[0];
    const getLastIndexVal = dateRange[1];

    const [convertToUnixSD, convertToUnixED]: any = convertToUnixFormat(dateRange[0], dateRange[1]);

    if (getFirstIndexVal && getLastIndexVal) {
      if (getFirstIndexVal !== getLastIndexVal) {
        created = {
          gte: convertToUnixSD,
          lte: convertToUnixED,
        };
      } else if (getFirstIndexVal === getLastIndexVal) {
        created = convertToUnixSD;
      }
    } else if (getFirstIndexVal && !getLastIndexVal) {
      created = convertToUnixSD;
    }
  } else if (dateId === 'all') {
    created = null;
  }

  return [created];
};
