import { styled } from '@mui/material/styles';

export const CustomerCardWrapper = styled('div')(
  ({ theme }) => `
 display: flex;
 align-items: center;
 cursor: pointer;
 background: ${theme.palette.white};
 justify-content: space-between;

 &.isBordered {
  border: 1px solid ${theme.palette.gray[100]};
  padding: ${theme.spacing(2, 4)};
  border-radius: 8px;
 }

 &.noneBordered {
  padding: ${theme.spacing(2, 0)};
 }
`
);

export const CardArrow = styled('div')(
  ({ theme }) => `
   width: auto;
   display: flex;
   align-items: center;
   justify-content: space-between;
   color: ${theme.palette.gray[300]} !important;

   svg {
    width: 18px;
   }
`
);

export const CustomerCardContainer = styled('div')(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    width: calc(100% - 30px);
  }
  @media (min-width: ${theme.breakpoints.values.sm}px) {
    width: calc(100% - 50px);
  }
`
);

export const CustomerCardContainerLeft = styled('div')`
  dsplay: flex;
  flex-direction: column;
  padding-right: 20px;
  align-items: center;
  max-width: calc(100% - 80px);
  min-width: 143px;

  * {
    text-align: left;
    width: 100%;
  }
`;

export const CustomerCardContainerRight = styled('div')`
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
