import { Box, Button, Stack, Typography } from '@mui/material';
import { ConfirmIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import { Modal } from '../Modal/Modal';
import { CustomerAdditionConfirmModalIconWrapper } from './CustomerAdditionConfirmModal.style';
import { useTranslation } from 'react-i18next';

export const CustomerAdditionConfirmModal = ({
  onCloseEvent,
  onFormSubmit,
  isUpdate,
  customerCount,
}: any) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal onCloseEvent={onCloseEvent} hideClose={true} isMobileBottomFixed={false}>
        <CustomerAdditionConfirmModalIconWrapper marginBottom={6}>
          <ConfirmIcon />
        </CustomerAdditionConfirmModalIconWrapper>
        <Box marginBottom={6} paddingX={5}>
          {customerCount > 1 && (
            <Stack spacing={3}>
              <Typography variant={'h4ExtraBold'} textAlign={'center'}>
                {t('customerAddition.multiCustomerConfirmModal.heading')}
              </Typography>
              <Typography variant="body2" textAlign="center" color={'gray.700'}>
                {t('customerAddition.confirmModal.content', {
                  payment: `$${parseInt(customerCount) * 50}`,
                })}
              </Typography>
            </Stack>
          )}
          {customerCount === 1 && (
            <Stack spacing={3}>
              <Typography variant={'h4ExtraBold'} textAlign={'center'}>
                {isUpdate
                  ? t('customerAddition.updateConfirmationModal.heading')
                  : t('customerAddition.confirmModal.heading')}
              </Typography>
              <Typography variant="body2" textAlign="center" color={'gray.700'}>
                {t('customerAddition.confirmModal.content', { payment: '$50' })}
              </Typography>
            </Stack>
          )}
        </Box>
        <Box marginBottom={2} justifyContent={'center'} display={'flex'}>
          <Button
            onClick={onFormSubmit}
            variant="containedLarge"
            size="small"
            color="primary"
            type="submit"
            sx={{ overflow: 'hidden', width: 120 }}
          >
            {'Done '}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
