import React from 'react';
import { useNavigate } from 'react-router-dom';

import { LogoImage } from './LogoImage';
import { LogoWrapper } from './Logo.style';

import { LogoProps } from 'src/models/component/base.model';
import classNames from 'classnames';

/**
 * Primary UI component for user interaction
 *
 * Use SVG for Logo
 */
export const Logo = ({
  logoMarginIndex = '0',
  isLogoClickable,
  isLogoOnlyForDesktop = false,
}: LogoProps) => {
  const navigate = useNavigate();

  const handleOnClick = () => navigate('/earnings/approved');

  return (
    <>
      {isLogoClickable ? (
        <LogoWrapper
          style={{ margin: logoMarginIndex, cursor: 'pointer' }}
          onClick={handleOnClick}
          className={classNames(['logo_wrapper', isLogoOnlyForDesktop ? 'logo-for-desktop' : ''])}
        >
          <LogoImage />
        </LogoWrapper>
      ) : (
        <LogoWrapper
          style={{ margin: logoMarginIndex }}
          className={classNames(['logo_wrapper', isLogoOnlyForDesktop ? 'logo-for-desktop' : ''])}
        >
          <LogoImage />
        </LogoWrapper>
      )}
    </>
  );
};
