import { ShadowCard, AfterBottomRow } from 'src/components/base/core';
import { Box, Typography } from '@mui/material';
import { PayupIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import { BatchDetailProps } from 'src/models/component/base.model';
import {
  getApprovalsStatusSorting,
  handlePayModeDisplay,
} from 'src/shared/utilities/dataConvertions';

export const BatchDetailAfterBottom = ({ batchDetails, t }: BatchDetailProps) => {
  const isPayModeCheck = batchDetails?.method === 'check' || batchDetails?.method === 'CHECK';

  return (
    <ShadowCard padding={4} marginBottom={4}>
      <Box marginBottom={4}>
        <Typography variant="caption1" color={'gray.500'}>
          {t('invoiceSummary.bottom.activity')}
        </Typography>
      </Box>

      <Box display={'flex'} flexDirection={'column'}>
        <AfterBottomRow className="content icon_available">
          <Box className="content_left">
            <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
              {t('invoiceSummary.bottom.origin')}
            </Typography>
          </Box>
          <Box className="content_right">
            <span className="payup_icon">
              <PayupIcon />
            </span>
            <Typography variant="h2Bold" component={'p'} color={'gray.500'}>
              {t('invoiceSummary.bottom.payUp')}
            </Typography>
          </Box>
        </AfterBottomRow>

        <AfterBottomRow className="content">
          <Box className="content_left">
            <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
              {isPayModeCheck
                ? t('invoiceSummary.bottom.recipientName')
                : t('invoiceSummary.bottom.destination')}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography variant="h2Bold" component={'p'} color={'gray.500'} textAlign={'right'}>
              {isPayModeCheck
                ? batchDetails?.check_recipient_name ?? '----'
                : batchDetails?.to_account_nickname ?? '----'}
            </Typography>
          </Box>
        </AfterBottomRow>

        {!isPayModeCheck && (
          <AfterBottomRow className="content">
            <Box className="content_left">
              <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
                {t('invoiceSummary.bottom.account')}
              </Typography>
            </Box>
            <Box className="content_right">
              {batchDetails?.bank_account_last4 ||
              batchDetails?.bank_account_last4 ||
              batchDetails?.to_account_detail ? (
                <Typography variant="h2Bold" component={'p'} color={'gray.500'}>
                  <span>• • • • •</span>{' '}
                  {batchDetails?.bank_account_last4 ??
                    batchDetails?.bank_account_last4 ??
                    batchDetails?.to_account_detail}
                </Typography>
              ) : (
                <Typography variant="h2Bold" component={'p'} color={'gray.500'}>
                  ----
                </Typography>
              )}
            </Box>
          </AfterBottomRow>
        )}

        {!isPayModeCheck && (
          <AfterBottomRow className="content">
            <Box className="content_left">
              <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
                {t('invoiceSummary.bottom.routing')}
              </Typography>
            </Box>
            <Box className="content_right">
              {batchDetails?.routing_number ? (
                <Typography variant="h2Bold" component={'p'} color={'gray.500'}>
                  {batchDetails?.routing_number}
                </Typography>
              ) : (
                <Typography variant="h2Bold" component={'p'} color={'gray.500'}>
                  ----
                </Typography>
              )}
            </Box>
          </AfterBottomRow>
        )}

        {isPayModeCheck && (
          <AfterBottomRow className="content">
            <Box className="content_left">
              <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
                {t('invoiceSummary.bottom.recipientAddress')}
              </Typography>
            </Box>
            <Box className="content_right">
              <Typography variant="h2Bold" component={'p'} color={'gray.500'} textAlign={'right'}>
                {batchDetails?.check_recipient_address ?? '----'}
              </Typography>
            </Box>
          </AfterBottomRow>
        )}

        <AfterBottomRow className="content have_two_row">
          <Box className="content_left">
            <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
              {t('invoiceSummary.bottom.method')}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography
              variant="h2Bold"
              component={'p'}
              color={'gray.500'}
              marginBottom={1}
              textAlign={'right'}
            >
              {handlePayModeDisplay(batchDetails?.method) ??
                handlePayModeDisplay(batchDetails?.method)}
            </Typography>
            <Typography variant="h1" component={'p'} color={'gray.500'} textAlign={'right'}>
              (
              {batchDetails?.method === 'debit_card'
                ? `1-60 mins`
                : batchDetails?.method === 'check'
                ? `30-60 days`
                : `1-3 ${t('invoiceSummary.bottom.daysText')}`}
              )
            </Typography>
          </Box>
        </AfterBottomRow>

        <AfterBottomRow className="content">
          <Box className="content_left">
            <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
              {isPayModeCheck
                ? t('invoiceSummary.bottom.checkNumber')
                : t('invoiceSummary.bottom.transactionID')}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography variant="h2Bold" component={'p'} color={'gray.500'}>
              {isPayModeCheck
                ? batchDetails?.check_number ?? '----'
                : batchDetails?.trace_number ?? '----'}
            </Typography>
          </Box>
        </AfterBottomRow>

        {isPayModeCheck && (
          <AfterBottomRow className="content">
            <Box className="content_left">
              <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
                {t('invoiceSummary.bottom.checkStatus')}
              </Typography>
            </Box>
            <Box className="content_right">
              <Typography variant="h2Bold" component={'p'} color={'gray.500'}>
                {getApprovalsStatusSorting(undefined, 'check', batchDetails?.check_status ?? '---')}
              </Typography>
            </Box>
          </AfterBottomRow>
        )}

        <AfterBottomRow className="content">
          <Box className="content_left">
            <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
              {t('invoiceSummary.bottom.batchNumber')}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography variant="h2Bold" component={'p'} color={'gray.500'}>
              {batchDetails?.batch_number ?? '----'}
            </Typography>
          </Box>
        </AfterBottomRow>
      </Box>
    </ShadowCard>
  );
};
