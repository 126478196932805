import moment from 'moment';
import { useEffect, useState } from 'react';

import { accountCompany } from 'src/models/account/account.service.mapper';
import { IMAccountResponseObject, IMCompany, IMResult } from 'src/models/api/account';

import {
  useCreateAccountMutation,
  useDeleteAccountMutation,
  useGetAccountQuery,
  useUpdateAccountMutation,
} from 'src/services/account/accountServiceApi';

import {
  AddAditionalPhoneDetails,
  deleteAditionalPhoneDetails,
} from 'src/services/account/additional.phone.service';

import { DOB } from 'src/shared/utilities/interface';
import { pre_fill_params } from 'src/shared/content/content';

/**
 * Initialize the Payup account
 * If account was already initialialized, update the account details
 * @param accountData
 * @param setAccountData
 * @returns
 */
export const handleBusinessInfo = (accountData: any, setAccountData: any) => {
  const [createAccount] = useCreateAccountMutation(); // RTK query create account call
  const [updateAccount] = useUpdateAccountMutation(); // RTK query update account call
  const [deleteAccount] = useDeleteAccountMutation(); // RTK query delete account call

  const [serverSideErrors, setServerSideErrors] = useState(); // initialize the state  to set the serversde error __
  const [isLoading, setIsLoading] = useState(false); // initialize the loading state, it's use used to handle n the Api call

  // set the get account call respnse in to state ___
  useEffect(() => {
    if (accountData) {
      setAccountData(accountData);
    }
  }, [accountData]);

  // handle business info form submition __
  const formSubmit = async (
    data: any, // form data
    onFormSubmit: any, // call back function
    isChangeBusinessType: boolean, // make sure is business type change or not
    setVerifyCodeContainerVisible?: any // set state, is phone verification containervisible or not
  ) => {
    try {
      // set Loading true ___
      setIsLoading(true);

      // if user back from individual flow, first delete the existing account __
      if (isChangeBusinessType) {
        await deleteAccount(null);
      }

      // set accountResponse in to variable __
      let accountResponse: any;

      // if tax id not provided and not came  from individual flow, then create the business account __
      if (!accountData?.company?.tax_id_provided || isChangeBusinessType) {
        // create object to send the value in the post call
        const obj: IMAccountResponseObject = {
          business_type: 'company',
          company: {
            name: data.inputCompanyName,
            tax_id: data.inputTaxId,
            phone: data.inputCompanyPhone?.replace(/[^0-9]/g, ''),
          },
          business_profile: {
            url: pre_fill_params.url,
            mcc: pre_fill_params.mcc,
            product_description: pre_fill_params.description,
          },
        };

        // call the create account call mutation __
        accountResponse = await createAccount(obj);
      }
      // if account is already initialized update the account __
      else {
        // initialize the updating values into variable
        let updateFormData: IMAccountResponseObject;

        // initialize the company object
        let companyObj: IMCompany = {
          name: data.inputCompanyName,
          phone: data.inputCompanyPhone?.replace(/[^0-9]/g, ''),
        };

        // if user updaed the EIN
        if (data.inputTaxId) {
          companyObj = {
            ...companyObj,
            tax_id: data.inputTaxId?.replace(/[^0-9]/g, ''),
          };
        }

        // if charges_enabled true send tos_shown_and_accepted as true
        if (accountData?.charges_enabled) {
          updateFormData = {
            company: companyObj,
            tos_shown_and_accepted: true,
          };
        }
        // or else send onlt the updated values
        else {
          updateFormData = {
            company: companyObj,
          };
        }

        // call the update account call mutation __
        accountResponse = await updateAccount(updateFormData);
      }

      // set account response data object into varaible
      const accountResponseData: IMResult = accountResponse?.data;

      // if account response data retrive after update
      if (accountResponseData) {
        onFormSubmit(); // call the call back function or handle the redirection
        // @ts-ignore
        setAccountData(accountResponseData); // se the account response data into state
      } else {
        // handle the error
        // @ts-ignore
        const responseError =
          // @ts-ignore
          accountResponse?.error?.data?.message ?? accountResponse?.error?.data.error;
        if (responseError) {
          // @ts-ignore
          setServerSideErrors(responseError);
          setVerifyCodeContainerVisible && setVerifyCodeContainerVisible(false);
        }
      }

      // make loding false __
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setVerifyCodeContainerVisible && setVerifyCodeContainerVisible(false);
      // make loding false __
      setIsLoading(false);
    }
  };

  // handle phone input field onchange functionality
  const onChangeHandlerPhone = (val: any, getValues: any, setValue: any, btnSubmitted: any) => {
    setValue('inputCompanyPhone', val); // Set value
  };

  // handle ein input field onchange functionality
  const onChangeHandlerTax = (val: any, getValues: any, setValue: any, btnSubmitted: any) => {
    setValue('inputTaxId', val); // Set value
  };

  // return the values
  return [formSubmit, isLoading, onChangeHandlerPhone, onChangeHandlerTax, serverSideErrors];
};

/**
 * Handle Business Info Address
 * Submit business address in to the update call
 * @param accountData
 * @param setAccountData
 * @param setAddressError
 * @returns
 */
export const handleBusinessInfoAddress = (
  accountData: any,
  setAccountData: any,
  setAddressError?: any
) => {
  const [updateAccount] = useUpdateAccountMutation(); // RTK query update account call

  const [isLoading, setIsLoading] = useState(false); // initialize the loading state, it's use used to handle n the Api call

  // handle business address form submition __
  const appSubmit = async (data: any, onFormSubmit: any, isBack: any, navigate: any) => {
    try {
      // make isLoading state true, now loader will enabled
      setIsLoading(true);

      if (accountData) {
        // set the updating values into variable
        const updateFormData = {
          business_type: 'company',
          company: {
            address: {
              city: data.city,
              line1: data.line1,
              line2: data.line2,
              postal_code: data.postal_code,
              state: data.state,
            },
          },
        };

        // call the update account call
        const accountUpdateResponse: any = await updateAccount(updateFormData);

        // check account update giving 200 response with response data
        // @ts-ignore
        if (accountUpdateResponse?.data) {
          // @ts-ignore
          const checkAddressError = accountUpdateResponse?.data?.errors;
          setAccountData(accountUpdateResponse?.data);

          // check the PO box address arrer, and check this from error list
          const errorItem: any = checkAddressError.filter(
            (object: any) =>
              object.code === 'invalid_address_po_boxes_disallowed' &&
              object.requirement === 'company.address.line1'
          );

          // if PO Box error occur set error into state using setAddressError method
          if (errorItem && errorItem.length === 1) {
            setAddressError(errorItem[0]?.reason);
          } else {
            // else redirect into the relevant screen
            localStorage.removeItem('isBack');
            setAddressError('');
            if (isBack) {
              // if user came from another screen, or back from previous screen
              navigate('/business-review');
            } else {
              // else navigate t the Business representative screen
              onFormSubmit();
            }
          }
        } else {
          // @ts-ignore
          if (accountUpdateResponse?.error) {
            // handle the errors
            // @ts-ignore
            setErrorState('errors');
            setAddressError(
              accountUpdateResponse?.error?.data?.error ??
                accountUpdateResponse?.error?.data?.message ??
                accountUpdateResponse?.error?.error
            );
          }
        }
      }

      // make isLoading state false, now loader will disabled
      setIsLoading(false);
    } catch (error) {
      // make isLoading state false, now loader will disabled
      setIsLoading(false);
      console.log(error);
    }
  };

  // return the values
  return [appSubmit, isLoading];
};

/**
 * OLD FLOW code
 * in Front End handled with the reduce_the_business_flow_fields (reduceTheBusinessFlowFields) featured flag
 * Buesiness Review -> Company details Edit flow without reducing the Representative details
 * @returns
 */
export const handleBusinessInfoEdit = () => {
  const [updateAccount] = useUpdateAccountMutation();
  const { data } = useGetAccountQuery({ count: 5 }, { refetchOnMountOrArgChange: true });
  const [isLoading, setIsLoading] = useState(false);
  const [serverSideErrors, setServerSideErrors] = useState('');

  const [accountData, setAccountData]: any = useState();
  useEffect(() => {
    if (data) {
      setAccountData(data);
    }
  }, [data]);

  const appLogin = async (
    data: any,
    onCancelButton: any,
    setUpdateData: any,
    setVerifyCodeContainerVisible?: any
  ) => {
    try {
      setIsLoading(true);
      setServerSideErrors('');
      let accountResponse = null;

      delete data.dob;
      delete data.id;
      const updateFormData = {
        ...data,
        inputCompanyPhone: data?.inputCompanyPhone?.replace(/[^0-9]/g, ''),
        inputComPostal: data.postal_code,
        business_type: 'company',
        inputComState: data.state,
        inputComCity: data.city,
        inputComLine1: data.line1,
        inputComLine2: data.line2,
        inputDescription: pre_fill_params.description,
        inputMCC: pre_fill_params.mcc,
        url: pre_fill_params.url,
        line1: data.line1Individual,
        line2: data.line2Individual,
        city: data.cityIndividual,
        postal_code: data.postal_codeIndividual,
        state: data.stateIndividual,
      };

      const updateObj = accountCompany(updateFormData, false, false);
      accountResponse = await updateAccount(updateObj);

      // @ts-ignore
      if (accountResponse?.data) {
        // @ts-ignore
        const checkAddressError = accountResponse?.data?.errors;

        const errorItem: any = checkAddressError.filter(
          (object: any) =>
            object.code === 'invalid_address_po_boxes_disallowed' &&
            object.requirement === 'company.address.line1'
        );

        if (errorItem && errorItem.length === 1) {
          setServerSideErrors(errorItem[0]?.reason);
        } else {
          setServerSideErrors('');
          onCancelButton();
        }
        // @ts-ignore
        setUpdateData(accountResponse?.data);
      } else {
        // @ts-ignore
        const responseError = accountResponse?.error?.data?.message;
        if (responseError && typeof responseError === 'string') {
          setServerSideErrors(responseError);
          setVerifyCodeContainerVisible && setVerifyCodeContainerVisible(false);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setVerifyCodeContainerVisible && setVerifyCodeContainerVisible(false);
    }
  };

  const onChangeHandlerPhone = (val: any, getValues: any, setValue: any) => {
    setValue('inputCompanyPhone', val); // Set value
  };
  const onChangeHandlerTax = (val: any, getValues: any, setValue: any) => {
    setValue('inputTaxId', val); // Set value
  };

  return [
    accountData,
    serverSideErrors,
    setServerSideErrors,
    appLogin,
    isLoading,
    onChangeHandlerPhone,
    onChangeHandlerTax,
  ];
};

/**
 * OLD FLOW code
 * in Front End handled with the reduce_the_business_flow_fields (reduceTheBusinessFlowFields) featured flag
 * Buesiness Review -> Personal details Edit flow  reducing the Representative details
 * @returns
 */
export const handlePersonalInfoEdit = () => {
  const [updateAccount] = useUpdateAccountMutation();
  // { count: 5 } use for init api all the time when component rerender
  const { data } = useGetAccountQuery({ count: 5 }, { refetchOnMountOrArgChange: true });
  const [isLoading, setIsLoading] = useState(false);
  const [serverSideErrors, setServerSideErrors] = useState('');

  const [accountData, setAccountData]: any = useState();
  useEffect(() => {
    if (data) {
      setAccountData(data);
    }
  }, [data]);

  const isNullDOB = (dob: DOB) => {
    return !dob.day || !dob.month || !dob.year;
  };

  const onChangeHandlerPhone = (val: any, getValues: any, setValue: any, btnSubmitted: any) => {
    setValue('phone_number', val, { shouldValidate: btnSubmitted && true }); // Set value
  };
  const onChangeHandlerTax = (val: any, getValues: any, setValue: any, btnSubmitted: any) => {
    setValue('inputTaxId', val, { shouldValidate: btnSubmitted && true }); // Set value
  };

  /**
   * add additonal phone number
   */
  const addAditionalPhone = async (additonalNumber: string, id: any) => {
    return await AddAditionalPhoneDetails([additonalNumber.replace(/[^0-9]/g, '')], accountData.id);
  };

  const deleteAditionalPhone = async (additonalNumber: string, id: any) => {
    return await deleteAditionalPhoneDetails([`${additonalNumber.replace(/[^0-9]/g, '')}`], id);
  };

  const onChangeHandlerDateInput = (val: any, setValue: any, setErrorManualDateType: any) => {
    if (val) {
      const dateMoment = moment(val);
      const toDate = moment(val).toDate();
      const dateWithFormat = moment(toDate).format('MM/DD/yyyy');
      if (
        !dateMoment?.isValid() ||
        !toDate?.getFullYear() ||
        toDate?.getFullYear() < 1900 ||
        (toDate?.getFullYear() > new Date().getFullYear() && val.length !== 10)
      ) {
        setErrorManualDateType(false);
      } else {
        const dateWithFormat = moment(toDate).format('MM/DD/yyyy');
        const years = moment().diff(dateWithFormat, 'years', true);
        if (years < 18) {
          val.length === 10 ? setErrorManualDateType(true) : setErrorManualDateType(false);
        } else {
          val.length < 9 ? setErrorManualDateType(false) : setErrorManualDateType(false);
        }
      }
    }
    setValue('dob', val); // Set value
  };

  const isNot18 = (val: any) => {
    const dateMoment = moment(val);
    const toDate = moment(val).toDate();
    const dateWithFormat = moment(toDate).format('MM/DD/yyyy');
    const years = moment().diff(dateWithFormat, 'years', true);
    if (years < 18) {
      return val.length === 10 ? false : true;
    }
    return true;
  };

  const appLogin = async (
    data: any,
    onCancelButton: any,
    setUpdateData: any,
    needToDeleteNumber: any,
    setVerifyCodeContainerVisible?: any
  ) => {
    setIsLoading(true);

    try {
      setServerSideErrors('');
      let accountResponse: any = null;
      const dob = data?.dob;
      data.dob = moment(dob).format('MMDDYYYY');
      delete data.id;
      const updateFormData = {
        ...data,
        inputDescription: pre_fill_params.description,
        inputMCC: pre_fill_params.mcc,
        url: pre_fill_params.url,
        line1: data.line1,
        line2: data.line2,
        city: data.city,
        postal_code: data.postal_code,
        state: data.state,
        inputCompanyPhone: data?.inputCompanyPhone?.replace(/[^0-9]/g, ''),
        phone_number: data?.phone_number?.replace(/[^0-9]/g, ''),
        phone_number_2: data?.phone_number_2?.replace(/[^0-9]/g, ''),
      };

      const updateObj = accountCompany(updateFormData, false, false);
      accountResponse = await updateAccount(updateObj);

      // @ts-ignore
      if (accountResponse?.data) {
        const checkAddressError = accountResponse?.data?.errors;

        const errorItem: any = checkAddressError.filter(
          (object: any) =>
            object.code === 'invalid_address_po_boxes_disallowed' &&
            object.requirement !== 'company.address.line1'
        );

        if (errorItem && errorItem.length === 1) {
          setServerSideErrors(errorItem[0]?.reason);
        } else {
          setServerSideErrors('');
          if (data.phone_number_2 === needToDeleteNumber) {
            onCancelButton();
          } else {
            if (data.phone_number_2) {
              if (needToDeleteNumber !== '') {
                await deleteAditionalPhone(needToDeleteNumber, accountData.id);
              }
              const response = await addAditionalPhone(data.phone_number_2, accountData.id);
              if (response?.data.length > 0) {
                onCancelButton();
              }
            } else {
              if (data.phone_number_2 === '' && needToDeleteNumber) {
                await deleteAditionalPhone(needToDeleteNumber, accountData.id);
              }
              onCancelButton();
            }
          }
          onCancelButton();
        }
        // @ts-ignore
        setUpdateData(accountResponse?.data);
      } else {
        // @ts-ignore
        const responseError =
          // @ts-ignore
          accountResponse?.error?.data?.message ?? accountResponse?.error?.data.error;
        if (responseError && typeof responseError === 'string') {
          setServerSideErrors(responseError);
          setVerifyCodeContainerVisible && setVerifyCodeContainerVisible(false);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setVerifyCodeContainerVisible && setVerifyCodeContainerVisible(false);
      setIsLoading(false);
    }
  };

  const isValidDOB = (value: any) => {
    const dateMoment = moment(value);
    return dateMoment?.isValid();
  };

  return [
    accountData,
    serverSideErrors,
    setServerSideErrors,
    appLogin,
    isLoading,
    onChangeHandlerPhone,
    onChangeHandlerTax,
    onChangeHandlerDateInput,
    isNullDOB,
    isNot18,
    isValidDOB,
  ];
};
