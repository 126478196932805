import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';

export const StylesFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginLeft: 0,
  marginRight: 0,
  marginBottom: theme.spacing(7),
  '& .MuiCheckbox-root': {
    padding: 0,
  },
}));
