import { Typography } from '@mui/material';
import {
  AccordionArrow,
  UploadIcon,
  CameraIcon,
} from 'src/components/base/core/Media/Icon/Icons/Icons';

import { ListItem } from './UploadDocumentCards.style';

export interface UploadDocumentCardsProps {
  title?: string;
  onClickEvent?: () => void;
  dataLabel?: string;
  iconType?: 'UploadIcon' | 'CameraIcon';
  isOnlyDesktop?: boolean;
}

export const UploadDocumentCards = ({
  title,
  onClickEvent,
  dataLabel,
  iconType,
  isOnlyDesktop = false,
}: UploadDocumentCardsProps) => {
  const handleFaqIconTypes = (_iconType: string | undefined) => {
    switch (_iconType) {
      case 'UploadIcon':
        return <UploadIcon />;
      case 'CameraIcon':
        return <CameraIcon />;
      default:
        break;
    }
  };
  return (
    <ListItem
      data-label={dataLabel ? dataLabel : title}
      onClick={onClickEvent}
      className={isOnlyDesktop ? 'hidden_desktop' : 'visble_dektop'}
    >
      <div className="list_item_left">
        {iconType && <div className="left_icon_block">{handleFaqIconTypes(iconType)}</div>}
        <Typography variant="h2Bold" color={'gray.800'}>
          {title}
        </Typography>
      </div>
      <div className="icon_wrapper">
        <AccordionArrow />
      </div>
    </ListItem>
  );
};
