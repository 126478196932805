import moment from 'moment';
import { downloadPeriodOptions } from 'src/shared/utilities/initialStates';
import { DownloadCSVState } from 'src/shared/utilities/interface';

export const getInvoicedAtFilter = (downloadStates: DownloadCSVState, dateNow: Date) => {
  switch (downloadStates.selectedOption) {
    case downloadPeriodOptions[0]:
      return undefined;
    case downloadPeriodOptions[1]:
      return {
        greaterThan: moment(dateNow).startOf('day').format(),
        lessThan: moment(dateNow).format(),
      };
    case downloadPeriodOptions[2]:
      return {
        greaterThan: moment(dateNow).subtract(6, 'day').startOf('day').format(),
        lessThan: moment(dateNow).format(),
      };
    case downloadPeriodOptions[3]:
      return {
        greaterThan: moment(dateNow).subtract(29, 'day').startOf('day').format(),
        lessThan: moment(dateNow).format(),
      };
    case downloadPeriodOptions[4]:
      return {
        greaterThan: moment(dateNow).subtract(89, 'day').startOf('day').format(),
        lessThan: moment(dateNow).format(),
      };
    case downloadPeriodOptions[5]:
      return {
        greaterThan: moment(dateNow).startOf('year').format(),
        lessThan: moment(dateNow).format(),
      };
    case downloadPeriodOptions[6]:
      if (downloadStates.selectedDateRange?.length > 1) {
        return {
          greaterThan: downloadStates.selectedDateRange[0],
          lessThan: downloadStates.selectedDateRange[1],
        };
      }
      break;
    default:
      return undefined;
  }
};
