import { Header, HeaderFixed, Wrapper, WrpperAuthorizedContainer } from 'src/components/base/core';
import { InvoiceDetailMiddle } from './InvoiceDetailMiddle/InvoiceDetailMiddle';
// import { InvoiceDetailBottom } from './InvoiceDetailBottom/InvoiceDetailBottom';
import { InvoiceDetailTop } from './InvoiceDetailTop/InvoiceDetailTop';
import { InvoiceDetailAfterBottom } from './InvoiceDetailAfterBottom/InvoiceDetailAfterBottom';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useGetInvoiceDetailsMutation } from 'src/services/invoice/invoiceServiceApi';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { InvoiceDetailBottom } from './InvoiceDetailBottom/InvoiceDetailBottom';
import { useTranslation } from 'react-i18next';
import { invoiceBatchTabsType } from 'src/shared/utilities/interface';

type LocationState = {
  payout_id?: string;
  tabType?: invoiceBatchTabsType;
};

export const InvoiceDetailContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [getInvoiceDetails, isFetching]: any = useGetInvoiceDetailsMutation();
  const [invoiceDetailsData, setInvoiceDetailsData] = useState<any>();

  const { payout_id, tabType } =
    location?.state != null ? (location?.state as LocationState) : { payout_id: null, tabType: '' };

  useEffect(() => {
    const getInvocieDetails = async () => {
      const retryData = await getInvoiceDetails({ payout_id: payout_id });
      setInvoiceDetailsData(retryData?.data);
    };

    getInvocieDetails();
  }, []);

  const handleBackButton = () => {
    navigate(-1);
  };

  const getDatesPendingStatus = (): boolean => {
    if (tabType === 'paid') {
      return false;
    }
    if (!invoiceDetailsData?.estimated_payout_date) {
      return true;
    }

    return false;
  };

  const isDatesPendingInvoice = getDatesPendingStatus();

  return (
    <>
      {isFetching && !invoiceDetailsData && <ManualLoader />}
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonVisible={true}
          paddingX={0}
          headerText={t('earning.invoiceSummaryText')}
          onBackButtonClick={handleBackButton}
          withCenteredHeaderText
          isInvoiceDownLoadLink
          invoiceDownloadLink={`/api/coupa/download-invoice/${payout_id}`}
        />
      </HeaderFixed>

      <Wrapper isAuthorizedWrapper>
        {invoiceDetailsData && (
          <WrpperAuthorizedContainer>
            <InvoiceDetailTop
              isDatesPendingInvoice={isDatesPendingInvoice}
              invoiceDetails={invoiceDetailsData}
              payoutId={payout_id}
              setInvoiceDetailsData={setInvoiceDetailsData}
              tabType={tabType}
            />
            <InvoiceDetailMiddle
              isDatesPendingInvoice={isDatesPendingInvoice}
              invoiceDetails={invoiceDetailsData}
              tabType={tabType}
              payoutId={payout_id}
            />
            {/*commented for future development*/}
            <InvoiceDetailBottom invoiceDetails={invoiceDetailsData} />
            <InvoiceDetailAfterBottom invoiceDetails={invoiceDetailsData} />
          </WrpperAuthorizedContainer>
        )}
      </Wrapper>
    </>
  );
};
