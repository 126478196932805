import { Checkbox, FormControlLabel, RadioGroup, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { FormRadioButton } from 'src/components/base/core';
import { UserIcon } from '../../Media/Icon/Icons/Icons';

export const FormSelectionVerifyBusinessStructure = ({
  radioChecked,
  handleRep,
  ownershipPercentageSelectionEnabled,
  ownerPercentageChecked,
  chnageOwnerPercentage,
  t,
}: {
  radioChecked?: string;
  handleRep?: any;
  ownershipPercentageSelectionEnabled?: boolean;
  ownerPercentageChecked?: any;
  chnageOwnerPercentage?: any;
  t?: any;
}) => {
  return (
    <Box paddingTop={2} marginBottom={4}>
      <Typography
        color={'primary'}
        sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}
        component={'div'}
      >
        <UserIcon width={'16'} height={'16'} strokeWidth="2.5" />
        <Typography
          sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 2.5 }}
          component={'div'}
          color={'inherit'}
        >
          <Typography variant="h2Bold" color={'inherit'} component={'span'} marginBottom={0.5}>
            {'Are you the sole owner of this business?'}
          </Typography>
          <Typography variant="h2Bold" color={'inherit'} component={'span'}>
            {'(Select “Yes” for a single-member LLC  )'}
          </Typography>
        </Typography>
      </Typography>

      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        sx={{ paddingLeft: 7 }}
      >
        <FormRadioButton
          label="Yes"
          name={'relationship'}
          value={'yes'}
          id={'yes'}
          checked={radioChecked === 'yes'}
          handleChange={handleRep}
        />
        <FormRadioButton
          label="No"
          name={'relationship'}
          value={'no'}
          id={'no'}
          checked={radioChecked === 'no'}
          handleChange={handleRep}
          marginRight={6}
        />
      </RadioGroup>

      {ownershipPercentageSelectionEnabled && (
        <FormControlLabel
          sx={{ marginBottom: 3 }}
          control={
            <Checkbox
              name="confirm_subscription"
              size="small"
              onChange={chnageOwnerPercentage}
              checked={ownerPercentageChecked}
            />
          }
          label={
            <Typography variant="body1" color={'gray.700'}>
              {t('formInput.executiveConfirmInput')}
            </Typography>
          }
        />
      )}
    </Box>
  );
};
