import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { useReadOTP } from 'react-read-otp';
import { Box } from '@mui/material';
import { Header, Wrapper, HeadingSummaryBlock, Form } from 'src/components/base/core';
import { useEffect } from 'react';
import { ReactInputVerificationCode } from 'src/components/base/core/FormElements/VerificatonCodeInput/ReactInputVerificationCode';
import { handlePhoneVerifyCode } from 'src/helpers/helperPhoneVerificaition';
import { typeVerificationCodeFrom } from 'src/models/component/base.model';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';

interface AccountVerifyPhoneProps {
  phone?: any;
  phoneWithMask?: string;
  onSubmitFunction?: any;
  fromWhere?: typeVerificationCodeFrom;
  setVerifyCodeContainerVisible?: any;
}

const AccountLoginSchema = yup.object().shape({
  code: yup.string().required('Please enter a full 6 digit code'),
});

export const PhoneVerifyCodeContainer = ({
  phone,
  phoneWithMask,
  onSubmitFunction,
  setVerifyCodeContainerVisible,
}: AccountVerifyPhoneProps) => {
  const [handleChange, handleResendCode, handleOnKeydown, isVerifyLoading] =
    handlePhoneVerifyCode();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(AccountLoginSchema) });

  const [error, setError] = useState('');
  const [isVerified, setisVerified] = useState(false);
  const [isWrong, setisWrong] = useState(false);
  const [isCoupaError, setIsCoupaError] = useState(false);
  const { t } = useTranslation();

  const [enabled, setEnabled] = useState(true);
  const [otp, setOTP] = useState('');
  useReadOTP(setOTP, {
    enabled,
    onError(err) {
      console.log(err);
    },
  });

  const appLogin = handleSubmit(async () => {});

  const handleBackButton = () => {
    setVerifyCodeContainerVisible(false);
  };

  useEffect(() => {
    if (otp.length === 6) {
      handleVerificationChange(otp);
    }
  }, [otp]);

  const handleVerificationChange = (values: any) => {
    // @ts-ignore
    handleChange(values, onSubmitFunction, phone, setError, setisVerified, setisWrong);
  };

  const handleOnKeydownHandler = (event: any) => {
    // @ts-ignore
    handleOnKeydown(event);
  };

  const handleResendCodeHandler = () => {
    setisWrong(false);
    // @ts-ignore
    handleResendCode(phone, setisWrong);
  };

  return (
    <>
      {isVerifyLoading && <ManualLoader />}
      <Wrapper isFullScreen>
        <Header isBackButtonVisible={!isVerified} onBackButtonClick={handleBackButton} />
        <Box
          sx={{ paddingLeft: 3, marginBottom: 6, paddingRight: 3, height: '93px' }}
          className={'fs-mask'}
        >
          <HeadingSummaryBlock
            heading={isVerified ? 'Phone Verified' : t('headingContent.verifyCode.heading')}
            headingAlignment={isVerified ? 'center' : 'center'}
            mobileHeadingAlignment={isVerified ? 'center' : 'left'}
            mobileContentAlignment={'left'}
            content={!isVerified ? `${t('headingContent.verifyCode.content')}` : null}
            contentNewLine={!isVerified ? `${phoneWithMask}` : null}
            contentAlignment="center"
          />
        </Box>
        <div>{otp}</div>
        <Form onSubmit={appLogin}>
          <ReactInputVerificationCode
            autoFocus
            onChange={(value: any) => {
              console.log(value);
            }}
            onCompleted={(value: any) => handleVerificationChange(value)}
            error={errors.code?.message}
            isWrong={isWrong}
            isVerified={isVerified}
            coupaError={isCoupaError}
            errorMsg={error}
            onClickResendOtp={handleResendCodeHandler}
            enableResend
          />
          <input
            id={'code'}
            type="hidden"
            maxLength={6}
            minLength={6}
            {...register('code')}
            value={''}
          />
        </Form>
      </Wrapper>
    </>
  );
};
