import { Typography } from '@mui/material';
import { GiftIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import {
  InviteCustomerNoteCardWrapper,
  InviteCustomerNoteCardLeft,
  InviteCustomerNoteCardRight,
} from './InviteCustomerNoteCard.style';

export const InviteCustomerNoteCard = ({ mainContent, subContent, onClickEvent }: any) => {
  return (
    <InviteCustomerNoteCardWrapper onClick={onClickEvent}>
      <InviteCustomerNoteCardLeft>
        <GiftIcon />
      </InviteCustomerNoteCardLeft>
      <InviteCustomerNoteCardRight>
        <Typography
          variant="h1"
          color={'blue.500'}
          fontWeight="medium"
          sx={{ marginBottom: 1 }}
          textTransform={'capitalize'}
        >
          {mainContent}
        </Typography>
        <Typography variant="h1" color={'gray.600'}>
          {subContent}
        </Typography>
      </InviteCustomerNoteCardRight>
    </InviteCustomerNoteCardWrapper>
  );
};
