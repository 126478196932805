import { configs } from '../config/config';
import { setCookie } from './cookiesHelper';
import { encryptWithAES } from './encryptHelpper';
import { AddressParams, CoupaParams } from './interface';

export const setPrimaryContacts = (
  data: CoupaParams | null,
  primary_address: AddressParams | null,
  id: string | null,
  phone: string | null,
  email: string | null,
  firstName: string | null,
  lastName: string | null,
  businessName: string | null
) => {
  const contactObj = {
    name: data ? data['name-given'] : firstName ? firstName : null,
    lastName: data ? data['name-family'] : lastName ? lastName : null,
    phone: phone ? phone.substring(2, phone.length) : null,
    email: data ? data?.email : email ? email : null,
    street1: primary_address ? primary_address?.street1 : null,
    street2: primary_address ? primary_address?.street2 : null,
    city: primary_address ? primary_address?.city : null,
    state: primary_address ? primary_address.state : null,
    postal_code: primary_address ? primary_address['postal-code'] : null,
    id: id ? id : null,
    businessName: businessName ? businessName : null,
  };

  setCookie('coupaObj', encryptWithAES(JSON.stringify(contactObj), configs.ENCRYPT_PHRESE), 7);
};

export const setPrimaryContactsForEmailSignup = (
  data: CoupaParams | null,
  primary_address: AddressParams | null,
  id: string | null,
  phone: string | null,
  email: string | null,
  firstName: string | null,
  lastName: string | null,
  businessName: string | null
) => {
  const contactObj = {
    name: data ? data['name-given'] : firstName ? firstName : null,
    lastName: data ? data['name-family'] : lastName ? lastName : null,
    phone: phone ? phone : null,
    email: data ? data?.email : email ? email : null,
    street1: primary_address ? primary_address?.street1 : null,
    street2: primary_address ? primary_address?.street2 : null,
    city: primary_address ? primary_address?.city : null,
    state: primary_address ? primary_address.state : null,
    postal_code: primary_address ? primary_address['postal-code'] : null,
    id: id ? id : null,
    businessName: businessName ? businessName : null,
  };

  setCookie('coupaObj', encryptWithAES(JSON.stringify(contactObj), configs.ENCRYPT_PHRESE), 7);
};
