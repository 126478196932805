import { useEffect, useState } from 'react';
import {
  useDeleteBankAccountMutation,
  useGetAccountQuery,
  useGetAllBankDetailsMutation,
  useUpdateDefaultBankAccountMutation,
} from 'src/services/account/accountServiceApi';

export const handleSettings = () => {
  const [getAllBankDetails] = useGetAllBankDetailsMutation({});
  const { data } = useGetAccountQuery('');
  const [allBankDetails, setAllBankDetails]: any = useState(null);
  const [isLoading, setIsLoading]: any = useState(null);
  const [deleteBankAccount] = useDeleteBankAccountMutation();
  const [updateDefaultBankAccount] = useUpdateDefaultBankAccountMutation();

  useEffect(() => {
    const genBankData = async () => {
      if (data && data?.id) {
        setIsLoading(true);
        getBankDetails();
      }
    };

    genBankData();
  }, [data]);

  const deleteAccount = async (id: any) => {
    setIsLoading(true);
    await deleteBankAccount({ id });
    getBankDetails();
  };

  const updateDefaultAccount = async (id: any) => {
    setIsLoading(true);
    await updateDefaultBankAccount({ id });
    getBankDetails();
  };

  const getBankDetails = async () => {
    const bankData = await getAllBankDetails({});
    setAllBankDetails(bankData);
    setIsLoading(false);
  };

  return [allBankDetails, isLoading, deleteAccount, updateDefaultAccount, data];
};
