import { styled } from '@mui/material/styles';

export const ConnectorContainer = styled('div')(
  ({ theme }) => `
  position: relative;
  flex-direction: column;
  margin-left: ${theme.spacing(1)};
`
);

export const TopConnectorLine = styled('div')<{
  initHeight?: number;
  position?: number;
}>(
  ({ theme, initHeight = 58, position = 0 }) => `
  width: 1px;
  height: ${(initHeight + (position > 0 ? 0 : 32)) / 2}px;
  margin-left: ${theme.spacing(3)};
  background: ${theme.palette.blue[100]};
`
);

export const BottomConnectorLine = styled('div')<{
  detailsSectionHeight?: number;
  initHeight?: number;
  isActive: boolean;
}>(
  ({ theme, isActive, detailsSectionHeight = 58, initHeight = 58 }) => `
  width: 1px;
  height: ${(detailsSectionHeight + (detailsSectionHeight - initHeight) + 32) / 2}px;
  margin-left: ${theme.spacing(3)};
  background: ${isActive ? theme.palette.blue[100] : theme.palette.gray[300]};
  opacity: ${isActive ? 1 : 0.6};
  
  transition-property: height;
  transition-duration: 300ms;
  transition-timing-function: linear;
`
);

export const ConnectorDot = styled('div')<{
  initHeight?: number;
  position?: number;
}>(
  ({ theme, initHeight = 58, position = 0 }) => `
  width: 7px;
  height: 7px;
  border-radius: 50%;
  position: absolute;
  top: ${initHeight / 2 + (position > 0 ? -4 : 12)}px;
  left: ${theme.spacing(2.25)};
  background: ${theme.palette.yellow[300]};
`
);

export const SecondRow = styled('div')`
  flex-direction: row;
  flex: 1;
  display: flex;
`;

export const DetailsSection = styled('div')<{ position?: number }>(
  ({ theme, position = 0 }) => `
  flex-direction: column;
  display: flex;
  flex: 1;
  margin-top: ${theme.spacing(position === 0 ? 4 : 0)};
  margin-left: ${theme.spacing(3)};
  padding-left: ${theme.spacing(3.25)};
  padding-top: ${theme.spacing(3)};
  padding-bottom: ${theme.spacing(3)};
  padding-right: ${theme.spacing(2.75)};
  background: ${theme.palette.yellow[40]};
  border-left: 2px solid ${theme.palette.yellow[200]};
`
);

export const Container = styled('div')<{ detailsSectionHeight?: number; position?: number }>(
  ({ detailsSectionHeight = 58, position = 0 }) => `
  height: ${detailsSectionHeight + (position > 0 ? 16 : 32)}px;
  flex-direction: row;
  flex: 1;
  display: flex;
  align-items: flex-start;

  transition-property: height;
  transition-duration: 300ms;
  transition-timing-function: linear;
`
);
