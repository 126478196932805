import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import {
  AddPayeeAccountModal,
  DivAuthorizedWrapper,
  FinanceLinkedAccountsCard,
  Header,
  HeaderFixed,
  Wrapper,
  WrpperAuthorizedContainer,
  ManualLoader,
} from 'src/components/base/core';

import { useGetBlockHeight } from 'src/hooks/useGetBlockHeight';

import {
  useDeleteBankAccountMutation,
  useDeletePayeeBankAccountMutation,
  useGetAllTreasuryAccountsQuery,
  useUpdateDefaultBankAccountMutation,
  useUseGetAllTreasuryPayeeAccountsQuery,
} from 'src/services/account/accountServiceApi';

import { commonSpacing, theme } from 'src/shared/theme/theme';

export const FinanceAccountMoveMoneyLandingContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [elementRef, height] = useGetBlockHeight();

  const {
    data: linkedAccountData,
    isSuccess: isSuccessLA,
    isLoading: isLoadingLA,
  } = useGetAllTreasuryAccountsQuery(null);
  const {
    data: payeeAccountData,
    isSuccess: isSuccessPA,
    isLoading: isLoadingPA,
  } = useUseGetAllTreasuryPayeeAccountsQuery(null);

  const [deleteBankAccount, { isLoading: isLoadingDeleteBA }] = useDeleteBankAccountMutation();
  const [deletePayeeBankAccount, { isLoading: isLoadingDeletePBA }] =
    useDeletePayeeBankAccountMutation();

  const [updateDefaultBankAccount, { isLoading: isLoadingUpdateBA }] =
    useUpdateDefaultBankAccountMutation();

  const [payeeModalOpen, setPayeeModalOpen] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState<string>('');

  const addLinkedBankAccount = () => {
    const getBankAccountCount = linkedAccountData && linkedAccountData.length > 0;

    !getBankAccountCount
      ? navigate('/payment-selection', {
          state: { isFromFinance: true, isAfterKycFromTermScreen: true },
        })
      : navigate('/bank-details', {
          state: { isFromFinance: true, isAfterKycFromTermScreen: false },
        });

    setSelectedItemID('');
  };

  const deleteAccount = async (id: any) => {
    await deleteBankAccount({ id });
    setSelectedItemID('');
  };

  const deletePayeeAccount = async (id: any) => {
    await deletePayeeBankAccount({ id });
    setSelectedItemID('');
  };

  const updateDefaultAccount = async (id: any) => {
    await updateDefaultBankAccount({ id });
    setSelectedItemID('');
  };

  const addPayeeBankAccount = () => {
    setPayeeModalOpen(true);
    setSelectedItemID('');
  };

  const onClickBankCard = (item: any) => {
    setSelectedItemID(item.id);
    localStorage.setItem('link-bank-details', JSON.stringify(item));

    setTimeout(function () {
      navigate('/finance/account/move-money/transfer', {
        state: {
          accountId: item.id,
          accountType: item.bankCardType,
        },
      });
    }, 500);
  };

  const onClickLinkBankCard = (item: any) => {
    const setItemObj: any = { ...item, bankCardType: 'linked-account' };
    onClickBankCard(setItemObj);
  };

  const onClickPayeeBankCard = (item: any) => {
    const setItemObj: any = { ...item, bankCardType: 'payee-account' };
    onClickBankCard(setItemObj);
  };

  return (
    <>
      {(isLoadingLA ||
        isLoadingDeleteBA ||
        isLoadingUpdateBA ||
        isLoadingPA ||
        isLoadingDeletePBA) && <ManualLoader />}
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonHide={false}
          isBackButtonVisible
          paddingX={0}
          isLogoVisible={false}
          headerText={t('finance.MoveMoney.headerText')}
          headerPaddingBottom={3}
          withCenteredHeaderText
          onBackButtonClick={() => navigate('/finance/account/main')}
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper>
        <WrpperAuthorizedContainer>
          <DivAuthorizedWrapper>
            <Box
              ref={elementRef}
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                  minHeight: height,
                  paddingBottom: height ? 4 : commonSpacing.desktopPaddingB,
                },
              }}
            >
              <Typography
                component="p"
                variant="h3"
                sx={{
                  marginBottom: 3,
                  marginTop: 3,
                  display: 'inline-flex',
                  alignItems: 'center',
                  color: 'gray.900',
                }}
              >
                {t('finance.MoveMoney.landing.topContent')}
              </Typography>

              {isSuccessLA && (
                <FinanceLinkedAccountsCard
                  blockTitle={t('finance.MoveMoney.landing.linkedAccountHeading')}
                  linkText={t('finance.MoveMoney.landing.linkedAccountLink')}
                  onClickLink={addLinkedBankAccount}
                  bankData={linkedAccountData}
                  emptyBlockContent={t('finance.MoveMoney.landing.linkedAccountEmptyBlockContent')}
                  onDeleteAccount={deleteAccount}
                  onUpdateDefaulAccount={updateDefaultAccount}
                  onClickBankCard={onClickLinkBankCard}
                  selectedItemID={selectedItemID}
                />
              )}

              {isSuccessPA && (
                <FinanceLinkedAccountsCard
                  blockTitle={t('finance.MoveMoney.landing.payeeAccountHeading')}
                  linkText={t('finance.MoveMoney.landing.payeeAccountLink')}
                  emptyBlockContent={t('finance.MoveMoney.landing.payeeAccountEmptyBlockContent')}
                  bankData={payeeAccountData}
                  blockMarginBottom={6}
                  onClickLink={addPayeeBankAccount}
                  onClickBankCard={onClickPayeeBankCard}
                  selectedItemID={selectedItemID}
                  onDeleteAccount={deletePayeeAccount}
                  isPayeeType
                />
              )}
            </Box>
          </DivAuthorizedWrapper>
        </WrpperAuthorizedContainer>
      </Wrapper>

      {payeeModalOpen && (
        <AddPayeeAccountModal closeIdModalEvent={() => setPayeeModalOpen(false)} t={t} />
      )}
    </>
  );
};
