import { Box } from '@mui/system';
import React, {
  ChangeEvent,
  ClipboardEvent,
  createRef,
  InputHTMLAttributes,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { VerifyConfirmationIcon } from '../../Media/Icon/Icons/Icons';
import { Messages } from '../../MessageElements/Messages/Messages';
import { ResendCode } from '../..';
import { FormInputContainer, FormInputWrapper } from './VerificationCodeInput.style';

export interface ReactInputVerificationCodeProps {
  autoFocus?: boolean;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  length?: number;
  onChange?: (data: string) => void;
  onCompleted?: (data: string) => void;
  placeholder?: string;
  type?: 'alphanumeric' | 'number';
  value?: string;
  error?: any;
  isVerified?: any;
  errorMsg?: string;
  isWrong?: boolean;
  coupaError?: boolean;
  isValid?: boolean;
  onClickResendOtp?: any;
  enableResend?: boolean;
}

export const ReactInputVerificationCode = ({
  autoFocus = true,
  inputProps,
  length = 6,
  onChange = () => null,
  onCompleted = () => null,
  placeholder = '',
  type = 'number',
  value: defaultValue = '',
  error,
  isValid,
  isVerified = false,
  errorMsg,
  isWrong,
  coupaError,
  onClickResendOtp,
  enableResend = false,
}: ReactInputVerificationCodeProps) => {
  const { t } = useTranslation();

  const fillValues = (value: string) =>
    new Array(length).fill('').map((_, index) => value[index] ?? '');

  const [values, setValues] = useState(fillValues(defaultValue));
  const [focusedIndex, setFocusedIndex] = useState<number>(-1);
  const [isResend, setIsResend] = useState(false);

  const inputsRefs = useMemo(
    () => new Array(length).fill(null).map(() => createRef<HTMLInputElement>()),
    [length]
  );

  useEffect(() => {
    isResend &&
      setTimeout(() => {
        setIsResend(false);
      }, 5000);
  }, [isResend]);

  const validate = (input: string) => {
    if (type === 'number') {
      return /^\d/.test(input);
    }

    if (type === 'alphanumeric') {
      return /^[a-zA-Z0-9]/.test(input);
    }

    return true;
  };

  const selectInputContent = (index: number) => {
    const input = inputsRefs[index].current;

    if (input) {
      requestAnimationFrame(() => {
        input.select();
      });
    }
  };

  const setValue = (value: string, index: number) => {
    const nextValues = [...values];
    nextValues[index] = value;

    setValues(nextValues);

    const stringifiedValues = nextValues.join('');
    const isCompleted = stringifiedValues.length === length;

    if (isCompleted) {
      onCompleted(stringifiedValues);
      return;
    }

    onChange(stringifiedValues);
  };

  const focusInput = useCallback(
    (index: number) => {
      const input = inputsRefs[index]?.current;

      if (input) {
        requestAnimationFrame(() => {
          input.focus();
        });
      }
    },
    [inputsRefs]
  );

  const blurInput = (index: number) => {
    const input = inputsRefs[index]?.current;
    if (input) {
      requestAnimationFrame(() => {
        input.blur();
      });
    }
  };

  const onInputFocus = (index: number) => {
    const input = inputsRefs[index]?.current;
    if (input) {
      setFocusedIndex(index);
    }
    if (index !== 0 && values.findIndex((e: any) => e > -1) === -1) {
      setFocusedIndex(0);
    }
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const eventValue = event.target.value;
    if (eventValue === '') {
      setValue('', focusedIndex);
      focusInput(index - 1 > 0 ? index - 1 : 0);

      return;
    }
    /**
     * ensure we only display 1 character in the input
     * by clearing the already setted value
     */
    const value = eventValue.replace(values[index], '');
    /**
     * if the value is not valid, don't go any further
     * and select the content of the input for a better UX
     */
    if (!validate(value)) {
      selectInputContent(index);
      return;
    }

    /**
     * otp code
     */
    if (value.length > 1) {
      setValues(fillValues(eventValue));

      const isCompleted = eventValue.length === length;

      if (isCompleted) {
        onCompleted(eventValue);
        blurInput(index);
        return;
      }

      return;
    }

    setValue(value, index);

    /**
     * if the input is the last of the list
     * blur it, otherwise focus the next one
     */
    if (index === length - 1) {
      blurInput(index);
      return;
    }

    focusInput(index + 1);
  };

  const onInputKeyDown = (event: KeyboardEvent<HTMLInputElement>, index: number) => {
    const eventKey = event.key;
    if (eventKey === 'Backspace' || eventKey === 'Delete') {
      /**
       * prevent trigger a change event
       * `onInputChange` won't be called
       */
      event.preventDefault();

      setValue('', focusedIndex);
      focusInput(index - 1);

      return;
    }
    /**
     * since the value won't change, `onInputChange` won't be called
     * only focus the next input
     */
    if (eventKey === values[index]) {
      focusInput(index + 1);
    }
  };

  const onInputPaste = (event: ClipboardEvent<HTMLInputElement>, index: number) => {
    event.preventDefault();
    const pastedValue = event.clipboardData.getData('text');
    const nextValues = pastedValue.slice(0, length);

    if (!validate(nextValues)) {
      return;
    }

    setValues(fillValues(nextValues));

    const isCompleted = nextValues.length === length;

    if (isCompleted) {
      onCompleted(nextValues);
      blurInput(index);
      return;
    }

    focusInput(nextValues.length);
  };

  /**
   * autoFocus
   */
  useEffect(() => {
    if (autoFocus) {
      focusInput(0);
    }
  }, [autoFocus, focusInput, inputsRefs]);

  return (
    <>
      <FormInputWrapper>
        <FormInputContainer>
          {inputsRefs.map((ref, i) => (
            <input
              autoComplete="one-time-code"
              key={i}
              onChange={(event) => onInputChange(event, i)}
              onFocus={() => onInputFocus(i)}
              onKeyDown={(event) => onInputKeyDown(event, i)}
              onPaste={(event) => onInputPaste(event, i)}
              placeholder={placeholder}
              ref={ref}
              value={values[i]}
              {...inputProps}
              inputMode="numeric"
              className={error && !isValid ? 'is-error' : isValid ? 'is-valid' : 'no-error'}
            />
          ))}
        </FormInputContainer>
        {isVerified && !coupaError && (
          <Box sx={{ marginTop: 12 }}>
            <VerifyConfirmationIcon />
          </Box>
        )}
      </FormInputWrapper>
      {/***/}
      {enableResend && !isVerified && (
        <ResendCode
          onClick={() => {
            setValues(fillValues(defaultValue));
            onClickResendOtp();
            setIsResend(true);
          }}
          isResend={isResend}
        />
      )}

      {(isWrong || coupaError) && (
        <Messages
          messageHeading={t('errorMessages.verifyCodeError.heading')}
          messageContent={errorMsg}
          messageLinkText={t('linkTexts.sendAgainLink')}
          messageLinkEvent={() => {
            setValues(fillValues(defaultValue));
            onClickResendOtp();
          }}
        />
      )}
    </>
  );
};
