import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  Header,
  HeadingSummaryBlock,
  FormWrapper,
  ContentContainerBottom,
  FormInput,
  FormInputPhoneNumber,
  Messages,
  FormSelectionVerifyBusinessStructure,
  ManualLoader,
} from 'src/components/base/core';

import { dtoToBusinessOwnershipRepFormValues } from 'src/shared/utilities/dto';
import { useGetBlockHeight } from 'src/hooks/useGetBlockHeight';
import { theme, commonSpacing } from 'src/shared/theme/theme';
import { AccountParams } from 'src/shared/utilities/interface';
import { getCookie } from 'src/shared/utilities/cookiesHelper';

import { handleBusinessRepOwnership } from 'src/helpers/containers/helperBusinessOwnership';
import { setCoupaInfoValuesForEmailLogin } from 'src/helpers/setCoupaValues';
import { businessVerifyOwnershipSchemaObj } from 'src/helpers/validationSchemas';
import { IRelationShip } from 'src/models/api/account';
import { initialRelationShipObject } from 'src/models/init/account.init';

export const FromVerifyRepresentSubmition = ({
  onBackButtonClick,
  onFormSubmit,
  accountData,
  onSkipSecondStep,
  isFromFinance,
}: any) => {
  // handle the featured flags __
  const { webauthnEnabled } = useFlags();
  // set the translation __
  const { t } = useTranslation();
  // call the handleBusinessRepOwnership(); helper function and it's return values __
  const [appSubmit, isLoading, onChangeHandlerPhone, serverSideErrors, setServerSideErrors]: any =
    handleBusinessRepOwnership(accountData);
  // call the useGetBlockHeight() custom hook to handle the container height in desktop view __
  const [elementRef, height] = useGetBlockHeight();

  // initialize the states __
  const [radioChecked, setRadioChecked] = useState<string>(''); // used to set the selected radio box id  (owner | executive), based on this radio button selection will handled
  const [btnSubmitted, setBtnSubmitted] = useState<boolean>(false); // used to make sure the button got submitted
  const [errorOwnership, setErrorOwnership] = useState<string>(''); // used to handle the error message of radio button selection
  const [relationshipObj, setRelationshipObj] = useState<IRelationShip>(initialRelationShipObject);
  const [perCentageOwnershipConfirmed, setPerCentageOwnershipConfirmed] = useState(false);

  // handle re-renders and inital load the values in to form elements based on the accountData load__
  useEffect(() => {
    if (accountData) {
      const isSoleOwnerCompanyStructure =
        accountData?.company_structure === 'sole_proprietorship' ? true : false;

      if (isSoleOwnerCompanyStructure) {
        setRadioChecked('yes');
        setRelationshipObj({
          director: true, // This person is a member of the governing board of the company: relationship[director]
          executive: true, // This person is an executive or senior manager with significant management responsibility: relationship[executive]
          owner: true, // This person owns 25% or more of the company: relationship[owner]
          percent_ownership: 100,
          representative: true, // This person is authorized as the primary representative of the account: relationship[representative]
        });
        setValue('company_structure', 'sole_proprietorship');
        setPerCentageOwnershipConfirmed(false);
      } else {
        setRadioChecked('no');
        setValue('company_structure', null);

        const getRelaObj = accountData?.individual?.relationShip;

        const isPercentageConfirmButtonClicked =
          !getRelaObj?.director &&
          getRelaObj?.executive &&
          getRelaObj?.owner &&
          getRelaObj?.representative &&
          getRelaObj?.percent_ownership === 25;

        if (isPercentageConfirmButtonClicked) {
          setPerCentageOwnershipConfirmed(true);
          setRelationshipObj({
            director: false, // This person is a member of the governing board of the company: relationship[director]
            executive: true, // This person is an executive or senior manager with significant management responsibility: relationship[executive]
            owner: true, // This person owns 25% or more of the company: relationship[owner]
            percent_ownership: 25,
            representative: true, // This person is authorized as the primary representative of the account: relationship[representative]
          });
        } else {
          setPerCentageOwnershipConfirmed(false);
          setRelationshipObj({
            director: false, // This person is a member of the governing board of the company: relationship[director]
            executive: true, // This person is an executive or senior manager with significant management responsibility: relationship[executive]
            owner: false, // This person owns 25% or more of the company: relationship[owner]
            percent_ownership: null,
            representative: true, // This person is authorized as the primary representative of the account: relationship[representative]
          });
        }
      }

      // set the rest the values into relavant field
      dtoToBusinessOwnershipRepFormValues(getValues, setValue, accountData);

      // set the full name, email, phone from cookies
      if (!accountData.individual.email && getCookie('coupaObj')) {
        if (webauthnEnabled) {
          setCoupaInfoValuesForEmailLogin(setValue);
        }
      }
    }
  }, [accountData]);

  // handle the form elements validation using yup __
  const revieInfoSchema = yup.object({ ...businessVerifyOwnershipSchemaObj }).required();

  // initialize the hook-forms __
  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<AccountParams>({
    resolver: yupResolver(revieInfoSchema),
  });

  // handle the form submit __
  const onAppFormSubmit = handleSubmit(async (data: any) => {
    const updatedData = {
      ...relationshipObj,
      ...data,
    };

    if (isFromFinance) {
      if (radioChecked !== '' && perCentageOwnershipConfirmed) {
        appSubmit(updatedData, onFormSubmit);
        setErrorOwnership('');
      } else {
        if (radioChecked === '' && perCentageOwnershipConfirmed) {
          setErrorOwnership('Please provide more information regarding ownership of the company.');
        } else if (radioChecked === 'no' && !perCentageOwnershipConfirmed) {
          setErrorOwnership(
            'Please ensure the representative has a 25% or more ownership in the company.'
          );
        } else if (radioChecked === 'yes' && !perCentageOwnershipConfirmed) {
          appSubmit(updatedData, onFormSubmit);
          setErrorOwnership('');
        }
      }
    } else {
      if (radioChecked !== '') {
        appSubmit(
          updatedData,
          radioChecked === 'no' && !isFromFinance ? onSkipSecondStep : onFormSubmit
        );
        setErrorOwnership('');
      } else {
        setErrorOwnership('Please provide more information regarding ownership of the company.');
      }
    }
  });

  // update the values via hookform setValue method __
  const updateBindingsChange = (event: any, feild: any) => {
    setValue(feild, event.target.value, { shouldValidate: btnSubmitted && true });
  };

  // handle the phone number onchange event __
  const onChangeHandler = (event: any) => {
    onChangeHandlerPhone(event, setValue);
  };

  // handle the radio buttons related to business tructure and relationship __
  const handleRep = (val: any) => {
    const { id } = val.target; // get the id from selected element
    setRadioChecked(id); // set the setRadioChecked state

    if (id === 'yes') {
      setRelationshipObj({
        director: true, // This person is a member of the governing board of the company: relationship[director]
        executive: true, // This person is an executive or senior manager with significant management responsibility: relationship[executive]
        owner: true, // This person owns 25% or more of the company: relationship[owner]
        percent_ownership: 100,
        representative: true, // This person is authorized as the primary representative of the account: relationship[representative]
      });
      setValue('company_structure', 'sole_proprietorship'); // if selected target id is 'owner' set the company_structure value in to form-data output
      setPerCentageOwnershipConfirmed(false);
    } else if (id === 'no') {
      setRelationshipObj({
        director: false, // This person is a member of the governing board of the company: relationship[director]
        executive: true, // This person is an executive or senior manager with significant management responsibility: relationship[executive]
        owner: false, // This person owns 25% or more of the company: relationship[owner]
        percent_ownership: null,
        representative: true, // This person is authorized as the primary representative of the account: relationship[representative]
      });
      setValue('company_structure', null); // if selected target id is 'executive' set the company_structure value in to form-data output
    }
  };

  const onChnageOwnerPercentage = (val: any) => {
    if (val.target.checked) {
      setPerCentageOwnershipConfirmed(true);
      setRelationshipObj({
        director: false, // This person is a member of the governing board of the company: relationship[director]
        executive: true, // This person is an executive or senior manager with significant management responsibility: relationship[executive]
        owner: true, // This person owns 25% or more of the company: relationship[owner]
        percent_ownership: 25,
        representative: true, // This person is authorized as the primary representative of the account: relationship[representative]
      });
    } else {
      setPerCentageOwnershipConfirmed(false);

      if (radioChecked === 'yes') {
        setRelationshipObj({
          director: true, // This person is a member of the governing board of the company: relationship[director]
          executive: true, // This person is an executive or senior manager with significant management responsibility: relationship[executive]
          owner: true, // This person owns 25% or more of the company: relationship[owner]
          percent_ownership: 100,
          representative: true, // This person is authorized as the primary representative of the account: relationship[representative]
        });
        setValue('company_structure', 'sole_proprietorship'); // if selected target id is 'owner' set the company_structure value in to form-data output
      } else if (radioChecked === 'no') {
        setRelationshipObj({
          director: false, // This person is a member of the governing board of the company: relationship[director]
          executive: true, // This person is an executive or senior manager with significant management responsibility: relationship[executive]
          owner: false, // This person owns 25% or more of the company: relationship[owner]
          percent_ownership: null,
          representative: true, // This person is authorized as the primary representative of the account: relationship[representative]
        });
        setValue('company_structure', null); // if selected target id is 'executive' set the company_structure value in to form-data output
      }
    }
  };

  return (
    <>
      {isLoading && <ManualLoader />}
      <FormWrapper onSubmit={onAppFormSubmit} className="no_fullscreen">
        <Box component={'div'} className={'container_content'}>
          <Box
            component={'div'}
            className={'container_content_top'}
            ref={elementRef}
            sx={{
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                minHeight: height,
                paddingBottom: height ? 4 : commonSpacing.desktopPaddingB,
              },
            }}
          >
            <Header
              isBackButtonVisible={true}
              onBackButtonClick={onBackButtonClick}
              isProgressBarVisible={true}
              totalProgressSteps={6}
              currentProgressStep={2}
              paddingX={0}
            />
            <Box sx={{ marginBottom: 6 }}>
              <HeadingSummaryBlock
                heading={t('headingContent.business.ownerInfo.heading')}
                headingAlignment="left"
                content={t('headingContent.business.ownerInfo.content')}
                contentAlignment="left"
              />
            </Box>
            <Controller
              control={control}
              name="first_name"
              render={({ field: { value } }) => (
                <FormInput
                  label={t('formInput.firstName')}
                  id={'first_name'}
                  placeholder={t('placeHolder.firstName')}
                  {...register('first_name')}
                  error={errors?.first_name?.message}
                  onChange={(event: any) => {
                    event.target.value = event.target.value.replace('  ', ' ');
                    updateBindingsChange(event, 'first_name');
                  }}
                  value={value || ''}
                  inputFocus={(e: any) => {
                    trigger('first_name');
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="last_name"
              render={({ field: { value } }) => (
                <FormInput
                  label={t('formInput.lastName')}
                  id={'last_name'}
                  placeholder={t('placeHolder.lastName')}
                  error={errors?.last_name?.message}
                  {...register('last_name')}
                  onChange={(event: any) => {
                    event.target.value = event.target.value.replace('  ', ' ');
                    updateBindingsChange(event, 'last_name');
                  }}
                  value={value || ''}
                  inputFocus={(e: any) => {
                    trigger('last_name');
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="email"
              render={({ field: { value } }) => (
                <FormInput
                  label={t('formInput.email')}
                  id={'email'}
                  placeholder={t('placeHolder.emailAddress')}
                  {...register('email')}
                  inputMode="email"
                  error={errors?.email?.message}
                  onChange={(event: any) => {
                    updateBindingsChange(event, 'email');
                  }}
                  value={value || ''}
                  inputFocus={(e: any) => {
                    trigger('email');
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="phone_number"
              render={({ field: { value } }) => (
                <FormInputPhoneNumber
                  label={t('formInput.phone')}
                  id={'phone'}
                  placeholder={'(123) 543-3454'}
                  {...register('phone_number')}
                  inputError={errors.phone_number?.message}
                  onChange={onChangeHandler}
                  inputMode="numeric"
                  value={value || ''}
                  inputFocus={(e: any) => {
                    trigger('phone_number');
                  }}
                />
              )}
            />

            <FormSelectionVerifyBusinessStructure
              radioChecked={radioChecked}
              handleRep={handleRep}
              t={t}
              ownershipPercentageSelectionEnabled={isFromFinance && radioChecked === 'no'}
              chnageOwnerPercentage={onChnageOwnerPercentage}
              ownerPercentageChecked={perCentageOwnershipConfirmed}
            />

            {serverSideErrors && (
              <Messages
                topMargin={0}
                bottomMargin={2}
                messageHeading={
                  serverSideErrors && serverSideErrors.includes('phone')
                    ? t('errorMessages.phoneUniqueError.heading')
                    : serverSideErrors.includes('email')
                    ? t('errorMessages.emailUniqueError.heading')
                    : serverSideErrors
                }
                messageContent={
                  serverSideErrors.includes('phone')
                    ? t('errorMessages.phoneUniqueError.content')
                    : serverSideErrors.includes('email')
                    ? t('errorMessages.emailUniqueError.content')
                    : 'Please provide the valid details to use PayUp.'
                }
                closeEvent={() => setServerSideErrors('')}
              />
            )}

            {errorOwnership && errorOwnership !== '' && (
              <Messages
                topMargin={0}
                bottomMargin={0}
                messageHeading={'A required field is missing'}
                messageContent={errorOwnership}
                closeEvent={() => setErrorOwnership('')}
              />
            )}
          </Box>
        </Box>
        <ContentContainerBottom className={'no_fullscreen'}>
          <Button
            variant="containedLarge"
            color="primary"
            type="submit"
            fullWidth
            sx={{ overflow: 'hidden' }}
            onClick={() => {
              setBtnSubmitted(true);
            }}
            // disabled={buttonState}
          >
            {t('buttonTexts.continueButton')}
          </Button>
        </ContentContainerBottom>
      </FormWrapper>
    </>
  );
};
