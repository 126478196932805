import { createGlobalStyle } from 'styled-components';
import { BackgroundImages } from '../utilities/images';

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  @import-normalize; 

  * {
   box-sizing: border-box;
   text-decoration: none;
   outline: 0;
   font-family: 'Public Sans', sans-serif;
  }

  html {
   --black: #000000;
   --black100: #1A1F36;
   --black75PercentOff: rgba(0, 0, 0, 0.25);
   --white: #FFFFFF;
   --primaryBlue: #635BFF;
   --primaryBlue50PercentOff: rgba(99, 91, 255, 0.05);
   --Gray100:#C9C9C9;
   --Gray600:#676b7d;
   --darkGrey: #272727;
   --darkSlateGray: #3C4257;
   --cyanBlue: #005eff;
   --darkSlateGray100: #323232;
   --lightGrey: #DFDFDF;
   --lightGrey100: #E3E8EE;
   --lightGrey200: #ADB5BD;
   --lightGrey300: #4E4F5E;
   --lightGrey400: #A3ACBA;
   --lightGrey500: #656565;
   --lightGrey600: #979797;
   --lightGrey700: #787579;
   --errorRed: #FF0000;
   --correctGreen: #03CC7A;
   --grey400 : #697386;
   --lightBlue : #98A8FA;
   --lightBlue100: #3152F5;
   --errorBg: #FFF4FA;
   --errorText: #4E515F;
   --warningBg: #FEF9DA;
   --warningOrange: #ED6802;
   --gray800:#2C2D30;
 }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Public Sans', sans-serif;
    box-sizing: border-box;
    overflow: hidden;
  }

  .hidden-mobile {
    @media(max-width:600px){
      display: none !important;
    }
  }

  .hidden-desktop {
    @media(min-width:600px){
      display: none !important;
    }
  }

  .visible_hidden {
    visibility: hidden;
  }

  .hidden_all {
    display: none !important;
  }

  @media(min-width: 600px){
    body {
      background: linear-gradient(181.57deg, #E2E9FF -39.28%, #FFFFFF 49.33%);
      background-repeat: no-repeat;
    }
  }

  @media(max-width: 600px){
    body {
      background: linear-gradient(181.57deg, #E2E9FF -39.28%, #FFFFFF 49.33%);
      background-repeat: no-repeat;

      > #root {
        background-image : url(${BackgroundImages.backgrounTenPercentOpacity});
        background-position: center top;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
`;
