import React, { Fragment, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import {
  ModalWrapper,
  ModalOverlay,
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalButton,
  ModalTermCardLeft,
  ModalTermCardRight,
  ModalTermCardWrapper,
  ModalClose,
  CustomerAdditionConfirmModalIconWrapper,
  BottomSpace,
} from './ModalTermEdit.style';

import {
  reOrderingTheTerms,
  getTermDueDays,
  searchFromArray,
} from '../../../../../helpers/helperHandlingTerms';
import { handleTheTermModalErrorMessage } from '../../../../../helpers/helperHandlingTerms';
import { useCurrencyFormat } from '../../../../../hooks/useCurrencyFormat';
import { Icon, ShadowCard } from '../..';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Messages } from 'src/components/base/core';
import { TermCardTimeLine } from '../../Cards/TermsCard/TermCardTimeLine/TermCardTimeLine';
import { ConfirmIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';

const domNode: any = document.getElementById('app-root');

export interface ModalTermEditProps {
  onConfirmButtonClick?: () => void;
  isConfirmed?: boolean;
  onModalClose?: () => void;
  onYesButtonClick?: () => void;
  onMessageClose?: () => void;
  options?: any;
  onTermChange?: any;
  updatedTerm?: any;
  isConfirmButtonDisable?: boolean;
  error?: boolean;
  isEditingOldInvoices?: boolean;
  isBulkEditableEnable?: boolean;
  selectedTerm?: string;
}

export const ModalTermEdit = ({
  isConfirmed = false,
  onModalClose,
  options,
  onTermChange,
  onConfirmButtonClick,
  onYesButtonClick,
  onMessageClose,
  updatedTerm,
  isConfirmButtonDisable,
  error,
  isEditingOldInvoices,
  isBulkEditableEnable,
  selectedTerm,
}: ModalTermEditProps) => {
  const { t } = useTranslation();
  const sorted = reOrderingTheTerms(options);
  const setDueDays = getTermDueDays(updatedTerm);
  const getTermslength = sorted && sorted.length;
  const oldTermId = 'old_net30';
  const getConfirmationForOldTerm =
    getTermslength === 1 ? searchFromArray(oldTermId, sorted) : false;
  const getConfirmationForSameTerm =
    getTermslength === 1 ? searchFromArray(updatedTerm, sorted) : false;

  const [getTermModalErrorObj, setTermModalErrorObj] = useState(
    handleTheTermModalErrorMessage(error, getConfirmationForOldTerm, getConfirmationForSameTerm)
  );

  useEffect(() => {
    setTermModalErrorObj(
      handleTheTermModalErrorMessage(error, getConfirmationForOldTerm, getConfirmationForSameTerm)
    );
  }, [error]);

  return ReactDOM.createPortal(
    <>
      <ModalWrapper>
        <ModalOverlay onClick={onModalClose} />
        {!isConfirmed && (
          <ModalContainer className="is_fixed">
            <ModalClose onClick={onModalClose}>
              <Icon iconType="CloseIcon" />
            </ModalClose>
            <ModalHeader>
              <Typography
                variant="h4ExtraBold"
                color="gray.800"
                component={'p'}
                sx={{ marginBottom: 4 }}
              >
                {t('invoiceSummary.top.termModal.title')}
              </Typography>
              <Typography variant="body2" color="gray.800" component={'p'}>
                {isEditingOldInvoices &&
                  !isBulkEditableEnable &&
                  t('invoiceSummary.top.termModal.editBoulkNotOld')}
                {isEditingOldInvoices &&
                  isBulkEditableEnable &&
                  t('invoiceSummary.top.termModal.editBulck')}
                {!isEditingOldInvoices && t('invoiceSummary.top.termModal.editOld')}
              </Typography>
            </ModalHeader>
            {getTermModalErrorObj.message && (
              <Messages
                type={getTermModalErrorObj.type}
                messageHeading={getTermModalErrorObj.title}
                messageContent={getTermModalErrorObj.message}
                closeEvent={
                  onMessageClose
                    ? onMessageClose
                    : () => setTermModalErrorObj(handleTheTermModalErrorMessage(null))
                }
              />
            )}
            <ModalContent>
              <ShadowCard paddingX={4} paddingTop={6} paddingBottom={4}>
                {/**Modal term card*/}
                {options &&
                  sorted?.map((termOption: any, index: any) => {
                    return (
                      <Fragment key={index + '-wrap'}>
                        <ModalTermCardWrapper
                          key={index + termOption.id}
                          className={termOption.isAvailable ? 'active' : 'in_active'}
                        >
                          {getConfirmationForOldTerm && (
                            <input
                              type="radio"
                              id={termOption.id}
                              name="netTerms"
                              value={termOption.id}
                              onChange={onTermChange}
                              checked={true}
                            />
                          )}
                          {!getConfirmationForOldTerm && (
                            <input
                              type="radio"
                              id={termOption.id}
                              name="netTerms"
                              value={termOption.id}
                              onChange={onTermChange}
                            />
                          )}
                          <label
                            htmlFor={termOption.id}
                            className={
                              // eslint-disable-next-line
                              selectedTerm == termOption.id ? 'default_label' : 'non_default_label'
                            }
                          >
                            {termOption.id !== 'net30' && termOption.id !== 'old_net30' && (
                              <>
                                <ModalTermCardLeft
                                  className={
                                    isBulkEditableEnable ? 'is_full_width' : 'is_not_full_width'
                                  }
                                >
                                  <div className="visible_days">
                                    <Typography
                                      variant="h2ExtraBold"
                                      color={'primary.main'}
                                      component="p"
                                    >{`${termOption.due_days} ${t(
                                      'termsSelection.termCardDays'
                                    )} `}</Typography>
                                  </div>
                                  <div className="fee_details">
                                    {isEditingOldInvoices && (
                                      <span className="discount_percent">
                                        {termOption.processing_fee !== 0 &&
                                          termOption.processing_fee * 2 + '%'}
                                      </span>
                                    )}
                                    <span className="discount_value">
                                      <span className="amount_text">
                                        {termOption.processing_fee}%
                                      </span>
                                      <span className="fee_text">Fee</span>
                                    </span>
                                    {termOption.isAccountDefault && (
                                      <span className="default_tag">
                                        {t('termsSelection.default')}
                                      </span>
                                    )}
                                  </div>
                                </ModalTermCardLeft>
                                {!isBulkEditableEnable && (
                                  <ModalTermCardRight>
                                    <span>{useCurrencyFormat(termOption.payout_amount)}</span>
                                  </ModalTermCardRight>
                                )}
                              </>
                            )}

                            {termOption.id === 'net30' && (
                              <>
                                <ModalTermCardLeft
                                  className={
                                    isBulkEditableEnable ? 'is_full_width' : 'is_not_full_width'
                                  }
                                >
                                  <div className="visible_days">
                                    <Typography
                                      variant="h2ExtraBold"
                                      color={'primary.main'}
                                      component="p"
                                    >{`${t(
                                      'termsSelection.termNoEalryPayoutHeading'
                                    )} `}</Typography>
                                    <Typography variant="h1" color={'primary.main'} component="p">
                                      {t('termsSelection.termNoEalryPayoutContent', {
                                        Fee: termOption.due_days,
                                      })}
                                    </Typography>
                                  </div>
                                  <div className="fee_details">
                                    {isEditingOldInvoices && (
                                      <span className="discount_percent">
                                        {termOption.processing_fee !== 0 &&
                                          termOption.processing_fee * 2 + '%'}
                                      </span>
                                    )}
                                    <span className="discount_value">
                                      <span className="amount_text zero_amount">
                                        {termOption.processing_fee}%
                                      </span>
                                      <span className="fee_text">Fee</span>
                                    </span>
                                    {termOption.isAccountDefault && (
                                      <span className="default_tag">
                                        {t('termsSelection.default')}
                                      </span>
                                    )}
                                  </div>
                                </ModalTermCardLeft>
                                {!isBulkEditableEnable && (
                                  <ModalTermCardRight>
                                    <span>{useCurrencyFormat(termOption.payout_amount)}</span>
                                  </ModalTermCardRight>
                                )}
                              </>
                            )}

                            {termOption.id === 'old_net30' && (
                              <>
                                <ModalTermCardLeft
                                  className={
                                    isBulkEditableEnable ? 'is_full_width' : 'is_not_full_width'
                                  }
                                >
                                  <div className="visible_days">
                                    <Typography variant="h1" color={'primary.main'} component="p">
                                      {termOption.name}
                                    </Typography>
                                  </div>
                                  <div className="fee_details">
                                    {isEditingOldInvoices && (
                                      <span className="discount_percent">
                                        {termOption.processing_fee !== 0 &&
                                          termOption.processing_fee * 2 + '%'}
                                      </span>
                                    )}
                                    <span className="discount_value">
                                      <span className="amount_text zero_amount">
                                        {termOption.processing_fee}%
                                      </span>
                                      <span className="fee_text">Fee</span>
                                    </span>
                                    {termOption.isAccountDefault && (
                                      <span className="default_tag">
                                        {t('termsSelection.default')}
                                      </span>
                                    )}
                                  </div>
                                </ModalTermCardLeft>
                                {!isBulkEditableEnable && (
                                  <ModalTermCardRight>
                                    <span>{useCurrencyFormat(termOption.payout_amount)}</span>
                                  </ModalTermCardRight>
                                )}
                              </>
                            )}
                          </label>
                          <div className="card_timeline">
                            <TermCardTimeLine getPaidDuration={termOption.due_days} isModalLayout />
                          </div>
                        </ModalTermCardWrapper>
                      </Fragment>
                    );
                  })}
              </ShadowCard>
            </ModalContent>
            <BottomSpace />
            <ModalButton>
              {!getConfirmationForOldTerm && !getConfirmationForSameTerm && (
                <Button
                  variant="containedLarge"
                  color="primary"
                  type="button"
                  fullWidth
                  onClick={onConfirmButtonClick}
                  sx={{ overflow: 'hidden' }}
                  disabled={isConfirmButtonDisable}
                >
                  {'Submit'}
                </Button>
              )}
              {(getConfirmationForOldTerm || getConfirmationForSameTerm) && (
                <Button
                  variant="containedLarge"
                  color="primary"
                  type="button"
                  fullWidth
                  onClick={onModalClose}
                  sx={{ overflow: 'hidden' }}
                >
                  {'Submit'}
                </Button>
              )}
            </ModalButton>
          </ModalContainer>
        )}

        {isConfirmed && (
          <ModalContainer className="confirmation_container">
            <CustomerAdditionConfirmModalIconWrapper marginBottom={6}>
              <ConfirmIcon />
            </CustomerAdditionConfirmModalIconWrapper>
            <Box marginBottom={6} paddingX={2}>
              <Stack spacing={3}>
                <Typography variant={'h4ExtraBold'} textAlign={'center'}>
                  {'Net Terms Updated!'}
                </Typography>
                <Typography variant="body2" textAlign="center" color={'gray.700'}>
                  {`Your payment for the selected invoice will be made in ${setDueDays} days.`}
                </Typography>
              </Stack>
            </Box>
            <Box marginBottom={2} justifyContent={'center'} display={'flex'}>
              <Button
                variant="containedLarge"
                color="primary"
                type="button"
                fullWidth
                onClick={onYesButtonClick}
                sx={{ overflow: 'hidden', width: 120 }}
              >
                {'Done'}
              </Button>
            </Box>
          </ModalContainer>
        )}
      </ModalWrapper>
    </>,
    domNode
  );
};
