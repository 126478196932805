import { FirstCell, SecondCell, ThirdCell } from '../TbodyCells';

export const COLUMNS = (title1 = 'TRANSACTION', title2 = 'DATE', title3 = 'AMOUNT') => {
  return [
    {
      Header: title1,
      accessor: 'custom_description',
      // Set a custom filter for age
      canFilter: true,
      Cell: (props: any) => {
        return <FirstCell props={props} />;
      },
    },
    {
      Header: title2,
      accessor: 'created',
      Cell: (props: any) => {
        return <SecondCell props={props} />;
      },
    },
    {
      Header: title3,
      accessor: 'amount',
      Cell: (props: any) => {
        return <ThirdCell props={props} />;
      },
    },
  ];
};
