import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';

import { useForm, Controller } from 'react-hook-form';
import { BankDetailsParams, BankandTermsLocationState } from '../../../shared/utilities/interface';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ERROR_MESSAGES } from '../../../constants/Validation';
import { getAppInit } from '../../../services/init/init.service';

import { BankLoginContainer } from './BankLogin/BankLogin';

import { Box, Button } from '@mui/material';
import {
  Header,
  Wrapper,
  HeadingSummaryBlock,
  FormWrapper,
  ContentContainerBottom,
  TermsText,
  FormInput,
  Messages,
  ShadowCard,
} from 'src/components/base/core';
import { BankSetupInfoContainer } from './BankSetupInfo/BankSetupInfo';

import { handleBankSetupNavigation } from 'src/helpers/containers/helperBankSetup';

import { useGetConfigQuery } from '../../../services/config/configAPI';
import {
  useCreateBankAccountMutation,
  useCreateTreasuryAccountMutation,
} from 'src/services/account/accountServiceApi';
import { commonSpacing, theme } from 'src/shared/theme/theme';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { getMyAccountHeader, nativeBridgeTypes } from 'src/shared/config/config';

export const BankDetailsContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const account = useSelector((state: RootState) => state.account);
  const serverErrors = useSelector((state: RootState) => state.serverError);
  const [addAccountError, setAddAccountError] = useState('');
  const [createBankAccount, { isLoading: isLoadingBankAccount, error: bankAccountError }] =
    useCreateBankAccountMutation();
  const [createFinanceBankAccount, { isLoading: isLoadingFinaceBA, error: financeBAError }] =
    useCreateTreasuryAccountMutation();

  const bankError = bankAccountError ?? financeBAError ?? undefined;
  const isLoading = isLoadingBankAccount || isLoadingFinaceBA;

  const [
    containersVisible,
    handleClickBankManual,
    handleClickContinueToBankDetails,
    handleBackButtonClick,
  ] = handleBankSetupNavigation();

  const {
    edit,
    fromSettings,
    fromHome,
    isSettingDesktop,
    isFromFinance,
    isAfterKycFromTermScreen,
    isFromPayMode,
  } =
    location?.state != null
      ? (location?.state as BankandTermsLocationState)
      : {
          edit: null,
          fromSettings: false,
          fromHome: false,
          isSettingDesktop: false,
          isFromFinance: false,
          isAfterKycFromTermScreen: false,
          isFromPayMode: false,
        };

  const schema = yup
    .object({
      account_holder_name: yup.string().required(ERROR_MESSAGES.REQUIRED),
      account_number: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .min(5, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID)
        .max(17, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID),
      routing_number: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .min(9, ERROR_MESSAGES.SSN_INVALID_FULL)
        .max(9, ERROR_MESSAGES.SSN_INVALID_FULL),
      account_number_conf: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .min(5, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID)
        .max(17, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID)
        .oneOf([yup.ref('account_number')], ERROR_MESSAGES.ACCOUNTS_NOT_MATCH),
      nickname: yup.string().required(ERROR_MESSAGES.REQUIRED),
    })
    .required();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<BankDetailsParams>({
    resolver: yupResolver(schema),
  });

  const appLogin = handleSubmit(async (data) => {
    const id = localStorage.getItem('account_id') as string;

    let response: any;

    if (!isFromFinance) {
      response = await createBankAccount({
        account_holder_name: data.account_holder_name,
        routing_number: data.routing_number,
        account_number: data.account_number,
        nickname: data.nickname,
      });
    }

    if (isFromFinance) {
      response = await createFinanceBankAccount({
        account_holder_name: data.account_holder_name,
        routing_number: data.routing_number,
        account_number: data.account_number,
        nickname: data.nickname,
      });
    }

    // @ts-ignore
    if (response && response?.data?.id) {
      navigate('/bank-setup-success', {
        state: {
          bankConnect: false,
          last4: response?.data?.last4,
          isFromFinance: isFromFinance,
          fromSettings: fromSettings,
          fromHome: fromHome,
          isSettingDesktop: isSettingDesktop,
          isFromPayMode: isFromPayMode,
        },
      });
      localStorage.setItem('lastFourDigits', `${response?.data?.last4}`);
      // @ts-ignore
    } else if (response?.error) {
      // @ts-ignore
      setAddAccountError(response?.error?.data?.error ?? response?.error?.data?.message);
      // @ts-ignore
    }
  });

  useEffect(() => {
    setValue('account_number', '');
    setValue('account_number_conf', '');
    setValue('routing_number', '');
    setValue('nickname', '');

    getAppInit().then((response) => {
      const myAccountHeader = getMyAccountHeader(response);
      const account_holder_name = `${
        myAccountHeader?.first_name ? myAccountHeader?.first_name : ''
      } ${myAccountHeader?.last_name ? myAccountHeader?.last_name : ''}`;
      if (account_holder_name) {
        setValue('account_holder_name', account_holder_name);
      }
    });
  }, []);

  useEffect(() => {
    if (edit) {
      setTimeout(function () {
        localStorage.removeItem('enable_account_edit');
      }, 100);
    }
  }, [account]);

  /**
   * Bank Fin connection Setup
   */

  const { data, isSuccess } = useGetConfigQuery('');
  // send stripe PK to native
  if (data?.stripe_api_public_key) {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: nativeBridgeTypes.STRIPE_PUBLISHABLE_KEY,
        key: data?.stripe_api_public_key,
      })
    );
  }

  const getErrorMessage = (): string => {
    if (bankError) {
      return addAccountError ?? 'Please provide the valid Bank Details.';
    } else {
      return serverErrors;
    }
  };

  const isValidAccNumConf = () => {
    if (bankError) {
      return false;
    }
    if (errors?.account_number_conf?.message) {
      return false;
    }

    const accNum = getValues('account_number');
    const accNumConf = getValues('account_number_conf');
    if (!accNum || !accNumConf) {
      return false;
    }
    if (accNum !== accNumConf) {
      return false;
    }

    return true;
  };

  const isValidAccNum = () => {
    if (bankError) {
      return false;
    }
    if (errors?.account_number?.message) {
      return false;
    }

    const accNum = getValues('account_number') ?? '';
    if (accNum.length < 5 || accNum.length > 17) {
      return false;
    }

    return true;
  };

  const onBackClickedInManualBankSetup = () => {
    setValue('account_number', '');
    setValue('account_number_conf', '');
    setValue('routing_number', '');
    setValue('nickname', '');
    setAddAccountError('');
    handleBackButtonClick();
  };

  const updateBindingsChange = (event: any, feild: any) => {
    setValue(feild, event.target.value);
  };

  return (
    <>
      {isLoading && bankError === undefined && !serverErrors && <ManualLoader />}
      <Wrapper>
        {containersVisible.step1 && (
          <>
            {isSuccess && (
              <Elements stripe={loadStripe(data?.stripe_api_public_key)}>
                <BankLoginContainer
                  onClickManualSetup={handleClickBankManual}
                  fromSettings={fromSettings}
                  isSettingDesktop={isSettingDesktop}
                  fromHome={fromHome}
                  isFromFinance={isFromFinance}
                  isAfterKycFromTermScreen={isAfterKycFromTermScreen}
                  isFromPayMode={isFromPayMode}
                />
              </Elements>
            )}
          </>
        )}

        {containersVisible.step2 && (
          <BankSetupInfoContainer
            onContinueClick={handleClickContinueToBankDetails}
            onBackButtonClick={handleBackButtonClick}
          />
        )}

        {containersVisible.step3 && (
          <>
            <FormWrapper onSubmit={appLogin} className="no_fullscreen">
              <Box
                component={'div'}
                className={'container_content bottom_terms_padding terms_note'}
              >
                <Box
                  component={'div'}
                  className={'container_content_top terms_note'}
                  sx={{
                    [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                      minHeight: 448 + commonSpacing.desktopPaddingB * 4,
                      paddingBottom: 4,
                    },
                  }}
                >
                  <Header
                    isBackButtonVisible={true}
                    onBackButtonClick={onBackClickedInManualBankSetup}
                    paddingX={0}
                    isLanguageSwitcher
                  />
                  <Box sx={{ marginBottom: 6 }}>
                    <HeadingSummaryBlock
                      heading={t('headingContent.commonSteps.bankDetails.heading')}
                      headingAlignment={'left'}
                      content={t('headingContent.commonSteps.bankDetails.content')}
                      contentAlignment="left"
                    />
                  </Box>
                  <ShadowCard
                    sx={{ marginBottom: 2 }}
                    paddingTop={6}
                    paddingX={4}
                    paddingBottom={2}
                  >
                    <Box>
                      <Controller
                        control={control}
                        name="account_holder_name"
                        render={({ field: { onChange, value } }) => (
                          <FormInput
                            label={t('formInput.accountName')}
                            id={'accountName'}
                            placeholder={t('placeHolder.accountName')}
                            {...register('account_holder_name')}
                            error={errors?.account_holder_name?.message}
                            onChange={(event: any) => {
                              event.target.value = event.target.value.replace('  ', ' ');
                              updateBindingsChange(event, 'account_holder_name');
                            }}
                            value={value}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="account_number"
                        render={({ field: { onChange, value } }) => (
                          <FormInput
                            label={t('formInput.accountNumber')}
                            id={'accountNumber'}
                            placeholder={'XXXXXXXXX'}
                            {...register('account_number')}
                            isValidationIconVisible
                            inputMode="numeric"
                            onChange={(e: any) => {
                              e.target.value = e.target.value.replace(/[^0-9]/g, '');
                              onChange(e);
                            }}
                            error={errors?.account_number?.message}
                            isValid={isValidAccNum()}
                            maxLength={17}
                            value={value}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="account_number_conf"
                        render={({ field: { onChange, value } }) => (
                          <FormInput
                            label={t('formInput.confirmAccountNumber')}
                            id={'confirmAccountNumber'}
                            placeholder={'XXXXXXXXX'}
                            {...register('account_number_conf')}
                            onChange={(e: any) => {
                              e.target.value = e.target.value.replace(/[^0-9]/g, '');
                              onChange(e);
                            }}
                            isValidationIconVisible
                            inputMode="numeric"
                            error={errors?.account_number_conf?.message}
                            isValid={isValidAccNumConf()}
                            maxLength={17}
                            disableCopyPast={true}
                            value={value}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="routing_number"
                        render={({ field: { onChange, value } }) => (
                          <FormInput
                            label={t('formInput.routingNumber')}
                            id={'routingNumber'}
                            placeholder={'XXXXXXXXX'}
                            {...register('routing_number')}
                            inputMode="numeric"
                            error={errors?.routing_number?.message}
                            maxLength={9}
                            onChange={(e: any) => {
                              e.target.value = e.target.value.replace(/[^0-9]/g, '');
                              onChange(e);
                            }}
                            value={value}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="nickname"
                        render={({ field: { onChange, value } }) => (
                          <FormInput
                            label={t('formInput.nickName')}
                            id={'nickname'}
                            {...register('nickname')}
                            error={errors?.nickname?.message}
                            maxLength={9}
                            onChange={(event: any) => {
                              event.target.value = event.target.value.replace('  ', ' ');
                              updateBindingsChange(event, 'nickname');
                            }}
                            value={value}
                          />
                        )}
                      />
                    </Box>
                  </ShadowCard>
                  {serverErrors ||
                    (bankError && addAccountError && (
                      <Messages
                        messageHeading={t('headingContent.commonSteps.bankDetails.heading')}
                        messageContent={getErrorMessage()}
                      />
                    ))}
                </Box>
                <Box component={'div'} className="container_content_bottom terms_note">
                  <TermsText
                    mainContent={t('noticeBlocks.termsBlock.heading')}
                    subContent={t('noticeBlocks.termsBlock.content')}
                  />
                </Box>
              </Box>
              <ContentContainerBottom className={'no_fullscreen no_note'}>
                <Button
                  variant="containedLarge"
                  color="primary"
                  type="submit"
                  fullWidth
                  onClick={appLogin}
                >
                  {edit ? t('buttonTexts.updateButton') : t('buttonTexts.saveContinueButton')}
                </Button>
              </ContentContainerBottom>
            </FormWrapper>
          </>
        )}
      </Wrapper>
    </>
  );
};
