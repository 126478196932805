import { Typography } from '@mui/material';
import {
  CustomerCardWrapper,
  // CardArrow,
  CustomerCardContainer,
  CustomerCardContainerLeft,
  CustomerCardContainerRight,
  CardArrow,
} from './InvitedCustomerCard.style';
import { AccountVerificationStatusses } from '../../Statusses/AccountVerificationStatusses/AccountVerificationStatusses';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export const InvitedCustomerCard = ({
  companyName,
  Address,
  status,
  onClickEvent,
  rowData,
  isBordered = true,
}: any) => {
  return (
    <CustomerCardWrapper
      onClick={() => onClickEvent(rowData)}
      className={isBordered ? 'isBordered' : 'noneBordered'}
    >
      <CustomerCardContainer>
        <CustomerCardContainerLeft>
          <Typography
            variant="h2Bold"
            color={'gray.700'}
            sx={{ marginBottom: 1, marginTop: 0 }}
            textTransform={'capitalize'}
          >
            {companyName}
          </Typography>
          <Typography variant="h1" color={'gray.400'} sx={{ margin: 0 }}>
            {Address}
          </Typography>
        </CustomerCardContainerLeft>
        <CustomerCardContainerRight>
          <AccountVerificationStatusses type={status} />
        </CustomerCardContainerRight>
      </CustomerCardContainer>
      <CardArrow>
        <KeyboardArrowRightIcon color={'inherit'} />
      </CardArrow>
    </CustomerCardWrapper>
  );
};
