import React from 'react';
import { Typography } from '@mui/material';
import { Box, BoxContainer } from './SearchResultEmpty.style';

interface SearchResultEmptyProps {
  content?: string;
}

export const SearchResultEmpty = ({
  content = 'Search result not found',
}: SearchResultEmptyProps) => {
  return (
    <Box>
      <BoxContainer>
        <Typography color="gray.900" variant="h2Bold" textAlign="center">
          {content}
        </Typography>
      </BoxContainer>
    </Box>
  );
};
