import { styled } from '@mui/material/styles';

export const PayoutsCounterWrapper = styled('div')`
  width: 100%;
  height: 24px;
`;

export const PayoutsCountercontainer = styled('div')(
  ({ theme }) => `
  display: flex;
  height: 83px;
  width: 100%;
  align-items: center;
  padding: ${theme.spacing(0, 4)};
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 10;
  background-color: ${theme.palette.white};
  justify-content: space-between;
  

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    max-width: 632px;
    margin: 0 auto;
  }
`
);

export const PayoutsAllSelector = styled('span')(
  ({ theme }) => `
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${theme.palette.primary.main};
  margin-right: 25px;
  cursor: pointer;
`
);

export const PayoutsEditTrigger = styled('div')(
  ({ theme }) => `
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${theme.palette.primary.main};
  cursor: pointer;
`
);

export const PayoutsEditTriggerDisabled = styled('div')(
  ({ theme }) => `
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${theme.palette.primary.main};
  opacity: 0.4;
  pointer-events: none;
`
);

export const PayoutsCounterContent = styled('div')(
  ({ theme }) => `
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${theme.palette.gray[900]};
  margin-left: -30px;
`
);
