import { styled } from '@mui/material/styles';

export const MinMaxRange = styled('div')(
  ({ theme }) => `
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: ${theme.palette.gray[600]};
  margin-bottom: 24px;
`
);
