import { trannslationProps } from 'src/models/component/base.model';
import { SliderImagesFA } from '../utilities/images';

export const content = {
  payment_selction: {
    default_term:
      'PayUp will process all future payouts post approval. In case of a delayed approval, we will pay on the next available term.',
    indicate_30_net_payment:
      'Future payouts will happen on the above selection. Lack of selection will set the default term to Net 30. You can always edit terms for each job while submitting the invoice.',
  },
};

export const splashScreenItems = ({ t }: trannslationProps) => {
  const items = [
    {
      imagePath: `${SliderImagesFA.SLIDE01}`,
      heading: t('finance.splashSlider.slide1.heading'),
      content: t('finance.splashSlider.slide1.content'),
    },
    {
      imagePath: `${SliderImagesFA.SLIDE02}`,
      heading: t('finance.splashSlider.slide2.heading'),
      content: t('finance.splashSlider.slide2.content'),
    },
    {
      imagePath: `${SliderImagesFA.SLIDE03}`,
      heading: t('finance.splashSlider.slide3.heading'),
      content: t('finance.splashSlider.slide3.content'),
    },
    {
      imagePath: `${SliderImagesFA.SLIDE04}`,
      heading: t('finance.splashSlider.slide4.heading'),
      content: t('finance.splashSlider.slide4.content'),
    },
  ];

  return [items];
};

export const pre_fill_params = {
  mcc: '7349',
  description: 'Service industry',
  url: 'https://payup.com',
  title: 'Manager',
};
