import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const CardWrapper = styled(Box)(
  ({ theme }) => `
  display: flex;
  width: 100%;
  margin: 0 0 16px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${theme.palette.gray[200]};
  background: ${theme.palette.white};
  border-radius: 8px;
  padding: 0px 16px;
  height: 52px;
  cursor: pointer;
  box-sizing: border-box;

  .content_block {
   align-items: center;
   display: flex;
   font-weight: 600;
   font-size: 14px;
   line-height: 16px;
   color: ${theme.palette.gray[800]};

   svg {
    margin-left: ${theme.spacing(1)};
    height: 17px;
   }
  }

  &:hover,
  &.active {
    border: 1px solid ${theme.palette.primary.main};
    background: ${theme.palette.blue[50]};

    > div:not( .content_block) {
      svg {
        fill: ${theme.palette.primary.main};
      }
    }
  }
  `
);

export const CardArrow = styled(Box)(
  ({ theme }) => `
   width: auto;
   display: flex;
   align-items: center;
   color: ${theme.palette.gray[300]} !important;

   svg {
    width: 18px;
   }
`
);
