import React, { useState, useEffect } from 'react';
import { Accordion } from 'src/components/base/core/OtherUtilities/Accordions/Accordion/Accordion';
import { GetAllTerms } from '../../../../../../../../../services/account/account.service';
import { FilterTag } from '../../FilterTag/FilterTag';
import {
  getFilteredTerms,
  getTermName,
  handleAllSelectChange,
  handleTermsFilterChange,
} from '../../../../../../../../../helpers/helperHandlingTerms';
import { FilterContentWrapper } from '../../FilterModal/FilterModal.style';
import { GridContext } from 'src/contexts/gridContext';
import { useTranslation } from 'react-i18next';

export interface TermsFilterProps {
  setSelectedFilterOptions?: (filterObj: any) => void;
  selectedFilterOptions?: any;
  resetFilter?: any;
  setAllSelect?: any;
  isAllSelect?: any;
}

export const TermsFilter = ({
  setSelectedFilterOptions,
  selectedFilterOptions,
  resetFilter,
  setAllSelect,
  isAllSelect,
}: TermsFilterProps) => {
  const { t } = useTranslation();
  const [terms, setTerms]: any = useState([]);
  const initTerms = async (isAllSelect: boolean = false) => {
    await GetAllTerms().then((response) => {
      const filteredTerms = getFilteredTerms(response?.data?.paymentTerms?.nodes);
      if (!selectedFilterOptions?.payout_term_ids) {
        setTerms(filteredTerms);
      } else if (isAllSelect) {
        filteredTerms.length > 0 &&
          setTerms(
            filteredTerms.map((obj: any) => {
              return { ...obj, active: true };
              return obj;
            })
          );
      } else {
        setSelectedData(filteredTerms);
      }
    });
  };

  useEffect(() => {
    initTerms(isAllSelect);
  }, []);

  useEffect(() => {
    initTerms(isAllSelect);
  }, [isAllSelect]);
  const setSelectedData = (termData: any) => {
    setAllSelect(selectedFilterOptions?.payout_term_ids?.length === termData?.length);
    const alteredTerms = termData?.map((item: any) => {
      return { ...item, active: selectedFilterOptions?.payout_term_ids.includes(item.id) };
    });
    setTerms(alteredTerms);
  };

  const gridContext = React.useContext(GridContext);
  const isFilterActive = gridContext?.filterStatus?.isTermFilterActive;
  return (
    <Accordion title={t('earning.filter.termFilter.title')} isFilterActive={isFilterActive}>
      <FilterContentWrapper>
        <>
          {terms &&
            terms
              .sort(function (a: any, b: any) {
                return a.due_days - b.due_days;
              })
              .map((option: any, index: any) => {
                return (
                  <FilterTag
                    key={index}
                    inputName={option.id}
                    inputType="checkbox"
                    title={getTermName(option.id)}
                    idValue={option.id}
                    inputValue={option.active}
                    handleOnChange={(e: any) =>
                      handleTermsFilterChange(
                        terms,
                        e,
                        isAllSelect,
                        setTerms,
                        setAllSelect,
                        setSelectedFilterOptions
                      )
                    }
                  />
                );
              })}
          <FilterTag
            inputName={'select_all'}
            inputType="checkbox"
            title={t('earning.filter.termFilter.all')}
            idValue={'selectAll'}
            inputValue={isAllSelect}
            handleOnChange={(e: any) =>
              handleAllSelectChange(
                terms,
                isAllSelect,
                setTerms,
                setAllSelect,
                setSelectedFilterOptions
              )
            }
          />
        </>
      </FilterContentWrapper>
    </Accordion>
  );
};
