import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  BusinessRepresentativeContainer,
  BusinessVerifyOwnershipContainer,
} from '../../../../../containers';

export const BusinessRepresentative = () => {
  const { reduceTheBusinessFlowFields } = useFlags();
  return (
    <>
      {reduceTheBusinessFlowFields ? (
        <BusinessVerifyOwnershipContainer />
      ) : (
        <BusinessRepresentativeContainer />
      )}
    </>
  );
};
