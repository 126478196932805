import React from 'react';
import { Accordion } from 'src/components/base/core/OtherUtilities/Accordions/Accordion/Accordion';
import { MinMaxRange } from './AmountFilter.style';
import { MultiRangeSlider } from 'src/components/base/core/Sliders/MultiRangeSlider/MultiRangeSlider';
import { GridContext } from 'src/contexts/gridContext';
import { useTranslation } from 'react-i18next';

export interface AmountFilterProps {
  setFilterAmountRange?: any;
  resetFilter?: boolean;
  minmumAmount?: any;
  maximumAmount?: any;
  selectedMinRange?: any;
  setSelectedMinRange?: any;
}
export const AmountFilter = ({
  setFilterAmountRange,
  resetFilter,
  minmumAmount = 0,
  maximumAmount = 100,
  selectedMinRange,
  setSelectedMinRange,
}: AmountFilterProps) => {
  const { t } = useTranslation();
  const minValue = minmumAmount > 0 ? minmumAmount : 0;
  const maxValue = maximumAmount > 0 ? maximumAmount : 100;
  const gridContext = React.useContext(GridContext);
  const isFilterActive = gridContext?.filterStatus?.isAmountFilterActive;
  return (
    <Accordion title={t('earning.filter.amountFilter.title')} isFilterActive={isFilterActive}>
      <MinMaxRange>
        {t('earning.filter.amountFilter.from')} ${minValue} {t('earning.filter.amountFilter.to')} $
        {maxValue}
      </MinMaxRange>
      <MultiRangeSlider
        min={minValue}
        max={maxValue}
        selectedMinRange={selectedMinRange}
        setSelectedMinRange={setSelectedMinRange}
        setFilterAmountRange={setFilterAmountRange}
        resetFilter={resetFilter}
      />
    </Accordion>
  );
};
