import { Box, Typography } from '@mui/material';
import { SpentOrEarnIndicator } from '../../Media/Icon/Icons/Icons';

import {
  TransactionSummaryPaymentInfoCardWrapper,
  TransactionSummaryPaymentInfoCardTop,
  TransactionSummaryPaymentInfoCardBottom,
} from './TransactionSummaryPaymentInfoCard.style';
import { TransactionSummaryUserIcon } from '../../CommonNestedStyles/CommonNestedStyles.style';

interface iTransactionSummaryPaymentInfoCard {
  currentBalance?: string | number;
  name?: string;
  dateStamp?: string;
  timeStamp?: string;
  nameFirstCharacters?: string;
  isSpentTransaction?: boolean;
}
export const TransactionSummaryPaymentInfoCard = ({
  currentBalance,
  name,
  dateStamp,
  timeStamp,
  nameFirstCharacters,
  isSpentTransaction,
}: iTransactionSummaryPaymentInfoCard) => {
  return (
    <TransactionSummaryPaymentInfoCardWrapper>
      <TransactionSummaryPaymentInfoCardTop>
        <TransactionSummaryUserIcon>{nameFirstCharacters}</TransactionSummaryUserIcon>
        <Typography component="p" variant="h2Bold" color={'gray.900'}>
          {name}
        </Typography>
      </TransactionSummaryPaymentInfoCardTop>

      <TransactionSummaryPaymentInfoCardBottom className={isSpentTransaction ? 'spent' : 'earned'}>
        <Box display={'flex'} marginBottom={2}>
          <Typography
            component="span"
            variant="h4"
            color={'gray.900'}
            fontWeight={500}
            position={'relative'}
            top={'1px'}
          >
            $
          </Typography>
          <Typography component="span" variant="h8Bold" color={'gray.900'} fontSize={'36px'}>
            {/* 100,321.00 */}
            {currentBalance}
          </Typography>
          <Typography
            component="span"
            color={isSpentTransaction ? 'orange.500' : 'blue.500'}
            className="indicator"
          >
            <SpentOrEarnIndicator />
          </Typography>
        </Box>
        <Box display={'flex'}>
          <Typography
            component="p"
            variant="h1"
            color={'gray.500'}
            marginBottom={1}
            fontWeight={400}
          >
            {dateStamp} <span className="separator">.</span> {timeStamp}
          </Typography>
        </Box>
      </TransactionSummaryPaymentInfoCardBottom>
    </TransactionSummaryPaymentInfoCardWrapper>
  );
};
