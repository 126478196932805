import React from 'react';
import { PaymentTableWrapper } from './TableWrapper.style';
import { invoiceBatchTabsType } from 'src/shared/utilities/interface';

export interface PaymentTableProps {
  children?: React.ReactNode;
  isToggle?: boolean;
  tabType?: invoiceBatchTabsType;
  isTransactionGrid?: boolean;
  onClick?: any;
}

export const TableWrapper = ({
  children,
  isToggle,
  tabType,
  isTransactionGrid,
  ...rest
}: PaymentTableProps) => {
  return (
    <>
      {isToggle && (
        <PaymentTableWrapper
          {...rest}
          className={
            tabType === 'submitted' && !isTransactionGrid ? 'table_submitted' : 'payment_table'
          }
        >
          {children}
        </PaymentTableWrapper>
      )}
    </>
  );
};
