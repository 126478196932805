import { styled } from '@mui/material/styles';

export const PaymentTabNavWrapper = styled('div')(
  ({ theme }) => `
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: ${theme.spacing(6)};

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
   padding-left: 16px;
   padding-right: 16px;
  }
`
);

export const PaymentTabNavContainer = styled('div')(
  ({ theme }) => `
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  background: ${theme.palette.white};
  border-radius: 20px;
  border: 1px solid ${theme.palette.gray[200]};

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33.33%;
    background: transparent;
    border-radius: 100px;
    font-weight: 600;
    font-size: 14px;
    color: ${theme.palette.gray[900]};

    &.active {
      background-color: ${theme.palette.primary.main};
      color: ${theme.palette.white};
      pointer-events: none;
    }
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    a {
      height: 44px;
    }
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    a {
      height: 38px;
    }
  }
`
);
