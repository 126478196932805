import { Box } from '@mui/material';
import { HeadingSummaryBlock } from '../ContentBlocks/HeadingSummaryBlock';
import { VerifyImageContentBlockProps } from 'src/models/component/base.model';

export const VerifyImageContentBlock = ({
  imagePath,
  heading,
  content,
  headingAlignment,
  contentAlignment,
  headingBoxStyle,
}: VerifyImageContentBlockProps) => {
  return (
    <>
      <div className="status_image">
        <img src={imagePath} alt="Status" height={200} />
      </div>
      <Box className="content_block" sx={headingBoxStyle}>
        <HeadingSummaryBlock
          heading={heading}
          headingAlignment={headingAlignment}
          content={content}
          contentAlignment={contentAlignment}
        />
      </Box>
    </>
  );
};
