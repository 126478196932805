import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const CardBg = styled(Box)(
  ({ theme }) => `
  position: relative;
  z-index:1;
  background: linear-gradient(45deg, #635BFF 0%, #6B4BCC 100%);
  display: block;
  overflow: hidden;
  filter: drop-shadow(0px 0px 2.04196px rgba(0, 0, 0, 0.06)) drop-shadow(0px 8.16783px 24.5035px rgba(0, 0, 0, 0.1));
  border-radius: 9.02414px;

  .card_bg_img {
    position: absolute;
    left: 180.45px;
    top: -0.85px;
    Width: 121.35px;
    Height: 181.78px;
  }

  .card_logo_img {
    position: absolute;
    left: 16px;
    top: 14px;
  }

  .card_dots_img {
    position: absolute;
    width: 24px;
    height: 18px;
    left: 72px;
    top: 108px;
  }

  input {
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  &.default {
    width: 298px;
    height: 179px;
  }

  &.onlyNumber {
    width: 286px;
    height: 179px;

    .card_bg_img {
      left: 166.45px;
      top: -7.85px;
    }

    .card_logo_img {
      left: 20px;
      top: 30px;
    }
  }

  &.swipe_enabled {
    left: 0;
  }
 `
);
export const CardImage = styled('img')(
  ({ theme }) => `
  position: relative;
  z-index:1;
  width: 286px !important;
  height: 193px  !important;
  display: block;
 `
);

export const CardName = styled(Box)(
  ({ theme }) => `
  position: absolute;
  z-index:2;
  color: #fff;
  top: 64px;
  left: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 12.2517px;
  line-height: 16px;
  letter-spacing: 0.8px;
 `
);

export const CardNumber = styled(Box)(
  ({ theme }) => `
  position: absolute;
  z-index:2;
  color: #fff;
  top: 86px;
  left: 16px;
  font-style: normal;

  span {
    font-style: normal;
    font-weight: 400;
    line-height: 17px;

    &.dots {
      font-size: 20px;
      margin-right: 9px;
      letter-spacing: 1.87px;
      position: relative;
      top: 2px;
    }

    &.number {
      font-size: 13.2937px;
      position: absolute;
      letter-spacing: 0.7px;
      top: 0;
    }

    &.hidde-none {
      margin-right: 9px;
      letter-spacing: 0.7px;
    }

    &.secondNum {
      left: 0px;
    }

    &.secondNum {
      left: 44px;
    }

    &.thirdNum {
      left: 87px;
    }

    &.fourthNum {
      left: 129px;
    }
  }

  &.onlyNumber {
      top: 132px;
      left: 20px;

      span {
        font-style: normal;
        font-weight: 400;
        line-height: 25px;

        &.dots {
          font-size: 30px;
          margin-right: 14px;
          top: -1px;
          letter-spacing: 2.2px;
        }

        &.number {
          font-size: 18px;
          position: absolute;
          letter-spacing: 1.2px;
        }

        &.fourthNum {
          left: 185px;
        }
      }
  }
  
 `
);

export const CardExpiry = styled(Box)(
  ({ theme }) => `
  position: absolute;
  z-index:2;
  color: #fff;
  top: 112px;
  left: 16px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
 `
);

export const CardShowNumber = styled(Box)(
  ({ theme }) => `
  position: absolute;
  z-index:2;
  color: #fff;
  top: 151px;
  left: 186px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;
  displsy: flex;
  align-items: center;

  svg {
    position: relative;
    margin-right: 4px;
    top: 3px;
  }
 `
);

export const CardCopy = styled(Box)(
  ({ theme }) => `
  position: absolute;
  z-index:2;
  color: #fff;
  top: 84px;
  left: 190px;
  width: 12px;
  height: 12px;
  background: transparent;
  cursor: pointer;
 `
);

export const CardCVCnumber = styled('div')(
  ({ theme }) => `
  position: absolute;
  left: 72px;
  top: 112px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 2px;
  color: white;
 `
);
