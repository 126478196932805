import { Box, Button } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import {
  Header,
  HeadingSummaryBlock,
  FormWrapper,
  ContentContainerBottom,
  Messages,
} from 'src/components/base/core';
import { ERROR_MESSAGES } from 'src/constants/Validation';
import moment from 'moment';
import { handleIdentityVerificationDOB } from 'src/helpers/containers/helperIdentityVerification';
import { useEffect, useState } from 'react';
import dtoToFormValues from 'src/shared/utilities/dto';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { trackEvent } from '../../../../../../components/analytics/service';
import { FormInputDate } from 'src/components/base/core/FormElements/FormInputDate/FormInputDate';
import { commonSpacing, theme } from 'src/shared/theme/theme';
import { useGetBlockHeight } from 'src/hooks/useGetBlockHeight';

export const FormDOBSubmition = ({
  onBackButtonClick,
  onFormSubmit,
  accountData,
  setAccountData,
  pageFromBack,
  backFrom,
}: any) => {
  const { t } = useTranslation();
  const [DOBValue, setDOBValue] = useState('');
  const [
    submitForm,
    serverSideErrors,
    isLoading,
    isNullDOB,
    onChangeHandlerDateInput,
    isNot18,
    isValidDOB,
  ]: any = handleIdentityVerificationDOB(accountData, setAccountData);
  const [errorManualDate, setErrorManualDate] = useState(false);
  const [btnSubmitted, setBtnSubmitted] = useState(false);
  const [elementRef, height] = useGetBlockHeight();

  useEffect(() => {
    if (accountData) {
      dtoToFormValues(setValue, accountData);
      if (!isNullDOB(accountData?.individual.dob)) {
        const dob = accountData?.individual?.dob;
        const formattedDOB: any = moment(new Date(dob.year, dob.month - 1, dob.day));
        setValue('dob', moment(formattedDOB).format('MM/DD/yyyy'));
        setDOBValue(moment(formattedDOB).format('MM/DD/yyyy'));
      }
    }
  }, [accountData]);

  const revieInfoSchema = yup
    .object({
      dob: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .test(
          'len',
          errorManualDate ? ERROR_MESSAGES.DATE_INVALID_LESS_THAN_18 : ERROR_MESSAGES.DATE_INVALID,
          (value) => {
            if (value && value?.length === 10 && isNot18(value) && isValidDOB(value)) return true;
            else return false;
          }
        ),
    })
    .required();

  //hook-forms
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(revieInfoSchema),
    mode: 'onSubmit',
  });

  const onAppFormSubmit = handleSubmit(async (data: any) => {
    submitForm(data, onFormSubmit, backFrom);
  });

  const onChangeHandlerDate = (event: any) => {
    onChangeHandlerDateInput(event, setValue, setErrorManualDate);
  };

  return (
    <>
      {isLoading && <ManualLoader />}
      <FormWrapper
        onSubmit={onAppFormSubmit}
        onClick={() => trackEvent('DOB Input Clicked')}
        className={'no_fullscreen'}
      >
        <Box
          component={'div'}
          className={'container_content'}
          ref={elementRef}
          sx={{
            [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
              minHeight: height,
              paddingBottom: height ? 4 : commonSpacing.desktopPaddingB,
            },
          }}
        >
          <Header
            isBackButtonVisible={true}
            onBackButtonClick={onBackButtonClick}
            isProgressBarVisible={true}
            totalProgressSteps={5}
            currentProgressStep={3}
            paddingX={0}
            backButtonId={'DOBStepBack'}
          />
          <Box sx={{ marginBottom: 6 }}>
            <HeadingSummaryBlock
              heading={t('headingContent.individual.dob.heading')}
              headingAlignment="left"
              content={t('headingContent.individual.dob.content')}
              contentAlignment="left"
            />
          </Box>
          <Controller
            control={control}
            name="dob"
            render={({ field: { value } }) => (
              <FormInputDate
                label={t('formInput.dob')}
                id={'dob'}
                placeholder={'(123) 543-3454'}
                {...register('dob')}
                inputError={errors.dob?.message}
                onChange={(event: any) => {
                  onChangeHandlerDate(event);
                }}
                inputMode="numeric"
                inputFocus={(e: any) => {
                  trigger('dob');
                }}
                value={value || ''}
              />
            )}
          />

          {serverSideErrors && (
            <Messages
              topMargin={4}
              bottomMargin={0}
              messageHeading={t('errorMessages.dobError.heading')}
              messageContent={t('errorMessages.dobError.content')}
            />
          )}
        </Box>
        <ContentContainerBottom className={'no_fullscreen'}>
          <Button
            variant="containedLarge"
            color="primary"
            type="submit"
            fullWidth
            id={'DobStepContinueButton'}
            sx={{ overflow: 'hidden' }}
            onClick={() => {
              setBtnSubmitted(true);
            }}
          >
            {t('buttonTexts.continueButton')}
          </Button>
        </ContentContainerBottom>
      </FormWrapper>
    </>
  );
};
