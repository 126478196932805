import { Typography } from '@mui/material';
import {
  ConnectorLine,
  BottomSection,
  Container,
  SecondRow,
  DetailsSection,
} from './BatchTimeLineItem.style';
import { TimeLineItemProps } from 'src/models/component/base.model';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  ApprovedIcon,
  PaymentArrivedIcon,
  PaymentOnTheWayIcon,
  PaymentTriggeredIcon,
  SubmittedIcon,
  PaymentInTransitIcon,
} from 'src/components/base/core/Media/Icon/Icons/Icons';
import { ReactNode } from 'react';

export const BatchTimeLineItem = ({
  itemName,
  dateText,
  timeText,
  position,
  isActive = false,
  hideConnector = false,
}: TimeLineItemProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  const getIcon = (): ReactNode => {
    switch (position) {
      case 1:
        return <SubmittedIcon isActive={isActive} />;
      case 2:
        return <PaymentInTransitIcon isActive={isActive} />;
      case 3:
        return <ApprovedIcon isActive={isActive} />;
      case 4:
        return <PaymentTriggeredIcon isActive={isActive} />;
      case 5:
        return <PaymentOnTheWayIcon isActive={isActive} />;
      case 6:
        return <PaymentArrivedIcon isActive={isActive} />;
      default:
        return <SubmittedIcon isActive={isActive} />;
    }
  };

  return (
    <Container>
      {position === 1 || hideConnector ? null : <ConnectorLine isActive={isActive} />}
      <BottomSection>
        {getIcon()}
        <DetailsSection>
          <Typography
            variant="h2Bold"
            component={'p'}
            color={'gray.700'}
            sx={{ marginBottom: 1, opacity: isActive ? 1 : 0.6 }}
          >
            {itemName}
          </Typography>
          <SecondRow>
            <Typography variant="h1" color={'gray.500'} sx={{ opacity: isActive ? 1 : 0.6 }}>
              {dateText}
            </Typography>
            {timeText ? (
              <Typography
                variant="h1"
                component={'p'}
                color={'gray.500'}
                sx={{ marginLeft: 0.75, opacity: isActive ? 1 : 0.6 }}
              >
                <span>• </span>
                {isActive ? timeText : t('invoiceSummary.middle.estimated')}
              </Typography>
            ) : null}
          </SecondRow>
        </DetailsSection>
      </BottomSection>
    </Container>
  );
};
