export const ERROR_MESSAGES = {
  REQUIRED: 'Please complete this field',
  NAME_INVALID: 'Not a valid name',
  TERMS: 'Terms must be accepted to continue',
  EMAIL_INVALID: 'Please enter a valid email address',
  SSN_INVALID_LAST4: 'Input required only 4 digits',
  SSN_INVALID_FULL: 'Input required only 9 digits',
  ACCOUNT_NUMBER_INVALID: 'Input required between 5 and 17 digits',
  ACCOUNTS_NOT_MATCH: 'Accounts do not match',
  EIN_INVALID: 'Input required only 9 digits',
  ZIPCODE_INVALID: 'Input required only 5 digits',
  PHONE_NUMBER_INVALID: 'Phone number required 10 digits',
  DATE_INVALID: 'Incorrect date format, please check.',
  DATE_INVALID_LESS_THAN_18: 'It seems your age is less than 18',
  REVIEW_MISSING_INFORMATION: 'fill in the missing information',
  CODE_INVALID: 'Invalid code',
  INVALID_SMS_PHONE: 'The number {phone} is not a valid phone number',
  INVALID_FIRST_NAME: 'Enter valid First Name',
  INVALID_NAME: 'Enter valid Name',
  INVALID_LAST_NAME: 'Enter valid Last Name',
  MAX_DISCRIPTOR_ALLOWED_LIMIT: 'Maximum character limit is 10',
  // INVALID_FULLNAME: 'Enter valid Full Name',
  // INVALID_BUSINESSNAME: 'Enter valid Business Name',
};

/*eslint-disable */
export const REGEX = {
  personalName: /^[a-z ,.'-]+$/i,
  email:
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  idNumber: /[0-9]{4}$/,
  phoneNumber: /[0-9]{10}$/,
  dob: /^(0[1-9]|[12]\d|3[01])$/,
};

export const ValidateIdNumber = (idNum: string) => {
  return idNum.replace(/\D/g, '').length == 4;
};
