import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import * as yup from 'yup';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  ModalWrapper,
  ModalOverlay,
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalButton,
  ModalClose,
} from './DownloadModal.style';

import { FormInput, Icon, ManualLoader, Messages } from '../../../../..';
import { Box, Button, Snackbar, Typography } from '@mui/material';
import { ShadowCard } from '../../../../..';
import { CustomDateFilter } from '../../Filter/CustomDateFilter/CustomDateFilter';
import { EmailToExportCSV } from 'src/shared/utilities/interface';
import { SendCSVIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import { SelectionRow } from '../SelectionRow/SelectionRow';
import { downloadPeriodOptions } from 'src/shared/utilities/initialStates';
import { getFormattedDate } from 'src/shared/utilities/dataConvertions';
import { EARNINGS, TRANSFERS } from 'src/constants/constants';
import { exportInvoices } from 'src/services/earnings/submited.service';
import { getInvoicedAtFilter } from 'src/helpers/helperExportInvoices';
import { ERROR_MESSAGES } from 'src/constants/Validation';
import { RootState } from 'src/store';
import { useExportBatchesCSVMutation } from 'src/services/transfers/transfers.service';
import { DownloadModalProps } from 'src/models/component/base.model';

const domNode: any = document.getElementById('app-root');

export const DownloadModal = ({
  downloadStates,
  setDownloadStates,
  tabType,
  isTransactionGrid,
  t,
}: DownloadModalProps) => {
  const userDetails = useSelector((state: RootState) => state.userDetails);
  const [prevSelection, setPrevSelection] = useState(downloadPeriodOptions[0]);
  const [responseError, setResponseError] = useState('');
  const [isLoadingInvoiceDownload, setsLoadingInvoiceDownload] = useState<boolean>(false);

  const dateNow = new Date();
  const today = getFormattedDate(dateNow);
  const sevenDaysBefore = getFormattedDate(moment(dateNow).subtract(6, 'day').toDate());
  const thirtyDaysBefore = getFormattedDate(moment(dateNow).subtract(29, 'day').toDate());
  const ninetyDaysBefore = getFormattedDate(moment(dateNow).subtract(89, 'day').toDate());
  const startOfYear = getFormattedDate(moment(dateNow).startOf('year').toDate());

  const [exportBatchesMutation, { isLoading }] = useExportBatchesCSVMutation();

  const reviewInfoSchema = yup
    .object({
      email: yup
        .string()
        .email(ERROR_MESSAGES.EMAIL_INVALID)
        .typeError(ERROR_MESSAGES.REQUIRED)
        .required(ERROR_MESSAGES.REQUIRED),
    })
    .required();

  //hook-forms
  const {
    register,
    getFieldState,
    setValue,
    control,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<EmailToExportCSV>({
    resolver: yupResolver(reviewInfoSchema),
    defaultValues: { email: userDetails?.email ?? '' },
    mode: 'onSubmit',
  });

  useEffect(() => {
    setDownloadStates({
      ...downloadStates,
      selectedOption: prevSelection ?? downloadPeriodOptions[0],
    });
  }, []);

  const getOrderBy = (): string => {
    if (tabType === 'submitted') {
      return isTransactionGrid ? TRANSFERS.CREATED_AT_DESC : EARNINGS.INVOICED_AT_DESC;
    }
    if (tabType === 'approved') {
      return isTransactionGrid ? TRANSFERS.APPROVED_AT_DESC : EARNINGS.APPROVED_AT_DESC;
    }
    return isTransactionGrid ? TRANSFERS.PAID_AT_DESC : EARNINGS.PAID_AT_DESC;
  };

  const getStatusForTheInQuery = (): string[] | string => {
    if (tabType === 'submitted') {
      return isTransactionGrid ? TRANSFERS.PENDING : [EARNINGS.SUBMITTED, EARNINGS.TERMS_ACCEPTED];
    }
    if (tabType === 'approved') {
      return isTransactionGrid ? TRANSFERS.APPROVED : [EARNINGS.APPROVED];
    }
    return isTransactionGrid ? TRANSFERS.PAID : [EARNINGS.PAID];
  };

  const onSendClicked = async () => {
    if (!getFieldState('email').error) {
      let response;
      const inQuery = getStatusForTheInQuery();
      const orderBy = getOrderBy();
      const status = isTransactionGrid ? { equalTo: JSON.stringify(inQuery) } : { in: inQuery };
      const invoiced_at = getInvoicedAtFilter(downloadStates, dateNow);
      const filter = !isTransactionGrid
        ? { status, invoiced_at }
        : { status, created_at: invoiced_at };
      const toEmail = getValues('email');

      setResponseError('');
      setsLoadingInvoiceDownload(true);

      if (isTransactionGrid) {
        response = await exportBatchesMutation({
          details: false,
          filter: filter,
          orderBy: orderBy,
          email: toEmail,
        });
      } else {
        response = await exportInvoices(filter, toEmail, orderBy);
      }

      if (response) {
        if (
          (!isTransactionGrid && response?.data?.payouts?.nodes.length > 0) ||
          (isTransactionGrid && response?.data?.payoutBatchTransfers?.totalCount > 0)
        ) {
          onModalClose();
          setsLoadingInvoiceDownload(false);
        } else {
          setResponseError(
            t(
              !isTransactionGrid
                ? 'earning.download.noInvoicesAvailableError'
                : 'batches.download.noInvoicesAvailableError'
            )
          );
          setsLoadingInvoiceDownload(false);
        }
      }
    }
  };

  const onModalClose = () => {
    setDownloadStates({
      ...downloadStates,
      isDownloadEnable: false,
    });
  };

  const onCustomRangeCancel = () => {
    setDownloadStates({
      ...downloadStates,
      selectedDateRange: [],
      selectedOption: prevSelection,
    });
  };

  const updateSelectedOption = (selectedOptionId: string) => {
    setDownloadStates({
      ...downloadStates,
      selectedOption: selectedOptionId,
    });
    if (selectedOptionId !== downloadPeriodOptions[6]) {
      setPrevSelection(selectedOptionId);
    }
  };

  const onCustomRangeChange = (event: any) => {
    if (event?.length > 1) {
      const startDate = moment(event[0]).startOf('day').format();
      let endDate;
      if (event[1]) {
        endDate = moment(event[1]).format();
      } else {
        endDate = moment(event[0]).format();
      }
      setDownloadStates({
        ...downloadStates,
        selectedDateRange: [startDate, endDate],
      });
    } else {
      setDownloadStates({
        ...downloadStates,
        selectedDateRange: [],
      });
    }
  };

  const onSnackBarClose = () => {
    setResponseError('');
  };

  return ReactDOM.createPortal(
    <>
      {(isLoading || isLoadingInvoiceDownload) && <ManualLoader />}
      <ModalWrapper>
        <ModalOverlay onClick={onModalClose} />
        <ModalContainer className="is_fixed">
          <ModalClose onClick={onModalClose}>
            <Icon iconType="CloseIcon" />
          </ModalClose>
          <ModalHeader>
            <Typography variant="h3Bold" color="gray.900" component={'p'} textAlign="center">
              {t('earning.download.title')}
            </Typography>
          </ModalHeader>
          <ModalContent>
            <ShadowCard padding={4}>
              <Typography
                variant="h2Bold"
                color="gray.800"
                component={'p'}
                sx={{ paddingBottom: 1.5, paddingTop: 1 }}
              >
                {t('earning.download.selectPeriod')}
              </Typography>
              <SelectionRow
                label={t('earning.filter.dateFilter.all')}
                checked={downloadPeriodOptions[0] === downloadStates.selectedOption}
                optionId={downloadPeriodOptions[0]}
                handleChange={updateSelectedOption}
              />
              <SelectionRow
                label={t('earning.download.today')}
                dateRange={today}
                checked={downloadPeriodOptions[1] === downloadStates.selectedOption}
                optionId={downloadPeriodOptions[1]}
                handleChange={updateSelectedOption}
              />
              <SelectionRow
                label={t('earning.filter.dateFilter.last7')}
                dateRange={`${sevenDaysBefore} - ${today}`}
                checked={downloadPeriodOptions[2] === downloadStates.selectedOption}
                optionId={downloadPeriodOptions[2]}
                handleChange={updateSelectedOption}
              />
              <SelectionRow
                label={t('earning.filter.dateFilter.last30')}
                dateRange={`${thirtyDaysBefore} - ${today}`}
                checked={downloadPeriodOptions[3] === downloadStates.selectedOption}
                optionId={downloadPeriodOptions[3]}
                handleChange={updateSelectedOption}
              />
              <SelectionRow
                label={t('earning.filter.dateFilter.last90')}
                dateRange={`${ninetyDaysBefore} - ${today}`}
                checked={downloadPeriodOptions[4] === downloadStates.selectedOption}
                optionId={downloadPeriodOptions[4]}
                handleChange={updateSelectedOption}
              />
              <SelectionRow
                label={t('earning.download.ytd')}
                dateRange={`${startOfYear} - ${today}`}
                checked={downloadPeriodOptions[5] === downloadStates.selectedOption}
                optionId={downloadPeriodOptions[5]}
                handleChange={updateSelectedOption}
              />
              <CustomDateFilter
                key={downloadPeriodOptions[6]}
                isExportInvoices={true}
                downloadStates={downloadStates}
                updateSelectedOption={updateSelectedOption}
                cancelDateSelection={onCustomRangeCancel}
                onDateChange={onCustomRangeChange}
              />
            </ShadowCard>
            <ShadowCard paddingTop={4} paddingX={4} paddingBottom={1} marginTop={5}>
              <Typography
                variant="h2Bold"
                color="gray.800"
                component={'p'}
                sx={{ paddingBottom: 1.5, paddingTop: 1 }}
              >
                {t('earning.download.whereToSent')}
              </Typography>
              <Box marginTop={4}>
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { value } }) => (
                    <FormInput
                      label={t('formInput.email')}
                      id={'email'}
                      placeholder={t('placeHolder.emailAddress')}
                      {...register('email')}
                      inputMode="email"
                      error={errors?.email?.message}
                      onChange={(event: any) => {
                        setValue('email', event.target.value);
                      }}
                      value={value}
                      inputFocus={(e: any) => {
                        trigger('email');
                      }}
                    />
                  )}
                />
              </Box>
            </ShadowCard>
            <Snackbar
              open={responseError !== ''}
              autoHideDuration={2000} // Time of Android Toast.LENGTH_SHORT
              onClose={onSnackBarClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              sx={{ marginBottom: 16, marginLeft: 2, marginRight: 2 }}
            >
              <div>
                {responseError !== '' ? (
                  <Messages
                    messageHeading={t('earning.download.noInvoicesAvailableTitle')}
                    messageContent={responseError}
                    type="default"
                  ></Messages>
                ) : null}
              </div>
            </Snackbar>
          </ModalContent>
          <ModalButton>
            <Button
              variant="containedLarge"
              color="primary"
              type="button"
              startIcon={<SendCSVIcon />}
              fullWidth
              onClick={onSendClicked}
              sx={{ overflow: 'hidden' }}
            >
              {t('earning.download.sendBtn')}
            </Button>
          </ModalButton>
        </ModalContainer>
      </ModalWrapper>
    </>,
    domNode
  );
};
