import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import { Box, Typography } from '@mui/material';

import { Wrapper, HeaderFixed, Header } from 'src/components/base/core';

import { LoaderWrapper, WrapperContainerLoader } from './AccountLoading.style';

import { useGetAccountDataMutation } from 'src/services/account/accountServiceApi';

import { theme } from 'src/shared/theme/theme';

// Inspired by the former Facebook spinners.
function FacebookCircularProgress(props: CircularProgressProps) {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: '#DAD8FF',
        }}
        size={80}
        thickness={3}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          animationDuration: '700ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        color="primary"
        size={80}
        value={60}
        thickness={3}
        {...props}
      />
    </Box>
  );
}

const redirection_time = 6000;

export const FinanceAccountLoadingContainer = () => {
  const [getAccountData]: any = useGetAccountDataMutation();
  const [account, setAccount]: any = useState();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const { isFromAccountSuccessScreen } =
    location?.state != null
      ? (location?.state as { isFromAccountSuccessScreen: boolean })
      : {
          isFromAccountSuccessScreen: false,
        };

  useEffect(() => {
    const timeoutId = setInterval(async () => {
      const retryData = await getAccountData();
      setAccount(retryData);
    }, redirection_time);

    return () => {
      clearInterval(timeoutId as NodeJS.Timeout);
    };
  }, []);

  useEffect(() => {
    if (account) {
      const getAccountData = account?.data;
      const treasuryStatus = getAccountData?.treasury_status;
      const treasuryAccountId = getAccountData?.treasury_account_id;
      const isTreasuryAccountActive = treasuryStatus === 'active' && treasuryAccountId !== null;

      if (isTreasuryAccountActive) {
        localStorage.removeItem('cardIssue');

        if (isFromAccountSuccessScreen) {
          navigate('/signup-success');
        } else {
          navigate('/finance/account/main');
        }
        return;
      }
    }
  }, [account]);

  return (
    <>
      <HeaderFixed className="fixed_only_desktop loader_wrapper_header">
        <Header
          isMainMunuVisible={isFromAccountSuccessScreen ? false : true}
          isSkipButtonHide={isFromAccountSuccessScreen ? false : true}
          isBackButtonHide={isFromAccountSuccessScreen ? false : true}
          paddingX={0}
          isLogoVisible={true}
        />
      </HeaderFixed>
      <Wrapper>
        <WrapperContainerLoader>
          <Box
            display={'flex'}
            width={'100%'}
            justifyContent={'center'}
            flexDirection={'column'}
            sx={{
              [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                marginTop: 'calc(50vh - 100px)',
              },
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                marginTop: '200px',
              },
            }}
          >
            <LoaderWrapper>
              <FacebookCircularProgress />
            </LoaderWrapper>

            <Typography
              variant="h3ExtraBold"
              component="div"
              sx={{
                color: 'gray.900',
                marginBottom: 6,
                maxWidth: 200,
                marginX: 'auto',
                textAlign: 'center',
                lineHeight: 1.5,
              }}
            >
              {t('finance.accountLoading.content')}
            </Typography>
          </Box>
        </WrapperContainerLoader>
      </Wrapper>
    </>
  );
};
