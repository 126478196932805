import { Box, Button, Typography } from '@mui/material';

import { Modal } from '../Modal/Modal';
import { LimitsProgressCard } from '../../Cards/LimitsProgressCard/LimitsProgressCard';

import { ShadowCard } from '../../CommonNestedStyles/FormWrapper.style';

import { trussaryHiddenUIs } from 'src/constants/constants';

import { ManageCardLimitsModalProps } from 'src/models/component/base.model';
import { handleProgressBarValues } from 'src/helpers/containers/helperTransactions';

export const ManageCardLimitsModal = ({
  closeIdModalEvent,
  cardData,
  totalSpentAmount,
  t,
}: ManageCardLimitsModalProps) => {
  // handle close event __
  const handleCloseEvent = () => {
    closeIdModalEvent && closeIdModalEvent();
  };

  const getSpendingLimits = cardData?.spending_controls?.spending_limits[0]?.amount ?? 0;

  const [
    currentProgressStep,
    progressValue,
    progressLabel,
    overallProgressLabel,
    overallProgressValue,
  ] = handleProgressBarValues(
    getSpendingLimits,
    totalSpentAmount,
    t('finance.manageCard.manageCardLimitsModal.progressBlock.totalLimit'),
    t('finance.manageCard.manageCardLimitsModal.progressBlock.spentLimit')
  );

  return (
    <>
      <Modal
        onCloseEvent={handleCloseEvent}
        modalTitle={t('finance.manageCard.manageCardLimitsModal.title')}
      >
        <ShadowCard paddingX={4} paddingY={4} marginBottom={20}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              marginBottom: 4,
              marginTop: '2px',
              cursor: 'pointer',
              width: '100%',
            }}
          >
            <Typography
              component="p"
              variant="h2ExtraBold"
              textTransform={'uppercase'}
              sx={{
                marginBottom: 1,
                display: 'inline-flex',
                alignItems: 'center',
                color: 'gray.400',
              }}
            >
              {t('finance.manageCard.manageCardLimitsModal.cardTitle')}
            </Typography>
          </Box>
          <LimitsProgressCard
            title={t('finance.manageCard.manageCardLimitsModal.progressBlock.title')}
            icon="CardSpendingsIcon"
            totalProgressSteps={100}
            currentProgressStep={currentProgressStep}
            progressValue={progressValue}
            progressLabel={progressLabel}
            stepsContent={''}
            overallProgressLabel={overallProgressLabel}
            overallProgressValue={overallProgressValue}
            marginBottom={trussaryHiddenUIs.atmWitdrawalLimits ? 10 : 4}
          />
          {trussaryHiddenUIs.atmWitdrawalLimits && (
            <LimitsProgressCard
              title={'ATM Withdrawals'}
              icon="AtmWithdrawalsIcon"
              totalProgressSteps={100}
              currentProgressStep={(100 * 0) / 500}
              progressValue={'$0'}
              progressLabel={'Spent/Day'}
              stepsContent={''}
              overallProgressLabel={'Available/Day'}
              overallProgressValue={'$500'}
              marginBottom={4}
            />
          )}
        </ShadowCard>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 3,
            flexDirection: 'column',
            position: 'absolute',
            left: '16px',
            right: '16px',
            bottom: 0,
            top: 'auto',
            paddingBottom: 6,
          }}
          component={'div'}
        >
          <Button
            variant="containedLarge"
            color="primary"
            type="button"
            fullWidth
            onClick={handleCloseEvent}
            sx={{ overflow: 'hidden' }}
          >
            {t('buttonTexts.doneButton')}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
