import React from 'react';
import { WarningIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import { ErrorMessageWrapper } from './FormInputErrorMessage.style';
import { FormInputErrorMessageProps } from 'src/models/component/base.model';

export const FormInputErrorMessage = ({ children }: FormInputErrorMessageProps) => {
  return (
    <ErrorMessageWrapper>
      <div>
        <WarningIcon />
      </div>
      <span className="error_content">{children}</span>
    </ErrorMessageWrapper>
  );
};
