import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

export const ListImage = styled('img')(
  ({ theme }) => `
  width: auto; 
  
  @media(min-width: ${theme.breakpoints.values.sm}px){
   height: 240px; 
  }

  @media(max-width: ${theme.breakpoints.values.sm}px){
   height: 200px; 
  }
`
);

export const SplashScreenListViewWrapper = styled(Box)(
  ({ theme }) => `
 @media(min-width: ${theme.breakpoints.values.sm}px){
  margin-bottom: 0;
 }

 @media(max-width: ${theme.breakpoints.values.sm}px){
  margin-bottom: 16px;
 }
`
);
