import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from '../src/store';
import { AppRoutes } from './components/app/Routes';
import { Loader, ApiFailMessage } from './components/base/core';
import { trackClickEvent } from './components/analytics/service';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as FullStory from '@fullstory/browser';
import { configs } from './shared/config/config';

function App() {
  const { enableFullstoryConfigs } = useFlags();

  useEffect(() => {
    const onclick = (ev: any) => {
      trackClickEvent(ev);
    };
    window.addEventListener('click', onclick, false);
    return () => {
      window.removeEventListener('click', onclick, false);
    };
  });

  /**
   * Enabled and Disabled Fullstory with the feature flags.
   */
  useEffect(() => {
    if (enableFullstoryConfigs) {
      FullStory.init({ orgId: configs.FULLSTORY_ORG_ID });
    }
  }, [enableFullstoryConfigs]);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <AppRoutes />
        <Loader />
        <ApiFailMessage message="Something went wrong. Please contact the system administrator" />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
