import React from 'react';
import {
  PayoutsCounterWrapper,
  PayoutsCountercontainer,
  PayoutsAllSelector,
  PayoutsEditTrigger,
  PayoutsEditTriggerDisabled,
  PayoutsCounterContent,
} from './PayoutsCounter.style';

export interface PayoutsCounterProps {
  selectedInvoiceCount?: string | number;
  selectAllClick?: () => void;
  editPayoutsClick?: () => void;
  isSelectAll?: boolean;
}

export const PayoutsCounter = ({
  editPayoutsClick,
  selectAllClick,
  selectedInvoiceCount,
  isSelectAll,
}: PayoutsCounterProps) => {
  return (
    <PayoutsCounterWrapper>
      <PayoutsCountercontainer>
        <PayoutsAllSelector onClick={selectAllClick}>{`${
          isSelectAll ? 'Unselect all' : 'Select all'
        }`}</PayoutsAllSelector>
        {selectedInvoiceCount === 0 ? (
          <>
            <PayoutsCounterContent>{selectedInvoiceCount} Invoices Selected</PayoutsCounterContent>
            <PayoutsEditTriggerDisabled onClick={editPayoutsClick}>Edit</PayoutsEditTriggerDisabled>
          </>
        ) : (
          <>
            <PayoutsCounterContent>{selectedInvoiceCount} Invoices Selected</PayoutsCounterContent>
            <PayoutsEditTrigger onClick={editPayoutsClick}>Edit</PayoutsEditTrigger>
          </>
        )}
      </PayoutsCountercontainer>
    </PayoutsCounterWrapper>
  );
};
