import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Qs from 'qs';

import { getTranPaginationParams } from './helperPaginationHandler';

import {
  initFilterStatus,
  initialDownloadOptions,
  initialFilterOptions,
  initialFilterStatus,
} from '../shared/utilities/initialStates';
import { GridFilterStatus, payoutsProps } from '../shared/utilities/interface';
import moneyFormat from '../shared/utilities/moneyFormat';
import { getSelectedDate } from './helperHandlingDates';
import { configs } from 'src/shared/config/config';
import { convertToInt } from 'src/shared/utilities/dataConvertions';
import { getTransfersData } from './getTransfersData';

/**
 * Used to handle the Transactions page data
 * @returns TODO
 */
export const handleTranData = () => {
  const [response, setResponse]: any = useState();
  const [offset, setOffset] = useState(0);
  const [rowCount, setRowCount] = useState(configs.DEFAULT_LIMIT);
  const [pageCount, setPageCount] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filterStates, setFilterStates] = useState<any>(initialFilterOptions);
  const [filterAmountRange, setFilterAmountRange] = useState();
  const [selectedFilterOptions, setSelectedFilterOptions]: any = useState([]);
  const [resetFilter, setResetFilter] = useState(false);
  const [selectedDateFilters, setSelectedDateFilterOptions]: any = useState('All');
  const [selectedDateRange, setSelectedDateRange]: any = useState();
  const [filterAllCount, setFilterAllCount]: any = useState(0);
  const [isAllSelect, setAllSelect] = useState(true);
  const [filterStatus, setFilterStatus] = useState<GridFilterStatus>(initialFilterStatus);
  const [filteringApplied, setFilteringApplied] = useState(false);
  const [filterSearchParams, setFilterSearchParams]: any = useState();
  const [enableQuerySearch, setenableQuerySearch] = useState(false);
  const [savedMinmumAmount, setSavedMinmumAmount]: any = useState();
  const [savedMaximumAmount, setSavedMaximumAmount]: any = useState();
  const [downloadStates, setDownloadStates] = useState(initialDownloadOptions);
  const location = useLocation();

  useEffect(() => {
    let filterParamsSavedData = null;
    if (location?.search) {
      const x = location.search.substring(1);
      filterParamsSavedData = Qs.parse(location.search.substring(1));
      setFilterSearchParams(null);
      setFilterSearchParams(filterParamsSavedData);
      setTimeout(() => {
        setenableQuerySearch(true);
      }, 1000);
      getFilterCount();
    }
    setFilterStates({ ...filterStates, sort: 'CREATED_AT_DESC' });
    const dataParams = getTranPaginationParams(
      offset,
      rowCount,
      searchText,
      'CREATED_AT_DESC',
      null,
      filterAmountRange,
      getSelectedDate(selectedDateFilters),
      selectedDateRange
    );
    makeFiltersBasedOnQueryParams(filterParamsSavedData);
    updatePaginationBasedOnQueryParams(filterParamsSavedData);
    mapTranData(
      updateRequestBodyIfQueryParamsAvailable(
        filterParamsSavedData,
        filterParamsSavedData?.params ? filterParamsSavedData.params : dataParams
      )
    );
  }, []);

  const updateRequestBodyIfQueryParamsAvailable = (filterParamsSavedData: any, dataParam: any) => {
    if (
      filterParamsSavedData?.pagination &&
      Object.keys(filterParamsSavedData?.pagination)?.length > 0
    ) {
      const { offset, rowCount } = filterParamsSavedData?.pagination;

      return { ...dataParam, limit: rowCount, pageOffset: rowCount * offset, first: rowCount };
    }

    return dataParam;
  };

  const updatePaginationBasedOnQueryParams = (filterParamsSavedData: any) => {
    if (filterParamsSavedData?.pagination) {
      const { offset, rowCount } = filterParamsSavedData?.pagination;

      setOffset(offset ? convertToInt(offset) : 0);
      setRowCount(rowCount ? convertToInt(rowCount) : configs.DEFAULT_LIMIT);
    }
  };

  const makeFiltersBasedOnQueryParams = (filterParamsSavedData: any) => {
    if (filterParamsSavedData) {
      let filterSatus = {
        isAmountFilterActive: false,
        isDateFilterActive: false,
        isTermFilterActive: false,
        isSortBFilterActive: false,
        selectedDateFilterValue: { isRange: false, value: null },
      };
      let count = 0;
      const paramsData = filterParamsSavedData.params;
      const filterData = filterParamsSavedData.filterData;

      if (filterData) {
        // @ts-ignore
        if (filterData?.dateFilter !== null && filterData.dateFilter !== 'All') {
          count++;
          filterSatus.isDateFilterActive = true;
          // @ts-ignore
          setSelectedDateFilterOptions(filterData.dateFilter);
          filterSatus.selectedDateFilterValue = { isRange: false, value: filterData.dateFilter };
        }
        if (filterData.dateRange?.length > 0 && filterData.dateFilter !== 'All') {
          count++;
          filterSatus.isDateFilterActive = true;
          setSelectedDateRange(filterData.dateRange);
          filterSatus.selectedDateFilterValue = { value: filterData.dateRange, isRange: true };
        }
        // @ts-ignore
        if (filterData?.amount) {
          count++;
          filterSatus.isAmountFilterActive = true;
          // @ts-ignore
          setFilterAmountRange({ max: filterData?.amount.max, min: filterData?.amount.min });
        }
        // @ts-ignore
        if (filterData?.terms?.payout_term_ids?.length > 0) {
          count++;
          filterSatus.isTermFilterActive = true;
          // @ts-ignore
          setSelectedFilterOptions({ payout_term_ids: filterData?.terms?.payout_term_ids });
        }
        // @ts-ignore
        setAllSelect(filterData?.termAll === 'true');
        // @ts-ignore
        if (filterData?.sort?.toString() !== 'CREATED_AT_DESC') {
          count++;
          filterSatus.isSortBFilterActive = true;
          setFilterStates({ ...filterStates, sort: filterData?.sort.toString() });
        }

        if (filterData?.searchText !== '') {
          setSearchText(filterData.searchText);
        }
        if (localStorage.getItem('ear-min') && localStorage.getItem('ear-max')) {
          setSavedMinmumAmount(localStorage.getItem('ear-min'));
          setSavedMaximumAmount(localStorage.getItem('ear-max'));
        }
      }
      setFilterStatus(filterSatus);
      setFilterAllCount(count);
    }
  };
  const mapTranData = async (dataParams: any, pagingInprogress: boolean = false) => {
    // set existing invoice data
    setShowLoader(pagingInprogress);
    const { data, totalCnt } = await getTransfersData(dataParams);
    setShowLoader(false);
    setResponse(data);
    setPageCount(totalCnt);
    // scrollToPosition();
  };

  const handleShowMore = async (offsetNext: any) => {
    const dataParams: payoutsProps = getTranPaginationParams(
      offsetNext,
      rowCount,
      searchText,
      filterStates.sort,
      selectedFilterOptions,
      filterAmountRange,
      getSelectedDate(selectedDateFilters),
      selectedDateRange
    );
    setOffset(offsetNext);
    mapTranData(dataParams, true);
  };

  const handleRowCountChange = async (newRowCount: number) => {
    const dataParams: payoutsProps = getTranPaginationParams(
      0,
      newRowCount,
      searchText,
      filterStates.sort,
      selectedFilterOptions,
      filterAmountRange,
      getSelectedDate(selectedDateFilters),
      selectedDateRange
    );
    setOffset(0);
    setRowCount(newRowCount);
    mapTranData(dataParams, true);
  };

  const tranData = response?.payoutBatchTransfers?.nodes;
  const payout_direct_amount = response?.payoutBatchTransfers?.aggregates?.sum?.paid_amount;
  const payout_amount = payout_direct_amount
    ? moneyFormat(parseFloat(payout_direct_amount)).split('.')
    : '-';
  const decimalAmount = payout_direct_amount ? payout_amount[1] : '';
  const totalInvoices =
    response?.payoutBatchTransfers && response?.payoutBatchTransfers?.totalCount;

  const roundedMin = response?.payoutBatchTransfers?.aggregates?.min?.paid_amount
    ? Math.round(response?.payoutBatchTransfers?.aggregates?.min?.paid_amount)
    : 0;
  const minmumAmount = roundedMin > 0 ? roundedMin - 1 : 0;
  const maximumAmount = response?.payoutBatchTransfers?.aggregates?.max?.paid_amount
    ? Math.round(response?.payoutBatchTransfers?.aggregates?.max?.paid_amount) + 1
    : 100;

  /**
   * search Tran Invoice by Title
   */
  const handleSearch = (event: any) => {
    setSearchText(event?.target?.value);
    setSearchInProgress(true);
    const dataParams = getTranPaginationParams(
      offset,
      rowCount,
      event?.target?.value,
      filterStates.sort,
      selectedFilterOptions,
      filterAmountRange,
      getSelectedDate(selectedDateFilters),
      selectedDateRange
    );
    mapTranData(dataParams);
    setFilterSearchParams(null);
    setFilterSearchParams({
      params: dataParams,
      pagination: {
        offset,
        rowCount,
      },
      filterData: {
        offsetCurrent: offset,
        searchText: event?.target?.value,
        sort: filterStates.sort,
        terms: selectedFilterOptions,
        amount: filterAmountRange,
        dateFilter: selectedDateFilters,
        dateRange: selectedDateRange,
        termAll: isAllSelect,
      },
    });
    setFilteringApplied(true);
  };

  const handleResetSearch = () => {
    setSearchText('');
    const dataParams = getTranPaginationParams(
      offset,
      rowCount,
      '',
      filterStates.sort,
      null,
      filterAmountRange,
      getSelectedDate(selectedDateFilters),
      selectedDateRange
    );
    mapTranData(dataParams);
    setFilterSearchParams(null);
    setFilterSearchParams({
      params: dataParams,
      pagination: {
        offset,
        rowCount,
      },
      filterData: {
        offsetCurrent: offset,
        searchText: '',
        sort: filterStates.sort,
        terms: selectedFilterOptions,
        amount: filterAmountRange,
        dateFilter: selectedDateFilters,
        dateRange: selectedDateRange,
        termAll: isAllSelect,
      },
    });
    setFilteringApplied(true);
  };

  const handleFilterOpen = () => {
    setFilterStates({ ...filterStates, isFilterEnable: true });
  };

  const handleFilterClose = () => {
    setFilterStates({ ...filterStates, isFilterEnable: false });
  };

  /**
   * Handle data sorting
   */
  const handleDataSorting = (e: any) => {
    setFilterStates({ ...filterStates, sort: e.currentTarget.value });
  };

  const getFilterCount = () => {
    let filterSatus = {
      isAmountFilterActive: false,
      isDateFilterActive: false,
      isTermFilterActive: false,
      isSortBFilterActive: false,
      selectedDateFilterValue: { isRange: false, value: null },
    };
    let count = 0;
    if (filterStates?.sort?.toString() !== 'CREATED_AT_DESC') {
      count++;
      filterSatus.isSortBFilterActive = true;
    }
    if (selectedFilterOptions?.payout_term_ids?.length > 0 && !isAllSelect) {
      count++;
      filterSatus.isTermFilterActive = true;
    }
    if (selectedDateRange?.length > 0 && selectedDateFilters !== 'All') {
      count++;
      filterSatus.isDateFilterActive = true;
      filterSatus.selectedDateFilterValue = { value: selectedDateRange, isRange: true };
    }
    if (selectedDateFilters === 'All') {
      filterSatus.selectedDateFilterValue = { isRange: false, value: null };
    }
    if (selectedDateFilters > 0 && selectedDateFilters !== 'All') {
      count++;
      filterSatus.isDateFilterActive = true;
      filterSatus.selectedDateFilterValue = { value: selectedDateFilters, isRange: false };
    }

    if (filterAmountRange) {
      count++;
      filterSatus.isAmountFilterActive = true;
    }
    setFilterAllCount(count);
    setFilterStatus(filterSatus);
  };

  /**
   * Handle reset all button
   */
  const handleFilterReset = () => {
    setFilterStates({
      isFilterEnable: true,
      sort: 'CREATED_AT_DESC',
    });
    setFilterAmountRange(undefined);
    setSelectedDateFilterOptions('All');
    setSelectedDateRange([]);
    setSelectedFilterOptions([]);
    setResetFilter(!resetFilter);
    setAllSelect(true);
    const dataParams = getTranPaginationParams(
      offset,
      rowCount,
      searchText,
      'CREATED_AT_DESC',
      null,
      undefined,
      getSelectedDate('All'),
      []
    );
    mapTranData(dataParams);
    /**
     * reset count and filter applied check
     */
    setFilterAllCount(0);
    setFilterStatus(initFilterStatus);
    setFilterSearchParams(null);
    setFilterSearchParams({
      params: dataParams,
      pagination: {
        offset,
        rowCount,
      },
      filterData: {
        offsetCurrent: offset,
        searchText: searchText,
        sort: filterStates.sort,
        terms: selectedFilterOptions,
        amount: filterAmountRange,
        dateFilter: selectedDateFilters,
        dateRange: selectedDateRange,
        termAll: isAllSelect,
      },
    });
    setFilteringApplied(true);
  };

  /**
   * Handle filter apply button
   */
  const handleApplyFilter = () => {
    setSearchInProgress(true);
    getFilterCount();
    const dataParams = getTranPaginationParams(
      offset,
      rowCount,
      searchText,
      filterStates.sort,
      selectedFilterOptions,
      filterAmountRange,
      getSelectedDate(selectedDateFilters),
      selectedDateRange
    );
    mapTranData(dataParams);

    handleFilterClose();
    if (resetFilter) {
      setFilteringApplied(false);
    } else {
      setFilteringApplied(true);
    }
    setFilterSearchParams(null);
    setFilterSearchParams({
      params: dataParams,
      pagination: {
        offset,
        rowCount,
      },
      filterData: {
        offsetCurrent: offset,
        searchText: searchText,
        sort: filterStates.sort,
        terms: selectedFilterOptions,
        amount: filterAmountRange,
        dateFilter: selectedDateFilters,
        dateRange: selectedDateRange,
        termAll: isAllSelect,
      },
    });
  };

  const onDateChange = (dates: any) => {
    setSelectedDateFilterOptions(null);
    setSelectedDateRange(dates);
  };

  return [
    offset,
    pageCount,
    showLoader,
    searchInProgress,
    handleShowMore,
    rowCount,
    handleRowCountChange,
    tranData,
    decimalAmount,
    payout_amount,
    handleSearch,
    handleDataSorting,
    handleApplyFilter,
    handleFilterOpen,
    handleFilterClose,
    handleFilterReset,
    filterStates,
    setSelectedFilterOptions,
    selectedFilterOptions,
    setFilterAmountRange,
    resetFilter,
    setSelectedDateFilterOptions,
    selectedDateFilters,
    minmumAmount,
    maximumAmount,
    searchText,
    handleResetSearch,
    onDateChange,
    selectedDateRange,
    filterAllCount,
    setSelectedDateRange,
    setAllSelect,
    isAllSelect,
    totalInvoices,
    filterStatus,
    filteringApplied,
    filterSearchParams,
    setResetFilter,
    enableQuerySearch,
    savedMinmumAmount,
    savedMaximumAmount,
    downloadStates,
    setDownloadStates,
  ];
};
