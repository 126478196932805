import { Typography } from '@mui/material';
import {
  TopConnectorLine,
  ConnectorContainer,
  Container,
  SecondRow,
  DetailsSection,
  ConnectorDot,
  BottomConnectorLine,
} from './TimelineNoteItem.style';
import { TimeLineNoteItemProps } from 'src/models/component/base.model';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useLayoutEffect, useRef, useState } from 'react';

export const TimelineNoteItem = ({
  itemName,
  description,
  dateText,
  timeText,
  position,
  hasNext = false,
}: TimeLineNoteItemProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isExpanded, setExpanded] = useState(false);
  const [isFirstRender, setFirstRender] = useState(true);
  const [height, setHeight] = useState(58);
  const [initHeight, setInitHeight] = useState(58);
  const ref = useRef(null);

  useLayoutEffect(() => {
    // @ts-ignore
    const val = ref?.current?.clientHeight;
    if (isFirstRender) {
      setFirstRender(false);
      setInitHeight(val ?? 58);
    }
    setHeight(val ?? 58);
  });

  return (
    <Container detailsSectionHeight={height} position={position}>
      <ConnectorContainer>
        <TopConnectorLine initHeight={initHeight} position={position} />
        <BottomConnectorLine
          isActive={hasNext}
          detailsSectionHeight={height}
          initHeight={initHeight}
        />
        <ConnectorDot initHeight={initHeight} position={position} />
      </ConnectorContainer>
      <DetailsSection ref={ref} position={position}>
        <Typography variant="h2Bold" component={'p'} color={'gray.700'} sx={{ marginBottom: 1 }}>
          {itemName}
        </Typography>
        <SecondRow>
          <SecondRow>
            <Typography variant="h1" color={'gray.500'}>
              {dateText}
            </Typography>
            {timeText ? (
              <Typography variant="h1" component={'p'} color={'gray.500'} sx={{ marginLeft: 0.75 }}>
                <span>• </span>
                {timeText}
              </Typography>
            ) : null}
          </SecondRow>
          {description ? (
            <Typography
              onClick={() => setExpanded(!isExpanded)}
              variant="h1"
              color={'gray.500'}
              sx={{ textDecorationLine: 'underline' }}
            >
              {isExpanded ? t('invoiceSummary.middle.less') : t('invoiceSummary.middle.details')}
            </Typography>
          ) : null}
        </SecondRow>
        {isExpanded ? (
          <Typography
            variant="h2"
            component={'p'}
            color={'gray.900'}
            sx={{ marginTop: 3, marginBottom: 1 }}
          >
            {description}
          </Typography>
        ) : null}
      </DetailsSection>
    </Container>
  );
};
