import { styled } from '@mui/material/styles';

export const ListItem = styled('div')(
  ({ theme }) => `
   display: flex;
   background: ${theme.palette.white};
   border: 1px solid ${theme.palette.gray[100]};
   border-radius: 8px;
   height: 52px;
   cursor: pointer;
   margin-bottom: ${theme.spacing(3)};
   padding-left:${theme.spacing(4)};
   padding-right:${theme.spacing(4)};
   justify-content: space-between;
   align-items: center;

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    &.hidden_desktop {
      display: none;
    }
  }

   .list_item_left {
    display: flex;
    align-items: center;
   }

   .left_icon_block {
    margin-right: ${theme.spacing(2)};

    svg {
      color: ${theme.palette.gray[600]};
    }
   }

   .icon_wrapper {
     transform: rotate(-90deg);
     svg {
       color: ${theme.palette.gray[300]};
     }
   }

   &:hover {
     border: 1px solid ${theme.palette.blue[500]};
     background: ${theme.palette.blue[50]};
     .icon_wrapper {
       svg {
         color: ${theme.palette.blue[500]};
       }
     }
   }
`
);
