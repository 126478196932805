import React from 'react';
import { Typography } from '@mui/material';
import {
  ReviewCardWrapper,
  ReviewCardContainer,
  EditIconWrapper,
  ReviewCardTop,
  StatusContainer,
} from './ReviewCard.style';
import { ReviewCardProps } from 'src/models/component/base.model';
import { AccountVerificationStatusses } from '../../Statusses/AccountVerificationStatusses/AccountVerificationStatusses';
import { EditFilledIcon } from '../../Media/Icon/Icons/Icons';

export const ReviewCard = ({
  title,
  editIconClickEvent,
  name,
  children,
  hideEdit = false,
  type,
  isInnerTitle = false,
  isShadowWrappedIcon = false,
}: ReviewCardProps) => {
  // @ts-ignore
  const styleForUndefined = children?.length === undefined ? 'edit-empty' : '';

  return (
    <ReviewCardWrapper sx={{ margin: isInnerTitle ? '0px' : '0 0 24px' }}>
      {!isInnerTitle && (
        <ReviewCardTop className={type ? 'enabled_status' : 'disabled_status'}>
          {title && (
            <Typography textTransform={'uppercase'} variant="caption2" color={'gray.500'}>
              {title}
            </Typography>
          )}
          {type && type !== undefined && <AccountVerificationStatusses type={type} />}
        </ReviewCardTop>
      )}
      <ReviewCardContainer
        sx={{ padding: isInnerTitle ? '0px' : '16px 38px 12px 16px', boader: '1px solid grsy.100' }}
      >
        {isInnerTitle && (
          <ReviewCardTop className={type ? 'enabled_status' : 'disabled_status'}>
            {title && (
              <Typography
                textTransform={'uppercase'}
                variant="caption2"
                color={isShadowWrappedIcon ? 'gray.500' : 'gray.400'}
                marginBottom={isShadowWrappedIcon ? 1 : 0}
              >
                {title}
              </Typography>
            )}
            <StatusContainer>
              {type && type !== undefined && <AccountVerificationStatusses type={type} />}
            </StatusContainer>
          </ReviewCardTop>
        )}
        {name && (
          <Typography
            textTransform={'capitalize'}
            variant="h2Bold"
            color={'gray.800'}
            marginBottom={2}
            className="fs-mask"
          >
            {name}
          </Typography>
        )}
        <div className="fs-mask">{children}</div>
        {!hideEdit && (
          <EditIconWrapper
            onClick={editIconClickEvent}
            className={`${styleForUndefined} ${isShadowWrappedIcon ? 'is_shadow_iconed' : ''}`}
          >
            <EditFilledIcon />
          </EditIconWrapper>
        )}
      </ReviewCardContainer>
    </ReviewCardWrapper>
  );
};
