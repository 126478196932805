import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { getAppInit } from 'src/services/init/init.service';
import { AvatarIcon } from '../../Media/Icon/Icons/Icons';

import {
  SettingsBlockUserBlock,
  SettingsAvatar,
  SettingsContact,
  AvatarText,
} from './UserIdentitify.style';
import { getMyAccountHeader } from 'src/shared/config/config';

export const UserIdentitify = () => {
  const [userDetails, setUserDetails]: any = useState(null);

  useEffect(() => {
    getAppInit().then(async (response) => {
      const myAccount = getMyAccountHeader(response);
      setUserDetails(myAccount);
    });
  }, []);

  // @ts-ignore
  const fullName = `${userDetails?.first_name ?? ''} ${userDetails?.last_name ?? ''}`;
  // @ts-ignore
  const email = userDetails?.email ?? '';

  const checkFirstNameAvailableOrNot = userDetails && userDetails?.first_name;
  // @ts-ignore
  const avatarText =
    userDetails && userDetails?.first_name
      ? `${userDetails?.first_name?.charAt(0)}${userDetails?.last_name?.charAt(0)}`
      : '';
  return (
    <SettingsBlockUserBlock>
      {userDetails && (
        <>
          {checkFirstNameAvailableOrNot && (
            <SettingsAvatar>
              <AvatarIcon />
              <AvatarText>
                <Typography
                  variant="h2Bold700"
                  sx={{ marginLeft: '12px', textAlign: 'center', textTransform: 'uppercase' }}
                  color="white"
                  component="span"
                >
                  {avatarText.trim()}
                </Typography>
              </AvatarText>
            </SettingsAvatar>
          )}
          <SettingsContact>
            {checkFirstNameAvailableOrNot && (
              <Typography
                variant="h3Bold"
                sx={{ marginLeft: '10px', textTransform: 'capitalize' }}
                color="gray.900"
              >
                {fullName}
              </Typography>
            )}
            <Typography
              variant="h1"
              sx={{ marginTop: '4px', marginLeft: '12px' }}
              color="darkSlateGray"
            >
              {email}
            </Typography>
          </SettingsContact>
        </>
      )}
    </SettingsBlockUserBlock>
  );
};
