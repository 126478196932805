import { createApi } from '@reduxjs/toolkit/query/react';
import { prepareBaseQuery } from '../../shared/config/config';

export const notificationPrefsApi = createApi({
  reducerPath: 'notificationPrefsApi',
  tagTypes: ['Notification-Preferences'],
  baseQuery: prepareBaseQuery(false),
  endpoints: (builder) => ({
    getNotificationPreferences: builder.query({
      query: () => ({
        url: `/query/notification-preferences`,
        method: 'POST',
        body: {
          limit: 1000,
          offset: 0,
          filter: {},
        },
      }),

      providesTags: ['Notification-Preferences'],
    }),
    updateNotificationPreferences: builder.mutation({
      query: ({ id, is_active }) => ({
        url: `/notification-preference/${id}`,
        method: 'POST',
        body: {
          is_active: is_active,
        },
      }),
      invalidatesTags: ['Notification-Preferences'],
    }),
  }),
});
export const { useGetNotificationPreferencesQuery, useUpdateNotificationPreferencesMutation } =
  notificationPrefsApi;
