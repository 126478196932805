import { ActionType } from '../action-types/index';
import { Action } from '../actions';
import { BankInit } from '../../models/init/account.init';
import { BankDetailsParams } from '../../shared/utilities/interface';

const reducer = (state: BankDetailsParams = BankInit, action: Action): any => {
  switch (action.type) {
    case ActionType.GET_BANK_DETAILS:
    case ActionType.BANK_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
      break;
    default:
      return null;
  }
};

export default reducer;
