import styled from 'styled-components';

export const Tbody = styled.div`
  display: flex;
  width: 100%;
  padding: 0 24px;
`;

export const Tr = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Th = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  .skelton-2,
  .skelton {
    display: flex;
    width: 100%;
    height: 9px;
    background: rgba(26, 31, 54, 0.08);
    border-radius: 2px;
  }

  @media (max-width: 767px) {
    padding: 10px 0 7px;

    .skelton {
      margin: 0 0 3px;
    }

    &.first {
      max-width: calc(100% - 80px);
      flex-direction: column;
      justify-content: left;
      align-items: flex-start;

      .skelton {
        max-width: 113px;
      }

      .skelton-2 {
        max-width: 60px;
      }
    }

    &.second {
      max-width: 80px;
      justify-content: left;
      align-items: flex-end;
      flex-direction: column;

      .skelton {
        max-width: 52px;
      }
    }
  }

  @media (min-width: 768px) {
    padding: 20px 0;

    &.first {
      max-width: calc(100% - 320px);

      .skelton {
        max-width: 168px;
      }
    }

    &.second {
      max-width: 200px;

      .skelton {
        max-width: 111px;
      }
    }

    &.third {
      max-width: 120px;
    }
  }
`;
