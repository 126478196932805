import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import {
  ModalWrapper,
  ModalOverlay,
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalButton,
  ModalClose,
  ModalResetAll,
} from './FilterModal.style';
import { Icon } from '../../../..';
import { Button, Typography } from '@mui/material';
import { ShadowCard } from '../../../..';

import { DataSorting } from '../DataSorting/DataSorting';
import { AmountFilter, DateFilter } from '../FilterRows';

const domNode: any = document.getElementById('app-root');

export interface TransactionModalFilterProps {
  onModalClose: any;
  onFilterReset: any;
  handleDateSorting?: any;
  selectedDateSortingOption?: string;
  onClickApplyFilter?: any;
  resetFilter?: any;
  onChangeDateFilter?: any;
  selectedDateFilterOption?: string;
  onChangeDateCustomEvent?: any;
  selectedCustomDateRange?: any;
  onCancelDateCustomEvent?: any;
  setFilterAmountRange?: any;
  minmumAmount?: any;
  maximumAmount?: any;
  selectedMinRange?: any;
  setSelectedMinRange?: any;
  isAmountFilterActive?: boolean;
}

export const TransactionFilterModal = ({
  onModalClose,
  onFilterReset,
  handleDateSorting,
  selectedDateSortingOption,
  onClickApplyFilter,
  resetFilter,
  onChangeDateFilter,
  selectedDateFilterOption,
  onChangeDateCustomEvent,
  selectedCustomDateRange,
  onCancelDateCustomEvent,
  setFilterAmountRange,
  minmumAmount,
  maximumAmount,
  selectedMinRange,
  setSelectedMinRange,
  isAmountFilterActive,
}: TransactionModalFilterProps) => {
  const onFilterResetNew = () => {
    setFilterAmountRange(undefined);
    setSelectedMinRange({ min: minmumAmount, max: maximumAmount });
    onFilterReset && onFilterReset();
  };

  const { t } = useTranslation();

  return ReactDOM.createPortal(
    <>
      <ModalWrapper>
        <ModalOverlay onClick={onModalClose} />
        <ModalContainer className="is_fixed">
          <ModalResetAll onClick={onFilterResetNew}> {t('earning.filter.resetAll')}</ModalResetAll>
          <ModalClose onClick={onModalClose}>
            <Icon iconType="CloseIcon" />
          </ModalClose>
          <ModalHeader>
            <Typography variant="h3Bold" color="gray.800" component={'p'} textAlign="center">
              {t('earning.filter.title')}
            </Typography>
          </ModalHeader>
          <ModalContent>
            <ShadowCard paddingY={2}>
              <AmountFilter
                setFilterAmountRange={setFilterAmountRange}
                resetFilter={resetFilter}
                minmumAmount={minmumAmount}
                maximumAmount={maximumAmount}
                selectedMinRange={selectedMinRange}
                setSelectedMinRange={setSelectedMinRange}
                isAmountFilterActive={isAmountFilterActive}
              />

              <DateFilter
                onChangeDate={onChangeDateFilter}
                selectedDateOption={selectedDateFilterOption}
                onChangeDateCustomEvent={onChangeDateCustomEvent}
                onCancelDateCustomEvent={onCancelDateCustomEvent}
                selectedDateRange={selectedCustomDateRange}
                resetFilter={resetFilter}
                isRangeFilterActive={selectedCustomDateRange && selectedCustomDateRange.length > 0}
              />

              <DataSorting
                handleOnChangeDate={handleDateSorting}
                selectedOptionDateSorting={selectedDateSortingOption}
                t={t}
              />
            </ShadowCard>
          </ModalContent>
          <ModalButton>
            <Button
              variant="containedLarge"
              color="primary"
              type="button"
              fullWidth
              onClick={onClickApplyFilter}
              sx={{ overflow: 'hidden' }}
            >
              {t('earning.filter.applyBtn')}
            </Button>
          </ModalButton>
        </ModalContainer>
      </ModalWrapper>
    </>,
    domNode
  );
};
