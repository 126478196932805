import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import auth0 from 'auth0-js';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { AuthResponse } from '../../../models/login.model';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useReadOTP } from 'react-read-otp';
import { Box } from '@mui/material';
import { Header, Wrapper, HeadingSummaryBlock, Form } from 'src/components/base/core';
import { handleVerifyCode } from 'src/helpers/helperVerifyCode';
import { useEffect } from 'react';
import { trackEvent } from '../../../components/analytics/service';
import { ReactInputVerificationCode } from 'src/components/base/core/FormElements/VerificatonCodeInput/ReactInputVerificationCode';

import { getWebAuth } from 'src/shared/config/config';
import { useResendEmailMutation } from 'src/services/auth/authServiceApi';
import { UnVerifyEmailContainer } from '../UnVerifyEmail/UnVerifyEmail';
import { VerifiedEmailContainer } from '../VerifiedEmail/VerifiedEmail';
import { AccessDeniedContainer } from '../AccessDenied/AccessDenied';

interface AccountVerifyPhoneProps {
  verificationData?: (data: AuthResponse) => void;
}

const AccountLoginSchema = yup.object().shape({
  code: yup.string().required('Please enter a full 6 digit code'),
});

type LocationState = {
  phone: string;
  phoneWithMask: string;
};

type emailVerificationStatus =
  | 'verified'
  | 'unVerified'
  | 'notProceedYet'
  | 'errorLogin'
  | undefined;

export const AccountVerifyCodeContainer = ({ verificationData }: AccountVerifyPhoneProps) => {
  const [
    setPrimaryContacts,
    getInputValues,
    inputCodeIdRef,
    handleChange,
    setValues,
    // clearInputs,
    handleResendCode,
    handleOnKeydown,
    handleAuthCallback,
  ] = handleVerifyCode();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(AccountLoginSchema) });

  const [emailResend, { isLoading }] = useResendEmailMutation();

  const [error, setError] = useState('');
  const [isVerified, setisVerified] = useState(false);
  const [isWrong, setisWrong] = useState(false);
  const [emailVerified, setEmailVerified] = useState<emailVerificationStatus>();
  const [userEmail, setUserEmail] = useState('');
  const [isCoupaError, setIsCoupaError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { webauthnEnabled } = useFlags();
  const getFromResend = localStorage.getItem('from_resend');
  const getFromLogin = localStorage.getItem('from_login');
  const getFromSignup = localStorage.getItem('from_signup');

  const [enabled, setEnabled] = useState(true);
  const [otp, setOTP] = useState('');
  useReadOTP(setOTP, {
    enabled,
    onError(err) {
      console.log(err);
    },
  });

  const { phone, phoneWithMask } = location.state
    ? (location.state as LocationState)
    : { phone: null, phoneWithMask: null };

  if (!webauthnEnabled && !phone) {
    setTimeout(function () {
      navigate('/');
    }, 100);
  }

  const appLogin = handleSubmit(async () => {});

  const linkToLogin = () => navigate('/verify-login');

  const handleBackButton = () => navigate('/verify-login');

  useEffect(() => {
    if (otp.length === 6) {
      handleVerificationChange(otp);
    }
  }, [otp]);

  useEffect(() => {
    if (webauthnEnabled && location.search?.length > 0) {
      // @ts-ignore
      handleAuthCallback(
        location.search,
        navigate,
        setError,
        setisVerified,
        setisWrong,
        setEmailVerified,
        setUserEmail,
        getFromResend,
        getFromLogin,
        getFromSignup
      );
    }
  }, [location.search]);

  const { webAuth } = getWebAuth();

  const onProceedClick = () => {
    localStorage.setItem('from_resend', 'yes');
    localStorage.setItem('from_login', 'no');
    localStorage.setItem('from_signup', 'no');
    webAuth.authorize({
      responseType: 'code',
      redirectUri: `${window.location.origin}/verify-code`,
    });
  };

  const handleVerificationChange = (values: any) => {
    // @ts-ignore
    handleChange(values, navigate, phone, setError, setisVerified, setisWrong);
  };

  const handleOnKeydownHandler = (event: any) => {
    // @ts-ignore
    handleOnKeydown(event);
  };

  const handleResendCodeHandler = () => {
    trackEvent('Resend Otp', { number: phone });
    setisWrong(false);
    setIsCoupaError(false);
    // @ts-ignore
    handleResendCode(phone, setisWrong);
  };

  const handleResendEmail = async () => {
    await emailResend('');
    localStorage.setItem('from_resend', 'yes');
    localStorage.setItem('from_signup', 'no');
  };

  if (webauthnEnabled) {
    const getErrorfalse = error === undefined || error === '' || !error;
    if (
      location.search?.length > 0 &&
      !isVerified &&
      emailVerified === 'unVerified' &&
      getErrorfalse
    ) {
      return (
        <UnVerifyEmailContainer
          handleResendEmail={handleResendEmail}
          onProceedClick={onProceedClick}
          isLoading={isLoading}
        />
      );
    }

    if (emailVerified === 'verified' && getFromResend === 'yes' && getErrorfalse) {
      return <VerifiedEmailContainer />;
    }

    if (
      emailVerified === 'verified' &&
      (getFromLogin === 'yes' || getFromSignup === 'yes') &&
      getFromResend === 'no' &&
      getErrorfalse
    ) {
      return <VerifiedEmailContainer isLogin />;
    }

    if (emailVerified === 'errorLogin' && !getErrorfalse) {
      return <AccessDeniedContainer errorMessage={error} />;
    }

    return (
      <Wrapper>
        <Box
          sx={{
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      </Wrapper>
    );
  }

  return (
    <>
      <Wrapper isFullScreen>
        <Header isBackButtonVisible={true} onBackButtonClick={handleBackButton} />
        <Box
          sx={{ paddingLeft: 3, marginBottom: 6, paddingRight: 3, height: '93px' }}
          className={'fs-mask'}
        >
          <HeadingSummaryBlock
            heading={
              isVerified
                ? t('headingContent.verifyCodeSuccess.loginComplete')
                : t('headingContent.verifyCode.heading')
            }
            headingAlignment={isVerified ? 'center' : 'center'}
            mobileHeadingAlignment={isVerified ? 'center' : 'left'}
            mobileContentAlignment={'left'}
            content={!isVerified ? `${t('headingContent.verifyCode.content')}` : null}
            contentNewLine={!isVerified ? `${phoneWithMask}` : null}
            contentAlignment="center"
          />
        </Box>
        <div>{otp}</div>
        <Form onSubmit={appLogin}>
          <ReactInputVerificationCode
            autoFocus
            onChange={(value: any) => {
              console.log(value);
            }}
            onCompleted={(value: any) => handleVerificationChange(value)}
            error={errors.code?.message}
            isWrong={isWrong}
            isVerified={isVerified}
            coupaError={isCoupaError}
            errorMsg={error}
            onClickResendOtp={handleResendCodeHandler}
            enableResend
          />
          <input
            id={'code'}
            type="hidden"
            maxLength={6}
            minLength={6}
            {...register('code')}
            value={''}
          />
        </Form>
      </Wrapper>
    </>
  );
};
