import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import {
  AccountVerificationStatusses,
  Messages,
  ReviewCard,
  ShadowCard,
} from '../../../components/base/core';
import {
  AddressCityStateZip,
  RecipentAddressCityStateZip,
} from 'src/shared/utilities/addressUtils';
import { RefreshIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import { AccountInfoEditModal } from './AccountInfoEdit/AccountInfoEdit';

import { helperAccountInfo } from 'src/helpers/containers/helperAccountInfo';

import { phoneBusinessNumberMask, phoneNumberMask } from 'src/shared/utilities/phoneNumberMask';
import { getFormattedDate } from 'src/shared/utilities/dob';
import { handleSettingBusinessAIVSAM } from 'src/shared/utilities/dataConvertions';
import { checkIsAnyOfTheRecipentDetailsAreAddedOrNot } from 'src/helpers/helperHandlingTerms';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const AccountInfoBusinessOwnership = ({
  accountData,
  isCardBorderEnabled = false,
  fromHome = false,
  setFormSubmitDone,
  formSubmitDone,
}: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { recipientDetailsFormEnabled } = useFlags();

  const [result, setResult]: any = useState();
  const [states, setStates]: any = helperAccountInfo();
  const [isPersonal, setIsPersonal]: any = useState();

  useEffect(() => {
    if (accountData) {
      setResult(accountData);
    }
  }, [accountData]);

  useEffect(() => {
    if (fromHome) {
      setStates({ ...states, openModal: true });
    } else {
      setStates({ ...states, openModal: false });
    }
  }, [fromHome]);

  const [messageObject, verifyObj]: any = handleSettingBusinessAIVSAM(accountData, t);
  const isChargesEnabled = result?.charges_enabled;
  const isTaxIDProvided = result?.currently_due_requirements.includes('company.tax_id');
  const verificationStatus = result?.individual?.verification?.status;

  const commonOnsubmitEvent = () => {
    handleCancelEvent();
    localStorage.removeItem('review_error_block');
    navigate('/settings/hold-on');
  };

  const onFormSubmitBusinsessPersonalInfo = () => {
    if (
      verifyObj.personal === 'verified' &&
      verifyObj.business === 'unverified' &&
      !isChargesEnabled
    ) {
      handleCancelEvent();
      localStorage.removeItem('review_error_block');
    } else if (
      verifyObj.personal === 'unverified' &&
      verifyObj.business === 'verified' &&
      !isChargesEnabled
    ) {
      commonOnsubmitEvent();
    } else if (
      verifyObj.personal === 'verified' &&
      verifyObj.business === 'verified' &&
      !verifyObj.tos
    ) {
      commonOnsubmitEvent();
    } else {
      handleCancelEvent();
      localStorage.removeItem('review_error_block');
    }

    setFormSubmitDone(!formSubmitDone);
  };

  const onFormSubmitBusinsessInfo = () => {
    if (
      verifyObj.business === 'verified' &&
      verifyObj.personal === 'unverified' &&
      !isChargesEnabled
    ) {
      handleCancelEvent();
      localStorage.removeItem('review_error_block');
    } else if (
      verifyObj.business === 'unverified' &&
      verifyObj.personal === 'verified' &&
      !isChargesEnabled
    ) {
      commonOnsubmitEvent();
    } else if (
      verifyObj.business === 'verified' &&
      verifyObj.personal === 'verified' &&
      !verifyObj.tos
    ) {
      commonOnsubmitEvent();
    } else {
      handleCancelEvent();
      localStorage.removeItem('review_error_block');
    }

    setFormSubmitDone(!formSubmitDone);
  };

  const handleCancelEvent = () => {
    setStates({ ...states, openModal: false });
  };

  const editDetails = (isPersonal: any) => {
    setIsPersonal(isPersonal);
    setStates({ ...states, openModal: true });
  };

  const verificationDocumentFront = result?.individual?.verification?.document.front;
  const verificationDocumentBack = result?.individual?.verification?.document.back;
  const taxDocFront = result?.company?.verification.document.front;
  const taxDocBack = result?.company?.verification.document.back;
  const isTaxDocIDAdded =
    (taxDocFront && taxDocFront !== undefined) || (taxDocBack && taxDocBack !== undefined);

  const getFirstName = result?.individual?.first_name ?? '';
  const getLastName = result?.individual?.last_name ?? '';

  const dateObject = result?.individual?.dob;
  const checkDOBNull =
    dateObject && dateObject?.day !== null && dateObject.month !== null && dateObject.year !== null;

  const [isRecipentDetailsAreAvailable] = checkIsAnyOfTheRecipentDetailsAreAddedOrNot(result);

  const onClickEditRecipientInfo = () => {
    navigate('/recipient-details', { state: { isFromSettingRPI: true } });
  };

  return (
    <>
      {result && (
        <Box
          display={'flex'}
          flexDirection={isCardBorderEnabled ? 'row' : 'column'}
          justifyContent={isCardBorderEnabled ? 'space-between' : 'start'}
        >
          <ShadowCard
            display={'flex'}
            flexDirection={'column'}
            paddingLeft={4}
            paddingRight={4}
            paddingTop={4}
            paddingBottom={1}
            marginTop={isCardBorderEnabled ? 0 : 2}
            marginBottom={isCardBorderEnabled ? 0 : 4}
            className={isCardBorderEnabled ? 'full_border' : 'normal_shadow'}
            sx={{ width: isCardBorderEnabled ? 'calc(50% - 12px)' : '100%' }}
          >
            <ReviewCard
              title={t('captions.businessDetails')}
              name={`${result.company.name}`}
              editIconClickEvent={() => editDetails(false)}
              isShadowWrappedIcon={true}
              isInnerTitle={true}
              type={verifyObj.business}
            >
              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {`${phoneBusinessNumberMask(result.company.phone)}`}
              </Typography>
              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {result?.company?.address?.line1 ?? ''}
              </Typography>
              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {result?.company?.address?.line2 ?? ''}
              </Typography>
              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {AddressCityStateZip(result?.company)}
              </Typography>

              <Typography
                variant="h1"
                color={'gray.700'}
                marginBottom={2}
                className="ssn_provided_text"
              >
                {t('reviewCardtexts.taxIdProvided')}
                {isTaxIDProvided && (
                  <div
                    className="icon_wrapper"
                    style={{ cursor: 'pointer' }}
                    onClick={() => editDetails(false)}
                  >
                    <RefreshIcon />
                  </div>
                )}
                {!isTaxIDProvided && (
                  <div className="ssn_provided_text_verified">
                    <AccountVerificationStatusses type={'verified'} />
                  </div>
                )}
              </Typography>
              {isTaxDocIDAdded && (
                <Typography
                  variant="h1"
                  color={'gray.700'}
                  marginBottom={2}
                  className="ssn_provided_text"
                >
                  {t('reviewCardtexts.idProvidedText')}
                  {!isTaxIDProvided && (
                    <div className="ssn_provided_text_verified">
                      <AccountVerificationStatusses type={'verified'} />
                    </div>
                  )}
                </Typography>
              )}
            </ReviewCard>
          </ShadowCard>

          <ShadowCard
            display={'flex'}
            flexDirection={'column'}
            paddingLeft={4}
            paddingRight={4}
            paddingTop={4}
            paddingBottom={1}
            marginBottom={isCardBorderEnabled ? 0 : 4}
            className={isCardBorderEnabled ? 'full_border' : 'normal_shadow'}
            sx={{ width: isCardBorderEnabled ? 'calc(50% - 12px)' : '100%' }}
          >
            <ReviewCard
              title={t('captions.personalDetails')}
              name={`${getFirstName} ${getLastName}`}
              editIconClickEvent={() => editDetails(true)}
              isShadowWrappedIcon={true}
              isInnerTitle={true}
              type={verifyObj.personal}
            >
              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {result?.individual.email ? `${result?.individual.email}` : ''}
              </Typography>
              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {`•••••••••••••••`}
              </Typography>
              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {result.individual.phone ? `${phoneNumberMask(result.individual.phone)}` : ''}
              </Typography>

              <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                {checkDOBNull
                  ? `${t('reviewCardtexts.bornOnText')} ${getFormattedDate(
                      result?.individual?.dob
                    )}`
                  : null}
              </Typography>

              {result?.individual?.address?.line1 && (
                <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                  {result?.individual?.address?.line1 ?? ''}
                </Typography>
              )}
              {result?.individual?.address?.line2 && (
                <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                  {result?.individual?.address?.line2 ?? ''}
                </Typography>
              )}
              {result?.individual?.address?.city && (
                <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                  {AddressCityStateZip(result?.individual)}
                </Typography>
              )}

              {result?.individual?.ssn_last4_provided && (
                <Typography
                  variant="h1"
                  color={'gray.700'}
                  marginBottom={2}
                  className="ssn_provided_text"
                >
                  {t('reviewCardtexts.idProvidedLast4Text')}
                  {verificationStatus !== 'verified' && (
                    <div
                      className="icon_wrapper"
                      style={{ cursor: 'pointer' }}
                      onClick={() => editDetails(true)}
                    >
                      <RefreshIcon />
                    </div>
                  )}
                  {verificationStatus === 'verified' && (
                    <div className="ssn_provided_text_verified">
                      <AccountVerificationStatusses type={'verified'} />
                    </div>
                  )}
                </Typography>
              )}

              {(verificationDocumentFront || verificationDocumentBack) && (
                <Typography
                  variant="h1"
                  color={'gray.700'}
                  marginBottom={2}
                  className="ssn_provided_text"
                >
                  {t('reviewCardtexts.idDocProvidedText')}
                  {verificationStatus === 'verified' && (
                    <div className="ssn_provided_text_verified">
                      <AccountVerificationStatusses type={'verified'} />
                    </div>
                  )}
                  {verificationStatus !== 'verified' && (
                    <div
                      className="icon_wrapper"
                      style={{ cursor: 'pointer' }}
                      onClick={() => editDetails(true)}
                    >
                      <RefreshIcon />
                    </div>
                  )}
                </Typography>
              )}
            </ReviewCard>
          </ShadowCard>

          {isRecipentDetailsAreAvailable && recipientDetailsFormEnabled && (
            <ShadowCard
              display={'flex'}
              flexDirection={'column'}
              paddingLeft={isCardBorderEnabled ? 0 : 4}
              paddingRight={isCardBorderEnabled ? 0 : 4}
              paddingTop={isCardBorderEnabled ? 0 : 4}
              paddingBottom={isCardBorderEnabled ? 0 : 1}
              marginBottom={isCardBorderEnabled ? 0 : 4}
              className={isCardBorderEnabled ? 'remove_all_shadow' : 'normal_shadow'}
            >
              <ReviewCard
                title={t('captions.recipientInfo')}
                name={result?.stripe_account_metadata?.check_recipient_details_name ?? ''}
                isShadowWrappedIcon={true}
                isInnerTitle={true}
                editIconClickEvent={onClickEditRecipientInfo}
              >
                {result?.stripe_account_metadata?.check_recipient_details_address && (
                  <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                    {result?.stripe_account_metadata?.check_recipient_details_address ?? ''}
                  </Typography>
                )}
                {result?.stripe_account_metadata?.check_recipient_details_address_2 && (
                  <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                    {result?.stripe_account_metadata?.check_recipient_details_address_2 ?? ''}
                  </Typography>
                )}
                <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                  {RecipentAddressCityStateZip(result)}
                </Typography>
              </ReviewCard>
            </ShadowCard>
          )}

          {messageObject.content !== '' && (
            <Messages
              topMargin={0}
              bottomMargin={2}
              messageHeading={messageObject.heading}
              messageContent={messageObject.content}
              messageLinkText={messageObject.buttonText}
              messageLinkEvent={() => {
                messageObject.triggerType === 'business' ? editDetails(false) : editDetails(true);
              }}
            />
          )}

          {states.openModal && (
            <AccountInfoEditModal
              handleCancelEvent={handleCancelEvent}
              setResult={setResult}
              result={result}
              title={t('captions.personalInfo')}
              bType={'company'}
              isPersonal={isPersonal}
              navigate={navigate}
              businessInfoVerificationStatus={verifyObj.business}
              personalInfoVerificationStatus={verifyObj.personal}
              onFormSubmitBusinsessPersonalInfo={onFormSubmitBusinsessPersonalInfo}
              onFormSubmitBusinsessInfo={onFormSubmitBusinsessInfo}
            />
          )}
        </Box>
      )}
    </>
  );
};
