import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const FaqBlockWrapper = styled(Box)(
  ({ theme }) => `
   display: flex;
   width: 100%;
   margin-bottom: ${theme.spacing(6)};
`
);

export const FaqBlockLeft = styled(Box)(
  ({ theme }) => `
 display: flex;
 width: 20px;
 height: 20px;
 margin-right: 10px;
`
);

export const FaqBlockRight = styled(Box)(
  ({ theme }) => `
 display: flex;
 width: calc(100% - 30px);
 flex-direction: column;
`
);
