import { styled } from '@mui/material/styles';

export const TopRow = styled('div')(
  ({ theme }) => `
  flex-direction: row;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${theme.spacing(1)};
`
);

export const SecondRow = styled('div')`
  flex-direction: row;
  flex: 1;
  display: flex;
`;

export const BottomSection = styled('div')(
  ({ theme }) => `
  flex-direction: column;
  display: flex;
  margin-top: ${theme.spacing(2.5)};
  margin-left: ${theme.spacing(3.5)};
`
);

export const AmountSection = styled('div')(
  ({ theme }) => `
  flex-direction: row;
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin-right: ${theme.spacing(2)};
 `
);

export const TagWrapper = styled('div')(
  ({ theme }) => `
  border-radius: 8px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  padding-top: ${theme.spacing(0.75)};
  padding-bottom: ${theme.spacing(0.75)};
  padding-left: ${theme.spacing(3)};
  padding-right: ${theme.spacing(3)};

  &.un_editable {
   background: #EBEEF1 !important;
   color: ${theme.palette.gray[900]} !important;
   pointer-events: none;
  }

  &.editable {
   background: ${theme.palette.blue[50]};
   color: ${theme.palette.blue[500]};
   cursor: pointer;
  }

  &.warning {
   background: ${theme.palette.yellow[75]};
   color: ${theme.palette.gray[900]} !important;
   cursor: pointer;
  }

  &.complete-disabled {
    pointer-events: none !important;
  }
 `
);
