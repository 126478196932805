import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import {
  Header,
  HeadingSummaryBlock,
  FormWrapper,
  ContentContainerBottom,
  AddressFields,
  Messages,
} from 'src/components/base/core';
import { identityVerificationSchemaObj } from 'src/helpers/validationSchemas';
import { handleBusinessRepAddress } from 'src/helpers/containers/helperBusinessRepresentative';
import dtoToFormValues from 'src/shared/utilities/dto';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { useGetBlockHeight } from 'src/hooks/useGetBlockHeight';
import { theme, commonSpacing } from 'src/shared/theme/theme';
import { isBillingAndShippingAreTheSame } from 'src/shared/utilities/addressUtils';

export type FormProps = {
  line1: string;
  line2: string;
  city: string;
  state: string;
  postal_code: string;
};
export const FormHomeAddressSubmition = ({ onBackButtonClick, onFormSubmit, accountData }: any) => {
  const { t } = useTranslation();
  let defaultValues: FormProps = { line1: '', line2: '', city: '', state: '', postal_code: '' };
  const [addressStates, setAddressStates] = useState(defaultValues);
  const [isSameChecked, setSameChecked] = useState(false);
  const [addressError, setAddressError] = useState('');
  const [appSubmit, isLoading, accountCompanyData]: any = handleBusinessRepAddress(setAddressError);
  const [elementRef, height] = useGetBlockHeight();

  useEffect(() => {
    if (accountCompanyData || accountData) {
      dtoToFormValues(setValue, accountCompanyData ?? accountData);
      const address = accountCompanyData?.company?.address ?? accountData?.company?.address;
      const individualAddress: any =
        accountCompanyData?.individual?.address ?? accountData?.individual?.address;

      const isBothAddressAreTheSame = isBillingAndShippingAreTheSame(address, individualAddress);

      if (isBothAddressAreTheSame) {
        setSameChecked(true);
      }

      if (address && isSameChecked && individualAddress?.line1 === null) {
        setValue('city', address.city);
        setValue('line1', address.line1);
        setValue('line2', address.line2);
        setValue('state', address.state);
        setValue('postal_code', address.postal_code);
        trigger(['line1', 'state', 'city', 'postal_code']);
      } else if (individualAddress && individualAddress?.line1 !== null) {
        setValue('city', individualAddress.city);
        setValue('line1', individualAddress.line1);
        setValue('line2', individualAddress.line2);
        setValue('state', individualAddress.state);
        setValue('postal_code', individualAddress.postal_code);
        trigger(['line1', 'state', 'city', 'postal_code']);
      } else {
        setValue('city', '');
        setValue('line1', '');
        setValue('line2', '');
        setValue('postal_code', '');
        setValue('state', null);
      }
    }
  }, [accountCompanyData, accountData, isSameChecked]);

  const revieInfoSchema = yup
    .object({
      ...identityVerificationSchemaObj,
    })
    .required();

  //hook-forms
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    control,
    formState: { errors },
  } = useForm<any>({
    mode: 'onSubmit', //used to check error in realtime
    shouldFocusError: true,
    resolver: yupResolver(revieInfoSchema),
    defaultValues: defaultValues,
  });

  const onAppFormSubmit = handleSubmit(async (data: any) => {
    appSubmit(data, onFormSubmit);
  });

  return (
    <>
      {isLoading && <ManualLoader />}
      <FormWrapper onSubmit={onAppFormSubmit} className="no_fullscreen">
        <Box
          component={'div'}
          className={'container_content'}
          ref={elementRef}
          sx={{
            [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
              minHeight: height,
              paddingBottom: height ? 4 : commonSpacing.desktopPaddingB,
            },
          }}
        >
          <Header
            isBackButtonVisible={true}
            onBackButtonClick={onBackButtonClick}
            isProgressBarVisible={true}
            totalProgressSteps={6}
            currentProgressStep={4}
            paddingX={0}
          />
          <Box sx={{ marginBottom: 3 }}>
            <HeadingSummaryBlock
              heading={t('headingContent.business.ownerAddress.heading')}
              headingAlignment="left"
              content={t('headingContent.business.ownerAddress.content')}
              contentAlignment="left"
            />
          </Box>
          <FormControlLabel
            sx={{ marginBottom: 3 }}
            control={
              <Checkbox
                checked={isSameChecked}
                name="jason"
                size="small"
                onClick={() => setSameChecked(!isSameChecked)}
              />
            }
            label={
              <Typography variant="body1" color={'gray.700'}>
                {t('formInput.sameBillingAddressCheckbox')}
              </Typography>
            }
          />
          <AddressFields
            setAddressStates={setAddressStates}
            addressStates={addressStates}
            register={register}
            errors={errors}
            control={control}
            getValue={getValues}
            setValue={setValue}
            trigger={trigger}
          />
          {addressError !== '' && (
            <Messages
              topMargin={2}
              type={'error'}
              messageHeading={t('headingContent.business.ownerAddress.heading')}
              messageContent={addressError}
              closeEvent={() => setAddressError('')}
            />
          )}
        </Box>
        <ContentContainerBottom className={'no_note no_fullscreen'}>
          <Button
            variant="containedLarge"
            color="primary"
            type="submit"
            fullWidth
            sx={{ overflow: 'hidden' }}
          >
            {t('buttonTexts.continueButton')}
          </Button>
        </ContentContainerBottom>
      </FormWrapper>
    </>
  );
};
