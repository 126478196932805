import { Box, Typography } from '@mui/material';
import { CustomerCardWrapper } from './CustomerCard.style';

export const CustomerCard = ({
  companyName,
  Address,
  inputId,
  onChangeEvent,
  isBordered = true,
}: any) => {
  return (
    <CustomerCardWrapper htmlFor={inputId} className={isBordered ? 'isBordered' : 'noneBordered'}>
      <input
        type="radio"
        id={inputId}
        value={companyName}
        onChange={onChangeEvent}
        name="customers-list"
      />
      <div className="input_container">
        <Box component="div" className="input_inner_container">
          <Typography variant="h2Bold" color={'gray.700'} sx={{ marginBottom: 1, marginTop: 0 }}>
            {companyName}
          </Typography>
          <Typography variant="h1" color={'gray.400'} sx={{ margin: 0 }} className="input_address">
            {Address}
          </Typography>
        </Box>
      </div>
    </CustomerCardWrapper>
  );
};
