import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InvitedCustomerCard } from 'src/components/base/core';

export const InvitedList = ({
  data,
  isSuccess,
  onClickEventHandler,
  spacing = 3,
  isTitleVIsible = true,
  isBordered = true,
}: any) => {
  const { t } = useTranslation();
  return (
    <>
      {isTitleVIsible && (
        <Box marginBottom={4}>
          <Typography variant="caption1" color={'gray.600'} textTransform="uppercase">
            {t('captions.invitedText')}
          </Typography>
        </Box>
      )}
      <Box
        display={'flex'}
        marginBottom={isTitleVIsible ? 4 : 0}
        flexWrap="nowrap"
        sx={{
          flexGrow: 1,
        }}
      >
        <Grid container spacing={spacing}>
          {isSuccess &&
            data?.customerReferrals?.nodes.map((item: any, index: any) => (
              <Grid item xs={12} key={index}>
                <InvitedCustomerCard
                  companyName={item.customer_name}
                  Address={`${item.city}`}
                  status="pending"
                  onClickEvent={onClickEventHandler}
                  rowData={item}
                  isBordered={isBordered}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
    </>
  );
};
