import Register, {
  Address,
  BussinessProfile,
  Company,
  DOB,
  Individual,
  IndividualExecutive,
  IndividualOwner,
  RegisterCompany,
  RelationshipExecutive,
  RelationshipOwner,
  Verification,
  Document,
  Metadata,
  CreateIndividual,
  BasicInfo,
} from './account.register.model';

export const accountIndividual = (payload: any, update: boolean, fullSSN: boolean): Register => {
  const date = payload?.dob;
  return new Register(
    payload.business_type,
    payload.language,
    update
      ? new Individual(
          new Address(
            payload.line1,
            payload.line2,
            payload.city,
            payload.state,
            payload.postal_code
          ),
          date &&
            new DOB(
              date?.toString().substring(2, 4),
              date?.toString().substring(0, 2),
              date?.toString().substring(4, 8)
            ),
          payload.first_name,
          payload.last_name,
          payload.phone_number,
          payload.email,
          update && fullSSN && payload.idNumber,
          update && !fullSSN && payload.idNumber,
          new Verification(new Document(payload.front, payload.back)),
          new Metadata(payload.documentType)
        )
      : new Individual(
          new Address(
            payload.line1,
            payload.line2,
            payload.city,
            payload.state,
            payload.postal_code
          ),
          new DOB(
            date?.toString().substring(2, 4),
            date?.toString().substring(0, 2),
            date?.toString().substring(4, 8)
          ),
          payload.first_name,
          payload.last_name,
          payload.phone_number,
          payload.email
        ),
    new BussinessProfile(
      payload.inputMCC,
      payload.inputDescription,
      payload.inputBusinessName,
      payload.url
    ),
    payload?.id,
    payload?.tos_shown_and_accepted,
    payload?.default_payment_term_id,
    payload?.accept_other_terms,
    payload?.is_email_notification_active,
    payload?.is_sms_notification_active,
    payload?.default_pay_mode
  );
};

export const accountCompany = (payload: any, update: boolean, fullSSN: boolean): Register => {
  const date = payload?.dob;
  if (update) {
    return new RegisterCompany(
      payload.business_type,
      payload.language,
      payload.ownership === 'owner'
        ? new IndividualOwner(
            new Address(
              payload.line1,
              payload.line2,
              payload.city,
              payload.state,
              payload.postal_code
            ),
            date &&
              date !== '' &&
              new DOB(
                date?.toString().substring(2, 4),
                date?.toString().substring(0, 2),
                date?.toString().substring(4, 8)
              ),
            payload.first_name,
            payload.last_name,
            payload.phone_number,
            payload.email,

            new RelationshipOwner(true, payload.inputOwnerTitle, true),
            update && fullSSN && payload.idNumber,
            update && !fullSSN && payload.idNumber,
            new Verification(new Document(payload.front, payload.back)),
            payload.documentType &&
              payload.documentType !== 'Document Type' &&
              new Metadata(payload.documentType)
          )
        : new IndividualExecutive(
            new Address(
              payload.line1,
              payload.line2,
              payload.city,
              payload.state,
              payload.postal_code
            ),
            date &&
              date !== '' &&
              new DOB(
                date?.toString().substring(2, 4),
                date?.toString().substring(0, 2),
                date?.toString().substring(4, 8)
              ),
            payload.first_name,
            payload.last_name,
            payload.phone_number,
            payload.email,
            new RelationshipExecutive(
              true,
              payload.inputOwnerTitle,
              true,
              payload.percentOwnership ? 25 : 0
            ),
            update && fullSSN && payload.idNumber,
            update && !fullSSN && payload.idNumber,
            new Verification(new Document(payload.front, payload.back)),
            payload.documentType &&
              payload.documentType !== 'Document Type' &&
              new Metadata(payload.documentType)
          ),
      new BussinessProfile(
        payload.inputMCC,
        payload.inputDescription,
        payload.inputBusinessName,
        payload.url
      ),
      payload.id,
      payload.tos_shown_and_accepted,
      payload.company_structure,
      payload?.default_payment_term_id,
      payload.accept_other_terms,
      new Company(
        payload.inputCompanyName,
        payload.inputCompanyPhone,
        new Address(
          payload.inputComLine1,
          payload.inputComLine2,
          payload.inputComCity,
          payload.inputComState,
          payload.inputComPostal
        ),
        payload.inputTaxId,
        new Verification(new Document(payload.companyFront, payload.companyBack)),
        payload.documentType &&
          payload.documentType !== 'Document Type' &&
          new Metadata(payload.documentType),
        payload.structure
      )
    );
  } else {
    return new RegisterCompany(
      payload.business_type,
      payload.language,
      payload.ownership === 'owner'
        ? new IndividualOwner(
            new Address(
              payload.line1,
              payload.line2,
              payload.city,
              payload.state,
              payload.postal_code
            ),
            new DOB(
              date?.toString().substring(2, 4),
              date?.toString().substring(0, 2),
              date?.toString().substring(4, 8)
            ),
            payload.first_name,
            payload.last_name,
            payload.phone_number,
            payload.email,
            new RelationshipOwner(true, payload.inputOwnerTitle, true),
            fullSSN && payload.idNumber,
            !fullSSN && payload.idNumber,
            new Verification(new Document(payload.front, payload.back)),
            payload.documentType &&
              payload.documentType !== 'Document Type' &&
              new Metadata(payload.documentType)
          )
        : new IndividualExecutive(
            new Address(
              payload.line1,
              payload.line2,
              payload.city,
              payload.state,
              payload.postal_code
            ),
            new DOB(
              date?.toString().substring(2, 4),
              date?.toString().substring(0, 2),
              date?.toString().substring(4, 8)
            ),
            payload.first_name,
            payload.last_name,
            payload.phone_number,
            payload.email,
            new RelationshipExecutive(true, payload.inputOwnerTitle, true),
            undefined,
            undefined,
            new Verification(new Document(payload.front, payload.back)),
            payload.documentType &&
              payload.documentType !== 'Document Type' &&
              new Metadata(payload.documentType)
          ),
      new BussinessProfile(
        payload.inputMCC,
        payload.inputDescription,
        payload.inputBusinessName,
        payload.url
      ),
      payload.id,
      payload.tos_shown_and_accepted,
      payload.company_structure,
      payload?.default_payment_term_id,
      payload.accept_other_terms,
      new Company(
        payload.inputCompanyName,
        payload.inputCompanyPhone,
        new Address(
          payload.inputComLine1,
          payload.inputComLine2,
          payload.inputComCity,
          payload.inputComState,
          payload.inputComPostal
        ),
        payload.inputTaxId,
        new Verification(new Document(payload.companyFront, payload.companyBack)),
        payload.documentType &&
          payload.documentType !== 'Document Type' &&
          new Metadata(payload.documentType),
        payload.structure
      )
    );
  }
};

export const accountIndividualInitMapper = (params: any): CreateIndividual => {
  return new CreateIndividual(
    new BasicInfo(params.first_name, params.last_name, params.email, params.phone_number),
    params.business_type,
    params.coupa_supplier_id
  );
};
