import { useEffect, useState } from 'react';
import { getTransfersData } from '../getTransfersData';
import { batchStatssues } from 'src/shared/utilities/interface';

export const helperTransactions = (statusType: batchStatssues = 'pending') => {
  const [upcomingCount, setUpcomingCount]: any = useState(undefined);

  const setOrderBy =
    statusType === 'approved'
      ? 'APPROVED_AT_DESC'
      : statusType === 'paid'
      ? 'PAID_AT_DESC'
      : 'CREATED_AT_DESC';

  const getUpcomingTransData = async () => {
    const { data } = await getTransfersData({
      first: 0,
      pageOffset: 0,
      filter: {
        status: { equalTo: JSON.stringify(statusType) },
      },
      sort: setOrderBy,
    });

    setUpcomingCount(data?.payoutBatchTransfers?.totalCount);
  };

  useEffect(() => {
    getUpcomingTransData();
  }, []);

  return [upcomingCount];
};

export const handleProgressBarValues = (
  getSpendingLimits: number,
  totalSpendAmount: number,
  overallProgressLabelPreFixText = 'Available',
  progressLabelPreFixText = 'Spent'
): any => {
  const currentProgressStep = (totalSpendAmount * 100) / getSpendingLimits;
  let progressValue = `$${totalSpendAmount / 100}`;
  let progressLabel = `${progressLabelPreFixText}/Day`;
  let overallProgressLabel = `${overallProgressLabelPreFixText}/Day`;
  let overallProgressValue = `$${getSpendingLimits / 100}`;

  const sixtyPercentageOfTotal = (getSpendingLimits / 100) * 0.6;
  const getTotalAmountsWithoutCents = totalSpendAmount / 100;

  if (sixtyPercentageOfTotal <= getTotalAmountsWithoutCents) {
    progressValue = '';
    progressLabel = '';
    overallProgressLabel = `${progressLabelPreFixText} / ${overallProgressLabelPreFixText} / Day`;
    overallProgressValue = `$${totalSpendAmount / 100} / ${overallProgressValue}`;
  }

  return [
    currentProgressStep,
    progressValue,
    progressLabel,
    overallProgressLabel,
    overallProgressValue,
  ];
};
