import { styled } from '@mui/material/styles';

export const AccordionSection = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
`;

export const AccordionButton = styled('div')(
  ({ theme }) => `
  background: ${theme.palette.white};
  color: ${theme.palette.gray[400]};
  cursor: pointer;
  padding: 15px 10px 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  border-bottom: 1px solid ${theme.palette.gray[50]};

  .accordion__icon {
    margin-left: auto;
    transition: transform 0.4s ease;
  }

  .rotate {
    transform: rotate(180deg);
  }

  &.none_border {
    border-bottom: none;
  }
`
);

export const AccordionTitle = styled('div')(
  ({ theme }) => `
  color: ${theme.palette.gray[800]};
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  text-align: left;
`
);

export const AccordionContent = styled('div')`
  background-color: white;
  overflow: auto;
  transition: max-height 0.4s ease;
`;
