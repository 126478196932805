import { useTranslation } from 'react-i18next';
import { ManualLoader } from '../../../components/base/core/OtherUtilities/Loader/ManualLoader';
import { useGetAccountDataMutation } from '../../../services/account/accountServiceApi';
import {
  Header,
  HeaderFixed,
  Wrapper,
  WrpperAuthorizedContainer,
} from '../../../components/base/core';
import { AccountInfoIndividual } from './AccountInfoIndividual';
import { AccountInfoBusiness } from './AccountInfoBusiness';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';
import { routeType } from 'src/helpers/containers/helperRoutePreference';
import { AccountInfoBusinessOwnership } from './AccountInfoBusinessOwnership';
import { useFlags } from 'launchdarkly-react-client-sdk';

type LocationState = {
  fromHome?: boolean;
};

export const AccountInfoContainer = ({ isSettingDesktop = false }: any) => {
  const { t } = useTranslation();
  const [getAccountData]: any = useGetAccountDataMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [accountData, setAccountData]: any = useState();
  const [accountType, setAccountType] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [updatePreferenceOnServer] = handlePreferences();
  const { reduceTheBusinessFlowFields } = useFlags();
  const [formSubmitDone, setFormSubmitDone] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const retryData = await getAccountData();
      setAccountType(retryData?.data?.business_type);
      setAccountData(retryData.data);
      setIsLoading(false);
    };

    // call the function
    fetchData();
  }, [formSubmitDone]);

  const onBackButtonClick = () => {
    if ((location?.state as LocationState)?.fromHome) {
      updatePreferenceOnServer(routeType.HOME);
      navigate('/dashboard');
    } else {
      updatePreferenceOnServer(routeType.SETTINGS);
      navigate('/settings');
    }
  };

  return (
    <>
      {isLoading && <ManualLoader />}
      {accountData !== undefined && (
        <>
          {!isSettingDesktop && (
            <HeaderFixed className="fixed_only_desktop">
              <Header
                isMainMunuVisible
                isBackButtonHide
                paddingX={0}
                isLogoVisible={false}
                headerText={t('settings.AccountInformation.title')}
                withCenteredHeaderText
                isBackButtonVisible={true}
                onBackButtonClick={onBackButtonClick}
              />
            </HeaderFixed>
          )}
          <Wrapper
            isAuthorizedWrapper
            idSelector={!isSettingDesktop ? 'wrapperContainer' : 'settingDesktopWraper'}
            isSettingDesktop={isSettingDesktop}
          >
            <WrpperAuthorizedContainer>
              {accountType === 'company' ? (
                !reduceTheBusinessFlowFields ? (
                  <AccountInfoBusiness
                    accountData={accountData}
                    isCardBorderEnabled={isSettingDesktop}
                    fromHome={(location?.state as LocationState)?.fromHome}
                  />
                ) : (
                  <AccountInfoBusinessOwnership
                    accountData={accountData}
                    isCardBorderEnabled={isSettingDesktop}
                    fromHome={(location?.state as LocationState)?.fromHome}
                    setFormSubmitDone={setFormSubmitDone}
                    formSubmitDone={formSubmitDone}
                  />
                )
              ) : (
                <AccountInfoIndividual
                  accountData={accountData}
                  isCardBorderEnabled={isSettingDesktop}
                  fromHome={(location?.state as LocationState)?.fromHome}
                />
              )}
            </WrpperAuthorizedContainer>
          </Wrapper>
        </>
      )}
    </>
  );
};
