import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGetBlockHeight } from 'src/hooks/useGetBlockHeight';

import {
  Header,
  HeaderFixed,
  WrpperAuthorizedContainer,
  ButtonAuthorizedWrapper,
  FormAuthorizedWrapper,
  Wrapper,
  NoteWrapperAuthorizedWrapper,
  Messages,
} from '../../../../components/base/core';
import { Box, Button, Typography } from '@mui/material';

import { commonSpacing, theme } from '../../../../shared/theme/theme';
import { Images } from '../../../../shared/utilities/images';

export const FinanceAnnouncementScreenContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [elementRef, height] = useGetBlockHeight();

  const [infoMessageVisible, setInfoMessageVisible] = useState(true);

  return (
    <>
      <HeaderFixed className="fixed_only_desktop loader_wrapper_header">
        <Header isMainMunuVisible isSkipButtonHide isBackButtonHide paddingX={0} />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper>
        <WrpperAuthorizedContainer>
          <FormAuthorizedWrapper>
            <Box
              ref={elementRef}
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                  minHeight: height,
                  paddingBottom: height ? 4 : commonSpacing.desktopPaddingB,
                },
              }}
            >
              <Typography
                variant="h6ExtraBold"
                component="div"
                sx={{ color: 'gray.800', fontWeight: 800, marginBottom: theme.spacing(6) }}
              >
                {t('finance.announcementScreen.heading')}
              </Typography>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    marginBottom: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    maxHeight: '260px',
                    [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                      maxWidth: '420px',
                    },
                  }}
                >
                  <img
                    src={Images.Finance.FINANCE_ANNOUNCEMENT_BANNER}
                    alt="PayUp finance account"
                    width="100%"
                  />
                </Box>
              </Box>
              <Typography
                variant="h3"
                component="p"
                sx={{
                  color: 'gray.800',
                  textAlign: 'left',
                  marginBottom: theme.spacing(6),
                  letterSpacing: '-0.24px',
                  lineHeight: `24px`,
                  justifyContent: 'start',
                }}
              >
                {t('finance.announcementScreen.content')}
              </Typography>
            </Box>
            <NoteWrapperAuthorizedWrapper>
              {infoMessageVisible && (
                <Messages
                  type="info"
                  messageContent={t('finance.announcementScreen.infoNote')}
                  onClickContent={() => navigate('/dashboard')}
                  isContentClickabale
                  closeEvent={() => setInfoMessageVisible(false)}
                />
              )}
            </NoteWrapperAuthorizedWrapper>
            <ButtonAuthorizedWrapper>
              <Button
                variant="containedLarge"
                color="primary"
                fullWidth
                id={'Finance'}
                disabled
                sx={{ marginBottom: 2 }}
              >
                {t('finance.announcementScreen.button')}
              </Button>
            </ButtonAuthorizedWrapper>
          </FormAuthorizedWrapper>
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
