import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Qs, { ParsedQs } from 'qs';

import { EARNINGS } from '../../constants/constants';
import { getInvoiceData } from '../getInvoiceData';

import { getPaginationParams } from '../helperPaginationHandler';
import {
  initFilterStatus,
  initialDownloadOptions,
  initialFilterOptions,
  initialFilterStatus,
  initialPayoutData,
} from '../../shared/utilities/initialStates';
import { GridFilterStatus, payoutsProps } from '../../shared/utilities/interface';
import moneyFormat from '../../shared/utilities/moneyFormat';
import { getSelectedDate } from '../helperHandlingDates';
import { configs } from 'src/shared/config/config';
import { convertToInt } from 'src/shared/utilities/dataConvertions';

/**
 * Used to handle the Submitted tab datas
 * @returns TODO
 */
export const handleSubmittedData = () => {
  const [offsetCurrent, setOffsetCurrent] = useState(0);
  const [offsetExisting, setOffsetExisting] = useState(0);
  const [rowCountCurrent, setRowCountCurrent] = useState(configs.DEFAULT_LIMIT);
  const [rowCountExisting, setRowCountExisting] = useState(configs.DEFAULT_LIMIT);
  const [data, setData]: any = useState();
  const [response, setResponse]: any = useState();
  const [pageCurrentCount, setPageCurrentCount] = useState(0);
  const [pageExistingCount, setPageExistingCount] = useState(0);
  const [showLoaderForCurrent, setShowLoaderForCurrent] = useState(false);
  const [showLoaderExsisting, setShowLoaderExsisting] = useState(false);
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [existingInvicedData, setExistingInvicedData]: any = useState<any>(initialPayoutData);
  const [submitData, setSubmitData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filterStates, setFilterStates] = useState<any>(initialFilterOptions);
  const [downloadStates, setDownloadStates] = useState(initialDownloadOptions);
  const [selectedFilterOptions, setSelectedFilterOptions]: any = useState([]);
  const [filterAmountRange, setFilterAmountRange] = useState();
  const [resetFilter, setResetFilter] = useState(false);
  const [selectedDateFilters, setSelectedDateFilterOptions]: any = useState('All');
  const [selectedDateRange, setSelectedDateRange]: any = useState();
  const [filterAllCount, setFilterAllCount]: any = useState(0);
  const [isAllSelect, setAllSelect] = useState(true);
  const [filterStatus, setFilterStatus] = useState<GridFilterStatus>(initialFilterStatus);
  const [showMoreInprogressOld, setShowMoreInprogressOld] = useState(false);
  const [isCloseEditTerms, setIsCloseEditTerms] = useState(false);
  const [filteringApplied, setFilteringApplied] = useState(false);
  const [filterSearchParams, setFilterSearchParams]: any = useState();
  const [filterSavedParams, setFilterSavedParams]: any = useState();
  const [enableQuerySearch, setenableQuerySearch] = useState(false);
  const [savedMinmumAmount, setSavedMinmumAmount]: any = useState();
  const [savedMaximumAmount, setSavedMaximumAmount]: any = useState();
  const location = useLocation();
  const dataParam: payoutsProps = getPaginationParams(
    EARNINGS.SUBMITTED,
    offsetExisting,
    rowCountExisting,
    searchText,
    'INVOICED_AT_DESC',
    null,
    filterAmountRange,
    getSelectedDate(selectedDateFilters),
    selectedDateRange,
    isAllSelect
  );

  const existingInvoicedPrams: payoutsProps = getPaginationParams(
    EARNINGS.TERMS_ACCEPTED,
    offsetExisting,
    rowCountExisting,
    searchText,
    filterStates.sort,
    null,
    filterAmountRange,
    getSelectedDate(selectedDateFilters),
    selectedDateRange,
    isAllSelect
  );

  const setFilteredParams = (filterParams: any) => {
    setFilterSavedParams(filterParams);
  };

  useEffect(() => {
    let filterParamsSavedData = null;
    if (location?.search) {
      const x = location.search.substring(1);
      filterParamsSavedData = Qs.parse(location.search.substring(1));
      setFilterSearchParams(null);
      setFilterSearchParams(filterParamsSavedData);
      setTimeout(() => {
        setenableQuerySearch(true);
      }, 1000);
      getFilterCount();
    }

    setFilterStates({ ...filterStates, sort: 'INVOICED_AT_DESC' });

    let existingInvoicedData: payoutsProps | ParsedQs = getPaginationParams(
      EARNINGS.TERMS_ACCEPTED,
      offsetExisting,
      rowCountExisting,
      searchText,
      'INVOICED_AT_DESC',
      null,
      filterAmountRange,
      getSelectedDate(selectedDateFilters),
      selectedDateRange,
      isAllSelect
    );

    makeFiltersBasedOnQueryParams(existingInvoicedData, filterParamsSavedData);
    updatePaginationBasedOnQueryParams(filterParamsSavedData);
    //set current invoices data
    mapCurrentInvoiceData(
      updateRequestBodyIfQueryParamsAvailable(
        filterParamsSavedData,
        filterParamsSavedData?.params ? filterParamsSavedData.params : dataParam,
        true
      )
    );

    if (filterParamsSavedData?.params) {
      const paramsData = filterParamsSavedData.params;
      // @ts-ignore
      let filterinner = Object.assign({}, paramsData?.filter);
      // @ts-ignore
      filterinner = { ...filterinner, status: { equalTo: EARNINGS.TERMS_ACCEPTED } };
      // @ts-ignore
      existingInvoicedData = { ...paramsData, filter: filterinner };
    }
    //existing invoice data
    mapExistingInvoiceData(
      updateRequestBodyIfQueryParamsAvailable(filterParamsSavedData, existingInvoicedData, false)
    );

    //alter the current array for handle checkbox selection
    updateExistingInvicedData(data?.payouts?.nodes);
  }, []);

  const updateRequestBodyIfQueryParamsAvailable = (
    filterParamsSavedData: any,
    dataParam: any,
    isCurrent: boolean
  ) => {
    if (
      filterParamsSavedData?.pagination &&
      Object.keys(filterParamsSavedData?.pagination)?.length > 0
    ) {
      const { offsetCurrent, offsetExisting, rowCountCurrent, rowCountExisting } =
        filterParamsSavedData?.pagination;

      const rows = isCurrent ? rowCountCurrent : rowCountExisting;
      const offsetVAl = isCurrent ? offsetCurrent : offsetExisting;

      return { ...dataParam, limit: rows, pageOffset: rows * offsetVAl, first: rows };
    }

    return dataParam;
  };

  const updatePaginationBasedOnQueryParams = (filterParamsSavedData: any) => {
    if (filterParamsSavedData?.pagination) {
      const { offsetCurrent, offsetExisting, rowCountCurrent, rowCountExisting } =
        filterParamsSavedData?.pagination;

      setOffsetCurrent(offsetCurrent ? convertToInt(offsetCurrent) : 0);
      setOffsetExisting(offsetExisting ? convertToInt(offsetExisting) : 0);
      setRowCountCurrent(rowCountCurrent ? convertToInt(rowCountCurrent) : configs.DEFAULT_LIMIT);
      setRowCountExisting(
        rowCountExisting ? convertToInt(rowCountExisting) : configs.DEFAULT_LIMIT
      );
    }
  };

  const makeFiltersBasedOnQueryParams = (existingInvoicedData: any, filterParamsSavedData: any) => {
    if (filterParamsSavedData) {
      let filterSatus = {
        isAmountFilterActive: false,
        isDateFilterActive: false,
        isTermFilterActive: false,
        isSortBFilterActive: false,
        selectedDateFilterValue: { isRange: false, value: null },
      };
      let count = 0;
      const paramsData = filterParamsSavedData.params;
      const filterData = filterParamsSavedData.filterData;

      if (filterData) {
        // @ts-ignore
        if (filterData?.dateFilter !== null && filterData.dateFilter !== 'All') {
          count++;
          filterSatus.isDateFilterActive = true;
          // @ts-ignore
          setSelectedDateFilterOptions(filterData.dateFilter);
          filterSatus.selectedDateFilterValue = { isRange: false, value: filterData.dateFilter };
        }
        if (filterData.dateRange?.length > 0 && filterData.dateFilter !== 'All') {
          count++;
          filterSatus.isDateFilterActive = true;
          setSelectedDateRange(filterData.dateRange);
          filterSatus.selectedDateFilterValue = { value: filterData.dateRange, isRange: true };
        }
        // @ts-ignore
        if (filterData?.amount) {
          count++;
          filterSatus.isAmountFilterActive = true;
          // @ts-ignore
          setFilterAmountRange({ max: filterData?.amount.max, min: filterData?.amount.min });
        }
        // @ts-ignore
        if (filterData?.terms?.payout_term_ids?.length > 0) {
          count++;
          filterSatus.isTermFilterActive = true;
          // @ts-ignore
          setSelectedFilterOptions({ payout_term_ids: filterData?.terms?.payout_term_ids });
        }
        // @ts-ignore
        setAllSelect(filterData?.termAll === 'true');
        // @ts-ignore
        if (filterData?.sort?.toString() !== 'INVOICED_AT_DESC') {
          count++;
          filterSatus.isSortBFilterActive = true;
          setFilterStates({ ...filterStates, sort: filterData?.sort.toString() });
        }
        if (filterData?.searchText !== '') {
          setSearchText(filterData.searchText);
        }
        if (localStorage.getItem('ear-min') && localStorage.getItem('ear-max')) {
          setSavedMinmumAmount(localStorage.getItem('ear-min'));
          setSavedMaximumAmount(localStorage.getItem('ear-max'));
        }
      }
      setFilterStatus(filterSatus);
      setFilterAllCount(count);
    }
  };

  const mapCurrentInvoiceData = async (dataParam: any, pagingInprogress: boolean = false) => {
    setShowLoaderForCurrent(pagingInprogress);
    // set current invoice data
    const { data, totalCnt } = await getInvoiceData(dataParam, true);
    // const { data1, totalCnt1 } = data;
    setShowLoaderForCurrent(false);
    setResponse(data);
    updateFlagCurrentInvicedData(data?.payouts?.nodes);
    setPageCurrentCount(totalCnt);
  };

  const onDateChange = (dates: any) => {
    setSelectedDateFilterOptions(null);
    setSelectedDateRange(dates);
  };

  const mapExistingInvoiceData = async (
    existingInvoicedDataParams: any,
    pagingInprogress: boolean = false,
    termsSetDetails: any = null
  ) => {
    setShowLoaderExsisting(pagingInprogress);
    // set existing invoice data
    const { data, totalCnt } = await getInvoiceData(existingInvoicedDataParams, false);
    setShowLoaderExsisting(false);
    setData(data);
    //alter the current array for handle checkbox selection
    updateExistingInvicedData(data?.payouts?.nodes, termsSetDetails);
    setPageExistingCount(totalCnt);
    // scrollToPosition();
  };

  const handleExsistingShowMore = (
    offsetNext: any,
    isCurrent: boolean,
    termsSetDetails: any = null
  ) => {
    const dataParams: payoutsProps = getPaginationParams(
      EARNINGS.TERMS_ACCEPTED,
      offsetNext,
      rowCountExisting,
      searchText,
      filterStates.sort,
      null,
      filterAmountRange,
      selectedDateRange,
      isAllSelect
    );
    setOffsetExisting(offsetNext);
    mapExistingInvoiceData(dataParams, true, termsSetDetails);

    setShowMoreInprogressOld(true);
  };

  const handleExistingRowCountChange = async (newRowCount: number, termsSetDetails: any = null) => {
    const dataParams: payoutsProps = getPaginationParams(
      EARNINGS.TERMS_ACCEPTED,
      0,
      newRowCount,
      searchText,
      filterStates.sort,
      null,
      filterAmountRange,
      selectedDateRange,
      isAllSelect
    );
    setOffsetExisting(0);
    setRowCountExisting(newRowCount);
    mapExistingInvoiceData(dataParams, true, termsSetDetails);

    setShowMoreInprogressOld(true);
  };

  const handleCurrentShowMore = (offsetNext: any, isCurrent: boolean) => {
    const dataParams: payoutsProps = getPaginationParams(
      EARNINGS.SUBMITTED,
      offsetNext,
      rowCountCurrent,
      searchText,
      filterStates.sort,
      selectedFilterOptions,
      filterAmountRange,
      getSelectedDate(selectedDateFilters),
      selectedDateRange,
      isAllSelect
    );
    setOffsetCurrent(offsetNext);
    mapCurrentInvoiceData(dataParams, true);
  };

  const handleCurrentRowCountChange = async (newRowCount: number) => {
    const dataParams: payoutsProps = getPaginationParams(
      EARNINGS.SUBMITTED,
      0,
      newRowCount,
      searchText,
      filterStates.sort,
      selectedFilterOptions,
      filterAmountRange,
      getSelectedDate(selectedDateFilters),
      selectedDateRange,
      isAllSelect
    );
    setOffsetCurrent(0);
    setRowCountCurrent(newRowCount);
    mapCurrentInvoiceData(dataParams, true);
  };

  const handletheMapping = () => {
    mapCurrentInvoiceData(dataParam);
    mapExistingInvoiceData(existingInvoicedPrams);
  };

  //alter the current array for handle checkbox selection
  const updateExistingInvicedData = (exData: any, termsSetDetails: any = null) => {
    setExistingInvicedData([]);
    if (termsSetDetails !== null) {
      let setMultipleTerms: any = termsSetDetails?.selectedMultipleTerms;
      const modifiedData = exData?.map((item: any) => ({
        ...item,
        isChecked: setMultipleTerms.indexOf(item.id) > -1 ? true : false,
      }));
      setExistingInvicedData(modifiedData);
    } else {
      setExistingInvicedData(exData?.map((item: any) => ({ ...item, isChecked: false })));
    }
  };

  //alter the current array for handle checkbox selection
  const updateFlagCurrentInvicedData = (curData: any) => {
    //clear previous data
    setSubmitData([]);
    const currentData = curData?.map((item: any) => ({ ...item, isModified: false }));
    // get curent invoices data
    setSubmitData(currentData);
  };

  const payoutsTotalCount = response?.payouts?.totalCount; // get curent invoices data total count
  const existingPayoutsTotalCount = data?.payouts?.totalCount; // get old invoices data total count
  const handlecurrentPayoutsVisibility = payoutsTotalCount && payoutsTotalCount > 0 ? true : false; // handle the visibility of the current invoices
  const handleExistingPayoutsVisibility =
    existingPayoutsTotalCount && existingPayoutsTotalCount > 0 ? true : false; // handle the visibility of the old invoice

  const transaction = payoutsTotalCount ? `${payoutsTotalCount} Invoices` : `0 Invoices`; // set the transactions to use in header
  const payout_direct_amount = response?.payouts?.aggregates?.sum?.payout_amount; // get the payout amounts

  const payout_amount = payout_direct_amount
    ? moneyFormat(parseFloat(payout_direct_amount)).split('.')
    : '-';
  const decimalAmount = payout_direct_amount ? payout_amount[1] : ''; // get the amount in decimal

  const roundedMin = response && Math.round(response?.payouts?.aggregates?.min?.payout_amount);
  const minmumAmount = roundedMin > 0 ? roundedMin - 1 : 0;
  const maximumAmount =
    response && Math.round(response?.payouts?.aggregates?.max?.payout_amount) + 1;

  /**
   * search Current Invoice by Title
   */
  const handleCurrentSearch = (event: any) => {
    setSearchInProgress(true);
    setSearchText(event?.target?.value);
    /**
     * current invoices
     */
    setOffsetCurrent(offsetCurrent);
    const dataParam = getPaginationParams(
      EARNINGS.SUBMITTED,
      offsetCurrent,
      rowCountCurrent,
      event?.target?.value,
      filterStates.sort,
      selectedFilterOptions,
      filterAmountRange,
      getSelectedDate(selectedDateFilters),
      selectedDateRange,
      isAllSelect
    );

    mapCurrentInvoiceData(dataParam);
    /**
     * Existing Invoice
     */
    mapExistingInvoiceData(
      getPaginationParams(
        EARNINGS.TERMS_ACCEPTED,
        offsetExisting,
        rowCountExisting,
        event?.target?.value,
        filterStates.sort,
        null,
        filterAmountRange,
        getSelectedDate(selectedDateFilters),
        selectedDateRange,
        isAllSelect
      )
    );

    setFilterSearchParams(null);
    setFilterSearchParams({
      params: dataParam,
      pagination: {
        offsetCurrent,
        offsetExisting,
        rowCountCurrent,
        rowCountExisting,
      },
      filterData: {
        offsetCurrent: offsetCurrent,
        searchText: event?.target?.value,
        sort: filterStates.sort,
        terms: selectedFilterOptions,
        amount: filterAmountRange,
        dateFilter: selectedDateFilters,
        dateRange: selectedDateRange,
        termAll: isAllSelect,
      },
    });
    setFilteringApplied(true);
  };

  const handleResetSearch = () => {
    setSearchText('');

    const dataParam: payoutsProps = getPaginationParams(
      EARNINGS.SUBMITTED,
      offsetExisting,
      rowCountExisting,
      '',
      filterStates.sort,
      null,
      filterAmountRange,
      getSelectedDate(selectedDateFilters),
      selectedDateRange,
      isAllSelect
    );

    const existingInvoicedData: payoutsProps = getPaginationParams(
      EARNINGS.TERMS_ACCEPTED,
      offsetExisting,
      rowCountExisting,
      '',
      filterStates.sort,
      null,
      filterAmountRange,
      getSelectedDate(selectedDateFilters),
      selectedDateRange,
      isAllSelect
    );
    //set current invoices data
    mapCurrentInvoiceData(dataParam);

    //existing invoice data
    mapExistingInvoiceData(existingInvoicedData);
    setFilterSearchParams(null);
    setFilterSearchParams({
      params: dataParam,
      pagination: {
        offsetCurrent,
        offsetExisting,
        rowCountCurrent,
        rowCountExisting,
      },
      filterData: {
        offsetCurrent: offsetCurrent,
        searchText: '',
        sort: filterStates.sort,
        terms: selectedFilterOptions,
        amount: filterAmountRange,
        dateFilter: selectedDateFilters,
        dateRange: selectedDateRange,
        termAll: isAllSelect,
      },
    });
    setFilteringApplied(true);
  };

  const handleFilterOpen = () => {
    setFilterStates({ ...filterStates, isFilterEnable: true });
  };

  const handleFilterClose = () => {
    setFilterStates({ ...filterStates, isFilterEnable: false });
  };

  /**
   * Handle data sorting
   */
  const handleDataSorting = (e: any) => {
    setFilterStates({ ...filterStates, sort: e.currentTarget.value });
  };

  /**
   * Handle reset all button
   */
  const handleFilterReset = () => {
    setFilterStates({
      ...filterStates,
      isFilterEnable: true,
      sort: 'INVOICED_AT_DESC',
    });
    setFilterAmountRange(undefined);
    setSelectedDateFilterOptions('All');
    setSelectedDateRange([]);
    setSelectedFilterOptions([]);
    setAllSelect(true);
    setResetFilter(!resetFilter);
    //set current invoices data
    const dataParams = getPaginationParams(
      EARNINGS.SUBMITTED,
      offsetExisting,
      rowCountExisting,
      searchText,
      'INVOICED_AT_DESC',
      null,
      undefined,
      getSelectedDate('All'),
      [],
      isAllSelect
    );
    mapCurrentInvoiceData(dataParams);

    //existing invoice data
    mapExistingInvoiceData(
      getPaginationParams(
        EARNINGS.TERMS_ACCEPTED,
        offsetExisting,
        rowCountExisting,
        searchText,
        filterStates.sort,
        null,
        undefined,
        getSelectedDate('All'),
        [],
        isAllSelect
      )
    );
    /**
     * reset count and filter applied check
     */

    setFilterAllCount(0);
    setFilterSearchParams(null);
    setFilterStatus(initFilterStatus);
    setFilterSearchParams({
      params: dataParams,
      pagination: {
        offsetCurrent,
        offsetExisting,
        rowCountCurrent,
        rowCountExisting,
      },
      filterData: {
        offsetCurrent: offsetCurrent,
        searchText: searchText,
        sort: filterStates.sort,
        terms: selectedFilterOptions,
        amount: filterAmountRange,
        dateFilter: selectedDateFilters,
        dateRange: selectedDateRange,
        termAll: isAllSelect,
      },
    });
  };

  const getFilterCount = () => {
    let filterSatus = {
      isAmountFilterActive: false,
      isDateFilterActive: false,
      isTermFilterActive: false,
      isSortBFilterActive: false,
      selectedDateFilterValue: { isRange: false, value: null },
    };
    let count = 0;
    if (filterStates?.sort?.toString() !== 'INVOICED_AT_DESC') {
      count++;
      filterSatus.isSortBFilterActive = true;
    }
    if (selectedFilterOptions?.payout_term_ids?.length > 0 && !isAllSelect) {
      count++;
      filterSatus.isTermFilterActive = true;
    }
    if (selectedDateRange?.length > 0 && selectedDateFilters !== 'All') {
      count++;
      filterSatus.isDateFilterActive = true;
      filterSatus.selectedDateFilterValue = { value: selectedDateRange, isRange: true };
    }
    if (selectedDateFilters === 'All') {
      filterSatus.selectedDateFilterValue = { isRange: false, value: null };
    }
    if (selectedDateFilters > 0 && selectedDateFilters !== 'All') {
      count++;
      filterSatus.isDateFilterActive = true;
      filterSatus.selectedDateFilterValue = { value: selectedDateFilters, isRange: false };
    }

    if (filterAmountRange) {
      count++;
      filterSatus.isAmountFilterActive = true;
    }
    setFilterAllCount(count);
    setFilterStatus(filterSatus);
  };

  /**
   * Handle filter apply button
   */
  const handleApplyFilter = () => {
    setSearchInProgress(true);
    /**
     * current invoices
     */
    setOffsetCurrent(offsetCurrent);
    getFilterCount();
    /**
     * current invoices
     */

    const dataParams: payoutsProps = getPaginationParams(
      EARNINGS.SUBMITTED,
      offsetCurrent,
      rowCountCurrent,
      searchText,
      filterStates.sort,
      selectedFilterOptions,
      filterAmountRange,
      getSelectedDate(selectedDateFilters),
      selectedDateRange,
      isAllSelect
    );
    setFilterSearchParams(null);
    if (resetFilter) {
      setFilteringApplied(false);
    } else {
      setFilteringApplied(true);
    }

    setFilterSearchParams({
      params: dataParams,
      pagination: {
        offsetCurrent,
        offsetExisting,
        rowCountCurrent,
        rowCountExisting,
      },
      filterData: {
        offsetCurrent: offsetCurrent,
        searchText: searchText,
        sort: filterStates.sort,
        terms: selectedFilterOptions,
        amount: filterAmountRange,
        dateFilter: selectedDateFilters,
        dateRange: selectedDateRange,
        termAll: isAllSelect,
      },
    });

    mapCurrentInvoiceData(dataParams);

    /**
     * Existing Invoice
     */
    mapExistingInvoiceData(
      getPaginationParams(
        EARNINGS.TERMS_ACCEPTED,
        offsetCurrent,
        rowCountCurrent,
        searchText,
        filterStates.sort,
        null,
        filterAmountRange,
        getSelectedDate(selectedDateFilters),
        selectedDateRange,
        isAllSelect
      )
    );

    handleFilterClose();
  };

  const closeEditTerms = (isClose: boolean = false) => {
    if (isClose) {
      const gridData = existingInvicedData?.map((item: any) => ({ ...item, isChecked: false }));
      setExistingInvicedData(gridData);
      setIsCloseEditTerms(true);
    } else {
      setIsCloseEditTerms(false);
    }
  };

  return [
    rowCountCurrent,
    handleCurrentRowCountChange,
    rowCountExisting,
    handleExistingRowCountChange,
    offsetCurrent,
    offsetExisting,
    pageCurrentCount,
    pageExistingCount,
    showLoaderForCurrent,
    showLoaderExsisting,
    searchInProgress,
    existingInvicedData,
    setExistingInvicedData,
    submitData,
    handletheMapping,
    handleExsistingShowMore,
    handleCurrentShowMore,
    handleCurrentSearch,
    handlecurrentPayoutsVisibility,
    handleExistingPayoutsVisibility,
    transaction,
    payout_amount,
    decimalAmount,
    handleDataSorting,
    handleApplyFilter,
    handleFilterOpen,
    handleFilterClose,
    handleFilterReset,
    filterStates,
    setSelectedFilterOptions,
    selectedFilterOptions,
    setFilterAmountRange,
    resetFilter,
    setSelectedDateFilterOptions,
    selectedDateFilters,
    minmumAmount,
    maximumAmount,
    searchText,
    handleResetSearch,
    onDateChange,
    selectedDateRange,
    filterAllCount,
    setSelectedDateRange,
    setAllSelect,
    isAllSelect,
    payoutsTotalCount,
    existingPayoutsTotalCount,
    filterStatus,
    showMoreInprogressOld,
    closeEditTerms,
    isCloseEditTerms,
    filteringApplied,
    filterSearchParams,
    setFilteredParams,
    setResetFilter,
    enableQuerySearch,
    savedMinmumAmount,
    savedMaximumAmount,
    downloadStates,
    setDownloadStates,
  ];
};
