import styled from 'styled-components';

export const PaymentTableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;

  > * {
    transition: 0.4s ease all;
  }
`;
