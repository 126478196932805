import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LanguageSwitchWrapper,
  LanguageSwitchSelectedVal,
  LanguageSwitchDropdown,
  DownArrow,
  LanguageSwitchCardWrapper,
} from './LanguageSwitcher.style';
import { useComponentVisible } from 'src/hooks/useComponentVisible';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ShadowCard } from '../../CommonNestedStyles/FormWrapper.style';
import { TickIcon } from '../../Media/Icon/Icons/Icons';
import { Typography } from '@mui/material';
import { SnackBar } from 'src/components/base/core';
import { AccountParams } from 'src/shared/utilities/interface';
import { useForm } from 'react-hook-form';
import dtoToFormValues from 'src/shared/utilities/dto';
import { accountIndividual, accountCompany } from 'src/models/account/account.service.mapper';
import {
  useGetAccountDataMutation,
  useUpdateAccountMutation,
} from 'src/services/account/accountServiceApi';
import { ManualLoader } from '../Loader/ManualLoader';
import { nativeBridgeTypes } from 'src/shared/config/config';
import { pre_fill_params } from 'src/shared/content/content';

export const lngs: any = {
  en: { nativeName: 'English', subName: null },
  es: { nativeName: 'Español', subName: 'Spanish' },
};

export interface LanguageSwitcherProps {
  isLeftLaigned?: boolean;
  isDropDownType?: boolean;
  isBorderEnabled?: boolean;
}

export const LanguageSwitcher = ({
  isLeftLaigned = false,
  isDropDownType = true,
  isBorderEnabled = false,
}: LanguageSwitcherProps) => {
  const { t } = useTranslation();
  const [getAccountData]: any = useGetAccountDataMutation();
  const [accountData, setAccountData] = useState();
  const [updateAccount] = useUpdateAccountMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [responseType, setResponseType] = useState('');
  const { i18n } = useTranslation();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const handleLanguageSwitch = (lng: any) => {
    i18n.changeLanguage(lng);
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({ type: nativeBridgeTypes.SELECTED_LOCALE, locale: lng })
    );
    setIsComponentVisible(false);
  };

  const getAccData = async () => {
    const accData = await getAccountData();
    if (accData) {
      setAccountData(accData);
    }
  };

  useEffect(() => {
    if (!accountData) {
      getAccData();
    }
  }, []);

  useEffect(() => {
    if (accountData) {
      // @ts-ignore
      if (!accountData?.data?.language) {
        handleLanguageSwitch(i18n.resolvedLanguage);
      } else {
        // @ts-ignore
        handleLanguageSwitch(accountData?.data?.language === 'spanish' ? 'es' : 'en');
        // @ts-ignore
        dtoToFormValues(setValue, accountData?.data);
      }
    }
  }, [accountData]);

  //hook-forms
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<AccountParams>();

  const updateLanguage = async (lng: any) => {
    setResponseType('');
    const postData = getValues();
    let updateFormData = {
      ...postData,
      phone_number: postData?.phone_number?.replace(/[^0-9]/g, ''),
      inputDescription: pre_fill_params.description,
      inputMCC: pre_fill_params.mcc,
      url: pre_fill_params.url,
      tos_shown_and_accepted: true,
      language: lng === 'en' ? 'english' : 'spanish',
    };

    let dataModel = null;
    if (postData?.business_type === 'company') {
      updateFormData.inputCompanyPhone = postData?.inputCompanyPhone?.replace(/[^0-9]/g, '');
      dataModel = accountCompany(updateFormData, false, false);
    } else {
      dataModel = accountIndividual(updateFormData, false, false);
    }
    const accountUpdateResponse = await updateAccount(dataModel);
    // @ts-ignore
    if (accountUpdateResponse?.data) {
      setResponseType(t('notifications.updateSuccess'));
    } else {
      // @ts-ignore
      const responseError = accountUpdateResponse?.error?.data;
      if (responseError) {
        setResponseType('error');
      }
    }
  };

  const handleLaguageSelection = async (lng: any) => {
    handleLanguageSwitch(lng);
    if (accountData) {
      setIsLoading(true);
      await updateLanguage(lng);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <ManualLoader />}
      {isDropDownType && (
        <LanguageSwitchWrapper ref={ref}>
          <LanguageSwitchSelectedVal
            onClick={() => {
              setIsComponentVisible(!isComponentVisible);
            }}
          >
            {i18n.resolvedLanguage}
            <DownArrow>
              <KeyboardArrowDownIcon color={'inherit'} />
            </DownArrow>
          </LanguageSwitchSelectedVal>
          {isComponentVisible && (
            <LanguageSwitchDropdown className={isLeftLaigned ? 'left_laigned' : 'right_aligned'}>
              {Object.keys(lngs).map(
                (lng: any) =>
                  i18n.resolvedLanguage !== lng && (
                    <div
                      key={lng}
                      onClick={() => handleLaguageSelection(lng)}
                      className={'language_option'}
                    >
                      {lng}
                    </div>
                  )
              )}
            </LanguageSwitchDropdown>
          )}
        </LanguageSwitchWrapper>
      )}

      {!isDropDownType && (
        <LanguageSwitchCardWrapper>
          <ShadowCard
            padding={isBorderEnabled ? 0 : 4}
            className={isBorderEnabled ? 'remove_all_shadow' : 'normal_shadow'}
          >
            {Object.keys(lngs).map((lng: any) => (
              <div
                key={lng}
                onClick={() => handleLaguageSelection(lng)}
                className={`language_option_card ${
                  i18n.resolvedLanguage === lng ? 'active' : 'in-active'
                }`}
              >
                <div className="language_option_card_left">
                  <Typography variant="h2Bold" color="gray.900" component={'p'} marginBottom={1}>
                    {lngs[lng].nativeName}
                  </Typography>
                  <Typography variant="h1" fontSize={'10px'} color="gray.400">
                    {lngs[lng].subName}
                  </Typography>
                </div>
                {i18n.resolvedLanguage === lng && (
                  <div className="tick-icon">
                    <TickIcon />
                  </div>
                )}
              </div>
            ))}
          </ShadowCard>
        </LanguageSwitchCardWrapper>
      )}
      <SnackBar
        messageHeading={responseType}
        type={responseType === 'error' ? 'error' : 'success'}
        onClose={() => setResponseType('')}
      />
    </>
  );
};
