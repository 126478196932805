import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Header,
  HeaderFixed,
  WrpperAuthorizedContainer,
  Wrapper,
  ManageVirtualCard,
  InformationList,
  ManageCardLimitsModal,
  Messages,
  CardAccountInfoModal,
} from '../../../../components/base/core';
import { Box } from '@mui/material';
import { handleManageCardsNavData } from './manageCardNavData';
import { useEffect, useState } from 'react';
import { FreezeCardModal } from 'src/components/base/core/Modals/FreezeCardModal/FreezeCardModal';
import {
  useGetTransactionDetailsQuery,
  useGetTreasuryAccountTransactionsQuery,
  useGetVertualCardDetailsQuery,
  useTreasuryFinancialCardStatusChangeMutation,
} from 'src/services/account/accountServiceApi';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';

const initialModalStates = {
  limitsModal: false,
  freezeCardModal: false,
  accountInfoModal: false,
};

export const FinanceAccountManageCardMainContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [modalStates, setModalStates] = useState(initialModalStates);
  const [cardFreezed, setCardFreezed] = useState<boolean | undefined>(undefined);
  const [cardDefaultStatus, setCardDefaultStatus] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const {
    data: cardData,
    isSuccess: isSuccessCardData,
    isLoading: isLoadingCardData,
  } = useGetVertualCardDetailsQuery(true);

  const [cardStatusChangeMutation, { isLoading: isLoadingCSC }] =
    useTreasuryFinancialCardStatusChangeMutation();

  const { data: faData, isLoading: isLoadingFaData } = useGetTransactionDetailsQuery(null);

  const {
    data: gtatData,
    isLoading: isLoadingGtatData,
    isSuccess: isSuccessGtatData,
  } = useGetTreasuryAccountTransactionsQuery(null);

  const handleModalOpen = (id?: string) => {
    switch (id) {
      case 'Limits':
        setModalStates({ ...initialModalStates, limitsModal: true });
        break;
      case 'FreezeCard':
        setModalStates({ ...initialModalStates, freezeCardModal: true });
        break;
      case 'accountDetails':
        setModalStates({ ...initialModalStates, accountInfoModal: true });
        break;
      default:
        break;
    }
  };

  const handleModalClose = () => {
    setModalStates(initialModalStates);
  };

  const clickAction = (type: any) => {
    switch (type) {
      case 'AddToAppleWallet':
        console.log('AddToAppleWallet');
        break;
      case 'OrderPhysicalCard':
        console.log('OrderPhysicalCard');
        break;
      case 'SetUpPinCode':
        console.log('SetUpPinCode');
        break;
      case 'CancelAccount':
        console.log('CancelAccount');
        break;
      default:
        handleModalOpen(type);
        break;
    }
  };

  // set the card Status in the initial load __
  useEffect(() => {
    if (isSuccessCardData) {
      if (cardData?.status === 'active') {
        setCardDefaultStatus(true);
      } else {
        setCardDefaultStatus(false);
      }
    }
  }, [isSuccessCardData, cardData]);

  const handleOnFreezeCardOnchange = (e: any) => {
    const isChecked = e.target.checked;
    setCardFreezed(isChecked);
  };

  const handleOnSubmitButtonClick = async () => {
    let status;

    if (cardFreezed) {
      status = 'active';
    } else {
      status = 'inactive';
    }

    const response: any = await cardStatusChangeMutation({ status: status });

    if (response && response?.error) {
      setMessage(response?.error?.data?.error ?? response?.error?.data?.message);
    } else {
      setMessage('');
    }

    handleModalClose();
  };

  const [manageCardNavObject] = handleManageCardsNavData(cardDefaultStatus, t);

  const totalSpentAmountDay =
    isSuccessGtatData && gtatData.totalDebitSum ? gtatData.totalDebitSum : 0;

  return (
    <>
      {(isLoadingCSC || isLoadingCardData || isLoadingFaData || isLoadingGtatData) && (
        <ManualLoader />
      )}

      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonHide={false}
          isBackButtonVisible
          paddingX={0}
          headerText={''}
          headerPaddingBottom={3}
          withCenteredHeaderText
          onBackButtonClick={() => navigate('/finance/account/main')}
          isLogoOnlyForDesktop
        />
      </HeaderFixed>

      <Wrapper isAuthorizedWrapper>
        <WrpperAuthorizedContainer>
          <Box>
            <ManageVirtualCard
              title={t('finance.manageCard.manageVirtualCard.title')}
              data={cardData}
              isSuccess={isSuccessCardData}
              totalSpendAmount={totalSpentAmountDay}
              t={t}
            />
          </Box>
          <Box>
            <InformationList
              title={t('finance.manageCard.navigation.title')}
              onClick={clickAction}
              data={manageCardNavObject}
              cardMarginTop={0}
              cardPaddingBottom={0}
            />
          </Box>
          {message !== '' && (
            <Messages messageContent={message} closeEvent={() => setMessage('')} />
          )}
        </WrpperAuthorizedContainer>
      </Wrapper>

      {modalStates.accountInfoModal && (
        <CardAccountInfoModal closeIdModalEvent={handleModalClose} cardData={faData} t={t} />
      )}

      {modalStates.limitsModal && (
        <ManageCardLimitsModal
          closeIdModalEvent={handleModalClose}
          cardData={cardData}
          t={t}
          totalSpentAmount={totalSpentAmountDay}
        />
      )}

      {modalStates.freezeCardModal && (
        <FreezeCardModal
          closeIdModalEvent={handleModalClose}
          onFreezeCardOnchange={handleOnFreezeCardOnchange}
          defaultChecked={cardDefaultStatus}
          onAcceptButtonClick={handleOnSubmitButtonClick}
          isFreezeModalButtonDisabled={cardFreezed === undefined}
          t={t}
        />
      )}
    </>
  );
};
