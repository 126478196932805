/*eslint-disable */
export enum ActionType {
  CREATE_INDIVIDUAL_ACCOUNT = 'account',
  CREATE_COMPANY_ACCOUNT = 'company',
  GET_ACCOUNT = 'getAccount',
  UNAUTHORIZED = 'unauthorized',
  UPDATE_INDIVIDUAL_ACCOUNT = 'update_account',
  UPDATE_COMPANY_ACCOUNT = 'update_company',
  SERVER_ERROR_500 = 'error500',
  AUTH_REQUEST_VERIFICATION = 'verify_phone',
  GENERATE_ACCESS_TOKEN = 'generate_access_token',
  AJAX_LOADING = 'ajax_loading',
  IS_NATIVE_APP = 'is_native_app',
  USER_DETAILS = 'user_details',
  LOGGING_IN = 'logging_in',
  GET_ACCOUNT_LINK = 'get_account_link',
  GET_TERMS = 'getAccountTerms',
  REFRESH_TOKEN = 'getRefreshToken',
  GET_LOGIN = 'getLogginDetails',
  BANK_DETAILS = 'save_bank_details',
  API_TIMEOUT = 'api_timeout',
  GET_BANK_DETAILS = 'get_bank_details',
  GET_EARNINGS_SUBMIT = 'getEarningsSubmitDetails',
  GET_INVOICE_DETAILS = 'GetInvoiceDetails',
  GET_PAYOUT_TERMS = 'GetPayoutTerms',
  SET_PAYOUT_TERMS = 'SetPayoutTerms',
  LOGOUT = 'LogoutDetails',
  GET_ADDITIONAL_PHONE = 'GetAdditionalPhoneNumber',
}

export enum linkType {
  ONBORSDING = 'account_onboarding',
  DASHBOARD = 'account_dashboard',
}
