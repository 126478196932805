import { styled } from '@mui/material/styles';

export const BottomRow = styled('div')(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;

  &.header {
   padding-top: ${theme.spacing(4)};
   padding-bottom: ${theme.spacing(2)};
   border-bottom: 1px solid ${theme.palette.gray[50]};
  }

  &.content {
   padding-top: ${theme.spacing(4)};
   padding-bottom: ${theme.spacing(3)};
  }

  &.total {
   padding-top: ${theme.spacing(3)};
   border-top: 1px solid ${theme.palette.gray[50]};
  }

  &.header,
  &.content,
  &.total {
   &_left {
    min-width: calc(100% - 100px);
    max-width: calc(100% - 100px);
    padding-right: 10px;
   }
  }

  &.header,
  &.content,
  &.total {
   &_right {
    min-width: 100px;
    max-width: 100px;
    padding-left: 10px;
   }
  }
`
);
