import { useState } from 'react';

const initialVisibilityState = {
  step1: true,
  step2: false,
  step3: false,
};

export const handleBankSetupNavigation = () => {
  const [containersVisible, setContainersVisible]: any = useState(initialVisibilityState);

  const handleClickBankManual = () => {
    setContainersVisible({
      ...containersVisible,
      step1: false,
      step2: true,
    });
  };

  const handleClickContinueToBankDetails = () => {
    setContainersVisible({
      ...containersVisible,
      step1: false,
      step2: false,
      step3: true,
    });
  };

  const handleBackButtonClick = () => {
    if (containersVisible.step2) {
      setContainersVisible({
        ...containersVisible,
        step1: true,
        step2: false,
        step3: false,
      });
    }
    if (containersVisible.step3) {
      setContainersVisible({
        ...containersVisible,
        step1: false,
        step2: true,
        step3: false,
      });
    }
  };

  return [
    containersVisible,
    handleClickBankManual,
    handleClickContinueToBankDetails,
    handleBackButtonClick,
    setContainersVisible,
  ];
};
