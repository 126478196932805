import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState } from 'src/store';

import { commonSpacing, theme } from 'src/shared/theme/theme';

import { Box, Button, Typography } from '@mui/material';
import {
  Header,
  Wrapper,
  HeadingSummaryBlock,
  ContentContainerBottom,
  Messages,
  DivWrapper,
} from 'src/components/base/core';
import { AccountSearchConfirmationIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';

export interface ISearchVendor {
  onBackButtonClick?: () => void;
  number?: string;
  email?: string;
  onSubmitEvent?: () => void;
  isPayupPhoneAccountPresent?: boolean;
  isPayupEmailAccountPresent?: boolean;
}

export const AccountAlreadyAvailableContainer = ({
  onBackButtonClick,
  number,
  email,
  onSubmitEvent,
  isPayupPhoneAccountPresent,
  isPayupEmailAccountPresent,
}: ISearchVendor) => {
  const { t } = useTranslation();
  const serverErrors = useSelector((state: RootState) => state.serverError);

  return (
    <Wrapper>
      <DivWrapper className="no_fullscreen">
        <Box
          component={'div'}
          className={'container_content'}
          sx={{
            [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
              minHeight: 400 + commonSpacing.desktopPaddingB * 4,
            },
          }}
        >
          <Header isBackButtonVisible={true} onBackButtonClick={onBackButtonClick} paddingX={0} />
          <Box sx={{ marginBottom: 14 }}>
            <HeadingSummaryBlock
              heading={t('headingContent.statusses.accountALreadyAvailable.heading')}
              headingAlignment="left"
              content={t('headingContent.statusses.accountALreadyAvailable.content1', {
                numberOrEmail: isPayupEmailAccountPresent
                  ? `email ${email}`
                  : isPayupPhoneAccountPresent
                  ? `phone number ${number}`
                  : `${email}/${number}`,
              })}
              contentAlignment="left"
            />
            <Typography
              variant="body2"
              sx={{
                textAlign: 'left',
                marginTop: 3,
              }}
            >
              {t('headingContent.statusses.accountALreadyAvailable.content2')}
            </Typography>
            {serverErrors && (
              <Messages messageHeading="Supplier Search" messageContent={serverErrors} />
            )}
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 3,
              color: 'primary.main',
            }}
          >
            <AccountSearchConfirmationIcon />
          </Box>
        </Box>

        <ContentContainerBottom className={'no_fullscreen'}>
          <Button
            variant="containedLarge"
            color="primary"
            type="submit"
            onClick={onSubmitEvent}
            fullWidth
            sx={{ overflow: 'hidden' }}
          >
            {t('buttonTexts.loginButtonText')}
          </Button>
        </ContentContainerBottom>
      </DivWrapper>
    </Wrapper>
  );
};
