import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  FinanceContainerNotifyMe,
  FinanceAccountInitializationContainer,
  FinanceAnnouncementScreenContainer,
} from '../../../containers';
import { useGetAccountQuery } from 'src/services/account/accountServiceApi';
import { ManualLoader } from 'src/components/base/core';

export const Finance = () => {
  const { treasuryAndCards } = useFlags();
  const { data, isSuccess } = useGetAccountQuery(null);

  const isTreasuryEnabled =
    data && isSuccess && data?.treasury_account_id !== null && data?.treasury_status === 'active'
      ? true
      : false;

  const isNewTreasuryFlowEnabled: boolean = treasuryAndCards ? true : false;

  return (
    <>
      {isSuccess ? (
        <>
          {isNewTreasuryFlowEnabled ? (
            isTreasuryEnabled ? (
              <FinanceAccountInitializationContainer />
            ) : (
              <FinanceAnnouncementScreenContainer />
            )
          ) : (
            <FinanceContainerNotifyMe />
          )}
        </>
      ) : (
        <ManualLoader />
      )}
    </>
  );
};
