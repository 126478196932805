import { ShadowCard } from 'src/components/base/core';
import { Box, Typography } from '@mui/material';
import { BatchDetailProps } from 'src/models/component/base.model';
import { MiddleRow, ReceiptLink } from './BatchDetailMiddle.style';
import {
  dateFormatMMMSpaceDD,
  dateFormatWithTimeZoneForEarning,
  getArrivalTimeforInstantPayment,
  timeFormatWithoutTimeZoneForEarning,
} from 'src/shared/utilities/dob';
import { BatchTimeLineItem } from '../BatchTimeLineItem/BatchTimeLineItem';

export const BatchDetailMiddle = ({
  batchDetails,
  payoutId,
  isDatesPendingInvoice = false,
  isInstantPayMode,
  t,
}: BatchDetailProps) => {
  const getBatchStatus = batchDetails?.status;

  const isSubmittedPayout = getBatchStatus === 'pending';
  const isApprovedPayout = getBatchStatus === 'approved';
  const isPaidPayout = getBatchStatus === 'paid';
  const isInTransitPayout = batchDetails?.estimated_approved_date ?? false;

  const dateFirstStep = batchDetails?.invoice_submitted_date;
  const dateSecondStep = batchDetails?.estimated_approved_date;
  const dateThirdStep = batchDetails?.approved_at_date ?? batchDetails?.estimated_approved_date;
  const dateFourthStep = batchDetails?.paid_at_date ?? batchDetails?.estimated_payout_date;
  const isPaymentEstArrivalDateisHigher =
    batchDetails?.payment_arrival_estimated_date >= batchDetails?.estimated_approved_date
      ? true
      : false;

  const dateFifthStepForInstantPayment = `${dateFormatMMMSpaceDD(
    batchDetails?.payment_arrival_estimated_date
  )}, ${timeFormatWithoutTimeZoneForEarning(
    batchDetails?.payment_arrival_estimated_date
  )} CST - ${getArrivalTimeforInstantPayment(batchDetails?.payment_arrival_estimated_date)} CST`;
  const dateFifthStepForNonInstantPayment = `${dateFormatMMMSpaceDD(
    isPaymentEstArrivalDateisHigher
      ? batchDetails?.estimated_approved_date
      : batchDetails?.payment_arrival_estimated_date
  )} - ${dateFormatMMMSpaceDD(
    isPaymentEstArrivalDateisHigher
      ? batchDetails?.payment_arrival_estimated_date
      : batchDetails?.estimated_approved_date
  )}`;
  const dateFifthStep = isInstantPayMode
    ? dateFifthStepForInstantPayment
    : dateFifthStepForNonInstantPayment;

  const dateSixthStep =
    batchDetails?.actual_arrival_date ?? batchDetails?.payment_arrival_estimated_date;

  return (
    <ShadowCard padding={4} marginBottom={4}>
      <MiddleRow className="header">
        <Box className="header_left">
          <Typography variant="h1Bold" color={'gray.500'}>
            {t('invoiceSummary.timeLineText')}
          </Typography>
        </Box>
      </MiddleRow>
      <BatchTimeLineItem
        itemName={t('batches.details.timeline.step1Title')}
        dateText={dateFormatWithTimeZoneForEarning(dateFirstStep)}
        timeText={timeFormatWithoutTimeZoneForEarning(dateFirstStep)}
        position={1}
        isActive={isSubmittedPayout || isApprovedPayout || isPaidPayout || isInTransitPayout}
      />

      <BatchTimeLineItem
        itemName={t('batches.details.timeline.step2Title')}
        dateText={`${dateFormatMMMSpaceDD(
          batchDetails?.invoice_submitted_date
        )} - ${dateFormatWithTimeZoneForEarning(dateSecondStep)}`}
        timeText={timeFormatWithoutTimeZoneForEarning(dateSecondStep)}
        position={2}
        isActive={isInTransitPayout || isApprovedPayout}
      />

      <BatchTimeLineItem
        itemName={t('batches.details.timeline.step3Title')}
        dateText={
          isDatesPendingInvoice && !isApprovedPayout
            ? t('invoiceSummary.middle.waitingForCustomerToApprove')
            : dateFormatWithTimeZoneForEarning(dateThirdStep)
        }
        timeText={
          isDatesPendingInvoice && !isApprovedPayout
            ? undefined
            : timeFormatWithoutTimeZoneForEarning(dateThirdStep)
        }
        position={3}
        isActive={isApprovedPayout || isPaidPayout}
      />

      <BatchTimeLineItem
        itemName={t('batches.details.timeline.step4Title')}
        dateText={
          isDatesPendingInvoice
            ? t('invoiceSummary.middle.pendingCustomerPay')
            : dateFormatWithTimeZoneForEarning(dateFourthStep)
        }
        timeText={
          isDatesPendingInvoice ? undefined : timeFormatWithoutTimeZoneForEarning(dateFourthStep)
        }
        position={4}
        isActive={isPaidPayout}
      />

      <BatchTimeLineItem
        itemName={t('batches.details.timeline.step5Title')}
        dateText={dateFifthStep}
        timeText={
          isInstantPayMode
            ? undefined
            : timeFormatWithoutTimeZoneForEarning(batchDetails?.payment_arrival_estimated_date)
        }
        position={5}
        isActive={isPaidPayout}
      />

      <BatchTimeLineItem
        itemName={t('batches.details.timeline.step6Title')}
        dateText={
          !isPaidPayout && isInstantPayMode
            ? `${dateFormatMMMSpaceDD(
                batchDetails?.payment_arrival_estimated_date
              )}, ${getArrivalTimeforInstantPayment(
                batchDetails?.payment_arrival_estimated_date
              )} CST`
            : dateFormatWithTimeZoneForEarning(dateSixthStep)
        }
        timeText={timeFormatWithoutTimeZoneForEarning(dateSixthStep)}
        position={6}
        isActive={isPaidPayout}
      />

      {isPaidPayout && (
        <a href={`/api/transfer/${payoutId}/receipt`} target="_blank">
          <ReceiptLink>{t('batches.details.timeline.transcriptLink')}</ReceiptLink>
        </a>
      )}
    </ShadowCard>
  );
};
