import { Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useResetPasswordLinkMutation } from 'src/services/auth/authServiceApi';
import { CustomerAdditionConfirmModalIconWrapper } from '../../Modals/CustomerAdditionConfirmModal/CustomerAdditionConfirmModal.style';
import { FormInput } from '../FormInput/FormInput';
import { ConfirmIcon } from '../../Media/Icon/Icons/Icons';
import { Modal } from '../../Modals/Modal/Modal';

export interface IFormInPutChangePassword {
  email?: string;
}
export const FormInPutChangePassword = ({ email = '' }: IFormInPutChangePassword) => {
  const { t } = useTranslation();
  const [passwordChangeConfirmed, setPasswordChangeConfirmed] = useState(false);
  const [resetPassword] = useResetPasswordLinkMutation();

  const handleChangePasswordClick = async () => {
    await resetPassword(email);
    setPasswordChangeConfirmed(true);
  };

  const handleConfirmButtonClick = () => {
    setPasswordChangeConfirmed(false);
  };

  return (
    <>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            zIndex: 1,
          }}
        >
          <FormInput
            label={t('formInput.password')}
            id={'password'}
            type="password"
            readonly={true}
            value={'•••••••••••••••'}
          />
        </Box>
        <Typography
          sx={{
            fontSize: 10,
            fontWeight: 'medium',
            position: 'absolute',
            right: 4,
            top: 16,
            zIndex: 2,
            cursor: 'pointer',
          }}
          color={'orange.400'}
          onClick={handleChangePasswordClick}
        >
          {t('formInput.changePasswordText')}
        </Typography>
      </Box>

      {passwordChangeConfirmed && (
        <Modal onCloseEvent={handleConfirmButtonClick} hideClose={true} isMobileBottomFixed={false}>
          <CustomerAdditionConfirmModalIconWrapper marginBottom={6}>
            <ConfirmIcon />
          </CustomerAdditionConfirmModalIconWrapper>
          <Box marginBottom={6} paddingX={5}>
            <Stack spacing={3}>
              <Typography variant={'h4ExtraBold'} textAlign={'center'}>
                {t('settings.ChnagePasswordConfirmation.title')}
              </Typography>
              <Typography variant="body2" textAlign="center" color={'gray.700'}>
                {t('settings.ChnagePasswordConfirmation.content')}
              </Typography>
            </Stack>
          </Box>
          <Box marginBottom={2} justifyContent={'center'} display={'flex'}>
            <Button
              onClick={handleConfirmButtonClick}
              variant="containedLarge"
              size="small"
              color="primary"
              type="submit"
              sx={{ overflow: 'hidden', width: 120 }}
            >
              {t('buttonTexts.doneButton')}
            </Button>
          </Box>
        </Modal>
      )}
    </>
  );
};
