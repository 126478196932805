import {
  MoneyIcon,
  IdentityIcon,
  PeopleIcon,
  SingleUser,
} from 'src/components/base/core/Media/Icon/Icons/Icons';
import { ChoiceCardElement, ChoiceCardTextRow, ChoiceCardArrow } from './ChoiceCard.style';
import { Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

interface ChoiceCardProps {
  /**
   * Optional click handler
   */
  onClick?: () => void;
  /**
   * Highlight the selected value
   */
  isSelected?: boolean;
  /**
   * Hover Enabled
   */
  isHover?: boolean;
  /**
   * Enable and disable the icon
   */
  isIconEnabled?: boolean;
  /**
   * Selection Individual or Business
   */
  isIndividual?: boolean;
}

/**
 * Primary UI component for user interaction
 */
export const ChoiceCard = ({
  onClick,
  isSelected,
  isHover = true,
  isIconEnabled = true,
  isIndividual = false,
  ...rest
}: ChoiceCardProps) => {
  const { t } = useTranslation();

  return (
    <ChoiceCardElement
      onClick={onClick}
      className={isSelected && isHover ? 'active' : !isHover ? 'hover_disabled' : 'in_active'}
      {...rest}
    >
      <Stack spacing={2}>
        <Typography variant="h3Bold" color={'gray.700'} marginBottom={3}>
          {isIndividual ? t('choice.individual.heading') : t('choice.business.heading')}
        </Typography>

        <ChoiceCardTextRow>
          <div className="icon_wrapper">{isIndividual ? <SingleUser /> : <IdentityIcon />}</div>
          <Typography variant="body1" color={'gray.800'}>
            {isIndividual ? t('choice.individual.info1') : t('choice.business.info1')}
          </Typography>
        </ChoiceCardTextRow>

        {!isIndividual && (
          <ChoiceCardTextRow>
            <div className="icon_wrapper">
              <PeopleIcon />
            </div>
            <Typography variant="body1" color={'gray.800'}>
              {t('choice.business.info2')}
            </Typography>
          </ChoiceCardTextRow>
        )}

        <ChoiceCardTextRow>
          <div className="icon_wrapper">
            <MoneyIcon />
          </div>
          <Typography variant="body1" color={'gray.800'}>
            {isIndividual ? t('choice.individual.info2') : t('choice.business.info3')}
          </Typography>
        </ChoiceCardTextRow>
      </Stack>
      {/** */}
      {isIconEnabled && (
        <ChoiceCardArrow>
          <KeyboardArrowRightIcon color={'inherit'} />
        </ChoiceCardArrow>
      )}
    </ChoiceCardElement>
  );
};
