import { configs } from 'src/shared/config/config';
import { getEarningFormattedDate } from '../shared/utilities/dob';
import moment from 'moment';
import { useCurrencyFormat, useCurrencyFormatWithoutSymbol } from 'src/hooks/useCurrencyFormat';

export const handleTheadColumnClassName = (
  _index?: any,
  _isOldInvoices: boolean = false,
  _iconArrowVisible: boolean = true
) => {
  let setClassName = '';

  if (_index === 0) {
    setClassName = `first`;
  }

  if (_index === 1) {
    setClassName = `second`;
  }

  //need to show date column in old invoices
  if (_index === 2) {
    setClassName = `third hidden-mobile ${
      !_iconArrowVisible ? 'icon_arrow_hide' : 'icon_arrow_visible'
    }`;
  }

  return setClassName;
};

export const handleColumnTooltipContent = (_index: any) => {
  let tooltipContent = '';

  if (_index === 0) {
    tooltipContent = 'Job and invoice details pulled from the business';
  }

  if (_index === 1) {
    tooltipContent = 'Estimated Payout amount based on selected Net X terms';
  }

  if (_index === 2) {
    tooltipContent = 'This is the date on which the invoice was submitted.';
  }

  return tooltipContent;
};

export const handleTbodyColumnClassName = (
  _index: any,
  _isOldInvoices?: boolean,
  _isHandleArrowClickable?: boolean,
  _isUpdate: boolean = false,
  _iconArrowVisible: boolean = true,
  _isUpcomingTran: boolean = false
) => {
  let setClassName = '';
  const handleCursorClass = _isHandleArrowClickable ? 'is_cursor' : 'no_cursor';

  if (_index === 0) {
    setClassName = `first ${handleCursorClass}`;
  }

  if (_index === 1) {
    setClassName = `second`;
  }

  if (_index === 2) {
    setClassName = `third ${handleCursorClass} ${
      !_iconArrowVisible ? 'icon_arrow_hide' : 'icon_arrow_visible'
    }`;
  }

  if (_isUpdate) {
    setClassName = `${setClassName} updated_terms update_ref_remove`;
  }

  if (_isUpcomingTran) {
    setClassName = `${setClassName} upcomingTran`;
  }
  return setClassName;
};

export const handlePayoutDate = (
  data: any,
  _tabType?: string,
  _isTransactionGridType?: boolean
) => {
  const invoice_date = data.invoiced_at ? getEarningFormattedDate(data.invoiced_at) : '-';
  const paid_date = data?.paid_at ? getEarningFormattedDate(data?.paid_at) : undefined;
  const approved_date = data.approved_at ? getEarningFormattedDate(data.approved_at) : '-';
  const created_date = data.created_at ? getEarningFormattedDate(data.created_at) : '-';

  const setDate: any =
    _tabType === 'submitted' && !_isTransactionGridType
      ? invoice_date
      : _tabType === 'submitted' && _isTransactionGridType
      ? created_date
      : _tabType === 'transactions'
      ? paid_date ?? created_date
      : _tabType === 'paid'
      ? paid_date ?? '-'
      : _tabType === 'approved'
      ? approved_date
      : '-';

  return setDate;
};

/**
 * remove added class after 15 second
 */
export const removeAddedClass = () => {
  setTimeout(() => {
    Array.from(document.querySelectorAll('.update_ref_remove')).map((x) =>
      x.classList.remove('updated_terms')
    );
  }, configs.GRID_ROW_HEIGHLIGHT_TIME * 1000);
};

/**
 * check term update within 15 second
 * @param updated_date
 */
export const isTermUpdate = (updated_date: string) => {
  //current time & date
  const currentDate = new Date();
  let isUpdateResently = false;
  // @ts-ignore
  const conertedUpdate = new Date(updated_date);
  // @ts-ignore
  const milliseconds = Math.abs(conertedUpdate - currentDate);
  // @ts-ignore
  const secs = Math.floor(Math.abs(milliseconds) / 1000);
  if (secs <= configs.GRID_ROW_HEIGHLIGHT_TIME) {
    isUpdateResently = true;
  }
  //remove class after 15 secound
  removeAddedClass();
  return isUpdateResently;
};

export const handleTransactionCreatedAt = (date?: any) => {
  const createdDate = date ? moment.unix(date).format('MM/DD/YYYY hh:mm a') : null;

  return createdDate;
};

export const handleTransactionAmount = (transactionAmount?: any) => {
  let amount;
  let plusValue = false;

  if (transactionAmount) {
    if (transactionAmount > 0) {
      amount = `+${useCurrencyFormat(transactionAmount / 100)}`;
      plusValue = true;
    } else {
      const convertAMountToPositiove = Math.abs(transactionAmount);
      amount = `-${useCurrencyFormat(convertAMountToPositiove / 100)}`;
      plusValue = false;
    }
  } else {
    amount = useCurrencyFormat(0);
  }

  return [amount, plusValue];
};

export const handleTransactionAmountWithoutSymbol = (transactionAmount?: any) => {
  let amount;
  let plusValue = false;

  if (transactionAmount) {
    if (transactionAmount > 0) {
      amount = `${useCurrencyFormatWithoutSymbol(transactionAmount / 100)}`;
      plusValue = true;
    } else {
      const convertAMountToPositiove = Math.abs(transactionAmount);
      amount = `${useCurrencyFormatWithoutSymbol(convertAMountToPositiove / 100)}`;
      plusValue = false;
    }
  } else {
    amount = useCurrencyFormatWithoutSymbol(0);
  }

  return [amount, plusValue];
};

export const handleTransactionDescription = (description?: any) => {
  const contentTextArray = description.split('|');
  const contentText = contentTextArray[contentTextArray.length - 1];
  // const getLastStringArray = contentGetLastText.split(' ');
  // const contentText = getLastStringArray[getLastStringArray.length - 1];

  return contentText;
};
