import {
  Wrapper,
  HeadingSummaryBlock,
  DivWrapper,
  ContentContainerBottom,
} from 'src/components/base/core';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useLocation } from 'react-router-dom';
import { Images } from 'src/shared/utilities/images';
import { commonSpacing, theme } from 'src/shared/theme/theme';
import { routeType } from 'src/helpers/containers/helperRoutePreference';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';

export const VerificationFailedContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [updatePreferenceOnServer] = handlePreferences();

  const { isFromFinance } =
    location?.state != null
      ? (location?.state as { isFromFinance: boolean })
      : { isFromFinance: false };

  const handleButtonClick = () => {
    localStorage.setItem('review_error_block', 'visible');
    if (localStorage.getItem('failed_company') === 'true') {
      updatePreferenceOnServer(routeType.BUSINESS_REVIEW);
      navigate('/business-review', { state: { isFromFinance: isFromFinance } });
    } else {
      updatePreferenceOnServer(routeType.INDIVIDUAL_REVIEW);
      navigate('/individual-review', { state: { isFromFinance: isFromFinance } });
    }
  };

  return (
    <Wrapper>
      <DivWrapper className="no_fullscreen">
        <Box component={'div'} className={'container_content no_header'}>
          <Box
            component={'div'}
            className={'container_content_top'}
            sx={{
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                minHeight: 320 + commonSpacing.desktopPaddingB * 4,
              },
            }}
          >
            <div className="status_image">
              <img src={Images.VERIFICATION_FAILED} alt="Status" height={200} />
            </div>
            <div className="content_block">
              <HeadingSummaryBlock
                heading={t('headingContent.statusses.verificationFailed.heading')}
                headingAlignment="center"
                content={t('headingContent.statusses.verificationFailed.content')}
                contentAlignment="center"
              />
            </div>
          </Box>
        </Box>
        <ContentContainerBottom className={'no_note no_fullscreen'}>
          <Button
            variant="containedLarge"
            color="primary"
            type="button"
            fullWidth
            onClick={handleButtonClick}
            sx={{ overflow: 'hidden' }}
          >
            {t('buttonTexts.reviwDataButton')}
          </Button>
        </ContentContainerBottom>
      </DivWrapper>
    </Wrapper>
  );
};
