export const handleIDVerificationForFaFlow = (result: any) => {
  const getIndividualObj = result?.individual;
  const isCompanyAccount = result?.business_type === 'company';
  const isIndividualAccount = result?.business_type === 'individual';
  const getIndividualVerificationObj = getIndividualObj?.verification;
  const isIDVerificationDone = getIndividualVerificationObj?.status === 'verified';
  const isSSNlast4Provided = getIndividualObj?.ssn_last4_provided;
  let isSSNUpdated = false;

  if (isCompanyAccount) {
    isSSNUpdated = isIDVerificationDone && isSSNlast4Provided;
  } else if (isIndividualAccount) {
    isSSNUpdated = isIDVerificationDone;
  }

  return isSSNUpdated;
};

export const handleMissingKYCDetails = (result: any) => {
  const getIndividualObj = result?.individual;
  const isCompanyAccount = result?.business_type === 'company';
  const isIndividualAccount = result?.business_type === 'individual';

  const getPersonalAddressObj = getIndividualObj?.address;
  const isAddressAdded = getPersonalAddressObj?.line1 !== null;
  const getDOBObj = getIndividualObj?.dob;
  const isDobAdded = getDOBObj?.day !== null;
  const getRelationShipObj = getIndividualObj?.relationShip;
  const isTitleAdded = getRelationShipObj?.title !== null;
  const isPercentageOwnershipAdded = getRelationShipObj?.percent_ownership > 0;

  const isSSNUpdated = handleIDVerificationForFaFlow(result);

  let isKYCDetailsMissing = false;

  if (isIndividualAccount) {
    isKYCDetailsMissing = isSSNUpdated && isDobAdded && isAddressAdded;
  }

  if (isCompanyAccount) {
    isKYCDetailsMissing =
      isSSNUpdated && isPercentageOwnershipAdded && isTitleAdded && isDobAdded && isAddressAdded;
  }

  return isKYCDetailsMissing;
};
