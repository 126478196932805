import React from 'react';
import { PaymentTableWrapper } from './TableWrapper.style';
import { invoiceBatchTabsType } from 'src/shared/utilities/interface';

export interface PaymentTableProps {
  children?: React.ReactNode;
  isToggle?: boolean;
  tabType?: invoiceBatchTabsType;
  onClick?: any;
}

export const TableWrapper = ({ children, isToggle, tabType, ...rest }: PaymentTableProps) => {
  return (
    <>
      {isToggle && (
        <PaymentTableWrapper
          {...rest}
          className={tabType === 'submitted' ? 'table_submitted' : 'payment_table'}
        >
          {children}
        </PaymentTableWrapper>
      )}
    </>
  );
};
