import axios from 'axios';
import config from '../config';

export const AddAditionalPhoneDetails = async (phone: any, id: any) => {
  config(true);
  localStorage.setItem('hide_loader', 'true');
  return axios
    .post(`/account/add-additional-account-phones/${id}`, {
      phone_numbers: phone,
    })
    .then((res) => {
      localStorage.removeItem('hide_loader');
      // @ts-ignore
      if (res?.response?.status === 500) {
        // @ts-ignore
        return res?.response?.data;
      } else {
        return res;
      }
    })
    .catch((error) => {
      localStorage.removeItem('hide_loader');
      return error.response.data;
    });
};

export const getAditionalPhoneDetails = async (id: any) => {
  config(true);
  localStorage.setItem('hide_loader', 'true');
  return axios
    .post(`/account/get-additional-account-phones/${id}`)
    .then((res) => {
      localStorage.removeItem('hide_loader');
      return res;
    })
    .catch((error) => {
      localStorage.removeItem('hide_loader');
      return JSON.parse(error?.request?.response);
    });
};

export const deleteAditionalPhoneDetails = async (phone: any, id: any) => {
  config(true);
  localStorage.setItem('hide_loader', 'true');
  return axios
    .post(`/account/delete-additional-account-phones/${id}`, {
      phone_numbers: phone,
    })
    .then((res) => {
      localStorage.removeItem('hide_loader');
      return res;
    })
    .catch((error) => {
      localStorage.removeItem('hide_loader');
      return error.response.data;
    });
};
