import { ActionType } from '../action-types/index';
import { Action } from '../actions';
import { AuthParams } from '../../models/login.model';
import { initAuth } from '../actions/auth.init';

const reducer = (state = initAuth, action: Action): AuthParams => {
  switch (action.type) {
    case ActionType.AUTH_REQUEST_VERIFICATION:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
