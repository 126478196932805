import axios from 'axios';
import config from '../config';

export const GetLogginDetails = async (hideLoader?: boolean) => {
  config(hideLoader);
  return axios
    .get(`/login/isloggedin`)
    .then((res) => res)
    .catch((error) => error.response.data);
};
