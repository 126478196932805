import { ActionType } from '../action-types/index';
import { Action } from '../actions';

const initialState = '';

const reducer = (state: string = initialState, action: Action): string => {
  switch (action.type) {
    case ActionType.SERVER_ERROR_500:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
