import { ActionType } from '../action-types/index';
import { Action } from '../actions';
import { initAccount } from '../../models/init/account.init';
import { AccountParams } from '../../shared/utilities/interface';

const reducer = (state: AccountParams = initAccount, action: Action): any => {
  switch (action.type) {
    case ActionType.CREATE_INDIVIDUAL_ACCOUNT:
    case ActionType.GET_ACCOUNT:
    case ActionType.CREATE_COMPANY_ACCOUNT:
      return {
        ...state,
        ...action.payload,
      };
      break;
    case ActionType.UNAUTHORIZED:
      return {
        ...initAccount,
      };
      break;
    default:
      return null;
  }
};

export default reducer;
