import { useFlags } from 'launchdarkly-react-client-sdk';

export const getUpdateRouteObj = (
  type: any,
  step: any,
  status: any = null,
  relativeRoutePath: string = '/'
) => {
  const { webauthnEnabled } = useFlags();

  let routeData = {
    status: webauthnEnabled ? 'sign-up' : 'customers-addition',
    currentStep: null,
    accountType: '',
    relativeRoutePath: webauthnEnabled ? '/sign-up' : '/customers-addition',
  };
  switch (type) {
    case routeType.HOME:
      routeData = {
        currentStep: step,
        relativeRoutePath: '/dashboard',
        accountType: '',
        status: 'home',
      };
      break;
    case routeType.OPEN_ROUTE:
      routeData = {
        currentStep: step,
        relativeRoutePath: relativeRoutePath,
        accountType: '',
        status: status,
      };
      break;
    case routeType.CUSTOMER_ADDITION:
      routeData = {
        currentStep: step,
        relativeRoutePath: '/customers-addition',
        accountType: '',
        status: 'customers-addition',
      };
      break;
    case routeType.SIGN_UP:
      routeData = {
        currentStep: step,
        relativeRoutePath: '/sign-up',
        accountType: '',
        status: 'sign-up',
      };
      break;
    case routeType.IDENTITY_VERIFICATION:
      routeData = {
        status: 'identity-verification',
        relativeRoutePath: '/identity-verification',
        currentStep: step,
        accountType: 'individual',
      };
      break;
    case routeType.BUSINESS_INFO:
      routeData = {
        status: 'business-info',
        relativeRoutePath: '/business-info',
        currentStep: step,
        accountType: 'company',
      };
      break;
    case routeType.BUSINESS_REP:
      routeData = {
        status: 'business-representative',
        relativeRoutePath: '/business-representative',
        currentStep: step,
        accountType: 'company',
      };
      break;
    case routeType.BUSINESS_REVIEW:
      routeData = {
        currentStep: step,
        relativeRoutePath: '/business-review',
        accountType: 'company',
        status: 'business-review',
      };
      break;
    case routeType.INDIVIDUAL_REVIEW:
      routeData = {
        currentStep: step,
        relativeRoutePath: '/individual-review',
        accountType: 'individual',
        status: 'individual-review',
      };
      break;
    case routeType.VERIFICATION_FAIL_INDIVIDUAL:
    case routeType.VERIFICATION_FAIL_BUSINESS:
      routeData = {
        status: 'verification-failed',
        relativeRoutePath: '/verification-failed',
        currentStep: step,
        accountType: type === routeType.VERIFICATION_FAIL_BUSINESS ? 'company' : 'individual',
      };
      break;
    case routeType.VERIFICATION_FAIL_SSN:
      routeData = {
        status: 'ssn-verification-failed',
        relativeRoutePath: '/ssn-verification-failed',
        currentStep: step,
        accountType: '',
      };
      break;
    case routeType.EIN_FAIL_BUSINESS:
      routeData = {
        status: 'ein-verification-failed',
        relativeRoutePath: '/ein-verification-failed',
        currentStep: step,
        accountType: '',
      };
      break;
    case routeType.ID_DOC_FAIL:
      routeData = {
        status: 'continueto-id-doc-on-verification-failed',
        relativeRoutePath: '/continueto-id-doc-on-verification-failed',
        currentStep: step,
        accountType: '',
      };
      break;
    case routeType.ACCOUNT_APPROVED:
      routeData = {
        status: 'account-approved',
        relativeRoutePath: '/account-approved',
        currentStep: step,
        accountType: '',
      };
      break;
    case routeType.ENABLE_NOTIFICATIONS:
      routeData = {
        status: 'enable-notifications',
        relativeRoutePath: '/enable-notifications',
        currentStep: step,
        accountType: '',
      };
      break;
    case routeType.EARNING_APPROVED:
      routeData = {
        status: 'earnings-approved',
        relativeRoutePath: '/earnings/approved',
        currentStep: step,
        accountType: '',
      };
      break;
    case routeType.EARLY_PAYOUT:
      routeData = {
        currentStep: step,
        relativeRoutePath: '/early-payout',
        accountType: '',
        status: 'early-payout',
      };
      break;
    case routeType.REFER_A_FRIEND:
      routeData = {
        currentStep: step,
        relativeRoutePath: '/refer-a-friend',
        accountType: '',
        status: 'refer-a-friend',
      };
      break;
    case routeType.SIGNUP_SUCCESS:
      routeData = {
        currentStep: step,
        relativeRoutePath: '/signup-success',
        accountType: '',
        status: 'signup-success',
      };
      break;
    case routeType.TERM_SELECTION:
      routeData = {
        currentStep: step,
        relativeRoutePath: '/payment-selection',
        accountType: '',
        status: 'payment-selection',
      };
      break;
    case routeType.BANK_CREATION:
      routeData = {
        currentStep: step,
        relativeRoutePath: '/bank-details',
        accountType: '',
        status: 'bank-details',
      };
      break;
    case routeType.ACCOUNT_COMPLETED:
      routeData = {
        currentStep: step,
        relativeRoutePath: '/account',
        accountType: '',
        status: 'account',
      };
      break;
    case routeType.SETTINGS:
      routeData = {
        currentStep: step,
        relativeRoutePath: '/settings',
        accountType: '',
        status: 'settings',
      };
      break;
  }

  return routeData;
};

export const routeType: any = {
  CUSTOMER_ADDITION: 'customers-addition',
  SIGN_UP: 'sign-up',
  IDENTITY_VERIFICATION: 'identity-verification',
  INDIVIDUAL_INFO: 'review-info',
  INDIVIDUAL_REVIEW: 'individual-review',
  BUSINESS_INFO: 'business-info',
  BUSINESS_REP: 'business-representative',
  BUSINESS_REVIEW: 'business-review',
  VERIFICATION_FAIL_INDIVIDUAL: 'verification-failed',
  VERIFICATION_FAIL_SSN: 'ssn-verification-failed',
  VERIFICATION_FAIL_BUSINESS: 'verification-failed',
  EIN_FAIL_BUSINESS: 'ein-verification-failed',
  ID_DOC_FAIL: 'continueto-id-doc-on-verification-failed',
  TERM_SELECTION: 'payment-selection',
  BANK_CREATION: 'bank-details',
  BANK_CREATION_SUCSESS: 'bank-details-sucsess',
  ACCOUNT_COMPLETED: 'account',
  ACCOUNT_APPROVED: 'account-approved',
  ENABLE_NOTIFICATIONS: 'enable-notifications',
  EARNING_APPROVED: 'earnings-approved',
  EARLY_PAYOUT: 'early-payout',
  REFER_A_FRIEND: 'refer-a-friend',
  SIGNUP_SUCCESS: 'signup-success',
  OPEN_ROUTE: 'open',
  HOME: 'dashboard',
  SETTINGS: 'settings',
};
