import { AuthParams } from '../../models/login.model';
import { TokenParams } from '../../shared/utilities/interface';

export const initAuth: AuthParams = {
  phone_number: '',
  response: {
    phone_number: '',
    _id: '',
    phone_verified: false,
    request_language: '',
  },
};

export const initToken: TokenParams = {
  response: {
    data: {
      access_token: '',
      expires_in: 0,
      refresh_token: '',
      token_type: '',
    },
  },
};
