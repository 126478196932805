import { ShadowCard } from 'src/components/base/core';
import { Box, Typography } from '@mui/material';
import { BottomRow } from './BatchDetailBottom.style';
import { BatchDetailProps } from 'src/models/component/base.model';
import moneyFormat from 'src/shared/utilities/moneyFormat';
import { BatcheInvoiceItem } from './BatcheInvoiceItem';

export const BatchDetailBottom = ({ batchDetails, t }: BatchDetailProps) => {
  const itemArr = batchDetails?.invoiceDetails;

  return (
    <ShadowCard padding={4} marginBottom={4}>
      <Box marginBottom={6}>
        <Typography variant="h1Bold" color={'gray.500'}>
          {t('invoiceSummary.work.workText')}
        </Typography>
      </Box>

      <Box display={'flex'} flexDirection={'column'}>
        <Box display={'flex'} flexDirection={'column'} marginBottom={4}>
          {itemArr &&
            itemArr.map(
              (
                item: {
                  amount: number;
                  approved_at?: string;
                  coupa_invoice_number?: string;
                  invoice_lines?: any[];
                  invoiced_at?: string;
                  paid_at?: string;
                },
                index: number | string
              ) => {
                const isInvoiceLinesAvailable =
                  item?.invoice_lines && item?.invoice_lines?.length > 0 ? true : false;

                const isLastIndex = index === itemArr.length - 1;
                return (
                  <Box key={index} marginBottom={isLastIndex ? 1 : 3}>
                    <BatcheInvoiceItem
                      item={item}
                      t={t}
                      paymode={batchDetails?.method}
                      isInvoiceLinesAvailable={isInvoiceLinesAvailable}
                    />
                  </Box>
                );
              }
            )}
        </Box>
        <BottomRow className="total">
          <Box className="total_left">
            <Typography variant="caption1" component={'p'} color={'gray.700'}>
              {t('invoiceSummary.work.totalText')}
            </Typography>
          </Box>
          <Box className="total_right">
            <Typography variant="caption1" component={'p'} color={'gray.700'}>
              {batchDetails?.amount && moneyFormat(batchDetails?.amount)}
            </Typography>
          </Box>
        </BottomRow>
      </Box>
    </ShadowCard>
  );
};
