import { Typography } from '@mui/material';
import ReactDOM from 'react-dom';
import { Icon } from 'src/components/base/core';
import { theme } from 'src/shared/theme/theme';
import { ModalWrapper, ModalOverlay, ModalContainer, ModalClose, ModalTitle } from './Modal.style';

const domNode: any = document.getElementById('app-root');

export interface ModalProps {
  onCloseEvent?: () => void;
  children?: React.ReactNode;
  hideClose?: boolean;
  isMobileBottomFixed?: boolean;
  modalTitle?: string;
  minHeight?: any;
}

function appHeight() {
  const doc = document.documentElement;
  doc.style.setProperty('--vh', window.innerHeight * 0.01 + 'px');
}

window.addEventListener('resize', appHeight);
appHeight();

export const Modal = ({
  onCloseEvent,
  children,
  hideClose = false,
  isMobileBottomFixed = true,
  modalTitle,
  minHeight = 'calc(calc(var(--vh, 1vh) * 100) - 82px)',
}: ModalProps) => {
  return ReactDOM.createPortal(
    <ModalWrapper>
      <ModalOverlay onClick={onCloseEvent} />
      <ModalContainer
        className={isMobileBottomFixed ? 'is_fixed' : 'mobile_bottom__not_fixed'}
        sx={{
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            minHeight: minHeight,
          },
        }}
      >
        {modalTitle && (
          <ModalTitle>
            <Typography variant="h3Bold" color={'gray.900'} component={'p'} margin={0}>
              {modalTitle}
            </Typography>
          </ModalTitle>
        )}
        {!hideClose && (
          <ModalClose onClick={onCloseEvent}>
            <Icon iconType="CloseIcon" />
          </ModalClose>
        )}
        {children}
      </ModalContainer>
    </ModalWrapper>,
    domNode
  );
};
