import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import {
  Header,
  HeaderFixed,
  WrpperAuthorizedContainer,
  ButtonAuthorizedWrapper,
  FormAuthorizedWrapper,
  Wrapper,
  SplashSliderFinances,
  Messages,
  FinancesTermsModal,
  SplashScreenListView,
} from '../../../../components/base/core';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { Box, Button } from '@mui/material';

import { theme } from '../../../../shared/theme/theme';

import {
  useGetAccountQuery,
  useTreasuryFinancialCardMutation,
} from 'src/services/account/accountServiceApi';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { handleMissingKYCDetails } from 'src/helpers/helperHandleAccountValues';

export const FinanceAccountInitializationContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { disableTreasurySplashScreenSlider } = useFlags();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [termsModalOpen, setTermsModalOpen] = useState<boolean>(false);
  const [enableAnnouncementWrapper, setEnableAnnouncementWrapper] = useState(false);
  const [isKYCflowRedirectionDisabled, setIsKYCflowRedirectionDisabled] = useState(false);

  const [cardCreation, { isLoading }] = useTreasuryFinancialCardMutation();
  const { data, isSuccess } = useGetAccountQuery(null);

  useEffect(() => {
    if (isSuccess) {
      const getAccountData: any = data;
      const treasuryAccountId = getAccountData?.treasury_account_id;
      const cardId = getAccountData?.card_id;
      const cardHolderId = getAccountData?.card_holder_id;
      const cardIssuingStatus = getAccountData?.card_issuing_status;
      const treasuryStatus = getAccountData?.treasury_status;
      const localStorageCardIssuedItem = localStorage.getItem('cardIssue');

      const isKYCDetailsMissing = handleMissingKYCDetails(data);

      setIsKYCflowRedirectionDisabled(isKYCDetailsMissing);

      if (
        cardIssuingStatus === 'unrequested' &&
        treasuryStatus === 'unrequested' &&
        treasuryAccountId === null &&
        cardId === null &&
        cardHolderId === null &&
        (localStorageCardIssuedItem === null || localStorageCardIssuedItem !== 'processed')
      ) {
        setEnableAnnouncementWrapper(true);
      } else {
        if (
          cardIssuingStatus === 'active' &&
          treasuryStatus === 'active' &&
          treasuryAccountId !== null &&
          cardId !== null &&
          cardHolderId !== null
        ) {
          localStorage.removeItem('cardIssue');
          setEnableAnnouncementWrapper(false);
          navigate('/finance/account/main');
        } else {
          localStorage.setItem('cardIssue', 'processed');
          setEnableAnnouncementWrapper(false);
          navigate('/finance/account-loading');
        }
      }
    }
  }, [data, isSuccess]);

  const onTermsModalOpen = () => setTermsModalOpen(true);

  const onTermsModalClose = () => setTermsModalOpen(false);

  const onMessageClose = () => setErrorMessage('');

  const onAcceptButtonClick = async () => {
    setErrorMessage('');
    const response: any = await cardCreation(null);

    if (response) {
      if (response.error) {
        setErrorMessage(response.error.data.message ?? response.error.data.error);
        setTermsModalOpen(false);
      } else {
        navigate('/finance/account-loading');
        setErrorMessage('');
        setTermsModalOpen(false);
        localStorage.setItem('cardIssue', 'processed');
      }
    }
  };

  const onNavigateToKYCflow = () => {
    navigate('/business-representative', { state: { isFromFinance: true } });
  };

  return (
    <>
      {!enableAnnouncementWrapper && (
        <Box
          sx={{
            position: 'fixed',
            display: 'flex',
            left: 0,
            right: 0,
            background: '#fff',
            zIndex: 20,

            [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
              top: 0,
              bottom: 0,
            },
            [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
              top: 0,
              bottom: '72px',
            },
          }}
        ></Box>
      )}

      {isLoading && <ManualLoader isLoaderEnable={false} />}
      <HeaderFixed className="fixed_only_desktop">
        <Header isMainMunuVisible isSkipButtonHide isBackButtonHide paddingX={0} />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper>
        <WrpperAuthorizedContainer>
          <FormAuthorizedWrapper>
            {errorMessage !== '' && disableTreasurySplashScreenSlider && (
              <Messages
                messageHeading={t('finance.splashSlider.messages.title')}
                type="error"
                messageContent={errorMessage}
                closeEvent={onMessageClose}
              />
            )}
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                  minHeight: '300px',
                  height: 'auto',
                  paddingBottom: 4,
                },
              }}
            >
              <Box
                component={'div'}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                    paddingBottom: 8,
                    paddingTop: 6,
                  },
                  [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                    flex: 1,
                  },
                }}
              >
                {!disableTreasurySplashScreenSlider ? (
                  <SplashSliderFinances />
                ) : (
                  <SplashScreenListView
                    onEnableFinaceAccount={
                      isKYCflowRedirectionDisabled ? onTermsModalOpen : onNavigateToKYCflow
                    }
                  />
                )}
              </Box>

              {errorMessage !== '' && !disableTreasurySplashScreenSlider && (
                <Messages
                  messageHeading={t('finance.splashSlider.messages.title')}
                  type="error"
                  messageContent={errorMessage}
                  closeEvent={onMessageClose}
                />
              )}
            </Box>
            <ButtonAuthorizedWrapper>
              {!disableTreasurySplashScreenSlider ? (
                <Button
                  variant="containedLarge"
                  color="primary"
                  fullWidth
                  id={'Finance'}
                  onClick={isKYCflowRedirectionDisabled ? onTermsModalOpen : onNavigateToKYCflow}
                  sx={{ backgroundColor: 'blue.500', color: 'white', marginBottom: 2 }}
                >
                  {t('finance.splashSlider.button')}
                </Button>
              ) : (
                <Button
                  variant="outlinedLarge"
                  color="primary"
                  fullWidth
                  id={'Finance'}
                  onClick={isKYCflowRedirectionDisabled ? onTermsModalOpen : onNavigateToKYCflow}
                  sx={{ marginBottom: 2 }}
                >
                  {t('finance.splashListView.mainViewButton')}
                </Button>
              )}
            </ButtonAuthorizedWrapper>
          </FormAuthorizedWrapper>
        </WrpperAuthorizedContainer>
      </Wrapper>

      {termsModalOpen && (
        <FinancesTermsModal
          onAcceptButtonClick={onAcceptButtonClick}
          closeIdModalEvent={onTermsModalClose}
          t={t}
        />
      )}
    </>
  );
};
