import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const FormInputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 24px;
  justify-content: center;
  align-items: center;
`;

export const FormInputContainer = styled(Box)(
  ({ theme }) => `
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;

  input {
    width: 44px;
    height: 40px;
    margin: ${theme.spacing(0, 0.75)};
    color: ${theme.palette.gray[900]};
    font-weight: 400;
    font-size: 35px;
    line-height: 35px;
    padding: 0;
    text-align: center;
    border-width: 0 0 3px 0;
    border-style: solid;
    border-color: ${theme.palette.gray[300]};
    outline: 0 !Important;
    background: transparent !important;
    border-radius: 0px !important;

    &:focus,
    &:active {
      border-color: ${theme.palette.primary.main};
    }

    &.is-error {
      border-color: ${theme.palette.error.main};
    }

    &.is-valid {
      border-color: ${theme.palette.primary.main};
    }
   
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
      -webkit-text-fill-color: #333;
      background-color: transparent !important;
      background-clip: content-box !important;
    }

    &:active,
    &:focus,
    &:hover {
      background: transparent !important;
    }
  }
`
);
