import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const FormInputWrapper = styled(Box)(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 ${theme.spacing(4)}; 

    .password {
      input {
        -webkit-text-security: disc;
        -moz-webkit-text-security: disc;
        -moz-text-security: disc;
      }
    }

    &.is-native {
      .password {
        input {
          color: ${theme.palette.gray[800]};
          min-height: 20px;
          font-weight: 500;
          font-size: 14px !important;
        }
      }
    }

    input {
      border: none !important; 
      color: ${theme.palette.gray[900]}; 
      font-size: 14px !important;
      background: ${theme.palette.white} !important;

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${theme.palette.gray[500]}; 
        opacity: 1; /* Firefox */
      }
  
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${theme.palette.gray[500]}; 
      }
  
      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${theme.palette.gray[500]}; 
      }

      &.MuiInputBase-input.MuiOutlinedInput-input {
        padding-top: 11px;
        padding-bottom: 11px;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.palette.gray[100]};
      border-radius: ${theme.shape.borderRadius}px;
    }

    &.valid_input {
      label {
        color: ${theme.palette.primary.main};
      }
      
      input {
        color: ${theme.palette.gray[900]}; 
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: ${theme.palette.primary.main};
        * {
          color: ${theme.palette.primary.main};
        }
      }
    }

    .Mui-error,
    &.error_input {
      label {
        color: ${theme.palette.error.main};
      }

      input {
        color: ${theme.palette.gray[900]}; 
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: ${theme.palette.gray[100]};
        * {
          color: ${theme.palette.error.main};
        }
      }
    }

    .MuiIconButton-root.MuiIconButton-edgeEnd {
      padding-right: 0;
      background: transparent !important;
    }

    .MuiFormLabel-root.Mui-focused {
      color: ${theme.palette.gray[600]};
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.palette.gray[100]};
      border-width: 1px;
    }

    label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-outlined{
      top: -2px;
      line-height: 16px;

      &.MuiInputLabel-shrink.MuiFormLabel-root {
        font-size: 12px;
        transform: translate(14px, -9px) scale(1);
        background: transparent;
        padding-right: 2px;
        top: 0;
        left: -4px;
      }
    }
`
);

export const ReplaceCard = styled(Box)(
  ({ theme }) => `
    width: 100%;
    display: flex;
    border: 1px solid ${theme.palette.gray[100]};
    border-radius: 4px;
    justify-content: space-between;
    padding: 10px 12px;
    align-items: center;
    box-sizing: border-box;
    
    .MuiTypography-root.MuiTypography-h1  {
      align-items: center;
      margin-bottom: 0;
    }

    .icon_wrapper {
      cursor: pointer;
      display: inline-flex;

      svg {
        height: 20px;
        color: ${theme.palette.primary.main};
      }
    }

    .error_icon_wrapper {
      cursor: pointer;
      display: inline-flex;

      svg {
        height: 20px;
        color: ${theme.palette.red[500]};
      }
    }
`
);

export const HighLightText = styled(Box)`
  margin: 8px 0 0;
  color: var(--black100);
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`;

export const DotsWrapper = styled(Box)(
  ({ theme }) => `
  color: #2c2d30;
  font-weight: 500;
  font-size: 14px !important;
  margin-top: 4px;

  &.is-native {
    font-size: 29px !important; 
    margin-top: 10px;
  }
  
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    width: 50px;

    &.is-native {
      width: 85px;
    }
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    width: 50px;
  }
`
);

export const DashWrapper = styled('span')(
  ({ theme }) => `
  font-weight: 800;
  font-size: 8px;
  margin-left: ${theme.spacing(0.25)};
  margin-right: ${theme.spacing(0.25)};
`
);

export const SecondDashWrapper = styled('span')(
  ({ theme }) => `
  font-weight: 800;
  font-size: 8px;
  margin-left: ${theme.spacing(0.25)};
`
);
