import axios from 'axios';
import config from '../config';

export const SearchCoupaSupplier = async (phone_number: string, type: string = 'work') => {
  config(false);
  return axios
    .get(
      `/coupa/suppliers?contacts[phone-${type}][number]=${phone_number.substring(
        5,
        phone_number.length
      )}&contacts[phone-${type}][area-code]=${phone_number.substring(2, 5)}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return JSON.parse(error.request.response);
    });
};

export const SearchCoupaSupplierByEmail = async (email: string = '') => {
  config(false);
  return axios
    .get(`/coupa/suppliers?primary-contact[email][contains]=${email}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return JSON.parse(error.request.response);
    });
};
