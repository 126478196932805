import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';

import dtoToFormValues from '../../../../../shared/utilities/dto';
import { DOBtoDate } from '../../../../../shared/utilities/dob';
import { AccountParams } from '../../../../../shared/utilities/interface';
import { getCookie } from '../../../../../shared/utilities/cookiesHelper';

import { revieInfoSchemaObj } from '../../../../../helpers/validationSchemas';
import {
  setCoupaInfoValues,
  setCoupaInfoValuesForEmailLogin,
} from '../../../../../helpers/setCoupaValues';

import { initAccount } from '../../../../../models/init/account.init';
import { ERROR_MESSAGES } from '../../../../../constants/Validation';

import { RootState } from 'src/store';

import { commonSpacing, theme } from 'src/shared/theme/theme';
import { useGetBlockHeight } from 'src/hooks/useGetBlockHeight';

import { Box, Button } from '@mui/material';
import {
  Header,
  Wrapper,
  HeadingSummaryBlock,
  FormInput,
  FormInputPhoneNumber,
  FormWrapper,
  ContentContainerBottom,
  Messages,
} from 'src/components/base/core';
import { handleReviewInfo } from 'src/helpers/containers/helperReviewInfo';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { trackEvent } from '../../../../../components/analytics/service';
import { useGetAccountQuery } from 'src/services/account/accountServiceApi';
import { handleVerifyPhone } from 'src/helpers/helperPhoneVerificaition';
import { PhoneVerifyCodeContainer } from '../../VerifyCode/VerifyCode';

type LocationState = {
  isBack?: boolean;
  edit?: boolean;
  backFrom?: string;
};

export const ReviewInfoContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { webauthnEnabled, disabledPhoneVerificationStep } = useFlags();

  const [formData, setFormData] = useState<AccountParams>(initAccount);
  const [verifyCodeContainerVisible, setVerifyCodeContainerVisible] = useState(false);
  const [handleVerificationCode, verifiedPhoneNumber, errorState, isPhoneVerificationLoading] =
    handleVerifyPhone();

  const [elementRef, height] = useGetBlockHeight();
  const serverErrors = useSelector((state: RootState) => state.serverError);
  const [coupaId, setCoupaId] = useState('');
  const [btnSubmitted, setBtnSubmitted] = useState(false);
  const [typeChanged, setTypeChanged] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);
  const [multipleInputValues, setMultipleInputValues]: any = useState([]);
  const [deletedAddtionalPhone, setDeletedAddtionalPhone] = useState('');
  const [addtionalPhone, setaddtionalPhone] = useState('');
  const [addtionalPhoneErrors, setaddtionalPhoneErrors] = useState('');
  const [accountEditEnable, setAccountEditEnable] = useState(false);
  const { isBack, edit, backFrom } =
    location?.state != null
      ? (location?.state as LocationState)
      : { edit: null, isBack: null, backFrom: '' };
  const [enableBack, setEnableBack]: any = useState(true);
  const { webShowBackButton, webAllowSignupWithoutCoupa } = useFlags();
  const [isChangeBusinessType, setIsChangeBusinessType] = useState(false);
  const { data, isFetching } = useGetAccountQuery('');
  const [
    getCoupaSupplyerByEmail,
    accountData,
    handleRedirection,
    onChangeHandlerPhone,
    handleBackButton,
    getMaxId,
    additionalInputValidation,
    submitInfo,
    serverSideErrors,
    setServerSideErrors,
    isLoading,
  ]: any = handleReviewInfo();

  useEffect(() => {
    if (data) {
      const accountData = data;
      if (accountData?.business_type === 'company') {
        setIsChangeBusinessType(true);
      } else {
        setIsChangeBusinessType(false);
      }

      if (accountData?.individual?.verification?.status === 'verified') {
        setEnableBack(false);
      }

      if (
        accountData?.individual?.ssn_last4_provided &&
        !isBack &&
        !localStorage.getItem('enable_account_edit')
      ) {
        navigate('/identity-verification');
      }

      if (localStorage.getItem('enable_account_edit')) {
        setAccountEditEnable(true);
      }

      setTimeout(function () {
        localStorage.removeItem('isBack');
      }, 2000);

      dtoToFormValues(setValue, accountData);

      const tempdob = DOBtoDate(accountData?.individual?.dob as string) as string;
      setValue('dob', tempdob.replace(/[^0-9]/g, ''));

      if (!serverErrors && btnSubmitted) {
        if (webAllowSignupWithoutCoupa) {
          const prefilData = localStorage.getItem('coupaObj') as string;
          const data = JSON.parse(prefilData);
          if (data && !data.name) {
            getCoupaSupplyerByEmail(getValues);
          }
        }
      }

      if ((edit || isBack) && !btnSubmitted) {
        setTimeout(function () {
          localStorage.removeItem('enable_account_edit');
        }, 2000);
      }
    }
  }, [data]);

  const revieInfoSchema = yup
    .object({
      ...revieInfoSchemaObj,
      phone_number: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .min(14, ERROR_MESSAGES.PHONE_NUMBER_INVALID),
    })
    .required();

  //hook-forms
  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<AccountParams>({
    resolver: yupResolver(revieInfoSchema),
    defaultValues: initAccount,
    mode: 'onSubmit',
  });

  useEffect(() => {
    accountData && dtoToFormValues(setValue, accountData);
    //prefill info with coupa
    if (!accountData && getCookie('coupaObj')) {
      let coupaId = setCoupaInfoValues(setValue);
      if (webauthnEnabled) {
        setCoupaInfoValuesForEmailLogin(setValue);
      }
      setCoupaId(coupaId);
    }
  }, []);

  const onChangeHandler = (event: any) => {
    onChangeHandlerPhone(event, getValues, setValue, btnSubmitted);
  };

  const updateBindingsChange = (event: any, feild: any) => {
    setValue(feild, event.target.value);
  };

  const skipTheOTPVerification = (data: any) => {
    submitInfo(
      data,
      setBtnSubmitted,
      coupaId,
      navigate,
      accountEditEnable,
      isChangeBusinessType,
      backFrom
    );
    setFormData(initAccount);
    setVerifyCodeContainerVisible(false);
  };

  const moveToOTPVerification = (data: any) => {
    setFormData(data);
    // @ts-ignore
    handleVerificationCode(data, getValues, setVerifyCodeContainerVisible);
  };

  const appSubmit = handleSubmit(async (data) => {
    if (webauthnEnabled && !disabledPhoneVerificationStep) {
      if (accountData && accountData.individual.phone) {
        if (accountData.individual.phone.includes(data?.phone_number?.replace(/[^0-9]/g, ''))) {
          skipTheOTPVerification(data);
        } else {
          moveToOTPVerification(data);
        }
      } else {
        moveToOTPVerification(data);
      }
    } else {
      skipTheOTPVerification(data);
    }
  });

  if (verifyCodeContainerVisible) {
    return (
      <PhoneVerifyCodeContainer
        phone={verifiedPhoneNumber}
        setVerifyCodeContainerVisible={setVerifyCodeContainerVisible}
        onSubmitFunction={() =>
          submitInfo(
            formData,
            setBtnSubmitted,
            coupaId,
            navigate,
            accountEditEnable,
            isChangeBusinessType,
            backFrom,
            setVerifyCodeContainerVisible
          )
        }
        phoneWithMask={formData.phone_number}
      />
    );
  }

  return (
    <Wrapper>
      {(isLoading || isPhoneVerificationLoading) && <ManualLoader />}
      <FormWrapper onSubmit={appSubmit} className="no_fullscreen">
        <Box
          component={'div'}
          className={'container_content'}
          ref={elementRef}
          sx={{
            [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
              minHeight: height,
              paddingBottom: height ? 4 : commonSpacing.desktopPaddingB,
            },
          }}
        >
          <Header
            isBackButtonVisible={webShowBackButton && !accountCreated && !edit && enableBack}
            onBackButtonClick={() => {
              handleBackButton(navigate);
              trackEvent('Back Click on Basic Info');
            }}
            isProgressBarVisible={!edit}
            totalProgressSteps={5}
            currentProgressStep={1}
            paddingX={0}
            backButtonId={'IndividualReviewInfoBack'}
          />
          <Box sx={{ marginBottom: 6 }}>
            <HeadingSummaryBlock
              heading={t('headingContent.individual.personalInfo.heading')}
              headingAlignment="left"
              content={t('headingContent.individual.personalInfo.content')}
              contentAlignment="left"
            />
            {(serverErrors || addtionalPhoneErrors !== '') && (
              <Messages
                messageHeading="Personal Info"
                messageContent={serverErrors || addtionalPhoneErrors}
              />
            )}
          </Box>
          <Box>
            <Controller
              control={control}
              name="first_name"
              render={({ field: { value } }) => (
                <FormInput
                  label={t('formInput.firstName')}
                  id={'first_name'}
                  placeholder={t('placeHolder.firstName')}
                  {...register('first_name')}
                  error={errors?.first_name?.message}
                  onChange={(event: any) => {
                    event.target.value = event.target.value.replace('  ', ' ');
                    updateBindingsChange(event, 'first_name');
                  }}
                  value={value}
                  inputFocus={(e: any) => {
                    trigger('first_name');
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="last_name"
              render={({ field: { value } }) => (
                <FormInput
                  id={'lastName'}
                  label={t('formInput.lastName')}
                  placeholder={t('formInput.lastName')}
                  {...register('last_name')}
                  error={errors?.last_name?.message}
                  onChange={(event: any) => {
                    event.target.value = event.target.value.replace('  ', ' ');
                    updateBindingsChange(event, 'last_name');
                  }}
                  value={value}
                  inputFocus={(e: any) => {
                    trigger('last_name');
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="email"
              render={({ field: { value } }) => (
                <FormInput
                  label={t('formInput.email')}
                  id={'email'}
                  placeholder={t('placeHolder.emailAddress')}
                  {...register('email')}
                  inputMode="email"
                  error={errors?.email?.message}
                  onChange={(event: any) => {
                    updateBindingsChange(event, 'email');
                  }}
                  value={value}
                  inputFocus={(e: any) => {
                    trigger('email');
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="phone_number"
              render={({ field: { value } }) => (
                <FormInputPhoneNumber
                  label={t('formInput.phone')}
                  id={'phone'}
                  placeholder={'(123) 543-3454'}
                  {...register('phone_number')}
                  inputError={errors.phone_number?.message || errorState}
                  onChange={onChangeHandler}
                  inputMode="numeric"
                  value={value}
                  inputFocus={(e: any) => {
                    trigger('phone_number');
                  }}
                />
              )}
            />
          </Box>
          {serverSideErrors && (
            <Messages
              topMargin={4}
              bottomMargin={0}
              messageHeading={
                serverSideErrors.includes('phone')
                  ? t('errorMessages.phoneUniqueError.heading')
                  : serverSideErrors.includes('email')
                  ? t('errorMessages.emailUniqueError.heading')
                  : serverSideErrors
              }
              messageContent={
                serverSideErrors.includes('phone')
                  ? t('errorMessages.phoneUniqueError.content')
                  : serverSideErrors.includes('email')
                  ? t('errorMessages.emailUniqueError.content')
                  : 'Please provide the valid details to use PayUp.'
              }
              closeEvent={() => setServerSideErrors('')}
            />
          )}
        </Box>

        <ContentContainerBottom className={'no_fullscreen'}>
          <Button
            variant="containedLarge"
            color="primary"
            type="submit"
            onClick={() => {
              setBtnSubmitted(true);
            }}
            fullWidth
            sx={{ overflow: 'hidden' }}
          >
            {edit ? t('buttonTexts.updateButton') : t('buttonTexts.continueButton')}
          </Button>
        </ContentContainerBottom>
      </FormWrapper>
    </Wrapper>
  );
};
