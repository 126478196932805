import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HeadingSummaryBlock } from '../../CommonNestedBlocks/ContentBlocks/HeadingSummaryBlock';
import { Form } from '../../FormElements/Form/Form.style';
import { Modal } from '../Modal/Modal';
import { FormInput } from '../../FormElements/FormInput/FormInput';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FormInputPhoneNumber } from '../../FormElements/FormInputPhoneNumber/FormInputPhoneNumber';

import { ManualLoader } from '../../OtherUtilities/Loader/ManualLoader';
import { DeleteIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import { AddMoreButtonWrapper, CompanyCounter, FormHeader } from './InviteUseModal.style';
import { ERROR_MESSAGES } from 'src/constants/Validation';
import { useEffect } from 'react';
import { ShadowCard } from '../..';

export type FormProps = {
  companyName: string;
  contactName: string;
  city: string;
  phone_number: string;
};

export type FormValues = {
  customer: {
    companyName: string;
    contactName: string;
    city: string;
    email: string;
    phone_number: string;
    id?: string;
  }[];
};

export const InviteUseModal = ({
  onCloseEvent,
  onFormSubmit,
  isLoading,
  updateRef,
  deleteCustomer,
  isDeleteCustomer = true,
}: any) => {
  const { t } = useTranslation();

  let defaultValues: any = {
    companyName: '',
    contactName: '',
    city: '',
    phone_number: '',
    email: '',
  };

  const {
    register,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      customer: updateRef === null ? [defaultValues] : [],
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const { fields, append, remove } = useFieldArray({
    name: 'customer',
    control,
  });

  useEffect(() => {
    if (updateRef) {
      append({
        companyName: updateRef?.customer_name,
        contactName: updateRef?.contact_person,
        city: updateRef?.city,
        phone_number: updateRef?.phone,
        email: updateRef?.email,
        id: updateRef?.id,
      });
    }
  }, [updateRef]);

  const appSubmit = handleSubmit(async (data) => {
    onFormSubmit(data);
  });

  const onChangeHandler = (event: any, index: any) => {
    setValue(`customer.${index}.phone_number`, event, { shouldValidate: true });
  };

  const handleAddMoreCustomer = () => {
    append(defaultValues);
  };

  return (
    <>
      <Modal onCloseEvent={onCloseEvent}>
        {isLoading && <ManualLoader />}
        <Box marginBottom={6}>
          <HeadingSummaryBlock
            heading={
              updateRef === null
                ? t('headingContent.addCustomerModal.heading')
                : t('headingContent.updateCustomerModal.heading')
            }
            headingAlignment="left"
            content={t('headingContent.addCustomerModal.content')}
            contentAlignment="left"
          />
        </Box>
        <Form onSubmit={appSubmit}>
          {fields.map((field, index) => {
            return (
              <ShadowCard
                marginBottom={4}
                key={index}
                paddingX={4}
                paddingTop={4}
                paddingBottom={0}
              >
                <FormHeader>
                  <Typography variant="caption1" color={'gray.600'} textTransform="uppercase">
                    {`${t('customerAddition.inviteUserModal.companyText')} ${index + 1}`}
                  </Typography>
                  {fields.length !== 1 && (
                    <div
                      onClick={() => remove(index)}
                      className="icon_delete"
                      key={`delete${index}`}
                      style={{ cursor: 'pointer' }}
                    >
                      <DeleteIcon />
                    </div>
                  )}
                  {updateRef !== null && isDeleteCustomer && (
                    <div
                      onClick={() => deleteCustomer(updateRef.id, onCloseEvent)}
                      className="icon_delete"
                      key={`delete${index}`}
                      style={{ cursor: 'pointer' }}
                    >
                      <DeleteIcon />
                    </div>
                  )}
                </FormHeader>
                <Box marginBottom={3}>
                  <Controller
                    control={control}
                    name={`customer.${index}.companyName`}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <FormInput
                        id="companyName"
                        {...register(`customer.${index}.companyName` as const, {
                          required: true,
                        })}
                        label={`${t('formInput.comapanyName')} (${t('formInput.required')})`}
                        value={value || ''}
                        error={
                          errors?.customer?.[index]?.companyName ? ERROR_MESSAGES.REQUIRED : ''
                        }
                        onChange={onChange}
                      />
                    )}
                  />
                </Box>
                <Box marginBottom={3}>
                  <Controller
                    control={control}
                    name={`customer.${index}.city`}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <FormInput
                        id="city"
                        {...register(`customer.${index}.city` as const, {
                          required: true,
                        })}
                        label={`${t('formInput.city')} (${t('formInput.required')})`}
                        value={value || ''}
                        error={errors?.customer?.[index]?.city ? ERROR_MESSAGES.REQUIRED : ''}
                        onChange={onChange}
                      />
                    )}
                  />
                </Box>
                <Box marginBottom={3}>
                  <Controller
                    control={control}
                    name={`customer.${index}.contactName`}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <FormInput
                        id="contactName"
                        {...register(`customer.${index}.contactName`)}
                        label={t('formInput.companyContactName')}
                        value={value || ''}
                        onChange={onChange}
                      />
                    )}
                  />
                </Box>
                <Box marginBottom={3}>
                  <Controller
                    control={control}
                    name={`customer.${index}.email`}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <FormInput
                        id="email"
                        {...register(`customer.${index}.email` as const, {
                          required: true,
                          pattern: {
                            value:
                              //eslint-disable-next-line
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'Please enter a valid email',
                          },
                        })}
                        label={`${t('formInput.email')} (${t('formInput.required')})`}
                        value={value || ''}
                        onChange={onChange}
                        error={errors?.customer?.[index]?.email ? ERROR_MESSAGES.EMAIL_INVALID : ''}
                      />
                    )}
                  />
                </Box>
                <Box marginBottom={3}>
                  <Controller
                    control={control}
                    name={`customer.${index}.phone_number`}
                    render={({ field: { value } }) => (
                      <FormInputPhoneNumber
                        label={`${t('formInput.phone')} (${t('formInput.required')})`}
                        id={'phone'}
                        placeholder={'(123) 543-3454'}
                        {...register(`customer.${index}.phone_number` as const, {
                          required: true,
                          minLength: 14,
                        })}
                        onChange={(event: any) => onChangeHandler(event, index)}
                        inputError={
                          errors?.customer?.[index]?.phone_number
                            ? ERROR_MESSAGES.PHONE_NUMBER_INVALID
                            : ''
                        }
                        inputMode="numeric"
                        value={value}
                      />
                    )}
                  />
                </Box>
              </ShadowCard>
            );
          })}
          {updateRef === null && (
            <AddMoreButtonWrapper>
              <Button
                variant="outlinedLarge"
                color="primary"
                type="button"
                sx={{ overflow: 'hidden' }}
                onClick={handleAddMoreCustomer}
              >
                <span className="plus_text">+</span>
                {t('buttonTexts.inviteUserButton')}
              </Button>
            </AddMoreButtonWrapper>
          )}
          {fields.length > 0 && updateRef === null && (
            <CompanyCounter>
              <Typography variant="h2Bold" color={'gray.600'}>
                {`${fields && fields.length} ${t(
                  'customerAddition.inviteUserModal.companiesAddedText'
                )}`}
              </Typography>
            </CompanyCounter>
          )}
          <Button
            variant="containedLarge"
            color="primary"
            type="submit"
            fullWidth
            sx={{ overflow: 'hidden' }}
          >
            {updateRef === null ? t('buttonTexts.addButton') : t('buttonTexts.updateButton')}
          </Button>
        </Form>
      </Modal>
    </>
  );
};
