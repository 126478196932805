import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  NotificationMessage,
  InvoiceCard,
  LinkBankCard,
  Header,
  HeaderFixed,
  WrpperAuthorizedContainer,
  ActionCardSlider,
  ActionCardsList,
  InvoiceCardEmpty,
  Wrapper,
  ManualLoader,
  SnackBar,
} from 'src/components/base/core';
import { handleHome } from 'src/helpers/containers/helperHome';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';
import { routeType } from 'src/helpers/containers/helperRoutePreference';
import { handleSettings } from 'src/helpers/containers/helperSettings';
import { EmptyStates } from 'src/shared/utilities/images';
import { Box } from '@mui/system';
import { nativeBridgeTypes } from 'src/shared/config/config';
import { store } from 'src/store';
import { ActionType } from 'src/store/action-types';
import { PAY3342, PAY3349 } from 'src/constants/constants';
import {
  checkIsInstantPayModeAndTreasuryEnabled,
  handleSettingBusinessAIVSAM,
} from 'src/shared/utilities/dataConvertions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useUpdateAccountMutation } from 'src/services/account/accountServiceApi';
import { useHandleAuthCallbackRoute } from 'src/hooks/useHandleAuthCallbackRoute';

const dispatchLoggingIn = (v: boolean) => {
  store.dispatch({
    type: ActionType.LOGGING_IN,
    payload: v,
  });
};

export const Homecontainer = () => {
  const { t } = useTranslation();
  const { reduceTheBusinessFlowFields } = useFlags();
  const [responsePaid, responseSubmitted, responseApproved, responseTrans, account] = handleHome();
  const navigate = useNavigate();
  const [updatePreferenceOnServer] = handlePreferences();
  const [allBankDetails] = handleSettings();
  const [updateAccount, { isLoading: isUALoading }] = useUpdateAccountMutation();

  const [messageObject, verifyObj]: any = handleSettingBusinessAIVSAM(account, t);
  const [errorState, setErrorState] = useState('');
  const { isPreRouteRedirectionAvailable } = useHandleAuthCallbackRoute(navigate);

  const navigateToDetails = (type: any) => {
    navigate(type);
  };

  useEffect(() => {
    dispatchLoggingIn(false);
    updatePreferenceOnServer(routeType.HOME);
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({ type: nativeBridgeTypes.LOGIN_STATUS, isLoggedIn: true })
    );

    setTimeout(function () {
      // @ts-ignore
      const boxes = document.querySelectorAll('.card-wrapper-home-main .first');
      boxes.forEach((box) => {
        box.classList.remove('no_cursor');
        box.classList.add('is_cursor');
      });
    }, 2000);
  }, []);

  const clickOnCard = (e: any, nav: any) => {
    if (
      e?.target?.className !== 'link-to-summary-submitted' &&
      e?.target?.className !== 'link-to-summary-paid' &&
      e?.target?.className !== 'link-to-summary-approved' &&
      e?.target?.parentElement?.className !== 'link-to-summary-submitted' &&
      e?.target?.parentElement?.className !== 'link-to-summary-paid' &&
      e?.target?.parentElement?.className !== 'link-to-summary-approved'
    ) {
      navigateToDetails(nav);
    }
  };

  const isCompanyAccount = account?.business_type === 'company';
  const isIndividualAccount = account?.business_type === 'individual';

  let isAccountNotVerified = false;
  if (reduceTheBusinessFlowFields && isCompanyAccount) {
    isAccountNotVerified =
      verifyObj.personal === 'unverified' || verifyObj.business === 'unverified';
  } else {
    isAccountNotVerified =
      account?.individual?.verification?.status &&
      account?.individual?.verification?.status !== 'verified';
  }

  const isUnverifiedIndividualAccount =
    account?.currently_due_requirements?.includes('individual.id_number');

  const isUnverifiedBusinessAccount =
    isCompanyAccount && account?.currently_due_requirements?.includes('company.tax_id');

  const shouldDisplayVerifyIdentityCard =
    isAccountNotVerified || isUnverifiedIndividualAccount || isUnverifiedBusinessAccount;
  const isChargesEnabled = account?.charges_enabled;

  const tosShownAcceptanceForIndividuals =
    account?.individual?.verification?.status === 'verified' &&
    account?.errors?.length === 0 &&
    account?.currently_due_requirements?.length === 0 &&
    isIndividualAccount &&
    !account?.tos_shown_and_accepted;

  const isTosMessageShown = messageObject.triggerType === 'tos' || tosShownAcceptanceForIndividuals;

  const acceptTerms = async () => {
    const response = await updateAccount({
      tos_shown_and_accepted: true,
    });

    // @ts-ignore
    if (response && !response?.error && !isChargesEnabled) {
      navigate('/settings/hold-on', { state: { isFromHome: true } });
    }
  };

  const isCheckPayModeIsNotSelectedAndExternalAccountNull =
    account && account?.external_account_id === null && account?.default_pay_mode !== 'check';
  const [isInstatPayoutTreasuryEnabled] = checkIsInstantPayModeAndTreasuryEnabled(account);

  if (isPreRouteRedirectionAvailable) {
    return <ManualLoader />;
  }

  return (
    <>
      {isUALoading && <ManualLoader />}
      <HeaderFixed className="fixed_only_desktop">
        <Header isMainMunuVisible isSkipButtonHide isBackButtonHide paddingX={0} />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper>
        <WrpperAuthorizedContainer>
          {shouldDisplayVerifyIdentityCard && (
            <>
              <NotificationMessage
                messageHeading={t('notificationMessages.verifyIdentity.heading')}
                messageContent={t('notificationMessages.verifyIdentity.content')}
                type={'warning'}
                messageLinkEvent={() =>
                  navigate('/settings/account-info', { state: { fromHome: true } })
                }
                topMargin={0}
              />
            </>
          )}
          {isTosMessageShown && (
            <>
              <NotificationMessage
                messageHeading={'Account Restricted'}
                messageContent={'This account must accept the terms of service'}
                type={'warning'}
                messageLinkEvent={() => acceptTerms()}
                topMargin={0}
              />
            </>
          )}
          {isCheckPayModeIsNotSelectedAndExternalAccountNull && (
            <>
              <NotificationMessage
                messageHeading={t('notificationMessages.linkAccount.heading')}
                messageContent={t('notificationMessages.linkAccount.content')}
                type={'neutral'}
                messageLinkEvent={() => {
                  if (isInstatPayoutTreasuryEnabled) {
                    setErrorState(t('notificationMessages.blockAddingBankAccount.content'));
                  } else {
                    account?.external_account_id === null
                      ? navigate('/payment-selection', {
                          state: { fromHome: true, isAfterKycFromTermScreen: true },
                        })
                      : navigate('/bank-details', {
                          state: { fromHome: true, isAfterKycFromTermScreen: false },
                        });
                  }
                }}
                topMargin={0}
              />
            </>
          )}
          {!shouldDisplayVerifyIdentityCard &&
            !PAY3342 &&
            account?.default_payment_term_id === null && (
              <>
                <NotificationMessage
                  messageHeading={t('notificationMessages.payoutTerms.heading')}
                  messageContent={t('notificationMessages.payoutTerms.content')}
                  type={'neutral'}
                  messageLinkEvent={() =>
                    navigate('/settings/terms-selection', { state: { fromHome: true } })
                  }
                  topMargin={0}
                />
              </>
            )}
          {PAY3349 ? (
            <Box>
              {responseTrans && responseTrans?.payoutBatchTransfers?.nodes?.length > 0 && (
                <InvoiceCard
                  data={responseTrans?.payoutBatchTransfers?.nodes}
                  title={t('inVoiceCards.paymentsHeading')}
                  clickOnCard={(e: any) => clickOnCard(e, '/payments')}
                  amount={responseTrans?.payoutBatchTransfers?.aggregates?.sum?.paid_amount}
                  icon={'LongReceiptIcon'}
                  tabType={'transactions'}
                />
              )}
              {/** Handle the paid invoices empty states */}
              {responseTrans && responseTrans?.payoutBatchTransfers?.nodes?.length === 0 && (
                <InvoiceCardEmpty
                  emptyStateContent={t('emptyStates.invoiceCards.payments.emptyStateContent')}
                  emptyStateTitle={t('emptyStates.invoiceCards.payments.emptyStateTitle')}
                  icon={'LongReceiptIcon'}
                  imagePath={EmptyStates.paid}
                  title={t('inVoiceCards.paymentsHeading')}
                />
              )}
            </Box>
          ) : (
            <Box>
              {responsePaid && responsePaid?.payouts?.nodes?.length > 0 && (
                <InvoiceCard
                  data={responsePaid?.payouts?.nodes}
                  title={t('inVoiceCards.paidHeading')}
                  clickOnCard={(e: any) => clickOnCard(e, '/earnings/paid')}
                  amount={responsePaid?.payouts?.aggregates?.sum?.payout_amount}
                  icon={'DollarWithBoarderIcon'}
                  tabType={'paid'}
                />
              )}
              {/** Handle the paid invoices empty states */}
              {responsePaid && responsePaid?.payouts?.nodes?.length === 0 && (
                <InvoiceCardEmpty
                  emptyStateContent={t('emptyStates.invoiceCards.paid.emptyStateContent')}
                  emptyStateTitle={t('emptyStates.invoiceCards.paid.emptyStateTitle')}
                  icon={'DollarWithBoarderIcon'}
                  imagePath={EmptyStates.paid}
                  title={t('inVoiceCards.paidHeading')}
                />
              )}
            </Box>
          )}
          <Box>
            {responseApproved && responseApproved?.payouts?.nodes?.length > 0 && (
              <InvoiceCard
                data={responseApproved?.payouts?.nodes}
                title={t('inVoiceCards.approvedHeading')}
                clickOnCard={(e: any) => clickOnCard(e, '/earnings/approved')}
                amount={responseApproved?.payouts?.aggregates?.sum?.payout_amount}
                icon={'CorrectIcon'}
                tabType={'approved'}
              />
            )}
            {/** Handle the aprroved invoices empty states */}
            {responseApproved && responseApproved?.payouts?.nodes?.length === 0 && (
              <InvoiceCardEmpty
                emptyStateContent={t('emptyStates.invoiceCards.approved.emptyStateContent')}
                emptyStateTitle={t('emptyStates.invoiceCards.approved.emptyStateTitle')}
                icon={'CorrectIcon'}
                imagePath={EmptyStates.aprroved}
                title={t('inVoiceCards.approvedHeading')}
              />
            )}
          </Box>
          <Box>
            {responseSubmitted && responseSubmitted?.payouts?.nodes?.length > 0 && (
              <InvoiceCard
                data={responseSubmitted?.payouts?.nodes}
                title={t('inVoiceCards.submittedHeading')}
                clickOnCard={(e: any) => clickOnCard(e, '/earnings/submitted')}
                amount={responseSubmitted?.payouts?.aggregates?.sum?.payout_amount}
                icon={'MoveIcon'}
                tabType={'submitted'}
              />
            )}
            {/** Handle the submitted invoices empty states */}
            {responseSubmitted && responseSubmitted?.payouts?.nodes?.length === 0 && (
              <InvoiceCardEmpty
                emptyStateContent={t('emptyStates.invoiceCards.submitted.emptyStateContent')}
                emptyStateTitle={t('emptyStates.invoiceCards.submitted.emptyStateTitle')}
                icon={'MoveIcon'}
                imagePath={EmptyStates.submitted}
                title={t('inVoiceCards.submittedHeading')}
              />
            )}
          </Box>
          {allBankDetails && (
            <LinkBankCard
              title={t('home.linkBank.title')}
              onClick={() => {
                if (isInstatPayoutTreasuryEnabled) {
                  setErrorState(t('notificationMessages.blockAddingBankAccount.content'));
                } else {
                  isCheckPayModeIsNotSelectedAndExternalAccountNull
                    ? navigate('/payment-selection', {
                        state: { fromHome: true, isAfterKycFromTermScreen: true },
                      })
                    : navigate('/bank-details', {
                        state: { fromHome: true, isAfterKycFromTermScreen: false },
                      });
                }
              }}
              data={allBankDetails}
            ></LinkBankCard>
          )}
          {errorState !== '' && (
            <SnackBar
              messageHeading={t('notificationMessages.blockAddingBankAccount.heading')}
              messageContent={errorState}
              messageLinkText={t('notificationMessages.blockAddingBankAccount.btnText')}
              messageLinkEvent={() => navigate('/settings/terms-selection')}
              type="error"
              duration={3000}
              onClose={() => setErrorState('')}
              hideCloseIcon={false}
            />
          )}
          <Box>
            <ActionCardSlider />
            <ActionCardsList />
          </Box>
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
