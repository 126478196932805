import { styled } from '@mui/material/styles';

export const AccountNumberCardWrapper = styled('div')(
  ({ theme }) => `
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  background: ${theme.palette.gray[100]};
  border: 1px solid ${theme.palette.gray[100]};
  border-radius: 8px;
  padding-left: ${theme.spacing(4)};
  padding-right: ${theme.spacing(4)};
  margin-bottom:  ${theme.spacing(4)};
`
);

export const AccountNumberCardLeft = styled('div')(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
);
