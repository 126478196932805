import accountReducer from './accountReducer';
import errorReducer from './errorReducer';
import authReducer from './authReducer';
import ajaxLoadingReducer from './ajaxLoadingReducer';
import appStateReducer from './appStateReducer';
import userDetailsReducer from './userDetailsReducer';
import accountLinkReducer from './accountLinkReducer';
import errorMessageReducer from './errorMessageReducer';
import refreshTokenReducer from './refreshTokenReducer';
import logginReducer from './logginReducer';
import termsReducer from './termsReducer';
import bankReducer from './bankReducer';
import apiTimeoutReducer from './apiTimeoutReducer';
import earningsSubmitReducer from './earningsSubmitReducer';
import invoiceDetails from './invoiceDetailsReducer';
import getPayoutTerms from './getPayoutTermsReducer';
import setPayoutTerms from './setPayoutTermReducer';
import logoutDetails from './logoutReducer';
import getAdditionalPhoneDetails from './additionalNumberReducer';
import { configApi } from '../../services/config/configAPI';
import { authApi, searchVendorApi } from '../../services/auth/authServiceApi';
import { accountApi } from 'src/services/account/accountServiceApi';
import { notificationPrefsApi } from '../../services/account/notificationServiceApi';
import { customerApi } from 'src/services/customer/customerServiceApi';
import { referralServiceApi } from '../../services/account/referralServiceApi';
import { accountPreferenceApi } from 'src/services/account/account.preferenceApi';
import { invoiceApi } from 'src/services/invoice/invoiceServiceApi';
import { supportServiceApi } from '../../services/account/supportServiceApi';
import { batchesApi } from 'src/services/transfers/transfers.service';

export const reducers = {
  account: accountReducer,
  authorized: errorReducer,
  auth: authReducer,
  ajaxLoading: ajaxLoadingReducer,
  appState: appStateReducer,
  userDetails: userDetailsReducer,
  accountLink: accountLinkReducer,
  serverError: errorMessageReducer,
  refreshToken: refreshTokenReducer,
  login: logginReducer,
  terms: termsReducer,
  bank: bankReducer,
  apiTimeout: apiTimeoutReducer,
  earnings: earningsSubmitReducer,
  invoiceDetails: invoiceDetails,
  getPayoutTerms: getPayoutTerms,
  setPayoutTerms: setPayoutTerms,
  logoutDetails: logoutDetails,
  additionalNumber: getAdditionalPhoneDetails,
  [configApi.reducerPath]: configApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [searchVendorApi.reducerPath]: searchVendorApi.reducer,
  [accountApi.reducerPath]: accountApi.reducer,
  [customerApi.reducerPath]: customerApi.reducer,
  [notificationPrefsApi.reducerPath]: notificationPrefsApi.reducer,
  [referralServiceApi.reducerPath]: referralServiceApi.reducer,
  [accountPreferenceApi.reducerPath]: accountPreferenceApi.reducer,
  [invoiceApi.reducerPath]: invoiceApi.reducer,
  [supportServiceApi.reducerPath]: supportServiceApi.reducer,
  [batchesApi.reducerPath]: batchesApi.reducer,
};
