import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Lotties } from '../../../../shared/utilities/lottieJson';
import {
  DivWrapper,
  HeadingSummaryBlock,
  LottieAnimation,
  Wrapper,
} from 'src/components/base/core';
import { Box } from '@mui/material';
import { useGetAccountDataMutation } from 'src/services/account/accountServiceApi';
import { routeType } from 'src/helpers/containers/helperRoutePreference';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';

const redirection_time = 15000;

export const HoldBeforeSucessContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [getAccountData]: any = useGetAccountDataMutation();
  const [account, setAccount]: any = useState();
  const [timeoutId, setTimeoutId] = useState({});
  const [updatePreferenceOnServer] = handlePreferences();

  useEffect(() => {
    const timeoutId = setInterval(async () => {
      const retryData = await getAccountData();
      setAccount(retryData);
    }, redirection_time);
    setTimeoutId(timeoutId);

    return () => {
      clearInterval(timeoutId as NodeJS.Timeout);
    };
  }, []);

  useEffect(() => {
    //if any case having some due requirment, back to review screen
    const dueRequirements = account?.data?.currently_due_requirements;
    if (account && account?.data?.payouts_enabled && dueRequirements?.length === 0) {
      localStorage.removeItem('enable_hold_sucsess');
      localStorage.setItem('signup_done', 'true');
      localStorage.setItem('account_id', account?.data?.id);
      clearInterval(timeoutId as NodeJS.Timeout);
      updatePreferenceOnServer(routeType.SIGNUP_SUCCESS);
      navigate('/signup-success');
    } else if (dueRequirements?.length > 0) {
      if (account?.data?.business_type === 'individual') {
        updatePreferenceOnServer(routeType.INDIVIDUAL_REVIEW);
        navigate('/individual-review');
      }
      if (account?.data?.business_type === 'company') {
        updatePreferenceOnServer(routeType.BUSINESS_REVIEW);
        navigate('/business-review');
      }
    }
  }, [account]);

  return (
    <Wrapper>
      <DivWrapper className="no_fullscreen">
        <Box component={'div'} className={'container_content no_fullscreen no_header'}>
          <Box component={'div'} className={'container_content_top'}>
            <div className="status_animation">
              <LottieAnimation json={Lotties.HOLD_ON} />
            </div>
            <div className="content_block">
              <HeadingSummaryBlock
                heading={t('headingContent.statusses.onHoldBeforeSuccess.heading')}
                headingAlignment="center"
                content={t('headingContent.statusses.onHoldBeforeSuccess.content')}
                contentAlignment="center"
              />
            </div>
          </Box>
        </Box>
      </DivWrapper>
    </Wrapper>
  );
};
