import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { Button } from '@mui/material';
import {
  Header,
  HeaderFixed,
  TermsCard,
  WrpperAuthorizedContainer,
  ButtonAuthorizedWrapper,
  FormAuthorizedWrapper,
  ShadowCard,
  Wrapper,
  SnackBar,
  PayModeCard,
  ManualLoader,
} from 'src/components/base/core';

import { GetPayModes, GetTerms } from 'src/services/account/account.service';
import {
  useGetAccountQuery,
  useUpdateAccountMutation,
} from 'src/services/account/accountServiceApi';

import { AccountParams } from 'src/shared/utilities/interface';
import { PayModesInterface } from 'src/models/component/base.model';

import { handlePreferences } from 'src/helpers/containers/helperPreferences';
import { routeType } from 'src/helpers/containers/helperRoutePreference';
import { PAY3342 } from 'src/constants/constants';

import { TermsListContainer } from 'src/containers/Payment/PaymentsSelection/PaymentsSelection.style';
import { useFlags } from 'launchdarkly-react-client-sdk';

type LocationState = {
  fromHome?: boolean;
};

export const TermsSelectionContainer = ({
  isSettingDesktop = false,
  newUpdatedTerm = null,
}: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { recipientDetailsFormEnabled } = useFlags();

  const [cardNet, setCardNet] = useState('');
  const [payMode, setPayMode] = useState('');
  const [terms, setTerms] = useState([]);
  const [payModes, setPayModes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [responseType, setResponseType] = useState('');
  const [errorState, setErrorState] = useState('');

  const [updateAccount] = useUpdateAccountMutation();
  const { data } = useGetAccountQuery('');
  const [updatePreferenceOnServer] = handlePreferences();

  useEffect(() => {
    if (data) {
      setCardNet(data.default_payment_term_id);

      if (PAY3342) {
        setPayMode(data.default_pay_mode);
      }
    }
  }, [data]);

  const initPayModes = async () => {
    await GetPayModes().then((response) => {
      setPayModes(response.data);
    });
  };

  const initTerms = async () => {
    await GetTerms().then((response) => {
      setTerms(response.data.terms);
    });
  };

  const { handleSubmit } = useForm<AccountParams>();

  useEffect(() => {
    if (PAY3342) {
      initPayModes();
    } else {
      initTerms();
    }
  }, []);

  const appSubmit = handleSubmit(async () => {
    const isPayModeIsCheck = payMode === 'check';

    if (isPayModeIsCheck && recipientDetailsFormEnabled) {
      navigate('/recipient-details', {
        state: {
          isFromSettingsPayMode: true,
        },
      });
    } else {
      setIsLoading(true);
      setResponseType('');

      const isPayModeNotCheckAndBankAccountsNull =
        data && data?.external_account_id === null && payMode === 'ach';

      const isPayModeIsDebitCard = payMode === 'debit_card';

      let dataModel;

      if (PAY3342) {
        dataModel = {
          default_pay_mode: payMode,
        };
      } else {
        dataModel = {
          default_payment_term_id: cardNet,
          accept_other_terms: false,
        };
      }

      const accountUpdateResponse = await updateAccount(dataModel);
      // @ts-ignore
      if (accountUpdateResponse?.data) {
        setIsLoading(false);
        setResponseType(t('notifications.updateSuccess'));
        // @ts-ignore
        newUpdatedTerm && newUpdatedTerm(accountUpdateResponse?.data.default_payment_term_id);

        if (PAY3342) {
          if (isPayModeNotCheckAndBankAccountsNull) {
            navigate('/bank-details', {
              state: {
                isFromPayMode: true,
              },
            });
          } else if (isPayModeIsDebitCard) {
            navigate('/finance');
          }
        }
      } else {
        // @ts-ignore
        const responseError = accountUpdateResponse?.error?.data?.message;
        if (responseError && typeof responseError === 'string') {
          setErrorState('Error');
        } else {
          setResponseType('error');
        }
      }
      setIsLoading(false);
    }
  });

  const selectCard = (value: any) => {
    setCardNet(value);
  };

  const onBackButtonClick = () => {
    if ((location?.state as LocationState)?.fromHome) {
      updatePreferenceOnServer(routeType.HOME);
      navigate('/dashboard');
    } else {
      updatePreferenceOnServer(routeType.SETTINGS);
      navigate('/settings');
    }
  };

  return (
    <>
      {isLoading && <ManualLoader />}
      {!isSettingDesktop && (
        <HeaderFixed className="fixed_only_desktop">
          <Header
            isMainMunuVisible
            isSkipButtonHide
            isBackButtonHide
            paddingX={0}
            isBackButtonVisible={true}
            headerText={PAY3342 ? t('settings.PayoutMode.title') : t('settings.PayoutTerms.title')}
            withCenteredHeaderText
            onBackButtonClick={onBackButtonClick}
          />
        </HeaderFixed>
      )}
      <Wrapper
        isAuthorizedWrapper
        idSelector={!isSettingDesktop ? 'wrapperContainer' : 'settingDesktopWraper'}
        isSettingDesktop={isSettingDesktop}
      >
        <WrpperAuthorizedContainer>
          <FormAuthorizedWrapper onSubmit={appSubmit}>
            <ShadowCard
              paddingX={isSettingDesktop ? 0 : 4}
              paddingTop={isSettingDesktop ? 0 : 6}
              paddingBottom={isSettingDesktop ? 0 : 2}
              marginBottom={isSettingDesktop ? 0 : 6}
              className={isSettingDesktop ? 'remove_all_shadow' : 'normal_shadow'}
            >
              {PAY3342 ? (
                <TermsListContainer>
                  {payModes?.map((option: PayModesInterface) => {
                    return (
                      <PayModeCard
                        key={option.id}
                        id={option.id}
                        modeName={option.modeName}
                        feePercentage={option.feePercentage}
                        isActive={payMode === option.id}
                        comingSoon={!option.active}
                        onCardClick={(id) => setPayMode(id)}
                        timeDuration={option.time}
                        isIconVisible={option.id === 'debit_card' ? true : false}
                      />
                    );
                  })}
                </TermsListContainer>
              ) : (
                <TermsListContainer>
                  {terms &&
                    terms
                      .sort(function (a: any, b: any) {
                        return a.due_days - b.due_days;
                      })
                      .map((option: any, index) => {
                        const getNetAmount = parseInt(option.description);

                        return (
                          <TermsCard
                            isActive={cardNet === option.id}
                            key={index}
                            cost={option.id}
                            date={option.due_days}
                            net={100 - getNetAmount}
                            selectCard={selectCard}
                          />
                        );
                      })}
                </TermsListContainer>
              )}
            </ShadowCard>
            <SnackBar
              messageHeading={responseType}
              type={responseType === 'error' ? 'error' : 'success'}
              onClose={() => setResponseType('')}
            />
            <SnackBar
              messageHeading={errorState}
              messageContent={'Please complete your identity verification.'}
              type="error"
              duration={3000}
              onClose={() => setErrorState('')}
            />
            <ButtonAuthorizedWrapper>
              <Button variant="containedLarge" color="primary" type="submit" fullWidth>
                {t('buttonTexts.confirmButton')}
              </Button>
            </ButtonAuthorizedWrapper>
          </FormAuthorizedWrapper>
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
