import { Box, Typography } from '@mui/material';
import { FormRadioButton } from 'src/components/base/core/FormElements/FormRadioButton/FormRadioButton';

interface SelectionRowProps {
  label: string;
  dateRange?: string;
  optionId: string;
  checked?: boolean;
  isDatePicker?: boolean;
  handleChange: (selectedOptionId: string) => void;
}

export const SelectionRow = ({
  optionId,
  label = 'Radio Button',
  dateRange,
  checked = false,
  isDatePicker = false,
  handleChange,
}: SelectionRowProps) => {
  const getDateRangeText = () => {
    if (dateRange) {
      if (isDatePicker) {
        return (
          <Typography variant="h1Bold" color="blue.500" component={'span'}>
            {dateRange}
          </Typography>
        );
      }

      return (
        <Typography variant="h1" color="gray.400" component={'span'}>
          {dateRange}
        </Typography>
      );
    }

    return null;
  };

  return (
    <Box
      onClick={() => handleChange(optionId)}
      flexDirection={'row'}
      sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}
    >
      <FormRadioButton label={label} id={optionId} checked={checked} handleChange={() => {}} />
      {getDateRangeText()}
    </Box>
  );
};
