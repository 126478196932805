import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box } from '@mui/system';

import {
  AddressFields,
  ContentContainerBottom,
  FormInput,
  FormWrapper,
  Header,
  HeadingSummaryBlock,
  ManualLoader,
  Messages,
  ShadowCard,
  TermsText,
  Wrapper,
} from 'src/components/base/core';
import { commonSpacing, theme } from 'src/shared/theme/theme';
import { RecipientDetailsFormParams } from 'src/shared/utilities/interface';
import { identityVerificationSchemaObj } from 'src/helpers/validationSchemas';
import { ERROR_MESSAGES } from 'src/constants/Validation';
import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useGetBlockHeight } from 'src/hooks/useGetBlockHeight';
import {
  useGetAccountQuery,
  useUpdateAccountMutation,
} from 'src/services/account/accountServiceApi';
import dtoToRecipentFormValues from 'src/shared/utilities/dto';
import { useLocation, useNavigate } from 'react-router-dom';
import { isBillingAndRecipentAddressAreTheSame } from 'src/shared/utilities/addressUtils';
import { checkIsRecipentDetailsAreAddedOrNot } from 'src/helpers/helperHandlingTerms';

export type FormProps = {
  line1: string;
  line2: string;
  city: string;
  state: string;
  postal_code: string;
};

export const RecipientDetailsContainer = () => {
  const { t } = useTranslation();
  const location: any = useLocation();
  const navigate = useNavigate();
  let defaultValues: FormProps = { line1: '', line2: '', city: '', state: '', postal_code: '' };
  const [addressStates, setAddressStates] = useState(defaultValues);
  const { data: accountData, isLoading: isLoadingAccountData } = useGetAccountQuery(null);
  const [updateAccount, { isLoading: isLoadingUpdateAccount }] = useUpdateAccountMutation();

  const [isSameChecked, setSameChecked] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const [elementRef, height] = useGetBlockHeight();

  const isCompany = accountData?.business_type !== 'individual';
  let address = accountData?.company?.address ?? null;
  const recipentAddress = accountData?.stripe_account_metadata;

  useEffect(() => {
    if (accountData) {
      if (!isCompany) {
        address = accountData?.individual?.address ?? null;
      }

      const isBothAddressAreTheSame = isBillingAndRecipentAddressAreTheSame(
        address,
        recipentAddress
      );

      const [isAvailable] = checkIsRecipentDetailsAreAddedOrNot(accountData);

      if (isAvailable) {
        dtoToRecipentFormValues(setValue, accountData);
        setValue('name', accountData?.stripe_account_metadata?.check_recipient_details_name ?? '');
        setValue(
          'line2',
          accountData?.stripe_account_metadata?.check_recipient_details_address_2 ?? ''
        );
      }

      if (isBothAddressAreTheSame) {
        setSameChecked(true);
      }

      if (address && isSameChecked) {
        setValue('city', address.city);
        setValue('line1', address.line1);
        setValue('line2', address.line2);
        setValue('state', address.state);
        setValue('postal_code', address.postal_code);
        trigger(['line1', 'state', 'city', 'postal_code']);
      } else if (!isAvailable) {
        setValue('city', '');
        setValue('line1', '');
        setValue('line2', '');
        setValue('state', '');
        setValue('postal_code', '');
      }
    }
  }, [accountData, isSameChecked]);

  const schema = yup
    .object({
      ...identityVerificationSchemaObj,
      name: yup
        .string()
        .typeError(ERROR_MESSAGES.REQUIRED)
        .required(ERROR_MESSAGES.REQUIRED)
        .trim()
        .min(2, ERROR_MESSAGES.INVALID_NAME)
        .matches(/[A-Za-z 0-9]/g, ERROR_MESSAGES.INVALID_NAME),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    formState: { errors },
  } = useForm<RecipientDetailsFormParams>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onBackButtonClicked = () => {
    if (location && location?.state?.isFromSettingsPayMode) {
      navigate('/settings/terms-selection', { state: location.state });
    } else if (location && location?.state?.isFromSettingRPI) {
      navigate('/settings/account-info');
    } else {
      navigate('/payment-selection', { state: location.state });
    }
  };

  const onAppFormSubmit = handleSubmit(async (data: any) => {
    const updateParams = {
      default_pay_mode: 'check',
      check_recipient_details: {
        address: data.line1,
        line2: data.line2,
        name: data.name,
        zip: data.postal_code,
        state: data.state,
        city: data.city,
        pay_mode: 'check',
      },
    };

    const response: any = await updateAccount(updateParams);

    if (response?.error) {
      setErrorMessage(response?.error?.data?.error ?? response?.error?.data?.message);
    } else {
      if (location && location?.state?.isFromSettingRPI) {
        navigate('/settings/account-info');
      } else {
        navigate('/paymode-selection-success', {
          state: { ...location.state, ifFromRecipentForm: true },
        });
      }
    }
  });

  const updateBindingsChange = (event: any, feild: any) => {
    setValue(feild, event.target.value);
  };

  return (
    <>
      {(isLoadingAccountData || isLoadingUpdateAccount) && <ManualLoader />}
      <Wrapper>
        <FormWrapper onSubmit={onAppFormSubmit} className="no_fullscreen">
          <Box component={'div'} className={'container_content bottom_terms_padding terms_note'}>
            <Box
              component={'div'}
              className={'container_content_top terms_note'}
              ref={elementRef}
              sx={{
                [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                  minHeight: height,
                  paddingBottom: height ? 4 : commonSpacing.desktopPaddingB,
                },
              }}
            >
              <Header
                isBackButtonVisible={true}
                onBackButtonClick={onBackButtonClicked}
                paddingX={0}
                isLanguageSwitcher
              />
              <Box sx={{ marginBottom: 6 }}>
                <HeadingSummaryBlock
                  heading={t('headingContent.commonSteps.recipientDetails.heading')}
                  headingAlignment={'left'}
                  content={t('headingContent.commonSteps.recipientDetails.content')}
                  contentAlignment="left"
                />
              </Box>
              <ShadowCard sx={{ marginBottom: 2 }} paddingTop={6} paddingX={4} paddingBottom={2}>
                <Box>
                  <Controller
                    control={control}
                    name="name"
                    render={({ field: { onChange, value } }) => (
                      <FormInput
                        label={t('formInput.recipientName')}
                        id={'accountName'}
                        placeholder={t('placeHolder.accountName')}
                        {...register('name')}
                        error={errors?.name?.message}
                        onChange={(event: any) => {
                          event.target.value = event.target.value.replace('  ', ' ');
                          updateBindingsChange(event, 'name');
                        }}
                        value={value}
                        highLightText={t('formInput.recipientNameFieldNote')}
                      />
                    )}
                  />
                  <FormControlLabel
                    sx={{ marginBottom: 3 }}
                    control={
                      <Checkbox
                        checked={isSameChecked}
                        name="jason"
                        size="small"
                        onClick={() => setSameChecked(!isSameChecked)}
                      />
                    }
                    label={
                      <Typography variant="body1" color={'gray.700'}>
                        {accountData && isCompany
                          ? t('formInput.sameBillingAddressCheckbox')
                          : t('formInput.sameHomeAddressCheckbox')}
                      </Typography>
                    }
                  />
                  <AddressFields
                    setAddressStates={setAddressStates}
                    addressStates={addressStates}
                    register={register}
                    errors={errors}
                    control={control}
                    getValue={getValues}
                    setValue={setValue}
                    trigger={trigger}
                    highLightText={t('formInput.recipientAddressFieldNote')}
                  />
                </Box>
              </ShadowCard>
              {errorMessage !== '' && (
                <Messages
                  messageHeading={t('headingContent.commonSteps.bankDetails.heading')}
                  messageContent={errorMessage}
                  closeEvent={() => setErrorMessage('')}
                />
              )}
            </Box>
            <Box component={'div'} className="container_content_bottom terms_note">
              <TermsText
                mainContent={t('noticeBlocks.termsBlock.heading')}
                subContent={t('noticeBlocks.termsBlock.content')}
              />
            </Box>
          </Box>
          <ContentContainerBottom className={'no_fullscreen no_note'}>
            <Button variant="containedLarge" color="primary" type="submit" fullWidth>
              {t('buttonTexts.saveContinueButton')}
            </Button>
          </ContentContainerBottom>
        </FormWrapper>
      </Wrapper>
    </>
  );
};
