import { styled } from '@mui/material/styles';

export const TagWrapper = styled('div')(
  ({ theme }) => `
  display: inline-flex;
  align-items: center;
  border-radius: ${theme.shape.borderRadius}px;
  padding: ${theme.spacing(0, 1)};
  height: 20px;
  svg {
   height: 12px;
   width: 12px;
   margin-right: ${theme.spacing(1)};
  }
   &.pending {
    background: ${theme.palette.blue[75]};
    svg {
     color: ${theme.palette.darkBlue[300]};
    }
   }
   &.restricted,
   &.unverified {
    background: ${theme.palette.red[75]};
    svg {
     color: ${theme.palette.red[500]};
    }
   }
   &.verified {
    background: ${theme.palette.green[75]};
    svg {
     color: ${theme.palette.green[500]};
    }
   }
`
);
