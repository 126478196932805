import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

import {
  Header,
  HeadingSummaryBlock,
  FormWrapper,
  ContentContainerBottom,
  FormInputSSN,
  TermsText,
  IdDocumentModal,
  DocVerifiedLink,
  AccountVerificationStatusses,
  ManualLoader,
  FormInputDate,
  Messages,
} from 'src/components/base/core';
import { RefreshIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';

import { handleBusinessRepSSN } from 'src/helpers/containers/helperBusinessOwnership';
import { handleIdModalVisibility } from 'src/helpers/containers/helperIdDocumentModal';

import { dtoToFormValuesVerifySSN } from 'src/shared/utilities/dto';
import { theme, commonSpacing } from 'src/shared/theme/theme';

import { ERROR_MESSAGES } from 'src/constants/Validation';
import { useGetBlockHeight } from 'src/hooks/useGetBlockHeight';
import { isNot18, isNullDOB, isValidDOB, onChangeHandlerDateInput } from 'src/shared/utilities/dob';

export const FormSSNSubmition = ({
  onBackButtonClick,
  onFormSubmit,
  accountData,
  isFromFinance,
}: any) => {
  const { t } = useTranslation();

  const [appSubmit, isLoading, serverSideErrors, setServerSideErrors]: any =
    handleBusinessRepSSN(accountData);
  const [openIdModal, onClickIdModalOpen, onClickIdModalClose] = handleIdModalVisibility();

  const [enableSSNUpdate, setEnableSSNUpdate]: any = useState(true);
  const [companyData, setCompanyData]: any = useState();
  const [btnSubmitted, setBtnSubmitted] = useState(false);
  const [errorManualDate, setErrorManualDate] = useState(false);
  const [elementRef, height] = useGetBlockHeight();

  useEffect(() => {
    if (accountData) {
      setCompanyData(accountData);
      dtoToFormValuesVerifySSN(setValue, accountData);

      if (accountData?.individual?.ssn_last4_provided) {
        setEnableSSNUpdate(false);
      } else {
        setEnableSSNUpdate(true);
      }

      if (!getValues('dob') && !isNullDOB(accountData?.individual?.dob)) {
        const dob = accountData?.individual?.dob;
        const formattedDOB: any = moment(new Date(dob.year, dob.month - 1, dob.day));
        setValue('dob', moment(formattedDOB).format('MM/DD/yyyy'));
      }
    }
  }, [accountData]);

  const dobSchema = {
    dob: yup
      .string()
      .required(ERROR_MESSAGES.REQUIRED)
      .test(
        'len',
        errorManualDate ? ERROR_MESSAGES.DATE_INVALID_LESS_THAN_18 : ERROR_MESSAGES.DATE_INVALID,
        (value) => {
          if (value && value?.length === 10 && isNot18(value) && isValidDOB(value)) return true;
          else return false;
        }
      ),
  };

  const handleRevieInfoSchema = isFromFinance
    ? {
        ...dobSchema,
        idNumber: yup.string().when('front', {
          is: () => {
            if (!enableSSNUpdate) {
              return false;
            } else {
              return true;
            }
          },
          then: yup
            .string()
            .required(ERROR_MESSAGES.REQUIRED)
            .min(4, ERROR_MESSAGES.SSN_INVALID_LAST4),
        }),
      }
    : {
        ...dobSchema,
        idNumber: yup.string().when('front', {
          is: (front: any) => {
            if (front || !enableSSNUpdate) {
              return false;
            } else {
              return true;
            }
          },
          then: yup
            .string()
            .required(ERROR_MESSAGES.REQUIRED)
            .min(4, ERROR_MESSAGES.SSN_INVALID_LAST4),
        }),
      };

  const revieInfoSchema = yup.object({
    ...handleRevieInfoSchema,
  });

  //hook-forms
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    control,
    getValues,
    formState: { errors },
  } = useForm<any>({
    reValidateMode: 'onBlur',
    mode: 'onSubmit',
    resolver: yupResolver(revieInfoSchema),
  });

  useEffect(() => {
    if (openIdModal) {
      trigger('idNumber');
    }
  }, [openIdModal]);

  const getVerificationData = companyData && companyData?.individual?.verification;
  const confirmVerificationDocumentSubmitted =
    getVerificationData?.document?.front !== null && getVerificationData?.status === 'verified';
  const confirmUnVerificationDocumentSubmitted =
    getVerificationData?.document?.front !== null && getVerificationData?.status !== 'verified';
  const confirmationNoUploadDone =
    !confirmVerificationDocumentSubmitted && !confirmUnVerificationDocumentSubmitted;

  const onAppFormSubmit = handleSubmit(async (data: any) => {
    appSubmit(
      data,
      onFormSubmit,
      enableSSNUpdate,
      openIdModal,
      isFromFinance,
      confirmVerificationDocumentSubmitted
    );
  });

  const updateBindingsChange = (event: any, feild: any) => {
    setEnableSSNUpdate(true);
    setValue(feild, event.target.value, { shouldValidate: btnSubmitted && true });
  };

  const onChangeHandlerDate = (event: any) => {
    onChangeHandlerDateInput(event, setValue, setErrorManualDate);
  };

  return (
    <>
      {isLoading && <ManualLoader />}
      <FormWrapper onSubmit={onAppFormSubmit} className="no_fullscreen">
        <Box component={'div'} className={'container_content bottom_terms_padding terms_note'}>
          <Box
            component={'div'}
            className={'container_content_top terms_note'}
            ref={elementRef}
            sx={{
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                minHeight: height,
                paddingBottom: height ? 4 : commonSpacing.desktopPaddingB,
              },
            }}
          >
            <Header
              isBackButtonVisible={true}
              onBackButtonClick={onBackButtonClick}
              isProgressBarVisible={true}
              totalProgressSteps={6}
              currentProgressStep={5}
              paddingX={0}
            />
            <Box sx={{ marginBottom: 6 }}>
              <HeadingSummaryBlock
                heading={t('headingContent.business.ssn.heading')}
                headingAlignment="left"
                content={t('headingContent.business.ssn.content')}
                contentAlignment="left"
              />
            </Box>
            <Controller
              control={control}
              name="dob"
              render={({ field: { ref, value, ...rest } }) => (
                <FormInputDate
                  label={t('formInput.dob')}
                  id={'dob'}
                  {...register('dob')}
                  inputError={errors.dob?.message}
                  onChange={(event: any) => {
                    onChangeHandlerDate(event);
                  }}
                  inputMode="numeric"
                  inputFocus={(e: any) => {
                    trigger('dob');
                  }}
                  value={value || ''}
                />
              )}
            />
            <Controller
              control={control}
              name="idNumber"
              render={({ field: { value, ref } }) => (
                <FormInputSSN
                  label={t('formInput.ssn')}
                  id={'securityNumber'}
                  only4={true}
                  {...register('idNumber')}
                  error={(!openIdModal || isFromFinance) && errors?.idNumber?.message}
                  inputError={(!openIdModal || isFromFinance) && errors?.idNumber?.message}
                  onChange={(event: any) => {
                    updateBindingsChange(event, 'idNumber');
                  }}
                  inputFocus={(e: any) => {
                    trigger('idNumber');
                  }}
                  replace={enableSSNUpdate}
                  setEnableSSNUpdate={setEnableSSNUpdate}
                />
              )}
            />
            <Box component={'div'} paddingY={2} marginBottom={2}>
              <DocVerifiedLink
                className={`${
                  confirmVerificationDocumentSubmitted ? 'pointer_disabled' : 'pointer_enabled'
                }`}
                onClick={onClickIdModalOpen}
              >
                {confirmationNoUploadDone && (
                  <Typography
                    variant="h2"
                    color={'primary'}
                    sx={{ cursor: 'pointer', marginRight: 2 }}
                  >
                    {t('linkTexts.business.provideIDDocLink')}
                  </Typography>
                )}

                {(confirmUnVerificationDocumentSubmitted ||
                  confirmVerificationDocumentSubmitted) && (
                  <Typography
                    variant="h2"
                    color={'primary'}
                    sx={{ cursor: 'pointer', marginRight: 2 }}
                  >
                    {t('linkTexts.business.providedIDDoc')}
                  </Typography>
                )}
                {confirmUnVerificationDocumentSubmitted && !confirmationNoUploadDone && (
                  <RefreshIcon />
                )}
                {confirmVerificationDocumentSubmitted && (
                  <AccountVerificationStatusses type="verified" />
                )}
              </DocVerifiedLink>
            </Box>
            {/* {openIdModal && ( */}
            <IdDocumentModal
              register={register}
              setValue={setValue}
              verificationData={companyData && companyData.individual.verification}
              openIdModal={openIdModal}
              closeIdModalEvent={onClickIdModalClose}
            />
            {/* )} */}

            {serverSideErrors && (
              <Messages
                topMargin={0}
                bottomMargin={2}
                messageHeading={t('headingContent.business.ssn.heading')}
                messageContent={serverSideErrors}
                closeEvent={() => setServerSideErrors('')}
              />
            )}
          </Box>
          <Box component={'div'} className="container_content_bottom terms_note">
            <TermsText
              mainContent={t('noticeBlocks.termsBlock.heading')}
              subContent={t('noticeBlocks.termsBlock.content')}
            />
          </Box>
        </Box>
        <ContentContainerBottom className={'no_note no_fullscreen'}>
          <Button
            variant="containedLarge"
            color="primary"
            type="submit"
            fullWidth
            sx={{ overflow: 'hidden' }}
            onClick={() => {
              setBtnSubmitted(true);
            }}
          >
            {t('buttonTexts.continueButton')}
          </Button>
        </ContentContainerBottom>
      </FormWrapper>
    </>
  );
};
