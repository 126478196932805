import { FC, forwardRef } from 'react';

import { FormInputWrapper, FormInputContainer, HighLightText } from './FormInput.style';
import { FormInputErrorMessage } from '../FormInputErrorMessage/FormInputErrorMessage';
import { ValidTickIcon, ErrorWarningIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import { TextField } from '@mui/material';

import { InputProps } from 'src/models/component/base.model';

export const FormInput: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      label,
      type = 'text',
      placeholder,
      error,
      isValid = false,
      highLightText,
      maxWidth = '100%',
      inputMode = 'text',
      value,
      readonly,
      onChange,
      trackClicks = false,
      isValidationIconVisible = false,
      inputFocus,
      disableCopyPast = false,
      onKeyPress,
      isAmountField = false,
    },
    ref,
    ...props
  ) => {
    const handleInputClasses =
      error && !isValid ? 'error_input' : isValid ? 'valid_input' : 'normal_input';

    return (
      <FormInputWrapper
        sx={{ maxWidth: maxWidth }}
        className={`${handleInputClasses} ${
          isValidationIconVisible ? 'icon_enabled' : 'icon_not_enabled'
        }`}
      >
        <FormInputContainer className={isAmountField ? 'left-icon-enabled' : ''}>
          <TextField
            fullWidth
            label={label}
            id={id}
            inputRef={ref}
            type={type}
            focused={value ? true : false}
            aria-label={label}
            placeholder={placeholder}
            inputMode={inputMode}
            inputProps={{
              inputMode: inputMode,
            }}
            value={value}
            key={id}
            {...props}
            error={error?.length > 0}
            disabled={readonly}
            onChange={onChange}
            onBlur={inputFocus && inputFocus}
            onCopy={(e: any) => {
              if (disableCopyPast) {
                e.preventDefault();
                return false;
              }
            }}
            onPaste={(e: any) => {
              if (disableCopyPast) {
                e.preventDefault();
                return false;
              }
            }}
            onCut={(e: any) => {
              if (disableCopyPast) {
                e.preventDefault();
                return false;
              }
            }}
            onKeyPress={onKeyPress}
          />
          {isValidationIconVisible && (
            <div className="validation_icons">
              {isValid && <ValidTickIcon />}
              {error && !isValid && <ErrorWarningIcon />}
            </div>
          )}
          {isAmountField && (
            <AttachMoneyIcon
              sx={{
                color: 'gray.600',
                fontSize: '15px',
                position: 'absolute',
                top: '13px',
                left: '15px',
              }}
            />
          )}
        </FormInputContainer>
        {highLightText && <HighLightText>{highLightText}</HighLightText>}
        {error && !isValid && <FormInputErrorMessage>{error}</FormInputErrorMessage>}
      </FormInputWrapper>
    );
  }
);
