import { Typography } from '@mui/material';
import { DeleteIcon, LinedTickIcon, MoreVerticalIcon } from '../../Media/Icon/Icons/Icons';
import { ShadowCard } from '../../CommonNestedStyles/FormWrapper.style';
import {
  BankInfoCardTop,
  BankInfoCardLeft,
  BankInfoCardRight,
  DefaultTag,
  ViewMoreDropDown,
  ViewMoreDropDownIcon,
  ViewMoreDropDownList,
  ViewMoreDropDownListItem,
} from '../../CommonNestedStyles/BankInfoCard.style';
import { useComponentVisible } from 'src/hooks/useComponentVisible';
import { useTranslation } from 'react-i18next';
import { TransactionSummaryUserIcon } from '../../CommonNestedStyles/CommonNestedStyles.style';
import { getNameWords } from 'src/shared/utilities/dataConvertions';

export const FinanceBankInfoCard = ({
  bankDetails,
  deleteAccount,
  updateDefaultAccount,
  userIconBgColor,
  isSelected,
  onClickBankCard,
  isUpdateEditOptionAvailable = true,
  isCardClickable = true,
  accountNumberPrefixText = 'Account',
  isDefaultTagVisible = true,
  isDefaultUpdateOptionAvailable = true,
}: {
  bankDetails?: any;
  deleteAccount?: any;
  updateDefaultAccount?: any;
  userIconBgColor?: string;
  isSelected?: boolean;
  onClickBankCard?: any;
  isUpdateEditOptionAvailable?: boolean;
  isCardClickable?: boolean;
  accountNumberPrefixText?: string;
  isDefaultTagVisible?: boolean;
  isDefaultUpdateOptionAvailable?: boolean;
}) => {
  const { t } = useTranslation();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const handleDropdownClick = () => {
    onClickBankCard && onClickBankCard(bankDetails);
  };

  return (
    <ShadowCard marginBottom={0} className={'remove_all_shadow'} position={'relative'}>
      <BankInfoCardTop className={isSelected ? 'selected' : 'unselected'}>
        <BankInfoCardLeft
          onClick={() => handleDropdownClick()}
          sx={{
            pointerEvents: isCardClickable ? 'auto' : 'none',
          }}
        >
          <TransactionSummaryUserIcon className="bank-info">
            {getNameWords(
              bankDetails?.metadata?.nickname ??
                bankDetails?.account_holder_name ??
                bankDetails?.bank_name
            )}
          </TransactionSummaryUserIcon>
          <div className="bank_details">
            <Typography
              variant="h2Bold"
              color="gray.900"
              marginBottom={1}
              component="p"
              textTransform={'capitalize'}
            >
              {bankDetails?.metadata?.nickname ??
                bankDetails?.account_holder_name ??
                bankDetails?.bank_name}
            </Typography>
            <Typography variant="h1" color="gray.600">
              {bankDetails?.last4
                ? `${accountNumberPrefixText} • • • • • ${bankDetails?.last4}`
                : ''}
            </Typography>
          </div>
        </BankInfoCardLeft>
      </BankInfoCardTop>
      <BankInfoCardRight>
        {bankDetails?.default_for_currency && isDefaultTagVisible && (
          <DefaultTag>
            <Typography variant="h1" color="darkBlue.300">
              {`Default`}
            </Typography>
          </DefaultTag>
        )}
        {!bankDetails?.default_for_currency && (
          <ViewMoreDropDown ref={ref}>
            {isUpdateEditOptionAvailable && (
              <ViewMoreDropDownIcon onClick={() => setIsComponentVisible(!isComponentVisible)}>
                <MoreVerticalIcon />
              </ViewMoreDropDownIcon>
            )}
            {isComponentVisible && (
              <ViewMoreDropDownList>
                <ViewMoreDropDownListItem className="remove-item">
                  <Typography
                    variant="h2"
                    color="red.500"
                    onClick={() => {
                      deleteAccount && deleteAccount(bankDetails.id);
                      setIsComponentVisible(false);
                    }}
                  >
                    <DeleteIcon />
                    {t('settings.LinkedBankAccount.remove')}
                  </Typography>
                </ViewMoreDropDownListItem>
                {isDefaultUpdateOptionAvailable && (
                  <ViewMoreDropDownListItem>
                    <Typography
                      variant="h2"
                      color="gray.900"
                      onClick={() => {
                        updateDefaultAccount && updateDefaultAccount(bankDetails.id);
                        setIsComponentVisible(false);
                      }}
                    >
                      <LinedTickIcon />
                      {t('settings.LinkedBankAccount.default')}
                    </Typography>
                  </ViewMoreDropDownListItem>
                )}
              </ViewMoreDropDownList>
            )}
          </ViewMoreDropDown>
        )}
      </BankInfoCardRight>
    </ShadowCard>
  );
};
