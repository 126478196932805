import { createApi } from '@reduxjs/toolkit/query/react';
import { prepareBaseQuery } from '../../shared/config/config';

export const accountApi = createApi({
  reducerPath: 'accountApi',
  tagTypes: ['Account', 'Finance'],
  baseQuery: prepareBaseQuery(false),
  endpoints: (builder) => ({
    createAccount: builder.mutation({
      query: (state) => ({
        url: '/account',
        method: 'POST',
        body: JSON.stringify(state),
      }),
    }),
    updateAccount: builder.mutation({
      query: (state) => {
        return {
          url: '/account',
          method: 'POST',
          body: JSON.stringify(state),
        };
      },
      invalidatesTags: ['Account'],
    }),
    getAccount: builder.query({
      query: () => ({
        url: `/account`,
        method: 'GET',
      }),
      transformResponse: (response: any) => {
        return response;
      },
      providesTags: ['Account'],
    }),
    getAccountData: builder.mutation({
      query: () => ({
        url: `/account`,
        method: 'GET',
      }),
      transformResponse: (response: any) => {
        return response;
      },
      invalidatesTags: ['Account'],
    }),
    deleteAccount: builder.mutation({
      query: (state) => ({
        url: '/account',
        method: 'DELETE',
      }),
      invalidatesTags: ['Account'],
    }),
    getDocumentTypes: builder.query({
      query: () => ({
        url: `/account/get-stripe-document-types/all`,
        method: 'GET',
      }),
    }),
    updateFile: builder.mutation({
      query: (data: any) => {
        return {
          url: '/account/upload-stripe-documents',
          method: 'POST',
          params: data,
        };
      },
      invalidatesTags: ['Account'],
    }),
    getBankDetails: builder.mutation({
      query: (state) => ({
        url: `/account/external-account/${state.id}`,
        method: 'GET',
      }),
      invalidatesTags: ['Account'],
    }),
    getAllBankDetails: builder.mutation({
      query: (state) => ({
        url: `/account/bank-accounts`,
        method: 'GET',
      }),
      invalidatesTags: ['Account'],
    }),
    createBankAccount: builder.mutation({
      query: (state) => ({
        url: '/account/external-account',
        method: 'POST',
        body: JSON.stringify(state),
      }),
      invalidatesTags: ['Account'],
    }),
    deleteBankAccount: builder.mutation({
      query: (state) => ({
        url: `/account/bank-accounts/${state.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Account', 'Finance'],
    }),
    updateDefaultBankAccount: builder.mutation({
      query: (state) => ({
        url: `/account/bank-accounts/default/${state.id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Account', 'Finance'],
    }),
    treasuryFinancialCard: builder.mutation({
      query: () => {
        return {
          url: '/account/treasury',
          method: 'POST',
        };
      },
    }),
    getTreasuryTransactions: builder.query({
      query: (filterSchema: any) => ({
        url: `/account/treasury/transactions`,
        method: 'POST',
        body: {
          ...filterSchema,
        },
      }),
      providesTags: ['Finance'],
    }),
    getEphemeralKey: builder.query({
      query: () => ({
        url: `/account/treasury/ephemeral-key`,
        method: 'GET',
      }),
    }),
    getTransactionDetails: builder.query({
      query: () => ({
        url: `/account/treasury/financial-account-details`,
        method: 'GET',
      }),
      providesTags: ['Finance'],
    }),
    getVertualCardDetails: builder.query({
      query: (send_full_card: boolean = true) => ({
        url: `/account/treasury/card/details`,
        method: 'POST',
        body: {
          send_full_card: send_full_card,
        },
      }),
      providesTags: ['Finance'],
    }),
    getTransactionSummary: builder.query({
      query: (id?: string) => ({
        url: `/account/treasury/transaction/${id}`,
        method: 'GET',
      }),
    }),
    treasuryFinancialCardStatusChange: builder.mutation({
      query: (obj: any) => {
        return {
          url: '/account/treasury-card-activate-deactivate',
          method: 'POST',
          body: obj,
        };
      },
      invalidatesTags: ['Finance'],
    }),
    createTreasuryAccount: builder.mutation({
      query: (state) => ({
        url: '/account/treasury/add-linked-account',
        method: 'POST',
        body: JSON.stringify(state),
      }),
      invalidatesTags: ['Account', 'Finance'],
    }),
    getAllTreasuryAccounts: builder.query({
      query: () => ({
        url: `/account/treasury/linked-accounts`,
        method: 'GET',
      }),
      providesTags: ['Finance'],
    }),
    useGetAllTreasuryPayeeAccounts: builder.query({
      query: () => ({
        url: `/account/treasury/payees`,
        method: 'GET',
      }),
      providesTags: ['Finance'],
    }),
    createTreasuryPayeeAccount: builder.mutation({
      query: (state) => ({
        url: '/account/treasury/add-payee-account',
        method: 'POST',
        body: JSON.stringify(state),
      }),
      invalidatesTags: ['Account', 'Finance'],
    }),
    deletePayeeBankAccount: builder.mutation({
      query: (state) => ({
        url: `/account/treasury/payee/${state.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Account', 'Finance'],
    }),
    sendMoneyToLinkedAccount: builder.mutation({
      query: (state) => ({
        url: '/account/treasury/send-money-to-linked-account',
        method: 'POST',
        body: JSON.stringify(state),
      }),
      invalidatesTags: ['Account', 'Finance'],
    }),
    sendMoneyToPayeeAccount: builder.mutation({
      query: (state) => ({
        url: '/account/treasury/send-money-to-payee',
        method: 'POST',
        body: JSON.stringify(state),
      }),
      invalidatesTags: ['Account', 'Finance'],
    }),
    getTreasuryAccountTransactions: builder.query({
      query: () => ({
        url: `/account/treasury/card-transactions`,
        method: 'GET',
      }),
      providesTags: ['Finance'],
    }),
    updateBankAccountFields: builder.mutation({
      query: (state) => ({
        url: `/account/bank-accounts/${state.field}/${state.id}`,
        method: 'POST',
        body: state.body,
      }),
      invalidatesTags: ['Account', 'Finance'],
    }),
  }),
});

export const {
  useCreateAccountMutation,
  useUpdateAccountMutation,
  useGetAccountQuery,
  useGetAccountDataMutation,
  useDeleteAccountMutation,
  useGetDocumentTypesQuery,
  useUpdateFileMutation,
  useGetBankDetailsMutation,
  useGetAllBankDetailsMutation,
  useCreateBankAccountMutation,
  useDeleteBankAccountMutation,
  useUpdateDefaultBankAccountMutation,
  useTreasuryFinancialCardMutation,
  useGetTreasuryTransactionsQuery,
  useGetEphemeralKeyQuery,
  useGetTransactionDetailsQuery,
  useGetVertualCardDetailsQuery,
  useGetTransactionSummaryQuery,
  useTreasuryFinancialCardStatusChangeMutation,
  useCreateTreasuryAccountMutation,
  useGetAllTreasuryAccountsQuery,
  useUseGetAllTreasuryPayeeAccountsQuery,
  useCreateTreasuryPayeeAccountMutation,
  useDeletePayeeBankAccountMutation,
  useSendMoneyToLinkedAccountMutation,
  useSendMoneyToPayeeAccountMutation,
  useGetTreasuryAccountTransactionsQuery,
  useUpdateBankAccountFieldsMutation,
} = accountApi;
