import {
  CreateBank,
  GenerateAccountLink,
  GetAccount,
  GetAccountLink,
  GetBankDetails,
  RedirectToAccount,
  RegisterAccount,
  RegisterCompanyAccount,
  UpdateAccount,
} from '../../services/account/account.service';
import { getAditionalPhoneDetails } from '../../services/account/additional.phone.service';
import {
  accountCompany,
  accountIndividual,
  accountIndividualInitMapper,
} from '../../models/account/account.service.mapper';
import { ActionType } from '../action-types';

import { Address } from '../../models/account/account.register.model';
import { AccountParams } from '../../shared/utilities/interface';

export const createIndividualAccountAction = (params: AccountParams, min: boolean = false) => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    params = { ...params, dob: '' };
    //account init data mapping
    const obj = accountIndividualInitMapper(params);
    try {
      RegisterAccount(obj).then((response) => {
        if (response?.data?.id) {
          dispatch({
            type: ActionType.CREATE_INDIVIDUAL_ACCOUNT,
            ...state,
            payload: {
              ...state,
              response: {
                errorMessage: response,
                data: response.data,
              },
            },
          });
        } else {
          dispatch({
            type: ActionType.SERVER_ERROR_500,
            ...state,
            payload: response?.response?.data?.message,
          });
        }
      });
    } catch (error: any) {
      console.error(error?.response?.data); // NOTE - use "error.response.data` (not "error")
    }
  };
};

export const createCompanyAccountAction = (params: AccountParams) => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const obj = {
      business_type: params.business_type,
      company: {
        name: params.inputCompanyName,
        tax_id: params.inputTaxId,
        address: new Address(
          params.inputComLine1,
          params.inputComLine2,
          params.inputComCity,
          params.inputComState,
          params.inputComPostal
        ),
        phone: params.inputCompanyPhone,
      },
      coupa_supplier_id: params.coupa_supplier_id,
    };
    try {
      RegisterCompanyAccount(obj).then((response) => {
        if (response?.data?.id) {
          dispatch({
            type: ActionType.CREATE_COMPANY_ACCOUNT,
            ...state,
            payload: {
              ...state,
              response: {
                errorMessage: response,
                data: response.data,
              },
            },
          });
        } else {
          dispatch({
            type: ActionType.SERVER_ERROR_500,
            ...state,
            payload: response?.response?.data?.message,
          });
        }
      });
    } catch (error: any) {
      console.error(error?.response?.data); // NOTE - use "error.response.data` (not "error")
    }
  };
};

export const navigateToExistingAccountAction = async (accountId: string) => {
  const res = await GenerateAccountLink(accountId);
  RedirectToAccount(res.data.url.toString());
};

// export const GetLogginDetailsAction = () => {
//   return (dispatch: any, getState : any) => {
//     const state = getState();
//     GetLogginDetails().then((response) => {
//       dispatch({
//         type: ActionType.GET_LOGIN,
//         ...state,
//         payload: response.data,
//       });
//     });
//   };
// };

export const getAccountAction = (hideLoader?: boolean, isNonLoggedIn?: boolean) => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    GetAccount(hideLoader, isNonLoggedIn).then((response) => {
      dispatch({
        type: ActionType.GET_ACCOUNT,
        ...state,
        payload: {
          ...state,
          response: {
            errorMessage: response.response?.data,
            data: response.data,
          },
        },
      });
    });
  };
};

export const clearErrorAction = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    dispatch({
      type: ActionType.SERVER_ERROR_500,
      ...state,
      payload: null,
    });
  };
};

export const getAdditionalPhoneAction = (id?: string) => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    getAditionalPhoneDetails(id).then((response: any) => {
      dispatch({
        type: ActionType.GET_ADDITIONAL_PHONE,
        ...state,
        payload: {
          ...state,
          response: {
            errorMessage: response.response?.data,
            data: response.data,
          },
        },
      });
    });
  };
};

export const getAccountLinkAction = (linkType: string) => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    GetAccountLink(linkType).then((response) => {
      dispatch({
        type: ActionType.GET_ACCOUNT_LINK,
        ...state,
        payload: response.data?.url,
      });
    });
  };
};

export const updateAccountAction = (
  params: AccountParams,
  isCompany: boolean,
  update: boolean = true,
  fullSSN: boolean = true
) => {
  const dataModel = isCompany
    ? accountCompany(params, update, fullSSN)
    : accountIndividual(params, update, fullSSN);
  return (dispatch: any, getState: any) => {
    const state = getState();

    UpdateAccount(dataModel).then((response) => {
      if (response?.data?.id) {
        dispatch({
          type: ActionType.CREATE_INDIVIDUAL_ACCOUNT,
          ...state,
          payload: {
            ...state,
            response: {
              errorMessage: response.response?.data,
              data: response.data,
            },
          },
        });
      } else {
        response?.response?.data?.message &&
          dispatch({
            type: ActionType.SERVER_ERROR_500,
            ...state,
            payload: response?.response?.data?.message,
          });
      }
    });
  };
};

export const createBankAction = (params: any, id: string) => {
  return (dispatch: any, getState: any) => {
    const state = getState();

    CreateBank(params, id).then((response) => {
      if (response?.data?.id) {
        dispatch({
          type: ActionType.BANK_DETAILS,
          ...state,
          payload: {
            ...state,
            response: {
              errorMessage: response.response?.data,
              data: response.data,
            },
          },
        });
      } else {
        response?.response?.data?.message &&
          dispatch({
            type: ActionType.SERVER_ERROR_500,
            ...state,
            payload: response?.response?.data?.message,
          });
      }
    });
  };
};

export const getBankAction = (id: string) => {
  return (dispatch: any, getState: any) => {
    const state = getState();

    GetBankDetails(id).then((response) => {
      if (response?.data?.id) {
        dispatch({
          type: ActionType.GET_BANK_DETAILS,
          ...state,
          payload: {
            ...state,
            response: {
              errorMessage: response.response?.data,
              data: response.data,
            },
          },
        });
      } else {
        response?.response?.data?.message &&
          dispatch({
            type: ActionType.SERVER_ERROR_500,
            ...state,
            payload: response?.response?.data?.message,
          });
      }
    });
  };
};
