import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '../../../..';
import { handleTransactionAmount } from 'src/helpers/helperHandlingTableGrid';

export const ThirdCell = (props: any) => {
  const navigate = useNavigate();

  const { isHandleArrowClickable, routeState } = props?.props;
  const getPropsOriginalData = props?.props?.row?.original;

  const handleCursorClass = isHandleArrowClickable ? 'is_cursor' : 'no_cursor';
  const transferId = getPropsOriginalData.id;
  const [amount, plusValue] = handleTransactionAmount(getPropsOriginalData.amount);

  return (
    <>
      <span
        onClick={() =>
          navigate(`/finance/account/transaction/detail/${transferId}`, { state: routeState })
        }
        className={`${handleCursorClass} amount_cell sub-cell-second fs-mask ${
          plusValue ? 'plusValue' : 'minusValue'
        }`}
      >
        {amount}
      </span>
      {props?.props?.iconArrowVisible && (
        <div
          onClick={() =>
            navigate(`/finance/account/transaction/detail/${transferId}`, { state: routeState })
          }
          className="sub-cell-third"
        >
          <Icon iconType="RightAlignedArrow" />
        </div>
      )}
    </>
  );
};
