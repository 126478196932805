import React from 'react';
import { Typography } from '@mui/material';
import {
  ShowMoreWrapper,
  ArrowIconContainer,
  PaginationWrapperContainer,
  PageNumberContainer,
  DropDownContainer,
} from './Pagination.style';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { RowCountDropDown } from '../RowCountDropDown/RowCountDropDown';
import { PaginationProps } from 'src/models/component/base.model';

export const Pagination = (props: PaginationProps) => {
  const setOffset = () => {
    const nextOffset = props?.offset === undefined ? 0 : props.offset + 1;
    props.handleShowMore && props.handleShowMore(nextOffset);
  };

  const setMinusOffset = () => {
    const previousOffset = props?.offset === undefined ? 0 : props.offset - 1;
    props.handleShowMore && props.handleShowMore(previousOffset);
  };

  const enableNextPage =
    props?.pageCount !== undefined &&
    props?.offset !== undefined &&
    props?.pageCount > props?.offset &&
    props?.pageCount !== 1
      ? true
      : false;
  const enablePrevPage = props?.offset !== undefined && props?.offset > 0 ? true : false;
  const defaultVisibility = !enablePrevPage && !enableNextPage;

  return (
    <>
      <ShowMoreWrapper className={props?.tabType}>
        {props?.showOffsetChangeButtons ? (
          <PaginationWrapperContainer>
            <ArrowIconContainer
              onClick={() => {
                if (enablePrevPage) {
                  setMinusOffset();
                }
              }}
            >
              <ArrowBackIosOutlinedIcon
                sx={{ width: 14, height: 14, color: enablePrevPage ? 'gray.600' : 'gray.300' }}
              />
            </ArrowIconContainer>
            <PageNumberContainer>
              <Typography variant="h2ExtraBold">
                {`${props?.offset ? props.offset + 1 : '1'}`}
              </Typography>
            </PageNumberContainer>
            <ArrowIconContainer
              onClick={() => {
                if (defaultVisibility || enableNextPage) {
                  setOffset();
                }
              }}
            >
              <ArrowForwardIosOutlinedIcon
                sx={{
                  width: 14,
                  height: 14,
                  color: defaultVisibility || enableNextPage ? 'gray.600' : 'gray.300',
                }}
              />
            </ArrowIconContainer>
          </PaginationWrapperContainer>
        ) : (
          <PaginationWrapperContainer />
        )}
        <DropDownContainer>
          <RowCountDropDown
            selectedRowCount={props?.selectedRowCount}
            onRowCountChange={props?.onRowCountChange}
          />
        </DropDownContainer>
      </ShowMoreWrapper>
    </>
  );
};
