import { styled } from '@mui/material/styles';

export const FormInputWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormInputContainer = styled('div')(
  ({ theme }) => `
  position: relative;

  input[type='file'] {
    width: 0;
    height: 0;
    overflow: hidden;
    margin: 0;
    position: absolute;
    left: -2px;
  }

  label {
    display: flex;
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.gray[100]};
    border-radius: 8px;
    height: 52px;
    cursor: pointer;
    margin-bottom: ${theme.spacing(3)};
    padding-left:${theme.spacing(4)};
    padding-right:${theme.spacing(4)};
    justify-content: space-between;
    align-items: center;

    .label_left {
      display: flex;
      align-items: center;
    }

    .doc_indication {
      height: 28px;
      display: flex;
      align-items: center;
      background: ${theme.palette.blue[50]};
      padding-left: ${theme.spacing(2)};
      padding-Right: ${theme.spacing(2)};
      border-radius: 4px;

      svg {
        color: ${theme.palette.blue[500]};
        margin-right: 4px;
      }

      span {
        max-width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        letter-spacing: 0.3px;
      }
    }

    .left_icon_block {
      margin-right: ${theme.spacing(2)};
  
      svg {
        color: ${theme.palette.gray[600]};
      }
     }

    .arrow_icon_wrapper {
      transform: rotate(-90deg);
      svg {
        color: ${theme.palette.gray[300]};
      }
    }

    &:hover {
      border: 1px solid ${theme.palette.blue[500]};
      background: ${theme.palette.blue[50]};
      
      .arrow_icon_wrapper {
        svg {
          color: ${theme.palette.blue[500]};
        }
      }
    }
  }
`
);

export const ErrorText = styled('p')`
  margin: 8px 0 0;
  color: var(--errorRed);
  font-size: 14px;
  font-weight: medium;
`;
