import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  ContentContainerBottom,
  DivWrapper,
  Header,
  HeadingSummaryBlock,
  Wrapper,
} from 'src/components/base/core';
import { VerifyConfirmationIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import { commonSpacing, theme } from 'src/shared/theme/theme';
import { logout } from 'src/shared/utilities/logout';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

export interface IVerifyEmailContainer {
  isButtonDisabled?: boolean;
  isLogin?: boolean;
  isError?: boolean;
}

export const VerifiedEmailContainer = ({
  isButtonDisabled = false,
  isLogin,
}: IVerifyEmailContainer) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isOnNativeApp = useSelector((state: RootState) => state.appState);

  const continueVerifiedSignup = () => {
    localStorage.removeItem('from_resend');
    localStorage.removeItem('from_login');
    navigate('/', {
      state: { verifiedEmail: true },
    });
  };

  return (
    <>
      <Wrapper isFullScreen>
        <DivWrapper className="no_fullscreen">
          <Box
            component={'div'}
            className={'container_content'}
            sx={{
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                minHeight: 400 + commonSpacing.desktopPaddingB * 4,
              },
            }}
          >
            <Header
              isBackButtonVisible={!isLogin && !isOnNativeApp}
              onBackButtonClick={() => logout(navigate)}
              paddingX={0}
            />
            <Box
              sx={{
                [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                  marginBottom: isLogin ? 10 : 30,
                },
                [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                  marginBottom: isLogin ? 20 : 40,
                },
              }}
            >
              <HeadingSummaryBlock
                heading={
                  isLogin
                    ? t('headingContent.verifiedEmail.headingLoginCompletion')
                    : t('headingContent.verifiedEmail.headingEmailVerified')
                }
                headingAlignment="center"
                mobileHeadingAlignment="center"
              />
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                marginBottom: 3,
              }}
            >
              <Box
                sx={{
                  width: '60px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block',
                  marginBottom: 11,
                }}
              >
                <VerifyConfirmationIcon />
              </Box>
              {!isLogin && (
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  {t('headingContent.verifiedEmail.content')}
                </Typography>
              )}
            </Box>
          </Box>

          <ContentContainerBottom className={'no_fullscreen'}>
            {!isLogin && (
              <Button
                variant="containedLarge"
                color="primary"
                type="submit"
                onClick={continueVerifiedSignup}
                fullWidth
                disabled={isButtonDisabled}
                sx={{ overflow: 'hidden' }}
              >
                {t('buttonTexts.continueButton')}
              </Button>
            )}
          </ContentContainerBottom>
        </DivWrapper>
      </Wrapper>
    </>
  );
};
