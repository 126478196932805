/*eslint-disable */
export enum ActionType {
  CREATE_INDIVIDUAL_ACCOUNT = 'account',
  CREATE_COMPANY_ACCOUNT = 'company',
  GET_ACCOUNT = 'getAccount',
  UNAUTHORIZED = 'unauthorized',
  UPDATE_INDIVIDUAL_ACCOUNT = 'update_account',
  UPDATE_COMPANY_ACCOUNT = 'update_company',
  SERVER_ERROR_500 = 'error500',
  AUTH_REQUEST_VERIFICATION = 'verify_phone',
  AJAX_LOADING = 'ajax_loading',
  GET_ACCOUNT_LINK = 'get_account_link',
  GET_TERMS = 'getAccountTerms',
  GET_LOGIN = 'getLogginDetails',
  API_TIMEOUT = 'api_timeout',
  GET_EARNINGS_SUBMIT = 'getEarningsSubmitDetails',
  GET_INVOICE_DETAILS = 'GetInvoiceDetails',
  GET_PAYOUT_TERMS = 'GetPayoutTerms',
  SET_PAYOUT_TERMS = 'SetPayoutTerms',
  LOGOUT = 'LogoutDetails',
  GET_ADDITIONAL_PHONE = 'GetAdditionalPhoneNumber',
}

export enum linkType {
  ONBORSDING = 'account_onboarding',
  DASHBOARD = 'account_dashboard',
}
interface TokenResult {
  data: {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    token_type: string;
  };
}
export interface TokenParams {
  response?: TokenResult;
}
export interface AccountParams {
  first_name?: string;
  last_name?: string;
  email?: string;
  phone_number?: string;
  line1?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  line2?: string;
  idNumber?: string;
  dob?: string;
  business_type: string;
  inputDescription?: string;
  inputMCC?: string;
  isRep?: boolean;
  ownership?: string;
  inputOwnerTitle?: string;
  inputComLine1?: string;
  inputComLine2?: string;
  inputComCity?: string;
  inputComState?: string;
  inputComPostal?: string;
  inputCompanyName?: string;
  inputTaxId?: string;
  inputCompanyPhone?: string;
  employerId?: string;
  id?: string;
  response?: Result;
  inputBusinessName?: string;
  message?: string;
  tos_shown_and_accepted?: boolean;
  default_payment_term_id?: string;
  url?: string;
  inputJobTitle?: string;
  checkboxSameAddress?: string;
  checkboxSharePercentage?: string;
  coupa_supplier_id?: string;
  accept_other_terms?: boolean;
  account_holder_name?: string;
  account_number?: string;
  routing_number?: string;
  front?: string;
  back?: string;
  companyFront?: string;
  companyBack?: string;
  documentType?: string;
  ssn_last_4?: string;
  percentOwnership?: any;
  percent_ownership?: any;
  phone_number_2?: string;
  is_email_notification_active?: boolean;
  is_sms_notification_active?: boolean;
  primary_contact_name?: string;
  display_name?: string;
  structure?: any;
  company_structure?: any;
  full_name?: string;
  company_structure_selection?: 'yes' | 'no' | '';
}

export interface ErrorMsg {
  message?: string;
  status?: number;
}

export interface Document {
  front?: string;
  back?: string;
}

export interface Verification {
  document?: Document;
}

export interface Result {
  data: ResultObject;
  errorMessage: ErrorMsg;
}

export interface BusinessProfile {
  mcc: string;
  name: string;
  product_description: string;
}

export interface ErrorReq {
  code: string;
  reason: string;
  requirement: string;
}

export interface Address {
  city: string;
  line1: string;
  line2: string;
  postal_code: string;
  state: string;
}

export interface DOB {
  day?: string;
  month?: string;
  year?: string;
}

export interface Metadata {
  documentType?: string;
}

export interface Individual {
  dob: DOB;
  address: Address;
  first_name: string;
  last_name: string;
  ssn_last4_provided: boolean;
  email: string;
  phone: string;
  id_number_provided: boolean;
  tos_shown_and_accepted: boolean;
  relationShip: RelationShip;
  connect_ui_acceptance_required: boolean;
  Verification: Verification;
  metadata: Metadata;
}

export interface RelationShip {
  director: boolean;
  executive: boolean;
  owner: boolean;
  percent_ownership: string;
  representative: boolean;
  title: string;
}

export interface Company {
  name: string;
  directors_provided: boolean;
  executives_provided: boolean;
  owners_provided: boolean;
  phone: string;
  tax_id_provided: boolean;
  address: Address;
  Verification: Verification;
  metadata: Metadata;
  structure: any;
}

export interface ResultObject {
  business_profile: BusinessProfile;
  errors: [ErrorReq];
  currently_due_requirements?: string[];
  individual: Individual;
  business_type: string;
  id: string;
  company: Company;
  charges_enabled: boolean;
  details_submitted: boolean;
  card_id: string;
  default_payment_term_id: string;
  connect_ui_acceptance_required: boolean;
  tos_shown_and_accepted: boolean;
  company_structure: string;
  stripe_account_metadata: {
    check_recipient_details_address: string;
    check_recipient_details_address_2: string;
    check_recipient_details_city: string;
    check_recipient_details_name: string;
    check_recipient_details_state: string;
    check_recipient_details_zip: string;
  };
}

export interface Response {
  id: string | undefined;
  first_name: string | undefined;
  last_name: string | undefined;
  email: string;
  phone: string;
  created_at: string;
  updated_at: string;
  stripe_id: string;
  default_payment_term_id: string;
  result: ResultObject;
  business_type: string;
}

export interface BankAccount {
  account_number: string;
  account_holder_name: string;
  routing_number: string;
}

export interface AuthResponse {
  phone_number?: string;
  phone_verified?: boolean;
  request_language?: string;
  _id?: string;
  id_token?: string;
}

export interface AuthParams {
  phone_number?: string;
  response?: { data: AuthResponse; errorResponse: string };
}

export interface TermParams {
  id: string;
  name: string;
  due_days: number;
  active: boolean;
  created_at: string;
  updated_at: string;
  description: string;
  payout_percentage: number;
  payout_amount: number;
}

export interface BankDetailsParams {
  account_holder_name?: string;
  routing_number?: string;
  account_number?: string;
  account_number_conf?: string;
  id?: string;
  account?: string;
  nickname?: string;
}

export interface CoupaParams {
  email: string;
  'name-family': string;
  'name-given': string;
  'phone-work': { 'area-code': string; number: string };
}

export interface AddressParams {
  street1: string;
  street2: string;
  city: string;
  'postal-code': string;
  state: string;
}

export type batchStatssues = 'approved' | 'pending' | 'paid';
export type invoiceBatchTabsType = 'submitted' | 'paid' | 'approved' | 'transactions';

export interface payoutsProps {
  hideLoader: boolean;
  limit: number;
  pageOffset: number;
  first: number;
  filter: FilterProps;
  sort?:
    | 'UPDATED_AT_DESC'
    | 'UPDATED_AT_ASC'
    | 'PAYOUT_TERM_ID_ASC'
    | 'PAYOUT_TERM_ID_DESC'
    | 'AMOUNT_ASC'
    | 'AMOUNT_DESC';
}

export interface TransfersProps {
  hideLoader?: boolean;
  details?: boolean;
  pageOffset: number;
  first: number;
  filter?: any;
  sort?:
    | 'UPDATED_AT_DESC'
    | 'UPDATED_AT_ASC'
    | 'CREATED_AT_DESC'
    | 'CREATED_AT_ASC'
    | 'PAID_AT_ASC'
    | 'PAID_AT_DESC'
    | 'APPROVED_AT_ASC'
    | 'APPROVED_AT_DESC';
  batchStatus?: batchStatssues;
}

export interface TransFilterProps {
  paid_at?: { isNull: boolean };
  id?: { equalTo: string };
}
export interface FilterProps {
  coupa_invoice_number?: { likeInsensitive: string };
  status: { equalTo: string };
}
export interface CodeInputParams {
  target: { maxLength: any; value: any; name: any; id: any };
}

//grid props
export interface GridProps {
  accordion: GridAccordionProps;
}

//grid props
export interface GridAccordionProps {
  activeAccordion: any;
  activeAccordionOld: any;
  enableAccordion: boolean;
  isOldInvoice: boolean;
  showMoreInprogressOld: boolean;
  selectedCount?: any;
  clickToggle?: boolean;
}

export interface GridFilterStatus {
  isAmountFilterActive: boolean;
  isDateFilterActive: boolean;
  isTermFilterActive: boolean;
  isSortBFilterActive: boolean;
  selectedDateFilterValue?: { isRange: boolean; value: any };
}
export type AccountResponse = Response;

//route params for filtering in earning
export interface RouteParams {
  amount: string;
  date: string;
  payoutTerms: string;
  sortBy: string;
}
export interface DownloadCSVState {
  isDownloadEnable: boolean;
  selectedDateRange: Array<string>;
  selectedOption: string;
}
export interface EmailToExportCSV {
  email: string;
}
export interface DownloadFilterProps {
  status: any;
  invoiced_at?: {
    lessThan: string;
    greaterThan: string;
  };
}
export interface UserDetailsInterface {
  first_name: string;
  last_name: string;
  email: string;
  isLoggingIn: boolean;
}

export type FormAddressProps = {
  line1: string;
  line2: string;
  city: string;
  state: string;
  postal_code: string;
};

export type BankandTermsLocationState = {
  edit?: boolean;
  fromSettings?: boolean;
  fromHome?: boolean;
  isSettingDesktop?: boolean;
  isFromFinance?: boolean;
  backFrom?: string;
  isAfterKycFromTermScreen?: boolean;
  isFromPayMode?: boolean;
};

export interface BankSuccessLocationState extends BankandTermsLocationState {
  bankConnect?: boolean;
  last4?: string;
  isFromFinConnection?: boolean;
}

export interface RecipientDetailsFormParams extends Address {
  name: string;
}

export interface IeditPayMode {
  method: string;
  estimated_payout_date: string;
  estimated_approved_date: string;
  payoutId: string;
  estimated_arival_date: string;
}

export interface PaymentDataTableProps {
  dataObject?: any;
  isOldInvoices?: boolean;
  isHandleArrowClickable?: boolean;
  tabType?: invoiceBatchTabsType;
  isTermEditable?: boolean;
  onClickTermId?: any;
  onTermSelection?: any;
  isTermEditEnabled?: boolean;
  isToggle?: boolean;
  isTermClicked?: boolean;
  selectedPayoutId?: string;
  isAllSelected?: boolean;
  searchInProgress?: boolean;
  hideHeader?: boolean;
  iconArrowVisible?: boolean;
  isTransactionGrid?: boolean;
  searchText?: string;
  onBachPayModeClick?: any;
}
