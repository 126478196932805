import { createTheme } from '@mui/material/styles';
import { COLORS, ColorsInterface } from './colorConfig';

export const Gradients = {
  IndigoTeal: `linear-gradient(45deg, #374FC7 0%, #23BA99 100%)`,
  PurpleRed: `linear-gradient(45deg, #6B4BCC 0%, #F2323F 100%)`,
  RedOrange: `linear-gradient(45deg, #F2323F 0%, #F76F34 100%)`,
  IndigoPurple: `linear-gradient(45deg, #374FC7 0%, #6B4BCC 100%)`,
  PurpleGreen: `linear-gradient(45deg, #6B4BCC 0%, #3DBA4E 100%)`,
  DarkGray: `linear-gradient(45deg, #141518 0.11%, #404145 100%)`,
  MediumGray: `linear-gradient(45deg, #404145 0%, #7E8085 100%)`,
  Gray: `linear-gradient(45deg, #7E8085 0%, #BABDC2 100%)`,
  SoftGray: `linear-gradient(45deg, #9FA1A6 0%, #D5D7DB 100%)`,
  LightGray: `linear-gradient(45deg, #D5D7DB 0%, #F8F9FA 100%)`,
};

export const commonSpacing = {
  desktopPaddingB: 15,
  desktopPaddingTopNoHeader: 15,
};

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 990,
      lg: 1024,
      xl: 1512,
      android: 360,
      iPhone: 392,
    },
  },
  palette: COLORS,
  spacing: 4,
  typography: {
    fontFamily: ['Public Sans', 'sans-serif'].join(','),
    htmlFontSize: 14,
    h1: {
      fontSize: 12,
      lineHeight: 1.1,
      fontWeight: 400,
      fontFamily: 'Public Sans, sans-serif',
    },
    h1Bold: {
      fontSize: 12,
      lineHeight: 1.1,
      fontWeight: 500,
      fontFamily: 'Public Sans, sans-serif',
    },
    h1ExtraBold: {
      fontSize: 12,
      lineHeight: 1.1,
      fontWeight: 800,
      fontFamily: 'Public Sans, sans-serif',
    },
    h2: {
      fontSize: 14,
      lineHeight: 1.2,
      fontWeight: 400,
      fontFamily: 'Public Sans, sans-serif',
    },
    h2Bold: {
      fontSize: 14,
      lineHeight: 1.2,
      fontWeight: 500,
      fontFamily: 'Public Sans, sans-serif',
    },
    h2ExtraBold: {
      fontSize: 14,
      lineHeight: 1.2,
      fontWeight: 600,
      fontFamily: 'Public Sans, sans-serif',
    },
    h2Bold700: {
      fontSize: 14,
      lineHeight: 1.2,
      fontWeight: 700,
      fontFamily: 'Public Sans, sans-serif',
    },
    h3: {
      fontSize: 17,
      lineHeight: 1.1,
      fontWeight: 400,
      fontFamily: 'Public Sans, sans-serif',
    },
    h3Bold: {
      fontSize: 17,
      lineHeight: 1.1,
      fontWeight: 600,
      fontFamily: 'Public Sans, sans-serif',
    },
    h3ExtraBold: {
      fontSize: 17,
      lineHeight: 1.1,
      fontWeight: 700,
      fontFamily: 'Public Sans, sans-serif',
    },
    h4: {
      fontSize: 21,
      lineHeight: 1.1,
      fontWeight: 400,
      fontFamily: 'Public Sans, sans-serif',
    },
    h4Bold: {
      fontSize: 21,
      lineHeight: 1.1,
      fontWeight: 500,
      fontFamily: 'Public Sans, sans-serif',
    },
    h4ExtraBold: {
      fontSize: 21,
      lineHeight: 1.1,
      fontWeight: 600,
      fontFamily: 'Public Sans, sans-serif',
    },
    h5: {
      fontSize: 24,
      lineHeight: 1.1,
      fontWeight: 400,
      fontFamily: 'Public Sans, sans-serif',
    },
    h5Bold: {
      fontSize: 24,
      lineHeight: 1.1,
      fontWeight: 500,
      fontFamily: 'Public Sans, sans-serif',
    },
    h5ExtraBold: {
      fontSize: 24,
      lineHeight: 1.1,
      fontWeight: 600,
      fontFamily: 'Public Sans, sans-serif',
    },
    h6: {
      fontSize: 28,
      lineHeight: 1.1,
      fontWeight: 400,
      fontFamily: 'Public Sans, sans-serif',
    },
    h6Bold: {
      fontSize: 28,
      lineHeight: 1.1,
      fontWeight: 500,
      fontFamily: 'Public Sans, sans-serif',
    },
    h6ExtraBold: {
      fontSize: 28,
      lineHeight: 1.1,
      fontWeight: 700,
      fontFamily: 'Public Sans, sans-serif',
    },
    h7: {
      fontSize: 31,
      lineHeight: 1.1,
      fontWeight: 400,
      fontFamily: 'Public Sans, sans-serif',
    },
    h7Bold: {
      fontSize: 31,
      lineHeight: 1.1,
      fontWeight: 500,
      fontFamily: 'Public Sans, sans-serif',
    },
    h7ExtraBold: {
      fontSize: 31,
      lineHeight: 1.1,
      fontWeight: 600,
      fontFamily: 'Public Sans, sans-serif',
    },
    h8: {
      fontSize: 35,
      lineHeight: 1.1,
      fontWeight: 400,
      fontFamily: 'Public Sans, sans-serif',
    },
    h8Bold: {
      fontSize: 35,
      lineHeight: 1.1,
      fontWeight: 500,
      fontFamily: 'Public Sans, sans-serif',
    },
    h8ExtraBold: {
      fontSize: 35,
      lineHeight: 1.1,
      fontWeight: 600,
      fontFamily: 'Public Sans, sans-serif',
    },
    h9: {
      fontSize: 42,
      lineHeight: 1.1,
      fontWeight: 400,
      fontFamily: 'Public Sans, sans-serif',
    },
    h9Bold: {
      fontSize: 42,
      lineHeight: 1.1,
      fontWeight: 500,
      fontFamily: 'Public Sans, sans-serif',
    },
    h9ExtraBold: {
      fontSize: 42,
      lineHeight: 1.1,
      fontWeight: 600,
      fontFamily: 'Public Sans, sans-serif',
    },
    invAmount: {
      fontSize: 36,
      lineHeight: 0.77,
      fontWeight: 700,
      fontFamily: 'Public Sans, sans-serif',
    },
    body1: {
      fontSize: 14,
      lineHeight: 1.1,
      fontWeight: 400,
      fontFamily: 'Public Sans, sans-serif',
    },
    body2: {
      fontSize: 17,
      lineHeight: 1.5,
      fontWeight: 400,
      fontFamily: 'Public Sans, sans-serif',
      color: COLORS.gray[700],
    },
    body2Small: {
      fontSize: 16,
      lineHeight: 1.4,
      fontWeight: 400,
      letterSpacing: -0.24,
      fontFamily: 'Public Sans, sans-serif',
      color: COLORS.gray[700],
    },
    body3: {
      fontSize: 21,
      lineHeight: 1.1,
      fontWeight: 500,
      fontFamily: 'Public Sans, sans-serif',
    },
    caption: {
      fontSize: 16,
      lineHeight: 1.1,
      fontWeight: 600,
      fontFamily: 'Public Sans, sans-serif',
    },
    caption1: {
      fontSize: 13,
      lineHeight: 1.1,
      fontWeight: 600,
      fontFamily: 'Public Sans, sans-serif',
    },
    caption2: {
      fontSize: 12,
      lineHeight: 1.1,
      fontWeight: 600,
      color: '#141518',
      fontFamily: 'Public Sans, sans-serif',
    },
    caption3: {
      fontSize: 12,
      lineHeight: 1.1,
      fontWeight: 700,
      color: '#141518',
      fontFamily: 'Public Sans, sans-serif',
    },
    button: {
      height: 44,
      textTransform: 'none',
      fontSize: 14,
      lineHeight: 1.1,
      fontWeight: 600,
      fontFamily: 'Public Sans, sans-serif',
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: `${COLORS.gray[300]}`,
          paddingRight: 8,
          paddingLeft: 8,
          background: `transparent !important`,
          svg: {
            width: '18px',
            height: '18px',
            borderRadius: '4px',
          },
        },
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            borderRadius: 4,
            boxShadow: 'none',
            fontWeight: 400,
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          minWidth: 120,
          paddingLeft: 16,
          paddingRight: 16,
          fontFamily: 'Public Sans, sans-serif',
          textTransform: 'none',
          borderRadius: 8,
          fontWeight: 600,
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            height: 44,
            color: COLORS.blue[500],
            background: COLORS.white,
            border: `1px solid ${COLORS.blue[500]}`,

            '&:hover': {
              //you want this to be the same as the backgroundColor above
              background: COLORS.white,
              border: `1px solid ${COLORS.blue[500]}`,
              color: COLORS.blue[500],
            },
            '&:disabled': {
              //you want this to be the same as the backgroundColor above
              opacity: '0.5',
              color: COLORS.blue[500],
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            height: 26,
            fontSize: 14,
            color: COLORS.blue[500],
            background: 'transparent !important',
            border: 'none',
            fontWeight: 400,
            minWidth: 12,
            padding: 0,

            '&:hover': {
              //you want this to be the same as the backgroundColor above
              color: COLORS.blue[500],
              background: 'transparent',
              border: 'none',
            },
            '&:disabled': {
              //you want this to be the same as the backgroundColor above
              opacity: '0.5',
              color: COLORS.blue[500],
              background: 'transparent',
              border: 'none',
            },
          },
        },
        {
          props: { variant: 'menuButton' },
          style: {
            height: 'auto',
            fontSize: 16,
            color: '#1a1f36',
            background: 'transparent !important',
            border: 'none',
            fontWeight: 500,
            marginTop: 10,
            marginBottom: 12,
            padding: 0,
            paddingRight: 10,
            justifyContent: 'right',

            '&:hover': {
              //you want this to be the same as the backgroundColor above
              color: '#1a1f36',
              background: 'transparent',
              border: 'none',
            },
            '&:disabled': {
              //you want this to be the same as the backgroundColor above
              opacity: '0.5',
              color: '#1a1f36',
              background: 'transparent',
              border: 'none',
            },
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            height: 44,
            background: COLORS.blue[500],
            color: COLORS.white,
            '&:hover': {
              //you want this to be the same as the backgroundColor above
              background: COLORS.blue[500],
              color: COLORS.white,
            },
            '&:disabled': {
              //you want this to be the same as the backgroundColor above
              opacity: '0.38',
              background: COLORS.blue[500],
              color: COLORS.white,
            },
          },
        },
        {
          props: { size: 'extraLarge' },
          style: { fontSize: 17, height: 52, lineHeight: 26 },
        },
        {
          props: { variant: 'containedLarge' },
          style: {
            fontSize: 17,
            height: 52,
            lineHeight: 26,
            background: COLORS.blue[500],
            overflow: 'hidden',
            color: COLORS.white,
            '&:hover': {
              //you want this to be the same as the backgroundColor above
              background: COLORS.blue[500],
              color: COLORS.white,
            },
            '&:disabled': {
              //you want this to be the same as the backgroundColor above
              opacity: '0.38',
              background: COLORS.blue[500],
              color: COLORS.white,
            },
          },
        },
        {
          props: { variant: 'outlinedLarge' },
          style: {
            fontSize: 17,
            height: 52,
            lineHeight: 26,
            color: COLORS.blue[500],
            background: COLORS.white,
            overflow: 'hidden',
            border: `1px solid ${COLORS.blue[500]}`,
            '&:hover': {
              //you want this to be the same as the backgroundColor above
              background: COLORS.white,
              border: `1px solid ${COLORS.blue[500]}`,
              color: COLORS.blue[500],
            },
            '&:disabled': {
              //you want this to be the same as the backgroundColor above
              opacity: '0.5',
              color: COLORS.blue[500],
            },
          },
        },
        {
          props: { variant: 'buttonMessages' },
          style: {
            height: 28,
            color: COLORS.messageLink.main,
            background: COLORS.white,
            border: `none`,
            minWidth: 60,
            paddingX: 8,
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.1)',
            fontWeight: 500,

            '&:hover': {
              //you want this to be the same as the backgroundColor above
              color: COLORS.messageLink.main,
              background: COLORS.white,
              border: `none`,
            },
            '&:disabled': {
              //you want this to be the same as the backgroundColor above
              opacity: '0.5',
              color: COLORS.messageLink.main,
              background: COLORS.white,
              border: `none`,
            },
          },
        },
      ],
    },
  },
});

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    containedLarge: true;
    outlinedLarge: true;
    buttonMessages: true;
    menuButton: true;
  }

  interface ButtonPropsSizeOverrides {
    extraLarge: true;
  }
}

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Palette extends ColorsInterface {}

  interface BreakpointOverrides {
    android: true; // adds the `tablet` breakpoint
    iPhone: true;
  }

  interface TypographyVariants {
    h1Bold: React.CSSProperties;
    h2Bold: React.CSSProperties;
    h3Bold: React.CSSProperties;
    h4Bold: React.CSSProperties;
    h5Bold: React.CSSProperties;
    h6Bold: React.CSSProperties;
    h7Bold: React.CSSProperties;
    h8Bold: React.CSSProperties;
    h9Bold: React.CSSProperties;
    h1ExtraBold: React.CSSProperties;
    h2ExtraBold: React.CSSProperties;
    h3ExtraBold: React.CSSProperties;
    h4ExtraBold: React.CSSProperties;
    h5ExtraBold: React.CSSProperties;
    h6ExtraBold: React.CSSProperties;
    h7ExtraBold: React.CSSProperties;
    h8ExtraBold: React.CSSProperties;
    h9ExtraBold: React.CSSProperties;
    invAmount: React.CSSProperties;
    body2Small: React.CSSProperties;
    h7: React.CSSProperties;
    h8: React.CSSProperties;
    h9: React.CSSProperties;
    body3: React.CSSProperties;
    caption1: React.CSSProperties;
    caption2: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h1Bold?: React.CSSProperties;
    h2Bold?: React.CSSProperties;
    h3Bold?: React.CSSProperties;
    h4Bold?: React.CSSProperties;
    h5Bold?: React.CSSProperties;
    h6Bold?: React.CSSProperties;
    h7Bold?: React.CSSProperties;
    h8Bold?: React.CSSProperties;
    h9Bold?: React.CSSProperties;
    h1ExtraBold?: React.CSSProperties;
    h2ExtraBold?: React.CSSProperties;
    h2Bold700?: React.CSSProperties;
    h3ExtraBold?: React.CSSProperties;
    h4ExtraBold?: React.CSSProperties;
    h5ExtraBold?: React.CSSProperties;
    h6ExtraBold?: React.CSSProperties;
    h7ExtraBold?: React.CSSProperties;
    h8ExtraBold?: React.CSSProperties;
    h9ExtraBold?: React.CSSProperties;
    invAmount?: React.CSSProperties;
    body2Small?: React.CSSProperties;
    h7?: React.CSSProperties;
    h8?: React.CSSProperties;
    h9?: React.CSSProperties;
    body3?: React.CSSProperties;
    caption1?: React.CSSProperties;
    caption2?: React.CSSProperties;
    caption3?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h7: true;
    h8: true;
    h9: true;
    h1Bold: true;
    h2Bold: true;
    h3Bold: true;
    h4Bold: true;
    h5Bold: true;
    h6Bold: true;
    h7Bold: true;
    h8Bold: true;
    h9Bold: true;
    h1ExtraBold: true;
    h2ExtraBold: true;
    h3ExtraBold: true;
    h4ExtraBold: true;
    h5ExtraBold: true;
    h6ExtraBold: true;
    h7ExtraBold: true;
    h8ExtraBold: true;
    h9ExtraBold: true;
    invAmount: true;
    body2Small: true;
    h2Bold700: true;
    body3: true;
    caption1: true;
    caption2: true;
    caption3: true;
  }
}
