import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import {
  HoldBeforeSucessContainer,
  OnHoldContainer,
  SettingsOnHoldForVerificationContainer,
} from '../../containers';
import {
  Landing,
  SignUpChoice,
  SignUpSuccess,
  BusinessInfo,
  BusinessRepresentative,
  ReviewInfo,
  IdentityVerification,
  AccountVerifyPhone,
  AccountVerifyCode,
  BankDetails,
  PaymentsSelection,
  EarningsSubmitted,
  EarningsApproved,
  EarningsPaid,
  Error404,
  IndividualLetsReview,
  BusinessLetsReview,
  BankSetupSuccess,
  AccountApproved,
  EarlyPayout,
  VerificationFailed,
  SSNVerificationFailed,
  VerificationFailedContinueToID,
  Notifications,
  EINVerificationFailed,
  ReferFriend,
  CustomersAddition,
  Home,
  Settings,
  AccountInfo,
  TermsSelection,
  SettingsNotifications,
  SettingsRefereals,
  SettingsBankSetup,
  SettingLanguages,
  SettingCustomersAddition,
  InvoiceDetail,
  Transactions,
  TranDetail,
  SearchVendor,
  Error401,
  FinanceAccountInitialization,
  FinanceAccountLoading,
  Finance,
  FinanceAccountCreationSuccess,
  FinanceAccountMain,
  FinanceAccountList,
  FinanceAccountManageCard,
  FinanceAccountListDetail,
  FinanceAccountMoveMoneyLanding,
  FinanceAccountMoveMoneyForm,
  FinanceAccountMoveMoneySummary,
  PayModeSelectionSuccess,
  RecipientDetails,
  BatchesSubmitted,
  BatchesApproved,
  BatchesPaid,
  BatcheDetail,
} from '../../pages';
import PrivateRoute from './PrivateRoute';
import { useGetConfigQuery } from '../../services/config/configAPI';
import { init, trackPage } from '../analytics/service';
import { createBrowserHistory } from 'history';
import { store } from 'src/store/store';
import { ActionType } from 'src/store/action-types';
import { isJSONObject } from 'src/shared/utilities/dataConvertions';
import { SettingsOnHoldContainer } from 'src/containers/Settings/OnHold/OnHold';
import { useFlags } from 'launchdarkly-react-client-sdk';

const dispatchAppState = (isNative: boolean) => {
  if (isNative) {
    store.dispatch({
      type: ActionType.IS_NATIVE_APP,
      payload: isNative,
    });
  }
};

export const AppRoutes = () => {
  const { data, isSuccess } = useGetConfigQuery('');
  const [path, setPath]: any = useState();
  const { webauthnEnabled, treasuryAndCards, reduceTheBusinessFlowFields } = useFlags();
  const location = useLocation();

  // Required to support native iOS app
  window.addEventListener('message', (message) => {
    if (isJSONObject(message?.data)) {
      const jsonData = JSON.parse(message.data);
      if (jsonData?.isNative) {
        dispatchAppState(jsonData?.isNative);
      }
    }
  });

  // Required to support native Android app
  document.addEventListener('message', (message) => {
    if (message instanceof MessageEvent && isJSONObject(message?.data)) {
      const jsonData = JSON.parse(message.data);
      if (jsonData?.isNative) {
        dispatchAppState(jsonData?.isNative);
      }
    }
  });

  useEffect(() => {
    isSuccess && init(data?.segment_write_key);
  }, [isSuccess]);

  const history = createBrowserHistory();

  useEffect(() => {
    // listen and notify Segment of client-side page updates
    if (data && history.location.pathname !== path) {
      setPath(history.location.pathname);
      // @ts-ignore
      trackPage();
    }
  }, [history]);

  useEffect(() => {
    if (
      location?.pathname !== '/finance/account/list' &&
      !location?.pathname.includes('finance/account/transaction/detail')
    ) {
      localStorage.removeItem('transactionFilter');
      localStorage.removeItem('transaction_sorting_data');
      localStorage.removeItem('transaction_filter_schema');
      localStorage.removeItem('transaction_filter_values');
    }
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/verify-code" element={<AccountVerifyCode />} />
      <Route path="/verify-login" element={<AccountVerifyPhone />} />
      <Route path="/search-supplier" element={<SearchVendor />} />
      {webauthnEnabled ? (
        <>
          <Route path="/customers-addition" element={<CustomersAddition />} />
        </>
      ) : (
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/customers-addition" element={<CustomersAddition />} />
        </Route>
      )}
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/sign-up" element={<SignUpChoice />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/hold-on" element={<OnHoldContainer />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/review-info" element={<ReviewInfo />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/identity-verification" element={<IdentityVerification />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/business-info" element={<BusinessInfo />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/business-representative" element={<BusinessRepresentative />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/signup-success" element={<SignUpSuccess />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/bank-details" element={<BankDetails />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/payment-selection" element={<PaymentsSelection />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/earnings/submitted" element={<EarningsSubmitted />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/earnings/approved" element={<EarningsApproved />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/earnings/paid" element={<EarningsPaid />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/individual-review" element={<IndividualLetsReview />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/business-review" element={<BusinessLetsReview />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/hold-sucess" element={<HoldBeforeSucessContainer />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/payout/:id" element={<InvoiceDetail />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/transfer/:id" element={<TranDetail />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/bank-setup-success" element={<BankSetupSuccess />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/paymode-selection-success" element={<PayModeSelectionSuccess />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/recipient-details" element={<RecipientDetails />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/account-approved" element={<AccountApproved />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/early-payout" element={<EarlyPayout />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/verification-failed" element={<VerificationFailed />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/ssn-verification-failed" element={<SSNVerificationFailed />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route
          path="/continueto-id-doc-on-verification-failed"
          element={<VerificationFailedContinueToID />}
        />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/ein-verification-failed" element={<EINVerificationFailed />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/enable-notifications" element={<Notifications />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/refer-a-friend" element={<ReferFriend />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/dashboard" element={<Home />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/payments" element={<Transactions />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/settings/account-info" element={<AccountInfo />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/settings/terms-selection" element={<TermsSelection />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/settings/notifications" element={<SettingsNotifications />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/settings/referrals" element={<SettingsRefereals />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/settings/bank-setup" element={<SettingsBankSetup />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/settings/languages" element={<SettingLanguages />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/settings/customers-addition" element={<SettingCustomersAddition />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/settings" element={<Settings />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route
          path="/settings/hold-on"
          element={
            reduceTheBusinessFlowFields ? (
              <SettingsOnHoldForVerificationContainer />
            ) : (
              <SettingsOnHoldContainer />
            )
          }
        />
      </Route>
      <Route path="*" element={<Error404 />} />
      <Route path="/401" element={<Error401 />} />
      {/**
       * START - Finance related Routings
       */}
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/finance" element={<Finance />} />
      </Route>
      {treasuryAndCards && (
        <>
          <Route path="/" element={<PrivateRoute />}>
            <Route
              path="/finance/account-initialization"
              element={<FinanceAccountInitialization />}
            />
          </Route>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/finance/account-loading" element={<FinanceAccountLoading />} />
          </Route>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/finance/account-success" element={<FinanceAccountCreationSuccess />} />
          </Route>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/finance/account/main" element={<FinanceAccountMain />} />
          </Route>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/finance/account/list" element={<FinanceAccountList />} />
          </Route>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/finance/account/manage-card" element={<FinanceAccountManageCard />} />
          </Route>
          <Route path="/" element={<PrivateRoute />}>
            <Route
              path="/finance/account/transaction/detail/:id"
              element={<FinanceAccountListDetail />}
            />
          </Route>
          <Route path="/" element={<PrivateRoute />}>
            <Route
              path="/finance/account/move-money"
              element={<FinanceAccountMoveMoneyLanding />}
            />
          </Route>
          <Route path="/" element={<PrivateRoute />}>
            <Route
              path="/finance/account/move-money/transfer"
              element={<FinanceAccountMoveMoneyForm />}
            />
          </Route>
          <Route path="/" element={<PrivateRoute />}>
            <Route
              path="/finance/account/move-money/summary"
              element={<FinanceAccountMoveMoneySummary />}
            />
          </Route>
        </>
      )}
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/batches/submitted" element={<BatchesSubmitted />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/batches/approved" element={<BatchesApproved />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/batches/paid" element={<BatchesPaid />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/batch/:payoutId" element={<BatcheDetail />} />
      </Route>
      {/**
       * END - Finance related Routings
       */}
    </Routes>
  );
};
