import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getFormattedDate } from '../../../../../shared/utilities/dob';
import {
  AddressCityStateZip,
  RecipentAddressCityStateZip,
} from '../../../../../shared/utilities/addressUtils';
import { AccountParams } from '../../../../../shared/utilities/interface';
import { phoneNumberMask } from '../../../../../shared/utilities/phoneNumberMask';

import { getAditionalPhoneDetails } from '../../../../../services/account/additional.phone.service';

import { Button, Box, Typography } from '@mui/material';

import { AccountVerificationStatusses, FooterNote, ShadowCard } from 'src/components/base/core';

import {
  Header,
  Wrapper,
  HeadingSummaryBlock,
  ContentContainerBottom,
  DivWrapper,
  ReviewCard,
  Messages,
} from 'src/components/base/core';
import { FormEditSubmition } from './FormEditSubmition/FormEditSubmition';
import {
  useGetAccountQuery,
  useUpdateAccountMutation,
} from 'src/services/account/accountServiceApi';
import dtoToFormValues from 'src/shared/utilities/dto';
import { accountIndividual } from 'src/models/account/account.service.mapper';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { RefreshIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import { trackEvent } from '../../../../../components/analytics/service';
import { commonSpacing, theme } from 'src/shared/theme/theme';
import { routeType } from 'src/helpers/containers/helperRoutePreference';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { pre_fill_params } from 'src/shared/content/content';
import { checkIsAnyOfTheRecipentDetailsAreAddedOrNot } from 'src/helpers/helperHandlingTerms';

export const IndividualReviewContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { webauthnEnabled, recipientDetailsFormEnabled } = useFlags();

  const { data, isFetching } = useGetAccountQuery('');
  const [updateAccount] = useUpdateAccountMutation();
  const [detailSummitted, setDetailSummitted] = useState(false);
  const [additionalPhoneNumber, setAdditionalPhoneNumber] = useState('');
  const [visibleUpdateForm, setVisibleUpdateForm] = useState(false);
  const getErrorBlockVisibility = localStorage.getItem('review_error_block');
  const [result, setResult]: any = useState();
  const [verificationDocumentFront, setVerificationDocumentFront]: any = useState();
  const [verificationDocumentBack, setVerificationDocumentBack]: any = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [serverErrorsSSN, setServerErrorsSSN]: any = useState(null);
  const [mapMissedValues, setMapMissedValues]: any = useState();

  const [updatePreferenceOnServer] = handlePreferences();

  //hook-forms
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<AccountParams>();

  React.useEffect(() => {
    if (data) {
      dtoToFormValues(setValue, data);
      setResult(data);
      setVerificationDocumentFront(data?.individual?.verification?.document.front);
      setVerificationDocumentBack(data?.individual?.verification?.document.back);
      localStorage.removeItem('isBack');
    }
    if (detailSummitted && data?.tos_shown_and_accepted) {
      localStorage.setItem('enable_holdon', 'true');
      localStorage.removeItem('backFromReview');
      setTimeout(function () {
        setDetailSummitted(false);
        navigate('/hold-on');
      }, 100);
    }
  }, [data]);

  React.useEffect(() => {
    dtoToFormValues(setValue, result);

    if (result) {
      const { status, details_code, details } = result?.individual?.verification;
      if (status === 'unverified' && details_code === 'failed_keyed_identity') {
        setServerErrorsSSN(details);
      } else {
        setServerErrorsSSN(null);
      }
    }
  }, [result]);

  const submitForm = async () => {
    trackEvent('Submit Click on Review');
    setIsLoading(true);
    const postData = getValues();
    const updateFormData = {
      ...postData,
      phone_number: postData?.phone_number?.replace(/[^0-9]/g, ''),
      inputDescription: pre_fill_params.description,
      inputMCC: pre_fill_params.mcc,
      url: pre_fill_params.url,
      tos_shown_and_accepted: true,
    };
    const dataModel = accountIndividual(updateFormData, false, false);
    const accountUpdateResponse = await updateAccount(dataModel);
    // @ts-ignore
    if (accountUpdateResponse?.data) {
      localStorage.removeItem('review_error_block');
      navigate('/hold-on');
    }
    setDetailSummitted(true);
    setIsLoading(false);
  };

  const handleEditIcon = () => {
    trackEvent('Edit Click On Review');
    setVisibleUpdateForm(true);
  };

  const handleCancelEvent = () => {
    setVisibleUpdateForm(false);
  };

  const navigateToSSNVerification = () => {
    updatePreferenceOnServer(routeType.VERIFICATION_FAIL_SSN);
    navigate('/ssn-verification-failed');
  };

  const handleBackButton = () => {
    trackEvent('Back Click from Review');
    localStorage.setItem('isBack', 'true');
    // localStorage.setItem('backFromReview', 'true');
    // localStorage.setItem('step', '1');
    setVisibleUpdateForm(false);

    if (getErrorBlockVisibility && getErrorBlockVisibility === 'visible') {
      updatePreferenceOnServer(routeType.VERIFICATION_FAIL_INDIVIDUAL);
      navigate('/verification-failed');
    } else {
      navigate('/identity-verification', {
        state: { isCompany: false, backFrom: 'review', step: 'step3' },
      });
    }
  };

  /**
   * get addtional phone number
   */
  const getAditionalPhoneDisplay = async (id: string) => {
    try {
      const resultPhone = await getAditionalPhoneDetails(id).then((res: any) => {
        return res !== null ? res : '';
      });
      setAdditionalPhoneNumber(resultPhone?.data[0]?.phone_number);
    } catch (error) {
      console.error(error);
    }
  };

  const onFormSubmit = () => {
    handleCancelEvent();
    localStorage.removeItem('review_error_block');
  };

  const verificationStatus = result?.individual?.verification?.status;
  const getFirstName = result?.individual?.first_name ?? '';
  const getLastName = result?.individual?.last_name ?? '';

  const dateObject = result?.individual?.dob;
  const checkDOBNull =
    dateObject && dateObject?.day !== null && dateObject.month !== null && dateObject.year !== null;

  const [isRecipentDetailsAreAvailable] = checkIsAnyOfTheRecipentDetailsAreAddedOrNot(result);

  return (
    <>
      {isLoading && <ManualLoader />}
      {result && (
        <Wrapper>
          <DivWrapper className={visibleUpdateForm ? 'no_fullscreen' : 'no_fullscreen'}>
            {!visibleUpdateForm && (
              <Box component={'div'} className={'container_content bottom_terms_padding'}>
                <Box
                  component={'div'}
                  className={'container_content_top'}
                  sx={{
                    [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                      minHeight: 455 + commonSpacing.desktopPaddingB * 4,
                      paddingBottom: 2,
                    },
                  }}
                >
                  <Header
                    isBackButtonVisible={true}
                    onBackButtonClick={handleBackButton}
                    isProgressBarVisible={true}
                    totalProgressSteps={5}
                    currentProgressStep={5}
                    paddingX={0}
                    backButtonId={'IndividualReviewBack'}
                    isLanguageSwitcher
                  />
                  <Box sx={{ marginBottom: 6 }}>
                    <HeadingSummaryBlock
                      heading={t('headingContent.individual.review.heading')}
                      headingAlignment="left"
                      content={t('headingContent.individual.review.content')}
                      contentAlignment="left"
                    />
                  </Box>
                  <Box>
                    <ShadowCard marginBottom={6} px={4} pt={6} pb={4}>
                      <ReviewCard
                        title={t('captions.personalInfo')}
                        name={`${getFirstName} ${getLastName}`}
                        editIconClickEvent={handleEditIcon}
                        type={
                          getErrorBlockVisibility && getErrorBlockVisibility === 'visible'
                            ? 'restricted'
                            : result?.individual?.verification?.status
                        }
                        isInnerTitle
                        isShadowWrappedIcon
                      >
                        <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                          {result?.individual.email ? `${result?.individual.email}` : null}
                        </Typography>
                        {webauthnEnabled && (
                          <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                            {`•••••••••••••••`}
                          </Typography>
                        )}
                        <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                          {`${phoneNumberMask(result?.individual.phone)}`}
                        </Typography>
                        <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                          {checkDOBNull
                            ? `${t('reviewCardtexts.bornOnText')} ${getFormattedDate(
                                result?.individual?.dob
                              )}`
                            : null}
                        </Typography>
                        <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                          {result?.individual?.address?.line1 ?? ''}
                        </Typography>
                        <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                          {result?.individual?.address?.line2 ?? ''}
                        </Typography>
                        <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                          {AddressCityStateZip(result?.individual)}
                        </Typography>
                        {result?.individual?.ssn_last4_provided && (
                          <Typography
                            variant="h1"
                            color={'gray.700'}
                            marginBottom={2}
                            className="ssn_provided_text"
                          >
                            {t('reviewCardtexts.idProvidedLast4Text')}
                            <div
                              className="icon_wrapper"
                              style={{ cursor: 'pointer' }}
                              onClick={handleEditIcon}
                            >
                              <RefreshIcon />
                            </div>
                          </Typography>
                        )}
                        {(verificationDocumentFront || verificationDocumentBack) && (
                          <Typography
                            variant="h1"
                            color={'gray.700'}
                            marginBottom={2}
                            className="ssn_provided_text"
                          >
                            {'Document Provided'}
                            {verificationStatus === 'verified' && (
                              <div className="ssn_provided_text_verified">
                                <AccountVerificationStatusses type="verified" />
                              </div>
                            )}
                            {verificationStatus !== 'verified' && (
                              <div
                                className="icon_wrapper"
                                style={{ cursor: 'pointer' }}
                                onClick={handleEditIcon}
                              >
                                <RefreshIcon />
                              </div>
                            )}
                          </Typography>
                        )}
                      </ReviewCard>
                    </ShadowCard>
                    {isRecipentDetailsAreAvailable && recipientDetailsFormEnabled && (
                      <ShadowCard marginBottom={6} px={4} pt={6} pb={4}>
                        <ReviewCard
                          title={t('captions.recipientInfo')}
                          name={result?.stripe_account_metadata?.check_recipient_details_name ?? ''}
                          isInnerTitle
                          isShadowWrappedIcon
                          hideEdit
                        >
                          {data?.stripe_account_metadata?.check_recipient_details_address && (
                            <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                              {data?.stripe_account_metadata?.check_recipient_details_address ?? ''}
                            </Typography>
                          )}
                          {data?.stripe_account_metadata?.check_recipient_details_address_2 && (
                            <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                              {data?.stripe_account_metadata?.check_recipient_details_address_2 ??
                                ''}
                            </Typography>
                          )}
                          <Typography variant="h1" color={'gray.700'} marginBottom={2}>
                            {RecipentAddressCityStateZip(result)}
                          </Typography>
                        </ReviewCard>
                      </ShadowCard>
                    )}
                  </Box>
                  <Box>
                    {((getErrorBlockVisibility && getErrorBlockVisibility === 'visible') ||
                      serverErrorsSSN ||
                      result?.errors?.length > 0 ||
                      result?.currently_due_requirements?.length > 0) &&
                      !isLoading && (
                        <Messages
                          topMargin={4}
                          bottomMargin={2}
                          messageHeading={t('errorMessages.reviewError.heading')}
                          messageContent={t('errorMessages.reviewError.content')}
                          messageLinkText={t('errorMessages.reviewError.linkText')}
                          messageLinkEvent={handleEditIcon}
                        />
                      )}
                  </Box>
                </Box>
                <Box component={'div'} className="container_content_bottom">
                  <FooterNote />
                </Box>
              </Box>
            )}
            {visibleUpdateForm && (
              <FormEditSubmition
                onBackButtonClick={handleBackButton}
                onCancelButton={handleCancelEvent}
                onFormSubmit={onFormSubmit}
                setResult={setResult}
                result={result}
                title={t('captions.personalInfo')}
                type={
                  getErrorBlockVisibility && getErrorBlockVisibility === 'visible'
                    ? 'restricted'
                    : result?.individual?.verification?.status
                }
                navigate={navigate}
              />
            )}
            <ContentContainerBottom
              className={visibleUpdateForm ? 'no_fullscreen no_note' : 'no_fullscreen no_note'}
            >
              <Button
                variant="containedLarge"
                color="primary"
                type="submit"
                fullWidth
                onClick={submitForm}
                sx={{ overflow: 'hidden' }}
                disabled={visibleUpdateForm}
              >
                {t('buttonTexts.submitButton')}
              </Button>
            </ContentContainerBottom>
          </DivWrapper>
        </Wrapper>
      )}
    </>
  );
};
