import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Box, Button, Typography } from '@mui/material';
import {
  ButtonAuthorizedWrapper,
  FormAuthorizedWrapper,
  FormInput,
  Header,
  HeaderFixed,
  ShadowCard,
  Wrapper,
  WrpperAuthorizedContainer,
} from 'src/components/base/core';
import { FinanceMoveMoneySummaryCard } from 'src/components/base/core/Cards/FinanceMoveMoneySummaryCard/FinanceMoveMoneySummaryCard';

import { useGetBlockHeight } from 'src/hooks/useGetBlockHeight';
import { commonSpacing, theme } from 'src/shared/theme/theme';
import { ERROR_MESSAGES } from 'src/constants/Validation';

type LocationState = {
  accountId?: boolean;
  accountType?: boolean;
};

export const FinanceAccountMoveMoneyFormContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [elementRef, height] = useGetBlockHeight();
  const [butonDisabledState, setButonDisabledState] = useState(false);

  const { accountId, accountType } =
    location?.state != null
      ? (location?.state as LocationState)
      : {
          accountId: null,
          accountType: null,
        };

  const schema = yup
    .object({
      amount: yup.string().required(ERROR_MESSAGES.REQUIRED),
      description: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .max(10, ERROR_MESSAGES.MAX_DISCRIPTOR_ALLOWED_LIMIT),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    formState: { errors },
  } = useForm<{
    amount?: string | number;
    description?: string | number;
  }>({
    resolver: yupResolver(schema),
  });

  const updateBindingsChange = (event: any, feild: any) => {
    setValue(feild, event.target.value);
    const isButtonDisabled = getValues('amount') !== '' && getValues('description') !== '';
    setButonDisabledState(isButtonDisabled);
  };

  const appSubmit = handleSubmit(async (data: any) => {
    const getStorageBankDetails: any = localStorage.getItem('link-bank-details');
    const getLinkedAccountDetails = JSON.parse(getStorageBankDetails);
    navigate('/finance/account/move-money/summary', {
      state: {
        amount: data.amount,
        description: data.description ?? null,
        accountId: accountId ?? getLinkedAccountDetails?.id,
        accountType: accountType ?? getLinkedAccountDetails?.bankCardType,
      },
    });
  });

  return (
    <>
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonHide={false}
          isBackButtonVisible
          paddingX={0}
          isLogoVisible={false}
          headerText={t('finance.MoveMoney.headerText')}
          headerPaddingBottom={3}
          withCenteredHeaderText
          onBackButtonClick={() => navigate('/finance/account/move-money')}
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper>
        <WrpperAuthorizedContainer>
          <FormAuthorizedWrapper onSubmit={appSubmit}>
            <Box
              ref={elementRef}
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                  minHeight: height,
                  paddingBottom: height ? 4 : commonSpacing.desktopPaddingB,
                },
              }}
            >
              <FinanceMoveMoneySummaryCard t={t} />
              <ShadowCard paddingX={4} paddingTop={4} paddingBottom={1} marginBottom={6}>
                <Typography
                  component="p"
                  variant="h2Bold"
                  textTransform={'uppercase'}
                  sx={{
                    marginBottom: 4,
                    display: 'inline-flex',
                    alignItems: 'center',
                    color: 'gray.500',
                  }}
                >
                  {t('finance.MoveMoney.form.title')}
                </Typography>
                <Controller
                  control={control}
                  name="amount"
                  render={({ field: { onChange, value } }) => (
                    <FormInput
                      label={t('formInput.amountRequired')}
                      id={'amount'}
                      {...register('amount')}
                      error={errors?.amount?.message}
                      onChange={(event: any) => {
                        updateBindingsChange(event, 'amount');
                      }}
                      onKeyPress={(event: any) => {
                        if (!/^[-+]?\d*\.?\d*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={value}
                      isAmountField
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="description"
                  render={({ field: { onChange, value } }) => (
                    <FormInput
                      label={t('formInput.addNote')}
                      id={'description'}
                      {...register('description')}
                      error={errors?.description?.message}
                      onChange={(event: any) => {
                        event.target.value = event.target.value.replace('  ', ' ');
                        updateBindingsChange(event, 'description');
                      }}
                      value={value}
                    />
                  )}
                />
              </ShadowCard>
            </Box>
            <ButtonAuthorizedWrapper>
              <Button
                variant="containedLarge"
                color="primary"
                fullWidth
                id={'Finance'}
                type={'submit'}
                sx={{ backgroundColor: 'blue.500', color: 'white', marginBottom: 0 }}
                disabled={!butonDisabledState}
              >
                {t('buttonTexts.continueButton')}
              </Button>
            </ButtonAuthorizedWrapper>
          </FormAuthorizedWrapper>
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
