import styled from 'styled-components';

export const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 20px;
  border-bottom 1px solid #e3e8ee;
  padding: 10px 24px;
  background-color: #f5f5f5;
  width: 100%;
  max-width: 100%;
`;
