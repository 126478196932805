import { useState } from 'react';
import { trackEvent } from 'src/components/analytics/service';
import { ERROR_MESSAGES } from 'src/constants/Validation';
import { useCreateVerificationCodeMutation } from 'src/services/auth/authServiceApi';
import { getIntVal } from 'src/shared/utilities/dataConvertions';

export const handleVerifyPhone = () => {
  const [errorState, setErrorState] = useState('');
  const [createVerificationCode, { isLoading }] = useCreateVerificationCodeMutation();

  const handleVerificationCode = async (data: any, getValues: any, navigate: any) => {
    const value = data.phone.replace(/\D/g, '');
    try {
      const verifyCodeResponse = await createVerificationCode(data.phone);
      // @ts-ignore
      if (verifyCodeResponse?.data?._id != null) {
        // @ts-ignore
        const pNumber = verifyCodeResponse?.data?.phone_number;
        navigate('/verify-code', { state: { phone: pNumber, phoneWithMask: data.phone } });
      } else {
        /**
         * display the server error along with input
         * if the error happen return error_description form catch block
         */
        // @ts-ignore
        const responseError = verifyCodeResponse?.data?.error;
        if (responseError) {
          /**
           * display a custom message because server error containes some code & for improve readability
           * ex: The 'To' number +10000000000 is not a valid phone number. (Code: 21211)"
           */
          if (responseError === 'sms_provider_error') {
            setErrorState(ERROR_MESSAGES.INVALID_SMS_PHONE.replace('{phone}', getValues('phone')));
          } else {
            // @ts-ignore
            setErrorState(verifyCodeResponse?.data?.error_description?.toString());
          }
        }
      }

      // @ts-ignore
      // setUserForTracking(`auth${verifyCodeResponse?.data?._id}`, 'Added Phone number');
      // @ts-ignore
      trackEvent('Added Phone number', verifyCodeResponse?.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const onChangeHandler = (val: any, getValues: any, setValue: any, setButtonDisable: any) => {
    //input change remove the errors
    setErrorState('');
    setValue('phone', val);
    if (val !== undefined && getIntVal(val)?.length === 10) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  };

  return [handleVerificationCode, errorState, onChangeHandler, isLoading];
};
