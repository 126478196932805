import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Header,
  HeaderFixed,
  WrpperAuthorizedContainer,
  BankInfoCard,
  FormAuthorizedWrapper,
  ButtonAuthorizedWrapper,
  Wrapper,
  SnackBar,
} from 'src/components/base/core';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';
import { routeType } from 'src/helpers/containers/helperRoutePreference';
import { handleSettings } from 'src/helpers/containers/helperSettings';
import { checkIsInstantPayModeAndTreasuryEnabled } from 'src/shared/utilities/dataConvertions';

export const SettingsBankSetupContainer = ({ isSettingDesktop = false }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [updatePreferenceOnServer] = handlePreferences();
  const [allBankDetails, isLoading, deleteAccount, updateDefaultAccount, data] = handleSettings();
  const [errorState, setErrorState] = useState('');

  const handleBackButton = () => {
    updatePreferenceOnServer(routeType.SETTINGS);
    navigate('/settings');
  };

  const addBankAccount = () => {
    const isNotCheckPayMode = data && data?.default_pay_mode !== 'check';
    const isCheckPayModeIsNotSelectedAndNoBankAccounts =
      allBankDetails && allBankDetails?.data.length === 0 && isNotCheckPayMode;
    const [isInstatPayoutTreasuryEnabled] = checkIsInstantPayModeAndTreasuryEnabled(data);

    if (isInstatPayoutTreasuryEnabled) {
      setErrorState(t('notificationMessages.blockAddingBankAccount.content'));
    } else {
      setErrorState('');
      isCheckPayModeIsNotSelectedAndNoBankAccounts
        ? navigate('/payment-selection', {
            state: {
              fromSettings: true,
              isSettingDesktop: isSettingDesktop,
              isAfterKycFromTermScreen: true,
            },
          })
        : navigate('/bank-details', {
            state: {
              fromSettings: true,
              isSettingDesktop: isSettingDesktop,
              isAfterKycFromTermScreen: false,
            },
          });
    }
  };

  return (
    <>
      {isLoading && <ManualLoader />}
      {!isSettingDesktop && (
        <HeaderFixed className="fixed_only_desktop">
          <Header
            isMainMunuVisible
            onBackButtonClick={handleBackButton}
            isBackButtonVisible
            isBackButtonHide
            paddingX={0}
            headerText={t('settings.LinkedBankAccount.title')}
            withCenteredHeaderText
          />
        </HeaderFixed>
      )}
      <Wrapper
        isAuthorizedWrapper
        idSelector={!isSettingDesktop ? 'wrapperContainer' : 'settingDesktopWraper'}
        isSettingDesktop={isSettingDesktop}
      >
        <WrpperAuthorizedContainer>
          <FormAuthorizedWrapper>
            {allBankDetails &&
              allBankDetails?.data?.map((option: any, index: any) => {
                return (
                  <BankInfoCard
                    bankDetails={option}
                    key={index}
                    deleteAccount={deleteAccount}
                    updateDefaultAccount={updateDefaultAccount}
                    isBorderEnabled={isSettingDesktop}
                  />
                );
              })}

            {errorState !== '' && (
              <SnackBar
                messageHeading={t('notificationMessages.blockAddingBankAccount.heading')}
                messageContent={errorState}
                type="error"
                duration={3000}
                onClose={() => setErrorState('')}
                messageLinkText={t('notificationMessages.blockAddingBankAccount.btnText')}
                messageLinkEvent={() => navigate('/settings/terms-selection')}
                hideCloseIcon={false}
              />
            )}
            <ButtonAuthorizedWrapper>
              {allBankDetails && (
                <Button
                  variant="containedLarge"
                  color="primary"
                  type="button"
                  fullWidth
                  sx={{ overflow: 'hidden' }}
                  onClick={addBankAccount}
                >
                  <Typography variant="h5Bold" color="white" sx={{ marginRight: 2 }}>
                    +
                  </Typography>
                  {t('settings.LinkedBankAccount.buttonText')}
                </Button>
              )}
            </ButtonAuthorizedWrapper>
          </FormAuthorizedWrapper>
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
