import axios from 'axios';
import { ActionType } from '../store/action-types';
import { store } from '../store';
import { configs } from '../shared/config/config';
import { delete_cookie } from 'src/shared/utilities/cookiesHelper';

const UNAUTHORIZED = 401;
const dispatchLoadingState = (v: boolean) => {
  (checkLoaderVisibility() || v === false) &&
    store.dispatch({
      type: ActionType.AJAX_LOADING,
      payload: v,
    });
};

const checkLoaderVisibility = (): boolean => {
  switch (window.location.pathname) {
    case '/hold-on':
    case '/hold-sucess':
    case '/earnings/submitted':
    case '/earnings/approved':
    case '/earnings/paid':
    case '/payments':
    case '/account':
      return false;
      break;
    default:
      return true;
      break;
  }
};

const dispatchRefreshTokenState = (value: boolean) => {
  store.dispatch({
    type: ActionType.REFRESH_TOKEN,
    payload: value,
  });
};
const dispatchApiTimeout = (v: boolean) => {
  store.dispatch({
    type: ActionType.API_TIMEOUT,
    payload: v,
  });
};
export default (hideLoader?: boolean, isNonLoggedIn = true) => {
  axios.defaults.baseURL = `${configs.BASE_URL}`;
  const api_timeout = 90000;
  axios.defaults.timeout = api_timeout;
  axios.interceptors.request.use((config: any) => {
    !hideLoader && dispatchLoadingState(true);
    const id_token = localStorage.getItem('id_token');
    config.headers.common['Authorization'] = `Bearer ${id_token}`;
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      dispatchLoadingState(false);
      dispatchApiTimeout(false);
      return response;
    },
    async (error) => {
      if (error.message === `timeout of ${api_timeout}ms exceeded`) {
        dispatchApiTimeout(true);
      }
      dispatchLoadingState(false);

      if (error?.response?.status === UNAUTHORIZED) {
        delete_cookie('T_added');
        if (isNonLoggedIn) {
          window.location.replace(`${window.location.origin}/401`);
        } else {
          localStorage.removeItem('id_token');
        }
        //    getStorage(REFRESH_TOKEN_STORAGE_KEY).then(async (result) => {
        //      if (result != null) {
        //        await getRefreshToken(result).then(async (response) => {
        //          await setIdTokenInStorage(response.data.access_token);
        //          dispatchRefreshTokenState(true);
        //        });
        //      }
        //    });
      }
      return error;
    }
  );
};
