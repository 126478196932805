import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Qs from 'qs';

import {
  EarningTabHeader,
  PaymentTabNav,
  PaymentTableGrid,
  SearchBar,
} from 'src/components/base/core/Grids/InvoiceGridElements';
import { PaginationLoader } from 'src/components/base/core/Grids/InvoiceGridElements/PaymentGrid/PaymentTableGrid/PaymentTableGrid.style';

import { handleTranData } from 'src/helpers/heperHandlingTransactionData';

import GridProvider from 'src/contexts/gridContext';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { getSearchParams } from 'src/helpers/helperSearchParams';
import {
  Header,
  HeaderFixed,
  WrpperAuthorizedContainer,
  EmptyList,
  Wrapper,
} from 'src/components/base/core';
import { helperTransactions } from 'src/helpers/containers/helperTransactions';

const enableSearchAndFilteringOnTrans = true;

export const TransactionsContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [
    offset,
    pageCount,
    showLoader,
    searchInProgress,
    handleShowMore,
    rowCount,
    handleRowCountChange,
    tranData,
    decimalAmount,
    payout_amount,
    handleSearch,
    handleDataSorting,
    handleApplyFilter,
    handleFilterOpen,
    handleFilterClose,
    handleFilterReset,
    filterStates,
    setSelectedFilterOptions,
    selectedFilterOptions,
    setFilterAmountRange,
    resetFilter,
    setSelectedDateFilterOptions,
    selectedDateFilters,
    minmumAmount,
    maximumAmount,
    searchText,
    handleResetSearch,
    onDateChange,
    selectedDateRange,
    filterAllCount,
    setSelectedDateRange,
    setAllSelect,
    isAllSelect,
    totalInvoices,
    filterStatus,
    filteringApplied,
    filterSearchParams,
    setResetFilter,
    enableQuerySearch,
    savedMinmumAmount,
    savedMaximumAmount,
    downloadStates,
    setDownloadStates,
  ] = handleTranData();

  const [upcomingCount] = helperTransactions();

  const getUrlParams = () => {
    if (location?.search) {
      return {
        ...Qs.parse(location.search.substring(1)),
        pagination: { offset, rowCount },
      };
    }

    return {
      pagination: { offset, rowCount },
    };
  };

  useEffect(() => {
    if (filteringApplied) {
      navigate(
        {
          pathname: '/payments',
          search: `?${createSearchParams(getSearchParams(filterSearchParams))}`,
        },
        { replace: true }
      );
    }
  }, [filterSearchParams]);

  useEffect(() => {
    if (filteringApplied) {
      localStorage.setItem('ear-min', minmumAmount);
      localStorage.setItem('ear-max', maximumAmount);
    }
  }, [filteringApplied]);

  useEffect(() => {
    navigate(
      {
        pathname: '/payments',
        search: `?${createSearchParams(getSearchParams(getUrlParams()))}`,
      },
      { replace: true }
    );
  }, [offset, rowCount]);

  useEffect(() => {
    if (resetFilter) {
      navigate({ pathname: '/payments' }, { replace: true });
      setResetFilter(!resetFilter);
    }
  }, [resetFilter]);

  return (
    <>
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonVisible={false}
          isBackButtonHide
          paddingX={0}
          headerText={t('menu.payments')}
          withCenteredHeaderText
        />
      </HeaderFixed>
      {/** Tab navigation */}
      <PaymentTabNav isInvoices={false} />
      <Wrapper isAuthorizedWrapper isTabNavigationAvailable>
        <WrpperAuthorizedContainer>
          {/** Data grid */}
          {((tranData && tranData?.length > 0) || searchInProgress || enableQuerySearch) && (
            <>
              <EarningTabHeader
                firstColTitle={t('inVoiceCards.paidOut')}
                firstColAmount={payout_amount[0]}
                secondColTitle={t('inVoiceCards.upcoming')}
                secondColContent={
                  upcomingCount === undefined
                    ? '-'
                    : `${upcomingCount} ${t('transactions.payouts')}`
                }
                decimalAmount={decimalAmount}
              />
              {enableSearchAndFilteringOnTrans && (
                <SearchBar
                  isTransactions
                  onchangeHandler={handleSearch}
                  onClickEnableFilter={handleFilterOpen}
                  isSearchIconVisible={searchText === '' || searchText === undefined}
                  onResetSearch={handleResetSearch}
                  searchText={searchText}
                  filterCounterValue={filterAllCount}
                  t={t}
                />
              )}
              <GridProvider
                accordion={{
                  activeAccordion: true,
                  activeAccordionOld: null,
                  enableAccordion: false,
                  isOldInvoice: false,
                  showMoreInprogressOld: false,
                }}
                filterStatus={filterStatus}
              >
                <PaymentTableGrid
                  handleShowMore={(value: any) => handleShowMore(value)}
                  selectedRowCount={rowCount}
                  onRowCountChange={(val) => handleRowCountChange(val)}
                  dataObject={tranData}
                  paginationDisplayCondition={tranData && pageCount >= 1}
                  offset={offset}
                  pageCount={pageCount}
                  tabType="transactions"
                  showLoader={showLoader}
                  onModalClose={handleFilterClose}
                  handleDataSorting={handleDataSorting}
                  onClickApplyFilter={handleApplyFilter}
                  selectedSortingOption={filterStates.sort}
                  setSelectedFilterOptions={setSelectedFilterOptions}
                  onFilterReset={handleFilterReset}
                  setFilterAmountRange={setFilterAmountRange}
                  selectedFilterOptions={selectedFilterOptions}
                  setSelectedDateFilterOptions={setSelectedDateFilterOptions}
                  selectedDateFilters={selectedDateFilters}
                  resetFilter={resetFilter}
                  minmumAmount={minmumAmount}
                  maximumAmount={maximumAmount}
                  onDateChange={onDateChange}
                  selectedDateRange={selectedDateRange}
                  isFilterEnable={filterStates && filterStates.isFilterEnable}
                  setSelectedDateRange={setSelectedDateRange}
                  setAllSelect={setAllSelect}
                  isAllSelect={isAllSelect}
                  isSelectTagVisible={false}
                  isAccordion={false}
                  accordionHeading={'Transactions'}
                  isAccordionArrowVisible={false}
                  isTooltipInfoEnabled={false}
                  dataCount={totalInvoices}
                  savedMinmumAmount={savedMinmumAmount}
                  savedMaximumAmount={savedMaximumAmount}
                  iconArrowVisible={true}
                  downloadStates={downloadStates}
                  setDownloadStates={setDownloadStates}
                />
              </GridProvider>
            </>
          )}

          {/** Loader */}
          {tranData === undefined && <PaginationLoader />}

          {/** Empty list */}
          {tranData?.length === 0 && !searchInProgress && (
            <EmptyList
              heading={t('emptyStates.invoiceCards.payments.emptyStateTitle')}
              subHeading={t('emptyStates.invoiceCards.payments.emptyStateContent')}
              t={t}
            />
          )}
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
