import React from 'react';
import { SkeltonBar } from '../SkeltonBar/SkeltonBar';
import { WrapperDiv, WrapperContainer, FirstCol, SecondCol } from './EarningTabHeader.style';
import { ShadowCard } from '../../../CommonNestedStyles/FormWrapper.style';
import { Typography } from '@mui/material';
import { theme } from 'src/shared/theme/theme';
import { getAmountFontVarient, getIntVal } from 'src/shared/utilities/dataConvertions';

export interface EarningTabHeaderProps {
  firstColTitle?: string;
  firstColAmount?: string;
  secondColTitle?: string;
  isSecondColHasInvoiceCount?: boolean;
  secondColContent?: string;
  decimalAmount?: string;
  isEqualColWidth?: boolean;
}

export const EarningTabHeader = ({
  firstColTitle,
  firstColAmount,
  secondColTitle,
  isSecondColHasInvoiceCount = false,
  secondColContent,
  decimalAmount,
  isEqualColWidth,
}: EarningTabHeaderProps) => {
  const [variants]: any = getAmountFontVarient(firstColAmount ? firstColAmount : '0');
  const getIntValueOfAmount: any = getIntVal(firstColAmount);
  const convertIntValueOfAmount = parseInt(getIntValueOfAmount);

  return (
    <ShadowCard
      marginTop={2}
      sx={{
        [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
          paddingX: 12,
          paddingY: 7,
          marginBottom: 12,
        },
        [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
          padding: 4,
          marginBottom: 8,
        },
      }}
    >
      <WrapperDiv>
        <WrapperContainer>
          <FirstCol className={isEqualColWidth ? 'equal_col_width' : 'not_equal_col_width'}>
            <Typography variant="h1Bold" component={'p'} color={'gray.500'} marginBottom={2.5}>
              {firstColTitle}
            </Typography>
            <Typography variant={variants} component={'p'} color={'gray.800'}>
              {firstColAmount !== '-' ? (
                <>
                  {firstColAmount}
                  {convertIntValueOfAmount < 1000 && <span>{`.${decimalAmount}`}</span>}
                </>
              ) : (
                <SkeltonBar />
              )}
            </Typography>
          </FirstCol>
          <SecondCol className={isEqualColWidth ? 'equal_col_width' : 'not_equal_col_width'}>
            <Typography variant="h1Bold" component={'p'} color={'gray.500'} marginBottom={2.5}>
              {secondColTitle}
            </Typography>
            {isSecondColHasInvoiceCount ? (
              <Typography variant={variants} component={'p'} color={'gray.800'}>
                {secondColContent !== '-' ? secondColContent : <SkeltonBar marginIndex="2px 0 0" />}
              </Typography>
            ) : (
              <Typography variant="h5Bold" component={'p'} color={'gray.800'}>
                {secondColContent !== '-' ? (
                  secondColContent
                ) : (
                  <SkeltonBar marginIndex="0 0 14px" />
                )}
              </Typography>
            )}
          </SecondCol>
        </WrapperContainer>
      </WrapperDiv>
    </ShadowCard>
  );
};
