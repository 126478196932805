import React, { FC, InputHTMLAttributes, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { FormInputWrapper, FormInputContainer, ErrorText } from './FileInput.style';
import {
  AccordionArrow,
  UploadFileIcon,
  UploadIcon,
  CameraIcon,
  PictureIcon,
} from 'src/components/base/core/Media/Icon/Icons/Icons';
import { Typography } from '@mui/material';

export type InputType = 'file';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  htmlFor?: string;
  error?: any;
  maxWidth?: string;
  isActive?: boolean;
  readOnly?: boolean;
  isRequired?: boolean;
  isCaptureButton?: boolean;
  isReplace?: boolean;
  fileString?: string;
  isFront?: boolean;
  documentType?: any;
  fileValue?: any;
  onChange?: any;
}

export const FileInput: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      htmlFor,
      error,
      maxWidth = '100%',
      inputMode = 'text',
      isActive,
      readOnly,
      isRequired = false,
      isCaptureButton = false,
      isReplace,
      fileString,
      isFront = true,
      documentType,
      fileValue,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const handleFrontBackText = isFront
      ? t('idDocument.step3.frontWord')
      : t('idDocument.step3.backWord');
    const handleUploadorReplaceText = isReplace
      ? t('idDocument.step3.replaceWord')
      : isCaptureButton
      ? t('idDocument.step3.takeWord')
      : t('idDocument.step3.uploadWord');

    const handleLabelText = () => {
      let labelText;

      if (documentType === 'Passport' && isCaptureButton) {
        labelText = `${handleUploadorReplaceText} ${t('idDocument.step3.photoWord')}`;
      } else if (documentType !== 'Passport' && !isCaptureButton) {
        labelText = `${handleUploadorReplaceText} ${handleFrontBackText}`;
      } else {
        labelText = `${handleUploadorReplaceText} ${handleFrontBackText} ${t(
          'idDocument.step3.photoWord'
        )}`;
      }

      return labelText;
    };

    const handleDocumentShortCode = () => {
      let shortCode;

      if (documentType === 'Passport') {
        shortCode = 'Pass';
      } else if (documentType === 'State ID') {
        shortCode = 'SID';
      } else if (documentType === 'US Green Card') {
        shortCode = 'USGC';
      } else if (documentType === 'Driver License') {
        shortCode = 'DL';
      } else {
        shortCode = documentType ? documentType.slice(2) : '';
      }

      return shortCode;
    };

    return (
      <FormInputWrapper style={{ maxWidth: maxWidth }} className={readOnly ? 'active' : 'inActive'}>
        <FormInputContainer className={isActive ? 'active' : 'inActive'}>
          <input
            id={isFront === true ? 'front' : 'back'}
            ref={ref}
            type={'file'}
            aria-label={handleLabelText()}
            inputMode={inputMode}
            {...props}
          />
          <label htmlFor={isFront === true ? 'front' : 'back'}>
            <div className="label_left">
              <div className="left_icon_block">
                {!isCaptureButton && <UploadIcon />}
                {isCaptureButton && <CameraIcon />}
              </div>
              <Typography variant="h2Bold" color={'gray.800'}>
                {handleLabelText()}
              </Typography>
            </div>

            <div className="label_right">
              {isReplace && (
                <div className="doc_indication">
                  {!isCaptureButton && <UploadFileIcon />}
                  {isCaptureButton && <PictureIcon />}
                  <Typography
                    variant="h2Bold"
                    color={'gray.800'}
                    textTransform={isCaptureButton ? 'uppercase' : 'capitalize'}
                  >
                    {isCaptureButton && `${fileValue}`}
                    {!isCaptureButton && `${handleDocumentShortCode()}_Doc_${handleFrontBackText}`}
                  </Typography>
                </div>
              )}

              {!isReplace && (
                <div className="arrow_icon_wrapper">
                  <AccordionArrow />
                </div>
              )}
            </div>
          </label>
        </FormInputContainer>
        {error && <ErrorText>{error}</ErrorText>}
      </FormInputWrapper>
    );
  }
);
