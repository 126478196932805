import { CardWrapper, CardArrow } from './BankNavigationCards.style';
import { NaviagtionCardsProps } from 'src/models/component/base.model';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export const BankNavigationCards = ({
  children,
  isArrowVisible,
  onClickEvent,
}: NaviagtionCardsProps) => {
  return (
    <CardWrapper onClick={onClickEvent}>
      <div className="content_block">{children}</div>
      {isArrowVisible && (
        <CardArrow>
          <KeyboardArrowRightIcon color={'inherit'} />
        </CardArrow>
      )}
    </CardWrapper>
  );
};
