import { ShadowCard, AfterBottomRow } from 'src/components/base/core';
import { Box, Typography } from '@mui/material';
import { PayupIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import { InvoiceDetailProps } from 'src/models/component/base.model';
import { useTranslation } from 'react-i18next';
import {
  getApprovalsStatusSorting,
  handlePayModeDisplay,
} from 'src/shared/utilities/dataConvertions';

export const InvoiceDetailAfterBottom = ({ invoiceDetails, tabType }: InvoiceDetailProps) => {
  const { t } = useTranslation();

  const isTransactions = tabType === 'transactions';
  const isPayModeCheck = invoiceDetails?.method === 'check';

  return (
    <ShadowCard padding={4} marginBottom={4}>
      <Box marginBottom={4}>
        <Typography variant="caption1" color={'gray.500'}>
          {t('invoiceSummary.bottom.activity')}
        </Typography>
      </Box>

      <Box display={'flex'} flexDirection={'column'}>
        <AfterBottomRow className="content icon_available">
          <Box className="content_left">
            <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
              {t('invoiceSummary.bottom.origin')}
            </Typography>
          </Box>
          <Box className="content_right">
            <span className="payup_icon">
              <PayupIcon />
            </span>
            <Typography variant="h2Bold" component={'p'} color={'gray.500'}>
              {t('invoiceSummary.bottom.payUp')}
            </Typography>
          </Box>
        </AfterBottomRow>

        <AfterBottomRow className="content">
          <Box className="content_left">
            <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
              {isPayModeCheck
                ? t('invoiceSummary.bottom.recipientName')
                : t('invoiceSummary.bottom.destination')}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography variant="h2Bold" component={'p'} color={'gray.500'}>
              {isPayModeCheck
                ? invoiceDetails?.check_recipient_name ?? '----'
                : invoiceDetails?.to_account_nickname ?? '----'}
            </Typography>
          </Box>
        </AfterBottomRow>

        {!isPayModeCheck && (
          <AfterBottomRow className="content">
            <Box className="content_left">
              <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
                {t('invoiceSummary.bottom.account')}
              </Typography>
            </Box>
            <Box className="content_right">
              {invoiceDetails?.bank_account_last4 ||
              invoiceDetails?.details?.bank_account_last4 ||
              invoiceDetails?.details?.to_account_detail ? (
                <Typography variant="h2Bold" component={'p'} color={'gray.500'}>
                  <span>• • • • •</span>{' '}
                  {invoiceDetails?.bank_account_last4 ??
                    invoiceDetails?.details?.bank_account_last4 ??
                    invoiceDetails?.details?.to_account_detail}
                </Typography>
              ) : (
                <Typography variant="h2Bold" component={'p'} color={'gray.500'}>
                  ----
                </Typography>
              )}
            </Box>
          </AfterBottomRow>
        )}

        {!isPayModeCheck && (
          <AfterBottomRow className="content">
            <Box className="content_left">
              <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
                {t('invoiceSummary.bottom.routing')}
              </Typography>
            </Box>
            <Box className="content_right">
              {invoiceDetails?.routing_number || invoiceDetails?.details?.routing_number ? (
                <Typography variant="h2Bold" component={'p'} color={'gray.500'}>
                  {invoiceDetails?.routing_number ?? invoiceDetails?.details?.routing_number}
                </Typography>
              ) : (
                <Typography variant="h2Bold" component={'p'} color={'gray.500'}>
                  ----
                </Typography>
              )}
            </Box>
          </AfterBottomRow>
        )}

        {isPayModeCheck && (
          <AfterBottomRow className="content">
            <Box className="content_left">
              <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
                {t('invoiceSummary.bottom.recipientAddress')}
              </Typography>
            </Box>
            <Box className="content_right">
              <Typography variant="h2Bold" component={'p'} color={'gray.500'}>
                {invoiceDetails?.check_recipient_address ??
                  invoiceDetails?.details?.check_recipient_address ??
                  '----'}
              </Typography>
            </Box>
          </AfterBottomRow>
        )}

        <AfterBottomRow className="content have_two_row">
          <Box className="content_left">
            <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
              {t('invoiceSummary.bottom.method')}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography
              variant="h2Bold"
              component={'p'}
              color={'gray.500'}
              marginBottom={1}
              textAlign={'right'}
            >
              {handlePayModeDisplay(invoiceDetails?.method) ??
                handlePayModeDisplay(invoiceDetails?.pay_mode)}
            </Typography>
            <Typography variant="h1" component={'p'} color={'gray.500'} textAlign={'right'}>
              (
              {invoiceDetails?.method === 'debit_card'
                ? `1-60 mins`
                : invoiceDetails?.method === 'check'
                ? `30-60 days`
                : `(1-2 ${t('invoiceSummary.bottom.daysText')})`}
              )
            </Typography>
          </Box>
        </AfterBottomRow>

        <AfterBottomRow className="content">
          <Box className="content_left">
            <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
              {isPayModeCheck
                ? t('invoiceSummary.bottom.checkNumber')
                : t('invoiceSummary.bottom.transactionID')}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography variant="h2Bold" component={'p'} color={'gray.500'}>
              {isPayModeCheck
                ? invoiceDetails?.check_number ?? invoiceDetails?.details?.check_number ?? '----'
                : invoiceDetails?.trace_number ?? invoiceDetails?.details?.trace_number ?? '----'}
            </Typography>
          </Box>
        </AfterBottomRow>

        {isPayModeCheck && (
          <AfterBottomRow className="content">
            <Box className="content_left">
              <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
                {t('invoiceSummary.bottom.checkStatus')}
              </Typography>
            </Box>
            <Box className="content_right">
              <Typography variant="h2Bold" component={'p'} color={'gray.500'}>
                {getApprovalsStatusSorting(
                  undefined,
                  'check',
                  invoiceDetails?.check_status ?? invoiceDetails?.details?.check_status ?? '---'
                )}
              </Typography>
            </Box>
          </AfterBottomRow>
        )}

        <AfterBottomRow className="content">
          <Box className="content_left">
            <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
              {t('invoiceSummary.bottom.batchNumber')}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography variant="h2Bold" component={'p'} color={'gray.500'}>
              {invoiceDetails?.batch_number ?? invoiceDetails?.details?.batch_number ?? '----'}
            </Typography>
          </Box>
        </AfterBottomRow>
      </Box>
    </ShadowCard>
  );
};
