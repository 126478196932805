import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Wrapper } from 'src/components/base/core';
import { FromVerifyRepresentSubmition } from './FromVerifyRepresentSubmition/FromVerifyRepresentSubmition';
import { FormSSNSubmition } from './FormSSNSubmition/FormSSNSubmition';
import { useGetAccountQuery } from 'src/services/account/accountServiceApi';
import { routeType } from 'src/helpers/containers/helperRoutePreference';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';
import { FormHomeAddressSubmition } from './FormHomeAddressSubmition/FormHomeAddressSubmition';

export const businessAddressInit = {
  city: '',
  line1: '',
  line2: '',
  postal_code: '',
  state: '',
};

type LocationState = {
  edit?: boolean;
  isBack?: boolean;
  backFrom?: string;
  step?: string;
  isFromFinance?: boolean;
  isFromAccountSuccessScreen: boolean;
};

export const BusinessVerifyOwnershipContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isForword, setIsForword] = useState('');
  const { data } = useGetAccountQuery({ count: 5 }, { refetchOnMountOrArgChange: true });
  const [updatePreferenceOnServer] = handlePreferences();

  const initalState = {
    step1: false,
    step2: false,
  };

  const [stepVisibility, setStepVisibility]: any = useState(initalState);

  const { backFrom, step, isFromFinance, isFromAccountSuccessScreen } =
    location?.state != null
      ? (location?.state as LocationState)
      : { backFrom: '', step: 'step1', isFromFinance: false, isFromAccountSuccessScreen: false };

  useEffect(() => {
    if (data && data?.company) {
      if (isForword === '') {
        if (isFromFinance) {
          switch (step) {
            case 'step1':
              setStepVisibility({ ...initalState, step1: true });
              break;
            case 'step2':
              setStepVisibility({ ...initalState, step2: true });
              break;
            case 'step3':
              setStepVisibility({ ...initalState, step3: true });
              break;
            default:
              setStepVisibility({ ...initalState, step1: true });
          }
        } else {
          switch (step) {
            case 'step1':
              setStepVisibility({ ...initalState, step1: true });
              break;
            case 'step2':
              setStepVisibility({ ...initalState, step3: true });
              break;
            default:
              setStepVisibility({ ...initalState, step1: true });
          }
        }
      }
    }
  }, [data]);

  const handleFirstStepClick = () => {
    setIsForword('step2');
    backFrom !== 'account' && updatePreferenceOnServer(routeType.BUSINESS_REP, 'step2');
    setStepVisibility({ ...stepVisibility, step1: false, step2: true, step3: false });
  };

  const handleFinalStepClick = () => {
    backFrom !== 'account' && updatePreferenceOnServer(routeType.BUSINESS_REVIEW);
    navigate('/business-review', {
      state: {
        isFromFinance: isFromFinance,
        isFromAccountSuccessScreen: isFromAccountSuccessScreen,
      },
    });
    localStorage.removeItem('set_id_modal_states');
  };

  const handleMiddleStepClick = () => {
    setIsForword('step3');
    backFrom !== 'account' && updatePreferenceOnServer(routeType.BUSINESS_REP, 'step3');
    setStepVisibility({ ...stepVisibility, step1: false, step2: false, step3: true });
  };

  const handleBackButtonClick = () => {
    if (stepVisibility.step1) {
      navigate('/sign-up', {
        state: { type: 'company' },
      });
    }
    if (stepVisibility.step2) {
      setStepVisibility({ ...initalState, step1: true });
    }
    if (stepVisibility.step3) {
      setStepVisibility({ ...initalState, step2: true });
    }
  };

  const handleBackButtonRepClick = () => {
    if (stepVisibility.step1) {
      if (isFromFinance && !isFromAccountSuccessScreen) {
        navigate('/finance', {
          state: { type: 'company', step: 'step2', backFromRepWithAccount: backFrom },
        });
      } else if (isFromFinance && isFromAccountSuccessScreen) {
        navigate('/bank-setup-success', {
          state: { type: 'company', step: 'step2', backFromRepWithAccount: backFrom },
        });
      } else {
        navigate('/business-info', {
          state: { type: 'company', step: 'step2', backFromRepWithAccount: backFrom },
        });
      }
    }
  };

  return (
    <Wrapper>
      {stepVisibility.step1 && (
        <FromVerifyRepresentSubmition
          onBackButtonClick={handleBackButtonRepClick}
          onFormSubmit={handleFirstStepClick}
          onSkipSecondStep={handleFinalStepClick}
          accountData={data}
          isFromFinance={isFromFinance}
        />
      )}

      {stepVisibility.step2 &&
        (isFromFinance ? (
          <FormHomeAddressSubmition
            onBackButtonClick={handleBackButtonClick}
            onFormSubmit={handleMiddleStepClick}
            accountData={data}
          />
        ) : (
          <FormSSNSubmition
            onBackButtonClick={handleBackButtonClick}
            onFormSubmit={handleFinalStepClick}
            accountData={data}
          />
        ))}

      {stepVisibility.step3 && (
        <FormSSNSubmition
          onBackButtonClick={handleBackButtonClick}
          onFormSubmit={handleFinalStepClick}
          accountData={data}
          isFromFinance={isFromFinance}
        />
      )}
    </Wrapper>
  );
};
