import { maskedInput } from './inputHandlers';
import { ResultObject } from './interface';

export default function dtoToFormValues(setValue: Function, data: ResultObject) {
  if (!data) {
    return;
  }
  setValue('first_name', data.individual?.first_name ?? '');
  setValue('last_name', data.individual?.last_name ?? '');
  setValue('business_type', data.business_type);
  setValue('id', data.id);
  setValue('email', data.individual.email ?? '');
  setValue('phone_number', maskedInput(data.individual.phone?.replace('+1', '')) ?? '');
  const bp = data.business_profile;
  setValue('inputDescription', bp.product_description ?? '');
  setValue('inputBusinessName', bp.name ?? '');
  setValue('inputMCC', bp.mcc ?? '');
  const address = data.individual?.address;
  setValue('line1', address?.line1 ?? '');
  setValue('line2', address?.line2 ?? '');
  setValue('city', address?.city ?? '');
  setValue('postal_code', address?.postal_code ?? '');
  setValue('zipCode', address?.postal_code ?? '');
  setValue('state', address?.state ?? '');
  setValue('percentOwnership', data?.individual?.relationShip?.percent_ownership ?? '');
  // @ts-ignore
  setValue('companyFront', data.individual?.verification?.document?.front ?? '');
  // @ts-ignore
  setValue('companyBack', data.individual?.verification?.document?.back ?? '');
  if (data.business_type === 'company') {
    setValue('inputCompanyName', data.company.name ?? '');
    setValue('isRep', data.individual.relationShip.representative);
    setValue('ownership', data.individual.relationShip.executive ? 'executive' : 'owner');
    const companyAddress = data.company.address;
    setValue('inputOwnerTitle', data.individual.relationShip.title ?? '');
    setValue('inputCompanyPhone', maskedInput(data.company.phone?.replace('+1', '')) ?? '');
    setValue('inputComCity', companyAddress.city ?? '');
    setValue('inputComLine2', companyAddress.line2 ?? '');
    setValue('inputComLine1', companyAddress.line1 ?? '');
    setValue('inputComPostal', companyAddress.postal_code ?? '');
    setValue('inputComState', companyAddress.state ?? '');
    // @ts-ignore
    setValue('companyFront', data.company?.verification?.document?.front ?? '');
    // @ts-ignore
    setValue('companyBack', data.company?.verification?.document?.back ?? '');
  }
}

export function dtoToFormValuesBusinessAddress(setValue: Function, data: ResultObject) {
  if (!data) {
    return;
  }
  setValue('first_name', data.individual?.first_name ?? '');
  setValue('last_name', data.individual?.last_name ?? '');
  setValue('business_type', data.business_type);
  setValue('id', data.id);
  setValue('email', data.individual.email ?? '');
  setValue('phone_number', maskedInput(data.individual.phone?.replace('+1', '')) ?? '');
  const bp = data.business_profile;
  setValue('inputDescription', bp.product_description ?? '');
  setValue('inputBusinessName', bp.name ?? '');
  setValue('inputMCC', bp.mcc ?? '');
  const address = data.individual?.address;
  setValue('line1Individual', address?.line1 ?? '');
  setValue('line2Individual', address?.line2 ?? '');
  setValue('cityIndividual', address?.city ?? '');
  setValue('postal_codeIndividual', address?.postal_code ?? '');
  setValue('zipCodeIndividual', address?.postal_code ?? '');
  setValue('stateIndividual', address?.state ?? '');
  setValue('percentOwnership', data?.individual?.relationShip?.percent_ownership ?? '');
  if (data.business_type === 'company') {
    setValue('inputCompanyName', data.company.name ?? '');
    setValue('isRep', data.individual.relationShip.representative);
    setValue('ownership', data.individual.relationShip.executive ? 'executive' : 'owner');
    const companyAddress = data.company.address;
    setValue('inputOwnerTitle', data.individual.relationShip.title ?? '');
    setValue('inputCompanyPhone', maskedInput(data.company.phone?.replace('+1', '')) ?? '');
    setValue('city', companyAddress.city ?? '');
    setValue('line2', companyAddress.line2 ?? '');
    setValue('line1', companyAddress.line1 ?? '');
    setValue('postal_code', companyAddress.postal_code ?? '');
    setValue('state', companyAddress.state ?? '');
  }
}

export function dtoToBusinessRepFormValues(
  getValue: Function,
  setValue: Function,
  data: ResultObject
) {
  if (!data) {
    return;
  }
  !getValue('first_name') && setValue('first_name', data.individual?.first_name ?? '');
  !getValue('last_name') && setValue('last_name', data.individual?.last_name ?? '');
  !getValue('business_type') && setValue('business_type', data.business_type);
  !getValue('id') && setValue('id', data.id);
  !getValue('email') && setValue('email', data.individual.email ?? '');
  if (!getValue('phone_number')) {
    if (data.individual?.phone) {
      setValue('phone_number', maskedInput(data.individual.phone?.replace('+1', '')));
    } else {
      setValue('phone_number', maskedInput(data.company?.phone?.replace('+1', '')) ?? '');
    }
  }
  const bp = data.business_profile;
  if (data.business_type === 'company') {
    !getValue('inputCompanyName') && setValue('inputCompanyName', data.company.name ?? '');
    !getValue('isRep') && setValue('isRep', data.individual.relationShip.representative);
    setValue('ownership', data.individual.relationShip.executive ? 'executive' : 'owner');
    setValue('percentOwnership', data?.individual?.relationShip?.percent_ownership ?? '');
    const companyAddress = data.company.address;
    !getValue('inputOwnerTitle') &&
      setValue('inputOwnerTitle', data.individual.relationShip.title ?? '');
    !getValue('inputCompanyPhone') &&
      setValue('inputCompanyPhone', maskedInput(data.company.phone?.replace('+1', '')) ?? '');
    !getValue('inputComCity') && setValue('inputComCity', companyAddress.city ?? '');
    !getValue('inputComLine2') && setValue('inputComLine2', companyAddress.line2 ?? '');
    !getValue('inputComLine1') && setValue('inputComLine1', companyAddress.line1 ?? '');
    !getValue('inputComPostal') && setValue('inputComPostal', companyAddress.postal_code ?? '');
    !getValue('inputComState') && setValue('inputComState', companyAddress.state ?? '');
    if (!getValue('companyFront')) {
      // @ts-ignore
      setValue('companyFront', data.company?.verification?.document?.front ?? '');
    }
    if (!getValue('companyBack')) {
      // @ts-ignore
      setValue('companyBack', data.company?.verification?.document?.back ?? '');
    }
    !getValue('structure') && setValue('structure', data.company.structure ?? '');
    !getValue('company_structure') && setValue('company_structure', data.company_structure ?? '');
  }
}

export function dtoToBusinessInforFormValue(
  getValue: Function,
  setValue: Function,
  data: ResultObject
) {
  if (!data) {
    return;
  }
  !getValue('first_name') && setValue('first_name', data.individual?.first_name ?? '');
  !getValue('last_name') && setValue('last_name', data.individual?.last_name ?? '');
  !getValue('business_type') && setValue('business_type', data.business_type);
  !getValue('id') && setValue('id', data.id);
  !getValue('email') && setValue('email', data.individual.email ?? '');
  if (!getValue('phone_number')) {
    if (data.individual?.phone) {
      setValue('phone_number', maskedInput(data.individual.phone?.replace('+1', '')));
    } else {
      setValue('phone_number', maskedInput(data.company?.phone?.replace('+1', '')) ?? '');
    }
  }
}

export function dtoToBusinessOwnershipRepFormValues(
  getValue: Function,
  setValue: Function,
  data: ResultObject
) {
  if (!data) {
    return;
  }
  !getValue('first_name') && setValue('first_name', data.individual?.first_name ?? '');
  !getValue('last_name') && setValue('last_name', data.individual?.last_name ?? '');
  !getValue('business_type') && setValue('business_type', data.business_type);
  !getValue('id') && setValue('id', data.id);
  !getValue('email') && setValue('email', data.individual.email ?? '');
  if (!getValue('phone_number')) {
    if (data.individual?.phone) {
      setValue('phone_number', maskedInput(data.individual.phone?.replace('+1', '')));
    } else {
      setValue('phone_number', maskedInput(data.company?.phone?.replace('+1', '')) ?? '');
    }
  }
  const bp = data.business_profile;
  if (data.business_type === 'company') {
    !getValue('inputCompanyName') && setValue('inputCompanyName', data.company.name ?? '');
    const companyAddress = data.company.address;
    !getValue('inputCompanyPhone') &&
      setValue('inputCompanyPhone', maskedInput(data.company.phone?.replace('+1', '')) ?? '');
    !getValue('inputComCity') && setValue('inputComCity', companyAddress.city ?? '');
    !getValue('inputComLine2') && setValue('inputComLine2', companyAddress.line2 ?? '');
    !getValue('inputComLine1') && setValue('inputComLine1', companyAddress.line1 ?? '');
    !getValue('inputComPostal') && setValue('inputComPostal', companyAddress.postal_code ?? '');
    !getValue('inputComState') && setValue('inputComState', companyAddress.state ?? '');
    !getValue('company_structure') && setValue('company_structure', data.company_structure ?? '');
  }
}

export function dtoToFormValuesVerifySSN(setValue: Function, data: ResultObject) {
  if (!data) {
    return;
  }
  setValue('id', data.id);
  // @ts-ignore
  setValue('front', data.individual?.verification?.document?.front ?? '');
  // @ts-ignore
  setValue('back', data.individual?.verification?.document?.back ?? '');
}

export function dtoToBusinessPersonalDetailsEditFormValues(
  getValue: Function,
  setValue: Function,
  data: ResultObject,
  isPersonalAddreesProvided: boolean = false
) {
  if (!data) {
    return;
  }
  !getValue('first_name') && setValue('first_name', data.individual?.first_name ?? '');
  !getValue('last_name') && setValue('last_name', data.individual?.last_name ?? '');
  !getValue('business_type') && setValue('business_type', data.business_type);
  !getValue('id') && setValue('id', data.id);
  !getValue('email') && setValue('email', data.individual.email ?? '');

  if (!getValue('phone_number')) {
    if (data.individual?.phone) {
      setValue('phone_number', maskedInput(data.individual.phone?.replace('+1', '')));
    } else {
      setValue('phone_number', maskedInput(data.company?.phone?.replace('+1', '')) ?? '');
    }
  }

  const bp = data.business_profile;
  if (data.business_type === 'company') {
    !getValue('isRep') && setValue('isRep', data.individual.relationShip.representative);
    setValue('percentOwnership', data?.individual?.relationShip?.percent_ownership ?? '');
    !getValue('company_structure') && setValue('company_structure', data.company_structure ?? '');
  }
  // @ts-ignore
  !getValue('front') && setValue('front', data.individual?.verification?.document?.front ?? '');
  // @ts-ignore
  !getValue('back') && setValue('back', data.individual?.verification?.document?.back ?? '');

  if (isPersonalAddreesProvided) {
    const address = data.individual?.address;
    setValue('line1', address?.line1 ?? '');
    setValue('line2', address?.line2 ?? '');
    setValue('city', address?.city ?? '');
    setValue('postal_code', address?.postal_code ?? '');
    setValue('zipCode', address?.postal_code ?? '');
    setValue('state', address?.state ?? '');
  }
}

export function dtoToRecipentFormValues(setValue: Function, data: ResultObject) {
  if (!data) {
    return;
  }
  setValue('line1', data?.stripe_account_metadata?.check_recipient_details_address ?? '');
  setValue('line2', data?.stripe_account_metadata?.check_recipient_details_address_2 ?? '');
  setValue('city', data?.stripe_account_metadata?.check_recipient_details_city ?? '');
  setValue('postal_code', data?.stripe_account_metadata?.check_recipient_details_zip ?? '');
  setValue('state', data?.stripe_account_metadata?.check_recipient_details_state ?? '');
  setValue('name', data?.stripe_account_metadata?.check_recipient_details_name ?? '');
}
