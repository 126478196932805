import React from 'react';
import { Icon } from '../../..';
import { TooltipWrapper } from './TooltipInfo.style';

export interface TooltipInfoProps {
  children?: React.ReactNode;
  aligned?: 'right' | 'left' | 'mRight' | 'mLeft' | 'topLeft';
}

export const TooltipInfo = ({
  children = 'Is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown',
  aligned = 'right',
}: TooltipInfoProps) => {
  return (
    <TooltipWrapper className={aligned}>
      <div className="tooltip-icon">
        <Icon iconType="InfoIcon" />
      </div>
      <div className="tooltip-content">{children}</div>
    </TooltipWrapper>
  );
};
