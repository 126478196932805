import { Box, Typography } from '@mui/material';
import { useCurrencyFormatWithoutSymbol } from 'src/hooks/useCurrencyFormat';

export interface IMainBalanceSummary {
  currentBalance?: string | number;
  balanceSummaryTitle?: string;
}

export const MainBalanceSummary = ({
  currentBalance,
  balanceSummaryTitle,
}: IMainBalanceSummary) => {
  return (
    <Box display={'flex'} flexDirection={'column'} marginTop={15}>
      <Typography component="p" variant="h1" color={'gray.500'} marginBottom={1} fontWeight={500}>
        {balanceSummaryTitle}
      </Typography>
      <Box display={'flex'} marginBottom={1}>
        <Typography component="span" variant="h4ExtraBold" color={'gray.900'} fontWeight={700}>
          $
        </Typography>
        <Typography component="span" variant="h7ExtraBold" color={'gray.900'}>
          {/* 100,321.00 */}
          {currentBalance
            ? useCurrencyFormatWithoutSymbol(currentBalance)
            : useCurrencyFormatWithoutSymbol(0)}
        </Typography>
      </Box>
      {/* <Typography component="p" variant="h1" color={'gray.500'} marginBottom={1} fontWeight={500}>
        Upcoming this week
        <Typography
          component="span"
          variant="h1"
          color={'gray.900'}
          fontWeight={500}
          marginLeft={1}
        >
          + $11,270.00
        </Typography>
      </Typography> */}
    </Box>
  );
};
