import moment from 'moment';
import { searchTransByProperty } from 'src/constants/constants';
import { payoutsProps } from 'src/shared/utilities/interface';
import { invoiceAtRange } from './helperDateFilter';

export const getPaginationParams = (
  status: string,
  offsetNext: number,
  rowCount: number,
  searchText: string = '',
  sortOption?: any,
  filter?: any,
  amountRange?: any,
  dateSelected?: any,
  dateRange?: any,
  isAllSelect?: any
): payoutsProps => {
  const searchTextLength = searchText?.length >= 1;
  const selectedMinAmountRange: string = amountRange && `${amountRange.min}`;
  const selectedMaxAmountRange: string = amountRange && `${amountRange.max}`;
  let searchFilter = searchTextLength
    ? status !== 'TERMS_ACCEPTED'
      ? {
          coupa_invoice_number: { likeInsensitive: `\"%${searchText}%\"` },
          status: { equalTo: status },
          payout_amount: {
            greaterThanOrEqualTo: `\"${selectedMinAmountRange}\"`,
            lessThanOrEqualTo: `\"${selectedMaxAmountRange}\"`,
          },
        }
      : {
          coupa_invoice_number: { likeInsensitive: `\"%${searchText}%\"` },
          status: { equalTo: status },
        }
    : status !== 'TERMS_ACCEPTED'
    ? amountRange
      ? {
          status: { equalTo: status },
          payout_amount: {
            greaterThanOrEqualTo: `\"${selectedMinAmountRange}\"`,
            lessThanOrEqualTo: `\"${selectedMaxAmountRange}\"`,
          },
        }
      : {
          status: { equalTo: status },
        }
    : {
        status: { equalTo: status },
      };

  if (!selectedMinAmountRange) {
    searchFilter = searchTextLength
      ? {
          coupa_invoice_number: { likeInsensitive: `\"%${searchText}%\"` },
          status: { equalTo: status },
        }
      : {
          status: { equalTo: status },
        };
  }

  if (
    filter &&
    filter != null &&
    filter?.payout_term_ids &&
    filter?.payout_term_ids?.length > 0 &&
    !isAllSelect
  ) {
    const selectedTermIds = filter?.payout_term_ids?.map((item: any) => `"${item}"`);
    filter = { payout_term_id: { in: getOldNetToFilter(selectedTermIds) } };
  }

  let filterResult = filter ? { ...searchFilter, ...filter } : searchFilter;

  if (dateSelected && dateSelected !== undefined && dateSelected?.length !== 0) {
    const invoiceAt = invoiceAtRange(dateSelected);
    if (invoiceAt.greaterThan !== '' && invoiceAt.lessThan !== '') {
      const invFilter = { invoiced_at: invoiceAt };
      filterResult = { ...filterResult, ...invFilter };
    }
  }
  if (dateRange && dateSelected == null && dateRange?.length > 0) {
    const [start, end] = dateRange;
    const invoicedAtRange = {
      lessThan: moment(end).format(),
      greaterThan: moment(start).format(),
    };
    const invFilterRange = { invoiced_at: invoicedAtRange };
    filterResult = { ...filterResult, ...invFilterRange };
  }
  if ((filterResult && filterResult?.payout_term_ids?.length === 0) || isAllSelect) {
    delete filterResult.payout_term_ids;
  }

  return {
    filter: filterResult,
    hideLoader: true,
    limit: rowCount,
    pageOffset: rowCount * offsetNext,
    first: rowCount,
    sort: sortOption,
  };
};

export const getTranPaginationParams = (
  offsetNext: number,
  rowCount: number,
  searchText: string = '',
  sortOption?: any,
  filter?: any,
  amountRange?: any,
  dateSelected?: any,
  dateRange?: any
): payoutsProps => {
  const searchTextLength = searchText?.length >= 1;
  const selectedMinAmountRange: string = amountRange && `${amountRange.min}`;
  const selectedMaxAmountRange: string = amountRange && `${amountRange.max}`;
  let searchFilter = searchTextLength
    ? {
        [searchTransByProperty ? 'property' : 'payup_transfer_number_searchable']:
          searchTransByProperty
            ? { name: { likeInsensitive: `\"%${searchText}%\"` } }
            : { likeInsensitive: `\"%${searchText}%\"` },
      }
    : amountRange
    ? {
        paid_amount: {
          greaterThanOrEqualTo: `\"${selectedMinAmountRange}\"`,
          lessThanOrEqualTo: `\"${selectedMaxAmountRange}\"`,
        },
      }
    : {};

  let filterResult = filter ? { ...searchFilter, ...filter } : searchFilter;

  if (dateSelected && dateSelected !== undefined && dateSelected?.length !== 0) {
    const paidAt = invoiceAtRange(dateSelected);
    if (paidAt.greaterThan !== '' && paidAt.lessThan !== '') {
      const invFilter = { paid_at: paidAt };
      filterResult = { ...filterResult, ...invFilter };
    }
  }
  if (dateRange && dateSelected == null && dateRange?.length > 0) {
    const [start, end] = dateRange;
    const paidAtRange = {
      lessThan: moment(end).format(),
      greaterThan: moment(start).format(),
    };
    const invFilterRange = { paid_at: paidAtRange };
    filterResult = { ...filterResult, ...invFilterRange };
  }

  delete filterResult.payout_term_ids;

  filterResult = Object.keys(filterResult).length > 0 ? filterResult : undefined;

  return {
    filter: filterResult,
    hideLoader: true,
    limit: rowCount,
    pageOffset: rowCount * offsetNext,
    first: rowCount,
    sort: sortOption,
  };
};

/**
 * get old and new terms for filter
 * @param terms
 */
const getOldNetToFilter = (terms: any) => {
  const allTerms = [...terms, ...terms.map((item: any) => item.replace('net', 'old_net'))];
  return allTerms;
};
