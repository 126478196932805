import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { Modal } from '../Modal/Modal';
import { HeadingSummaryBlock } from '../../CommonNestedBlocks/ContentBlocks/HeadingSummaryBlock';
import { TermsText } from '../../NotesBlocks/TermsText/TermsText';
import { UploadDocumentCards } from '../../Cards/UploadDocumentCards/UploadDocumentCards';

import {
  helperIdDocumentUpload,
  getDocumentData,
} from '../../../../../helpers/containers/helperIdDocumentModal';
import { Images } from 'src/shared/utilities/images';
import {
  StepOneContentContainer,
  StepTwoContentContainer,
  DocumentType,
  StepThreeContentContainer,
  SelectedDocumentContent,
  SelectedDocumentContainer,
  SelectedDocumentHeader,
  BackIcon,
} from './IdDocumentModal.style';
import { TermsSelection } from './TermsSelection/TermsSelection';
import { FileInput } from './FileInput/FileInput';
import { ContentContainerBottom } from '../../CommonNestedStyles/FormWrapper.style';
import { ManualLoader } from '../../OtherUtilities/Loader/ManualLoader';
import { VerifyImageContentBlock } from '../../CommonNestedBlocks/VerifyImageContentBlock/VerifyImageContentBlock';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { commonSpacing, theme } from 'src/shared/theme/theme';

export interface IdDocumentModalProps {
  register?: any;
  setValue?: any;
  setOpenIdModal?: any;
  verificationData?: any;
  isSubmittedValuesHeaderVisible?: boolean;
  setHandleVisible?: any;
  isFormButtonBlock?: boolean;
  onClickIdModalOpen?: () => void;
  openIdModal?: any;
  closeIdModalEvent?: () => void;
}

export const IdDocumentModal = ({
  register,
  setValue,
  setOpenIdModal,
  verificationData,
  isSubmittedValuesHeaderVisible = true,
  isFormButtonBlock = false,
  onClickIdModalOpen,
  openIdModal,
  closeIdModalEvent,
}: IdDocumentModalProps) => {
  const { t } = useTranslation();

  const [
    modalStates,
    setModalStates,
    handleCloseModal,
    stepsVisibility,
    setStepsVisibility,
    handleHeadingContent,
    onDocumentItemClick,
    onUploadButtonClick,
    onCameraButtonClick,
    onFileChange,
    isPassportBackButtonVisible,
    isFrontImageVisible,
    onTermsConfirmationDocument,
    onTermsConfirmationName,
    isSubmitButtonVisibility,
    handleSubmitButtonEvent,
    uploadLoaderEnabled,
    onBackButtonClick,
  ] = helperIdDocumentUpload();

  // get the content __
  const { heading, content } = handleHeadingContent();

  // get document data __
  const [documentData] = getDocumentData();
  const elmentWrapper: any = document.getElementById('wrapperContainer');

  // handle submit __

  const handlePopUpSubmit = () => {
    handleSubmitButtonEvent && handleSubmitButtonEvent();
    setValue('front', modalStates.front, { shouldValidate: true });
    setValue('back', modalStates.back, { shouldValidate: false });
    localStorage.setItem('set_id_modal_states', JSON.stringify(modalStates));
    elmentWrapper.style.overflowY = 'auto';
  };

  // handle close event __

  const handleCloseEvent = () => {
    handleCloseModal && handleCloseModal();
    closeIdModalEvent && closeIdModalEvent();
    setValue('front', modalStates.front, { shouldValidate: true });
    setValue('back', modalStates.back, { shouldValidate: false });
    elmentWrapper.style.overflowY = 'auto';
  };

  return (
    <>
      <Box
        className={
          isFormButtonBlock ? 'addition_element_padding_bottom' : 'addition_element_padding_none'
        }
        sx={{
          paddingX: isFormButtonBlock ? 4 : 0,
          [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
            minHeight: isFormButtonBlock ? 320 + commonSpacing.desktopPaddingB * 4 : 1,
          },
        }}
      >
        {isFormButtonBlock && (
          <>
            {!modalStates.selctedContainerVisible && (
              <VerifyImageContentBlock
                heading={t('headingContent.statusses.verificationFailed.heading')}
                headingAlignment="center"
                content={t('headingContent.statusses.verificationFailed.content')}
                contentAlignment="center"
                imagePath={Images.VERIFICATION_FAILED}
              />
            )}
            {modalStates.selctedContainerVisible && (
              <VerifyImageContentBlock
                content={t('headingContent.statusses.idDocUploadDone.content')}
                contentAlignment="left"
                heading={t('headingContent.statusses.idDocUploadDone.heading')}
                headingAlignment="left"
                imagePath={Images.DOC_UPLOAD_DONE}
              />
            )}
          </>
        )}
        {modalStates.selctedContainerVisible && openIdModal && (
          <SelectedDocumentContainer>
            {isSubmittedValuesHeaderVisible && (
              <SelectedDocumentHeader>
                <Typography variant="caption1" color={'gray.600'} textTransform="uppercase">
                  {t('captions.submittedText')}
                </Typography>
                <DocumentType>{modalStates.selectedDocumentType}</DocumentType>
              </SelectedDocumentHeader>
            )}
            <SelectedDocumentContent>
              <FileInput
                isFront={true}
                {...register('front')}
                onChange={onFileChange}
                accept="image/*"
                capture={modalStates.uploadMode === 'camera'}
                fileValue={modalStates.front ? `IMG ${modalStates.front.slice(-5)}` : `IMG`}
                isCaptureButton={modalStates.uploadMode === 'camera'}
                documentType={modalStates.selectedDocumentType}
                isReplace={true}
              />
              {modalStates.back !== undefined && (
                <FileInput
                  isFront={false}
                  {...register('back')}
                  onChange={onFileChange}
                  accept="image/*"
                  capture={modalStates.uploadMode === 'camera'}
                  fileValue={modalStates.back ? `IMG ${modalStates.back.slice(-5)}` : `IMG`}
                  isCaptureButton={modalStates.uploadMode === 'camera'}
                  documentType={modalStates.selectedDocumentType}
                  isReplace={true}
                />
              )}
            </SelectedDocumentContent>
          </SelectedDocumentContainer>
        )}
      </Box>

      {modalStates.modalVisbility && openIdModal && (
        <Modal onCloseEvent={handleCloseEvent}>
          {uploadLoaderEnabled && <ManualLoader />}

          <Box sx={{ marginBottom: 6 }} component={'div'}>
            <Box display={'flex'} alignItems={'center'}>
              <BackIcon onClick={stepsVisibility.step1 ? handleCloseEvent : onBackButtonClick}>
                <ArrowBackIosNewIcon sx={{ height: 18 }} />
              </BackIcon>
              {stepsVisibility.step3 && (
                <DocumentType>{modalStates.selectedDocumentType}</DocumentType>
              )}
            </Box>
            {(stepsVisibility.step1 || stepsVisibility.step2) && (
              <Box
                sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 3 }}
                component={'div'}
              >
                <img src={Images.SUBMIT_DOCUMENT} alt="Submit Document" height={124} />
              </Box>
            )}
            <HeadingSummaryBlock
              heading={heading}
              headingAlignment="left"
              content={content}
              contentAlignment="left"
            />
          </Box>

          {stepsVisibility.step1 && (
            <StepOneContentContainer>
              <Box sx={{ marginBottom: 6 }}>
                {documentData &&
                  documentData.map((data: any, index: any) => (
                    <UploadDocumentCards
                      key={index}
                      title={data}
                      dataLabel={data}
                      onClickEvent={onDocumentItemClick}
                    />
                  ))}
              </Box>
              <TermsText
                mainContent={t('noticeBlocks.termsBlock.heading')}
                subContent={t('noticeBlocks.termsBlock.content')}
              />
            </StepOneContentContainer>
          )}

          {stepsVisibility.step2 && (
            <StepTwoContentContainer>
              <UploadDocumentCards
                title={t('idDocument.step2.uploadButtonText')}
                dataLabel={'upload'}
                onClickEvent={onUploadButtonClick}
                iconType="UploadIcon"
              />
              <UploadDocumentCards
                title={t('idDocument.step2.photoButtonText')}
                dataLabel={'camera'}
                onClickEvent={onCameraButtonClick}
                iconType="CameraIcon"
                isOnlyDesktop
              />
            </StepTwoContentContainer>
          )}

          {stepsVisibility.step3 && (
            <>
              <StepThreeContentContainer>
                <Box>
                  <FileInput
                    isFront={true}
                    {...register('front')}
                    onChange={onFileChange}
                    accept="image/*"
                    capture={modalStates.uploadMode === 'camera'}
                    fileValue={modalStates.front ? `IMG ${modalStates.front.slice(-5)}` : `IMG`}
                    isCaptureButton={modalStates.uploadMode === 'camera'}
                    documentType={modalStates.selectedDocumentType}
                    isReplace={modalStates.front !== undefined}
                  />

                  {!isPassportBackButtonVisible && modalStates.front !== undefined && (
                    <FileInput
                      isFront={false}
                      {...register('back')}
                      onChange={onFileChange}
                      accept="image/*"
                      capture={modalStates.uploadMode === 'camera'}
                      fileValue={modalStates.back ? `IMG ${modalStates.back.slice(-5)}` : `IMG`}
                      isCaptureButton={modalStates.uploadMode === 'camera'}
                      documentType={modalStates.selectedDocumentType}
                      isReplace={modalStates.back !== undefined}
                    />
                  )}
                </Box>
                <Box component={'div'} marginBottom={8} className="upload_view_image">
                  {isFrontImageVisible && <img src={Images.PLACEHOLDER_FRONT_PHOTO} alt="front" />}
                  {!isPassportBackButtonVisible && modalStates.front !== undefined && (
                    <img src={Images.PLACEHOLDER_BACK_PHOTO} alt="back" />
                  )}
                </Box>
                <TermsSelection
                  handleTermConfirmationDocument={onTermsConfirmationDocument}
                  handleTermConfirmationName={onTermsConfirmationName}
                />
              </StepThreeContentContainer>
              <Box sx={{ display: 'flex', position: 'relative' }}>
                <Button
                  variant="containedLarge"
                  color="primary"
                  type="button"
                  fullWidth
                  sx={{ overflow: 'hidden' }}
                  disabled={!isSubmitButtonVisibility}
                  onClick={handlePopUpSubmit}
                >
                  {t('buttonTexts.submitButton')}
                </Button>
              </Box>
            </>
          )}
        </Modal>
      )}

      {isFormButtonBlock && (
        <ContentContainerBottom className={'no_note no_fullscreen'}>
          {!modalStates ||
            (!modalStates.selctedContainerVisible && (
              <Button
                variant="containedLarge"
                color="primary"
                type="button"
                fullWidth
                onClick={onClickIdModalOpen}
                sx={{ overflow: 'hidden' }}
              >
                {t('buttonTexts.continueWithIdButton')}
              </Button>
            ))}
          {modalStates.selctedContainerVisible && (
            <Button
              variant="containedLarge"
              color="primary"
              type="submit"
              fullWidth
              sx={{ overflow: 'hidden' }}
            >
              {t('buttonTexts.submitButton')}
            </Button>
          )}
        </ContentContainerBottom>
      )}
    </>
  );
};
