import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  ContentContainerBottom,
  DivWrapper,
  Header,
  HeadingSummaryBlock,
  ResendCode,
  Wrapper,
} from 'src/components/base/core';
import { EmailVerifiedRequestIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import { commonSpacing, theme } from 'src/shared/theme/theme';
import { logout } from 'src/shared/utilities/logout';
import { useTranslation } from 'react-i18next';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

export interface IVerifyEmailContainer {
  handleResendEmail?: () => void;
  onProceedClick?: () => void;
  isButtonDisabled?: boolean;
  isLoading?: boolean;
}

export const UnVerifyEmailContainer = ({
  handleResendEmail,
  onProceedClick,
  isButtonDisabled,
  isLoading,
}: IVerifyEmailContainer) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isOnNativeApp = useSelector((state: RootState) => state.appState);

  return (
    <>
      {isLoading && <ManualLoader />}
      <Wrapper isFullScreen>
        <DivWrapper className="no_fullscreen">
          <Box
            component={'div'}
            className={'container_content'}
            sx={{
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                minHeight: 400 + commonSpacing.desktopPaddingB * 4,
              },
            }}
          >
            <Header
              isBackButtonVisible={!isOnNativeApp}
              onBackButtonClick={() => logout(navigate)}
              paddingX={0}
            />
            <Box
              sx={{
                [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                  marginBottom: '60px',
                },
                [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                  marginBottom: '103px',
                },
              }}
            >
              <HeadingSummaryBlock
                heading={t('headingContent.unVerifiedEmail.heading')}
                content={t('headingContent.unVerifiedEmail.content')}
                headingAlignment="left"
                mobileHeadingAlignment="left"
                contentAlignment="left"
                mobileContentAlignment="left"
              />
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                marginBottom: 3,
              }}
            >
              <Box
                sx={{
                  width: '105px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block',
                  [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                    marginBottom: '20px',
                  },
                  [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                    marginBottom: '70px',
                  },
                }}
              >
                <EmailVerifiedRequestIcon />
              </Box>

              <ResendCode isEmailVerification onClick={handleResendEmail} />
            </Box>
          </Box>

          <ContentContainerBottom className={'no_fullscreen'}>
            <Button
              variant="containedLarge"
              color="primary"
              type="submit"
              onClick={onProceedClick}
              fullWidth
              disabled={isButtonDisabled}
              sx={{ overflow: 'hidden' }}
            >
              {t('buttonTexts.continueButton')}
            </Button>
          </ContentContainerBottom>
        </DivWrapper>
      </Wrapper>
    </>
  );
};
