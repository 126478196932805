import moment from 'moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { pre_fill_params } from 'src/shared/content/content';
import { accountIndividual } from 'src/models/account/account.service.mapper';
import { useUpdateAccountMutation } from 'src/services/account/accountServiceApi';
import { DOB } from 'src/shared/utilities/interface';

export const handleIdentityVerification = (accountData: any, setAccountData: any) => {
  const dispatch = useDispatch();
  const [updateAccount] = useUpdateAccountMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorState, setErrorState] = useState('');

  const isNullDOB = (dob: DOB) => {
    return !dob.day || !dob.month || !dob.year;
  };

  const appLogin = async (data: any, onFormSubmit: any) => {
    try {
      setIsLoading(true);
      setErrorState('');
      if (accountData) {
        if (accountData?.individual?.dob?.day === null) {
          delete data.dob;
        } else {
          data.dob = moment(data?.dob).format('MMDDYYYY');
        }
        delete data.id;
        let updateFormData = null;
        if (accountData?.charges_enabled) {
          updateFormData = {
            ...data,
            phone_number: data?.phone_number?.replace(/[^0-9]/g, ''),
            inputDescription: pre_fill_params.description,
            inputMCC: pre_fill_params.mcc,
            url: pre_fill_params.url,
            tos_shown_and_accepted: true,
          };
        } else {
          updateFormData = {
            ...data,
            phone_number: data?.phone_number?.replace(/[^0-9]/g, ''),
            inputDescription: pre_fill_params.description,
            inputMCC: pre_fill_params.mcc,
            url: pre_fill_params.url,
          };
        }

        const dataModel = accountIndividual(updateFormData, true, false);
        const accountUpdateResponse = await updateAccount(dataModel);
        // @ts-ignore
        if (accountUpdateResponse?.data) {
          // @ts-ignore
          setAccountData(accountUpdateResponse?.data);
          onFormSubmit();
          // navigate('/identity-verification');
          // @ts-ignore
        } else {
          // @ts-ignore
          const errorBody = accountUpdateResponse?.error?.data;
          const responseError = errorBody?.message ?? errorBody;
          if (responseError && typeof responseError === 'string') {
            setErrorState(responseError);
          }
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return [appLogin, isLoading, isNullDOB, errorState, setErrorState];
};

export const handleIdentityVerificationDOB = (accountData: any, setAccountData: any) => {
  const [updateAccount] = useUpdateAccountMutation();
  const [serverSideErrors, setServerSideErrors]: any = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const isNullDOB = (dob: DOB) => {
    return !dob.day || !dob.month || !dob.year;
  };

  const submitForm = async (data: any, onFormSubmit: any) => {
    try {
      setIsLoading(true);
      if (data) {
        delete data.id;
        data.dob = moment(data?.dob).format('MMDDYYYY');
        const updateFormData = {
          ...data,
          phone_number: data?.phone_number?.replace(/[^0-9]/g, ''),
          inputDescription: pre_fill_params.description,
          inputMCC: pre_fill_params.mcc,
          url: pre_fill_params.url,
        };
        const dataModel = accountIndividual(updateFormData, true, false);
        const accountUpdateResponse = await updateAccount(dataModel);
        // @ts-ignore
        if (accountUpdateResponse?.data) {
          // @ts-ignore
          setAccountData(accountUpdateResponse?.data);
          onFormSubmit();
          // navigate('/identity-verification');
          // @ts-ignore
        } else {
          // @ts-ignore
          const responseError = accountUpdateResponse?.error?.data;
          if (responseError) {
            // @ts-ignore
            setServerSideErrors(responseError.message);
          }
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeHandlerDateInput = (val: any, setValue: any, setErrorManualDateType: any) => {
    if (val) {
      const dateMoment = moment(val);
      const toDate = moment(val).toDate();
      const dateWithFormat = moment(toDate).format('MM/DD/yyyy');
      if (
        !dateMoment?.isValid() ||
        !toDate?.getFullYear() ||
        toDate?.getFullYear() < 1900 ||
        (toDate?.getFullYear() > new Date().getFullYear() && val.length !== 10)
      ) {
        setErrorManualDateType(false);
      } else {
        const dateWithFormat = moment(toDate).format('MM/DD/yyyy');
        const years = moment().diff(dateWithFormat, 'years', true);
        if (years < 18) {
          val.length === 10 ? setErrorManualDateType(true) : setErrorManualDateType(false);
        } else {
          val.length < 9 ? setErrorManualDateType(false) : setErrorManualDateType(false);
        }
      }
    }
    setValue('dob', val); // Set value
  };

  const isNot18 = (val: any) => {
    const dateMoment = moment(val);
    const toDate = moment(val).toDate();
    const dateWithFormat = moment(toDate).format('MM/DD/yyyy');
    const years = moment().diff(dateWithFormat, 'years', true);
    if (years < 18) {
      return val.length === 10 ? false : true;
    }
    return true;
  };

  const isValidDOB = (value: any) => {
    const dateMoment = moment(value);
    return dateMoment?.isValid();
  };

  return [
    submitForm,
    serverSideErrors,
    isLoading,
    isNullDOB,
    onChangeHandlerDateInput,
    isNot18,
    isValidDOB,
  ];
};

export const handleIdentityVerificationAddress = (
  accountData: any,
  setAccountData: any,
  setAddressError?: any
) => {
  const [updateAccount] = useUpdateAccountMutation();
  const [isLoading, setIsLoading] = useState(false);

  const isNullDOB = (dob: DOB) => {
    return !dob.day || !dob.month || !dob.year;
  };

  const submitForm = async (data: any, onFormSubmit: any) => {
    try {
      setIsLoading(true);
      if (accountData) {
        const dob = accountData?.individual?.dob;
        const formattedDOB: any = moment(new Date(dob.year, dob.month - 1, dob.day));
        data.dob = moment(formattedDOB).format('MMDDYYYY');
        // delete data.id;
        const updateFormData = {
          ...data,
          inputDescription: pre_fill_params.description,
          inputMCC: pre_fill_params.mcc,
          url: pre_fill_params.url,
        };
        const dataModel = accountIndividual(updateFormData, true, false);
        const accountUpdateResponse = await updateAccount(dataModel);
        // @ts-ignore
        if (accountUpdateResponse?.data) {
          // @ts-ignore
          setAccountData(accountUpdateResponse?.data);
          // @ts-ignore
          const checkAddressError = accountUpdateResponse?.data?.errors;

          const errorItem: any = checkAddressError.filter(
            (object: any) =>
              object.code === 'invalid_address_po_boxes_disallowed' &&
              object.requirement === 'individual.address.line1'
          );

          if (errorItem && errorItem.length === 1) {
            setAddressError(errorItem[0]?.reason);
          } else {
            setAddressError('');
            localStorage.removeItem('isBack');
            setTimeout(function () {
              onFormSubmit();
            }, 1000);
          }

          // @ts-ignore
        } else {
          // @ts-ignore
          const responseError = accountUpdateResponse?.error;
          if (responseError) {
            // @ts-ignore
            setErrorState('errors');
            setAddressError(
              responseError?.data?.error ?? responseError?.data?.message ?? responseError?.error
            );
          }
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return [submitForm, isLoading, isNullDOB];
};
