import { Typography } from '@mui/material';
import { InfoRoundedIcon, LockedIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';
import { NotTextWrapper } from './NoteText.style';
import { useTranslation } from 'react-i18next';

export interface NoteTextProps {
  iconType?: 'InfoRoundedIcon' | 'LockedIcon';
  children?: React.ReactNode;
  isBorder?: boolean;
  isIconVisible?: boolean;
}

export const NoteContent = () => {
  const { t } = useTranslation();
  return (
    <>
      {`${t('noticeBlocks.bankdetailSelection.heading')} ${t(
        'noticeBlocks.bankdetailSelection.emailPrefix'
      )}`}{' '}
      <a href="mailto:support@payup.com">support@payup.com</a>{' '}
      {t('noticeBlocks.bankdetailSelection.callPrefix')}{' '}
      <a href="tel:5126674081<">(512) 667 4081</a>
    </>
  );
};

export const NoteText = ({
  iconType = 'InfoRoundedIcon',
  children,
  isBorder = true,
  isIconVisible = true,
}: NoteTextProps) => {
  const handleIconTypes = (_iconType: string | undefined) => {
    switch (_iconType) {
      case 'InfoRoundedIcon':
        return <InfoRoundedIcon />;
      case 'LockedIcon':
        return <LockedIcon />;
      default:
        break;
    }
  };

  return (
    <NotTextWrapper className={`note ${!isBorder ? 'no_border' : 'is_border'}`}>
      <>
        {isIconVisible && <div className="icon_wrapper">{handleIconTypes(iconType)}</div>}
        <Typography variant="h1" color={'gray.800'}>
          {children ? children : <NoteContent />}
        </Typography>
      </>
    </NotTextWrapper>
  );
};
