import moment from 'moment';
import { useEffect, useState } from 'react';
import { accountCompany } from 'src/models/account/account.service.mapper';
import {
  useGetAccountQuery,
  useUpdateAccountMutation,
} from 'src/services/account/accountServiceApi';
import { pre_fill_params } from 'src/shared/content/content';
import { IMCompany, IMResult } from 'src/models/api/account';

export const helperBusinessOwnership = (setAddressError?: any) => {
  const { data, isFetching } = useGetAccountQuery(
    { count: 5 },
    { refetchOnMountOrArgChange: true }
  );

  const [updateAccount] = useUpdateAccountMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [accountCompanyData, setAccountData]: any = useState();

  useEffect(() => {
    if (data) {
      setAccountData(data);
    }
  }, [data]);

  const appSubmit = async (data: any, onFormSubmit: any, isBack: any, navigate: any) => {
    try {
      setIsLoading(true);
      if (accountCompanyData) {
        if (accountCompanyData?.individual?.dob?.day === null) {
          delete data.dob;
        }
        const updateFormData = {
          ...data,
          inputDescription: pre_fill_params.description,
          inputMCC: pre_fill_params.mcc,
          url: pre_fill_params.url,
          line1: data.line1,
          line2: data.line2,
          city: data.city,
          postal_code: data.postal_code,
          state: data.state,
          inputCompanyPhone: data?.inputCompanyPhone?.replace(/[^0-9]/g, ''),
          phone_number: data?.phone?.replace(/[^0-9]/g, ''),
        };

        const updateObj = accountCompany(updateFormData, false, false);
        const accountUpdateResponse: any = await updateAccount(updateObj);
        // @ts-ignore
        if (accountUpdateResponse?.data) {
          let addressError = false;
          const checkAddressError = accountUpdateResponse?.data?.errors;

          checkAddressError.findIndex((object: any) => {
            if (object.code === 'invalid_address_po_boxes_disallowed') {
              setAddressError(object.reason);
              addressError = true;
            } else {
              setAddressError('');
              addressError = false;
            }
          });

          if (!addressError) {
            localStorage.removeItem('isBack');
            if (isBack) {
              navigate('/business-review');
            } else {
              onFormSubmit();
            }
          }
        } else {
          // @ts-ignore
          if (accountUpdateResponse?.error) {
            // @ts-ignore
            setErrorState('errors');
            setAddressError(
              accountUpdateResponse?.error?.data?.error ??
                accountUpdateResponse?.error?.data?.message ??
                accountUpdateResponse?.error?.error
            );
          }
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return [appSubmit, isLoading, accountCompanyData];
};

/**
 * Handle Business Representative SSN detail giving scrreen in KYC flow
 * @returns
 */

export const handleBusinessRepSSN = (result: any) => {
  const [updateAccount] = useUpdateAccountMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [serverSideErrors, setServerSideErrors] = useState('');

  const [accountData, setAccountData]: any = useState();
  useEffect(() => {
    if (result) {
      setAccountData(result);
    }
  }, [result]);

  const appSubmit = async (
    data: any,
    onFormSubmit: any,
    enableSSNUpdate: any,
    openIdModal: any,
    isFromFinance: boolean = false,
    isDocumentUploaded?: boolean
  ) => {
    try {
      setIsLoading(true);
      setServerSideErrors('');

      if (accountData) {
        data.dob = data.dob = moment(data?.dob).format('MMDDYYYY');

        if (!enableSSNUpdate) {
          onFormSubmit();
          return;
        }

        if (!openIdModal || isFromFinance) {
          const getIDNumber = data.idNumber;
          const getLastFour = getIDNumber.slice(-4);
          data.idNumber = getLastFour;
        }

        const getDOB = data.dob;

        let individual: any = {
          dob: {
            day: getDOB.toString().substring(2, 4),
            month: getDOB.toString().substring(0, 2),
            year: getDOB.toString().substring(4, 8),
          },
        };

        if (data.idNumber) {
          individual = {
            ...individual,
            ssn_last_4: data.idNumber,
          };
        }

        if (data.front && !isDocumentUploaded) {
          individual = {
            ...individual,
            verification: {
              document: {
                back: data.back,
                front: data.front,
              },
            },
          };
        }

        let updatedData: any = {
          individual: individual,
        };

        if (accountData?.charges_enabled) {
          updatedData = {
            ...updatedData,
            tos_shown_and_accepted: true,
          };
        }

        const accountUpdateResponse = await updateAccount(updatedData);
        // @ts-ignore
        if (accountUpdateResponse?.data) {
          onFormSubmit();
          setServerSideErrors('');
        } else {
          // @ts-ignore
          const responseError = accountUpdateResponse?.error;
          if (responseError) {
            // @ts-ignore
            setServerSideErrors(
              responseError?.data?.error ?? responseError?.data?.message ?? responseError?.error
            );
          }
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
    setIsLoading(false);
  };

  return [appSubmit, isLoading, serverSideErrors, setServerSideErrors];
};

/**
 * Handle Business Representative details in KYC flow
 * @returns
 */
export const handleBusinessRepOwnership = (accountData: IMResult) => {
  const [updateAccount] = useUpdateAccountMutation(); // RTK query update account call

  const [serverSideErrors, setServerSideErrors] = useState(''); // initialize the state  to set the serversde error __
  const [isLoading, setIsLoading] = useState(false); // initialize the loading state, it's use used to handle n the Api call

  const onChangeHandlerPhone = (val: any, setValue: any) => {
    setValue('phone_number', val); // Set value
  };

  // handle business representative form submition __
  const appSubmit = async (data: any, onFormSubmit: any) => {
    try {
      setIsLoading(true);
      setServerSideErrors('');

      if (accountData) {
        let individualData: any = {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone: data?.phone_number?.replace(/[^0-9]/g, ''),
          relationship: {
            representative: data.representative,
            executive: data.executive,
            owner: data.owner,
            percent_ownership: data.percent_ownership,
            director: data.director,
            title: pre_fill_params.title,
          },
        };

        const updateFormData: any = {
          individual: individualData,
          company_structure: data.company_structure,
        };

        const accountUpdateResponse = await updateAccount(updateFormData);

        // @ts-ignore
        if (accountUpdateResponse) {
          // @ts-ignore
          const responseError = accountUpdateResponse?.error;

          if (responseError) {
            // @ts-ignore
            setServerSideErrors(
              responseError?.data?.error ?? responseError?.data?.message ?? responseError?.error
            );
          } else {
            onFormSubmit();
            setServerSideErrors('');
          }
        }
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return [appSubmit, isLoading, onChangeHandlerPhone, serverSideErrors, setServerSideErrors];
};

/**
 * Handle Business Representative details edit in Review screen
 * @returns
 */
export const handleBusinessPersonalInfoEdit = () => {
  const [updateAccount] = useUpdateAccountMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [serverSideErrors, setServerSideErrors] = useState('');

  const onChangeHandlerPhone = (val: any, setValue: any, btnSubmitted: any) => {
    setValue('phone_number', val, { shouldValidate: btnSubmitted && true }); // Set value
  };

  const onChangeHandlerDateInput = (val: any, setValue: any, setErrorManualDateType: any) => {
    if (val) {
      const dateMoment = moment(val);
      const toDate = moment(val).toDate();
      if (
        !dateMoment?.isValid() ||
        !toDate?.getFullYear() ||
        toDate?.getFullYear() < 1900 ||
        (toDate?.getFullYear() > new Date().getFullYear() && val.length !== 10)
      ) {
        setErrorManualDateType(false);
      } else {
        const dateWithFormat = moment(toDate).format('MM/DD/yyyy');
        const years = moment().diff(dateWithFormat, 'years', true);
        if (years < 18) {
          val.length === 10 ? setErrorManualDateType(true) : setErrorManualDateType(false);
        } else {
          val.length < 9 ? setErrorManualDateType(false) : setErrorManualDateType(false);
        }
      }
    }
    setValue('dob', val); // Set value
  };

  const isNot18 = (val: any) => {
    const toDate = moment(val).toDate();
    const dateWithFormat = moment(toDate).format('MM/DD/yyyy');
    const years = moment().diff(dateWithFormat, 'years', true);
    if (years < 18) {
      return val.length === 10 ? false : true;
    }
    return true;
  };

  const appLogin = async (
    data: any,
    onCancelButton: any,
    isSettingAccountVerified?: boolean,
    isSkipDocumentValuesUpdate?: boolean,
    isTosShownAcceptedFalse: boolean = true,
    isSettingsVerified: boolean = false
  ) => {
    setIsLoading(true);

    try {
      setServerSideErrors('');
      let accountResponse: any = null;

      const dob = data?.dob;
      data.dob = moment(dob).format('MMDDYYYY');

      const getDOB = data.dob;
      const isUerIsExecutiveandNotAccountVerified =
        data.ownership === 'executive' && !isSettingAccountVerified;

      const skipDocDataUpdate = isSettingAccountVerified || isSkipDocumentValuesUpdate;

      let individualData: any = {
        phone: data?.phone_number?.replace(/[^0-9]/g, ''),
      };

      if (!isSettingsVerified) {
        individualData = {
          ...individualData,
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          relationship: {
            representative: data.representative,
            executive: data.executive,
            owner: data.owner,
            percent_ownership: data.percent_ownership,
            director: data.director,
            title: pre_fill_params.title,
          },
        };

        if (data.idNumber) {
          individualData = {
            ...individualData,
            ssn_last_4: data.idNumber,
          };
        }

        if (data.dob) {
          individualData = {
            ...individualData,
            dob: {
              day: getDOB.toString().substring(2, 4),
              month: getDOB.toString().substring(0, 2),
              year: getDOB.toString().substring(4, 8),
            },
          };
        }

        if (data.front && !skipDocDataUpdate) {
          individualData = {
            ...individualData,
            verification: {
              document: {
                back: data.back,
                front: data.front,
              },
            },
          };
        }

        if (data.line1 || data.line2 || data.city || data.state || data.postal_code) {
          individualData = {
            ...individualData,
            address: {
              city: data.city,
              line1: data.line1,
              line2: data.line2,
              postal_code: data.postal_code,
              state: data.state,
            },
          };
        }
      }

      let updateFormData: any = {
        individual: individualData,
        company_structure: data.company_structure,
      };

      if (!isTosShownAcceptedFalse) {
        updateFormData = {
          ...updateFormData,
          tos_shown_and_accepted: true,
        };
      }

      accountResponse = await updateAccount(updateFormData);

      // @ts-ignore
      if (accountResponse?.data) {
        setServerSideErrors('');
        onCancelButton();

        // @ts-ignore
        // setUpdateData(accountResponse?.data);
      } else {
        // @ts-ignore
        const responseError =
          // @ts-ignore
          accountResponse?.error?.data?.message ?? accountResponse?.error?.data.error;
        if (responseError && typeof responseError === 'string') {
          setServerSideErrors(responseError);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const isValidDOB = (value: any) => {
    const dateMoment = moment(value);
    return dateMoment?.isValid();
  };

  return [
    serverSideErrors,
    setServerSideErrors,
    appLogin,
    isLoading,
    onChangeHandlerPhone,
    onChangeHandlerDateInput,
    isNot18,
    isValidDOB,
  ];
};

/**
 * OLD FLOW code
 * in Front End handled with the reduce_the_business_flow_fields (reduceTheBusinessFlowFields) featured flag
 * Buesiness Review -> Company details Edit flow without reducing the Representative details
 * @returns
 */
export const handleBusinessDetailsReviewEdit = (result: any) => {
  const [updateAccount] = useUpdateAccountMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [serverSideErrors, setServerSideErrors] = useState('');

  const appLogin = async (data: any, onCancelButton: any, setVerifyCodeContainerVisible?: any) => {
    try {
      setIsLoading(true);
      setServerSideErrors('');

      let accountResponse = null;

      let companyObj: IMCompany = {
        name: data.inputCompanyName,
        phone: data.inputCompanyPhone?.replace(/[^0-9]/g, ''),
        address: {
          city: data.city,
          line1: data.line1,
          line2: data.line2,
          postal_code: data.postal_code,
          state: data.state,
        },
      };

      // if user updaed the EIN
      if (data.inputTaxId) {
        companyObj = {
          ...companyObj,
          tax_id: data.inputTaxId?.replace(/[^0-9]/g, ''),
        };
      }

      const updateFormData = {
        business_type: 'company',
        company: companyObj,
      };

      accountResponse = await updateAccount(updateFormData);

      // @ts-ignore
      if (accountResponse?.data) {
        // @ts-ignore
        const checkAddressError = accountResponse?.data?.errors;

        const errorItem: any = checkAddressError.filter(
          (object: any) =>
            object.code === 'invalid_address_po_boxes_disallowed' &&
            object.requirement === 'company.address.line1'
        );

        if (errorItem && errorItem.length === 1) {
          setServerSideErrors(errorItem[0]?.reason);
        } else {
          setServerSideErrors('');
          onCancelButton();
        }
      } else {
        // @ts-ignore
        const responseError = accountResponse?.error?.data?.message;
        if (responseError && typeof responseError === 'string') {
          setServerSideErrors(responseError);
          setVerifyCodeContainerVisible && setVerifyCodeContainerVisible(false);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setVerifyCodeContainerVisible && setVerifyCodeContainerVisible(false);
    }
  };

  const onChangeHandlerPhone = (val: any, getValues: any, setValue: any) => {
    setValue('inputCompanyPhone', val); // Set value
  };

  const onChangeHandlerTax = (val: any, getValues: any, setValue: any) => {
    setValue('inputTaxId', val); // Set value
  };

  return [
    serverSideErrors,
    setServerSideErrors,
    appLogin,
    isLoading,
    onChangeHandlerPhone,
    onChangeHandlerTax,
  ];
};
