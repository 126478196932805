import { ShadowCard } from 'src/components/base/core';
import { Box, Typography } from '@mui/material';
import { InvoiceDetailProps } from 'src/models/component/base.model';
import { MiddleRow, ReceiptLink } from './InvoiceDetailMiddle.style';
import {
  dateFormatMMMSpaceDD,
  dateFormatWithTimeZoneForEarning,
  getCalculatedPayArrivalDate,
  timeFormatWithoutTimeZoneForEarning,
} from 'src/shared/utilities/dob';
import { useTranslation } from 'react-i18next';
import { InvoiceTimelineItem } from '../InvoiceTimelineItem/InvoiceTimelineItem';
import { TimelineNoteItem } from '../TimelineNoteItem/TimelineNoteItem';
import moment from 'moment';

export const InvoiceDetailMiddle = ({
  invoiceDetails,
  payoutId,
  isDatesPendingInvoice = false,
  tabType,
}: InvoiceDetailProps) => {
  const { t } = useTranslation();

  const isApproved = invoiceDetails?.approved_at_date ?? false;
  const dateForApproved =
    invoiceDetails?.approved_at_date ?? invoiceDetails?.estimated_approved_date;
  const dateForTriggeredAndOnTheWay =
    invoiceDetails?.paid_at_date ?? invoiceDetails?.estimated_payout_date;
  const dateForArrived =
    invoiceDetails?.payment_arrival_estimated_date ?? dateForTriggeredAndOnTheWay;
  const dateForFundsRecived = invoiceDetails?.received_from_customer ?? null;
  const dateForArrivedForInstantPayment = invoiceDetails?.actual_arrival_date ?? null;

  const isPayModeCheck = invoiceDetails?.method === 'check' ? true : false;
  const dateForCheckMailed = invoiceDetails?.check_mailed_on ?? null;
  const dateForCheckPaid = invoiceDetails?.check_paid_on ?? null;

  const termArr = invoiceDetails?.netTermChangeTimeline;

  const isTransactions = tabType === 'transactions';
  const isInstantPayment = invoiceDetails?.method === 'debit_card';

  const invoiceTermsTimeline = () => {
    const getDescriptionText = (causedByType: string) => {
      if (causedByType === 'DELINQUENT_PROPERTY') {
        return t('invoiceSummary.middle.delinquentTermChangeDescription', {
          customer: invoiceDetails?.customer ?? t('invoiceSummary.middle.yourCustomer'),
        });
      }
      if (causedByType === 'APPROVAL_DEADLINE_MISSED') {
        return t('invoiceSummary.middle.approvalDeadlineMissedDescription');
      }

      return undefined;
    };

    const getTitleText = (termText: string, causedByType: string) => {
      if (causedByType === 'APPROVAL_DEADLINE_MISSED') {
        return t('invoiceSummary.middle.approvalDeadlineMissed');
      }

      return `${t('invoiceSummary.middle.termsUpdatedTo')} ${termText}`;
    };

    return termArr?.map((item: any, i: number) => (
      <TimelineNoteItem
        key={i}
        itemName={getTitleText(item?.changedTerm, item?.changeReason)}
        description={getDescriptionText(item?.changeReason)}
        dateText={dateFormatWithTimeZoneForEarning(item?.updatedAt)}
        timeText={timeFormatWithoutTimeZoneForEarning(item?.updatedAt)}
        hasNext={isApproved || i < termArr?.length - 1}
        position={i}
      />
    ));
  };

  if (isTransactions) {
    const EstdPayArrivalOrSettlementDate =
      invoiceDetails?.details?.payment_arrival_estimated_date ??
      invoiceDetails?.expected_settlement_date;

    const payArrivalDate = getCalculatedPayArrivalDate(EstdPayArrivalOrSettlementDate);
    const isPayArrivalDatePassed = moment(payArrivalDate).isBefore(moment());

    return (
      <ShadowCard padding={4} marginBottom={4}>
        <MiddleRow className="header">
          <Box className="header_left">
            <Typography variant="h1Bold" color={'gray.500'}>
              {t('invoiceSummary.timeLineText')}
            </Typography>
          </Box>
        </MiddleRow>
        <InvoiceTimelineItem
          itemName={t('invoiceSummary.middle.paymentTrigger')}
          dateText={dateFormatWithTimeZoneForEarning(invoiceDetails?.paid_at)}
          timeText={timeFormatWithoutTimeZoneForEarning(invoiceDetails?.paid_at)}
          position={3}
          isActive={invoiceDetails?.paid_at ?? false}
          hideConnector
        />
        <InvoiceTimelineItem
          itemName={t('invoiceSummary.middle.paymentOntheWay')}
          dateText={
            isPayModeCheck && dateForCheckMailed
              ? `${dateFormatWithTimeZoneForEarning(
                  dateForCheckMailed
                )} - ${timeFormatWithoutTimeZoneForEarning(dateForCheckMailed)}`
              : `${dateFormatMMMSpaceDD(
                  invoiceDetails?.paid_at
                )} - ${dateFormatWithTimeZoneForEarning(payArrivalDate)}`
          }
          timeText={isPayArrivalDatePassed ? undefined : t('invoiceSummary.middle.estimated')}
          position={4}
          isActive={invoiceDetails?.paid_at ?? payArrivalDate ?? false}
        />

        <InvoiceTimelineItem
          itemName={t('invoiceSummary.middle.paymentArrived')}
          dateText={
            isPayModeCheck && dateForCheckPaid
              ? dateFormatWithTimeZoneForEarning(dateForCheckPaid)
              : dateFormatWithTimeZoneForEarning(payArrivalDate ?? invoiceDetails?.paid_at)
          }
          timeText={
            isPayModeCheck && dateForCheckPaid
              ? timeFormatWithoutTimeZoneForEarning(dateForCheckPaid)
              : payArrivalDate
              ? timeFormatWithoutTimeZoneForEarning(payArrivalDate)
              : undefined
          }
          position={5}
          isActive={isPayArrivalDatePassed}
        />

        {invoiceDetails?.paid_at && (
          <a href={`/api/transfer/${payoutId}/receipt`} target="_blank">
            <ReceiptLink>{t('invoiceSummary.middle.viewTranReceipt')}</ReceiptLink>
          </a>
        )}
      </ShadowCard>
    );
  }

  return (
    <ShadowCard padding={4} marginBottom={4}>
      <MiddleRow className="header">
        <Box className="header_left">
          <Typography variant="h1Bold" color={'gray.500'}>
            {t('invoiceSummary.timeLineText')}
          </Typography>
        </Box>
      </MiddleRow>
      <InvoiceTimelineItem
        itemName={t('invoiceSummary.middle.invSubmittedText')}
        dateText={dateFormatWithTimeZoneForEarning(invoiceDetails?.invoice_submitted_date)}
        timeText={timeFormatWithoutTimeZoneForEarning(invoiceDetails?.invoice_submitted_date)}
        position={1}
        isActive={invoiceDetails?.invoice_submitted_date ?? false}
      />
      {invoiceTermsTimeline()}
      <InvoiceTimelineItem
        itemName={t('invoiceSummary.middle.invApproveText')}
        dateText={
          isDatesPendingInvoice && !isApproved
            ? t('invoiceSummary.middle.waitingForCustomerToApprove')
            : dateFormatWithTimeZoneForEarning(dateForApproved)
        }
        timeText={
          isDatesPendingInvoice && !isApproved
            ? undefined
            : timeFormatWithoutTimeZoneForEarning(dateForApproved)
        }
        position={2}
        isActive={isApproved}
        hideConnector={termArr?.length > 0}
      />
      {/**
       * Timeline for Funds Recived for Instant payment
       */}
      {isInstantPayment && invoiceDetails?.received_from_customer && (
        <InvoiceTimelineItem
          itemName={t('invoiceSummary.middle.fundsRecivedFromCustomer')}
          dateText={
            isDatesPendingInvoice
              ? t('invoiceSummary.middle.pendingCustomerPay')
              : dateFormatWithTimeZoneForEarning(dateForFundsRecived)
          }
          timeText={
            isDatesPendingInvoice
              ? undefined
              : timeFormatWithoutTimeZoneForEarning(dateForFundsRecived)
          }
          position={3}
          isActive={invoiceDetails?.received_from_customer ?? false}
        />
      )}

      <InvoiceTimelineItem
        itemName={t('invoiceSummary.middle.paymentTrigger')}
        dateText={
          isDatesPendingInvoice
            ? t('invoiceSummary.middle.pendingCustomerPay')
            : dateFormatWithTimeZoneForEarning(dateForTriggeredAndOnTheWay)
        }
        timeText={
          isDatesPendingInvoice
            ? undefined
            : timeFormatWithoutTimeZoneForEarning(dateForTriggeredAndOnTheWay)
        }
        position={isInstantPayment ? 4 : 3}
        isActive={invoiceDetails?.paid_at_date ?? false}
        hideConnector={isTransactions}
      />

      {/**
       * Timeline for rest of the Instant payment
       */}
      {!isInstantPayment && !isPayModeCheck && (
        <>
          <InvoiceTimelineItem
            itemName={t('invoiceSummary.middle.paymentOntheWay')}
            dateText={
              isDatesPendingInvoice
                ? t('invoiceSummary.middle.pendingCustomerPay')
                : `${dateFormatMMMSpaceDD(
                    dateForTriggeredAndOnTheWay
                  )} - ${dateFormatWithTimeZoneForEarning(dateForArrived)}`
            }
            timeText={isDatesPendingInvoice ? undefined : t('invoiceSummary.middle.estimated')}
            position={4}
            isActive={invoiceDetails?.paid_at_date ?? false}
          />
          <InvoiceTimelineItem
            itemName={t('invoiceSummary.middle.paymentArrived')}
            dateText={
              isDatesPendingInvoice
                ? t('invoiceSummary.middle.pendingCustomerPay')
                : dateFormatWithTimeZoneForEarning(dateForArrived)
            }
            timeText={
              isDatesPendingInvoice
                ? undefined
                : timeFormatWithoutTimeZoneForEarning(dateForArrived)
            }
            position={5}
            isActive={invoiceDetails?.status === 'paid'}
          />
        </>
      )}

      {isPayModeCheck && (
        <>
          <InvoiceTimelineItem
            itemName={t('invoiceSummary.middle.paymentOntheWay')}
            dateText={
              !invoiceDetails?.paid_at_date
                ? t('invoiceSummary.middle.pendingCustomerPay')
                : `${dateFormatMMMSpaceDD(
                    dateForCheckMailed ?? invoiceDetails?.paid_at_date
                  )} - ${dateFormatWithTimeZoneForEarning(dateForArrived)}`
            }
            timeText={
              !dateForCheckMailed && !invoiceDetails?.paid_at_date
                ? undefined
                : t('invoiceSummary.middle.estimated')
            }
            position={4}
            isActive={invoiceDetails?.paid_at_date ?? false}
          />
          <InvoiceTimelineItem
            itemName={t('invoiceSummary.middle.paymentArrived')}
            dateText={
              !dateForCheckPaid && !invoiceDetails?.paid_at_date
                ? t('invoiceSummary.middle.pendingCustomerPay')
                : dateFormatWithTimeZoneForEarning(dateForCheckPaid)
            }
            timeText={
              !dateForCheckPaid && !invoiceDetails?.paid_at_date
                ? undefined
                : timeFormatWithoutTimeZoneForEarning(dateForCheckPaid)
            }
            position={5}
            isActive={invoiceDetails?.status === 'paid'}
          />
        </>
      )}

      {/**
       * Timeline for Payment Arrived for Instant payment
       */}
      {isInstantPayment && invoiceDetails?.actual_arrival_date && (
        <InvoiceTimelineItem
          itemName={t('invoiceSummary.middle.paymentArrived')}
          dateText={
            isDatesPendingInvoice
              ? t('invoiceSummary.middle.pendingCustomerPay')
              : dateFormatWithTimeZoneForEarning(dateForArrivedForInstantPayment)
          }
          timeText={
            isDatesPendingInvoice
              ? undefined
              : timeFormatWithoutTimeZoneForEarning(dateForArrivedForInstantPayment)
          }
          position={5}
          isActive={invoiceDetails?.status === 'paid'}
        />
      )}

      {invoiceDetails?.status === 'paid' && (
        <a href={`/api/payout/${payoutId}/invoice`} target="_blank">
          <ReceiptLink>{t('invoiceSummary.middle.viewTranReceipt')}</ReceiptLink>
        </a>
      )}
    </ShadowCard>
  );
};
