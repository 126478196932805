import { useState } from 'react';
import classNames from 'classnames';

import {
  CardNumber,
  CardShowNumber,
  CardExpiry,
  CardName,
  CardCopy,
  CardBg,
  CardCVCnumber,
} from './PayupCard.style';

import {
  CardBGImg,
  CardDotsIcon,
  CardPayupLogo,
  CopyClipBoardIcon,
} from '../../Media/Icon/Icons/Icons';

import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import { IPayupCard } from 'src/models/component/base.model';

export const PayupCard = ({
  data,
  isCardSwipable = true,
  isNameVisible = true,
  isCardExpiryVisible = true,
  isCardShowNumberVisible = true,
  isCardCopyVisible = true,
  isCardDotsIconVisible = true,
  logoWidth = '60',
  logoHeight = '22',
  cardType = 'default',
  cardShowNumberText,
}: IPayupCard) => {
  const [showNumber, setShowNumber] = useState(false);

  const number = data && data?.number ? data?.number : 0; //1234 5678 9111 4556
  const convertToString = String(number);
  const firstSet = convertToString.slice(0, 4);
  const secondSet = convertToString.slice(4, 8);
  const thirdSet = convertToString.slice(8, 12);
  const fourthSet = convertToString.slice(12, 16);

  const setExpirtMonth =
    data && data?.exp_month
      ? data?.exp_month < 10
        ? `0${data?.exp_month}`
        : `${data?.exp_month}`
      : null;
  const setExpirtYear = data && data?.exp_year ? String(data.exp_year).slice(-2) : null;
  const setExpiryDate =
    setExpirtMonth && setExpirtYear ? `${setExpirtMonth} / ${setExpirtYear}` : null;

  const setCardHolderName = data && data?.cardholder?.name ? data?.cardholder?.name : null;

  const setCVCnumber = data && data?.cvc ? data?.cvc : null;

  const onCardNumberCopy = () => {
    // Get the text field
    const copyText: any = document.getElementById('cardNumberInput');

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);
  };

  return (
    <>
      <CardBg
        className={classNames([isCardSwipable ? 'swipe_enabled' : 'swipe_disabled', cardType])}
      >
        <CardPayupLogo width={logoWidth} height={logoHeight} />
        <CardBGImg />
        {isCardDotsIconVisible &&
          (!showNumber ? <CardDotsIcon /> : <CardCVCnumber>{setCVCnumber}</CardCVCnumber>)}
        {setCardHolderName && isNameVisible && (
          <CardName id="cardName" component={'div'}>
            {setCardHolderName}
          </CardName>
        )}
        <CardNumber id="cardNumber" component={'div'} className={cardType}>
          {!showNumber ? (
            <span className="dots firstDots">{`••••`}</span>
          ) : (
            <span className="number hidde-none firstNum">{firstSet}</span>
          )}
          {!showNumber ? (
            <span className="dots secondDots">{`••••`}</span>
          ) : (
            <span className="number hidde-none secondNum">{secondSet}</span>
          )}
          {!showNumber ? (
            <span className="dots thirdDots">{`••••`}</span>
          ) : (
            <span className="number hidde-none thirdNum">{thirdSet}</span>
          )}
          <span className="number fourthNum">{fourthSet}</span>
        </CardNumber>
        <input type="text" name="card_number" id="cardNumberInput" defaultValue={number} />
        {setExpiryDate && isCardExpiryVisible && (
          <CardExpiry id="cardExpiry" component={'div'}>
            {setExpiryDate}
          </CardExpiry>
        )}
        {isCardShowNumberVisible && (
          <CardShowNumber
            id="cardShowNumber"
            component={'div'}
            onClick={() => setShowNumber(!showNumber)}
          >
            {!showNumber ? (
              <VisibilityOffOutlinedIcon sx={{ color: '#fff', fontSize: 14 }} />
            ) : (
              <RemoveRedEyeOutlinedIcon sx={{ color: '#fff', fontSize: 14 }} />
            )}
            {cardShowNumberText}
          </CardShowNumber>
        )}
        {isCardCopyVisible && (
          <CardCopy id="cardCopy" onClick={onCardNumberCopy}>
            <CopyClipBoardIcon width={'20'} height={'20'} />
          </CardCopy>
        )}
      </CardBg>
    </>
  );
};
