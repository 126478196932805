import { styled } from '@mui/material/styles';
import { theme } from 'src/shared/theme/theme';

export const HeaderWrapper = styled('div')(
  ({ theme }) => `
  display: flex;
  width: 100%;
  position: relative;
  padding-top: ${theme.spacing(6)};
`
);

export const HeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding-bottom: 12px;

  &.active {
    padding-bottom: 12px;
    margin-bottom: 12px;
  }
`;

export const Headerheading = styled('span')(
  ({ theme }) => `
  text-transform: capitalize;
  margin-right: ${theme.spacing(1)};
  font-weight: 600;
  color: ${theme.palette.gray[800]};

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    font-size: 14px;
    line-height: 16px;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    font-size: 16px;
    line-height: 18px;
  }
`
);

export const HeadingCounter = styled('div')`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 10px;
  min-width: 28px;
  height: 16px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  background: #ebeef1;
  border-radius: 8px;
  margin-left: ${theme.spacing(4)};
  position: relative;
  top: 2px;
`;

export const SelectTag = styled('div')(
  ({ theme }) => `
  width: auto;
  position: absolute;
  top: 14px;
  font-weight: 500;
  border-radius: 15px;
  font-size: 12px;
  text-align: right;
  color: ${theme.palette.primary.main};
  cursor: pointer;
  right: 0;
  top: 26px;

  &.non_clickable {
    pointer-events: none;
  }
`
);

export const ArrowiconContainer = styled('div')(
  ({ theme }) => `
  display: inline-flex;
  margin-right: 10px;
  transition: 0.3s ease all;
  transform: rotate(0deg);
  cursor: pointer;

  svg {
    color: ${theme.palette.gray[600]};
  }

  transform: rotate(-90deg);

  &.active {
    transform: rotate(0deg);
  }
`
);

export const HeaderLeft = styled('div')(
  ({ theme }) => `
  display: flex;
  align-items: center;

  svg {
    width: 15px;
    * {
      stroke ${theme.palette.gray[600]};
    }
  }
`
);

export const TooltipInfoContainer = styled('div')`
  width: 200px;

  p {
    margin: 0 0 4px;
  }
`;
