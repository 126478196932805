import axios from 'axios';
import { TransFilterProps, batchStatssues } from 'src/shared/utilities/interface';
import config from '../config';
import { createApi } from '@reduxjs/toolkit/query/react';
import { prepareBaseQuery } from '../../shared/config/config';

export const getTransfers = async (
  filter?: TransFilterProps,
  offset: number = 0,
  first: number = 0,
  details = false,
  hideLoader = true,
  orderBy: string = 'PAID_AT_DESC',
  batchStatus?: batchStatssues
) => {
  config(hideLoader);
  localStorage.setItem('hide_loader', 'true');
  return axios
    .post('/query/transfers', {
      details: details,
      offset: offset,
      first: first,
      filter: { ...filter, status: { equalTo: JSON.stringify(batchStatus) } },
      orderBy: orderBy,
    })
    .then((res) => {
      localStorage.removeItem('hide_loader');
      return res;
    })
    .catch((error) => {
      localStorage.removeItem('hide_loader');
      return error.response.data;
    });
};

export const getTransferDetails = async (id: string, hideLoader = true) => {
  config(hideLoader);
  localStorage.setItem('hide_loader', 'true');
  return axios
    .get(`/payout/transfer-detail/${id}`)
    .then((res) => {
      localStorage.removeItem('hide_loader');
      return res;
    })
    .catch((error) => {
      localStorage.removeItem('hide_loader');
      return error.response.data;
    });
};

export const batchesApi = createApi({
  reducerPath: 'batchesApi',
  tagTypes: ['transfers'],
  baseQuery: prepareBaseQuery(false),
  endpoints: (builder) => ({
    getTransferDetails: builder.query({
      query: (id: string) => {
        return {
          url: `/payout/transfer-detail/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['transfers'],
    }),
    updatePayMode: builder.mutation({
      query: (data: any) => {
        return {
          url: '/transfer/change-pay-mode',
          method: 'POST',
          params: data,
        };
      },
      invalidatesTags: ['transfers'],
    }),
    exportBatchesCSV: builder.mutation({
      query: (data: { details: boolean; filter: any; orderBy: string; email: string }) => {
        return {
          url: '/query/transfers',
          method: 'POST',
          body: {
            details: data.details ?? false,
            offset: 0,
            first: 5000,
            filter: data.filter,
            orderBy: data.orderBy ?? 'PAID_AT_DESC',
            email_id: data.email,
          },
        };
      },
      invalidatesTags: ['transfers'],
    }),
  }),
});

export const { useGetTransferDetailsQuery, useUpdatePayModeMutation, useExportBatchesCSVMutation } =
  batchesApi;
