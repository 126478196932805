import React, { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import Qs from 'qs';

import {
  PaymentTabNav,
  EarningTabHeader,
  PaymentTableGrid,
  SearchBar,
} from '../../../components/base/core/Grids/InvoiceGridElements';
import { PaginationLoader } from '../../../components/base/core/Grids/InvoiceGridElements/PaymentGrid/PaymentTableGrid/PaymentTableGrid.style';

import { handlePaidData } from '../../../helpers/containers/helperPaidInvoices';

import GridProvider from 'src/contexts/gridContext';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { getSearchParams } from 'src/helpers/helperSearchParams';
import {
  Header,
  HeaderFixed,
  WrpperAuthorizedContainer,
  EmptyList,
  Wrapper,
} from 'src/components/base/core';
import { PAY3349 } from 'src/constants/constants';

export const EarningsPaidContainer = () => {
  // set feature flag __
  const { enableSearchFilterSorting } = useFlags();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [
    offset,
    pageCount,
    showLoader,
    searchInProgress,
    handleShowMore,
    rowCount,
    handleRowCountChange,
    savedDays,
    paidData,
    decimalAmount,
    payout_amount,
    handleSearch,
    handleDataSorting,
    handleApplyFilter,
    handleFilterOpen,
    handleFilterClose,
    handleFilterReset,
    filterStates,
    setSelectedFilterOptions,
    selectedFilterOptions,
    setFilterAmountRange,
    resetFilter,
    setSelectedDateFilterOptions,
    selectedDateFilters,
    minmumAmount,
    maximumAmount,
    searchText,
    handleResetSearch,
    onDateChange,
    selectedDateRange,
    filterAllCount,
    setSelectedDateRange,
    setAllSelect,
    isAllSelect,
    totalInvoices,
    filterStatus,
    filteringApplied,
    filterSearchParams,
    setFilteredParams,
    setResetFilter,
    enableQuerySearch,
    savedMinmumAmount,
    savedMaximumAmount,
    downloadStates,
    setDownloadStates,
  ] = handlePaidData();

  const getUrlParams = () => {
    if (location?.search) {
      return {
        ...Qs.parse(location.search.substring(1)),
        pagination: { offset, rowCount },
      };
    }

    return {
      pagination: { offset, rowCount },
    };
  };

  useEffect(() => {
    if (filteringApplied) {
      navigate({
        pathname: '/earnings/paid',
        search: `?${createSearchParams(getSearchParams(filterSearchParams))}`,
      });
    }
  }, [filterSearchParams]);

  useEffect(() => {
    if (filteringApplied) {
      localStorage.setItem('ear-min', minmumAmount);
      localStorage.setItem('ear-max', maximumAmount);
    }
  }, [filteringApplied]);

  useEffect(() => {
    navigate({
      pathname: '/earnings/paid',
      search: `?${createSearchParams(getSearchParams(getUrlParams()))}`,
    });
  }, [offset, rowCount]);

  useEffect(() => {
    if (resetFilter) {
      navigate({ pathname: '/earnings/paid' });
      setResetFilter(!resetFilter);
    }
  }, [resetFilter]);

  return (
    <>
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonVisible={false}
          isBackButtonHide
          paddingX={0}
          headerText={t('earning.headerText')}
          withCenteredHeaderText
        />
      </HeaderFixed>
      {/** Tab navigation */}
      <PaymentTabNav />
      <Wrapper isAuthorizedWrapper isTabNavigationAvailable>
        {PAY3349 ? (
          <WrpperAuthorizedContainer>
            <EmptyList
              comingSoon
              heading={t('earningEmptyState.paidTabComingSoonHeading')}
              subHeading={t('earningEmptyState.paidTabComingSoonContent1')}
              secondParagraph={t('earningEmptyState.paidTabComingSoonContent2')}
              t={t}
            />
          </WrpperAuthorizedContainer>
        ) : (
          <WrpperAuthorizedContainer>
            {/** Data grid */}
            {((paidData && paidData?.length > 0) || searchInProgress || enableQuerySearch) && (
              <>
                <EarningTabHeader
                  firstColTitle={t('inVoiceCards.total')}
                  firstColAmount={payout_amount[0]}
                  secondColTitle={t('inVoiceCards.saved')}
                  secondColContent={savedDays}
                  decimalAmount={decimalAmount}
                />
                {enableSearchFilterSorting && (
                  <SearchBar
                    onchangeHandler={handleSearch}
                    onClickEnableFilter={handleFilterOpen}
                    isSearchIconVisible={searchText === '' || searchText === undefined}
                    onResetSearch={handleResetSearch}
                    searchText={searchText}
                    filterCounterValue={filterAllCount}
                    onClickDownloadCSV={() =>
                      setDownloadStates({
                        ...downloadStates,
                        isDownloadEnable: true,
                      })
                    }
                    tabType="paid"
                    t={t}
                  />
                )}
                <GridProvider
                  accordion={{
                    activeAccordion: true,
                    activeAccordionOld: null,
                    enableAccordion: false,
                    isOldInvoice: false,
                    showMoreInprogressOld: false,
                  }}
                  filterStatus={filterStatus}
                >
                  <PaymentTableGrid
                    handleShowMore={(value: any) => handleShowMore(value)}
                    selectedRowCount={rowCount}
                    onRowCountChange={(val) => handleRowCountChange(val)}
                    dataObject={paidData}
                    paginationDisplayCondition={paidData && pageCount >= 1}
                    offset={offset}
                    pageCount={pageCount}
                    tabType="paid"
                    showLoader={showLoader}
                    onModalClose={handleFilterClose}
                    handleDataSorting={handleDataSorting}
                    onClickApplyFilter={handleApplyFilter}
                    selectedSortingOption={filterStates.sort}
                    setSelectedFilterOptions={setSelectedFilterOptions}
                    onFilterReset={handleFilterReset}
                    setFilterAmountRange={setFilterAmountRange}
                    selectedFilterOptions={selectedFilterOptions}
                    setSelectedDateFilterOptions={setSelectedDateFilterOptions}
                    selectedDateFilters={selectedDateFilters}
                    resetFilter={resetFilter}
                    minmumAmount={minmumAmount}
                    maximumAmount={maximumAmount}
                    onDateChange={onDateChange}
                    selectedDateRange={selectedDateRange}
                    isFilterEnable={filterStates && filterStates.isFilterEnable}
                    setSelectedDateRange={setSelectedDateRange}
                    setAllSelect={setAllSelect}
                    isAllSelect={isAllSelect}
                    isSelectTagVisible={false}
                    isAccordion={true}
                    accordionHeading={'Paid Invoices'}
                    isAccordionArrowVisible={false}
                    isTooltipInfoEnabled={false}
                    dataCount={totalInvoices}
                    savedMinmumAmount={savedMinmumAmount}
                    savedMaximumAmount={savedMaximumAmount}
                    iconArrowVisible={true}
                    downloadStates={downloadStates}
                    setDownloadStates={setDownloadStates}
                  />
                </GridProvider>
              </>
            )}

            {/** Loader */}
            {paidData === undefined && <PaginationLoader />}

            {/** Empty list */}
            {paidData?.length === 0 && !searchInProgress && (
              <EmptyList
                heading={t('earningEmptyState.thirdTabHeading')}
                subHeading={t('earningEmptyState.thirdTabContent')}
                tabType="paid"
                t={t}
              />
            )}
          </WrpperAuthorizedContainer>
        )}
      </Wrapper>
    </>
  );
};
