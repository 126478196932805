import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { AccountParams } from 'src/shared/utilities/interface';

import { searchVendorSchemaObj } from 'src/helpers/validationSchemas';

import { initAccount } from 'src/models/init/account.init';
import { ERROR_MESSAGES } from 'src/constants/Validation';

import { RootState } from 'src/store';

import { commonSpacing, theme } from 'src/shared/theme/theme';

import { Box, Button } from '@mui/material';
import {
  Header,
  Wrapper,
  HeadingSummaryBlock,
  FormInput,
  FormInputPhoneNumber,
  FormWrapper,
  ContentContainerBottom,
  Messages,
} from 'src/components/base/core';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { useSearchVendorMutation } from 'src/services/auth/authServiceApi';
import { SearchVendorSuccessContainer } from '../SearchVendorSuccess/SearchVendorSuccess';
import { SearchVendorFailiureContainer } from '../SearchVendorFailiure/SearchVendorFailiure';
import { getWebAuth } from 'src/shared/config/config';
import { AccountAlreadyAvailableContainer } from '../AccountAlreadyAvailable/AccountAlreadyAvailable';

export const SearchVendorContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { removeVendorCheckFunctionality, disabledTheCustomerAddition, toSearchVendorByTaxId } =
    useFlags();

  const [vendorSearch]: any = useSearchVendorMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isPayupAccountAlreadyThere, setIsPayupAccountAlreadyThere] = useState(false);
  const [isPayupPhoneAccountPresent, setIsPayupPhoneAccountPresent] = useState(false);
  const [isPayupEmailAccountPresent, setIsPayupEmailAccountPresent] = useState(false);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [responseData, setResponseData] = useState({
    customer: '',
    vendor: '',
    email: '',
    phone: '',
    inputTaxId: '',
  });
  // const [elementRef, height]:any = useGetBlockHeight();
  const serverErrors = useSelector((state: RootState) => state.serverError);
  const [btnSubmitted, setBtnSubmitted] = useState(false);
  const revieInfoSchema = yup
    .object({
      ...searchVendorSchemaObj,
      phone_number: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .min(14, ERROR_MESSAGES.PHONE_NUMBER_INVALID),
    })
    .required();

  //hook-forms
  const {
    register,
    handleSubmit,
    setValue,
    control,
    // getValues,
    trigger,
    formState: { errors },
  } = useForm<AccountParams>({
    resolver: yupResolver(revieInfoSchema),
    defaultValues: initAccount,
    mode: 'onSubmit',
  });

  const onChangeHandler = (event: any) => {
    setValue('phone_number', event, { shouldValidate: btnSubmitted && true });
  };

  const updateBindingsChange = (event: any, feild: any) => {
    setValue(feild, event.target.value);
  };

  const appSubmit = handleSubmit(async (data: any) => {
    setIsLoading(true);
    setResponseData({
      customer: data.primary_contact_name,
      vendor: data.display_name,
      email: data.email,
      phone: data.phone_number,
      inputTaxId: data?.inputTaxId,
    });

    const primaryContactName = data.primary_contact_name;
    const splitFullName = primaryContactName.split(' ');
    const firstName = splitFullName[0] ? splitFullName[0] : null;
    const lastName = splitFullName.length > 1 ? splitFullName[splitFullName.length - 1] : null;

    const setfromSuppliers = {
      firstName: firstName,
      lastName: lastName,
      businessName: data.display_name ? data.display_name : null,
      email: data.email,
      phone: data.phone_number,
      inputTaxId: data?.inputTaxId,
    };

    if (removeVendorCheckFunctionality) {
      // skip the vendor search check for QA __
      setIsPayupAccountAlreadyThere(false);
      setIsPayupPhoneAccountPresent(false);
      setIsPayupEmailAccountPresent(false);
      setIsSuccess(true);
      setIsError(false);
      setFormSubmitted(true);
      localStorage.setItem('vendorDetails', JSON.stringify(setfromSuppliers));
    } else {
      const response = await vendorSearch({
        email: data.email,
        fullName: data.primary_contact_name,
        businessName: data.display_name,
        phone: data.phone_number,
        tax_id: data?.inputTaxId,
      });

      if (response) {
        setIsLoading(false);
        if (response.data.isPayupAccountAlreadyPresent) {
          setIsPayupAccountAlreadyThere(true);
          setIsError(false);
          setIsSuccess(false);
          setFormSubmitted(true);
          localStorage.setItem('vendorDetails', JSON.stringify(setfromSuppliers));
          if (response.data.isPayupEmailAccountPresent) {
            setIsPayupEmailAccountPresent(true);
          } else if (response.data.isPayupPhoneAccountPresent) {
            setIsPayupPhoneAccountPresent(true);
          }
        } else {
          setIsPayupAccountAlreadyThere(false);
          setIsPayupPhoneAccountPresent(false);
          setIsPayupEmailAccountPresent(false);
          if (response.data.suppliers.length > 0) {
            setIsSuccess(true);
            setIsError(false);
            setFormSubmitted(true);
            localStorage.setItem('vendorDetails', JSON.stringify(setfromSuppliers));
          } else if (
            response.data.suppliers.length === 0 ||
            response.data.errors.request.length > 0
          ) {
            setIsError(true);
            setIsSuccess(false);
            setFormSubmitted(true);
          }
        }
      }
    }
  });

  const handleBackButton = () => {
    if (disabledTheCustomerAddition) {
      navigate('/');
    } else {
      navigate('/customers-addition');
    }
  };

  const handleStatusScreensBackButtonClick = () => {
    setIsSuccess(false);
    setFormSubmitted(false);
    setIsError(false);
    setIsPayupAccountAlreadyThere(false);
    setIsPayupPhoneAccountPresent(false);
    setIsPayupEmailAccountPresent(false);
  };

  const { webAuth } = getWebAuth();
  const webAuthnSignUp = () => {
    webAuth.authorize({
      responseType: 'code',
      redirectUri: `${window.location.origin}/verify-code`,
      prompt: 'login',
      screen_hint: 'signup',
      mode: 'signUp',
      login_hint: responseData.email,
    });
  };

  const webAuthnLogin = () => {
    localStorage.setItem('from_login', 'yes');
    localStorage.setItem('from_resend', 'no');
    localStorage.setItem('from_signup', 'no');

    webAuth.authorize({
      responseType: 'code',
      redirectUri: `${window.location.origin}/verify-code`,
      mode: 'login',
      login_hint: responseData.email,
    });
  };

  return (
    <>
      {isFormSubmitted && isSuccess && (
        <SearchVendorSuccessContainer
          onBackButtonClick={handleStatusScreensBackButtonClick}
          customer={'Resprop'}
          vendor={responseData.vendor}
          onSubmitEvent={webAuthnSignUp}
        />
      )}

      {isFormSubmitted && isError && (
        <SearchVendorFailiureContainer
          onBackButtonClick={handleStatusScreensBackButtonClick}
          customer={'Resprop'}
        />
      )}

      {isFormSubmitted && isPayupAccountAlreadyThere && (
        <AccountAlreadyAvailableContainer
          onSubmitEvent={webAuthnLogin}
          onBackButtonClick={handleStatusScreensBackButtonClick}
          number={responseData.phone}
          email={responseData.email}
          isPayupPhoneAccountPresent={isPayupPhoneAccountPresent}
          isPayupEmailAccountPresent={isPayupEmailAccountPresent}
        />
      )}

      {!isFormSubmitted && (
        <Wrapper>
          {isLoading && <ManualLoader />}
          <FormWrapper onSubmit={appSubmit} className="no_fullscreen">
            <Box
              component={'div'}
              className={'container_content'}
              // ref={elementRef}
              sx={{
                [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                  minHeight: 400 + commonSpacing.desktopPaddingB * 4,
                },
              }}
            >
              <Header
                isBackButtonVisible={true}
                onBackButtonClick={() => handleBackButton()}
                paddingX={0}
              />
              <Box sx={{ marginBottom: 6 }}>
                <HeadingSummaryBlock
                  heading={t('headingContent.commonSteps.searchVendor.heading')}
                  headingAlignment="left"
                  content={t('headingContent.commonSteps.searchVendor.content')}
                  contentAlignment="left"
                />
              </Box>
              <Box>
                <Controller
                  control={control}
                  name="primary_contact_name"
                  render={({ field: { value } }) => (
                    <FormInput
                      label={t('formInput.fullName')}
                      id={'primary_contact_name'}
                      placeholder={t('placeHolder.fullName')}
                      {...register('primary_contact_name')}
                      error={errors?.primary_contact_name?.message}
                      onChange={(event: any) => {
                        event.target.value = event.target.value.replace('  ', ' ');
                        updateBindingsChange(event, 'primary_contact_name');
                      }}
                      value={value}
                      inputFocus={(e: any) => {
                        trigger('primary_contact_name');
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="display_name"
                  render={({ field: { value } }) => (
                    <FormInput
                      id={'businessName'}
                      label={t('formInput.businessName')}
                      placeholder={t('formInput.businessName')}
                      {...register('display_name')}
                      // error={errors?.last_name?.message}
                      onChange={(event: any) => {
                        event.target.value = event.target.value.replace('  ', ' ');
                        updateBindingsChange(event, 'display_name');
                      }}
                      value={value}
                      inputFocus={(e: any) => {
                        trigger('display_name');
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { value } }) => (
                    <FormInput
                      label={t('formInput.email')}
                      id={'email'}
                      placeholder={t('placeHolder.emailAddress')}
                      {...register('email')}
                      inputMode="email"
                      error={errors?.email?.message}
                      onChange={(event: any) => {
                        updateBindingsChange(event, 'email');
                      }}
                      value={value}
                      inputFocus={(e: any) => {
                        trigger('email');
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="phone_number"
                  render={({ field: { value } }) => (
                    <FormInputPhoneNumber
                      label={t('formInput.phone')}
                      id={'phone'}
                      placeholder={'(123) 543-3454'}
                      {...register('phone_number')}
                      inputError={errors.phone_number?.message}
                      onChange={onChangeHandler}
                      inputMode="numeric"
                      value={value}
                      inputFocus={(e: any) => {
                        trigger('phone_number');
                      }}
                    />
                  )}
                />
                {toSearchVendorByTaxId && (
                  <Controller
                    control={control}
                    name="inputTaxId"
                    render={({ field: { value } }) => (
                      <FormInput
                        label={t('formInput.taxId')}
                        id={'inputTaxId'}
                        placeholder={'00-00000000'}
                        {...register('inputTaxId')}
                        onChange={(event: any) => {
                          updateBindingsChange(event, 'inputTaxId');
                        }}
                        value={value}
                        inputFocus={(e: any) => {
                          trigger('inputTaxId');
                        }}
                      />
                    )}
                  />
                )}
                {serverErrors && (
                  <Messages messageHeading="Search Customers" messageContent={serverErrors} />
                )}
              </Box>
            </Box>

            <ContentContainerBottom className={'no_fullscreen'}>
              <Button
                variant="containedLarge"
                color="primary"
                type="submit"
                onClick={() => {
                  setBtnSubmitted(true);
                }}
                fullWidth
                sx={{ overflow: 'hidden' }}
              >
                {t('buttonTexts.buttonTextSignup')}
              </Button>
            </ContentContainerBottom>
          </FormWrapper>
        </Wrapper>
      )}
    </>
  );
};
