import React, { useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import Qs from 'qs';
import {
  PaymentTabNav,
  EarningTabHeader,
  PayoutsCounter,
  PaymentTableGrid,
  SearchBar,
} from '../../../components/base/core/Grids/InvoiceGridElements';
import { PaginationLoader } from '../../../components/base/core/Grids/InvoiceGridElements/PaymentGrid/PaymentTableGrid/PaymentTableGrid.style';

import { handleAccordion, helperHandlingTerms } from '../../../helpers/helperHandlingTerms';
import { handleSubmittedData } from '../../../helpers/containers/helperSubmittedInvoices';

import GridProvider from 'src/contexts/gridContext';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { getSearchParams } from 'src/helpers/helperSearchParams';
import {
  Header,
  HeaderFixed,
  WrpperAuthorizedContainer,
  EmptyList,
  Wrapper,
  ModalTermEdit,
} from 'src/components/base/core';
import { Messages } from 'src/components/base/core';

export const EarningsSubmittedContainer = () => {
  // set feature flag __
  const { enablePay1181, existingInvoiceHandle } = useFlags();
  const [clickToggle, setClickToggle] = useState(false);
  const [hasDelinquent, setHasDelinquent] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [
    termsSetDetails,
    handleTermClick,
    handleTermChange,
    handleConfirmButtonClick,
    handleYesButtonClick,
    handleModalClose,
    handleMultiplePaymentSelection,
    handleAllPayoutSelector,
    handleEditInvoicesClick,
    handleOldInvoiceToggle,
    handleCurrentInvoiceToggle,
    getSelectedCount,
    activeAccordion,
    activeAccordionOldInv,
    setActiveAccordion,
    setActiveAccordionOldInv,
  ] = helperHandlingTerms();

  const [
    rowCountCurrent,
    handleCurrentRowCountChange,
    rowCountExisting,
    handleExistingRowCountChange,
    offsetCurrent,
    offsetExisting,
    pageCurrentCount,
    pageExistingCount,
    showLoaderForCurrent,
    showLoaderExsisting,
    searchInProgress,
    existingInvicedData,
    setExistingInvicedData,
    submitData,
    handletheMapping,
    handleExsistingShowMore,
    handleCurrentShowMore,
    handleCurrentSearch,
    handlecurrentPayoutsVisibility,
    handleExistingPayoutsVisibility,
    transaction,
    payout_amount,
    decimalAmount,
    handleDataSorting,
    handleApplyFilter,
    handleFilterOpen,
    handleFilterClose,
    handleFilterReset,
    filterStates,
    setSelectedFilterOptions,
    selectedFilterOptions,
    setFilterAmountRange,
    resetFilter,
    setSelectedDateFilterOptions,
    selectedDateFilters,
    minmumAmount,
    maximumAmount,
    searchText,
    handleResetSearch,
    onDateChange,
    selectedDateRange,
    filterAllCount,
    setSelectedDateRange,
    setAllSelect,
    isAllSelect,
    payoutsTotalCount,
    existingPayoutsTotalCount,
    filterStatus,
    showMoreInprogressOld,
    closeEditTerms,
    isCloseEditTerms,
    filteringApplied,
    filterSearchParams,
    setFilteredParams,
    setResetFilter,
    enableQuerySearch,
    savedMinmumAmount,
    savedMaximumAmount,
    downloadStates,
    setDownloadStates,
  ]: any = handleSubmittedData();

  const handleOnYesButtonClick = () => {
    handletheMapping();
    handleYesButtonClick();
  };

  const getUrlParams = () => {
    if (location?.search) {
      return {
        ...Qs.parse(location.search.substring(1)),
        pagination: {
          offsetCurrent,
          offsetExisting,
          rowCountCurrent,
          rowCountExisting,
        },
      };
    }

    return {
      pagination: {
        offsetCurrent,
        offsetExisting,
        rowCountCurrent,
        rowCountExisting,
      },
    };
  };

  useEffect(() => {
    if (submitData?.length > 0) {
      localStorage.setItem('activeCurrentInv', 'true');
    }
    handleAccordion(
      searchInProgress,
      existingInvicedData,
      submitData,
      setActiveAccordion,
      setActiveAccordionOldInv,
      searchText,
      activeAccordionOldInv,
      showMoreInprogressOld
    );
    const hasDeleq = submitData.find((x: any) => x?.property?.is_delinquent);
    setHasDelinquent(hasDeleq);
  }, [submitData, existingInvicedData]);

  useEffect(() => {
    if (filteringApplied) {
      navigate({
        pathname: '/earnings/submitted',
        search: `?${createSearchParams(getSearchParams(filterSearchParams))}`,
      });
    }
  }, [filterSearchParams]);

  useEffect(() => {
    if (filteringApplied) {
      localStorage.setItem('ear-min', minmumAmount);
      localStorage.setItem('ear-max', maximumAmount);
    }
  }, [filteringApplied]);

  useEffect(() => {
    navigate({
      pathname: '/earnings/submitted',
      search: `?${createSearchParams(getSearchParams(getUrlParams()))}`,
    });
  }, [rowCountCurrent, rowCountExisting, offsetCurrent, offsetExisting]);

  useEffect(() => {
    if (resetFilter) {
      navigate({ pathname: '/earnings/submitted' });
      setResetFilter(!resetFilter);
    }
  }, [resetFilter]);

  const getDataForDownload = () => {
    const tempArray = [];
    if (submitData) {
      tempArray.push(...submitData);
    }

    if (existingInvicedData) {
      tempArray.push(...existingInvicedData);
    }

    return tempArray;
  };

  return (
    <>
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonVisible={false}
          isBackButtonHide
          paddingX={0}
          headerText={t('earning.headerText')}
          withCenteredHeaderText
        />
      </HeaderFixed>
      {/** Tab navigation */}
      <PaymentTabNav />
      <Wrapper isAuthorizedWrapper isTabNavigationAvailable>
        <WrpperAuthorizedContainer>
          {hasDelinquent && (
            <Messages
              messageHeading={t('earning.messageHeader')}
              messageContent={t('earning.messageContent')}
              type="delinquent"
            />
          )}
          {(handlecurrentPayoutsVisibility ||
            handleExistingPayoutsVisibility ||
            searchInProgress ||
            enableQuerySearch) && (
            <>
              <EarningTabHeader
                isSecondColHasInvoiceCount={true}
                firstColTitle={t('earning.submitted.firstColTitle')}
                firstColAmount={payout_amount[0]}
                secondColTitle={t('earning.submitted.secondColTitle')}
                secondColContent={transaction}
                decimalAmount={decimalAmount}
              />

              <SearchBar
                onchangeHandler={handleCurrentSearch}
                onClickEnableFilter={handleFilterOpen}
                isSearchIconVisible={searchText === '' || searchText === undefined}
                onResetSearch={handleResetSearch}
                searchText={searchText}
                filterCounterValue={filterAllCount}
                onClickDownloadCSV={() =>
                  setDownloadStates({
                    ...downloadStates,
                    isDownloadEnable: true,
                  })
                }
                t={t}
              />
            </>
          )}
          {(handlecurrentPayoutsVisibility || enableQuerySearch || searchInProgress) && (
            <GridProvider
              accordion={{
                activeAccordion: activeAccordion,
                activeAccordionOld: null,
                enableAccordion: true,
                isOldInvoice: false,
                showMoreInprogressOld: false,
              }}
              filterStatus={filterStatus}
            >
              <PaymentTableGrid
                selectedRowCount={rowCountCurrent}
                onRowCountChange={(val) => handleCurrentRowCountChange(val)}
                accordionHeading={t('earning.submitted.accordionHeadingCurrent')}
                tooltipContent={t('earning.submitted.tooltipContentCurrent')}
                termsSetDetails={termsSetDetails}
                handleInvoiceToggle={handleCurrentInvoiceToggle}
                handleTermClick={(event: any) => handleTermClick(event, true)}
                handleShowMore={(value: any) => handleCurrentShowMore(value, true)}
                dataObject={submitData}
                paginationDisplayCondition={submitData && pageCurrentCount >= 1}
                offset={offsetCurrent}
                pageCount={pageCurrentCount}
                isAccordion
                isTermEditable={false}
                showLoader={showLoaderForCurrent}
                onModalClose={handleFilterClose}
                handleDataSorting={handleDataSorting}
                onClickApplyFilter={handleApplyFilter}
                selectedSortingOption={filterStates.sort}
                setSelectedFilterOptions={setSelectedFilterOptions}
                onFilterReset={handleFilterReset}
                setFilterAmountRange={setFilterAmountRange}
                selectedFilterOptions={selectedFilterOptions}
                setSelectedDateFilterOptions={setSelectedDateFilterOptions}
                selectedDateFilters={selectedDateFilters}
                resetFilter={resetFilter}
                minmumAmount={minmumAmount}
                maximumAmount={maximumAmount}
                onDateChange={onDateChange}
                selectedDateRange={selectedDateRange}
                isFilterEnable={filterStates && filterStates.isFilterEnable}
                setSelectedDateRange={setSelectedDateRange}
                setAllSelect={setAllSelect}
                isAllSelect={isAllSelect}
                dataCount={payoutsTotalCount}
                searchInProgress={searchInProgress}
                enableQuerySearch={enableQuerySearch}
                savedMinmumAmount={savedMinmumAmount}
                savedMaximumAmount={savedMaximumAmount}
                iconArrowVisible={true}
                downloadStates={downloadStates}
                setDownloadStates={setDownloadStates}
              />
            </GridProvider>
          )}

          {(handleExistingPayoutsVisibility || searchInProgress || enableQuerySearch) && (
            <GridProvider
              accordion={{
                activeAccordion: null,
                activeAccordionOld: activeAccordionOldInv,
                enableAccordion: true,
                isOldInvoice: true,
                showMoreInprogressOld: showMoreInprogressOld,
                selectedCount: getSelectedCount(existingInvicedData),
                clickToggle: clickToggle,
              }}
              filterStatus={filterStatus}
            >
              <PaymentTableGrid
                selectedRowCount={rowCountExisting}
                onRowCountChange={(val) => handleExistingRowCountChange(val, termsSetDetails)}
                accordionHeading={t('earning.submitted.accordionHeadingExisting')}
                tooltipContent={t('earning.submitted.tooltipContentExisting')}
                termsSetDetails={termsSetDetails}
                handleInvoiceToggle={() => {
                  handleOldInvoiceToggle();
                  setClickToggle(true);
                }}
                handleTermClick={handleTermClick}
                handleShowMore={(value: any) =>
                  handleExsistingShowMore(value, false, termsSetDetails)
                }
                handleMultiplePaymentSelection={(e: any) =>
                  handleMultiplePaymentSelection(e, existingInvicedData, setExistingInvicedData)
                }
                dataObject={existingInvicedData}
                isOldInvoiceGrid
                isTermEditable={false}
                paginationDisplayCondition={existingInvicedData && pageExistingCount >= 1}
                offset={offsetExisting}
                pageCount={pageExistingCount}
                isHandleArrowClickable={false}
                isAccordion
                showLoader={showLoaderExsisting}
                isFilterEnable={false}
                dataCount={existingPayoutsTotalCount}
                closeEditTerms={closeEditTerms}
                searchInProgress={searchInProgress}
                iconArrowVisible={false}
              />
            </GridProvider>
          )}

          {/** Loader */}
          {(submitData === undefined || existingInvicedData === undefined) &&
            (submitData?.length !== 0 || existingInvicedData?.length !== 0) && <PaginationLoader />}

          {/** Empty list */}
          {submitData !== undefined &&
            existingInvicedData !== undefined &&
            submitData?.length === 0 &&
            existingInvicedData?.length === 0 &&
            !searchInProgress &&
            !enableQuerySearch && (
              <EmptyList
                heading={t('earningEmptyState.firstTabHeading')}
                subHeading={t('earningEmptyState.firstTabContent')}
                tabType="submitted"
                t={t}
              />
            )}

          {termsSetDetails.modalOpen && enablePay1181 && (
            <ModalTermEdit
              onModalClose={handleModalClose}
              options={termsSetDetails.termsData}
              onTermChange={handleTermChange}
              isConfirmButtonDisable={
                termsSetDetails.initialLoadingTerm === termsSetDetails.updatedTerm
              }
              onConfirmButtonClick={handleConfirmButtonClick}
              onYesButtonClick={handleOnYesButtonClick}
              isConfirmed={termsSetDetails.showConfirmModal}
              updatedTerm={termsSetDetails.updatedTerm}
              selectedTerm={termsSetDetails.selectedTerm}
              error={termsSetDetails.error}
              isEditingOldInvoices={termsSetDetails.isOldInvoiceType}
              isBulkEditableEnable={termsSetDetails && termsSetDetails.selectedTermsCount > 0}
            />
          )}

          {/** Bulk invoice count block */}
          {termsSetDetails &&
            existingInvicedData?.length > 0 &&
            getSelectedCount(existingInvicedData) !== 0 && (
              <PayoutsCounter
                editPayoutsClick={handleEditInvoicesClick}
                selectAllClick={() =>
                  handleAllPayoutSelector(existingInvicedData, setExistingInvicedData)
                }
                selectedInvoiceCount={getSelectedCount(existingInvicedData)}
                isSelectAll={termsSetDetails?.isAllTermChecked}
              />
            )}
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
