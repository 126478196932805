import { styled } from '@mui/material/styles';

export const ShowMoreWrapper = styled('div')(
  ({ theme }) => `
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${theme.spacing(2)};
  padding-right: ${theme.spacing(1.5)};
`
);

export const PaginationWrapperContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DropDownContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ArrowIconContainer = styled('div')(
  ({ theme }) => `
  cursor: pointer;
  padding: ${theme.spacing(1.5)};
`
);

export const PageNumberContainer = styled('div')(
  ({ theme }) => `
  background-color: ${theme.palette.blue[50]};
  display: flex;
  border-radius: 30px;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  margin-left: ${theme.spacing(1.5)};
  margin-right: ${theme.spacing(1.5)};
`
);
