import { useTranslation } from 'react-i18next';
import { PAY3342, removeFriendReferrals } from 'src/constants/constants';
import { getTermName } from './helperHandlingTerms';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { handleSettingBusinessAIVSAM } from 'src/shared/utilities/dataConvertions';

export const getSettingsData = (accountDetails: any, t: any) => {
  const { disabledTheCustomerAddition, reduceTheBusinessFlowFields } = useFlags();

  const isCompanyAccount = accountDetails?.business_type === 'company';
  const isUnverifiedBusinessAccount =
    isCompanyAccount && accountDetails?.currently_due_requirements?.includes('company.tax_id');
  const verificationStatus = accountDetails?.individual?.verification?.status;
  const havePersonalDueReqsAvailable =
    accountDetails?.currently_due_requirements?.filter((item: any) => item?.startsWith('person_'))
      .length > 0;

  const handleVerificationStatus = () => {
    let status;

    const [messageObject, verifyObj]: any = handleSettingBusinessAIVSAM(accountDetails, t);

    if (reduceTheBusinessFlowFields && isCompanyAccount) {
      if (verifyObj.personal === 'verified' && verifyObj.business === 'verified') {
        status = 'verified';
      } else if (verifyObj.personal === 'unverified' || verifyObj.business === 'unverified') {
        status = 'unverified';
      } else if (verifyObj.personal === 'pending' || verifyObj.business === 'pending') {
        status = 'pending';
      }
    } else {
      status = isUnverifiedBusinessAccount ? 'unverified' : verificationStatus;
    }

    return status;
  };

  const handlePayMode = (paymodeValue: string = '') => {
    let _paymode = paymodeValue.toLocaleUpperCase();

    if (paymodeValue === 'debit_card') {
      _paymode = 'Instant Payouts';
    }

    if (paymodeValue === 'check') {
      _paymode = 'Checks';
    }

    return _paymode;
  };

  const accInfoStatus = getVerificationDetails(handleVerificationStatus());

  const settingObj = [
    {
      title: t('settings.AccountInformation.title'),
      id: 'AccountInformation',
      icon: 'PersonIcon',
      buttonDetails: {
        buttonEnable: true,
        buttonText: accInfoStatus.text,
        color: accInfoStatus.color,
      },
      isHidden: false,
    },
    {
      title: PAY3342 ? t('settings.PayoutMode.title') : t('settings.PayoutTerms.title'),
      id: accInfoStatus.text !== 'Verified' ? 'DisablePayoutTerms' : 'PayoutTerms',
      icon: 'DolarCurcleIcon',
      buttonDetails: {
        buttonEnable: true,
        buttonText: PAY3342
          ? handlePayMode(accountDetails?.default_pay_mode)
          : accountDetails?.default_payment_term_id &&
            getTermName(accountDetails?.default_payment_term_id),
        color: 'gray-blue',
      },
      isHidden: false,
    },
    {
      title: t('settings.LinkedBankAccount.title'),
      id: 'LinkedBankAccount',
      icon: 'bankIcon',
      buttonDetails: { buttonEnable: false, buttonText: '', color: '' },
      isHidden: false,
    },
    {
      title: t('settings.Notifications.title'),
      id: 'Notifications',
      icon: 'NotificationIcon',
      buttonDetails: { buttonEnable: false, buttonText: '', color: '' },
      isHidden: false,
    },
    {
      title: t('settings.Customers.title'),
      id: 'Customers',
      icon: 'GiftIcon',
      buttonDetails: { buttonEnable: false, buttonText: '', color: '' },
      isHidden: disabledTheCustomerAddition,
    },
    {
      title: t('settings.Referrals.title'),
      id: 'Referrals',
      icon: 'CustomerIcon',
      buttonDetails: { buttonEnable: false, buttonText: '', color: '' },
      isHidden: false,
    },
    {
      title: t('settings.Languages.title'),
      id: 'Languages',
      icon: 'WorldIcon',
      buttonDetails: {
        buttonEnable: true,
        buttonText: t('settings.Languages.buttonText'),
        color: 'gray-blue',
        type: 'gray-blue',
      },
      isHidden: false,
    },
    {
      title: t('settings.LogOut.title'),
      id: 'LogOut',
      icon: 'LogoutIcon',
      buttonDetails: { buttonEnable: false, buttonText: '', color: '' },
      isHidden: false,
    },
  ];

  if (removeFriendReferrals) {
    return settingObj.filter((item) => item.id !== 'Referrals' && !item.isHidden);
  }

  return settingObj;
};

export const getVerificationDetails = (status: string) => {
  const { t } = useTranslation();
  switch (status) {
    case 'verified':
      return { color: 'green.500', text: t('settings.AccountInformation.buttonTextVerified') };
    case 'restricted':
      return { color: 'red.500', text: t('settings.AccountInformation.buttonTextRestricted') };
    case 'pending':
      return { color: 'darkBlue.300', text: t('settings.AccountInformation.buttonTextPending') };
    default:
      return { color: 'red.500', text: t('settings.AccountInformation.buttonTextUnverified') };
      break;
  }
};
