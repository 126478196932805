import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import {
  DollarWithBoarderIcon,
  CorrectIcon,
  MoveIcon,
  LongReceiptIcon,
} from '../../Media/Icon/Icons/Icons';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { PaymentDataTable } from '../../Grids/InvoiceGridElements/PaymentGrid/PaymentDataTable/PaymentDataTable';

import { InvoiceCardProps } from 'src/models/component/base.model';
import moneyFormat from 'src/shared/utilities/moneyFormat';
import { InvoiceCardWrapper, IconArrow, InvoiceCardIcon } from './InvoiceCard.style';
import { theme } from 'src/shared/theme/theme';

/**
 * Primary UI component for user interaction
 */
export const InvoiceCard = ({
  onClick,
  data = [],
  title = '',
  amount,
  icon,
  tabType,
  clickOnCard,
}: InvoiceCardProps) => {
  const { t } = useTranslation();

  const payout_amount = amount ? moneyFormat(parseFloat(amount)).split('.') : '-';
  const decimalAmount = amount ? payout_amount[1] : '';

  const cardIcon = () => {
    switch (icon) {
      case 'LongReceiptIcon':
        return <LongReceiptIcon />;
      case 'DollarWithBoarderIcon':
        return <DollarWithBoarderIcon />;
      case 'CorrectIcon':
        return <CorrectIcon />;
      case 'MoveIcon':
        return <MoveIcon />;
    }
  };

  return (
    <InvoiceCardWrapper className={`card-wrapper-home-main`} onClick={clickOnCard}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: 3,
          cursor: 'pointer',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
          onClick={clickOnCard}
        >
          <Typography
            component="p"
            variant="caption3"
            textTransform={'uppercase'}
            sx={{
              marginBottom: 1,
              display: 'inline-flex',
              alignItems: 'center',
            }}
          >
            <InvoiceCardIcon>{cardIcon()}</InvoiceCardIcon>
            {title}
          </Typography>
          <Typography
            component="p"
            variant={'h5Bold'}
            color="gray.800"
            sx={{
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                fontSize: '28px',
              },
            }}
          >
            <>
              {payout_amount[0]}
              {decimalAmount !== '00' && <span>{`.${decimalAmount}`}</span>}
            </>
          </Typography>
        </Box>

        <Typography
          component="span"
          variant="h1Bold"
          color={'primary'}
          textTransform={'capitalize'}
          onClick={onClick}
          textAlign={'right'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
              fontSize: '14px',
            },
          }}
        >
          {t('home.viewAll')}
          <IconArrow>
            <KeyboardArrowRightIcon color="primary" />
          </IconArrow>
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <PaymentDataTable
          isToggle={true}
          dataObject={data}
          searchInProgress={false}
          iconArrowVisible={false}
          tabType={tabType}
        />
      </Box>
    </InvoiceCardWrapper>
  );
};
