import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  FormInput,
  FormInputPhoneNumber,
  UpdateFormWrapper,
  UpdateCardWrapper,
  UpdateFormTop,
  AccountVerificationStatusses,
  AddressFields,
  Messages,
  FixedParentWrapper,
  FixedParentWrapperContainer,
} from 'src/components/base/core';
import { FormEditSubmitionprops } from 'src/models/component/base.model';
import { AccountParams } from 'src/shared/utilities/interface';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ERROR_MESSAGES } from 'src/constants/Validation';
import { addressDefaultValues, initAccount } from 'src/models/init/account.init';
import { identityVerificationSchemaObj } from 'src/helpers/validationSchemas';
import { dtoToFormValuesBusinessAddress } from 'src/shared/utilities/dto';
import { FormEINInput } from 'src/components/base/core/FormElements/FormEINInput/FormEINInput';
import { ManualLoader } from 'src/components/base/core/OtherUtilities/Loader/ManualLoader';
import { handleVerifyPhone } from 'src/helpers/helperPhoneVerificaition';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PhoneVerifyCodeContainer } from '../../../VerifyCode/VerifyCode';
import { handleBusinessDetailsReviewEdit } from 'src/helpers/containers/helperBusinessOwnership';

export const FormEditBusinesslDetailsSubmition = ({
  onCancelButton,
  title,
  type,
  isSettings = false,
  isShadowCard = false,
  isVerified = false,
  onFormSubmit,
  result,
}: FormEditSubmitionprops) => {
  const { t } = useTranslation();

  const { webauthnEnabled, disabledPhoneVerificationStep } = useFlags();
  const [formData, setFormData] = useState<AccountParams>(initAccount);
  const [verifyCodeContainerVisible, setVerifyCodeContainerVisible] = useState(false);
  const [handleVerificationCode, verifiedPhoneNumber, errorState, isPhoneVerificationLoading] =
    handleVerifyPhone();

  const [addressStates, setAddressStates] = useState(addressDefaultValues);
  const [enableEIN, setEnableEIN]: any = useState(false);
  const [
    serverSideErrors,
    setServerSideErrors,
    appLogin,
    isLoading,
    onChangeHandlerPhone,
    onChangeHandlerTax,
  ]: any = handleBusinessDetailsReviewEdit(result);

  useEffect(() => {
    if (result) {
      dtoToFormValuesBusinessAddress(setValue, result);
      if (result?.company?.tax_id_provided) {
        setEnableEIN(false);
      }
    }
  }, [result]);

  //modify init for custom validation
  const schemaObj = {
    ...identityVerificationSchemaObj,
    inputTaxId: yup
      .string()
      .test('required', ERROR_MESSAGES.REQUIRED, (value) => {
        if (!enableEIN || value) return true;
        return false;
      })
      .test('min', ERROR_MESSAGES.EIN_INVALID, (value) => {
        if ((value && value?.length === 9) || !enableEIN) return true;
        return false;
      }),
    inputCompanyName: yup.string().required(ERROR_MESSAGES.REQUIRED),
    inputCompanyPhone: yup.string().required(ERROR_MESSAGES.REQUIRED),
  };

  //update with custom validation
  const schema = yup.object(schemaObj).required();

  //hook-forms
  const {
    register,
    handleSubmit,
    setValue,
    control,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<AccountParams>({
    resolver: yupResolver(schema),
    defaultValues: initAccount,
    mode: 'onSubmit',
  });

  const onSubmitForm = () => {
    onFormSubmit && onFormSubmit();
    onCancelButton && onCancelButton();
    localStorage.removeItem('review_error_block');
  };

  const isTosAccepted = result && result?.tos_shown_and_accepted;

  const skipTheOTPVerification = (data: any) => {
    let setData = data;
    // if tos_shown_and_accepted false __
    if (!isTosAccepted && isSettings) {
      setData = {
        ...data,
        tos_shown_and_accepted: true,
      };
    } else {
      setData = {
        ...data,
      };
    }

    appLogin(setData, onSubmitForm);
    setFormData(initAccount);
    setVerifyCodeContainerVisible(false);
  };

  const moveToOTPVerification = (data: any) => {
    let setData = data;
    // if tos_shown_and_accepted false __
    if (!isTosAccepted && isSettings) {
      setData = {
        ...data,
        tos_shown_and_accepted: true,
      };
    } else {
      setData = {
        ...data,
      };
    }

    setFormData(setData);
    // @ts-ignore
    handleVerificationCode(setData, getValues, setVerifyCodeContainerVisible, 'business_info');
  };

  const onAppFormSubmit = handleSubmit(async (data: any) => {
    let setData = data;
    // check tos_shown_and_accepted true or false __
    const getToshShownAccepted = result && result?.tos_shown_and_accepted ? true : false;
    // if tos_shown_and_accepted false __
    if (!getToshShownAccepted) {
      setData = { ...data, tos_shown_and_accepted: true };
    }
    // if webauthnEnabled featureflag enable or not __
    if (webauthnEnabled && !disabledPhoneVerificationStep) {
      if (result && result.company.phone) {
        if (result.company.phone.includes(data?.inputCompanyPhone?.replace(/[^0-9]/g, ''))) {
          skipTheOTPVerification(setData);
        } else if (
          result.individual.phone.includes(data?.inputCompanyPhone?.replace(/[^0-9]/g, ''))
        ) {
          skipTheOTPVerification(setData);
        } else {
          moveToOTPVerification(setData);
        }
      } else {
        moveToOTPVerification(setData);
      }
    } else {
      skipTheOTPVerification(setData);
    }
  });

  const updateBindingsChange = (event: any, feild: any) => {
    setValue(feild, event.target.value);
  };

  const onChangeHandler = (event: any) => {
    onChangeHandlerPhone(event, getValues, setValue);
  };
  const onChangeHandlerTaxValue = (event: any) => {
    onChangeHandlerTax(event, getValues, setValue);
  };

  const settingsVerifiedTaxID =
    result && result.currently_due_requirements.includes('company.tax_id');

  const isSettingVerified = isSettings && isVerified;

  if (verifyCodeContainerVisible) {
    return (
      <FixedParentWrapper>
        <FixedParentWrapperContainer>
          <PhoneVerifyCodeContainer
            phone={verifiedPhoneNumber}
            setVerifyCodeContainerVisible={setVerifyCodeContainerVisible}
            onSubmitFunction={() => appLogin(formData, onSubmitForm, setVerifyCodeContainerVisible)}
            phoneWithMask={formData.inputCompanyPhone}
          />
        </FixedParentWrapperContainer>
      </FixedParentWrapper>
    );
  }

  return (
    <>
      {(isLoading || isPhoneVerificationLoading) && <ManualLoader />}
      <UpdateFormWrapper
        onSubmit={onAppFormSubmit}
        className={`no-mobile-padding ${isShadowCard ? 'is_shadow_card' : 'disable_shadow_card'}`}
      >
        {!isSettings && (
          <UpdateFormTop className={type ? 'enabled_status' : 'disabled_status'}>
            <Typography
              textTransform={'uppercase'}
              variant="caption2"
              color={'gray.500'}
              marginBottom={1}
            >
              {title}
            </Typography>
            {type && type !== undefined && <AccountVerificationStatusses type={type} />}
          </UpdateFormTop>
        )}
        <UpdateCardWrapper className="update_wrapper_top">
          <Box
            className={isSettings ? 'is_shadow_card' : 'disabled_shadow_card'}
            paddingTop={isSettings ? 4 : 0}
            paddingBottom={isSettings ? 1 : 0}
            paddingX={isSettings ? 4 : 0}
            marginBottom={isSettings ? 4 : 0}
          >
            {isSettings && (
              <UpdateFormTop className={type ? 'enabled_status' : 'disabled_status'}>
                <Typography
                  textTransform={'uppercase'}
                  variant="caption2"
                  color={'gray.500'}
                  marginBottom={2}
                >
                  {title}
                </Typography>
                {type && type !== undefined && <AccountVerificationStatusses type={type} />}
              </UpdateFormTop>
            )}
            <Controller
              control={control}
              name="inputCompanyName"
              render={({ field: { value } }) => (
                <FormInput
                  label={t('formInput.comapanyName')}
                  id={'company_name'}
                  placeholder={t('placeHolder.comapnyName')}
                  {...register('inputCompanyName')}
                  error={errors?.inputCompanyName?.message || errorState}
                  onChange={(event: any) => {
                    updateBindingsChange(event, 'inputCompanyName');
                  }}
                  value={value}
                  inputFocus={() => trigger('inputCompanyName')}
                  readonly={isSettingVerified && value !== '' && value !== null}
                />
              )}
            />
            {(settingsVerifiedTaxID || !isSettings) && (
              <Controller
                control={control}
                name="inputTaxId"
                render={({ field: { value } }) => (
                  <FormEINInput
                    label={t('formInput.ein')}
                    id={'ein'}
                    placeholder={'00-00000000'}
                    {...register('inputTaxId')}
                    error={errors?.inputTaxId?.message}
                    inputError={errors?.inputTaxId?.message}
                    onChange={onChangeHandlerTaxValue}
                    value={value}
                    replace={enableEIN}
                    inputMode="numeric"
                    setEnableEIN={setEnableEIN}
                    inputFocus={() => trigger('inputTaxId')}
                    inValidEIN={settingsVerifiedTaxID}
                  />
                )}
              />
            )}
            <Controller
              control={control}
              name="inputCompanyPhone"
              render={({ field: { value } }) => (
                <FormInputPhoneNumber
                  label={t('formInput.phone')}
                  id={'inputCompanyPhone'}
                  placeholder={'(123) 543-3454'}
                  {...register('inputCompanyPhone')}
                  inputError={errors.inputCompanyPhone?.message}
                  onChange={onChangeHandler}
                  inputMode="numeric"
                  value={value}
                  inputFocus={() => trigger('inputCompanyPhone')}
                />
              )}
            />

            <AddressFields
              setAddressStates={setAddressStates}
              addressStates={addressStates}
              register={register}
              errors={errors}
              control={control}
              getValue={getValues}
              setValue={setValue}
              trigger={trigger}
              isVerifiedOnlyForSetting={isSettingVerified}
            />

            {serverSideErrors && (
              <Messages
                topMargin={0}
                bottomMargin={4}
                messageHeading={
                  serverSideErrors.includes('phone')
                    ? t('errorMessages.phoneUniqueError.heading')
                    : serverSideErrors.includes('email')
                    ? t('errorMessages.emailUniqueError.heading')
                    : serverSideErrors.includes('P.O. Box')
                    ? t('errorMessages.invalidAddressError.heading')
                    : serverSideErrors
                }
                messageContent={
                  serverSideErrors.includes('phone')
                    ? t('errorMessages.phoneUniqueError.content')
                    : serverSideErrors.includes('email')
                    ? t('errorMessages.emailUniqueError.content')
                    : serverSideErrors.includes('P.O. Box')
                    ? serverSideErrors
                    : 'Please provide valid details to use PayUp.'
                }
                closeEvent={() => setServerSideErrors('')}
              />
            )}
          </Box>

          <Box className="update_wrapper_bottom">
            {!isSettings && (
              <Button
                variant="text"
                color="primary"
                type="button"
                sx={{ overflow: 'hidden', fontWeight: 600 }}
                onClick={onCancelButton}
              >
                {t('buttonTexts.cancelButton')}
              </Button>
            )}
            <Button
              variant="containedLarge"
              color="primary"
              type="submit"
              sx={{ overflow: 'hidden', width: isSettings ? '100%' : 'auto' }}
            >
              {!isSettings ? t('buttonTexts.updateButton') : t('buttonTexts.submitButton')}
            </Button>
          </Box>
        </UpdateCardWrapper>
      </UpdateFormWrapper>
    </>
  );
};
