import { Box, Typography } from '@mui/material';
import { TransactionCardWrapper } from 'src/components/base/core';

import { AfterBottomRow } from '../../CommonNestedStyles/CommonNestedStyles.style';

import { IFinanceMoveMoneySummaryDetailsCard } from 'src/models/component/base.model';

import { theme } from 'src/shared/theme/theme';

export const FinanceMoveMoneySummaryDetailsCard = ({
  valueType,
  valueDate,
  valueDescriptorCode,
  valueMethod,
  valueNotes,
  t,
}: IFinanceMoveMoneySummaryDetailsCard) => {
  return (
    <TransactionCardWrapper sx={{ marginBottom: 6 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          marginBottom: 1,
          marginTop: '2px',
          cursor: 'pointer',
          width: '100%',
        }}
      >
        <Typography
          component="p"
          variant="h2ExtraBold"
          textTransform={'uppercase'}
          sx={{
            marginBottom: 1,
            display: 'inline-flex',
            alignItems: 'center',
            color: 'gray.400',
          }}
        >
          {t('finance.MoveMoney.financeMoveMoneySummaryDetailsCard.heading')}
        </Typography>
      </Box>

      <Box display={'flex'} flexDirection={'column'}>
        <AfterBottomRow className="content icon_available">
          <Box className="content_left">
            <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
              {t('finance.MoveMoney.financeMoveMoneySummaryDetailsCard.type')}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography
              variant="h2"
              component={'p'}
              color={'gray.500'}
              fontWeight={300}
              textTransform={'capitalize'}
            >
              {valueType ?? '----'}
            </Typography>
          </Box>
        </AfterBottomRow>
        <AfterBottomRow className="content icon_available">
          <Box className="content_left">
            <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
              {t('finance.MoveMoney.financeMoveMoneySummaryDetailsCard.date')}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography variant="h2" component={'p'} color={'gray.500'} fontWeight={300}>
              {valueDate ? `${valueDate}` : '----'}
            </Typography>
          </Box>
        </AfterBottomRow>
        <AfterBottomRow className="content have_two_row">
          <Box className="content_left">
            <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
              {t('finance.MoveMoney.financeMoveMoneySummaryDetailsCard.method')}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography
              variant="h2"
              component={'p'}
              color={'gray.500'}
              marginBottom={1}
              textAlign={'right'}
              fontWeight={300}
            >
              {valueMethod ?? '----'}
            </Typography>
            <Typography variant="h1" component={'p'} color={'gray.500'} textAlign={'right'}>
              {`(1-2 ${t('finance.MoveMoney.financeMoveMoneySummaryDetailsCard.methodContent')})`}
            </Typography>
          </Box>
        </AfterBottomRow>

        {valueDescriptorCode && (
          <AfterBottomRow className="content">
            <Box className="content_left">
              <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
                {'Descriptor'}
              </Typography>
            </Box>
            <Box className="content_right">
              <Typography
                variant="h2"
                component={'p'}
                color={'gray.500'}
                fontWeight={300}
                sx={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                    maxWidth: '200px',
                  },
                }}
              >
                {valueDescriptorCode ?? '----'}
              </Typography>
            </Box>
          </AfterBottomRow>
        )}
        <AfterBottomRow className="content">
          <Box className="content_left">
            <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
              {t('finance.MoveMoney.financeMoveMoneySummaryDetailsCard.notes')}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography
              variant="h2"
              component={'p'}
              color={'gray.500'}
              fontWeight={300}
              sx={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                  maxWidth: '200px',
                },
              }}
            >
              {valueNotes}
            </Typography>
          </Box>
        </AfterBottomRow>
      </Box>
    </TransactionCardWrapper>
  );
};
