import React, { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import Qs from 'qs';

import {
  PaymentTabNav,
  PaymentTableGrid,
  SearchBar,
} from '../../../components/base/core/Grids/InvoiceGridElements';
import { PaginationLoader } from '../../../components/base/core/Grids/InvoiceGridElements/PaymentGrid/PaymentTableGrid/PaymentTableGrid.style';

import { helperHandlingTerms } from '../../../helpers/helperHandlingTerms';
import { handleApprovedData } from '../../../helpers/containers/helperApprovedInvoices';

import GridProvider from 'src/contexts/gridContext';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { getSearchParams } from 'src/helpers/helperSearchParams';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';
import { routeType } from 'src/helpers/containers/helperRoutePreference';
import {
  Header,
  HeaderFixed,
  Wrapper,
  WrpperAuthorizedContainer,
  EmptyList,
  ModalTermEdit,
} from 'src/components/base/core';

export const EarningsApprovedContainer = () => {
  // set feature flag __
  const { enablePay1181, enableSearchFilterSorting } = useFlags();
  const navigate = useNavigate();
  const location = useLocation();
  const [updatePreferenceOnServer] = handlePreferences();
  const { t } = useTranslation();

  const [
    termsSetDetails,
    handleTermClick,
    handleTermChange,
    handleConfirmButtonClick,
    handleYesButtonClick,
    handleModalClose,
  ] = helperHandlingTerms();

  const [
    offset,
    pageCount,
    showLoader,
    searchInProgress,
    handleShowMore,
    rowCount,
    handleRowCountChange,
    handletheMapping,
    submitData,
    recentInvoicePaidDate,
    payout_amount,
    decimalAmount,
    handleSearch,
    handleDataSorting,
    handleApplyFilter,
    handleFilterOpen,
    handleFilterClose,
    handleFilterReset,
    filterStates,
    setSelectedFilterOptions,
    selectedFilterOptions,
    setFilterAmountRange,
    resetFilter,
    setSelectedDateFilterOptions,
    selectedDateFilters,
    minmumAmount,
    maximumAmount,
    searchText,
    handleResetSearch,
    onDateChange,
    selectedDateRange,
    filterAllCount,
    setSelectedDateRange,
    setAllSelect,
    isAllSelect,
    totalInvoices,
    filterStatus,
    filteringApplied,
    filterSearchParams,
    setFilteredParams,
    setResetFilter,
    enableQuerySearch,
    savedMinmumAmount,
    savedMaximumAmount,
    downloadStates,
    setDownloadStates,
  ] = handleApprovedData();

  const getUrlParams = () => {
    if (location?.search) {
      return {
        ...Qs.parse(location.search.substring(1)),
        pagination: { offset, rowCount },
      };
    }

    return {
      pagination: { offset, rowCount },
    };
  };

  useEffect(() => {
    if (filteringApplied) {
      navigate({
        pathname: '/earnings/approved',
        search: `?${createSearchParams(getSearchParams(filterSearchParams))}`,
      });
    }
  }, [filterSearchParams]);

  useEffect(() => {
    if (filteringApplied) {
      localStorage.setItem('ear-min', minmumAmount);
      localStorage.setItem('ear-max', maximumAmount);
    }
  }, [filteringApplied]);

  useEffect(() => {
    navigate({
      pathname: '/earnings/approved',
      search: `?${createSearchParams(getSearchParams(getUrlParams()))}`,
    });
  }, [offset, rowCount]);

  useEffect(() => {
    if (resetFilter) {
      navigate({ pathname: '/earnings/approved' });
      setResetFilter(!resetFilter);
    }
  }, [resetFilter]);

  const handleOnYesButtonClick = () => {
    handletheMapping();
    handleYesButtonClick();
  };

  useEffect(() => {
    updatePreferenceOnServer(routeType.EARNING_APPROVED);
  }, []);

  return (
    <>
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonVisible={false}
          isBackButtonHide
          paddingX={0}
          headerText={t('earning.headerText')}
          withCenteredHeaderText
        />
      </HeaderFixed>
      {/** Tab navigation */}
      <PaymentTabNav />
      <Wrapper isTabNavigationAvailable isAuthorizedWrapper>
        <WrpperAuthorizedContainer>
          {((submitData && submitData?.length > 0) ||
            searchInProgress ||
            enableQuerySearch ||
            enableQuerySearch) && (
            <>
              {enableSearchFilterSorting && (
                <SearchBar
                  onchangeHandler={handleSearch}
                  onClickEnableFilter={handleFilterOpen}
                  isSearchIconVisible={searchText === '' || searchText === undefined}
                  onResetSearch={handleResetSearch}
                  searchText={searchText}
                  filterCounterValue={filterAllCount}
                  onClickDownloadCSV={() =>
                    setDownloadStates({
                      ...downloadStates,
                      isDownloadEnable: true,
                    })
                  }
                  tabType="approved"
                  t={t}
                />
              )}
              <GridProvider
                accordion={{
                  activeAccordion: true,
                  activeAccordionOld: null,
                  enableAccordion: false,
                  isOldInvoice: false,
                  showMoreInprogressOld: false,
                }}
                filterStatus={filterStatus}
              >
                <PaymentTableGrid
                  termsSetDetails={termsSetDetails}
                  isAccordion={true}
                  accordionHeading={t('inVoiceCards.approvedInvoices')}
                  isTooltipInfoEnabled={false}
                  handleShowMore={(value: any) => handleShowMore(value)}
                  selectedRowCount={rowCount}
                  onRowCountChange={(val) => handleRowCountChange(val)}
                  dataObject={submitData}
                  paginationDisplayCondition={submitData && pageCount >= 1}
                  offset={offset}
                  pageCount={pageCount}
                  tabType="approved"
                  isTermEditable={true}
                  handleTermClick={(event: any) => handleTermClick(event, true)}
                  showLoader={showLoader}
                  onModalClose={handleFilterClose}
                  handleDataSorting={handleDataSorting}
                  onClickApplyFilter={handleApplyFilter}
                  selectedSortingOption={filterStates.sort}
                  setSelectedFilterOptions={setSelectedFilterOptions}
                  onFilterReset={handleFilterReset}
                  setFilterAmountRange={setFilterAmountRange}
                  selectedFilterOptions={selectedFilterOptions}
                  setSelectedDateFilterOptions={setSelectedDateFilterOptions}
                  selectedDateFilters={selectedDateFilters}
                  resetFilter={resetFilter}
                  minmumAmount={minmumAmount}
                  maximumAmount={maximumAmount}
                  onDateChange={onDateChange}
                  selectedDateRange={selectedDateRange}
                  isFilterEnable={filterStates && filterStates.isFilterEnable}
                  setSelectedDateRange={setSelectedDateRange}
                  setAllSelect={setAllSelect}
                  isAllSelect={isAllSelect}
                  isAccordionArrowVisible={false}
                  dataCount={totalInvoices}
                  savedMinmumAmount={savedMinmumAmount}
                  savedMaximumAmount={savedMaximumAmount}
                  iconArrowVisible={true}
                  downloadStates={downloadStates}
                  setDownloadStates={setDownloadStates}
                />
              </GridProvider>
            </>
          )}

          {/** Loader */}
          {submitData === undefined && <PaginationLoader />}

          {/** Empty list */}
          {submitData?.length === 0 && !searchInProgress && (
            <EmptyList
              heading={t('earningEmptyState.secondTabHeading')}
              subHeading={t('earningEmptyState.secondTabContent')}
              tabType="approved"
              t={t}
            />
          )}

          {termsSetDetails.modalOpen && enablePay1181 && (
            <ModalTermEdit
              onModalClose={handleModalClose}
              options={termsSetDetails.termsData}
              onTermChange={handleTermChange}
              isConfirmButtonDisable={
                termsSetDetails.initialLoadingTerm === termsSetDetails.updatedTerm
              }
              onConfirmButtonClick={handleConfirmButtonClick}
              onYesButtonClick={handleOnYesButtonClick}
              isConfirmed={termsSetDetails.showConfirmModal}
              updatedTerm={termsSetDetails.updatedTerm}
              selectedTerm={termsSetDetails.selectedTerm}
              error={termsSetDetails.error}
            />
          )}
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
