import { Lotties } from 'src/shared/utilities/lottieJson';
import { useTranslation } from 'react-i18next';

import {
  Wrapper,
  HeadingSummaryBlock,
  DivWrapper,
  LottieAnimation,
} from 'src/components/base/core';
import { Box } from '@mui/material';

export const OnHoldBlock = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <DivWrapper>
        <Box component={'div'} className={'container_content no_fullscreen no_header'}>
          <Box component={'div'} className={'container_content_top'}>
            <div className="status_animation">
              <LottieAnimation json={Lotties.HOLD_ON} />
            </div>
            <div className="content_block">
              <HeadingSummaryBlock
                heading={t('headingContent.statusses.onHold.heading')}
                headingAlignment="center"
                content={t('headingContent.statusses.onHold.content')}
                contentAlignment="center"
              />
            </div>
          </Box>
        </Box>
      </DivWrapper>
    </Wrapper>
  );
};
