import { Action } from '../actions';
import { ActionType } from '../../shared/utilities/interface';

const reducer = (state = {}, action: Action): any => {
  switch (action.type) {
    case ActionType.GET_ADDITIONAL_PHONE:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
