import { Progressbar } from '../../OtherUtilities/Progressbar/Progressbar';
import { Box, Typography } from '@mui/material';
import { LimitProgressCardProps } from 'src/models/component/base.model';
import { LimitsProgressCardBottom, LimitsProgressCardIcon } from './LimitsProgressCard.style';
import { AtmWithdrawalsIcon, CardSpendingsIcon, LimitsIcon } from '../../Media/Icon/Icons/Icons';
export const LimitsProgressCard = ({
  totalProgressSteps,
  currentProgressStep,
  progressValue,
  progressLabel,
  title,
  stepsContent,
  overallProgressLabel,
  overallProgressValue,
  isLeftIconEnabled = false,
  icon = 'CardSpendingsIcon',
  marginBottom = 3,
}: LimitProgressCardProps) => {
  const cardIcon = () => {
    switch (icon) {
      case 'CardSpendingsIcon':
        return <CardSpendingsIcon />;
      case 'AtmWithdrawalsIcon':
        return <AtmWithdrawalsIcon />;
      case 'LimitsIcon':
        return <LimitsIcon />;
      default:
        break;
    }
  };

  return (
    <Box marginBottom={marginBottom}>
      {title && (
        <Box marginBottom={5} display="flex" justifyContent={'flex-start'} alignItems={'center'}>
          {cardIcon()}
          <Typography
            component="p"
            variant="h2Bold"
            sx={{
              marginBottom: 0,
              marginLeft: 1.5,
              display: 'inline-flex',
              color: 'gray.900',
            }}
          >
            {title}
          </Typography>
        </Box>
      )}
      <LimitsProgressCardBottom>
        {isLeftIconEnabled && <LimitsProgressCardIcon>{cardIcon()}</LimitsProgressCardIcon>}
        <Progressbar
          isProgressInfoVisible
          overallProgressValue={overallProgressValue}
          overallProgressLabel={overallProgressLabel}
          progressLabel={progressLabel}
          progressValue={progressValue}
          currentProgressStep={currentProgressStep}
          totalProgressSteps={totalProgressSteps}
          progressBarHeight={'4px'}
          sxProgressLabel={{ fontWeight: '400', fontSize: '10px' }}
        />
      </LimitsProgressCardBottom>
    </Box>
  );
};
