import { ShadowCard } from 'src/components/base/core';
import { Box, Typography } from '@mui/material';
import { BottomRow } from './InvoiceDetailBottom.style';
import { InvoiceDetailProps } from 'src/models/component/base.model';
import { useTranslation } from 'react-i18next';
import moneyFormat from 'src/shared/utilities/moneyFormat';

export const InvoiceDetailBottom = ({ invoiceDetails }: InvoiceDetailProps) => {
  const { t } = useTranslation();
  const itemArr = invoiceDetails?.invoice_lines;
  let totalValue = 0;

  const invoicelineItems = () => {
    return itemArr.map((item: any, i: any) => {
      const qty = item?.quantity;
      return (
        <BottomRow className="content" key={i}>
          <Box className="content_left">
            <Typography variant="h2Bold" component={'p'} color={'gray.700'} marginBottom={1}>
              {item?.description}
            </Typography>
            <Typography variant="h1" component={'p'} color={'gray.500'}>
              {qty && `${qty} x `}
              {moneyFormat(item?.price)}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography variant="h2Bold" component={'p'} color={'gray.500'}>
              {moneyFormat(item?.total)}
            </Typography>
          </Box>
        </BottomRow>
      );
    });
  };
  return (
    <ShadowCard padding={4} marginBottom={4}>
      <Box>
        <Typography variant="h1Bold" color={'gray.500'}>
          {t('invoiceSummary.work.workText')}
        </Typography>
      </Box>

      <Box display={'flex'} flexDirection={'column'}>
        <BottomRow className="header">
          <Box className="header_left">
            <Typography variant="h1" fontSize={'10px'} color={'gray.500'}>
              {t('invoiceSummary.work.itemText')}
            </Typography>
          </Box>
          <Box className="header_right">
            <Typography variant="h1" fontSize={'10px'} color={'gray.500'}>
              {t('invoiceSummary.work.amountText')}
            </Typography>
          </Box>
        </BottomRow>
        {invoicelineItems()}
        <BottomRow className="total">
          <Box className="total_left">
            <Typography variant="caption1" component={'p'} color={'gray.700'}>
              {t('invoiceSummary.work.totalText')}
            </Typography>
          </Box>
          <Box className="total_right">
            <Typography variant="caption1" component={'p'} color={'gray.700'}>
              {invoiceDetails?.amount && moneyFormat(invoiceDetails?.amount)}
            </Typography>
          </Box>
        </BottomRow>
      </Box>
    </ShadowCard>
  );
};
