import { styled } from '@mui/material/styles';

export const WrapperDiv = styled('div')(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
);

export const WrapperContainer = styled('div')(
  ({ theme }) => `
  display: inline-flex;
  width: 100%;
`
);

export const FirstCol = styled('div')(
  ({ theme }) => `
  position: relative;
  
  &.equal_col_width {
    min-width: 50%;
  }

  &.not_equal_col_width {
    min-width: 40%;
  }

  &:after {
    content: '';
    top: 0;
    right: 0;
    bottom: 4px;
    display: block;
    width: 1px;
    position: absolute;
    background: ${theme.palette.gray[100]};
  }
 
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    padding-right: 12px;
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    padding-right: 30px;
  }
`
);

export const SecondCol = styled('div')(
  ({ theme }) => `
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    padding-left: 12px;
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    padding-left: 30px;
  }
`
);
