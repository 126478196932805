import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PayModeComponent, PayModeContainer, PayModeLeft, PayModeRight } from './PayModeCard.style';
import { Box, Typography } from '@mui/material';
import { PayModeCardProps } from 'src/models/component/base.model';
import { InstantPaymentsIcon } from '../../Media/Icon/Icons/Icons';
import { theme } from 'src/shared/theme/theme';

export const PayModeCard: React.FC<PayModeCardProps> = ({
  id,
  modeName,
  feePercentage,
  isActive = false,
  comingSoon = false,
  onCardClick,
  timeDuration,
  isIconVisible,
  feePercentageTextAlignment = 'left',
  isEstDateVisible = false,
  estDateValue = {
    submittedDate: '',
    arrivalDate: '',
  },
  isTransparentBackground = false,
}) => {
  const { t } = useTranslation();
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia('(min-width: 425px)');
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [matches]);

  return (
    <PayModeComponent>
      <PayModeContainer
        className={isActive ? 'active_card' : comingSoon ? 'coming_soon_card' : 'in_active_card'}
        onClick={() => {
          if (!comingSoon && onCardClick) {
            onCardClick(id);
          }
        }}
        sx={{
          alignItems: isEstDateVisible ? 'flex-start' : 'center',
          paddingY: isEstDateVisible ? 4 : 0,
          height: isEstDateVisible ? '68px' : '52px',
          background: isTransparentBackground ? 'transparent' : theme.palette.white,
        }}
      >
        <PayModeLeft>
          <Typography variant="h2ExtraBold" color={'primary.main'}>
            {modeName}
          </Typography>
        </PayModeLeft>

        <PayModeRight>
          {isIconVisible && <InstantPaymentsIcon />}
          {timeDuration && !isEstDateVisible && (
            <Typography
              variant="h2"
              color={'gray.700'}
              sx={{ marginRight: 3, width: '75px', marginLeft: 1 }}
            >
              {timeDuration}
            </Typography>
          )}

          {timeDuration && isEstDateVisible && (
            <Box
              sx={{ marginRight: 7, width: '110px', marginLeft: 1 }}
              display={'flex'}
              flexDirection={'column'}
            >
              <Box marginBottom={1}>
                <Typography
                  variant="h2"
                  color={'gray.700'}
                  sx={{ marginRight: 3, width: '75px', marginLeft: 1 }}
                  fontWeight={300}
                >
                  {timeDuration}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  paddingLeft: 1,
                }}
              >
                {estDateValue?.submittedDate !== '' && (
                  <Typography
                    variant="h2"
                    color={'gray.400'}
                    sx={{ textDecoration: 'line-through', marginRight: 3 }}
                    fontWeight={300}
                  >
                    {estDateValue?.submittedDate}
                  </Typography>
                )}
                {estDateValue?.arrivalDate !== '' && (
                  <Typography variant="h2" color={'gray.700'} fontWeight={300}>
                    {estDateValue?.arrivalDate}
                  </Typography>
                )}
              </Box>
            </Box>
          )}
          {feePercentage && (
            <Typography
              variant="h2"
              color={'gray.700'}
              sx={{ width: '62px', textAlign: feePercentageTextAlignment }}
              fontWeight={isEstDateVisible ? 300 : 400}
            >
              {feePercentage}
            </Typography>
          )}
        </PayModeRight>
      </PayModeContainer>
    </PayModeComponent>
  );
};
