import { Action } from '../actions';
import { ActionType, TermParams } from '../../shared/utilities/interface';
import { initTerms } from '../../models/init/terms.init';

const reducer = (state = [initTerms], action: Action): TermParams[] => {
  switch (action.type) {
    case ActionType.GET_TERMS:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
