import { styled } from '@mui/material/styles';

function appHeight() {
  const doc = document.documentElement;
  doc.style.setProperty('--vh', window.innerHeight * 0.01 + 'px');
}

window.addEventListener('resize', appHeight);
appHeight();

export const ModalWrapper = styled('div')(
  ({ theme }) => `
  display: flex; 
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  justify-content: center;
  align-items: center;

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    justify-content: center;
    align-items: center;
  }
`
);

export const ModalOverlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(26, 31, 54, 0.6);
  backdrop-filter: blur(0.5px);
  z-index: 1;
`;

export const ModalClose = styled('div')`
  width: 10px;
  height: 10px;
  position: absolute;
  right: 25px;
  right: 25px;
  cursor: pointer;
`;

export const ModalContainer = styled('div')(
  ({ theme }) => `
  background: linear-gradient(181.57deg, #e2e9ff -39.28%, #ffffff 49.33%);
  padding: 16px;
  z-index: 2;
  width: calc(100% - 46px);
  overflow-y: auto;
  max-height: calc(var(--vh, 1vh) * 100);

  &.is_fixed {
    width: 100%;
    padding: 24px 0;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    height: auto;
    max-width: 312px;
    border-radius: 8px;

    &.is_fixed {
      border-radius: 8px 8px 0px 0px;
      position: absolute;
      bottom: 0;
      padding-bottom: 80px;
      max-width: 100%;
      overflow-y: auto;
      min-height: calc(calc(var(--vh, 1vh) * 100) - 80px);
    }
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    border-radius: 8px;
    position: relative;
    height: auto;
    max-width: 312px;

    &.is_fixed {
      max-width: 400px;
    }
  }
`
);

export const ModalHeader = styled('div')(
  ({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing(7)};
`
);

export const ModalContent = styled('div')(
  ({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
`
);

export const ModalButton = styled('div')(
  ({ theme }) => `
  padding-left: ${theme.spacing(4)};
  padding-right: ${theme.spacing(4)};
  padding-top: ${theme.spacing(4)};
  padding-bottom: ${theme.spacing(8)};

  &.is_button_not_fixed {
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      max-width: 136px;
    }
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    &:not(.is_button_not_fixed) {
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;
      background-color: #fff;
      padding-left: ${theme.spacing(4)};
      padding-right: ${theme.spacing(4)};
      padding-top: ${theme.spacing(4)};
      padding-bottom: ${theme.spacing(8)};
    }
  }
`
);
