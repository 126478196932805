import { useState } from 'react';
import Qs from 'qs';
import jwt_decode from 'jwt-decode';
import { AuthCookie } from 'src/services/auth/auth.service';
import {
  useCreateConfirmVerificationCodeMutation,
  useCreateVerificationCodeMutation,
} from 'src/services/auth/authServiceApi';
import { SearchCoupaSupplier, SearchCoupaSupplierByEmail } from 'src/services/coupa/coupa.service';
import { getAppInit } from 'src/services/init/init.service';
import { configs, getMyAccountHeader } from 'src/shared/config/config';
import { setCookie } from 'src/shared/utilities/cookiesHelper';
import { encryptWithAES } from 'src/shared/utilities/encryptHelpper';
import { AddressParams, CoupaParams } from 'src/shared/utilities/interface';
import { store } from 'src/store';
import { ActionType } from 'src/store/action-types';
import { ISearchVendoDetails } from 'src/models/component/base.model';
import { setPrimaryContactsForEmailSignup } from 'src/shared/utilities/cooupaObject';

const forceEmailVerification = true;

const dispatchLoggingIn = (v: boolean) => {
  store.dispatch({
    type: ActionType.LOGGING_IN,
    payload: v,
  });
};

export const handleVerifyCode = () => {
  const inputCodeIdRef = [1, 2, 3, 4, 5, 6];
  const [createConfirmVerificationCode] = useCreateConfirmVerificationCodeMutation();
  const [createVerificationCode] = useCreateVerificationCodeMutation();
  const [values, setValues] = useState({
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    code5: '',
    code6: '',
  });
  const setPrimaryContacts = (
    data: CoupaParams | null,
    primary_address: AddressParams | null,
    id: string | null,
    phone: string | null,
    email: string | null
  ) => {
    const contactObj = {
      name: data ? data['name-given'] : null,
      lastName: data ? data['name-family'] : null,
      phone: phone ? phone.substring(2, phone.length) : null,
      email: data ? data?.email : email ? email : null,
      street1: primary_address ? primary_address?.street1 : null,
      street2: primary_address ? primary_address?.street2 : null,
      city: primary_address ? primary_address?.city : null,
      state: primary_address ? primary_address.state : null,
      postal_code: primary_address ? primary_address['postal-code'] : null,
      id: id ? id : null,
    };
    //encript object & store in cookies
    setCookie('coupaObj', encryptWithAES(JSON.stringify(contactObj), configs.ENCRYPT_PHRESE), 7);
  };

  const getInputValues = () => {
    let inpArray: any = [];
    inputCodeIdRef.map((index: any) => {
      let code: any = document.getElementById('code-' + index);
      inpArray.push(code.value);
    });
    const newFilteredArr = inpArray.filter(function (el: any) {
      return el !== '';
    });
    return {
      lnth: newFilteredArr.toString().replaceAll(',', '')?.length,
      val: newFilteredArr.toString().replaceAll(',', ''),
    };
  };

  const handleChange = async (
    values: any,
    navigate: any,
    phone: any,
    setError: any,
    setisVerified: any,
    setisWrong: any
  ) => {
    // @ts-ignore
    if (values?.length === 6) {
      if (phone != null) {
        setisWrong(false);
        dispatchLoggingIn(true);
        // @ts-ignore
        const response = await createConfirmVerificationCode({
          phone_number: phone,
          code: values,
        });
        // @ts-ignore
        if (response && response?.data?.error) {
          // @ts-ignore
          setError(response?.data?.error_description);
          setisVerified(false);
          setisWrong(true);
          dispatchLoggingIn(false);
          return;
        }

        // @ts-ignore
        const { id_token, refresh_token } = response?.data?.id_token
          ? // @ts-ignore
            response?.data
          : { id_token: null, refresh_token: '' };

        // @ts-ignore
        if (response?.data && id_token != null) {
          await AuthCookie(id_token);
          setCookie('T_added', encryptWithAES('true', configs.ENCRYPT_PHRESE), 7);
          if (window.location.port === '3000' && !window.location.origin.includes('payup')) {
            localStorage.setItem('id_token', id_token);
            localStorage.setItem('refresh_token', refresh_token);
          }
          setisVerified(true);
          setisWrong(false);
          localStorage.removeItem('approved-account');
          localStorage.removeItem('set_id_modal_states');
          const userAccountInformation = await getAppInit();
          const myAccountHeader =
            userAccountInformation?.data?.accountByIdpId ??
            userAccountInformation?.data?.accountByPhone;
          if (myAccountHeader) {
            localStorage.setItem('init_login', 'true');
            setTimeout(function () {
              navigate('/');
            }, 200);
            return;
          }
          let response = await SearchCoupaSupplier(phone);

          if (response?.data?.length === 0) {
            response = await SearchCoupaSupplier(phone, 'mobile');
            if (response?.data?.length > 0) {
              // @ts-ignore
              setPrimaryContacts(
                response?.data[0]['primary-contact'],
                response?.data[0]['primary-address'],
                response?.data[0]?.id,
                phone
              );
              setTimeout(function () {
                navigate('/');
              }, 200);
            } else {
              // @ts-ignore
              setPrimaryContacts(null, null, null, phone);
              setTimeout(function () {
                navigate('/');
              }, 200);
            }
          } else {
            // @ts-ignore
            setPrimaryContacts(
              response?.data[0]['primary-contact'],
              response?.data[0]['primary-address'],
              response?.data[0]?.id,
              phone
            );
            setTimeout(function () {
              navigate('/');
            }, 200);
          }
        }
        dispatchLoggingIn(false);
      } else {
        navigate('/');
      }
    }
  };

  const handleAuthCallback = async (
    callBackData: string,
    navigate: any,
    setError: any,
    setisVerified: any,
    setisWrong: any,
    setEmailVerified: any,
    setUserEmail: any,
    getFromResend: any,
    getFromLogin: any,
    getFromSignup: any
  ) => {
    let code = undefined;
    if (callBackData) {
      code = Qs.parse(callBackData.substring(1))?.code;
    }

    if (code) {
      setUserEmail('');
      setisWrong(false);
      dispatchLoggingIn(true);
      setEmailVerified('notProceedYet');

      // @ts-ignore
      const response = await createConfirmVerificationCode({
        code: code,
        redirect_uri: `${window.location.origin}/verify-code`,
      });

      // @ts-ignore
      if (response && response?.data?.error) {
        // @ts-ignore
        setError(response?.data?.error_description);
        setisVerified(false);
        setisWrong(true);
        setEmailVerified('errorLogin');
        dispatchLoggingIn(false);
        return;
      }

      // @ts-ignore
      const id_token = response?.data?.id_token ?? null;

      // @ts-ignore
      if (response?.data && id_token != null) {
        const tokenData = jwt_decode<{ email: string; email_verified: boolean }>(id_token);
        const { email, email_verified } = tokenData;

        const getVendorDetailsFromStorage = localStorage.getItem('vendorDetails');
        const getVendorDetailsObj: ISearchVendoDetails =
          getVendorDetailsFromStorage && JSON.parse(getVendorDetailsFromStorage);

        await AuthCookie(id_token);
        if (!forceEmailVerification || email_verified) {
          setCookie('T_added', encryptWithAES('true', configs.ENCRYPT_PHRESE), 7);
          if (window.location.port === '3000' && !window.location.origin.includes('payup')) {
            localStorage.setItem('id_token', id_token);
          }

          setEmailVerified('verified');

          setisVerified(true);
          setisWrong(false);
          localStorage.removeItem('approved-account');
          localStorage.removeItem('set_id_modal_states');
          const userAccountInformation = await getAppInit();

          const myAccountHeader = getMyAccountHeader(userAccountInformation);

          if (myAccountHeader) {
            localStorage.setItem('init_login', 'true');
            setTimeout(function () {
              navigate('/');
            }, 200);
            return;
          }

          let response = null;
          if (email) {
            response = await SearchCoupaSupplierByEmail(email);
          }

          // if (response?.data?.length > 0) {
          if (getFromResend !== 'yes' && (getFromLogin === 'yes' || getFromSignup === 'yes')) {
            setTimeout(function () {
              navigate('/', {
                state: { verifiedEmail: true },
              });
            }, 200);

            if (
              !localStorage.getItem('vendorDetails') &&
              localStorage.getItem('vendorDetails') === undefined
            ) {
              setPrimaryContacts(null, null, null, null, email);
            } else {
              setPrimaryContactsForEmailSignup(
                null,
                null,
                null,
                getVendorDetailsObj.phone,
                getVendorDetailsObj.email,
                getVendorDetailsObj.firstName,
                getVendorDetailsObj.lastName,
                getVendorDetailsObj.businessName
              );
            }
          } else {
            setPrimaryContactsForEmailSignup(
              null,
              null,
              null,
              getVendorDetailsObj.phone,
              getVendorDetailsObj.email,
              getVendorDetailsObj.firstName,
              getVendorDetailsObj.lastName,
              getVendorDetailsObj.businessName
            );
          }
        } else {
          setUserEmail(email);
          setEmailVerified('unVerified');

          setPrimaryContactsForEmailSignup(
            null,
            null,
            null,
            getVendorDetailsObj.phone,
            getVendorDetailsObj.email,
            getVendorDetailsObj.firstName,
            getVendorDetailsObj.lastName,
            getVendorDetailsObj.businessName
          );

          if (window.location.port === '3000' && !window.location.origin.includes('payup')) {
            localStorage.setItem('id_token', id_token);
          }

          localStorage.setItem('userEmail', email);
          localStorage.setItem('from_login', 'no');
          localStorage.setItem('from_resend', 'yes');
        }
      }
      dispatchLoggingIn(false);
    } else {
      setEmailVerified('unVerified');
    }
  };

  const handleResendCode = async (phone: any, setisWrong: any) => {
    // clearInputs(setisWrong);
    try {
      if (phone != null) {
        const response = createVerificationCode(phone);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleOnKeydown = (e: any) => {
    if (e.keyCode === 8 && e.target.value === '') {
      const previousIndex =
        e.target?.id.substr(e.target?.id.length - 1) !== 0
          ? e.target?.id.substr(e.target?.id.length - 1) - 1
          : 0;
      let code: any = document.getElementById(`code-${previousIndex}`);
      code.focus();
    }
  };

  return [
    setPrimaryContacts,
    getInputValues,
    inputCodeIdRef,
    handleChange,
    setValues,
    // clearInputs,
    handleResendCode,
    handleOnKeydown,
    handleAuthCallback,
  ];
};
