import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { theme, commonSpacing } from 'src/shared/theme/theme';

function appHeight() {
  const doc = document.documentElement;
  doc.style.setProperty('--vh', window.innerHeight * 0.01 + 'px');
}

window.addEventListener('resize', appHeight);
appHeight();

const spacingContainer = {
  maxScreenHeightDesktop: '800px',
  maxScreenHeightMobile: 'calc(var(--vh, 1vh) * 100)',
  formWrapperHeightMobile: 'calc(var(--vh, 1vh) * 100)',
  containerContentHeightMobile: 'calc(var(--vh, 1vh) * 100)',
  buttonSpacing: '72px',
  contentSpacing: '72px',
  termsNoteSpacing: '48px',
  buttonPositionFromTop: 'calc(100% - 72px)',
  buttonAddtionalSpacing: '12px',
  mobileContentContainerContentMinHeight: 'calc((var(--vh, 1vh) * 100) - 96px)',
  mobileContentTopMinHeight: 'calc((var(--vh, 1vh) * 100) - 186px)',
  mobileTermsContentTopMinHeight: 'calc((var(--vh, 1vh) * 100) - 168px)',
};

export const FormWrapper = styled('form')`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .status_animation,
  .status_image {
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 24px;
    box-sizing: border-box;
  }

  .container_content {
    padding-left: 16px;
    padding-right: 16px;
    position: relative;

    .addition_element {
      padding-top: 24px;
    }

    &_bottom {
      display: flex;
      align-items: flex-end;
    }

    &.no_header {
      .container_content_top {
        display: flex;
        flex-direction: column;
      }
    }
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    min-height: ${spacingContainer.maxScreenHeightDesktop};
    height: auto;

    &.no_fullscreen {
      padding-bottom: calc(${spacingContainer.buttonSpacing});
    }

    .container_content {
      &.no_header {
        padding-top: ${theme.spacing(commonSpacing.desktopPaddingTopNoHeader)};
      }
    }

    &.no_fullscreen {
      min-height: 1px;
      .container_content {
        &_bottom {
          min-height: 1px;
          margin-bottom: ${theme.spacing(3)};
          width: 100%;

          &.terms_note {
            min-height: ${spacingContainer.termsNoteSpacing};
          }
        }

        &_top {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .add_bottom_padding {
      padding-bottom: ${theme.spacing(commonSpacing.desktopPaddingB)};
    }
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    height: ${spacingContainer.formWrapperHeightMobile};
    height: -webkit-fill-available;
    min-height: ${spacingContainer.maxScreenHeightMobile};

    &.no_fullscreen {
      padding-bottom: 96px;
    }

    &.full_height {
      padding-bottom: 0;
    }

    .container_content {
      min-height: ${spacingContainer.mobileContentContainerContentMinHeight};
      padding-bottom: 0;
      position: relative;

      &.bottom_terms_padding {
        padding-bottom: 72px;

        &.terms_note {
          padding-bottom: 36px;
        }
      }

      &_top {
        min-height: 1px;
      }

      &_bottom {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        left: 16px;
        right: 16px;
      }

      &.no_header {
        padding-top: 48px;

        .container_content_top {
          justify-content: center;
        }
      }
    }

    &.no_fullscreen {
      height: auto;

      .container_content {
        max-height: initial;
        overflow-y: visible;
      }
    }
  }
`;

export const DivWrapper = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .status_animation,
  .status_image {
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 24px;
    box-sizing: border-box;
  }

  .container_content {
    padding-left: 16px;
    padding-right: 16px;
    position: relative;

    .addition_element {
      padding-top: 24px;
    }

    &_bottom {
      display: flex;
      align-items: flex-end;
    }

    &.no_header {
      .container_content_top {
        display: flex;
        flex-direction: column;
      }
    }
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    min-height: ${spacingContainer.maxScreenHeightDesktop};
    height: auto;

    &.no_fullscreen {
      padding-bottom: calc(${spacingContainer.buttonSpacing});
    }

    .container_content {
      &.no_header {
        padding-top: ${theme.spacing(commonSpacing.desktopPaddingTopNoHeader)};
      }
    }

    &.no_fullscreen {
      min-height: 1px;
      .container_content {
        &_bottom {
          min-height: 1px;
          margin-bottom: ${theme.spacing(3)};
          width: 100%;

          &.terms_note {
            min-height: ${spacingContainer.termsNoteSpacing};
          }
        }

        &_top {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .add_bottom_padding {
      padding-bottom: ${theme.spacing(commonSpacing.desktopPaddingB)};
    }
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    height: ${spacingContainer.formWrapperHeightMobile};
    height: -webkit-fill-available;
    min-height: ${spacingContainer.maxScreenHeightMobile};

    &.no_fullscreen {
      padding-bottom: 96px;
    }

    &.full_height {
      padding-bottom: 0;
    }

    .container_content {
      min-height: ${spacingContainer.mobileContentContainerContentMinHeight};
      padding-bottom: 0;
      position: relative;

      &.bottom_terms_padding {
        padding-bottom: 72px;

        &.terms_note {
          padding-bottom: 36px;
        }
      }

      &_top {
        min-height: 1px;
      }

      &_bottom {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        left: 16px;
        right: 16px;
      }

      &.no_header {
        padding-top: 48px;

        .container_content_top {
          justify-content: center;
        }
      }
    }

    &.no_fullscreen {
      height: auto;

      .container_content {
        max-height: initial;
        overflow-y: visible;
      }
    }
  }
`;

export const ContentContainerBottom = styled('div')`
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  background: ${theme.palette.white};
  z-index: 10;
  transition: 0.4s ease all;

  * {
    transition: 0.4s ease all;
  }

  .container_bottom_wrapper {
    width: 100%;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    position: fixed;
    left: 24px;
    right: 24px;
    top: ${spacingContainer.buttonPositionFromTop};

    .container_bottom_wrapper {
      width: 100%;
    }

    &.no_fullscreen {
      position: absolute;
      bottom: 0;
      padding-bottom: calc(
        ${spacingContainer.buttonAddtionalSpacing} + ${spacingContainer.buttonAddtionalSpacing}
      );
      padding-left: 16px;
      padding-right: 16px;
      top: auto;
      right: 0;
      left: 0;
    }
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    position: absolute;
    bottom: 0;

    &.no_fullscreen {
      padding-bottom: calc(${spacingContainer.buttonAddtionalSpacing} * 0.75);
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`;

export const NotTextWrapper = styled('div')`
  display: flex;
  padding-top: 12px;
  margin-bottom: 16px;
  align-items: center;
  width: 100%;

  a {
    font-size: 12px;
    font-weight: 400;
    color: ${theme.palette.gray[800]};
    text-decoration: underline;
  }

  .content_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &.not_note {
    visibility: hidden;
    pointer-events: none;
  }

  .icon_wrapper {
    width: 18px;
    margin-right: 12px;
  }

  svg {
    height: 18px;
  }
`;

export const UpdateCardWrapper = styled('div')(
  ({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.palette.gray[100]};
  border-radius: 4px;
  position: relative;
  padding: ${theme.spacing(6, 4)};

  .update_wrapper_bottom {
    padding-top: ${theme.spacing(2)};
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .is_shadow_card {
    width: 100%;
    height: auto;
    background: ${theme.palette.white};
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.06), 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
`
);

export const UpdateFormWrapper = styled('form')(
  ({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    &.no_bottom_margin {
      margin-bottom: ${theme.spacing(1)};
    }
  }

  &.is_shadow_card .update_wrapper_top {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: ${theme.spacing(4)};

    &.no_bottom_margin {
      margin-bottom: ${theme.spacing(1)};
    }

    &.no-mobile-padding,
    &.is_shadow_card {
      padding-left: 0;
      padding-right: 0;
    }
  }
`
);

export const UpdateFormTop = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
  align-items: center;
`;

export const DocVerifiedLink = styled('div')`
  display: flex;

  &.pointer_disabled {
    pointer-events: none;
  }

  svg {
    height: 20px;
    color: #635bff;
  }
`;

export const WrpperAuthorized = styled('div')(
  ({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    &:not(.is-native-wrapper) {
      height: calc(calc(var(--vh, 1vh) * 100) - 154px);
    }
    
    &.tab_available:not(.is-native-wrapper) {
      height: calc(calc(var(--vh, 1vh) * 100) - 218px);
    }

    &.is-native-wrapper {
      height: calc(calc(var(--vh, 1vh) * 100) - 84px);

      &.tab_available:not(is-native-wrapper) {
        height: calc(calc(var(--vh, 1vh) * 100) - 148px);
      }
    }
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    height: calc(100vh - 100px);

    &.tab_available {
      height: calc(100vh - 172px);
    }


    &.setting_desktop_wrapper {
      height: auto;
      overflow-y: visible;
      padding-top: ${theme.spacing(4.5)};
      padding-bottom: ${theme.spacing(4.5)};
    }
  }
`
);

export const WrpperAuthorizedContainer = styled('div')(
  ({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  max-width: 600px;
  margin: 0 auto;
  
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    padding: ${theme.spacing(0, 4)}
  }
`
);

export const HeaderFixed = styled('div')(
  ({ theme }) => `
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.loader_wrapper_header {
      position: relative;
    }

    > div {
      width: 100%;
      max-width: ${theme.breakpoints.values.sm}px;
    }

    @media (max-width: ${theme.breakpoints.values.sm}px) {
     padding-left: 16px;
     padding-right: 16px;

      &.loader_wrapper_header{
        z-index: 10;
      }
    }

    @media (min-width: ${theme.breakpoints.values.sm}px) {
      &.loader_wrapper_header{
        z-index: 10;
      }
    }
  `
);

export const FormAuthorizedWrapper = styled('form')`
  width: 100%;
  height: auto;
  position: relative;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    min-height: calc(calc(var(--vh, 1vh) * 100) - 154px);
    padding-bottom: 64px;

    &.note_notify {
      padding-bottom: 114px;
    }
  }
`;

export const DivAuthorizedWrapper = styled('div')`
  width: 100%;
  height: auto;
  position: relative;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    min-height: calc(calc(var(--vh, 1vh) * 100) - 154px);
    padding-bottom: 64px;

    &.note_notify {
      padding-bottom: 114px;
    }
  }
`;
export const ButtonAuthorizedWrapper = styled('div')`
  width: 100%;
  height: auto;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    position: absolute;
    bottom: 8px;
    left: 0;
    right: 0;
    transition: all 0.4s ease;
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    padding-top: 12px;
  }
`;

export const NoteWrapperAuthorizedWrapper = styled('div')`
  width: 100%;
  height: auto;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    position: absolute;
    bottom: 64px;
    left: 0;
    right: 0;
  }
`;

export const ShadowCard = styled(Box)(
  ({ theme }) => `
  width: 100%;
  height: auto;
  background: ${theme.palette.white};
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.06), 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  &.is_transparent {
    background: transparent;
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.06)) drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.1));
  }

  &.no_shadow {
    box-shadow: none;
    border-top: 1px solid ${theme.palette.gray[50]};
  }

  &.remove_all_shadow {
    box-shadow: none;
    border: none;
  }

  &.full_border {
    box-shadow: none;
    border: 1px solid ${theme.palette.gray[100]};
  }
`
);
