import axios from 'axios';
import config from './../config';

export const getAppInit = async () => {
  config(false);
  return axios
    .post(`/query/my-account`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return JSON.parse(error.request.response);
    });
};
