import React from 'react';
import ReactDOM from 'react-dom';
import {
  ModalWrapper,
  ModalOverlay,
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalButton,
  ModalClose,
  ModalResetAll,
} from './FilterModal.style';
import { Icon } from '../../../../..';
import { AmountFilter, DateFilter, TermsFilter } from '../FilterRows';
import { DataSorting } from '../DataSorting/DataSorting';
import { Button, Typography } from '@mui/material';
import { ShadowCard } from '../../../../..';
import { useTranslation } from 'react-i18next';
import { invoiceBatchTabsType } from 'src/shared/utilities/interface';

const domNode: any = document.getElementById('app-root');

export interface ModalFilterProps {
  onModalClose?: () => void;
  handleDataSorting?: any;
  onClickApplyFilter?: any;
  selectedSortingOption?: string;
  onFilterReset?: () => void;
  setFilterAmountRange?: any;
  setSelectedFilterOptions?: (filterObj: any) => void;
  selectedFilterOptions?: any;
  resetFilter?: any;
  setSelectedDateFilterOptions?: (filterObj: any) => void;
  selectedDateFilters?: any;
  minmumAmount?: any;
  maximumAmount?: any;
  tabType?: invoiceBatchTabsType;
  onDateChange?: (filterObj: any) => void;
  selectedDateRange?: any;
  selectedMinRange?: any;
  setSelectedMinRange?: any;
  setSelectedDateRange?: (filterObj: any) => void;
  setAllSelect?: any;
  isAllSelect?: any;
  isTransactionGrid?: boolean;
}

export const FilterModal = ({
  onModalClose,
  handleDataSorting,
  onClickApplyFilter,
  selectedSortingOption,
  onFilterReset,
  setFilterAmountRange,
  setSelectedFilterOptions,
  selectedFilterOptions,
  resetFilter,
  setSelectedDateFilterOptions,
  selectedDateFilters,
  minmumAmount,
  maximumAmount,
  tabType,
  onDateChange,
  selectedDateRange,
  selectedMinRange,
  setSelectedMinRange,
  setSelectedDateRange,
  setAllSelect,
  isAllSelect,
  isTransactionGrid,
}: ModalFilterProps) => {
  const onFilterResetNew = () => {
    setFilterAmountRange({ min: minmumAmount, max: maximumAmount });
    setSelectedMinRange({ min: minmumAmount, max: maximumAmount });
    onFilterReset && onFilterReset();
  };
  const { t } = useTranslation();

  return ReactDOM.createPortal(
    <>
      <ModalWrapper>
        <ModalOverlay onClick={onModalClose} />
        <ModalContainer className="is_fixed">
          <ModalResetAll onClick={onFilterResetNew}> {t('earning.filter.resetAll')}</ModalResetAll>
          <ModalClose onClick={onModalClose}>
            <Icon iconType="CloseIcon" />
          </ModalClose>
          <ModalHeader>
            <Typography variant="h3Bold" color="gray.800" component={'p'} textAlign="center">
              {t('earning.filter.title')}
            </Typography>
          </ModalHeader>
          <ModalContent>
            <ShadowCard paddingY={2}>
              <AmountFilter
                setFilterAmountRange={setFilterAmountRange}
                resetFilter={resetFilter}
                minmumAmount={minmumAmount}
                maximumAmount={maximumAmount}
                selectedMinRange={selectedMinRange}
                setSelectedMinRange={setSelectedMinRange}
              />
              <DateFilter
                setSelectedDateFilterOptions={setSelectedDateFilterOptions}
                selectedDateFilters={selectedDateFilters}
                resetFilter={resetFilter}
                onDateChange={onDateChange}
                selectedDateRange={selectedDateRange}
                setSelectedDateRange={setSelectedDateRange}
              />
              {isTransactionGrid ? null : (
                <TermsFilter
                  setSelectedFilterOptions={setSelectedFilterOptions}
                  selectedFilterOptions={selectedFilterOptions}
                  resetFilter={resetFilter}
                  setAllSelect={setAllSelect}
                  isAllSelect={isAllSelect}
                />
              )}
              <DataSorting
                handleOnChange={handleDataSorting}
                selectedOption={selectedSortingOption}
                tabType={tabType}
                isTransactionGrid={isTransactionGrid}
              />
            </ShadowCard>
          </ModalContent>
          <ModalButton>
            <Button
              variant="containedLarge"
              color="primary"
              type="button"
              fullWidth
              onClick={onClickApplyFilter}
              sx={{ overflow: 'hidden' }}
            >
              {t('earning.filter.applyBtn')}
            </Button>
          </ModalButton>
        </ModalContainer>
      </ModalWrapper>
    </>,
    domNode
  );
};
