export const Images = {
  LANDING_GET_STARTED_IMG: require('../../assets/images/landing-page-get-started.png'),
  PLACE_HOLDER_IMAGE: require('../../assets/images/placeholder.png'),
  SIGNUP_SUCCESS_IMG: require('../../assets/images/signup-success.png'),
  ON_HOLD_IMG: require('../../assets/images/on-hold.png'),
  BANK_SETUP_SUCCESS: require('../../assets/images/status/bank-setup-success.png'),
  VERIFICATION_FAILED: require('../../assets/images/status/verification-failed.png'),
  SIGNUP_SUCCESS: require('../../assets/images/status/signup-success.png'),
  SUBMIT_DOCUMENT: require('../../assets/images/status/signup-success.png'),
  PLACEHOLDER_FRONT_PHOTO: require('../../assets/images/uploads/front-photo.png'),
  PLACEHOLDER_BACK_PHOTO: require('../../assets/images/uploads/back-photo.png'),
  DOC_UPLOAD_DONE: require('../../assets/images/status/doc_upload_done.png'),
  NOTIFICATIONS: require('../../assets/images/status/notifications.png'),
  REFER_A_FRIEND: require('../../assets/images/refrer-a-friend.png'),
  FINANCE_ACCOUNT_BANNER: require('../../assets/images/fa-banner.png'),
  V_CARD: require('../../assets/images/virtual-card.png'),
  Finance: {
    FINANCE_ANNOUNCEMENT_BANNER: require('../../assets/images/finance/announcement-banner.png'),
    MANAGE_CARD: require('../../assets/images/finance/manage-card.png'),
    MANAGE_CARD_BG: require('../../assets/images/finance/manage-card-bg.png'),
    TOP_BANNER: require('../../assets/images/finance/splash-screen-top-banner.png'),
  },
};

export const SliderImages = {
  SLIDE01: require('../../assets/images/slides/slide-01.png'),
  SLIDE02: require('../../assets/images/slides/slide-02.png'),
  SLIDE03: require('../../assets/images/slides/slide-03.png'),
  SLIDE04: require('../../assets/images/slides/slide-04.png'),
  SLIDE05: require('../../assets/images/slides/slide-05.png'),
};

export const SliderImagesFA = {
  SLIDE01: require('../../assets/images/fa-slider/slide-1.png'),
  SLIDE02: require('../../assets/images/fa-slider/slide-2.png'),
  SLIDE03: require('../../assets/images/fa-slider/slide-3.png'),
  SLIDE04: require('../../assets/images/fa-slider/slide-4.png'),
};

export const LargeIcons = {
  inviteFriend: require('../../assets/images/icons/invite_friend.png'),
  inviteCustomer: require('../../assets/images/icons/invite-customers.png'),
  notifications: require('../../assets/images/icons/notifications.png'),
  giftIcon: require('../../assets/images/icons/gift-icon.png'),
  invoiceDownload: require('../../assets/images/icons/invoice-download.png'),
  invoiceEmptyState: require('../../assets/images/icons/invoice-empty-state.png'),
};

export const EmptyStates = {
  aprroved: require('../../assets/images/status/empty-states/aprroved.png'),
  submitted: require('../../assets/images/status/empty-states/submitted.png'),
  paid: require('../../assets/images/status/empty-states/paid.png'),
};

export const BackgroundImages = {
  backgrounTenPercentOpacity: require('../../assets/images/background/bg-10-percent.png'),
  backgrounTwentyFivePercentOpacity: require('../../assets/images/background/bg-25-percent.png'),
};
