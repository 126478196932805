import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';

export interface TermsSelectionProps {
  handleTermConfirmationName?: any;
  handleTermConfirmationDocument?: any;
  ischecked?: boolean;
  readonly?: boolean;
}

export const TermsSelection = ({
  handleTermConfirmationName,
  handleTermConfirmationDocument,
  ischecked,
  readonly = false,
}: TermsSelectionProps) => {
  const { t } = useTranslation();
  return (
    <Box component={'div'}>
      <Box component={'div'} marginBottom={2}>
        <Typography variant="h1" color={'gray.700'} sx={{ lineHeight: 1.4 }}>
          {t('idDocument.step3.modalTerm')}
        </Typography>
      </Box>
      <FormControlLabel
        sx={{ marginBottom: 0 }}
        control={
          <Checkbox
            id={'confirm_color_document'}
            value={'confirm_color_document'}
            name="confirm_color_document"
            size="small"
            onChange={handleTermConfirmationDocument}
            readOnly={readonly}
          />
        }
        label={
          <Typography variant="h1" color={'gray.700'}>
            {t('idDocument.step3.firstModalCheckBox')}
          </Typography>
        }
      />

      <FormControlLabel
        sx={{ marginBottom: 0 }}
        control={
          <Checkbox
            id={'confirm_legal_name'}
            value={'confirm_legal_name'}
            name="confirm_legal_name"
            size="small"
            onChange={handleTermConfirmationName}
            readOnly={readonly}
          />
        }
        label={
          <Typography variant="h1" color={'gray.700'}>
            {t('idDocument.step3.secondModalCheckBox')}
          </Typography>
        }
      />
    </Box>
  );
};
