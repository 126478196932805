import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const BackIcon = styled(Box)(
  ({ theme }) => `
   justify-content: flex-start;
   cursor: pointer;

    svg {
     fill: ${theme.palette.gray[600]};
     position: relative;
     left: -8px;
    }
`
);

export const HeaderTextBlock = styled(Box)(
  ({ theme }) => `
  @media (min-width: ${theme.breakpoints.values.sm}px) {
    .heading_content{
      display: none;
    }
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    .logo_wrapper{
      display: none;
    }
  }
`
);

export const InvoiceDownLoadLink = styled('a')(
  ({ theme }) => `
  display: inline-flex;
  cursor: pointer;

  img {
    height: 20px;
    width: auto;
  }

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    margin-left: ${theme.spacing(6)};
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin-left: ${theme.spacing(4)};
  }
`
);
