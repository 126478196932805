import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import { HeadingSummaryBlock } from '../../CommonNestedBlocks/ContentBlocks/HeadingSummaryBlock';

import {
  LinearLoader,
  LoadedBg,
  LoaderBg,
  LoadersRow,
  Swiper,
  TransparentBox,
} from './SplashSliderFinances.style';
// import { configs } from 'src/shared/config/config';
import { theme } from 'src/shared/theme/theme';
import { splashScreenItems } from 'src/shared/content/content';

export const SplashSliderFinances = () => {
  const { t, i18n } = useTranslation();
  const [displayIndex, setDisplayIndex] = useState(0);
  const [slideToLeft, setSlideToLeft] = useState(false);
  const [loaderPaused, setLoaderPaused] = useState(false);

  const slideLeft = () => {
    setSlideToLeft(true);
    setDisplayIndex(displayIndex + 1 < items.length ? displayIndex + 1 : 0);
  };

  const slideRight = () => {
    setSlideToLeft(false);
    setDisplayIndex(displayIndex > 0 ? displayIndex - 1 : items.length - 1);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => slideLeft(),
    onSwipedRight: () => slideRight(),
    onTouchStartOrOnMouseDown: () => setLoaderPaused(true),
    onTouchEndOrOnMouseUp: () => setLoaderPaused(false),
    delta: 5,
  });

  const [items] = splashScreenItems({ t: t });

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
        '& > :not(style)': {
          m: 0,
          width: 370,
        },
      }}
    >
      {displayIndex < items.length ? (
        <Box sx={{ position: 'relative' }}>
          {items.map((item, i) => {
            if (i === displayIndex) {
              return (
                <Swiper key={i} toLeft={slideToLeft}>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'center',
                      [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                        height: `240px !important`,
                        marginBottom: 12,
                      },
                      [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                        height: 'auto !important',
                        marginBottom: 6,
                      },
                    }}
                  >
                    <img
                      src={item.imagePath}
                      alt={item.heading}
                      loading="lazy"
                      style={{ height: 'auto', width: '100%' }}
                    />
                  </Box>
                  <Box
                    component={'div'}
                    sx={{
                      marginX: 'auto',
                      width: i18n.resolvedLanguage !== 'en' ? 370 : 327,
                      height: '180px',
                    }}
                  >
                    <HeadingSummaryBlock
                      heading={item.heading}
                      content={item.content}
                      headingAlignment="center"
                      contentAlignment="center"
                      spacing={1}
                    />
                  </Box>
                </Swiper>
              );
            }
            return null;
          })}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <LoadersRow>
              {items.map((_item, i) => {
                if (i < displayIndex) {
                  return <LoadedBg key={i} />;
                }
                if (i === displayIndex) {
                  return (
                    <LoaderBg key={i}>
                      <LinearLoader
                        style={{ animationPlayState: loaderPaused ? 'paused' : 'running' }}
                        onAnimationEnd={() => {
                          setSlideToLeft(true);
                          if (displayIndex + 1 < items.length) {
                            setDisplayIndex(displayIndex + 1);
                          } else {
                            setDisplayIndex(0);
                          }
                        }}
                        displayTime={3000}
                      />
                    </LoaderBg>
                  );
                }
                return <LoaderBg key={i} />;
              })}
            </LoadersRow>
          </Box>
          <TransparentBox {...swipeHandlers} />
        </Box>
      ) : null}
    </Box>
  );
};
