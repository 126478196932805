import { ShadowCard } from 'src/components/base/core';
import { Typography } from '@mui/material';
import {
  TopRow,
  TagWrapper,
  AmountSection,
  SecondRow,
  BottomSection,
  EditIconWrapper,
} from './BatchDetailTop.style';
import { BatchDetailProps } from 'src/models/component/base.model';
import moneyFormat from 'src/shared/utilities/moneyFormat';
import {
  dateFormatMMMSpaceDD,
  dateFormatMMMSpaceDDYYYY,
  getArrivalTimeforInstantPayment,
} from 'src/shared/utilities/dob';
import { handlePayModeDisplay } from 'src/shared/utilities/dataConvertions';
import { EditFilledIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';

export const BatchDetailTop = ({
  batchDetails,
  onClickEditPayMode,
  isInstantPayMode,
  t,
  isCheckPayModeEditFeaturedFlaged,
}: BatchDetailProps) => {
  const isPendingPayout = batchDetails?.status === 'pending';
  const isPaidPayout = batchDetails?.status === 'paid';
  const isPaymodeEditable = isPendingPayout;
  const isInstantPayoutsExceptPaidStatus = !isPaidPayout && isInstantPayMode;

  const getBottomLineText = () => {
    return `${batchDetails?.batch_number ?? 'N/A'} | ${
      batchDetails?.property_name ?? batchDetails?.customer
    }`;
  };

  const getDateTextFirstPart = () => {
    if (isPaidPayout) {
      return t('invoiceSummary.top.arrivedOn');
    }

    return t('invoiceSummary.top.estdToArriveOn');
  };

  const getDateTextSecondPart = () => {
    const estimatedArrivedOnDateForInstantPayouts = `${dateFormatMMMSpaceDD(
      batchDetails?.payment_arrival_estimated_date
    )}, ${getArrivalTimeforInstantPayment(batchDetails?.payment_arrival_estimated_date)} CST`;

    const payArrivalDate =
      batchDetails?.actual_arrival_date ?? batchDetails?.payment_arrival_estimated_date;
    return isInstantPayoutsExceptPaidStatus
      ? estimatedArrivedOnDateForInstantPayouts
      : dateFormatMMMSpaceDDYYYY(payArrivalDate);
  };

  const isCheckPayModeOnlyEditable =
    batchDetails?.method === 'check' || isCheckPayModeEditFeaturedFlaged; // make sure the batch paymode can be editable

  return (
    <>
      <ShadowCard padding={6} marginBottom={4}>
        <TopRow>
          <AmountSection>
            <Typography variant="h3" color={'gray.900'}>
              $
            </Typography>
            <Typography variant="invAmount" color={'gray.900'}>
              {moneyFormat(batchDetails?.amount)?.replace('$', '')}
            </Typography>
          </AmountSection>
          {!isPaymodeEditable ? (
            <TagWrapper className={`complete-disabled un_editable`}>
              {handlePayModeDisplay(batchDetails?.method)}
            </TagWrapper>
          ) : (
            <TagWrapper
              className={isCheckPayModeOnlyEditable ? `editable` : `complete-disabled un_editable`}
              onClick={isCheckPayModeOnlyEditable ? onClickEditPayMode : () => {}}
            >
              {isCheckPayModeOnlyEditable && (
                <EditIconWrapper>
                  <EditFilledIcon width={'8'} height={'8'} />
                </EditIconWrapper>
              )}
              {handlePayModeDisplay(batchDetails?.method)}
            </TagWrapper>
          )}
        </TopRow>
        <BottomSection>
          <SecondRow>
            <Typography
              variant={isInstantPayoutsExceptPaidStatus ? 'h2' : 'h2'}
              color={'gray.900'}
              sx={{ marginRight: 0.75 }}
            >
              {getDateTextFirstPart()}
            </Typography>
            <Typography
              variant={isInstantPayoutsExceptPaidStatus ? 'h1ExtraBold' : 'h2ExtraBold'}
              color={'gray.900'}
              lineHeight={'18px'}
            >
              {getDateTextSecondPart()}
            </Typography>
          </SecondRow>
          <Typography variant="h1Bold" color={'gray.500'} sx={{ marginTop: 1.5 }}>
            {getBottomLineText()}
          </Typography>
        </BottomSection>
      </ShadowCard>
    </>
  );
};
