import styled from 'styled-components';

export const SettingsBlockUserBlock = styled('div')(
  ({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: row;
`
);

export const SettingsAvatar = styled('div')(
  ({ theme }) => `
 position: relative;
 width: 40px;
 height: 40px;
`
);

export const SettingsContact = styled('div')(
  ({ theme }) => `
 position: relative;
 width: 100%;
  display: flex;
  flex-direction: column;
`
);
export const AvatarText = styled('div')(
  ({ theme }) => `
    position: relative;
    left: calc(50% - 19px/2  - 12px);
    top: calc(50% - 16px/2 - 45px);
`
);
