import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pre_fill_params } from 'src/shared/content/content';
import { accountIndividual } from 'src/models/account/account.service.mapper';
import {
  useGetAccountQuery,
  useUpdateAccountMutation,
} from 'src/services/account/accountServiceApi';

import { DOB } from 'src/shared/utilities/interface';

export const handleEditIndividual = () => {
  const dispatch = useDispatch();
  const { data, isFetching } = useGetAccountQuery(null);
  const [updateAccount] = useUpdateAccountMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [accountData, setAccountData]: any = useState();
  const [errorState, setErrorState] = useState('');

  useEffect(() => {
    if (data) {
      setAccountData(data);
    }
  }, [data]);

  const appLogin = async (
    data: any,
    onFormSubmit?: any,
    setResult?: any,
    setVerifyCodeContainerVisible?: any
  ) => {
    try {
      const allowFullSSN = data?.allowFullSSN ?? false;
      if (accountData) {
        setIsLoading(true);
        setErrorState('');
        const dob = data?.dob;
        //  const formattedDOB: any = moment(new Date(dob.year, dob.month, dob.day));
        data.dob = moment(dob).format('MMDDYYYY');
        // delete data.id;
        const updateFormData = {
          ...data,
          idNumber: data.idNumber,
          phone_number: data?.phone_number?.replace(/[^0-9]/g, ''),
          inputDescription: pre_fill_params.description,
          inputMCC: pre_fill_params.mcc,
          url: pre_fill_params.url,
        };
        const dataModel = accountIndividual(updateFormData, true, allowFullSSN);
        const accountUpdateResponse = await updateAccount(dataModel);
        // @ts-ignore
        if (accountUpdateResponse?.data) {
          // @ts-ignore
          setResult(accountUpdateResponse?.data);

          // @ts-ignore
          const checkAddressError = accountUpdateResponse?.data?.errors;

          const errorItem: any = checkAddressError.filter(
            (object: any) =>
              object.code === 'invalid_address_po_boxes_disallowed' &&
              object.requirement === 'individual.address.line1'
          );

          if (errorItem && errorItem.length === 1) {
            setErrorState(errorItem[0]?.reason);
          } else {
            setErrorState('');
            onFormSubmit();
          }
          // navigate('/identity-verification');
          // @ts-ignore
        } else {
          // @ts-ignore
          const responseError =
            // @ts-ignore
            accountUpdateResponse?.error?.data?.message ?? // @ts-ignore
            accountUpdateResponse?.error?.data?.error;
          if (responseError && typeof responseError === 'string') {
            setErrorState(responseError);
            setVerifyCodeContainerVisible && setVerifyCodeContainerVisible(false);
          }
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setVerifyCodeContainerVisible && setVerifyCodeContainerVisible(false);
    }
  };

  const onChangeHandlerPhone = (val: any, setValue: any) => {
    setValue('phone_number', val); // Set value
  };

  const isNullDOB = (dob: DOB) => {
    return !dob.day || !dob.month || !dob.year;
  };

  const onChangeHandlerDateInput = (val: any, setValue: any, setErrorManualDateType: any) => {
    if (val) {
      const dateMoment = moment(val);
      const toDate = moment(val).toDate();
      const dateWithFormat = moment(toDate).format('MM/DD/yyyy');
      if (
        !dateMoment?.isValid() ||
        !toDate?.getFullYear() ||
        toDate?.getFullYear() < 1900 ||
        (toDate?.getFullYear() > new Date().getFullYear() && val.length !== 10)
      ) {
        setErrorManualDateType(false);
      } else {
        const dateWithFormat = moment(toDate).format('MM/DD/yyyy');
        const years = moment().diff(dateWithFormat, 'years', true);
        if (years < 18) {
          val.length === 10 ? setErrorManualDateType(true) : setErrorManualDateType(false);
        } else {
          val.length < 9 ? setErrorManualDateType(false) : setErrorManualDateType(false);
        }
      }
    }
    setValue('dob', val); // Set value
  };

  const isNot18 = (val: any) => {
    const dateMoment = moment(val);
    const toDate = moment(val).toDate();
    const dateWithFormat = moment(toDate).format('MM/DD/yyyy');
    const years = moment().diff(dateWithFormat, 'years', true);
    if (years < 18) {
      return val.length === 10 ? false : true;
    }
    return true;
  };

  const isValidDOB = (value: any) => {
    const dateMoment = moment(value);
    return dateMoment?.isValid();
  };

  return [
    accountData,
    errorState,
    setErrorState,
    appLogin,
    isNullDOB,
    onChangeHandlerPhone,
    isLoading,
    onChangeHandlerDateInput,
    isNot18,
    isValidDOB,
  ];
};
