import { PayModesInterface } from 'src/models/component/base.model';

export const EARNINGS = {
  SUBMITTED: 'INVOICED',
  APPROVED: 'APPROVED',
  PAID: 'PAID',
  TERMS_ACCEPTED: 'TERMS_ACCEPTED',
  INVOICED_AT_DESC: 'INVOICED_AT_DESC',
  APPROVED_AT_DESC: 'APPROVED_AT_DESC',
  PAID_AT_DESC: 'PAID_AT_DESC',
  CREATED_AT_DESC: 'CREATED_AT_DESC',
};

export const TRANSFERS = {
  APPROVED: 'approved',
  PAID: 'paid',
  PENDING: 'pending',
  APPROVED_AT_DESC: 'APPROVED_AT_DESC',
  PAID_AT_DESC: 'PAID_AT_DESC',
  CREATED_AT_DESC: 'CREATED_AT_DESC',
};

export const defPayModes: PayModesInterface[] = [
  {
    id: 'ach',
    modeName: 'ACH',
    feePercentage: '0.80',
    active: true,
  },
  {
    id: 'debit_card',
    modeName: 'Debit Cards',
    feePercentage: 'N/A',
    active: false,
  },
  {
    id: 'check',
    modeName: 'Checks',
    feePercentage: 'N/A',
    active: false,
  },
];

export const PAY3342 = true;
export const PAY3349 = false;
export const removeFriendReferrals = true;
export const searchTransByProperty = false;

export const trussaryHiddenUIs = {
  atmWitdrawalLimits: false,
};
