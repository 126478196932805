import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const MainPayupCardWrapper = styled(Box)(
  ({ theme }) => `
   width: calc(100% + 32px);
   margin-left: -16px;
   display: flex;
   padding-left: 16px;
   padding-right: 16px;

   @media(min-width: ${theme.breakpoints.values.sm}px){
    margin-top: 32px;
   }
 `
);

export const MainPayupCardContainer = styled(Box)(
  ({ theme }) => `
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing(4)};
    background: ${theme.palette.white};
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 6px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    @media(min-width: ${theme.breakpoints.values.sm}px){
      padding: ${theme.spacing(6)} ${theme.spacing(4)} ${theme.spacing(4)};
    }
    
    @media(max-width: ${theme.breakpoints.values.sm}px){
      padding: ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(2)};
      .invoices_table_head {
        display: none;
      }
    }
  `
);

export const MovingCardWrapper = styled(Box)(
  ({ theme }) => `
  width: calc(100% + 32px);
  display: flex;
  align-items: center;
  height: 179px;
  position: relative;
  overflow: hidden;
  position: relative;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;

  @media(min-width: ${theme.breakpoints.values.sm}px){
    max-width: 360px;
  }

  @media(max-width: ${theme.breakpoints.values.sm}px){
    max-width: 340px;
  }
`
);

export const MovingCardWrapperLeft = styled(Box)(
  ({ theme }) => `
  width: 100%;
  display: flex:
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  padding-left: 8px;
  max-width: 300px;
  transition: all 0.4s ease;

  &.move-left {
    left: -100%;
    right: 100%;
  }
`
);

export const MovingCardWrapperRight = styled(Box)(
  ({ theme }) => `
  width: 100%;
  top: 0;
  bottom: 0;
  left: auto;
  right: -200px;
  position: absolute;
  padding-left: 0;
  transition: all 0.4s ease;

  img {
   width: auto;
   height: auto;
  }

  @media(min-width: ${theme.breakpoints.values.sm}px){
    &.move-left {
      right: -30px;
    }
  }

  @media(max-width: ${theme.breakpoints.values.sm}px){
    &.move-left {
      right: -14px;
    }
  }

  @media(max-width: 380px){
    &.move-left {
      padding-left: 10px;
    }
  }
`
);

export const MovingCardWSwipper = styled(Box)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background: transparent;
  z-index: 3;

  @media(min-width: ${theme.breakpoints.values.sm}px){
    width: 160px;

    &.move-left {
      width: 48px;
    }
  }

  @media(max-width: ${theme.breakpoints.values.sm}px){
    width: 140px;

    &.move-left {
      width: 44px;
    }
  }
`
);

export const MovingCardWSwipperRight = styled(Box)(
  ({ theme }) => `
   display: flex;
   align-items: center;
   top: 0;
   bottom: 0;
   left: 0;
   position: absolute;
   background: transparent;
   z-index: 3;

   @media(min-width: ${theme.breakpoints.values.sm}px){
    width: 210px;
  }

  @media(max-width: ${theme.breakpoints.values.sm}px){
    width: 190px;
  }
 `
);
