import React from 'react';
import { Icon } from '../../../..';

import {
  SearchBarWrapper,
  FilterButton,
  SearchInputWrapper,
  FilterCounter,
  DownloadButton,
} from './SearchBar.style';
import { InvoicesBatchesNavigation } from 'src/components/base/core/Navigations/InvoicesBatchesNavigation/InvoicesBatchesNavigation';
import { FilterIconFilled } from 'src/components/base/core/Media/Icon/Icons/Icons';
import { searchBarProps } from 'src/models/component/base.model';

export const SearchBar = ({
  onchangeHandler,
  onClickEnableFilter,
  isSearchIconVisible,
  onResetSearch,
  searchText,
  filterCounterValue,
  showFilterBar = true,
  isTransactions = false,
  onClickDownloadCSV,
  tabType,
  isInvoicesActiveInSearchBar = true,
  t,
}: searchBarProps) => {
  const isVisible = filterCounterValue !== 0 && filterCounterValue !== undefined;

  return (
    <SearchBarWrapper>
      <SearchInputWrapper className={isTransactions ? 'no_download' : undefined}>
        <input
          type="text"
          placeholder={
            isInvoicesActiveInSearchBar
              ? t('earning.searchText')
              : t('batches.searchInputPlaceHolder')
          }
          onChange={onchangeHandler}
          value={searchText}
        />
        {isSearchIconVisible && <Icon iconType="SearchIcon" />}
        {!isSearchIconVisible && (
          <span className="close_icon" onClick={onResetSearch}>
            <Icon iconType="CloseIcon" />
          </span>
        )}
      </SearchInputWrapper>

      <InvoicesBatchesNavigation
        tabType={tabType}
        isInvoicesActive={isInvoicesActiveInSearchBar}
        t={t}
      />

      {showFilterBar && (
        <FilterButton
          onClick={onClickEnableFilter}
          className={!onClickDownloadCSV ? 'enable_full_radius' : 'enable_half_radius'}
        >
          <FilterIconFilled />
          {isVisible && (
            <FilterCounter>
              <span>{filterCounterValue}</span>
            </FilterCounter>
          )}
        </FilterButton>
      )}

      {onClickDownloadCSV ? (
        <DownloadButton
          onClick={onClickDownloadCSV}
          className={!showFilterBar ? 'enable_full_radius' : 'enable_half_radius'}
        >
          <Icon iconType="DownloadCSVIcon" />
        </DownloadButton>
      ) : null}
    </SearchBarWrapper>
  );
};
