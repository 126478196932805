import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const InvoiceCardWrapper = styled(Box)(
  ({ theme }) => `
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing(4)};
    background: ${theme.palette.white};
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 6px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    @media(min-width: ${theme.breakpoints.values.sm}px){
      padding: ${theme.spacing(6)} ${theme.spacing(4)} ${theme.spacing(4)};
    }
    
    @media(max-width: ${theme.breakpoints.values.sm}px){
      padding: ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(2)};
      .invoices_table_head {
        display: none;
      }
    }
  `
);

export const IconArrow = styled('div')`
  display: inline-flex;
  width: 15px;
  overflow: hidden;
  cusrsor: pointer;

  svg {
    height: 22px;
    width: 22px;
  }
`;

export const InvoiceCardIcon = styled('div')(
  ({ theme }) => `
  margin-right: ${theme.spacing(2)};
`
);
