import { styled } from '@mui/material/styles';

export const ProgressCardBottom = styled('div')`
  display: flex;
  align-items: center;
`;

export const ProgressCardIcon = styled('div')(
  ({ theme }) => `
 height: 48px;
 width: 48px;
 margin-right: ${theme.spacing(2)};

 img {
  height: 48px;
  width: 48px;
 }
`
);
