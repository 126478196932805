import { useTranslation } from 'react-i18next';

import { Box, Button, Stack, Typography } from '@mui/material';

import { Modal } from '../Modal/Modal';
import { ConfirmIcon } from 'src/components/base/core/Media/Icon/Icons/Icons';

import { CustomerAdditionConfirmModalIconWrapper } from './ConfirmationModal.style';

export const ConfirmationModal = ({ onCloseEvent, onFormSubmit, title, content }: any) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal onCloseEvent={onCloseEvent} hideClose={false} isMobileBottomFixed={false}>
        <CustomerAdditionConfirmModalIconWrapper marginBottom={3}>
          <ConfirmIcon />
        </CustomerAdditionConfirmModalIconWrapper>
        <Box marginBottom={4} paddingX={5}>
          <Stack spacing={3}>
            {title && (
              <Typography variant={'h3ExtraBold'} textAlign={'center'} color={'gray.900'}>
                {title}
              </Typography>
            )}
            {content && (
              <Typography
                variant="h2"
                textAlign="center"
                color={'messageLink.main'}
                sx={{
                  lineHeight: 1.5,
                  fontWeight: 400,
                }}
              >
                {content}
              </Typography>
            )}
          </Stack>
        </Box>
        <Box marginBottom={2} justifyContent={'center'} display={'flex'}>
          <Button
            onClick={onFormSubmit}
            variant="containedLarge"
            size="small"
            color="primary"
            type="button"
            sx={{ overflow: 'hidden', width: 120, height: 44 }}
          >
            {t('buttonTexts.doneButton')}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
