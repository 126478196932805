import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { OnHoldBlock } from 'src/components/base/core';

import { useGetAccountDataMutation } from 'src/services/account/accountServiceApi';

import { routeType } from 'src/helpers/containers/helperRoutePreference';
import { handlePreferences } from 'src/helpers/containers/helperPreferences';

// set time out triggeringt time
const redirection_time = 4000;

export const SettingsOnHoldForVerificationContainer = () => {
  const navigate = useNavigate(); // call useNavigate hook from react-router-dom
  const location = useLocation(); // call useLocation hook from react-router-dom

  /**
   * call react useState hooks to handle the states
   */
  const [account, setAccount]: any = useState();
  const [timeoutId, setTimeoutId] = useState({});

  /**
   * call RTK query mutation
   */
  const [getAccountData]: any = useGetAccountDataMutation();

  /**
   * call custom hooks
   */
  const [updatePreferenceOnServer] = handlePreferences();

  /**
   * get location states
   */
  const { isFromHome } =
    location?.state != null
      ? (location?.state as { isFromHome: boolean })
      : {
          isFromHome: false,
        };

  /**
   * trigger the account call after the page load
   * this will happen after 4s (redirection_time = 4000)
   */
  useEffect(() => {
    // trigger the the interval call __
    const timeoutId = setInterval(async () => {
      const retryData = await getAccountData();
      setAccount(retryData);
    }, redirection_time);

    // use setTimeoutId method to set the timout time __
    setTimeoutId(timeoutId);

    // clear the interval __
    return () => {
      clearInterval(timeoutId as NodeJS.Timeout);
    };
  }, []);

  /**
   * common on function to handle the reset values before redirect to the dashboard
   */
  const handleCommonOldOnFunctions = () => {
    localStorage.removeItem('enable_holdon');
    clearInterval(timeoutId as NodeJS.Timeout);
    updatePreferenceOnServer(routeType.ACCOUNT_APPROVED);
  };

  /**
   * function to handle ssn failiure redirection
   */
  const handleCommonsSSNFailRedirection = () => {
    localStorage.removeItem('enable_holdon');
    clearInterval(timeoutId as NodeJS.Timeout);
    setTimeout(function () {
      updatePreferenceOnServer(routeType.ID_DOC_FAIL);
      navigate('/continueto-id-doc-on-verification-failed', {
        state: { isFromSettings: true },
      });
    }, 2000);
  };

  /**
   * check the account data and handle the redirection
   */
  useEffect(() => {
    if (account) {
      const getAccountData = account?.data; // get account response object
      const individual = getAccountData?.individual; // get nested individual object from account response
      const company = getAccountData?.company; // get nested comapny object from account response
      const dueRequirements = getAccountData?.currently_due_requirements; // get currently_due_requirements array
      const isCompanyAccount = getAccountData?.business_type === 'company'; // check is business type is company (return true or false)
      const isIndividualAccount = getAccountData?.business_type === 'individual'; // check is business type is individual (return true or false)
      const getRelationshipObj = individual?.relationShip; // get nested relationShip object from accountrespnse -> individual
      const getCompanyStructure = getAccountData?.company_structure; // get company_structure value (sole_proprietorship or null)
      const isChargesEnabled = getAccountData?.charges_enabled; // get charges_enabled value (return true or false)

      // confirm is ownership related details provided or not (return true or false)
      const IsOwnerTypeNotSelected =
        !getRelationshipObj?.executive && // executive value false
        !getRelationshipObj?.owner && // owner value false
        !getRelationshipObj?.representative && // representative value false
        !getRelationshipObj?.director; // director value false

      const checkSSNUnVerified = individual.verification.status === 'unverified'; // get unverified id verification status (return true or false)
      const checkSSNVerified = individual.verification.status === 'verified'; // get verified id verification status (return true or false)

      const checkTaxIdProvided =
        dueRequirements.includes('company.tax_id') || dueRequirements.includes('tax_id'); // get tax id  provided or not (return true or false)

      // check the due requirements, if tax Id document is need to provide or dues displaying or not
      const checkVerificationDocumentrequirement = dueRequirements.includes(
        'individual.verification.document'
      );

      const checkTaxIdDocumentUpdated =
        company?.verification?.document?.front || company?.verification?.document?.back; // get tax id doc provided or not (return true or false)

      // check if comapny structure is sole_proprietorship, charges enabled, tax id provided, and ssn details are given or not
      // with above conditions also check ownership and percentage details are given or not__
      const checkChargesEnabledForExecutiveTypeOrNot =
        isChargesEnabled &&
        getCompanyStructure === 'sole_proprietorship' &&
        (!checkTaxIdProvided || checkTaxIdDocumentUpdated) &&
        checkSSNVerified;

      // check if comapny structure is not sole_proprietorship and it's null, also charges enabled, and tax id details are given or not
      // with above conditions also check ownership and percentage details are given or not__
      const checkChargesEnabledForOwnerTypeOrNot =
        isChargesEnabled &&
        getCompanyStructure !== 'sole_proprietorship' &&
        (!checkTaxIdProvided || checkTaxIdDocumentUpdated);

      // check if comapny structure is not sole_proprietorship and it's null, also charges enabled, and tax id details are given or not
      // with above conditions also check ownership and percentage details are given or not__
      const checkChargesEnabledForNOwnerTypeSelection =
        isChargesEnabled &&
        getCompanyStructure !== 'sole_proprietorship' &&
        (!checkTaxIdProvided || checkTaxIdDocumentUpdated) &&
        IsOwnerTypeNotSelected;

      // condition apply with ::  redirect from dashboard, charges enabled, business type was selected__
      if (isFromHome && isChargesEnabled && (isCompanyAccount || isIndividualAccount)) {
        handleCommonOldOnFunctions(); // call the reset function
        navigate('/dashboard'); // rederect to dashboard
        return;
      } // condition apply with :: is company business type, and not redirect from dashboard__
      else if (isCompanyAccount && !isFromHome) {
        // is company structure value is 'sole_proprietorship', ownership details are given, charges enabled and required other all details are given
        if (checkChargesEnabledForExecutiveTypeOrNot) {
          handleCommonOldOnFunctions(); // call the reset function
          navigate('/settings/account-info'); // redirect to setting account info
          return;
        } // is company structure value is null, ownership details are given, but charges enabled and required other all details are given
        else if (checkChargesEnabledForOwnerTypeOrNot) {
          handleCommonOldOnFunctions(); // call the reset function
          navigate('/settings/account-info'); // redirect to setting account info
        } // is company structure value is null, ownership details are not given, charges enabled and required other all details are given
        else if (checkChargesEnabledForNOwnerTypeSelection) {
          handleCommonOldOnFunctions(); // call the reset function
          navigate('/settings/account-info'); // redirect to setting account info
        }

        // handle SSN failiure redirection
        if (
          (checkSSNUnVerified || checkVerificationDocumentrequirement) &&
          getCompanyStructure === 'sole_proprietorship'
        ) {
          handleCommonsSSNFailRedirection(); // call the SSN failiure handling function
        } // handle EIN failiure redirection
        else if (checkTaxIdProvided && !checkTaxIdDocumentUpdated) {
          localStorage.removeItem('enable_holdon');
          clearInterval(timeoutId as NodeJS.Timeout);
          updatePreferenceOnServer(routeType.EIN_FAIL_BUSINESS);
          setTimeout(function () {
            navigate('/ein-verification-failed', {
              state: { isFromSettings: true },
            });
          }, 2000);
        }
      } // condition apply with :: is individual business type, and not redirect from dashboard__
      else if (isIndividualAccount && !isFromHome) {
        // all details are given and rederection to account-info route
        if (
          account?.data?.charges_enabled &&
          !checkVerificationDocumentrequirement &&
          (!checkTaxIdProvided || checkTaxIdDocumentUpdated) &&
          checkSSNVerified
        ) {
          handleCommonOldOnFunctions(); // call the reset function
          navigate('/settings/account-info');
          return;
        }

        // iff SSN failiure and andle the redirection
        if (checkSSNUnVerified || checkVerificationDocumentrequirement) {
          handleCommonsSSNFailRedirection(); // call the SSN failiure handling function
        }
      }
    }
  }, [account]);

  /**
   * return primary JSX element
   */
  return <OnHoldBlock />;
};
