import { ShadowCard } from '../../CommonNestedStyles/FormWrapper.style';
import { Progressbar } from '../../OtherUtilities/Progressbar/Progressbar';
import { LargeIcons } from 'src/shared/utilities/images';
import { Box, Typography } from '@mui/material';
import { ProgressCardProps } from 'src/models/component/base.model';
import { ProgressCardBottom, ProgressCardIcon } from './ProgressCard.style';
export const ProgressCard = ({
  totalProgressSteps,
  currentProgressStep,
  progressValue,
  progressLabel,
  title,
  stepsContent,
  overallProgressLabel,
  overallProgressValue,
  isBorderCardEnabled = false,
}: ProgressCardProps) => {
  return (
    <ShadowCard
      marginBottom={3}
      paddingX={4}
      paddingY={4}
      className={isBorderCardEnabled ? 'full_border' : 'normal_shadow'}
    >
      <Box marginBottom={4} display="flex" justifyContent={'space-between'}>
        <Typography variant="caption3" color="gray.400" textTransform={'uppercase'}>
          {title}
        </Typography>
        <Typography variant="h1Bold" color="gray.900">
          {`${currentProgressStep ?? 0} / ${totalProgressSteps ?? 0} ${stepsContent}`}
        </Typography>
      </Box>
      <ProgressCardBottom>
        <ProgressCardIcon>
          <img src={LargeIcons.giftIcon} alt={'gift-icon'} />
        </ProgressCardIcon>
        <Progressbar
          isProgressInfoVisible
          overallProgressValue={overallProgressValue}
          overallProgressLabel={overallProgressLabel}
          progressLabel={progressLabel}
          progressValue={progressValue}
          currentProgressStep={currentProgressStep}
          totalProgressSteps={totalProgressSteps}
        />
      </ProgressCardBottom>
    </ShadowCard>
  );
};
